import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const RemoteventsIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20">
    <g id="Remote_events" data-name="Remote events" transform="translate(-3.5 -2)">
      <path
        id="Path_4639"
        data-name="Path 4639"
        d="M11.562,15.982a1,1,0,0,0-1.062,1V20H7a1,1,0,0,0,0,2H17a1,1,0,0,0,0-2H13.5V16.98a1,1,0,0,0-1.062-1c-.161.01-.3.018-.438.018S11.724,15.992,11.562,15.982Z"
        fill="#676e7b"
        fillRule="evenodd"
      />
      <path id="Path_4640" data-name="Path 4640" d="M12,18a8,8,0,1,0-8-8A8,8,0,0,0,12,18Z" fill="#676e7b" />
      <path id="Path_4641" data-name="Path 4641" d="M12,13.5A3.5,3.5,0,1,0,8.5,10,3.5,3.5,0,0,0,12,13.5Z" fill="#676e7b" />
      <path
        id="Path_4642"
        data-name="Path 4642"
        d="M20,10H15.5"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_4643"
        data-name="Path 4643"
        d="M8.5,10H4"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_4644"
        data-name="Path 4644"
        d="M12,13.5A3.5,3.5,0,1,0,8.5,10,3.5,3.5,0,0,0,12,13.5Z"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>,
  ' RemoteventsIcon'
);
export default RemoteventsIcon;
