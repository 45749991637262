import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const SustainabilityIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="20.995" height="21.178" viewBox="0 0 20.995 21.178" style={{ fontSize: 'larger' }}>
    <g id="Sustainability" transform="translate(-48.626 -46.197)">
      <path
        id="Path_4809"
        data-name="Path 4809"
        d="M119.471,114.26a7.162,7.162,0,1,0,7.162,7.162A7.17,7.17,0,0,0,119.471,114.26Zm-6.252,7.162a6.213,6.213,0,0,1,.792-3.045,4.432,4.432,0,0,0,2.314.629c2.128,0,3.854-1.4,3.854-3.136a2.586,2.586,0,0,0-.089-.671,6.263,6.263,0,0,1,5.619,5.785,3.58,3.58,0,0,0-3.137,2.361c-.556,1.907-3.144,1.051-3.564,4.311A6.263,6.263,0,0,1,113.219,121.422Z"
        transform="translate(-60.468 -64.625)"
        fill="#676e7b"
      />
      <path
        id="Path_4810"
        data-name="Path 4810"
        d="M226,279.081a1.483,1.483,0,0,0,1.515-1.182.235.235,0,0,0-.229-.289h-2.576a.235.235,0,0,0-.229.289A1.492,1.492,0,0,0,226,279.081Z"
        transform="translate(-166.97 -219.725)"
        fill="#676e7b"
      />
      <path
        id="Path_4811"
        data-name="Path 4811"
        d="M202.615,236.59a.645.645,0,1,0,.644.645A.645.645,0,0,0,202.615,236.59Z"
        transform="translate(-145.599 -180.777)"
        fill="#676e7b"
      />
      <path
        id="Path_4812"
        data-name="Path 4812"
        d="M282.495,237.88a.645.645,0,1,0-.645-.645A.645.645,0,0,0,282.495,237.88Z"
        transform="translate(-221.444 -180.777)"
        fill="#676e7b"
      />
      <path
        id="Path_4813"
        data-name="Path 4813"
        d="M188.853,64.278A10.636,10.636,0,0,0,177.4,46.921l.33.848A9.726,9.726,0,0,1,188.2,63.644l-.647-.63a.48.48,0,0,0-.816.351l.024,1.712a.48.48,0,0,0,.486.473l1.712-.02a.48.48,0,0,0,.329-.824Z"
        transform="translate(-122.27)"
        fill="#676e7b"
      />
      <path
        id="Path_4814"
        data-name="Path 4814"
        d="M55.355,98.487a9.72,9.72,0,0,1-3.031-15.708l.573.556a.48.48,0,0,0,.816-.351l-.024-1.712A.479.479,0,0,0,53.2,80.8l-1.712.02a.48.48,0,0,0-.331.827l.512.5A10.634,10.634,0,0,0,63.125,99.5l-.329-.847A9.662,9.662,0,0,1,55.355,98.487Z"
        transform="translate(0 -32.854)"
        fill="#676e7b"
      />
    </g>
  </svg>,
  'SustainabilityIcon'
);
export default SustainabilityIcon;
