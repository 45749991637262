import React, { useState, useEffect } from 'react';
import { Grid, Select, Typography, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getUserVisibility, getVisibilities, sendVisibility } from 'app/shared/reducers/settings/settings-privacy-visibility';
import CommonSelect from 'app/components/common/select/common-select';
import UpdateButton from 'app/components/common/buttons/update-button';

const ChangeVisibility = () => {
  const [selected, setSelected] = useState('');
  const visibilitySlice = useAppSelector(state => state.visibilityReducer);
  const id = useAppSelector(state => state.authentication.account.id);
  const options = visibilitySlice?.visibilities?.map(option => option.charAt(0).toUpperCase() + option.slice(1).toLowerCase());
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getVisibilities());
    dispatch(getUserVisibility());
  }, []);

  useEffect(() => {
    if (visibilitySlice?.currentVisibility) {
      setSelected(visibilitySlice.currentVisibility.charAt(0).toUpperCase() + visibilitySlice.currentVisibility.slice(1).toLowerCase());
    }
  }, [visibilitySlice?.currentVisibility]);

  const capitalSelected = selected.toUpperCase();
  const handleSubmit = () => {
    dispatch(sendVisibility({ visibility: capitalSelected, id })).then(() => {
      dispatch(getUserVisibility());
    });
  };
  const theme = useTheme();

  const isEqual = capitalSelected === visibilitySlice?.currentVisibility;

  return (
    <Grid container direction="column" gap={2}>
      <Grid item>
        <Typography variant="h5" sx={{ color: theme.textColor.mainHeading, fontSize: '14px' }}>
          Select your visibility
        </Typography>
      </Grid>
      <Grid item>
        <CommonSelect options={options} selected={selected} setSelected={setSelected} />

        <small style={{ color: theme.textColor.mainHeading, fontSize: '12px', fontStyle: 'italic' }}>
          Default - can be viewed by registered users only.
        </small>
        <br></br>
        <small style={{ color: theme.textColor.mainHeading, fontSize: '12px', fontStyle: 'italic' }}>
          Public - can be viewed by anyone.
        </small>
        <br></br>
        <small style={{ color: theme.textColor.mainHeading, fontSize: '12px', fontStyle: 'italic' }}>
          Private - can be viewed by co-participants and organizers only.
        </small>
      </Grid>
      <Grid item>
        <UpdateButton isEqual={isEqual} handleSubmit={handleSubmit} />
      </Grid>
    </Grid>
  );
};

export default ChangeVisibility;
