// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiTablePagination-selectLabel {
  margin-bottom: 0px !important;
}

.MuiTablePagination-displayedRows {
  margin-bottom: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/common/table/draft-table.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;AACF;;AACA;EACE,6BAAA;AAEF","sourcesContent":[".MuiTablePagination-selectLabel {\n  margin-bottom: 0px !important;\n}\n.MuiTablePagination-displayedRows {\n  margin-bottom: 0px !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
