import React, { useState, useEffect } from 'react';
import EventsSlider from 'app/components/ui/auth/events-near-you/event-slider';
import { Grid, InputAdornment, TextField, Typography, useTheme } from '@mui/material';
import { Storage } from 'react-jhipster';
import { fetchDistanceLimit } from 'app/shared/reducers/distance-limit';
import { useAppDispatch, useAppSelector } from 'app/config/store';

interface SettingsProfileEventsProps {
  setUpdatedProfile: React.Dispatch<React.SetStateAction<{}>>;
}
const SettingsProfileEvents = ({ setUpdatedProfile }: SettingsProfileEventsProps) => {
  const [textFieldDistance, setTextFieldDistance] = useState<number>(0);
  const [sliderDistance, setSliderDistance] = useState<number | string>(0);
  const [defaultMaxDistance, setDefaultMaxDistance] = useState<number>(0);
  const [sliderDisabled, setSliderDisabled] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const profileSettings = useAppSelector(state => state.settingsProfile.settingsProfile);

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    setTextFieldDistance(value);
  };
  let maxLimitFromStorage: number;
  let defaultDistance: number;
  useEffect(() => {
    if (Storage.session.get('Maxdistance')) {
      const maxDistanceFromStorage = Storage.session.get('Maxdistance');
      const defaultDistanceFromStorage = Storage.session.get('defaultDistance');
      if (maxDistanceFromStorage) {
        maxLimitFromStorage = JSON.parse(maxDistanceFromStorage);
      }
      if (defaultDistanceFromStorage) {
        defaultDistance = JSON.parse(defaultDistanceFromStorage);
      }
      setDefaultMaxDistance(defaultDistance);
      if (Storage.session.get('ActiveField') == 'TextField') {
        setTextFieldDistance(maxLimitFromStorage);
      } else {
        setSliderDistance(maxLimitFromStorage);
      }
    } else {
      (async () => {
        try {
          const response = await dispatch(fetchDistanceLimit());
          setDefaultMaxDistance(Number(response.payload));
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (profileSettings) {
      setSliderDistance(profileSettings?.distance);
      setUpdatedProfile(prevState => ({
        ...prevState,
        distance: profileSettings?.distance,
      }));
    }
  }, [profileSettings]);
  const input = document.querySelector('input[name="locationRange"]');

  if (input) {
    input.addEventListener('wheel', function (event) {
      event.preventDefault();
    });
  }
  useEffect(() => {
    if (textFieldDistance > 0) {
      setSliderDisabled(true);
      setSliderDistance(0);
    } else {
      setSliderDisabled(false);
    }
  }, [textFieldDistance]);
  const handleSliderChange = (newValue: number) => {
    console.log('New slider value:', newValue);
    setUpdatedProfile(prevState => ({
      ...prevState,
      distance: newValue,
    }));
  };
  const theme = useTheme();
  return (
    <Grid container direction={'column'}>
      <Grid item>
        <Typography variant="h5" sx={{ color: theme.textColor.mainHeading, fontSize: '14px' }}>
          Want to know about events near you?
        </Typography>
      </Grid>
      <Grid item px={3} pt={1.2} mt={1}>
        <div className="d-inline-flex col-12 mt-2">
          <p className="d-inline-block col-2 p-0 text-center pt-1" style={{ fontSize: '12px', color: 'secondary.light' }}>
            {' '}
            0 km/miles
          </p>
          <div className="col-8">
            <EventsSlider
              sliderDistance={sliderDistance}
              sliderDisabled={sliderDisabled}
              setSliderDistance={setSliderDistance}
              defaultMaxDistance={defaultMaxDistance}
              onSliderChange={handleSliderChange}
            />
          </div>
          <p className="d-inline-block col-2 ps-2 pt-1" style={{ fontSize: '12px', color: 'secondary.light' }}>
            {' '}
            100 km/miles
          </p>
        </div>

        <p className="col-12 text-center" style={{ fontSize: '14px', color: 'secondary.light' }}>
          {' '}
          {sliderDistance != '0' && sliderDistance != null ? sliderDistance + ' km/miles' : ''}
        </p>
      </Grid>
      {/* <Grid item>
        <TextField
          type="number"
          InputProps={{ inputProps: { min: 0, max: 10000 }, endAdornment: <InputAdornment position="end">km</InputAdornment> }}
          name="locationRange"
          fullWidth
          size="small"
          value={textFieldDistance}
          onChange={handleTextFieldChange}
        />
      </Grid> */}
    </Grid>
  );
};

export default SettingsProfileEvents;
