import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';

type Props = {
  color: string;
  label: string;
  startDate: string;
  count: number;
};

export default function ColoredBox({ color, label, startDate, count }: Props) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box display="flex" alignItems="center" gap={0.6}>
      <Box height={10} width={10} borderRadius={0.7} bgcolor={color}></Box>
      <Typography variant="body2" textAlign="center" color={isDarkMode ? '#fff' : '#798498'} fontSize="0.7rem">
        {`${label} from ${startDate}: `}
        <Typography fontWeight="bold" textAlign="center" color={color} fontSize="0.7rem" component="span">
          {count}
        </Typography>
      </Typography>
    </Box>
  );
}
