import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import SideNavigation from 'app/components/layout/side-navigaion/side-navigation';
import PrimaryHeader from 'app/components/layout/header/primary-header';
import { fetchProfile, fetchUnAuthorizedProfile } from 'app/shared/reducers/profile';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import AmyIcon from 'app/components/common/cards/chat-card/amy-icons';
import toast from 'react-hot-toast';
import { Button, Card, Grid, useMediaQuery } from '@mui/material';
import AuthorisationModal from 'app/components/common/authorization-modal/authorization-modal';
import { resetLogin, toggleModalLogin } from 'app/shared/reducers/authentication';
import axios from 'axios';
import NewPrimarySearchAppBar from 'app/components/layout/header/new-primary-header';
import { Container, Typography, List, ListItem, Link } from '@mui/material';
import RelevantLinks from '../relevant-links/relevant-links';

interface Props {
  window?: () => Window;
}
const TermsAndCondition = () => {
  // const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [currentComponent, setCurrentComponent] = React.useState(' ');
  const [authModalOpen, setAuthModalOpen] = React.useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDarkMode = theme.palette.mode === 'dark';
  const [toggleValue, setToggleValue] = useState(false);
  const [toggleMapValue, setToggleMapValue] = useState(false);
  const [searchKeyword, setSearchKeyword] = React.useState<string>('');
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [routeBack, setRouteBack] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const location = useLocation();

  const isBackHome = location.state?.isBackHome;
  const [htmlContent, setHtmlContent] = useState('');
  // const isBackHome = true;
  const fetchAboutUs = async () => {
    try {
      const response = await axios.get<any>(`api/staticContent/terms-and-condition`);
      console.log('community-guidelines', response);
      setHtmlContent(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAboutUs();
  }, []);
  useEffect(() => {
    if (isBackHome) {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener('popstate', e => {
        e.preventDefault();
        window.history.pushState(null, null, window.location.pathname);
      });
    }
  }, []);

  const handleSubCategoryClick = (category: string, subCategory: string) => {
    setCategory(category);
    setSubCategory(subCategory);
  };
  const handleAccordionClick = (category: any, currentState: any) => {
    setCategory(category);
  };
  const handleNavigationArrowButton = () => {
    if (fieldsChanged) {
      setRouteBack(true);
    } else if (isBackHome) {
      navigate('/');
    } else {
      navigate(-1);
    }
  };
  const handleButtonClicked = () => {
    const newToggleValue = !toggleValue;
    if (toggleMapValue) {
      setToggleMapValue(false);
    }
    setToggleValue(newToggleValue);
  };

  const handleButtonMapClicked = () => {
    const newToggleMapValue = !toggleMapValue;
    if (toggleValue) {
      setToggleValue(false);
    }
    setToggleMapValue(newToggleMapValue);
  };
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  const handleButtonFeatureClicked = () => {};

  // const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <Box>
      <NewPrimarySearchAppBar searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} />
      <Box
        sx={{
          maxWidth: '800px',
          width: '100%',
          margin: 'auto',
          overflowY: 'scroll',
          height: '100vh',
          paddingBottom: '120px',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
      >
        <Card style={{ background: isDarkMode ? '#2c2c38' : '' }}>
          <Container maxWidth="md" sx={{ paddingY: 4 }}>
            <Typography variant="h4" gutterBottom>
              Terms and Conditions
            </Typography>

            <Typography variant="h5" gutterBottom>
              1. Introduction
            </Typography>
            <List>
              <ListItem>
                1.1. These Terms and Conditions ("Terms") govern Your use of the ReachAMY website and mobile application (collectively, the
                "Platform"), operated by ReachAMY Inc. ("we", "us", "our"). By accessing or using the Platform, You agree to be bound by
                these Terms. If You disagree with any part of these Terms, You may not access the Platform.
              </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
              2. Use of the Platform
            </Typography>
            <List>
              <ListItem>
                2.1. The Platform provides tools and services for facilitating, creating, participating in, and documenting community
                service activities, petitions, and related content.
              </ListItem>
              <ListItem>
                2.2. You may create activities, petitions, participate in activities, and share related content on the Platform.
              </ListItem>
              <ListItem>
                2.3. You agree to provide accurate and complete information when using the Platform and to comply with all applicable laws
                and regulations.
              </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
              3. User Accounts
            </Typography>
            <List>
              <ListItem>
                3.1. To access certain features of the Platform, You may be required to create an account. You are responsible for
                maintaining the confidentiality of Your account and password and for restricting access to Your account. You agree to accept
                responsibility for all activities that occur under Your account.
              </ListItem>
              <ListItem>
                3.2. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of Your account.
              </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
              4. Content Ownership
            </Typography>
            <List>
              <ListItem>
                4.1. You retain ownership of any content You create, upload, or share on the Platform ("User Content"). By posting User
                Content on the Platform, You grant us a non-exclusive, royalty-free, worldwide, perpetual, and transferable license to use,
                reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such User Content in
                connection with operating and promoting the Platform.
              </ListItem>
              <ListItem>
                4.2. We reserve the right to remove any User Content that violates these Terms or is otherwise objectionable.
              </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
              5. Privacy
            </Typography>
            <List>
              <ListItem>
                5.1. Your privacy is important to us. Our Privacy Policy explains how we collect, use, and disclose information about You.
                By using the Platform, You consent to the collection and use of Your information as described in our Privacy Policy.
              </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
              6. Intellectual Property
            </Typography>
            <List>
              <ListItem>
                6.1. The Platform and its original content, features, and functionality are owned by ReachAMY Inc. and are protected by
                international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
              </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
              7. Limitation of Liability
            </Typography>
            <List>
              <ListItem>
                7.1. The Platform is provided "as is" without any warranties of any kind, whether express or implied. We do not warrant that
                the Platform will be uninterrupted, error-free, secure, or free from viruses or other harmful components.
              </ListItem>
              <ListItem>
                7.2. In no event shall ReachAMY Inc., its directors, officers, employees, or agents be liable for any indirect,
                consequential, exemplary, incidental, special, or punitive damages, including lost profits, lost data, personal injury, or
                property damage, arising out of or in connection with Your use of the Platform.
              </ListItem>
              <ListItem>
                7.3. ReachAMY Inc. expressly disclaims any liability for any harm, damages, or losses arising from Your participation in any
                activities, petitions, or use of the Platform.
              </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
              8. Third-Party Links
            </Typography>
            <List>
              <ListItem>
                8.1. The Platform may contain links to third-party websites or services that are not owned or controlled by us. We have no
                control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or
                services.
              </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
              9. Modifications
            </Typography>
            <List>
              <ListItem>
                9.1. We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material,
                we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be
                determined at our sole discretion.
              </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
              10. Governing Law
            </Typography>
            <List>
              <ListItem>
                10.1. These Terms shall be governed and construed in accordance with the laws of the State of Delaware, without regard to
                its conflict of law provisions.
              </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
              11. Dispute Resolution
            </Typography>
            <List>
              <ListItem>
                11.1. Any dispute arising out of or relating to these Terms or the use of the Platform shall be settled by arbitration
                administered by [Arbitration Institution] in accordance with its rules, and judgment on the award rendered by the
                arbitrator(s) may be entered in any court having jurisdiction thereof.
              </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
              12. Contact Us
            </Typography>
            <List>
              <ListItem>12.1. If You have any questions about these Terms, please contact us:</ListItem>
            </List>
            <List>
              <ListItem>
                - By email:{' '}
                <Link href="mailto:contact@reachamy.com" style={{ color: isDarkMode ? '#FFCE00;' : '#674bff' }}>
                  contact@reachamy.com
                </Link>
              </ListItem>
              {/* <ListItem>
                - By visiting this page on our website:{' '}
                <Link href="https://www.reachamy.com/privacy" target="_blank" style={{ color: isDarkMode ? '#FFCE00;' : '#674bff' }}>
                  www.reachamy.com/privacy
                </Link>
              </ListItem> */}
            </List>
              <RelevantLinks/>
          </Container>
        </Card>
      </Box>
    </Box>
  );
};

export default TermsAndCondition;
