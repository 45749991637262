import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CalendarIcon } from '@mui/x-date-pickers';
import { convertDateToDesiredFormat } from 'app/utils/common-utils/common-utils';
import { getColor } from '../general/section-head';

type Props = {
  activityCountData: any;
};

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const BarChartComponent = ({ activityCountData }: Props) => {
  const isSmallScreen = useMediaQuery('(max-width: 640px)');
  const [activityCounts, setActivityCounts] = useState([]);
  const [categoryNames, setCategoryNames] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  useEffect(() => {
    const zeroActivityCounts = [];
    const nonZeroActivityCounts = [];
    const zeroCategories = [];
    const nonZeroCategories = [];

    if (activityCountData?.dashBoardCategories?.length > 0) {
      for (const category of activityCountData.dashBoardCategories) {
        if (category.activityCount === 0) {
          zeroActivityCounts.push(category.activityCount);
          zeroCategories.push(category.category);
        } else {
          nonZeroActivityCounts.push(category.activityCount);
          nonZeroCategories.push(category.category);
        }
      }
    }

    // Combine zero activity counts with non-zero activity counts
    const combinedActivityCounts = [...nonZeroActivityCounts, ...zeroActivityCounts];
    setActivityCounts(combinedActivityCounts);

    // Combine zero categories with non-zero categories
    const combinedCategories = [...nonZeroCategories, ...zeroCategories];
    setCategoryNames(combinedCategories);
  }, [activityCountData?.dashBoardCategories]);

  const data = {
    labels: categoryNames,
    datasets: [
      {
        label: 'Activity Count',
        data: activityCounts,
        backgroundColor: categoryNames.map(category => getColor(category)),
        barBorderRadius: 4,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          callback: function (value) {
            if (value % 1 === 0) {
              return value;
            }
          },
        },
        grid: {
          display: false,
        },
      },

      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: 'SDG Activity Count',
      },
      tooltip: {
        enabled: true,
      },
    },
    maintainAspectRatio: false,
  };

  const chartContainerStyle = {
    width: '94%',
  };

  useEffect(() => {
    const elem = document.getElementById('bar-graph-canvas');
    if (elem) elem.style.height = '304px';
  }, []);

  const grayFontColor = isDarkMode ? '#fff' : '#798498';

  return (
    <div style={chartContainerStyle}>
      <Box pl={isSmallScreen ? 0 : 2} mb={1} display="flex" alignItems="center" justifyContent="space-between">
        <Typography fontSize="1rem" fontWeight="bold" component="h5">
          SDG Activity Count
        </Typography>
        {(startDate || endDate) && (
          <Box display="flex" alignItems="center" gap={1}>
            <CalendarIcon sx={{ fontSize: '16px', color: '#798498' }} />
            <Typography variant="body2" fontWeight="600" color={grayFontColor}>
              {startDate} - {endDate}
            </Typography>
          </Box>
        )}
      </Box>
      <Box display="flex">
        <Typography
          display={isSmallScreen ? 'none' : 'block'}
          variant="body2"
          fontWeight="600"
          color={grayFontColor}
          sx={{ writingMode: 'vertical-lr', transform: 'rotate(-180deg)', paddingTop: ' 17px', paddingLeft: '13px' }}
        >
          Activity Count
        </Typography>
        <Bar id="bar-graph-canvas" data={data} options={options} />
      </Box>

      <Typography mt={1.1} pl={isSmallScreen ? 0 : 11} variant="body2" fontWeight="600" color={grayFontColor}>
        Sustainable Development Goals (SDG)
      </Typography>
    </div>
  );
};

export default BarChartComponent;
