import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

interface categoryState {
  loading: null | boolean;
  success: null | boolean;
  error: null | string;
}

export const initialState: categoryState = {
  loading: null,
  success: null,
  error: null,
};

export const fetchAllCategoriesSubcategories = createAsyncThunk<any, void, { rejectValue: string }>(
  'api/fetchCategoriesSubcategories',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<any>('api/category/subcategories');
      localStorage.setItem('fetchAllCategories', JSON.stringify(response?.data));
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const fetchAllCategoriesSubcategoriesUnauthorized = createAsyncThunk<any, void, { rejectValue: string }>(
  'api/fetchCategoriesSubcategoriesUnautorized',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<any>('api/unauthorized/category/subcategories');
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const makeFavoriteCategory = createAsyncThunk<any, { favoriteCategories?: any }, { rejectValue: string }>(
  'api/makeFavoriteCategory',
  async ({ favoriteCategories }, { rejectWithValue }) => {
    try {
      const response = await axios.post<any>('api/favorite-categories', { favoriteCategories });
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const categorySlice = createSlice({
  name: 'categorySettings',
  initialState: initialState as categoryState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchAllCategoriesSubcategories.pending, state => {
      state.loading = true;
      state.success = null;
      state.error = null;
    });

    builder.addCase(fetchAllCategoriesSubcategories.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = null;
    });

    builder.addCase(fetchAllCategoriesSubcategories.rejected, (state, action) => {
      state.loading = null;
      state.error = action.error.message;
      state.success = null;
    });
    builder.addCase(fetchAllCategoriesSubcategoriesUnauthorized.pending, state => {
      state.loading = true;
      state.success = null;
      state.error = null;
    });

    builder.addCase(fetchAllCategoriesSubcategoriesUnauthorized.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = null;
    });

    builder.addCase(fetchAllCategoriesSubcategoriesUnauthorized.rejected, (state, action) => {
      state.loading = null;
      state.error = action.error.message;
      state.success = null;
    });

    builder.addCase(makeFavoriteCategory.pending, state => {
      state.loading = true;
      state.success = null;
      state.error = null;
    });

    builder.addCase(makeFavoriteCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = null;
    });

    builder.addCase(makeFavoriteCategory.rejected, (state, action) => {
      state.loading = null;
      state.error = action.error.message;
      state.success = null;
    });
  },
});

export default categorySlice.reducer;
