import { useAppSelector } from 'app/config/store';
import React, { useState, useEffect } from 'react';
import { GoogleMap, useLoadScript, Marker, OverlayView, OverlayViewF } from '@react-google-maps/api';
import { Link, useNavigate } from 'react-router-dom';
import { apiKeys } from 'app/utils/data/constants/api-keys';
import { Box, Typography, CircularProgress, useTheme } from '@mui/material';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import './map-events-page.scss';

const renderBgColor = (category: any) => {
  switch (category) {
    case 'LATEST':
      return '#F9CB00';
    case 'UPCOMING':
      return '#DC031E';
    case 'ONGOING':
    case 'RUNNING':
      return '#22e1d3';
    case 'COMPLETED':
    case 'ENDED':
      return '#787EFE';
    default:
      return '#FFF';
  }
};

const CustomMarkerComponent = ({ timelineCategory, eventType, id, text }) => (
  <Link
    className="link-container"
    key={id}
    to={eventType === 'Activity' ? `/activity/${id}` : `/petition/${id}`}
    style={{
      position: 'relative',
      background: renderBgColor(timelineCategory),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #fff',
      width: 40,
      height: 40,
      borderRadius: '50%',
    }}
  >
    <img style={{ display: 'block', width: '66%' }} src="content/images/logos/app-icon.png" alt="App Icon" />
    <Box className="hover-box" position="absolute">
      <Typography component="span" variant="body2">
        {text?.split(' ').length > 5 ? `${text?.split(' ')?.slice(0, 5)?.join(' ')}...` : text}
      </Typography>
    </Box>
  </Link>
);

const getPixelPositionOffset = (width, height) => ({
  x: -(width / 2),
  y: -(height / 2),
});

const MapEventsPage = () => {
  const activities = useAppSelector(state => state.filters.mapResults);
  const [isLocationLoaded, setIsLocationLoaded] = useState(false);
  const [location, setLocation] = useState({ lat: 0, lng: 0 });
  const [selectedLabel, setSelectedLabel] = useState<string>('');
  const [filteredActivities, setFilteredActivities] = useState<any>([]);
  const [loading, setLoading] = useState(true); // New loading state

  useEffect(() => {
    const handleLocationSuccess = position => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      setLocation({ lat: latitude, lng: longitude });
      localStorage.setItem('latitude', latitude.toString());
      localStorage.setItem('longitude', longitude.toString());
    };

    const handleLocationError = error => {
      alert('Error fetching location: ' + error.message);
    };

    if ('geolocation' in navigator) {
      setIsLocationLoaded(true);
      navigator.geolocation.getCurrentPosition(handleLocationSuccess, handleLocationError);
    } else {
      alert('Location services are not available.');
    }
  }, [isLocationLoaded]);

  useEffect(() => {
    setLoading(true);

    let filtered = activities;

    if (selectedLabel) {
      filtered = activities.filter(activity => {
        switch (selectedLabel) {
          case 'Completed':
            return activity.timelineCategory === 'COMPLETED' || activity.timelineCategory === 'ENDED';
          case 'Ongoing':
            return activity.timelineCategory === 'ONGOING' || activity.timelineCategory === 'RUNNING';
          case 'Petition':
            return activity?.eventType === 'Petition';
          default:
            return activity.timelineCategory === selectedLabel.toUpperCase();
        }
      });
    }

    setFilteredActivities(filtered);
    setLoading(false);
  }, [activities, selectedLabel]);

  console.log('filteredActivities', filteredActivities);
  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: apiKeys.GMAP_API_Key,
  });

  const navigate = useNavigate();
  const zoom = 12;
  const width = '100%';
  const height = '100vh';
  const center = {
    lat: parseFloat(localStorage.getItem('latitude')),
    lng: parseFloat(localStorage.getItem('longitude')),
  };

  const handleCloseButtonClick = () => {
    navigate('/', { state: { activeMenu: 'nearme' } });
  };

  if (!isLoaded || loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }

  const mapContainerStyle = {
    width: width,
    height: height,
    borderRadius: '16px',
  };

  const labels = [
    { id: 1, name: 'Latest', color: '#F9CB00' },
    { id: 2, name: 'Upcoming', color: '#DC031E' },
    { id: 3, name: 'Ongoing', color: '#22e1d3' },
    { id: 4, name: 'Completed', color: '#787EFE' },
  ];
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <div className="mx-4 py-2" style={{ height: '100vh', scrollbarWidth: 'none' }}>
      <div className="d-flex justify-content-start">
        <div onClick={handleCloseButtonClick}>
          <ArrowCircleLeftIcon sx={{ cursor: 'pointer', color: isDarkMode ? '#FFCE00' : '#775fff', borderRadius: '50%', fontSize: '25px' }} />
        </div>
      </div>
      <GoogleMap zoom={zoom} center={location} mapContainerStyle={mapContainerStyle}>
        <Box
          display="flex"
          flexDirection="column"
          gap="8px"
          position="absolute"
          width={166}
          bottom={50}
          left={16}
          bgcolor="#fff"
          p={2.4}
          borderRadius={2}
        >
          {labels.map(label => (
            <Box
              key={label.id}
              display="flex"
              alignItems="center"
              gap={1.2}
              sx={{ cursor: 'pointer' }}
              onClick={() => setSelectedLabel(label.name)}
            >
              <Box bgcolor={label.color} height={16} width={16} borderRadius="1px"></Box>
              <Typography component="span" variant="body2" sx={{ fontWeight: selectedLabel === label.name ? 'bold' : 'normal' }}>
                {label.name}
              </Typography>
            </Box>
          ))}
        </Box>

        <Marker position={{ lat: parseFloat(localStorage.getItem('latitude')), lng: parseFloat(localStorage.getItem('longitude')) }} />

        {filteredActivities.map((markerData, index) => {
          const latitude = parseFloat(markerData.latitude);
          const longitude = parseFloat(markerData.longitude);

          if (isNaN(latitude) || isNaN(longitude)) {
            return null;
          }
          console.log('latitude', markerData.latitude, markerData?.longitude);
          return (
            <OverlayViewF
              key={index}
              position={{ lat: latitude, lng: longitude }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              getPixelPositionOffset={getPixelPositionOffset}
            >
              <CustomMarkerComponent
                timelineCategory={markerData.timelineCategory}
                eventType={markerData.eventType}
                id={markerData.id}
                text={markerData.title}
              />
            </OverlayViewF>
          );
        })}
      </GoogleMap>
    </div>
  );
};

export default MapEventsPage;
