import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  checkoutSession: null,
  checkoutSessionLoading: false,
  checkoutSessionSuccess: false,
  checkoutSessionError: null,

  paymentDetails: null,
  paymentLoading: false,
  paymentSuccess: false,
  paymentError: null,
  transactionHistory: null,

  transactionHistoryLoading: false,
  transactionHistorySuccess: false,
  transactionHistoryError: null,
};

export const fetchPaymentDetails = createAsyncThunk<
  any,
  { userId: number },
  {
    rejectValue: string;
  }
>('payment/fetchPaymentDetails', async ({ userId }, thunkAPI) => {
  try {
    const response = await axios.get(`/api/payment/details/${userId}`);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch payment details');
  }
});

export const createCheckoutSession = createAsyncThunk<
  any,
  { activityId: number; userId: number; priceId: string },
  {
    rejectValue: string;
  }
>('payment/createCheckoutSession', async ({ activityId, userId, priceId }, thunkAPI) => {
  try {
    const response = await axios.post(`/api/create-checkout-session/${activityId}/${userId}/${priceId}`);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to create checkout session');
  }
});

export const fetchTransactionHistory = createAsyncThunk<any, void, { rejectValue: string }>(
  'payment/fetchTransactionHistory',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`/api/payment-service/history`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch transaction history');
    }
  }
);

// Creating the payment slice
const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchPaymentDetails.pending, state => {
        state.paymentLoading = true;
      })
      .addCase(fetchPaymentDetails.fulfilled, (state, action) => {
        state.paymentLoading = false;
        state.paymentSuccess = true;
        state.paymentDetails = action.payload;
      })
      .addCase(fetchPaymentDetails.rejected, (state, action) => {
        state.paymentLoading = false;
        state.paymentError = action.payload;
      })

      .addCase(createCheckoutSession.pending, state => {
        state.checkoutSessionLoading = true;
        state.checkoutSessionSuccess = false;
        state.checkoutSessionError = null;
      })
      .addCase(createCheckoutSession.fulfilled, (state, action) => {
        state.checkoutSessionLoading = false;
        state.checkoutSessionSuccess = true;
        state.checkoutSessionError = null;
        state.checkoutSession = action.payload;
      })
      .addCase(createCheckoutSession.rejected, (state, action) => {
        state.checkoutSessionLoading = false;
        state.checkoutSessionSuccess = false;
        state.checkoutSessionError = action.payload;
        state.checkoutSession = null;
      })

      .addCase(fetchTransactionHistory.pending, state => {
        state.transactionHistoryLoading = true;
      })
      .addCase(fetchTransactionHistory.fulfilled, (state, action) => {
        state.transactionHistoryLoading = false;
        state.transactionHistorySuccess = true;
        state.transactionHistory = action.payload;
        // state.transactionHistory = [
        //   {
        //     transactionId: null,
        //     paymentDate: '2024-03-19T09:05:20.356173Z',
        //     serviceType: null,
        //     totalAmount: 10,
        //     invoiceId: '',
        //     productId: 'prod_PbuxTaXPkR9Hup',
        //     productLabel: 'Impact Report for Organization',
        //     paymentStatus: true,
        //   },
        // ];
      })
      .addCase(fetchTransactionHistory.rejected, (state, action) => {
        state.transactionHistoryLoading = false;
        state.transactionHistoryError = action.payload;
      });
  },
});

export default paymentSlice.reducer;
