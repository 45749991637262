import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from '@mui/material';
import { useAppDispatch } from 'app/config/store';
import { deleteOrganization, setSelectedOrgId } from 'app/shared/reducers/settings/settings-organization';
import { fetchUserGrantedOrganizations } from 'app/shared/reducers/organization';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoaderIcon } from 'react-hot-toast';

type Props = {
  open: boolean;
  setFeatureOpen: Dispatch<SetStateAction<boolean>>;
  values?: any;
  agreeAction: () => void;
  paymentLoading?: boolean;
};

export default function FeatureDialog({ values, open, setFeatureOpen, agreeAction, paymentLoading }: Props) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  return (
    <>
      <Dialog
        open={open}
        onClose={() => setFeatureOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <style>
          {
            `.css-rsdpij-MuiPaper-root-MuiDialog-paper { border-radius: 15px;}`
          }
          {
            `.css-przs3r-MuiPaper-root-MuiDialog-paper { border-radius: 15px;
            }`
          }
        </style>
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent sx={{ fontWeight: '400', fontSize: '1 rem', color: isDarkMode ? '#FFFFFF' : '#000' }}>
          {
            // Use a ternary operator or a function to return the correct message
            values?.productName === 'Featured Petition'
              ? 'Featuring Petition is a paid service. Do you wish to continue?'
              : values?.productName === 'Featured Activity'
                ? 'Featuring Activity is a paid service. Do you wish to continue?'
                : values?.productName === 'Featured Blog'
                  ? 'Featuring Blog is a paid service. Do you wish to continue?'
                  : 'This service is paid. Do you wish to continue?'
          }
          {/* <DialogContentText id="alert-dialog-description">{values}</DialogContentText> */}
        </DialogContent>
        <DialogActions sx={{ paddingRight: '24px;' }}>
          <Button
            onClick={() => setFeatureOpen(false)}
            sx={{
            color: isDarkMode ? '#FFCE00' : '#674BFF',
 
            '&:hover': {
              color: isDarkMode ? '#FFCE00' : '#674BFF',
            },
          }}
          >
            Cancel
          </Button>
          <Button
            onClick={agreeAction}
            autoFocus
            endIcon={paymentLoading ? <LoaderIcon color="primary.light" /> : null}
            disabled={paymentLoading}
              sx={{
            backgroundColor: isDarkMode ? '#FFCE00' : '#674BFF',
          color: isDarkMode ? "black":'#ffff',
            '&:hover': {
              backgroundColor: isDarkMode ? '#FFCE00' : '#674BFF',
             
            },
          }}
          variant="contained"
          >
            Proceed ${values?.totalPrice}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
