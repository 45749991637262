import React from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

interface LinearProgressBarProps {
  currentValue: number;
  maxValue: number;
}
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.mode === 'light' ? 'rgba(193, 181, 255, 0.3)' : 'rgba(193, 181, 255, 0.3)',
    border: '1px solid #bfbfbf',
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundImage: 'linear-gradient(270deg, #2000CF 0%, #C1B5FF 100%)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0% 0%',
    backgroundColor: 'transparent',
  },
}));

const LinearProgressBar = ({ currentValue, maxValue }: LinearProgressBarProps) => {
  const percentage = (currentValue / maxValue) * 100;

  return <BorderLinearProgress variant="determinate" value={percentage} />;
};

export default LinearProgressBar;
