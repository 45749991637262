import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const UpVoteFilled = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="18.933" height="17.526" viewBox="0 0 18.933 17.526">
    <path
      id="arrow-up_24x24_1x"
      data-name="arrow-up(24x24)@1x"
      d="M15.467,3l9.467,9.467H18.148v8.06H12.781v-8.06H6Z"
      transform="translate(-6 -3)"
      fill="#674bff"
    />
  </svg>,
  'UpVoteFilled'
);

export default UpVoteFilled;
