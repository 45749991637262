import React, { useEffect, useState, useContext } from 'react';

import { Box, Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { logout, logoutSession, unregisterDevice } from 'app/shared/reducers/authentication';
import {
  clearExistingActivities,
  clearSearchKeyword,
  clearSearhedActivities,
  updateFilterProperties,
} from 'app/shared/reducers/activity-filter';
import { Storage } from 'react-jhipster';
import { clearUserPreferences } from 'app/shared/reducers/user-preferences';
import Cookies from 'js-cookie';
import { clearPhoneVerification } from 'app/shared/reducers/phone-verification';
import { toast } from 'react-hot-toast';
import WebSocketContext from 'app/components/common/cards/chat-card/WebSocketContext';
const LogoutModal = ({ handleAuthModalClose, handleSearchClear }: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const [isYesButtonDisabled, setYesButtonDisabled] = useState(false);

  const updatedProperties = {
    nearMe: null,
    country: null,
    state: null,
    city: null,
    organizationId: null,
    categoryId: null,
    subCategoryId: null,
    remoteEvents: false,
    petition: false,
    mySelf: false,
  };

  const handleClose = () => {
    handleAuthModalClose();
  };
  const { disconnect } = useContext(WebSocketContext);

  const handleLogoutConfirm = async () => {
    setYesButtonDisabled(true);
    disconnect();
    const fcmTokenId = Storage.local.get('firebaseToken');
    if (fcmTokenId !== undefined) {
      try {
        await dispatch(unregisterDevice({ deviceId: localStorage.getItem('randomDeviceId'), fcmTokenId }));
        Cookies.remove('jhi-authenticationToken');
        dispatch(clearExistingActivities());
        localStorage.removeItem('firebaseToken');
      } catch (error) {
        console.error('Error unregistering device:', error);
      }
    }

    navigate({
      pathname: window.location.pathname,
      search: '',
    });

    dispatch(logout());
    dispatch(clearSearchKeyword());
    dispatch(clearExistingActivities());
    dispatch(updateFilterProperties(updatedProperties));
    dispatch(clearSearhedActivities());
    dispatch(clearUserPreferences());
    dispatch(clearPhoneVerification());
    Cookies.remove('jhi-authenticationToken');
    localStorage.clear();
    sessionStorage.clear();
    handleSearchClear();
    handleClose();
  };

  useEffect(() => {
    if (!isAuthenticated) {
      handleClose();
      navigate('/');
    }
  }, [isAuthenticated]);

  return (
    <>
      <style>
        {`.MuiBox-root.css-1je311u{
        display:none !important
      }`}
        {`.css-pdp0uf-MuiStack-root { border-radius: 15px !important;
        }`}
        {`.css-rsdpij-MuiPaper-root-MuiDialog-paper {
    border-radius: 15px !important;}`}
      </style>
      <Box sx={{ padding: '32px', maxWidth: '600px' }} textAlign={'center'}>
        <h5 className="mb-5">Are you sure you want to logout?</h5>
        <div className="d-flex justify-content-center ">
          <Button
            sx={{
              textTransform: 'capitalize',
              backgroundColor: 'primary.light',
              height: '30px',
              fontSize: '14px',
              marginRight: '10px',
              boxShadow: '0px 3px 6px  rgb(0,0,0,0.16) ',
              color: 'common.white',
              '&:hover': {
                color: '#fffff',
                backgroundColor: 'primary.light',
                textTransform: 'capitalize',
              },
            }}
            disabled={isYesButtonDisabled}
            onClick={handleLogoutConfirm}
          >
            Yes
          </Button>

          <Button
            sx={{
              textTransform: 'capitalize',
              backgroundColor: 'primary.light',
              height: '30px',
              boxShadow: '0px 3px 6px rgb(0,0,0,0.16) ',
              color: 'common.white',
              fontSize: '14px',
              '&:hover': {
                color: '#fffff',
                backgroundColor: 'primary.light',
                textTransform: 'capitalize',
              },
            }}
            onClick={handleClose}
          >
            No
          </Button>
        </div>
      </Box>
    </>
  );
};

export default LogoutModal;
