import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const EventsIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="17.075" height="15.523" viewBox="0 0 17.075 15.523">
    <g id="noun_Party_3679723" transform="translate(-4.999 -7.272)">
      <path
        id="Path_3219"
        data-name="Path 3219"
        d="M5.056,24.508c-.287.7.57,1.482,1.567,1.888a3.674,3.674,0,0,0,1.352.3.5.5,0,0,0,.072,0c.7-.02.939-.348,1.018-.54.174-.43-.089-.949-.686-1.4l1.011-2.472a2.458,2.458,0,0,0,2.23-1.537l1.919-4.682,1.919,4.682a2.458,2.458,0,0,0,2.23,1.537L18.7,24.75c-.6.451-.861.97-.686,1.4a1.066,1.066,0,0,0,1.083.543,3.692,3.692,0,0,0,1.363-.3,3.6,3.6,0,0,0,1.219-.789c.485-.509.423-.9.345-1.1-.174-.427-.727-.615-1.472-.516l-1.011-2.469a2.491,2.491,0,0,0,.512-2.664l-1.236-3.012-1-2.445a.344.344,0,0,0-.447-.184l-3.832,1.569L9.707,13.218a.35.35,0,0,0-.263,0,.342.342,0,0,0-.181.184L7.026,18.859a2.492,2.492,0,0,0,.512,2.664L6.531,23.992C5.783,23.893,5.23,24.077,5.056,24.508ZM17.312,13.98l.741,1.81-3.326,1.363-.744-1.81Zm-7.547,0,3.33,1.363-.744,1.81L9.024,15.789Z"
        transform="translate(0 -3.898)"
        fill="#676e7b"
      />
      <path
        id="Path_3220"
        data-name="Path 3220"
        d="M29.687,8.848V7.613a.341.341,0,1,0-.683,0V8.848a.341.341,0,0,0,.683,0Z"
        transform="translate(-15.807)"
        fill="#676e7b"
      />
      <path
        id="Path_3221"
        data-name="Path 3221"
        d="M24.139,10.756a.341.341,0,0,0,.284-.53L23.742,9.2a.341.341,0,0,0-.57.377l.681,1.029A.341.341,0,0,0,24.139,10.756Z"
        transform="translate(-11.93 -1.167)"
        fill="#676e7b"
      />
      <path
        id="Path_3222"
        data-name="Path 3222"
        d="M33.239,10.756a.341.341,0,0,0,.285-.153L34.2,9.574a.342.342,0,0,0-.57-.377l-.681,1.029a.342.342,0,0,0,.284.53Z"
        transform="translate(-18.371 -1.167)"
        fill="#676e7b"
      />
    </g>
  </svg>,

  'EventsIcon'
);
export default EventsIcon;
