import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const AnimalRescueIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="16.057" height="19.826" viewBox="0 0 16.057 19.826">
    <g id="Group_2505" data-name="Group 2505" transform="translate(-274.908 -8121.407)">
      <path
        id="Path_99"
        data-name="Path 99"
        d="M17.459,15.373a7.986,7.986,0,0,1,1.478-1.765c.371-.338-.291-3.184,2.114-3.87.06.1.847,1.243,1.144,1.776a.5.5,0,0,0,.586.275,11.321,11.321,0,0,1,0-1.667.729.729,0,0,1,.639-.664,7.857,7.857,0,0,0,2.841-1.133A2.659,2.659,0,0,0,27.5,6.647a5.508,5.508,0,0,0,.022-.756,4.755,4.755,0,0,0,.055-.717c-.049-.459-.239-.6-.69-.586a6.335,6.335,0,0,1-1.235.006,7.548,7.548,0,0,1-1.258-.43.185.185,0,0,1-.1-.112.9.9,0,0,0-.88-.684,1.1,1.1,0,0,1-.7-.308,2.338,2.338,0,0,0-1.63-.56,6.31,6.31,0,0,0-2.1.287,3.5,3.5,0,0,0-2.422,2.962l-.06.435a3.1,3.1,0,0,1,1.68,1.9c.105.337.148.44.628.587s2.339.2,2.278-.65a6.353,6.353,0,0,0-1.533-3.6.345.345,0,1,1,.515-.46A6.732,6.732,0,0,1,21.824,7.8a3.836,3.836,0,0,1,.009.577.843.843,0,0,1-.329.65,2.432,2.432,0,0,1-1.369.54,4.229,4.229,0,0,1-1.961-.285.891.891,0,0,1-.568-.62,3.111,3.111,0,0,0-1.146-1.51q-.009.2,0,.41a5.694,5.694,0,0,1-1.5,4.41,28.211,28.211,0,0,0-3.446,5.168,15.434,15.434,0,0,0,3.911,3.431,20.226,20.226,0,0,1,2.033-5.2ZM22.987,5.2a.364.364,0,1,1-.349.363.356.356,0,0,1,.349-.363Z"
        transform="translate(263.393 8118.912)"
        fill="#676e7b"
      />
      <path
        id="Path_100"
        data-name="Path 100"
        d="M39.565,48.434a1.069,1.069,0,0,1,.716-1.353,5.821,5.821,0,0,1,.87-.263.76.76,0,0,1,.3.028c.447.079.651-.051.763-.49a.292.292,0,0,1,.07-.15c.325-.265.308-.683.456-1.025.028-.064-.037-.2-.1-.262a1.02,1.02,0,0,0-.3-.2,1.3,1.3,0,0,1-.639-.723c-.121-.313-.254-.621-.37-.936a.727.727,0,0,0-.578-.494c-.4-.076-.812-.142-1.22-.188a.669.669,0,0,1-.552-.341c-.246-.414-.508-.818-.765-1.227-.026-.042-.055-.081-.088-.13a1.136,1.136,0,0,0-.7.782,5.723,5.723,0,0,0-.187,1.29,1.017,1.017,0,0,1-.4.864A6.282,6.282,0,0,0,35.2,45.987a15.035,15.035,0,0,0-.975,3.147,15.35,15.35,0,0,0,6.143,1.7c-.025-.066-.046-.128-.072-.188A10.13,10.13,0,0,1,39.565,48.434Zm.77-4.747a.3.3,0,1,1-.212.088A.3.3,0,0,1,40.335,43.687Z"
        transform="translate(246.24 8090.396)"
        fill="#676e7b"
      />
    </g>
  </svg>,

  'AnimalRescueIcon'
);
export default AnimalRescueIcon;
