import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const HappyemojiIcons = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="512.009" height="512.008" viewBox="0 0 512.009 512.008">
    <g id="happy-emoji-svgrepo-com" transform="translate(0)">
      <circle id="Ellipse_284" data-name="Ellipse 284" cx="256.004" cy="256.004" r="256.004" transform="translate(0 0)" fill="#f7b239" />
      <path
        id="Path_4647"
        data-name="Path 4647"
        d="M121.5,390.5C29.407,298.407,22.15,153.608,99.723,53.2A258.273,258.273,0,0,0,74.98,74.981c-99.974,99.974-99.974,262.065,0,362.038s262.065,99.974,362.038,0A258.427,258.427,0,0,0,458.8,412.276C358.392,489.85,213.593,482.593,121.5,390.5Z"
        fill="#e09b2d"
      />
      <path
        id="Path_4648"
        data-name="Path 4648"
        d="M256,316.616a167.575,167.575,0,0,1-118.865-49.158,9.488,9.488,0,1,1,13.418-13.418c58.144,58.144,152.75,58.143,210.894,0a9.488,9.488,0,0,1,13.418,13.418A167.564,167.564,0,0,1,256,316.616Z"
        transform="translate(7 36)"
        fill="#4d4d4d"
      />
      <path
        id="Path_4649"
        data-name="Path 4649"
        d="M256,357.764a168.453,168.453,0,0,1-45.475-6.255,9.489,9.489,0,0,1,5.123-18.273,149.455,149.455,0,0,0,80.706,0,9.489,9.489,0,1,1,5.123,18.273A168.552,168.552,0,0,1,256,357.764Z"
        transform="translate(7 36)"
        fill="#e09b2d"
      />
      <g id="Group_6411" data-name="Group 6411">
        <path
          id="Path_4650"
          data-name="Path 4650"
          d="M190.8,200.674a9.489,9.489,0,0,1-9.489-9.489,46.186,46.186,0,0,0-92.372,0,9.489,9.489,0,0,1-18.978,0,65.163,65.163,0,1,1,130.326,0A9.485,9.485,0,0,1,190.8,200.674Z"
          fill="#4d4d4d"
        />
        <path
          id="Path_4651"
          data-name="Path 4651"
          d="M432.546,200.674a9.489,9.489,0,0,1-9.489-9.489,46.186,46.186,0,1,0-92.372,0,9.489,9.489,0,0,1-18.978,0,65.163,65.163,0,1,1,130.326,0A9.487,9.487,0,0,1,432.546,200.674Z"
          fill="#4d4d4d"
        />
      </g>
      <g id="Group_6412" data-name="Group 6412" transform="translate(7 36)">
        <circle
          id="Ellipse_285"
          data-name="Ellipse 285"
          cx="25.013"
          cy="25.013"
          r="25.013"
          transform="translate(69.798 218.302)"
          fill="#f95428"
        />
        <circle
          id="Ellipse_286"
          data-name="Ellipse 286"
          cx="25.013"
          cy="25.013"
          r="25.013"
          transform="translate(392.172 218.302)"
          fill="#f95428"
        />
      </g>
    </g>
  </svg>,
  'HappyemojiIcons'
);
export default HappyemojiIcons;
