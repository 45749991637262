import { Button, CardActions, CardContent, Divider, Typography, useTheme } from '@mui/material';
import React from 'react';
import { Card } from 'reactstrap';
import SettingsOrgMembers from './settings-org-members';
import SettingsOrgInvitations from './settings-org-invitations';
import SettingsOrgInvite from './settings-org-invite';

export type settingOrgPermssionProps = {
  hasPermission: boolean;
};
const SettingsOrgrightcard = ({ hasPermission }: settingOrgPermssionProps) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Card
      sx={{ minWidth: '100%', minHeight: '100%' }}
      style={{ borderRadius: '15px', border: 'none', background: theme.palette.mode === 'dark' ? '#2C2C38' : '' }}
    >
      <CardContent>
        <SettingsOrgMembers hasPermission={hasPermission} />
        {hasPermission && <SettingsOrgInvitations hasPermission={hasPermission} />}
      </CardContent>
      {hasPermission && (
        <CardActions sx={{ px: 2 }}>
          <SettingsOrgInvite hasPermission={hasPermission} />
        </CardActions>
      )}
    </Card>
  );
};

export default SettingsOrgrightcard;
