import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import AwesomeIcon from 'app/components/icons/activity-rating-icons/awesome-icon';
import MehIcon from 'app/components/icons/activity-rating-icons/meh-icon';
import BadIcon from 'app/components/icons/activity-rating-icons/bad-icon';
import TerribleIcon from 'app/components/icons/activity-rating-icons/terrible-icon';
import GoodIcon from 'app/components/icons/activity-rating-icons/good-icon';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Carousel } from 'react-responsive-carousel';
import DeleteIcon from '@mui/icons-material/Delete';
import { singleImageType } from 'app/shared/reducers/image-source';
import { useDropzone } from 'react-dropzone';
import { useFormik } from 'formik';
import ActivityRatingImageUpload from 'app/components/ui/activity-details-section/activity-rating/activity-rating-image-upload';
import SelectedAwesomeIcon from 'app/components/icons/activity-rating-icons/selected-awesome-icon';
import SelectedGoodIcon from 'app/components/icons/activity-rating-icons/selected-good-icon';
import SelectedMehIcon from 'app/components/icons/activity-rating-icons/selected-meh-icon';
import SelectedBadIcon from 'app/components/icons/activity-rating-icons/selected-bad-icon';
import SelectedTerribleIcon from 'app/components/icons/activity-rating-icons/selected-terrible-icon';
import { rateActivity } from 'app/shared/reducers/activity/activity-rating';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import toast from 'react-hot-toast';
import './activity-rating-modal.scss';
import { submitActivityImages } from 'app/shared/reducers/activity/activity-rating-image-upload';
import { filterActivities } from 'app/shared/reducers/activity-filter';

const initialValues = {
  multimedia: [],
};

function ActivityRatingModal({ open, handleClose, onClose, activity }) {
  const dispatch = useAppDispatch();
  const [multiMedia, setMultiMedia] = useState<{ id: number }[]>([]);
  const formik = useFormik({
    initialValues,
    onSubmit: () => {
      handleRatingSubmit();
    },
  });

  const acceptingFiles = {
    'image/jpeg': [],
    'image/png': [],
    'image/jpg': [],
    'video/mp4': [],
    'video/webm': [],
    'video/ogg': [],
  };

  const [files, setFiles] = useState<File[]>();
  const theme = useTheme();

  const { filter, eventType } = useAppSelector(state => state.filters.RequestBody);
  const [localMultimedia, setLocalMultimedia] = useState<singleImageType[]>([]);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isRatingSelected, setIsRatingSelected] = useState(false);
  const [selectedRatings, setSelectedRatings] = useState({
    experience: null,
    punctuality: null,
    team: null,
  });

  const [clickedRatings, setClickedRatings] = useState({
    experience: null,
    punctuality: null,
    team: null,
  });

  const handleRatingClick = (question, value) => {
    setSelectedRatings(prevState => ({
      ...prevState,
      [question]: value,
    }));

    setClickedRatings(prevState => ({
      ...prevState,
      [question]: value,
    }));
  };

  useEffect(() => {
    // Check if any rating is selected
    const anyRatingSelected = Object.values(clickedRatings).some(value => value !== null);
    setIsRatingSelected(anyRatingSelected);
  }, [clickedRatings]);

  const handleModalclose = () => {
    handleClose();
    setIsRatingSelected(false);
    setSelectedRatings({
      experience: null,
      punctuality: null,
      team: null,
    });

    setClickedRatings({
      experience: null,
      punctuality: null,
      team: null,
    });
  };
  const onDrop = useCallback((accFiles: File[], FileRejection: any) => {
    const acceptedFiles = accFiles.map(file => ({ file, errors: [] }));
    setFiles(acceptedFiles.map(entry => entry.file));
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptingFiles,
    multiple: true,
  });

  const handleImageClear = id => {
    const newMultiMedia = multiMedia.filter(media => media.id !== id);
    const newLocalMultiMedia = localMultimedia.filter(media => media.id !== id);

    setLocalMultimedia(newLocalMultiMedia);
    if (newMultiMedia?.length > 0) {
      setMultiMedia(newMultiMedia);
    }
  };

  const handleRatingSubmit = async () => {
    const requestBodyForRate = {
      activityId: activity.id,
      experienceRating: selectedRatings.experience || 0,
      punctualityRating: selectedRatings.punctuality || 0,
      teamRating: selectedRatings.team || 0,
    };

    const requestBodyForImages = {
      activityId: activity.id,
      imageId: multiMedia.map(item => item.id),
    };

    try {
      const responseRating = await dispatch(rateActivity(requestBodyForRate));
      if (responseRating.meta.requestStatus === 'fulfilled') {
        handleModalclose();
        const responseImages = await dispatch(submitActivityImages(requestBodyForImages));

        if (responseImages.meta.requestStatus === 'fulfilled') {
          toast.success('You have rated successfully', { id: 'rating-success' });
        }
      } else {
        toast.error(responseRating?.payload || 'An error occurred', { id: 'rating-error' });
      }
    } catch (error) {
      toast.error('An unexpected error occurred.', { id: 'rating-unexpected-error' });
    }
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: '15px',
          width: { lg: '50%' },
        },
      }}
      open={open}
      onClose={handleModalclose}
      fullScreen={fullScreen}
    >
      <style>
        {`.css-16q7ppr-MuiTypography-root {
          width:347px !important};
        } `}

        {`.css-tpyrhl-MuiTypography-root {
          width:347px !important};
        } `}
        {`.MuiTypography-root.MuiTypography-body1.css-tpyrhl-MuiTypography-root{
          width:347px !important};
        } `}
      </style>
      <Box
        sx={{
          position: 'relative',
          border: 'none',
          maxHeight: '95vh',
          overflowY: 'scroll',
          overflowX: 'hidden',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '&::-webkit-scrollbar-thumb': { backgroundColor: 'secondary.contrastText', height: '10px' },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <Grid item container justifyContent={'flex-end'}>
          <IconButton
            aria-label="close"
            onClick={handleModalclose}
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              zIndex: 1,
              color: '#000000',
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>

        <DialogContent sx={{ padding: '0px' }}>
          <Grid container justifyContent="center" alignItems="center">
            <Box paddingBottom={'3%'} textAlign={'center'} sx={{ position: 'relative' }}>
              <Box>
                <img
                  src="../content/images/rate-activity/rating-background-new.png"
                  alt="Rate activity"
                  style={{ height: '177px', width: '100%', objectFit: 'cover' }}
                />
                <Box
                  sx={{
                    height: '100px',
                    width: '100px',
                    position: 'absolute',
                    top: '127px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                  }}
                >
                  <Carousel showThumbs={false} showStatus={false} showArrows={true} showIndicators={false} useKeyboardArrows={true}>
                    {activity?.eventGallery?.length > 0 &&
                      activity?.eventGallery?.map((item, index) => (
                        <div key={item.link}>
                          <img src={item.link} alt={`slide${index + 1}`} style={{ height: '100px', width: '100px', objectFit: 'fill' }} />
                        </div>
                      ))}
                  </Carousel>
                </Box>
              </Box>
              <Typography
                className="px-5"
                sx={{ fontWeight: '600', fontSize: '20px', paddingTop: 10, paddingBottom: 2, color: theme.palette.secondary.main }}
              >
                {activity.title}
              </Typography>
            </Box>
            <Box className="p-2">
              <Grid container justifyContent={'center'} paddingBottom={1}>
                <Typography sx={{ color: theme.textColor.heading, width: '347px' }}>How was your experience at the event ?</Typography>
              </Grid>
              <Grid container justifyContent="center" spacing={1} paddingBottom={4} className=" w-dth">
                <Grid item>
                  <div style={{ textAlign: 'center', width: '4rem' }}>
                    {clickedRatings.experience === 5 ? (
                      <div>
                        <SelectedAwesomeIcon style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: theme.palette.primary.light }}>Awesome</Typography>
                      </div>
                    ) : (
                      <div>
                        <AwesomeIcon onClick={() => handleRatingClick('experience', 5)} style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: '#7F8286' }}>Awesome</Typography>
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item>
                  <div style={{ textAlign: 'center', width: '4rem' }}>
                    {clickedRatings.experience === 4 ? (
                      <div>
                        <SelectedGoodIcon style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: theme.palette.primary.light }}>Good</Typography>
                      </div>
                    ) : (
                      <div>
                        <GoodIcon onClick={() => handleRatingClick('experience', 4)} style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: '#7F8286' }}>Good</Typography>
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item>
                  <div style={{ textAlign: 'center', width: '4rem' }}>
                    {clickedRatings.experience === 3 ? (
                      <div>
                        <SelectedMehIcon style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: theme.palette.primary.light }}>Meh</Typography>
                      </div>
                    ) : (
                      <div>
                        <MehIcon onClick={() => handleRatingClick('experience', 3)} style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: '#7F8286' }}>Meh</Typography>
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item>
                  <div style={{ textAlign: 'center', width: '4rem' }}>
                    {clickedRatings.experience === 2 ? (
                      <div>
                        <SelectedBadIcon style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: theme.palette.primary.light }}>Bad</Typography>
                      </div>
                    ) : (
                      <div>
                        <BadIcon onClick={() => handleRatingClick('experience', 2)} style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: '#7F8286' }}>Bad</Typography>
                      </div>
                    )}
                  </div>
                </Grid>

                <Grid item>
                  <div style={{ textAlign: 'center', width: '4rem' }}>
                    {clickedRatings.experience === 1 ? (
                      <div>
                        <SelectedTerribleIcon style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: theme.palette.primary.light }}>Terrible</Typography>
                      </div>
                    ) : (
                      <div>
                        <TerribleIcon onClick={() => handleRatingClick('experience', 1)} style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: '#7F8286' }}>Terrible</Typography>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid item container justifyContent={'center'} paddingBottom={1}>
                <Typography sx={{ color: theme.textColor.heading, width: '347px' }}>
                  How would you rate the Planning & Execution of the event?
                </Typography>
              </Grid>
              <Grid container justifyContent="center" spacing={1} paddingBottom={4} className=" w-dth">
                <Grid item>
                  <div style={{ textAlign: 'center', width: '4rem' }}>
                    {clickedRatings.punctuality === 5 ? (
                      <div>
                        <SelectedAwesomeIcon style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: theme.palette.primary.light }}>Awesome</Typography>
                      </div>
                    ) : (
                      <div>
                        <AwesomeIcon onClick={() => handleRatingClick('punctuality', 5)} style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: '#7F8286' }}>Awesome</Typography>
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item>
                  <div style={{ textAlign: 'center', width: '4rem' }}>
                    {clickedRatings.punctuality === 4 ? (
                      <div>
                        <SelectedGoodIcon style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: theme.palette.primary.light }}>Good</Typography>
                      </div>
                    ) : (
                      <div>
                        <GoodIcon onClick={() => handleRatingClick('punctuality', 4)} style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: '#7F8286' }}>Good</Typography>
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item>
                  <div style={{ textAlign: 'center', width: '4rem' }}>
                    {clickedRatings.punctuality === 3 ? (
                      <div>
                        <SelectedMehIcon style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: theme.palette.primary.light }}>Meh</Typography>
                      </div>
                    ) : (
                      <div>
                        <MehIcon onClick={() => handleRatingClick('punctuality', 3)} style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: '#7F8286' }}>Meh</Typography>
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item>
                  <div style={{ textAlign: 'center', width: '4rem' }}>
                    {clickedRatings.punctuality === 2 ? (
                      <div>
                        <SelectedBadIcon style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: theme.palette.primary.light }}>Bad</Typography>
                      </div>
                    ) : (
                      <div>
                        <BadIcon onClick={() => handleRatingClick('punctuality', 2)} style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: '#7F8286' }}>Bad</Typography>
                      </div>
                    )}
                  </div>
                </Grid>

                <Grid item>
                  <div style={{ textAlign: 'center', width: '4rem' }}>
                    {clickedRatings.punctuality === 1 ? (
                      <div>
                        <SelectedTerribleIcon style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: theme.palette.primary.light }}>Terrible</Typography>
                      </div>
                    ) : (
                      <div>
                        <TerribleIcon onClick={() => handleRatingClick('punctuality', 1)} style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: '#7F8286' }}>Terrible</Typography>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>

              <Grid item container justifyContent={'center'} paddingBottom={1}>
                <Typography sx={{ color: theme.textColor.heading, width: '347px' }}>
                  Was there adequate communication and information provided by the organizer before and during the event?
                </Typography>
              </Grid>
              <Grid container justifyContent="center" spacing={1} paddingBottom={4} className=" w-dth">
                <Grid item>
                  <div style={{ textAlign: 'center', width: '4rem' }}>
                    {clickedRatings.team === 5 ? (
                      <div>
                        <SelectedAwesomeIcon style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: theme.palette.primary.light }}>Awesome</Typography>
                      </div>
                    ) : (
                      <div>
                        <AwesomeIcon onClick={() => handleRatingClick('team', 5)} style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: '#7F8286' }}>Awesome</Typography>
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item>
                  <div style={{ textAlign: 'center', width: '4rem' }}>
                    {clickedRatings.team === 4 ? (
                      <div>
                        <SelectedGoodIcon style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: theme.palette.primary.light }}>Good</Typography>
                      </div>
                    ) : (
                      <div>
                        <GoodIcon onClick={() => handleRatingClick('team', 4)} style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: '#7F8286' }}>Good</Typography>
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item>
                  <div style={{ textAlign: 'center', width: '4rem' }}>
                    {clickedRatings.team === 3 ? (
                      <div>
                        <SelectedMehIcon style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: theme.palette.primary.light }}>Meh</Typography>
                      </div>
                    ) : (
                      <div>
                        <MehIcon onClick={() => handleRatingClick('team', 3)} style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: '#7F8286' }}>Meh</Typography>
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item>
                  <div style={{ textAlign: 'center', width: '4rem' }}>
                    {clickedRatings.team === 2 ? (
                      <div>
                        <SelectedBadIcon style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: theme.palette.primary.light }}>Bad</Typography>
                      </div>
                    ) : (
                      <div>
                        <BadIcon onClick={() => handleRatingClick('team', 2)} style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: '#7F8286' }}>Bad</Typography>
                      </div>
                    )}
                  </div>
                </Grid>

                <Grid item>
                  <div style={{ textAlign: 'center', width: '4rem' }}>
                    {clickedRatings.team === 1 ? (
                      <div>
                        <SelectedTerribleIcon style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: theme.palette.primary.light }}>Terrible</Typography>
                      </div>
                    ) : (
                      <div>
                        <TerribleIcon onClick={() => handleRatingClick('team', 1)} style={{ cursor: 'pointer', fontSize: '2em' }} />
                        <Typography sx={{ fontSize: '14px', color: '#7F8286' }}>Terrible</Typography>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
              <Box>
                <Typography sx={{ color: theme.textColor.heading }} paddingTop={2} paddingBottom={2}>
                  Would you like to share some images/videos?
                </Typography>
                <ActivityRatingImageUpload formik={formik} multiMedia={multiMedia} setMultiMedia={setMultiMedia} />
              </Box>
            </Box>
          </Grid>
          <Grid container justifyContent={'flex-end'} paddingRight={'30px'} paddingBottom={'30px'}>
            <Button variant="contained" sx={{ borderRadius: '15px' }} onClick={handleRatingSubmit} disabled={!isRatingSelected}>
              <ArrowForwardIosIcon />
            </Button>
          </Grid>
        </DialogContent>
      </Box>
    </Dialog>
  );
}

export default ActivityRatingModal;
