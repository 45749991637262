import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

interface EventRequest {
  eventId: number;
  eventType: string;
}

export interface chatMessageState {
  chatResponse: {
    chatDetails: any[];
    chatConversations: any[];
  } | null;
  loading: boolean;
  error: string | null;
  success: boolean | null;
}

export const initialState: chatMessageState = {
  chatResponse: {
    chatDetails: [],
    chatConversations: [],
  },
  loading: false,
  error: null,
  success: false,
};

//get all the activity and latest chat details
export const getAllEvents = createAsyncThunk<any, void, { rejectValue: string }>(
  '/api/chats/getAllEvents',
  async (_, { rejectWithValue }) => {
    try {
      const axiosResponse = await axios.get<any[]>('/api/chats/getAllEvents');
      return axiosResponse.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const getAllEventsById = createAsyncThunk<any, EventRequest, { rejectValue: string }>(
  '/api/chats/getAllEventsWithId',
  async (eventRequest, { rejectWithValue }) => {
    try {
      const axiosResponse = await axios.post<any>('/api/chats/getAllEventsWithId', eventRequest);
      return axiosResponse.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const leftPanelSlice = createSlice({
  name: 'leftPanelReducer',
  initialState,
  reducers: {
    updateChatResponse(state, action: any) {
      if (state.chatResponse && Array.isArray(state.chatResponse.chatDetails)) {
        const eventId = action.payload.eventId;
        const chatDetail = state.chatResponse.chatDetails.find(item => item.event_id === eventId);

        if (chatDetail) {
          chatDetail.unreadMessageCount = (chatDetail.unreadMessageCount || 0) + 1;
          // chatDetail.eventCreationTime = new Date().toISOString();
        }
      }
    },
    deleteChatResponse(state, action: any) {
      console.log('delete first');
      if (state.chatResponse && Array.isArray(state.chatResponse.chatDetails)) {
        console.log('delete second');
        const eventId = action.payload.eventId;
        const chatDetail = state.chatResponse.chatDetails.find(item => item.event_id === eventId);

        if (chatDetail && chatDetail.unreadMessageCount > 0) {
          console.log('delete third');
          chatDetail.unreadMessageCount = chatDetail.unreadMessageCount - 1;
          // chatDetail.eventCreationTime = new Date().toISOString();
        }
      }
      return state;
    },
    removeChatResponse(state, action: any) {
      if (state.chatResponse && Array.isArray(state.chatResponse.chatDetails)) {
        const eventId = action.payload?.event_id;
        const chatDetail = state.chatResponse.chatDetails.find(item => item.event_id === eventId);

        if (chatDetail) {
          chatDetail.unreadMessageCount = 0;
        }
      }
      return state;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAllEvents.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getAllEvents.fulfilled, (state, action) => {
        state.chatResponse = action.payload;
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      .addCase(getAllEvents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'An error occurred';
        state.success = false;
      })
      .addCase(getAllEventsById.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getAllEventsById.fulfilled, (state, action) => {
        // If localStorage contains pinned chat details, move pinned chat to the top
        if (localStorage.getItem('dataKey')) {
          const keysJSON = localStorage.getItem('dataKey');
          const keys = JSON.parse(keysJSON);
          const object = action?.payload?.chatDetails?.find(item => item.pinTop === true);
          if (object) {
            // Remove the object from its current position
            const index = action?.payload?.chatDetails?.indexOf(object);
            if (index !== -1) {
              action?.payload?.chatDetails?.splice(index, 1);
            }

            // Move the object to the first position
            action?.payload?.chatDetails?.unshift(object);
          }
        }
        state.chatResponse = action.payload;
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      .addCase(getAllEventsById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'An error occurred';
        state.success = false;
      });
  },
});

export const { updateChatResponse, removeChatResponse, deleteChatResponse } = leftPanelSlice.actions;
export default leftPanelSlice.reducer;
