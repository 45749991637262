import dayjs from 'dayjs';
import { WMY } from 'app/shared/model/enumerations/wmy.model';

export interface ICustomOccurence {
  id?: number;
  activityNumber?: number | null;
  repeatEveryNth?: number | null;
  weekORMonthORYear?: keyof typeof WMY | null;
  occurence?: string | null;
  endDate?: string | null;
}

export const defaultValue: Readonly<ICustomOccurence> = {};
