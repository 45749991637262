// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 455px) {
  .mob-community {
    display: block !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/ui/community-guidelines/community-guidelines.scss"],"names":[],"mappings":"AAAA;EACI;IACI,yBAAA;EACN;AACF","sourcesContent":["@media only screen and (max-width: 455px) {\n    .mob-community {\n        display: block !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
