import React, { useState } from 'react';
import { Dialog, DialogContent, DialogActions, Button, TextField, DialogTitle, Typography, useTheme } from '@mui/material';
import { useAppDispatch } from 'app/config/store';
import { useParams } from 'react-router-dom';
import chatEdit, { getChatEdited } from 'app/shared/reducers/chat-messages/chat-edit';
import { toast } from 'react-toastify';

const ChatEdit = ({ open, onClose, handleChat, onSave }) => {
    const [editedChat, setEditedChat] = useState(handleChat.message);
    const dispatch = useAppDispatch();
    const { id } = useParams();

    const handleSave = async () => {
        onClose();
        const userid2 = JSON.parse(localStorage?.getItem('MyDetailsAccount'));
        const payload = {
            userId: userid2?.id,
            messageId: handleChat?.id,
            eventId: handleChat?.eventId,
            eventType: handleChat?.eventType,
            message: editedChat ? editedChat : ''
        };
        onSave(payload);
    };
    const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

    const handleCancel = () => {
        setEditedChat('');
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                style: { borderRadius: 15 } // Apply border-radius to the dialog
            }}
        >
            <DialogTitle id="alert-dialog-title">
                 <Typography variant="h5" gutterBottom>
      Edit Message
      </Typography>
            </DialogTitle>
            <DialogContent>
                <TextField
                    multiline
                    rows={4}
                    variant="outlined"
                   
                    fullWidth
                    defaultValue={handleChat.message}
                    onChange={(e) => setEditedChat(e.target.value)}
                />
            </DialogContent>
            <DialogActions   sx={{
        px: 3 // Adds 24px padding to DialogActions
    }}>
                <Button onClick={handleCancel}     sx={{
            color: isDarkMode ? '#FFCE00' : '#674BFF',
 
            '&:hover': {
              color: isDarkMode ? '#FFCE00' : '#674BFF',
            },
          }}>
                    Cancel
                </Button>
                <Button onClick={handleSave} sx={{
            backgroundColor: isDarkMode ? '#FFCE00' : '#674BFF',
            color:isDarkMode ? 'black':'white',
            '&:hover': {
              backgroundColor: isDarkMode ? '#FFCE00' : '#674BFF',
             color:isDarkMode ? 'black':'white',
            },
          }}
          variant="contained" disabled={!editedChat?.trim()}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ChatEdit;
