import React from 'react';
import { Button } from '@mui/material';
import { weekDaysType } from 'app/utils/types/choose-availability-types/choose-availability-types';

interface WeekDayButtonsProps {
  selected: weekDaysType[];
  item: any;
  handleItemClick: (id: weekDaysType) => void;
}
const WeekDayButtons = ({ selected, item, handleItemClick }: WeekDayButtonsProps) => {
  return (
    <Button
      variant="contained"
      sx={{
        color: selected.some(element => element.label === item.label && element.value === item.value) ? 'common.white' : 'secondary.light',
        backgroundColor: selected.some(element => element.label === item.label && element.value === item.value)
          ? 'primary.light'
          : '#F6F7F8',
        borderRadius: '32px',
        height: '48px',
        width: '48px',
        minWidth: '48px',
        textTransform: 'none',
        boxShadow: 'none',
        p: 0,
        '&:hover': {
          color: '#F6F7F8',
          backgroundColor: '#674BFF',
          boxShadow: 'none',
        },
      }}
      onClick={() => handleItemClick(item)}
    >
      {item.label}
    </Button>
  );
};

export default WeekDayButtons;
