import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const ClimateactionIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="21.858" height="16.032" viewBox="0 0 21.858 16.032" style={{ fontSize: 'larger' }}>
    <g id="Climate_Action" data-name="Climate Action" transform="translate(-0.998 -5)">
      <path
        id="Path_32"
        data-name="Path 32"
        d="M15.091,22.409l-.357.2a1.625,1.625,0,0,1-2.186-.5.5.5,0,0,0-.437-.24.546.546,0,0,0-.532.393A6.061,6.061,0,0,0,11.31,24a5.624,5.624,0,0,0,.255,1.6h.248a7.941,7.941,0,0,0,4.473-1.377,5.77,5.77,0,0,1-.729-2.04A2.79,2.79,0,0,0,15.091,22.409Z"
        transform="translate(-2.799 -4.579)"
        fill="#676e7b"
      />
      <path
        id="Path_33"
        data-name="Path 33"
        d="M12.185,16.941a.546.546,0,0,0,.729.168l.357-.2a4.007,4.007,0,0,1,.9-.408,5.828,5.828,0,0,1,1.646-3.89,1.02,1.02,0,0,0-.656-.313L14.021,12.2a2.12,2.12,0,0,1-1.894-2.521,3.869,3.869,0,0,0-1.115-3.643L9.934,5A7.934,7.934,0,0,0,6.59,6.013a4.612,4.612,0,0,1,.393,3.41,3.643,3.643,0,0,0-.095.94,3.555,3.555,0,0,0,.8,2.28l.109.138a4.59,4.59,0,0,1,.947,3.992l-.86,3.8a7.453,7.453,0,0,0,1.26.328A6.652,6.652,0,0,1,8.98,19.44a6.768,6.768,0,0,1,.328-2.054,1.625,1.625,0,0,1,1.545-1.144A1.566,1.566,0,0,1,12.185,16.941Z"
        transform="translate(-1.518 0)"
        fill="#676e7b"
      />
      <path
        id="Path_34"
        data-name="Path 34"
        d="M15.1,8.882A4.845,4.845,0,0,1,15,9.9a1.027,1.027,0,0,0,.189.831,1.049,1.049,0,0,0,.729.415l1.151.087a2.076,2.076,0,0,1,1.1.437V7.512A8.014,8.014,0,0,0,13.31,5.05l.262.248A5.027,5.027,0,0,1,15.1,8.882Z"
        transform="translate(-3.342 -0.014)"
        fill="#676e7b"
      />
      <path
        id="Path_35"
        data-name="Path 35"
        d="M5.371,13.934a4.648,4.648,0,0,1-1.093-2.972,4.59,4.59,0,0,1,.16-1.224A3.57,3.57,0,0,0,4.176,7.21,8.014,8.014,0,0,0,5.371,20.739L6.187,17.1h0a3.5,3.5,0,0,0-.729-3.067Z"
        transform="translate(0 -0.6)"
        fill="#676e7b"
      />
      <path
        id="Path_36"
        data-name="Path 36"
        d="M25.557,12.876V7.186a2.186,2.186,0,1,0-4.371,0v5.69a4.371,4.371,0,1,0,4.371,0Z"
        transform="translate(-4.887 0)"
        fill="#676e7b"
      />
    </g>
  </svg>,
  'ClimateactionIcon'
);
export default ClimateactionIcon;
