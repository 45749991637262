import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const WaterIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" style={{ fontSize: 'larger' }}>
    <g id="water" transform="translate(-6 -6)">
      <path
        id="Path_20"
        data-name="Path 20"
        d="M33.667,6h-10.5A1.167,1.167,0,0,0,22,7.167V9.5H33.667Z"
        transform="translate(-6.667)"
        fill="#676e7b"
      />
      <path
        id="Path_21"
        data-name="Path 21"
        d="M22,14.583A.583.583,0,0,1,22.583,14h3.5a.583.583,0,0,1,0,1.167h-3.5A.583.583,0,0,1,22,14.583Z"
        transform="translate(-6.667 -3.333)"
        fill="#676e7b"
      />
      <path
        id="Path_22"
        data-name="Path 22"
        d="M25.583,18.172a.583.583,0,0,1,.583.583v1.167a.583.583,0,0,1-1.167,0V18.755A.583.583,0,0,1,25.583,18.172Z"
        transform="translate(-7.917 -5.072)"
        fill="#676e7b"
        fillRule="evenodd"
      />
      <path
        id="Path_23"
        data-name="Path 23"
        d="M28.585,18.171a.583.583,0,0,1,.825,0l.825.825a.583.583,0,0,1-.825.825L28.585,19A.583.583,0,0,1,28.585,18.171Z"
        transform="translate(-9.339 -5)"
        fill="#676e7b"
        fillRule="evenodd"
      />
      <path
        id="Path_24"
        data-name="Path 24"
        d="M20.171,19.992a.583.583,0,0,1,0-.825L21,18.342a.583.583,0,1,1,.825.825L21,19.992A.583.583,0,0,1,20.171,19.992Z"
        transform="translate(-5.833 -5.071)"
        fill="#676e7b"
        fillRule="evenodd"
      />
      <path
        id="Path_25"
        data-name="Path 25"
        d="M25.583,23.172a.583.583,0,0,1,.583.583v1.167a.583.583,0,1,1-1.167,0V23.755A.583.583,0,0,1,25.583,23.172Z"
        transform="translate(-7.917 -7.155)"
        fill="#676e7b"
        fillRule="evenodd"
      />
      <path
        id="Path_26"
        data-name="Path 26"
        d="M32.085,21.671a.583.583,0,0,1,.825,0l.825.825a.583.583,0,0,1-.825.825l-.825-.825A.583.583,0,0,1,32.085,21.671Z"
        transform="translate(-10.798 -6.458)"
        fill="#676e7b"
        fillRule="evenodd"
      />
      <path
        id="Path_27"
        data-name="Path 27"
        d="M16.671,23.242a.583.583,0,0,1,0-.825l.825-.825a.583.583,0,0,1,.825.825l-.825.825A.583.583,0,0,1,16.671,23.242Z"
        transform="translate(-4.375 -6.426)"
        fill="#676e7b"
        fillRule="evenodd"
      />
      <path
        id="Path_28"
        data-name="Path 28"
        d="M7.167,28.5A1.167,1.167,0,0,0,6,29.667v5.542a1.167,1.167,0,0,0,1.167,1.167H8.333A1.167,1.167,0,0,0,9.5,35.208v-.2c.566-.019,1.156-.035,1.407-.025a10.843,10.843,0,0,1,3,.61,11.824,11.824,0,0,0,2.817.613c.281.021.554.048.819.074a5.958,5.958,0,0,0,2.506-.074A46.277,46.277,0,0,0,26.7,32.8c.64-.644.32-2.381-1.663-2.059a16.073,16.073,0,0,0-3.14,1.029,7.936,7.936,0,0,1-2.551.773c-1.087-.064-3.965-.579-3.965-.579l3.3.039a1.96,1.96,0,0,0,1.108-.425c.642-.45,1.215-1.738.256-1.738a7.692,7.692,0,0,1-1.982-.322l-4.093-.9a4.9,4.9,0,0,0-1.982,0c-.366.164-1.63.614-2.5.92A1.167,1.167,0,0,0,8.333,28.5Z"
        transform="translate(0 -9.375)"
        fill="#676e7b"
        fillRule="evenodd"
      />
    </g>
  </svg>,
  'WaterIcon'
);
export default WaterIcon;
