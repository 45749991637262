import React from 'react';
import { Box } from '@mui/material';
import { TabPanelProps } from 'app/components/layout/event-filter-tabs/event-filter-tabs';

const CustomTabPanel = (props: TabPanelProps, component: string) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`${component}-${index}`} aria-labelledby={`${component}-${index}`} {...other}>
      {value === index && <Box sx={{ py: 3, px: 0 }}>{children}</Box>}
    </div>
  );
};

export default CustomTabPanel;
