import React from 'react';
import { Grid, Button, Typography, useTheme } from '@mui/material';

interface SettingsUpdateProps {
  handleSubmit: (arg?: any) => void;
  locationManualTyped?: boolean;
  isEqual?: boolean;
}

const UpdateButton = ({ handleSubmit, locationManualTyped, isEqual }: SettingsUpdateProps) => {
  const theme = useTheme();
  return (
    <Grid sx={{ textAlign: 'end' }}>
      <Button
        onClick={handleSubmit}
        disabled={locationManualTyped || isEqual}
        variant="text"
        disableRipple
        sx={{
          borderRadius: 1.875,
          py: 0.5,
          px: 3.25,
          borderColor: 'primary.light',
          bgcolor: 'primary.light',
          color: 'white',
          gap: 1,
          '&.MuiButtonBase-root:hover': {
            bgcolor: 'primary.light',
            color: 'white',
          },
          '&.MuiButton-root.Mui-disabled': {
            bgcolor: '#BFBFBF',
          },
        }}
      >
        <Typography
          sx={{
            textTransform: 'none',
            fontSize: theme.infoText.regular.fontSize,
            fontWeight: theme.infoText.regular.fontWeight,
            fontStyle: theme.infoText.regular.fontStyle,
          }}
        >
          Update
        </Typography>
      </Button>
    </Grid>
  );
};

export default UpdateButton;
