import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const HealthIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="17.85" viewBox="0 0 21 17.85" style={{ fontSize: 'larger' }}>
    <path
      id="Health"
      d="M9.31,19.242C6.22,16.874,2,12.963,2,9.265,2,3.087,7.775.78,12.5,5.553,17.225.78,23,3.087,23,9.265c0,3.7-4.22,7.61-7.31,9.977-1.4,1.072-2.1,1.608-3.19,1.608S10.708,20.314,9.31,19.242Zm7.915-12.83a.788.788,0,0,1,.787.788V8.513h1.313a.787.787,0,1,1,0,1.575H18.012V11.4a.787.787,0,1,1-1.575,0V10.088H15.125a.787.787,0,1,1,0-1.575h1.312V7.2A.788.788,0,0,1,17.225,6.412Z"
      transform="translate(-2 -3)"
      fill="#676e7b"
      fillRule="evenodd"
    />
  </svg>,
  'HealthIcon'
);
export default HealthIcon;
