import React, { useEffect, useState } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import 'react-datepicker/dist/react-datepicker.css';
import CatButtons from 'app/components/ui/auth/pick-your-interests/cat-buttons';
import { fetchCategories } from 'app/shared/reducers/categories';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ItemData } from 'app/components/ui/auth/pick-your-interests/pick-your-interests';
import { SettingsProfileType } from 'app/utils/types/settings/settings-profile-types';
import SettingsProfileIntrestsList from './settings-profile-intrests-list';

interface SettingsProfileIntrestsProps {
  setUpdatedProfile: React.Dispatch<React.SetStateAction<SettingsProfileType>>;
}
const SettingsProfileIntrests = ({ setUpdatedProfile }: SettingsProfileIntrestsProps) => {
  const dispatch = useAppDispatch();
  const [categories, setCategories] = useState<any>([]);
  const [selected, setSelected] = useState<ItemData[]>([]);
  const profileSettings = useAppSelector(state => state.settingsProfile.settingsProfile);

  const handleItemClick = (item: ItemData) => {
    const isSelected = selected.some(element => element.category === item.category && element.id === item.id);

    if (isSelected) {
      setSelected(prevSelected => prevSelected.filter(element => element.id !== item.id));
    } else {
      setSelected(prevSelected => [...prevSelected, item]);
    }
    setUpdatedProfile(state => ({
      ...state,
      interests:
        state?.interests !== null ? (isSelected ? state.interests.filter(id => id !== item.id) : [...state.interests, item?.id]) : [],
    }));
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = (await dispatch(fetchCategories())) as any;
        setCategories(response?.payload?.map((item: any) => ({ id: item?.id, category: item?.category })));
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (profileSettings?.interests !== null && profileSettings?.interests.length > 0 && categories?.length > 0) {
      const commonCategories = categories.filter(category => profileSettings.interests.includes(category.id));
      setSelected(commonCategories);
    }
  }, [profileSettings?.interests, categories]);
  const theme = useTheme();

  return (
    <Grid container justifyContent="flex-start" alignItems={'center'} spacing={3}>
      <Grid xs={12} item textAlign={'left'}>
        <Typography variant="h5" sx={{ color: theme.textColor.mainHeading, fontSize: '14px' }}>
          Pick your interests so that we can personalise Reachamy for you
        </Typography>
      </Grid>
      <Grid xs={12} pl={2.2} pt={1} mr={1}>
        {' '}
        <SettingsProfileIntrestsList setUpdatedProfile={setUpdatedProfile} />
      </Grid>
      {/* <Grid item container justifyContent={'center'}>
        {categories.length > 0 &&
          categories?.map((data, index) => (
            <Grid item key={index}>
              <CatButtons item={data} selected={selected} handleItemClick={handleItemClick} />
            </Grid>
          ))}
      </Grid> */}
    </Grid>
  );
};

export default SettingsProfileIntrests;
