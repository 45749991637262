import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import axios from 'axios';
import { postingRulesType } from 'app/utils/types/activity/activity-types';
import { useAppDispatch } from 'app/config/store';
import { fetchCategories } from 'app/shared/reducers/categories';
import { singleImageType } from 'app/shared/reducers/image-source';
import { apiEndPoints } from 'app/utils/data/constants/api-end-points';

const WriteBlogRightSection = ({ formik }: any) => {
  const [activityPostingRules, setActivityPostingRules] = useState<postingRulesType[]>([]);
  const [files, setFiles] = useState<File>();
  const [categories, setCategories] = useState<any>([]);
  const [imageResponse, setImageResponse] = useState<singleImageType>(null);
  const [error, setError] = useState(false);
  const [imageUploaded, setImageUploaded] = useState<boolean>(false);
  const theme = useTheme();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(fetchCategories());
        setCategories(response.payload);
      } catch (error) {
        setError(error);
      }
    };
    const fetchPostingRules = async () => {
      try {
        const response = await axios.get<postingRulesType[]>('api/rules-tables');

        setActivityPostingRules(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
    fetchPostingRules();
  }, []);

  useEffect(() => {
    if (files !== undefined) {
      const sendFile = async () => {
        try {
          const formData = new FormData();
          formData.append('file', files as Blob);
          const response = await axios.post<any>(apiEndPoints.imageUpload, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
          if (response.status === 201) {
            setImageResponse(response.data);
            formik.setFieldValue('imageId', response.data.id);
            setImageUploaded(true);
          }
        } catch (error) {
          console.log(error);
        }
      };
      sendFile();
    }
  }, [files]);

  return (
    <Stack direction="column" gap={2}>
      <Stack sx={{ backgroundColor: 'secondary.dark', borderRadius: '16px', p: { xs: 2, lg: 3 } }} gap={2}>
        <Typography
          sx={{
            color: theme.textColor.mainHeading,
            fontSize: theme.p1.semiBold.fontSize,
            fontWeight: theme.p1.semiBold.fontWeight,
          }}
        >
          Rules for posting
        </Typography>

        <Stack
          className="example1"
          direction="column"
          gap={1}
          sx={{
            maxWidth: { xs: '100%', lg: 265 },
            borderRadius: '15px',
            height: '66vh',
            overflowY: 'scroll',
            overflowX: 'hidden',
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
              // width: '0px',
              display: 'none',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'primary.light',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#AAA',
              borderRadius: '10px',
            },
          }}
        >
          {activityPostingRules?.length && activityPostingRules[0]?.description !== ''
            ? activityPostingRules?.map(item => (
                <Stack key={item.id} direction="row" spacing={1} alignItems="flex-start" justifyContent={'flex-start'}>
                  <Stack p={0.7}>
                    <Box
                      sx={{
                        width: '9px',
                        height: '9px',
                        borderRadius: '50%',
                        backgroundColor: '#775FFF',
                      }}
                    />
                  </Stack>
                  <Stack direction="row" sx={{ color: theme.textColor.paragraph, gap: 0.2 }}>
                    <Typography sx={{ fontSize: theme.infoText.regular.fontSize }}>{item.description}</Typography>
                  </Stack>
                </Stack>
              ))
            : null}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default WriteBlogRightSection;
