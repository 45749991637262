// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --background-color: #fff;
  --text-color: #000;
  --border-color: #ccc;
}

.dark-mode {
  --background-color: #333;
  --text-color: #fff;
  --border-color: #555;
}

.PhoneInputInput {
  background-color: var(--background-color);
  color: var(--text-color);
  border-color: var(--border-color);
}

.PhoneInputCountrySelect::-webkit-scrollbar {
  display: none;
}

.PhoneInputCountrySelect {
  /* Common styles for both modes */
  background-color: var(--background-color);
  color: var(--text-color);
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Dark mode styles */
.dark-mode .PhoneInputCountrySelect {
  /* Override variables for dark mode */
  background-color: var(--background-color);
  color: var(--text-color);
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/ui/phone-verification/phone-number-input-section.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,kBAAA;EACA,oBAAA;AACF;;AAEA;EACE,wBAAA;EACA,kBAAA;EACA,oBAAA;AACF;;AAEA;EACE,yCAAA;EACA,wBAAA;EACA,iCAAA;AACF;;AACA;EACE,aAAA;AAEF;;AAAA;EACE,iCAAA;EACA,yCAAA;EACA,wBAAA;EACA,wBAAA;EACA,qBAAA;AAGF;;AAAA,qBAAA;AACA;EACE,qCAAA;EACA,yCAAA;EACA,wBAAA;AAGF","sourcesContent":[":root {\n  --background-color: #fff;\n  --text-color: #000;\n  --border-color: #ccc;\n}\n\n.dark-mode {\n  --background-color: #333;\n  --text-color: #fff;\n  --border-color: #555;\n}\n\n.PhoneInputInput {\n  background-color: var(--background-color);\n  color: var(--text-color);\n  border-color: var(--border-color);\n}\n.PhoneInputCountrySelect::-webkit-scrollbar {\n  display: none;\n}\n.PhoneInputCountrySelect {\n  /* Common styles for both modes */\n  background-color: var(--background-color);\n  color: var(--text-color);\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n\n/* Dark mode styles */\n.dark-mode .PhoneInputCountrySelect {\n  /* Override variables for dark mode */\n  background-color: var(--background-color);\n  color: var(--text-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
