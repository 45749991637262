import {
  Avatar,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import './settings-org-members.scss';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  fetchOrganizationMembers,
  removeAcceptedUser,
  setSelectedOrgId,
  updateUserRole,
} from 'app/shared/reducers/settings/settings-organization';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { settingOrgPermssionProps } from './settings-org-rightcard';
import { Link } from 'react-router-dom';

const SettingsOrgMembers = ({ hasPermission }: settingOrgPermssionProps) => {
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [searchInput, setSearchInput] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const { members, selectedOrgId } = useAppSelector(state => state.settingsOrganization);
  const myProfileId = useAppSelector(state => state.authentication.account.id);

  const theme = useTheme();
  const dispatch = useAppDispatch();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>, member: any) => {
    setSelectedMemberId(member?.id);
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleRemoveUser = async (orgId, userId) => {
    try {
      await dispatch(removeAcceptedUser({ orgId, userId })).then(() => handleCloseUserMenu());
    } catch (error) {
      console.error(error);
    }
  };

  const handleAssignRole = async memberRole => {
    dispatch(updateUserRole({ organizationId: parseInt(selectedOrgId, 10), userId: selectedMemberId, memberRole }))
      .unwrap()
      .then(() => handleCloseUserMenu())
      .catch(error => {
        console.log(error, 'ERRORR');
      });
  };

  useEffect(() => {
    if (selectedOrgId) dispatch(fetchOrganizationMembers({ orgId: selectedOrgId }));
  }, [dispatch, selectedOrgId]);

  const handleSearchInputChange = event => {
    setSearchInput(event.target.value);
    setIsSearching(true);
  };

  const filteredMembers = isSearching
    ? members.filter(member => {
        const fullName = member.fullName ? member.fullName.toLowerCase() : '';
        const login = member.login ? member.login.toLowerCase() : '';

        return fullName.includes(searchInput.toLowerCase()) || login.includes(searchInput.toLowerCase());
      })
    : members;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          px={1}
          sx={{ color: theme.palette.mode === 'dark' ? '#ffff' : '#2a2a2a', fontSize: '14px', fontWeight: 600 }}
        >
          Members
        </Typography>
      </Grid>
      {members.length > 0 && (
        <Grid item xs={12} md={12}>
          <FormControl sx={{ width: '100%' }} variant="outlined">
            <OutlinedInput
              placeholder="Search members"
              sx={{
                height: '40px',
                fontSize: '0.75rem',
              }}
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                'aria-label': 'weight',
              }}
              onChange={handleSearchInputChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12}>
        {/* <Box
          sx={{
            width: '100%',
            height: '265px',
            borderRadius: 1,
            border: '1px solid #c4c4c4',
          }}
        > */}
        <Grid container spacing={2}>
          <Grid item xs={12} className={members.length > 0 ? 'org-member-pl-16' : 'org-member-pl-0'}>
            {members.length > 0 ? (
              <Box
                className="example-scrol"
                sx={{
                  width: '100%',
                  maxHeight: '200px',
                }}
              >
                {filteredMembers.length > 0 ? (
                  filteredMembers.map(member => (
                    <Grid key={member?.id} container spacing={2} px={1} pt={1} m={0} alignItems="center">
                      <Grid item xs={2} md={2} className="org-member-avatar pl-0">
                        <Link to={`/profile/${member?.id}`}>
                          <Avatar alt={member.fullName} src={member.profilePic} />
                        </Link>
                      </Grid>
                      <Grid item xs={6} md={6} display="flex" justifyContent="space-between" alignItems="center">
                        <Box sx={{ color: theme.palette.mode === 'dark' ? '#ffff' : '#2a2a2a' }}>
                          <Typography variant="subtitle2">
                            {member.fullName} {member.id === myProfileId && '(You)'}
                          </Typography>
                          <Typography variant="caption">{member.login}</Typography>
                        </Box>
                        <Box>
                          <Button
                            className="org-member-btn"
                            disableElevation
                            disableRipple
                            size="small"
                            sx={{
                              color: 'primary.light',
                              '&.MuiButtonBase-root:hover': {
                                bgcolor: 'transparent',
                              },
                            }}
                            variant="text"
                          >
                            {member.creator ? 'CREATOR' : member.role ?? 'MEMBER'}
                          </Button>
                        </Box>
                      </Grid>
                      {hasPermission && member.role !== 'CREATOR' && member.id !== myProfileId && (
                        <Grid item xs={4} md={4} className="org-member-MoreVertIcon-mob">
                          <Box textAlign={'end'}>
                            <span role="button" className="p-2" onClick={event => handleOpenUserMenu(event, member)}>
                              <MoreVertIcon sx={{ color: theme.palette.mode === 'dark' ? '#ffff' : '#2a2a2a' }} />
                            </span>
                            <Menu
                              sx={{ mt: '45px' }}
                              id="menu-appbar"
                              anchorEl={anchorElUser}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              keepMounted
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              open={Boolean(anchorElUser)}
                              onClose={handleCloseUserMenu}
                            >
                              <MenuItem onClick={() => handleAssignRole('ADMIN')}>
                                <Typography textAlign="center">Make admin</Typography>
                              </MenuItem>

                              <MenuItem onClick={() => handleAssignRole('ORGANIZER')}>
                                <Typography textAlign="center">Make organizer</Typography>
                              </MenuItem>

                              <MenuItem onClick={() => handleAssignRole('MEMBER')}>
                                <Typography textAlign="center">Make member</Typography>
                              </MenuItem>

                              <MenuItem onClick={() => handleRemoveUser(selectedOrgId, member.id)}>
                                <Typography textAlign="center">Remove</Typography>
                              </MenuItem>
                            </Menu>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  ))
                ) : (
                  <Box
                    sx={{
                      width: '100%',
                      height: '110px',
                      borderRadius: 1,
                    }}
                  >
                    <Grid container spacing={2} p={1} m={0} alignItems="center">
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: theme.palette.mode === 'dark' ? '#ffff' : 'rgba(0, 0, 0, 0.6)',

                            fontWeight: '400',
                          }}
                        >
                          {isSearching ? 'No members found' : 'There are no members'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  width: '100%',
                  height: '110px',
                  borderRadius: 1,
                }}
              >
                <Grid container spacing={2} p={1} m={0} alignItems="center">
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: theme.palette.mode === 'dark' ? '#ffff' : 'rgba(0, 0, 0, 0.6)', fontWeight: '400' }}
                    >
                      {isSearching ? 'No members found' : 'There are no members'}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Grid>
        </Grid>
        {/* </Box> */}
      </Grid>
    </Grid>
  );
};

export default SettingsOrgMembers;
