import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const ModeratedIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
    <path
      id="post-svgrepo-com"
      d="M2,5.15A3.15,3.15,0,0,1,5.15,2h14.7A3.15,3.15,0,0,1,23,5.15v14.7A3.15,3.15,0,0,1,19.85,23H5.15A3.15,3.15,0,0,1,2,19.85ZM5.15,4.1A1.05,1.05,0,0,0,4.1,5.15V10.4H20.9V5.15A1.05,1.05,0,0,0,19.85,4.1ZM4.1,12.5v7.35A1.05,1.05,0,0,0,5.15,20.9h14.7a1.05,1.05,0,0,0,1.05-1.05V12.5Zm10.5,1.05a1.05,1.05,0,0,1,.742.308l4.2,4.2a1.05,1.05,0,1,1-1.485,1.485L14.6,16.085l-2.408,2.408-1.05,1.05a1.05,1.05,0,1,1-1.485-1.485l.308-.308-.615-.615L6.942,19.542a1.05,1.05,0,1,1-1.485-1.485l3.15-3.15a1.05,1.05,0,0,1,1.485,0l1.358,1.358,2.408-2.408A1.05,1.05,0,0,1,14.6,13.55Zm-3.15-6.3A1.05,1.05,0,0,1,12.5,6.2h5.25a1.05,1.05,0,0,1,0,2.1H12.5A1.05,1.05,0,0,1,11.45,7.25ZM7.25,9.087A1.838,1.838,0,1,0,5.412,7.25,1.837,1.837,0,0,0,7.25,9.087Z"
      transform="translate(-2 -2)"
      fill="#707070"
      fillRule="evenodd"
    />
  </svg>,
  'ModeratedIcon'
);
export default ModeratedIcon;
