import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const OthersIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="15.811" height="15.811" viewBox="0 0 15.811 15.811">
    <g id="noun_other_3513326" transform="translate(5987.987 2914.179)">
      <g id="Group_2486" data-name="Group 2486" transform="translate(-5987.987 -2914.179)">
        <path
          id="Path_3239"
          data-name="Path 3239"
          d="M36.905,29A7.905,7.905,0,1,1,29,36.905,7.891,7.891,0,0,1,36.905,29Zm-2.613,3.2a2.12,2.12,0,0,1,2.156,2.091,2.175,2.175,0,0,1-2.156,2.156A2.12,2.12,0,0,1,32.2,34.292,2.064,2.064,0,0,1,34.292,32.2Zm5.227,0a2.064,2.064,0,0,1,2.091,2.091,2.12,2.12,0,0,1-2.091,2.156,2.175,2.175,0,0,1-2.156-2.156A2.12,2.12,0,0,1,39.519,32.2Zm-5.227,5.161a2.175,2.175,0,0,1,2.156,2.156,2.12,2.12,0,0,1-2.156,2.091A2.064,2.064,0,0,1,32.2,39.519,2.12,2.12,0,0,1,34.292,37.363Zm5.227,0a2.12,2.12,0,0,1,2.091,2.156,2.064,2.064,0,0,1-2.091,2.091,2.12,2.12,0,0,1-2.156-2.091A2.175,2.175,0,0,1,39.519,37.363Z"
          transform="translate(-29 -29)"
          fill="#676e7b"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>,

  'OthersIcon'
);
export default OthersIcon;
