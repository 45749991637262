import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export interface ShareViaMailPayload {
  activityId: string;
  url: string;
  emailAddresses: string[];
}

export interface ShareViaMailPetitionPayload {
  petitionId: string;
  url: string;
  emailAddresses: string[];
}

export interface ShareViaMailOrganizationPayload {
  organizationId: string;
  url: string;
  emailAddresses: string[];
  user: string;
}

const initialState = {
  loading: false,
  success: false,
  error: null,
  successMessage: null,
};

export const shareActivity = createAsyncThunk<
  void,
  ShareViaMailPayload,
  {
    rejectValue: string;
  }
>('Link/share', async (shareViaMailData: ShareViaMailPayload, thunkAPI) => {
  try {
    await axios.post<void>('/api/activity/share-activity2', shareViaMailData);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to share activity');
  }
});

export const sharePetition = createAsyncThunk<
  void,
  ShareViaMailPetitionPayload,
  {
    rejectValue: string;
  }
>('Link/share', async (shareViaMailData: ShareViaMailPetitionPayload, thunkAPI) => {
  try {
    await axios.post<void>('/api/petition/share-petition', shareViaMailData);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to share petition');
  }
});
export const shareOrganization = createAsyncThunk<
  void,
  ShareViaMailOrganizationPayload,
  {
    rejectValue: string;
  }
>('Link/share', async (shareViaMailData: ShareViaMailOrganizationPayload, thunkAPI) => {
  try {
    await axios.post<void>('/api/organization/invite-members', shareViaMailData);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to share petition');
  }
});

const sendLinkthrouhMailSlice = createSlice({
  name: 'shareViaMail',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(shareActivity.pending, state => {
      state.loading = true;
      state.success = false;
      state.error = false;
      state.successMessage = null;
    });
    builder.addCase(shareActivity.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.successMessage = ' Emails sent successfully';
    });
    builder.addCase(shareActivity.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload || 'Failed to share activity';
      state.successMessage = 'Failed to send Mail';
    });
  },
});

export default sendLinkthrouhMailSlice.reducer;
