import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const UrgentIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="14.492" height="26.716" viewBox="0 0 14.492 26.716" style={{ fontSize: 'larger' }}>
    <g id="hour-glass-svgrepo-com_1_" data-name="hour-glass-svgrepo-com (1)" transform="translate(-7.591)">
      <g id="Group_6598" data-name="Group 6598" transform="translate(7.591)">
        <path
          id="Path_4821"
          data-name="Path 4821"
          d="M22.083,3.294C22.083.862,18.179,0,14.837,0S7.591.862,7.591,3.294c0,2.791,3.259,8.022,5.258,10.064-2,2.043-5.258,7.273-5.258,10.064,0,2.432,3.9,3.294,7.246,3.294s7.246-.863,7.246-3.294c0-2.791-3.259-8.022-5.258-10.064C18.824,11.315,22.083,6.085,22.083,3.294Zm-.805,20.128c0,1.66-3.221,2.489-6.441,2.489S8.4,25.081,8.4,23.422c0-2.936,4.142-9.08,5.676-10.064C12.538,12.375,8.4,6.231,8.4,3.294,8.4,1.634,11.617.805,14.837.805s6.441.83,6.441,2.489c0,2.937-4.142,9.081-5.676,10.064C17.136,14.341,21.278,20.485,21.278,23.422Z"
          transform="translate(-7.591)"
          fill="#676e7b"
        />
        <path
          id="Path_4822"
          data-name="Path 4822"
          d="M10.527,28.091c0,.15,1.679.584,4.882.584s4.882-.434,4.882-.584c0-2.216-4.882-3.741-4.882-7.709C15.409,24.35,10.527,25.793,10.527,28.091Z"
          transform="translate(-8.163 -3.972)"
          fill="#676e7b"
        />
        <path
          id="Path_4823"
          data-name="Path 4823"
          d="M15.445,13.523l.094.858.094-.858c.956-.539,4.247-4.795,4.247-6.619,0-.241-1.493-.938-4.343-.938s-4.343.7-4.343.938C11.2,8.728,14.489,12.984,15.445,13.523Z"
          transform="translate(-8.293 -1.163)"
          fill="#676e7b"
        />
      </g>
    </g>
  </svg>,
  'UrgentIcon'
);
export default UrgentIcon;
