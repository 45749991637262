import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { serializeAxiosError } from '../reducer.utils';
import toast from 'react-hot-toast';

export interface SettingsProfileDetails {
  fetchLoading: boolean;
  fetchError: object | null | string;
  fetchSuccess: object | null | string;
  settingsProfile: object | null;
  sendSettingsSuccess: object | null | boolean;
  sendSettingsError: object | null | string;
}
interface ProfileParams {
  settings: object;
  id: string;
}
const initialState = {
  fetchLoading: false,
  fetchError: null,
  fetchSuccess: null,
  settingsProfile: null,
  sendSettingsSuccess: null,
  sendSettingsError: null,
};
const apiEndPoint = '/api/profile/settings/profile-details/';

// get settings profile
export const getSettingsProfile = createAsyncThunk('settings/profile/get', async (id: number) => axios.get<any>(apiEndPoint + id), {
  serializeError: serializeAxiosError,
});

export const sendSettingsProfile = createAsyncThunk(
  'settings/profile/send',
  async ({ settings, id }: ProfileParams) => axios.put<any>(apiEndPoint + id, settings),
  {
    serializeError: serializeAxiosError,
  }
);
const settingProfileSlice = createSlice({
  name: 'settingsProfile',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getSettingsProfile.pending, (state, action) => {
        state = initialState;
      })
      .addCase(getSettingsProfile.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.fetchSuccess = true;
        state.fetchError = null;
        state.settingsProfile = action.payload.data;
      })
      .addCase(getSettingsProfile.rejected, (state, action) => {
        state.fetchLoading = false;
        state.fetchSuccess = false;
        state.fetchError = action.error.message;
        state.settingsProfile = null;
      })
      .addCase(sendSettingsProfile.pending, state => {
        state.fetchLoading = true;
        state.sendSettingsSuccess = null;
        state.sendSettingsError = null;
      })
      .addCase(sendSettingsProfile.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.sendSettingsSuccess = true;
        state.sendSettingsError = null;
        toast.success(action?.payload?.data?.message);
      })
      .addCase(sendSettingsProfile.rejected, (state, action) => {
        state.fetchLoading = false;
        state.sendSettingsSuccess = false;
        state.sendSettingsError = true;
        toast.error('Something went wrong');
      });
  },
});

export default settingProfileSlice.reducer;
