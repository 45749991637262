import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const OrganiserCrownIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="8.182" viewBox="0 0 15 8.182">
    <path id="Path_3491" data-name="Path 3491" d="M2.116,277.423a.511.511,0,0,0-.512.64l1.864,7.158a.511.511,0,0,0,.495.383h10.25a.511.511,0,0,0,.495-.383l1.863-7.158a.511.511,0,0,0-.755-.569l-4.507,2.664L9.5,277.636a.511.511,0,0,0-.832,0l-1.805,2.521-4.507-2.664a.512.512,0,0,0-.243-.071Z" transform="translate(-1.588 -277.421)" fill="#058847"/>
  </svg>,
  'OrganiserCrownIcon'
);

export default OrganiserCrownIcon;
