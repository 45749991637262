import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';
import './profile-details.scss';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import SingleActivityCard from 'app/components/common/cards/activity-card/activity-card';
import { deleteActivityById } from 'app/shared/reducers/activity-home';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import PetitionCard from 'app/components/common/cards/petition-card/petition-card';
import { deletePetitionById } from 'app/shared/reducers/petition-home';
import BlogCard from 'app/components/common/cards/blog-card/blog-card';
import { TabPanelProps } from 'app/components/layout/event-filter-tabs/event-filter-tabs';
import { updateEventType } from 'app/shared/reducers/activity-filter';
import {
  fetchOrganizationDraftedItems,
  fetchOrganizationDetails,
  fetchUnauthorizedOrganizationDetails,
  fetchUnauthorizedOrganizationDraftedItems,
} from 'app/shared/reducers/organization';
import DraftTable from 'app/components/common/table/draft-table';
import RadioDialog from 'app/components/common/alert-dialog/radio-dialog';

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
      {value === index && <Box sx={{ py: 3, px: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `profile-tab-${index}`,
    'aria-controls': `profile-tabpanel-${index}`,
  };
}

const OrganizationActivityDetails = ({ id, profileData }: any) => {
  console.log('profileData', profileData);
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const [bookmarkSuccess, setBookmarkSuccess] = useState(false);
  const [likeSuccess, setLikemarkSuccess] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [currentActivityId, setCurrentActivityId] = useState(null);
  const [isVolunteerModalOpen, setIsVolunteerModalOpen] = useState(false);
  const userOwnedOrganizations = useAppSelector(state => state.organizationReducer.userOwnedOrganization);

  const organizationDetails = useAppSelector(state => state.organizationReducer.organizationDetails);
  const organizationDetailsLoading = useAppSelector(state => state.organizationReducer.organizationDetailsLoading);
  const draftedItems = useAppSelector(state => state.organizationReducer.draftedItems);
  const draftLoading = useAppSelector(state => state.organizationReducer.loading);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const particiaptedCount = useAppSelector(state => state.organizationReducer?.organization?.participatedCountbyOrganization);
  const organizedCount = useAppSelector(state => state.organizationReducer?.organization?.activitiesCountbyOrganization);
  const dispatch = useAppDispatch();
  const filterData = useAppSelector(state => state.filters.RequestBody.filter);

  const commonParams = {
    organizationId: id,
    userId: profileData?.createdBy,
    categoryId: filterData?.categoryId,
    subCategoryId: filterData?.subCategoryId,
  };

  const handleAuthenticatedChange = (newValue: number) => {
    if (profileData?.hasPermission || profileData?.hasOrganizerPermission) {
      switch (newValue) {
        case 0:
          dispatch(fetchOrganizationDraftedItems({ ...commonParams, filterType: 'Draft' }));
          break;
        case 1:
          dispatch(fetchOrganizationDetails({ ...commonParams, filterType: 'Participated' }));
          break;
        case 2:
          dispatch(fetchOrganizationDetails({ ...commonParams, filterType: 'Organized' }));
          break;
        default:
          dispatch(fetchOrganizationDraftedItems({ ...commonParams, filterType: '' }));
          break;
      }
    } else {
      switch (newValue) {
        case 0:
          dispatch(fetchOrganizationDetails({ ...commonParams, filterType: 'Participated' }));
          break;
        case 1:
          dispatch(fetchOrganizationDetails({ ...commonParams, filterType: 'Organized' }));
          break;
        default:
          dispatch(fetchOrganizationDraftedItems({ ...commonParams, filterType: '' }));
          break;
      }
    }
  };

  const handleUnauthorizedChange = (newValue: number) => {
    const commonParams = { organizationId: id };

    switch (newValue) {
      case 0:
        dispatch(fetchUnauthorizedOrganizationDetails({ ...commonParams, filterType: 'Participated' }));
        break;
      case 1:
        dispatch(fetchUnauthorizedOrganizationDetails({ ...commonParams, filterType: 'Organized' }));
        break;
      default:
        dispatch(fetchUnauthorizedOrganizationDraftedItems({ ...commonParams, filterType: '' }));
        break;
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (isAuthenticated) {
      handleAuthenticatedChange(newValue);
    } else {
      handleUnauthorizedChange(newValue);
    }

    setValue(newValue);
  };

  const handleBookmarkSuccess = () => {
    setBookmarkSuccess(s => !s);
  };

  useEffect(() => {
    const filterType = value === 1 ? 'Participated' : value === 2 ? 'Organized' : 'Draft';

    if (isAuthenticated && id) dispatch(fetchOrganizationDraftedItems({ ...commonParams, organizationId: id, filterType: filterType }));
  }, [id, filterData]);

  const handleLikeSuccess = () => {
    setLikemarkSuccess(s => !s);
  };
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1030));
  const isSmallScreenNew = useMediaQuery(theme.breakpoints.down('sm'));
  const marginRightVW = isSmallScreen ? 4 : (16 / window.innerWidth) * 100;
  const customTheme = createTheme({
    ...theme,
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            minWidth: isSmallScreen ? 'auto' : '',
            fontSize: isSmallScreen ? '0.675rem' : '0.975rem',
            marginRight: isSmallScreen ? `${marginRightVW}vw` : '',
            padding: isSmallScreen ? '0px' : 'auto',
            '&.Mui-selected': {
              color: '#674BFF',
            },
          },
        },
      },
    },
  });

  const handleDeleteClick = async (id: string) => {
    try {
      const response = await dispatch(deleteActivityById({ id }));
      if (response) {
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeletePetition = async (petitionId: string) => {
    try {
      const response = await dispatch(deletePetitionById({ petitionId }));
      if (response) {
        window.location.reload();
        try {
          dispatch(updateEventType('Latest'));
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDraftDeleteSuccess = async () => {
    console.log('Draft deleted successfully. Triggering additional logic...');
    dispatch(fetchOrganizationDraftedItems({ ...commonParams, organizationId: id, filterType: 'Draft' }));
  };

  const tabStyle = { textTransform: 'capitalize', color: theme.profile.color, fontWeight: 600, fontSize: '14px' };

  const [showOrgDialog, setShowOrgDialog] = useState(false);
  const handleOrgsDialog = value => {
    setShowOrgDialog(value);
  };

  const getCurrentActivity = id => {
    setCurrentActivityId(id);
  };
  const clearCurrentActivity = () => {
    setCurrentActivityId(null);
  };

  const handleOptionChange = event => {
    const selectedValue = event.target.value;

    if (parseInt(selectedValue, 10) === 1) {
      setSelectedOrgId(1);
      setSelectedOption('My Self');
    } else {
      const selectedOrganization = userOwnedOrganizations.find(organization => organization.id === parseInt(selectedValue, 10));

      if (selectedOrganization) {
        setSelectedOption(selectedOrganization.label);
        setSelectedOrgId(selectedOrganization.id);
      }
    }
  };

  const handleInstructionsModal = value => {
    setIsVolunteerModalOpen(value);
  };

  const handleCancel = () => {
    setShowOrgDialog(false);
    setSelectedOrgId(null);
    setSelectedOption('');
  };

  const changeOrgId = value => {
    setSelectedOrgId(value);
  };
  return (
    <Box>
      <RadioDialog
        selectedOrgId={selectedOrgId}
        open={showOrgDialog}
        handleShow={handleOrgsDialog}
        handleOptionChange={handleOptionChange}
        handleCancel={handleCancel}
        handleInstructionsModal={handleInstructionsModal}
      />
      <ThemeProvider theme={customTheme}>
        <Tabs
          className="hi"
          value={value}
          onChange={handleChange}
          aria-label="profile tabs"
          sx={{ borderBottom: '1px solid grey', position: 'sticky', top: 0, zIndex: 1000 }}
        >
          {isAuthenticated && (profileData?.hasPermission || profileData?.hasOrganizerPermission) && (
            <Tab
              disableRipple
              label="Draft"
              {...a11yProps(1)}
              sx={{ ...tabStyle, minHeight: '72px' }}
              icon={
                draftedItems?.size ? (
                  <span
                    style={{
                      fontSize: '10px',
                      paddingTop: '2px',
                      backgroundColor: draftedItems?.size ? '#674BFF' : '',
                      width: '24px',
                      height: '16px',
                      borderRadius: '8px',
                      color: draftedItems?.size ? '#fff' : '',
                    }}
                  >
                    {draftedItems?.size}
                  </span>
                ) : null
              }
              iconPosition="end"
            />
          )}
          <Tab
            disableRipple
            label="Participated"
            {...a11yProps(2)}
            sx={{ ...tabStyle, minHeight: '72px' }}
            icon={
              <span
                style={{
                  fontSize: '10px',
                  paddingTop: '2px',
                  backgroundColor: '#674BFF',
                  width: '24px',
                  height: '16px',
                  borderRadius: '8px',
                  color: '#fff',
                }}
              >
                {particiaptedCount ? particiaptedCount : 0}
              </span>
            }
            iconPosition="end"
          />
          <Tab
            disableRipple
            label="Organized"
            {...a11yProps(3)}
            sx={{ ...tabStyle, minHeight: '72px' }}
            icon={
              <span
                style={{
                  fontSize: '10px',
                  paddingTop: '2px',
                  backgroundColor: '#674BFF',
                  width: '24px',
                  height: '16px',
                  borderRadius: '8px',
                  color: '#fff',
                }}
              >
                {organizedCount ? organizedCount : 0}
              </span>
            }
            iconPosition="end"
          />
        </Tabs>
      </ThemeProvider>
      <div
        className="example1"
        style={{
          width: isSmallScreenNew ? '100%' : '100%',
          overflowY: 'scroll',
          height: '80vh',
          overflow: 'auto',
        }}
      >
        <Box>
          {(profileData?.hasPermission || profileData?.hasOrganizerPermission) && (
            <CustomTabPanel value={value} index={0}>
              <Stack
                gap={4}
                sx={{
                  marginBottom: '100px',
                }}
              >
                {draftedItems?.size > 0 ? (
                  <DraftTable data={draftedItems?.homeDTOS} profileId={parseFloat(id)} onDraftDeleteSuccess={handleDraftDeleteSuccess} />
                ) : (
                  <Box>{draftLoading ? <p>Loading...</p> : <p>No drafted items</p>}</Box>
                )}
              </Stack>
            </CustomTabPanel>
          )}
          <CustomTabPanel
            value={value}
            index={isAuthenticated && (profileData?.hasPermission || profileData?.hasOrganizerPermission) ? 1 : 0}
          >
            <Stack
              gap={4}
              sx={{
                marginBottom: '100px',
              }}
            >
              {organizationDetails?.size > 0 ? (
                organizationDetails?.homeDTOS?.map((activity: any) =>
                  activity?.eventType === 'Activity' ? (
                    <SingleActivityCard
                      changeOrgId={changeOrgId}
                      handleCancel={handleCancel}
                      isVolunteerModalOpen={isVolunteerModalOpen}
                      handleInstructionsModal={handleInstructionsModal}
                      currentActivityId={currentActivityId}
                      getCurrentActivity={getCurrentActivity}
                      clearCurrentActivity={clearCurrentActivity}
                      showOrgDialog={showOrgDialog}
                      selectedOption={selectedOption}
                      selectedOrgId={selectedOrgId}
                      handleOrgsDialog={handleOrgsDialog}
                      onLikeSuccess={handleLikeSuccess}
                      onBookmarkSuccess={handleBookmarkSuccess}
                      activity={activity}
                      key={activity.id}
                      eventGallery={activity.eventGallery}
                      handleDeleteClick={handleDeleteClick}
                    />
                  ) : activity?.eventType === 'Blog' ? (
                    <BlogCard blog={activity} key={activity.id} />
                  ) : (
                    <PetitionCard key={activity.id} petition={activity} handleDeletePetition={handleDeletePetition} />
                  )
                )
              ) : (
                <Box>{organizationDetailsLoading ? <p>Loading...</p> : <p>No participated events</p>}</Box>
              )}
            </Stack>
          </CustomTabPanel>

          <CustomTabPanel
            value={value}
            index={isAuthenticated && (profileData?.hasPermission || profileData?.hasOrganizerPermission) ? 2 : 1}
          >
            <Stack
              gap={4}
              sx={{
                marginBottom: '100px',
              }}
            >
              {organizationDetails?.size > 0 ? (
                organizationDetails?.homeDTOS?.map((activity: any) =>
                  activity?.eventType === 'Activity' ? (
                    <SingleActivityCard
                      changeOrgId={changeOrgId}
                      handleCancel={handleCancel}
                      isVolunteerModalOpen={isVolunteerModalOpen}
                      handleInstructionsModal={handleInstructionsModal}
                      currentActivityId={currentActivityId}
                      getCurrentActivity={getCurrentActivity}
                      clearCurrentActivity={clearCurrentActivity}
                      showOrgDialog={showOrgDialog}
                      selectedOption={selectedOption}
                      selectedOrgId={selectedOrgId}
                      handleOrgsDialog={handleOrgsDialog}
                      onLikeSuccess={handleLikeSuccess}
                      onBookmarkSuccess={handleBookmarkSuccess}
                      activity={activity}
                      key={activity.id}
                      eventGallery={activity.eventGallery}
                      handleDeleteClick={handleDeleteClick}
                    />
                  ) : activity?.eventType === 'Blog' ? (
                    <BlogCard blog={activity} key={activity.id} />
                  ) : (
                    <PetitionCard key={activity.id} petition={activity} handleDeletePetition={handleDeletePetition} />
                  )
                )
              ) : (
                <Box>{organizationDetailsLoading ? <p>Loading...</p> : <p>No oraganized events</p>}</Box>
              )}
            </Stack>
          </CustomTabPanel>
        </Box>
      </div>
    </Box>
  );
};

export default OrganizationActivityDetails;
