import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { serializeAxiosError } from '../reducer.utils';
import toast from 'react-hot-toast';

export interface blockedAccounts {
  fetchLoading: boolean;
  fetchError: object | null | string;
  fetchSuccess: object | null | string;
  blockedAccounts: object | null;
  unblockSuccess: object | null | boolean;
  unnblockError: object | null | string;

  currentVisibilitySuccess: object | null;
  currentVisibilityError: object | null;
}

interface BlockPayloadParams {
  userId: number;
}
const initialState = {
  fetchLoading: false,
  fetchError: null,
  fetchSuccess: null,
  blockedAccounts: null,
  currentVisibility: null,
  unblockSuccess: null,
  unnblockError: null,
  currentVisibilitySuccess: null,
  currentVisibilityError: null,
};

// remove from blocked accounts
export const getBlockedAccounts = createAsyncThunk(
  'settings/privacy/blocked-accounts',
  async () => axios.get<any>('/api/profile/settings-blocked-participants'),
  {
    serializeError: serializeAxiosError,
  }
);

// send profile visibility
export const unblockAccount = createAsyncThunk(
  'settings/unblock',
  async ({ userId }: BlockPayloadParams) => axios.post<any>('/api/profile/unblocked-participant', { userId }),
  {
    serializeError: serializeAxiosError,
  }
);

const settingsBlockAccounts = createSlice({
  name: 'blockedAccounts',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      .addCase(unblockAccount.pending, state => {
        state.fetchLoading = true;
        state.unblockSuccess = null;
        state.unnblockError = null;
      })
      .addCase(unblockAccount.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.unblockSuccess = true;
        state.unnblockError = null;
        toast.success(action?.payload?.data?.message);
      })
      .addCase(unblockAccount.rejected, (state, action) => {
        state.fetchLoading = false;
        state.unblockSuccess = false;
        state.unnblockError = true;
        toast.error('Something went wrong');
      })
      .addCase(getBlockedAccounts.pending, (state, action) => {
        state.fetchLoading = true;
        state.currentVisibilitySuccess = null;
        state.currentVisibilityError = null;
        state.currentVisibility = null;
      })
      .addCase(getBlockedAccounts.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.currentVisibilitySuccess = true;
        state.currentVisibilityError = null;
        state.currentVisibility = action.payload.data;
      })

      .addCase(getBlockedAccounts.rejected, state => {
        state.fetchLoading = false;
        state.currentVisibilitySuccess = false;
        state.currentVisibilityError = true;
      });
  },
});

export default settingsBlockAccounts.reducer;
