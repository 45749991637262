// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.org-invitation-avatar {
  max-width: -moz-min-content !important;
  max-width: min-content !important;
}

.org-invitation-btn {
  cursor: pointer !important;
}

@media only screen and (max-width: 992px) {
  .org-invitation-button-mob {
    max-width: 28% !important;
    padding-left: 0px !important;
  }
}
.org-invitation-pt-0 {
  padding: 0px !important;
}

@media only screen and (max-width: 600px) {
  .mr-block {
    margin-right: 0px;
  }
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/ui/settings/settings-body/settings-panels/settings-privacy/blocked-accounts/block-cards/block-cards.scss"],"names":[],"mappings":"AAAA;EACE,sCAAA;EAAA,iCAAA;AACF;;AACA;EACE,0BAAA;AAEF;;AAAA;EACE;IACE,yBAAA;IACA,4BAAA;EAGF;AACF;AADA;EACE,uBAAA;AAGF;;AADA;EACE;IACE,iBAAA;EAIF;AACF","sourcesContent":[".org-invitation-avatar {\n  max-width: min-content !important;\n}\n.org-invitation-btn {\n  cursor: pointer !important;\n}\n@media only screen and (max-width: 992px) {\n  .org-invitation-button-mob {\n    max-width: 28% !important;\n    padding-left: 0px !important;\n  }\n}\n.org-invitation-pt-0 {\n  padding: 0px !important;\n}\n@media only screen and (max-width: 600px) {\n  .mr-block {\n    margin-right: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
