import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import toast from 'react-hot-toast';

// Define the initial state for the organization module
const initialState = {
  organization: null,
  organizationLoading: false,
  organizationSuccess: false,
  organizationError: null,

  userOwnedOrganization: [],
  userOwnedOrganizationLoading: false,
  userOwnedOrganizationSuccess: false,
  userOwnedOrganizationError: null,

  userGrantedOrganizations: [],
  userGrantedOrganizationsLoading: false,
  userGrantedOrganizationsSuccess: false,
  userGrantedOrganizationsError: null,

  draftedItems: null,
  draftLoading: false,
  draftSuccess: false,
  draftError: null,
  draftCount: null,

  organizationDetails: null,
  organizationDetailsLoading: false,
  organizationDetailsSuccess: false,
  organizationDetailsError: null,

  handleInvitationLoading: false,
  handleInvitationSuccess: false,
  handleInvitationError: null,
  handleInvitationType: '',
  handleInvitationOrgId: null,

  orgMileStonesLoading: false,
  orgMileStonesSuccess: false,
  orgMileStonesError: null,
  orgMileStones: null,
};

// Define the filter type for organization details
export interface organizationFilterType {
  organizationId: number;
  filterType: string;
}

export interface FetchUserOrganizationsParams {
  userId: string; // Change the type according to your needs
}

export const handleInvitation = createAsyncThunk(
  'organization/handleInvitation',
  async (payload: { email: string; organizationId: number; status: string }, thunkAPI) => {
    try {
      const organizationIdNumeric = payload.organizationId.toString().match(/\d+$/)?.[0];
      const updatedRequestData = {
        ...payload,
        organizationId: organizationIdNumeric,
      };
      const result = await axios.put(`api/organization-invitees`, updatedRequestData);
      return result.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || 'Failed to handle invitation: ');
    }
  }
);

export const fetchOrganization = createAsyncThunk<any, any, { rejectValue: string }>(
  'organization/fetchOrganization',
  async (id, thunkAPI) => {
    try {
      const idStr = id.toString();
      const numericId = idStr.match(/\d+$/)?.[0];
      const response = await axios.get(`/api/organizations/${numericId}`);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch organization');
    }
  }
);

export const fetchUnauthorizedOrganization = createAsyncThunk<any, any, { rejectValue: string }>(
  'organization/fetchOrganization',
  async (id, thunkAPI) => {
    try {
      const idStr = id.toString();
      const numericId = idStr.match(/\d+$/)?.[0];
      const response = await axios.get(`/api/unauthorized/organizations/${numericId}`);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch organization');
    }
  }
);

export const fetchUserOrganizations = createAsyncThunk<any, any, { rejectValue: string }>(
  'organization/fetchUserOrganizations',
  async (id, thunkAPI) => {
    try {
      const idStr = id.toString();
      const numericId = idStr.match(/\d+$/)?.[0];
      const response = await axios.get(`/api/user-engaged-organizations/${numericId}`);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch organization');
    }
  }
);

export const fetchUserConnectedOrganizations = createAsyncThunk<any, any, { rejectValue: string }>(
  'organization/fetchUserConnectedOrganizations',
  async (id, thunkAPI) => {
    try {
      const idStr = id.toString();
      const numericId = idStr.match(/\d+$/)?.[0];
      const response = await axios.get(`/api/user-engaged-organizations/${numericId}`);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch organization');
    }
  }
);

export const fetchUserGrantedOrganizations = createAsyncThunk<any, void, { rejectValue: string }>(
  'organization/fetchUserGrantedOrganizations',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get('/api/organizations/user');

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch organization');
    }
  }
);

export const fetchOrganizationDraftedItems = createAsyncThunk<any, organizationFilterType, { rejectValue: string }>(
  'profile/fetchDraftedItems',
  async (requestData, thunkAPI) => {
    try {
      const organizationIdNumeric = requestData.organizationId.toString().match(/\d+$/)?.[0];
      const updatedRequestData = {
        ...requestData,
        organizationId: organizationIdNumeric,
      };
      const response = await axios.post('/api/organization/filter', updatedRequestData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch drafts');
    }
  }
);

export const fetchUnauthorizedOrganizationDraftedItems = createAsyncThunk<any, organizationFilterType, { rejectValue: string }>(
  'profile/fetchDraftedItems',
  async (requestData, thunkAPI) => {
    try {
      const organizationIdNumeric = requestData.organizationId.toString().match(/\d+$/)?.[0];
      const updatedRequestData = {
        ...requestData,
        organizationId: organizationIdNumeric,
      };
      const response = await axios.post('/api/unauthorized/organization/filter', updatedRequestData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch drafts');
    }
  }
);

export const fetchOrganizationDetails = createAsyncThunk<any, organizationFilterType, { rejectValue: string }>(
  'profile/fetchOrganizationDetails',
  async (requestData, thunkAPI) => {
    try {
      const organizationIdNumeric = requestData.organizationId.toString().match(/\d+$/)?.[0];
      const updatedRequestData = {
        ...requestData,
        organizationId: organizationIdNumeric,
      };
      const response = await axios.post('/api/organization/filter', updatedRequestData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch profile details');
    }
  }
);

export const fetchUnauthorizedOrganizationDetails = createAsyncThunk<any, organizationFilterType, { rejectValue: string }>(
  'profile/fetchOrganizationDetails',
  async (requestData, thunkAPI) => {
    try {
      const organizationIdNumeric = requestData.organizationId.toString().match(/\d+$/)?.[0];
      const updatedRequestData = {
        ...requestData,
        organizationId: organizationIdNumeric,
      };
      const response = await axios.post('/api/unauthorized/organization/filter', updatedRequestData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch profile details');
    }
  }
);

export const fetchOrgMileStones = createAsyncThunk<any, any, { rejectValue: string }>(
  'organization/fetchOrgMileStones',
  async (id, thunkAPI) => {
    try {
      let response;

      if (localStorage.getItem('firebaseToken')) {
        const idStr = id.toString();
        const numericId = idStr.match(/\d+$/)?.[0];
        response = await axios.get(`/api/profile/${numericId}/organizationMilestones`);
      } else {
        const idStr = id.toString();
        const numericId = idStr.match(/\d+$/)?.[0];
        response = await axios.get(`/api/unauthorized/profile/${numericId}/organizationMilestones`);
      }

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch milestones');
    }
  }
);

export const fetchOrgImpactReport = createAsyncThunk<
  any,
  any,
  {
    rejectValue: string;
  }
>('profile/impactReportForOrganization', async (id, thunkAPI) => {
  try {
    const offsetMinutes = new Date().getTimezoneOffset();
    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
    const offsetMins = Math.abs(offsetMinutes) % 60;
    const sign = offsetMinutes > 0 ? '-' : '+';
    const zoneId = `${sign}${String(offsetHours).padStart(2, '0')}:${String(offsetMins).padStart(2, '0')}`;

    const response = await axios.post(`/api/profile/${id}/impactReportForOrganization`, {
      zoneId: zoneId,
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch report');
  }
});

// Create the organization slice
const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(handleInvitation.pending, state => {
        state.handleInvitationLoading = true;
        state.handleInvitationSuccess = false;
        state.handleInvitationError = null;
      })
      .addCase(handleInvitation.fulfilled, (state, action) => {
        state.handleInvitationLoading = false;
        state.handleInvitationSuccess = true;
        state.handleInvitationError = null;
        state.handleInvitationType = action.meta.arg.status;
        state.handleInvitationOrgId = action.meta.arg.organizationId;
        if (action.meta.arg.status === 'DECLINED') {
          toast.success('Invitation rejected successfully!');
        } else {
          toast.success('Invitation accepted successfully!');
        }
      })
      .addCase(handleInvitation.rejected, (state, action) => {
        state.handleInvitationLoading = false;
        state.handleInvitationSuccess = false;
        state.handleInvitationError = action.payload;
        toast.error(`${action.payload}`);
      })
      .addCase(fetchOrganization.pending, state => {
        state.organizationLoading = true;
        state.organizationSuccess = false;
        state.organizationError = null;
        state.organization = null;
      })
      .addCase(fetchOrganization.fulfilled, (state, action) => {
        state.organizationLoading = false;
        state.organizationSuccess = true;
        state.organizationError = null;
        state.organization = action.payload;
      })
      .addCase(fetchOrganization.rejected, (state, action) => {
        state.organizationLoading = false;
        state.organizationSuccess = false;
        state.organizationError = action.payload;
        state.organization = null;
      })

      .addCase(fetchUserOrganizations.pending, state => {
        state.userOwnedOrganizationLoading = true;
        state.userOwnedOrganizationSuccess = false;
        state.userOwnedOrganizationError = null;
        state.userOwnedOrganization = null;
      })
      .addCase(fetchUserOrganizations.fulfilled, (state, action) => {
        const sortedOrgs = action.payload.sort((a, b) => a.label.localeCompare(b.label));

        state.userOwnedOrganizationLoading = false;
        state.userOwnedOrganizationSuccess = true;
        state.userOwnedOrganizationError = null;
        state.userOwnedOrganization = sortedOrgs;
      })
      .addCase(fetchUserOrganizations.rejected, (state, action) => {
        state.userOwnedOrganizationLoading = false;
        state.userOwnedOrganizationSuccess = false;
        state.userOwnedOrganizationError = action.payload;
        state.userOwnedOrganization = null;
      })
      .addCase(fetchUserGrantedOrganizations.pending, state => {
        state.userGrantedOrganizationsLoading = true;
        state.userGrantedOrganizationsSuccess = false;
        state.userGrantedOrganizationsError = null;
        state.userGrantedOrganizations = [];
      })
      .addCase(fetchUserGrantedOrganizations.fulfilled, (state, action) => {
        state.userGrantedOrganizationsLoading = false;
        state.userGrantedOrganizationsSuccess = true;
        state.userGrantedOrganizationsError = null;
        state.userGrantedOrganizations = action.payload;
      })
      .addCase(fetchUserGrantedOrganizations.rejected, (state, action) => {
        state.userGrantedOrganizationsLoading = false;
        state.userGrantedOrganizationsSuccess = false;
        state.userGrantedOrganizationsError = action.payload;
        state.userGrantedOrganizations = [];
      })

      .addCase(fetchOrganizationDraftedItems.pending, state => {
        state.draftLoading = true;
        state.draftSuccess = false;
        state.draftError = null;
      })
      .addCase(fetchOrganizationDraftedItems.fulfilled, (state, action) => {
        state.draftLoading = false;
        state.draftSuccess = true;
        state.draftError = null;
        state.draftedItems = action.payload;
        state.draftCount = action.payload;
      })
      .addCase(fetchOrganizationDraftedItems.rejected, (state, action) => {
        state.draftLoading = false;
        state.draftSuccess = false;
        state.draftError = action.payload;
      })

      .addCase(fetchOrganizationDetails.pending, state => {
        state.organizationDetailsLoading = false;
        state.organizationDetailsSuccess = false;
        state.organizationDetails = null;
      })
      .addCase(fetchOrganizationDetails.fulfilled, (state, action) => {
        state.organizationDetailsLoading = false;
        state.organizationDetailsSuccess = false;
        state.organizationDetailsError = null;
        state.organizationDetails = action.payload;
      })
      .addCase(fetchOrganizationDetails.rejected, (state, action) => {
        state.organizationDetailsLoading = false;
        state.organizationDetailsSuccess = false;
        state.organizationDetailsError = action.payload;
        state.organizationDetails = null;
      })

      .addCase(fetchOrgMileStones.pending, state => {
        state.orgMileStonesLoading = false;
        state.orgMileStonesSuccess = false;
        state.orgMileStones = null;
      })
      .addCase(fetchOrgMileStones.fulfilled, (state, action) => {
        state.orgMileStonesLoading = false;
        state.orgMileStonesSuccess = false;
        state.orgMileStonesError = null;
        state.orgMileStones = action.payload;
      })
      .addCase(fetchOrgMileStones.rejected, (state, action) => {
        state.orgMileStonesLoading = false;
        state.orgMileStonesSuccess = false;
        state.orgMileStonesError = action.payload;
        state.orgMileStones = null;
      });
  },
});

// Export the organization reducer
export default organizationSlice.reducer;
