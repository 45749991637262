import { Button, Grid, Stack, Typography, useTheme } from '@mui/material';
import { useAppDispatch } from 'app/config/store';
import theme from 'app/styles/theme/theme';
import React, { useState, useEffect, useRef } from 'react';
import { Storage } from 'react-jhipster';
import OtpInput from 'react-otp-input';
import { verifyOtp, getOtp } from 'app/shared/reducers/phone-verification';
import toast from 'react-hot-toast';

interface VerifyOtpSectionProps {
  setotpVerificationCompleted: (completed: any) => void;
  setPhoneInputCompleted: (completed: any) => void;
  handleClose: () => void;
  otpVerificationCompleted: boolean;
}

function VerifyOtpSection({
  setotpVerificationCompleted,
  setPhoneInputCompleted,
  handleClose,
  otpVerificationCompleted,
}: VerifyOtpSectionProps) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const sendOtp = async (phoneNo, medium, countryCode) => {
    try {
      const response = await dispatch(getOtp({ phoneNumber: phoneNo, medium: medium, countryCode: countryCode }));
      return response;
    } catch (error) {
      throw error;
    }
  };
  const VerifyingOtp = async otp => {
    try {
      const countryCode = Storage.session.get('country-code');
      const phoneNo = Storage.session.get('phone-number');
      const country = Storage.session.get('country');
      const response = await dispatch(verifyOtp({ phoneNumber: phoneNo, countryCode: countryCode, code: otp, isoCode: country }));
      if (!(response.meta.requestStatus == 'fulfilled')) {
        setError('OTP verification Unsuccessfull!');
        setotpVerificationCompleted(false);
        Storage.session.set('otp-verification', false);
        // Stop the timer
        clearInterval(timerInterval);
        // Show the Resend OTP section
        setTimeRemaining(0);
        //reset OTP and clear timer interval
        setOtp('');
      } else {
        setError('');
        setotpVerificationCompleted(true);
        Storage.session.set('otp-verification', true);
        // Show success toast notification
        toast.success('OTP verification successfull!', { id: 'otp-success' });
      }
    } catch (error) {
      setError('OTP verification Unsuccessfull!');
      setotpVerificationCompleted(false);
      Storage.session.set('otp-verification', false);
      // Stop the timer
      clearInterval(timerInterval);
      // Show the Resend OTP section
      setTimeRemaining(0);
      //reset OTP and clear timer interval
      setOtp('');
      throw error;
    }
  };

  const handleResendOTP = async () => {
    const country = Storage.session.get('country');
    const countryCode = Storage.session.get('country-code');
    const phoneNo = Storage.session.get('phone-number');
    const medium = Storage.session.get('medium');
    const response = await sendOtp(phoneNo, medium, countryCode);
    if (!(response.meta.requestStatus == 'fulfilled')) {
      setError('OTP Resent Unsuccessfull!');
      setOtp('');
    } else {
      setOtp('');
      toast.success('OTP Resent successfull!', { id: 'otp-resend-success' });
      setTimeRemaining(5 * 60); // Reset the timer to 5 minutes
    }
  };

  const handleSubmit = async () => {
    VerifyingOtp(otp);
  };
  useEffect(() => {
    if (otpVerificationCompleted) {
      handleClose();
    }
  }, [otpVerificationCompleted, handleClose]);

  function formatTime(timeInSeconds: number): string {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;

    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

    return `${formattedMinutes}:${formattedSeconds}`;
  }

  const [timeRemaining, setTimeRemaining] = useState(5 * 60);
  const [timerInterval, setTimerInterval] = useState<number | null>(null);

  const handleChangeNumber = () => {
    setPhoneInputCompleted(false);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(prevTimeRemaining => {
        if (prevTimeRemaining > 0) {
          return prevTimeRemaining - 1;
        } else {
          // Timer finished, reset OTP and clear timer interval
          setOtp('');
          clearInterval(timer);
          return 0;
        }
      });
    }, 1000);
    setTimerInterval(timer);

    return () => {
      clearInterval(timer); // Clean up the interval when the component unmounts
    };
  }, []);

  return (
    <Grid container gap={2} justifyContent="center" alignItems="center" direction={'column'}>
      <Typography variant="h5" align="center">
        Please enter the verification code
      </Typography>
      {error && (
        <Typography color="error" align="center">
          {error}
        </Typography>
      )}

      <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
        <OtpInput
          inputStyle={{
            width: '3rem',
            height: '3rem',
            border: '1px solid rgb(103, 75, 255)',
            borderRadius: '8px',
            margin: '0 3px',
            backgroundColor: isDarkMode ? '#2C2C38' : 'common.white',
            color: isDarkMode ? 'white' : '',
          }}
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span></span>}
          renderInput={props => <input {...props} type="tel" />}
          inputType="number"
        />
      </Stack>
      <Stack direction="row">
        <Stack direction={'column'} alignItems={'center'} gap={1}>
          {timeRemaining > 0 && <Stack>Time remaining: {formatTime(timeRemaining)}</Stack>}
          <Stack direction="row" alignItems={'center'} spacing={1}>
            <Typography>Didn't receive code?</Typography>
            <Button
              variant="text"
              sx={{
                textTransform: 'capitalize',
                backgroundColor: isDarkMode ? 'transparent' : 'common.white',
                borderColor: 'primary.light',
                color: 'primary.light',
                '&:hover': {
                  backgroundColor: isDarkMode ? 'transparent' : 'common.white',
                  borderColor: 'primary.light',
                  color: 'primary.light',
                  textTransform: 'capitalize',
                  boxShadow: 'none',
                },
              }}
              onClick={handleResendOTP}
            >
              Resend OTP
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <Stack direction="row" justifyContent="center" spacing={2}>
        <Button
          variant="outlined"
          sx={{
            textTransform: 'capitalize',
            backgroundColor: isDarkMode ? '#2C2C38' : 'common.white',
            borderColor: 'primary.light',
            color: 'primary.light',
            '&:hover': {
              backgroundColor: isDarkMode ? '#2C2C38' : 'common.white',
              borderColor: 'primary.light',
              color: 'primary.light',
              textTransform: 'capitalize',
              boxShadow: 'none',
            },
          }}
          onClick={handleChangeNumber}
        >
          Change number
        </Button>
        <Button
          variant="contained"
          sx={{
            textTransform: 'capitalize',
            backgroundColor: 'primary.light',
            color: 'common.white',
            '&:hover': {
              color: 'common.white',
              backgroundColor: 'primary.light',
              textTransform: 'capitalize',
              boxShadow: 'none',
            },
          }}
          disabled={otp.length !== 6}
          onClick={handleSubmit}
        >
          Verify
        </Button>
      </Stack>
    </Grid>
  );
}

export default VerifyOtpSection;
