import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiKeys } from 'app/utils/data/constants/api-keys';

export interface CountryType {
    id: number
    iso2: number
    name: string
}

export interface CountryState {
    countries: CountryType[] | null;
    loading: boolean;
    error: string | null;
}

export const initialState: CountryState = {
    countries: null,
    loading: false,
    error: null,
};


export const fetchCountries = createAsyncThunk('country/fetchCountries', async (_, thunkAPI) => {
    try {
        const API_KEY = apiKeys.LOCATION_FILTER_KEY;
        const headers = {
            'X-CSCAPI-KEY': API_KEY,
        };
        const response = await axios.get('https://api.countrystatecity.in/v1/countries', {
            headers,
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
});

const countrySlice = createSlice({
    name: 'countryReducer',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchCountries.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCountries.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.countries = action.payload;
            })
            .addCase(fetchCountries.rejected, (state, action) => {
                state.loading = false;
                state.error ='An error occurred';
            });
    },
});

export default countrySlice.reducer;