import React, { useEffect, useLayoutEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { logout } from 'app/shared/reducers/authentication';
import { Button, Grid, Stack, Typography, useTheme } from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

export const Logout = () => {
  const logoutUrl = useAppSelector(state => state.authentication.logoutUrl);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    dispatch(logout());
    if (logoutUrl) {
      window.location.href = logoutUrl;
    }
  });

  return (
    <div className="p-5">
      <Grid container alignItems="center" justifyContent={'center'} direction={'column'}>
        <Grid item>
          <h4>Logged out successfully!</h4>
        </Grid>
        <Button
          type="submit"
          variant="contained"
          sx={{
            marginBottom: '10px',
            width: '150px',
            height: theme.createActivity.height,
            backgroundColor: theme.palette.primary.main,
            borderRadius: theme.createActivity.borderRadius,
            color: '#292D34',
            borderColor: theme.palette.primary.main,
            boxShadow: 'none',
            '&:active, &:focus, &:hover': {
              backgroundColor: theme.small.backgroundColor.hover,
              boxShadow: 'none',
            },
          }}
          onClick={() => navigate('/')}
        >
          <span>
            <Typography style={{ textTransform: 'capitalize' }}>Back Home</Typography>
          </span>
        </Button>
      </Grid>
    </div>
  );
};

export default Logout;
