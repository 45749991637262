import { Box, Typography } from '@mui/material';
import React from 'react';

type Props = {
  color: string;
  label: string;
};

export default function TopLabel({ color, label }: Props) {
  return (
    <Box display="flex" alignItems="center" gap={0.8}>
      <Box height={10} width={10} borderRadius={0.7} bgcolor={color}></Box>
      <Typography color="#8D96A7" fontSize="0.6rem">
        {label}
      </Typography>
    </Box>
  );
}
