import React, { useEffect, useState, useRef } from 'react';
import { Storage } from 'react-jhipster';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { Box, useTheme } from '@mui/material';
import ChooseCitySearchBar from 'app/components/common/choose-city-search-bar/choose-city-search-bar';
import { useAppSelector } from 'app/config/store';

interface Props {
  setFullLocationDetail: any;
  setCity: any;
  city: any;
  setLocationManualyTyped?: any;
}

const PlacesAutocomplete = ({ setFullLocationDetail, setCity, city, setLocationManualyTyped }: Props) => {
  let locationFromStorage: any;
  const [ready, setReady] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const resultsRef = useRef<HTMLUListElement>(null);
  const {
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();
  const userSettings = useAppSelector(state => state.settingsReducer.userSettings);
  const userLocation = `${userSettings?.city ? userSettings.city : ''}${userSettings?.city && userSettings?.country ? ',' : ''} ${
    userSettings?.country ? userSettings?.country : ' '
  }`;

  useEffect(() => {
    if (userLocation && userSettings?.city !== undefined) {
      setValue(userLocation);
      setCity(userLocation);
    } else if (Storage.session.get('location')) {
      locationFromStorage = Storage.session.get('location');
      const city = Storage.session.get('city');
      setFullLocationDetail(locationFromStorage);
      setValue(city);
      setCity(city);
    }
  }, [userSettings]);

  const handleSelect = async (address: string) => {
    console.log('SettingsChangeLocation');
    if (setLocationManualyTyped) setLocationManualyTyped(false);
    setValue(address, false);
    setCity(address);
    clearSuggestions();

    const results = await getGeocode({ address });
    const latLng = getLatLng(results[0]);

    const addressComponents = results[0]?.address_components;
    let choosenCity = '',
      state = '',
      country = '';

    for (let i = 0; i < addressComponents.length; i++) {
      const component = addressComponents[i];

      if (component.types.includes('locality')) {
        choosenCity = component.long_name;
      }

      if (component.types.includes('administrative_area_level_1')) {
        state = component.long_name;
      }

      if (component.types.includes('country')) {
        country = component.long_name;
      }
    }

    let location = {
      city: choosenCity,
      state: state,
      country: country,
      latitude: latLng.lat,
      longitude: latLng.lng,
    };

    Storage.session.set('location', location);
    Storage.session.set('city', address);

    setFullLocationDetail(location);
    setReady(false);
  };

  const handleChange = (input: string) => {
    if (setLocationManualyTyped) {
      setLocationManualyTyped(true);
    }
    setReady(true);
    setValue(input);

    if (input.trim() === '' && city) {
      setCity('');
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!ready) return;

    if (event.key === 'ArrowDown') {
      setActiveIndex(prevIndex => (prevIndex + 1) % data.length);
    } else if (event.key === 'ArrowUp') {
      setActiveIndex(prevIndex => (prevIndex - 1 + data.length) % data.length);
    } else if (event.key === 'Enter') {
      handleSelect(data[activeIndex].description);
    }
  };

  useEffect(() => {
    if (resultsRef.current && activeIndex >= 0 && activeIndex < resultsRef.current.children.length) {
      (resultsRef.current.children[activeIndex] as HTMLLIElement).scrollIntoView({ block: 'nearest' });
    }
  }, [activeIndex]);

  const theme = useTheme();

  return (
    <Box onKeyDown={handleKeyDown}>
      <ChooseCitySearchBar
        placeholder="Choose location"
        value={value}
        city={city}
        onChange={handleChange}
        clear={() => {
          clearSuggestions();
          setValue('');
        }}
      />
      {ready && (
        <ul
          ref={resultsRef}
          style={{
            width: '100%',
            listStyle: 'none',
            borderRadius: '4px',
            backgroundColor: theme.palette.mode === 'dark' ? '#1f1f2b' : '#fff',
            padding: 0,
            margin: 0,
            // position: 'absolute',
            zIndex: 1,
          }}
        >
          {status === 'OK' &&
            data?.map(({ place_id, description }: any, index: number) => (
              <li
                key={place_id}
                onClick={() => handleSelect(description)}
                role="button"
                style={{
                  padding: '8px',
                  backgroundColor: activeIndex === index ? '#757575' : '',
                  cursor: 'pointer',
                }}
              >
                {description}
              </li>
            ))}
        </ul>
      )}
    </Box>
  );
};

export default PlacesAutocomplete;
