import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './user-preferences.reducer';

export const UserPreferencesDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const userPreferencesEntity = useAppSelector(state => state.userPreferences.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="userPreferencesDetailsHeading">
          <Translate contentKey="reachamyApp.userPreferences.detail.title">UserPreferences</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{userPreferencesEntity.id}</dd>
          <dt>
            <span id="userID">
              <Translate contentKey="reachamyApp.userPreferences.userID">User ID</Translate>
            </span>
          </dt>
          <dd>{userPreferencesEntity.userID}</dd>
          <dt>
            <span id="profilePic">
              <Translate contentKey="reachamyApp.userPreferences.profilePic">Profile Pic</Translate>
            </span>
          </dt>
          <dd>{userPreferencesEntity.profilePic}</dd>
          <dt>
            <span id="contactEmail">
              <Translate contentKey="reachamyApp.userPreferences.contactEmail">Contact Email</Translate>
            </span>
          </dt>
          <dd>{userPreferencesEntity.contactEmail}</dd>
          <dt>
            <span id="country">
              <Translate contentKey="reachamyApp.userPreferences.country">Country</Translate>
            </span>
          </dt>
          <dd>{userPreferencesEntity.country}</dd>
          <dt>
            <span id="bio">
              <Translate contentKey="reachamyApp.userPreferences.bio">Bio</Translate>
            </span>
          </dt>
          <dd>{userPreferencesEntity.bio}</dd>
          <dt>
            <span id="darkMode">
              <Translate contentKey="reachamyApp.userPreferences.darkMode">Dark Mode</Translate>
            </span>
          </dt>
          <dd>{userPreferencesEntity.darkMode ? 'true' : 'false'}</dd>
          <dt>
            <span id="personalizeAdsBasedOnInfoFromOurPartners">
              <Translate contentKey="reachamyApp.userPreferences.personalizeAdsBasedOnInfoFromOurPartners">
                Personalize Ads Based On Info From Our Partners
              </Translate>
            </span>
          </dt>
          <dd>{userPreferencesEntity.personalizeAdsBasedOnInfoFromOurPartners ? 'true' : 'false'}</dd>
          <dt>
            <span id="personalizeRecommendationsBasedOnYourGeneralLocation">
              <Translate contentKey="reachamyApp.userPreferences.personalizeRecommendationsBasedOnYourGeneralLocation">
                Personalize Recommendations Based On Your General Location
              </Translate>
            </span>
          </dt>
          <dd>{userPreferencesEntity.personalizeRecommendationsBasedOnYourGeneralLocation ? 'true' : 'false'}</dd>
          <dt>
            <span id="notificationsInboxMessages">
              <Translate contentKey="reachamyApp.userPreferences.notificationsInboxMessages">Notifications Inbox Messages</Translate>
            </span>
          </dt>
          <dd>{userPreferencesEntity.notificationsInboxMessages ? 'true' : 'false'}</dd>
          <dt>
            <span id="notificationsEmailMessages">
              <Translate contentKey="reachamyApp.userPreferences.notificationsEmailMessages">Notifications Email Messages</Translate>
            </span>
          </dt>
          <dd>{userPreferencesEntity.notificationsEmailMessages ? 'true' : 'false'}</dd>
          <dt>
            <span id="notificationsUpvotesOnYourPosts">
              <Translate contentKey="reachamyApp.userPreferences.notificationsUpvotesOnYourPosts">
                Notifications Upvotes On Your Posts
              </Translate>
            </span>
          </dt>
          <dd>{userPreferencesEntity.notificationsUpvotesOnYourPosts ? 'true' : 'false'}</dd>
          <dt>
            <span id="notificationsRepliesToYourComments">
              <Translate contentKey="reachamyApp.userPreferences.notificationsRepliesToYourComments">
                Notifications Replies To Your Comments
              </Translate>
            </span>
          </dt>
          <dd>{userPreferencesEntity.notificationsRepliesToYourComments ? 'true' : 'false'}</dd>
          <dt>
            <span id="notificationsActivityOnYourPosts">
              <Translate contentKey="reachamyApp.userPreferences.notificationsActivityOnYourPosts">
                Notifications Activity On Your Posts
              </Translate>
            </span>
          </dt>
          <dd>{userPreferencesEntity.notificationsActivityOnYourPosts ? 'true' : 'false'}</dd>
        </dl>
        <Button tag={Link} to="/user-preferences" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/user-preferences/${userPreferencesEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default UserPreferencesDetail;
