import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DustbinIcon from 'app/components/icons/profile/dust-bin-icon';
import { TablePagination, useTheme } from '@mui/material';
import { formatDateToNormal } from 'app/utils/common-utils/common-utils';
import PublishIconNormal from 'app/components/icons/profile/publish-icon';
import { deleteActivityById, deleteActivityByIds } from 'app/shared/reducers/activity-home';
import { useAppDispatch } from 'app/config/store';
import { deletePetitionById } from 'app/shared/reducers/petition-home';
import toast from 'react-hot-toast';
import { discardAllDrafts, fetchDraftedItems } from 'app/shared/reducers/activity/activity-draft';
import { useLocation, useNavigate } from 'react-router-dom';
import PublishIcon from '@mui/icons-material/Publish';
import DeleteIcon from '@mui/icons-material/Delete';
import './draft-table.scss';

interface Data {
  id: number;
  eventType: string;
  title: string;
  createdAt: string;
  name: string;
  protein: number;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Discard draft',
  },
  {
    id: 'eventType',
    numeric: false,
    disablePadding: false,
    label: '',
  },
  {
    id: 'createdAt',
    numeric: true,
    disablePadding: false,
    label: '',
  },
  {
    id: 'title',
    numeric: false,
    disablePadding: false,
    label: '',
  },
  {
    id: 'protein',
    numeric: false,
    disablePadding: false,
    label: '',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  const location = useLocation();

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all drafts',
            }}
            sx={{
              '&.Mui-checked': { color: 'primary.light' },
              '& .MuiSvgIcon-root': {
                padding: '3px',
                color: numSelected > 0 && numSelected < rowCount ? 'primary.light' : '',
              },
            }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell key={headCell.id} align={headCell.numeric ? 'right' : 'left'} padding={headCell.disablePadding ? 'none' : 'normal'}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

interface EnhancedTableToolbarProps {
  numSelected: number;
  data: any;
  profileId: any;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected, data, profileId } = props;
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const handleDiscardDraft = async () => {
    const payloadObject = data.map(item => ({
      id: item?.id,
      eventType: item?.eventType,
    }));
    if (payloadObject.length > 0) {
      const apiPromises = payloadObject.map(item => {
        if (item.eventType === 'Activity') {
          return dispatch(deleteActivityByIds({ activityIds: [item.id], organizationId: 0, message: '' }));
        } else if (item.eventType === 'Petition') {
          return dispatch(deletePetitionById({ petitionId: item.id.toString() }));
        }
      });

      try {
        const responses = await Promise.all(apiPromises);
        dispatch(fetchDraftedItems({ id: profileId, eventType: 'Draft' }));
        toast.dismiss();
        toast.success('Drafts discarded successfully');
      } catch (error) {
        console.log('Error in API calls:', error);
        toast('Error discarding drafts');
      }
    }
  };
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: theme => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : null}
      {numSelected > 0 ? (
        <Tooltip title="Discard All">
          <IconButton onClick={handleDiscardDraft} sx={{ marginRight: '40px' }}>
            {theme.palette.mode === 'dark' ? <DeleteIcon sx={{ fontSize: 14 }} /> : <DustbinIcon sx={{ fontSize: 14 }} />}
          </IconButton>
        </Tooltip>
      ) : null}
    </Toolbar>
  );
}
export default function DraftTable({ data, profileId, onDraftDeleteSuccess }: any) {
  const dispatch = useAppDispatch();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('title');
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const theme = useTheme();

  const navigate = useNavigate();

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data?.map(n => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const visibleRows = React.useMemo(
    () => stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [data, order, orderBy, page, rowsPerPage]
  );

  const handleDustbinClick = async (event: React.MouseEvent<unknown>, id: string | number, type: string | number) => {
    if (type === 'Activity') {
      console.log('check activity');
      const activityDeleteResponse = await dispatch(deleteActivityByIds({ activityIds: [id], organizationId: 0, message: '' }));
      if (activityDeleteResponse) {
        // data = data.filter(item => item.id !== id);
        // dispatch(fetchDraftedItems({ id: profileId, eventType: 'Draft' }));

        if (location.pathname === '/profile') {
          dispatch(fetchDraftedItems({ id: profileId, eventType: 'Draft' }));
        }
        if (onDraftDeleteSuccess) {
          onDraftDeleteSuccess();
        }
        // toast.dismiss();
      } else if (deleteActivityById.rejected.match(activityDeleteResponse)) {
        toast.error('Failed to delete draft');
      }
    } else if (type === 'Petition') {
      const petitionDeleteResponse = await dispatch(deletePetitionById({ petitionId: id.toString() }));
      if (petitionDeleteResponse) {
        // data = data.filter(item => item.id !== id);
        dispatch(fetchDraftedItems({ id: profileId, eventType: 'Draft' }));
        if (onDraftDeleteSuccess) {
          onDraftDeleteSuccess();
        }
        toast.dismiss();
        toast.success('Draft deleted successfully');
      } else if (deletePetitionById.rejected.match(petitionDeleteResponse)) {
        ``;
        toast.error('Failed to delete draft');
      }
    }
  };
  return (
    <Box sx={{ width: '100%', mb: 1 }}>
      <Paper sx={{ width: '100%', mb: 2, boxShadow: 'none' }}>
        <EnhancedTableToolbar numSelected={selected.length} data={data} profileId={profileId} />
        <TableContainer>
          <Table sx={{ minWidth: '100%' }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(Number(row.id));
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row.id} selected={isItemSelected}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        onClick={event => handleClick(event, Number(row.id))}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                        sx={{
                          cursor: 'pointer',
                          '&.Mui-checked': { color: 'primary.light' },
                          '& .MuiSvgIcon-root': {
                            padding: '3px',
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      onClick={() =>
                        navigate(row?.eventType === 'Activity' ? `/edit-activity/${row?.id}?s=draft` : `/edit-petition/${row?.id}`)
                      }
                      sx={{ color: 'primary.light', fontSize: '13px', fontWeight: 600, cursor: 'pointer' }}
                    >
                      {row?.eventType === 'Activity' ? 'Create Activity' : 'Create Petition'}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        color: theme.profile.color,
                        fontSize: '13px',
                        fontWeight: 600,
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                      }}
                    >
                      {typeof row?.title === 'string' && row?.title?.length > 20
                        ? `${row?.title.slice(0, 20)}...`
                        : typeof row?.title === 'string' && row?.title?.length === 0
                        ? '(no subject)'
                        : row?.title}
                    </TableCell>
                    <TableCell align="left">{formatDateToNormal(row.createdAt)}</TableCell>
                    <TableCell align="right" width={'30px'}>
                      {
                        <Tooltip title="Delete">
                          <IconButton onClick={event => handleDustbinClick(event, row?.id, row?.eventType)}>
                            {theme.palette.mode === 'dark' ? (
                              <DeleteIcon
                                sx={{ fontSize: 14, fontColor: theme.palette.mode === 'dark' ? 'white' : '', cursor: 'pointer' }}
                              />
                            ) : (
                              <DustbinIcon sx={{ fontSize: 14, cursor: 'pointer' }} />
                            )}
                          </IconButton>
                        </Tooltip>
                      }
                    </TableCell>
                    <TableCell align="right" width={'30px'}>
                      {/* <Tooltip
                        title="Publish"
                        onClick={() => navigate(row?.eventType === 'Activity' ? `/edit-activity/${row?.id}` : '/edit-petition')}
                      >
                        {theme.palette.mode === 'dark' ? (
                          <PublishIcon sx={{ fontSize: 14, fontColor: theme.palette.mode === 'dark' ? 'white' : '', cursor: 'pointer' }} />
                        ) : (
                          <PublishIconNormal sx={{ fontSize: 14, cursor: 'pointer' }} />
                        )}
                      </Tooltip> */}
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
