import { format } from 'date-fns';
import { linksData } from '../data/constants/constants';
import { Storage } from 'react-jhipster';
import { apiKeys } from '../data/constants/api-keys';
import axios from 'axios';
import moment from 'moment';

// calculate distance between 2 locations
export const calculateDistance = (userLocation, eventLocation) => {
  const R = 6371; // Earth's radius in kilometers
  // Convert latitude and longitude from degrees to radians
  const lat1Rad = (userLocation.latitude * Math.PI) / 180;
  const lon1Rad = (userLocation.longitude * Math.PI) / 180;
  const lat2Rad = (eventLocation.latitude * Math.PI) / 180;
  const lon2Rad = (eventLocation.longitude * Math.PI) / 180;
  // Calculate differences between latitudes and longitudes
  const dLat = lat2Rad - lat1Rad;
  const dLon = lon2Rad - lon1Rad;
  // Haversine formula
  const a = Math.sin(dLat / 2) ** 2 + Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(dLon / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  // Calculate the distance in meters
  const distance = R * c * 1000; // Convert to meters
  return distance;
};

//convert ISO date in to 'dd-MM-yyyy' format

export const transformDate = (startDate: string) => {
  const momentDate = moment(startDate);
  // Format the Moment object to the desired format
  const formattedDate = momentDate.format('DD-MM-YYYY');

  return formattedDate;
};

//convert ISO date in to 'dd MMM yyyy' format

export const convertDate = (postedDate: string) => {
  const date = new Date(postedDate);
  return format(date, 'dd MMM yyyy');
};

// convert date to hours Ago format

export const timestampToHoursAgo = (timestamp: string) => {
  const current_time = new Date();
  const given_time = new Date(timestamp);
  const time_difference = current_time.getTime() - given_time.getTime();

  const seconds_ago = Math.floor(time_difference / 1000);
  if (seconds_ago < 1) {
    return 'Just now';
  }

  if (seconds_ago < 60) {
    return seconds_ago + (seconds_ago === 1 ? ' sec ago' : ' secs ago');
  }

  const minutes_ago = Math.floor(seconds_ago / 60);
  if (minutes_ago < 60) {
    return minutes_ago + (minutes_ago === 1 ? ' min ago' : ' mins ago');
  }

  const hours_ago = Math.floor(minutes_ago / 60);
  if (hours_ago < 24) {
    return hours_ago + (hours_ago === 1 ? ' hr ago' : ' hrs ago');
  }

  const days_ago = Math.floor(hours_ago / 24);
  return days_ago + (days_ago === 1 ? ' day ago' : ' days ago');
};

export const convertDateToDay = (postedDate: string) => {
  // const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // const date = new Date(postedDate);
  // const day = date.getDate();
  // const month = months[date.getMonth()];
  // const year = date.getFullYear();
  const momentDate = moment(postedDate);
  const day = momentDate.format('DD');
  const month = momentDate.format('MMM');

  return `${day} ${month}`;
};
export const convertDateToYear = (postedDate: string) => {
  const momentDate = moment(postedDate);
  const day = momentDate.format('DD');
  const month = momentDate.format('MMM');
  const year = momentDate.format('YYYY');

  return `${day} ${month} ${year}`;
};

export const convertDateToFormattedString = (postedDate: string) => {
  const momentDate = moment(postedDate);
  const formattedString = momentDate.format('ddd, D MMM YYYY');

  return formattedString;
};

export const getFormattedTime = (postedDate: string) => {
  const date = new Date(postedDate);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Determine if it's AM or PM
  const meridiem = hours >= 12 ? 'PM' : 'AM';

  // Convert to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Add leading zeros if necessary
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

  return `${formattedHours}:${formattedMinutes} ${meridiem}`;
};

// generate url for lat and long
export const generateGMapsLinkCords = (latitude, longitude) => `https://www.google.com/maps?q=${latitude},${longitude}`;

// genertate url for place
export const generateGMapsLinkPlace = placeName => {
  const encodedPlaceName = encodeURIComponent(placeName);
  // Construct the Google Maps URL
  const googleMapsUrl = `${linksData.googleMapSearch}+${encodedPlaceName}`;
  return googleMapsUrl;
};

// Extract ID from share link
export const extractIdFromUrl = (url: string) => {
  //split url with '/'
  const spliitedUrl = url.split('/');
  const id = spliitedUrl[spliitedUrl.length - 1];
  return id;
};

export const extractIdFromUrlName = (url: string) => {
  const match = url.match(/(\d+)$/);
  return match ? match[0] : '';
};

//share link to socal media
export const shareOnSocialMedia = (type, url, title) => {
  let sharingUrl;

  if (type === 'twitter') {
    const tweetText = `${title}`;
    sharingUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}&url=${encodeURIComponent(url)}`;
  } else if (type === 'facebook') {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(title)}`;
    sharingUrl = facebookUrl;
  } else if (type === 'linkedIn') {
    const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?mini=true&url=${encodeURIComponent(url)}`;
    sharingUrl = linkedInUrl;
  } else {
    alert('Unsupported social media type');
    return;
  }

  window.open(sharingUrl, '_blank');
};

// get access token from storage
export const getAccessToken = async () => {
  try {
    // Attempt to retrieve the token from local storage
    const authTokenLocal = await Storage.local.get('jhi-authenticationToken');

    // If it exists in local storage, return it
    if (authTokenLocal) {
      return authTokenLocal;
    }

    // If not, try retrieving it from session storage
    const authTokenSession = await Storage.session.get('jhi-authenticationToken');

    // If it exists in session storage, return it
    if (authTokenSession) {
      return authTokenSession;
    }
    return null;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const getNearbyPlacesGmaps = async (latitude, longitude) => {
  // Construct the API URL
  const apiKey = apiKeys.GMAP_API_Key;
  const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

  try {
    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error(`Fetch failed with status ${response.status}`);
    }

    const data = await response.json();

    return data?.results[0];
  } catch (error) {
    // Handle errors
    console.error('Error fetching data:', error);
  }
};

export const getAddressDetails = addressObject => {
  let country, state, city;

  // Loop through the address components
  for (const component of addressObject.address_components) {
    if (component.types.includes('country')) {
      country = component.long_name;
    } else if (component.types.includes('administrative_area_level_1')) {
      state = component.long_name;
    } else if (component.types.includes('locality')) {
      city = component.long_name;
    }
  }

  return { country, state, city };
};
export function findDifferentFields(obj1, obj2) {
  for (const key in obj1) {
    if (key === 'activityStartTime' || key === 'activityEndTime' || key === 'startDate' || key === 'endDate' || key === 'lostPet') {
      // Ignore these fields
      continue;
    }

    if (Array.isArray(obj1[key]) && Array.isArray(obj2[key]) && obj1[key].length === obj2[key].length) {
      // Neglect arrays with the same length
      continue;
    }

    if (obj1[key] !== obj2[key]) {
      return true; // Found a difference
    }
  }

  return false; // No other differences found
}

export function formatDateToNormal(inputDate) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  // Format the date as "DD/MM/YYYY"
  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
}

//test email format
export const isValidEmail = (email: string) => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(email);
};

export const isValidSocial = (email: string) => {
  const emailRegex = /^https:\/\/.+/i;
  return emailRegex.test(email);
};

//  Tab paneel props
export function a11yProps(index: number, component: string) {
  return {
    id: `${component}-${index}`,
    'aria-controls': `${component}-${index}`,
  };
}

export function convertDateToDesiredFormat(dateString) {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return null;
  }

  const year = date.getFullYear();
  const month = date.toLocaleString('default', { month: 'short' });
  const day = date.getDate();

  if (!isNaN(year) && month && !isNaN(day)) {
    return `${day} ${month} ${year}`;
  } else {
    return null;
  }
}

const colorList = [
  '#E5243B',
  '#DDA63A',
  '#4C9F38',
  '#C5192D',
  '#FF3A21',
  '#26BDE2',
  '#FCC30B',
  '#A21942',
  '#FD6925',
  '#DD1367',
  '#FD9D24',
  '#BF8B2E',
  '#3F7E44',
  '#0A97D9',
  '#56C02B',
  '#00689D',
  '#19486A',
  '#A21942',
  '#BF8B2E',
];

export function getRandomColor() {
  const randomIndex = Math.floor(Math.random() * colorList.length);
  return colorList[randomIndex];
}

export function formatDate(dateString, formatString) {
  const inputDate = new Date(dateString);

  if (isNaN(inputDate.getTime()) || !/DD|MMM|YYYY/.test(formatString)) {
    return 'DD-MMM-YYYY'; // or your desired default format
  }

  const day = inputDate.getDate();
  const month = inputDate.toLocaleString('en-US', { month: 'short' });
  const year = inputDate.getFullYear();

  return formatString.replace(/DD/, day).replace(/MMM/, month).replace(/YYYY/, year);
}

export const truncateString = (str, maxLength) => {
  if (str?.length > maxLength) {
    return str.substring(0, maxLength) + '...';
  }
  return str;
};

export function getInitials(name) {
  if (!name) {
    return '';
  }

  return name
    .split(' ')
    .map(word => word.charAt(0))
    .join('');
}

export const clsx = (...classes: string[]) => {
  return classes
    .filter(Boolean)
    .map(className => className.trim())
    .join(' ');
};

export const encodedTitle = title => {
  return title.replace(/ /g, '-').toLowerCase();
};

export const validateSocialLink = url => {
  const socialMediaPattern = /^(https?:\/\/)?(www\.)?(facebook|twitter|instagram|linkedin)\.com\/[a-zA-Z0-9(_)?]+\/?$/;
  return socialMediaPattern.test(url);
};

export const validateWebsite = url => {
  const websitePattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}\/?$/;
  return websitePattern.test(url);
};

export const isEmail = value => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(value);
};
