import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const DustbinIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="16.018" height="17.414" viewBox="0 0 16.018 17.414">
    <g id="Group_6695" data-name="Group 6695" transform="translate(-6774.569 -5960.016)">
      <path
        id="Path_4688"
        data-name="Path 4688"
        d="M4.5,9H19.018"
        transform="translate(6770.819 5954.949)"
        fill="none"
        stroke="#393c46"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_4689"
        data-name="Path 4689"
        d="M18.792,6.183v11.14a1.6,1.6,0,0,1-1.613,1.591H9.113A1.6,1.6,0,0,1,7.5,17.323V6.183m2.42,0V4.591A1.6,1.6,0,0,1,11.533,3h3.226a1.6,1.6,0,0,1,1.613,1.591V6.183"
        transform="translate(6769.432 5957.766)"
        fill="none"
        stroke="#393c46"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_4690"
        data-name="Path 4690"
        d="M15,16.5v4.774"
        transform="translate(6765.965 5951.427)"
        fill="none"
        stroke="#393c46"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        id="Path_4691"
        data-name="Path 4691"
        d="M21,16.5v4.774"
        transform="translate(6763.19 5951.427)"
        fill="none"
        stroke="#393c46"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </svg>,
  'DustbinIcon'
);
export default DustbinIcon;
