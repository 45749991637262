// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circle {
  width: 100px;
  height: 100px;
  border-radius: 1000px;
  border: 3px solid;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 21px;
  color: green;
}

.circle2 {
  width: 100px;
  height: 100px;
  border-radius: 1000px;
  border: 3px solid;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 21px;
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/ui/payment-modal/payment-modal.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,qBAAA;EACA,iBAAA;EACA,aAAA;EACA,6BAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,YAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,qBAAA;EACA,iBAAA;EACA,aAAA;EACA,6BAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,UAAA;AACF","sourcesContent":[".circle {\n  width: 100px;\n  height: 100px;\n  border-radius: 1000px;\n  border: 3px solid;\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  margin-top: 10px;\n  margin-bottom: 21px;\n  color: green;\n}\n\n.circle2 {\n  width: 100px;\n  height: 100px;\n  border-radius: 1000px;\n  border: 3px solid;\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  margin-top: 10px;\n  margin-bottom: 21px;\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
