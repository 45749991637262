// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-outline {
  outline: none !important;
  border: none !important;
}

.no-outline fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/common/modals/invite-more/invite-more.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,uBAAA;AACF;;AACA;EACE,uBAAA;AAEF","sourcesContent":[".no-outline {\n  outline: none !important;\n  border: none !important;\n}\n.no-outline fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {\n  border: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
