import React, { useEffect, useState } from 'react';
import WeekDayButtons from 'app/components/ui/auth/choose-availability/weekday-buttons';
import { weekDaysType } from 'app/utils/types/choose-availability-types/choose-availability-types';
import { weekDays } from 'app/utils/data/choose-availability/week-days';
import { Grid, Typography, useTheme } from '@mui/material';
import { useAppSelector } from 'app/config/store';
import { SettingsProfileType } from 'app/utils/types/settings/settings-profile-types';

interface SettingsProfileAvailabilityProps {
  setUpdatedProfile: React.Dispatch<React.SetStateAction<SettingsProfileType>>;
}
const SettingsProfileAvailability = ({ setUpdatedProfile }: SettingsProfileAvailabilityProps) => {
  const [selected, setSelected] = useState<weekDaysType[]>([]);
  const [timerId, setTimerId] = useState(null);
  const profileSettings = useAppSelector(state => state.settingsProfile.settingsProfile);
  console.log('check prifile', profileSettings);
  const updateWeekDaysInProfile = (selectedArray: weekDaysType[]) => {
    let updatedWeekDays = selectedArray.map(item => item.value).filter(value => value !== null);
    if (updatedWeekDays.length === 0 || updatedWeekDays.every(value => value === null)) {
      updatedWeekDays = [];
    }
    console.log('checkmine', updatedWeekDays);
    setUpdatedProfile(prevProfile => ({
      ...prevProfile,
      weekDays: updatedWeekDays,
    }));
  };

  const handleItemClick = (item: weekDaysType) => {
    const isItemInSelected = selected.some(element => element.label === item.label && element.value === item.value);

    if (isItemInSelected) {
      // Remove the item from the selected array
      const updatedSelected = selected.filter(element => element.value !== item.value);
      setSelected(updatedSelected);

      // Update the profile with the updated selected array
      updateWeekDaysInProfile(updatedSelected);
    } else {
      if (timerId) {
        clearTimeout(timerId);
      }

      setSelected(prevSelected => [...prevSelected, item]);

      // Update the profile with the updated selected array
      updateWeekDaysInProfile([...selected, item]);
    }
  };

  useEffect(() => {
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [timerId]);
  useEffect(() => {
    if (profileSettings?.weekDays) {
      let capitalizedWeekDays = [];
      if (profileSettings.weekDays[0] !== null) {
        capitalizedWeekDays = profileSettings.weekDays.map(day => ({
          label: day?.charAt(0).toUpperCase() + day?.slice(1).toLowerCase(),
          value: day?.charAt(0).toUpperCase() + day?.slice(1).toLowerCase(),
        }));
      }
      console.log('check status', capitalizedWeekDays);
      setSelected(capitalizedWeekDays);
    }
  }, [profileSettings?.weekDays]);
  const theme = useTheme();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ color: theme.textColor.mainHeading, fontSize: '14px' }}>
          Choose your availability
        </Typography>
      </Grid>
      {weekDays?.map(item => (
        <Grid item xs={4} sm={2} md key={item.value} width={{ xs: '100%', lg: '47px' }} height="100%">
          <WeekDayButtons selected={selected} item={item} handleItemClick={handleItemClick} />
        </Grid>
      ))}
    </Grid>
  );
};

export default SettingsProfileAvailability;
