import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import { Menu } from '@mui/material';
import { getReaction } from 'app/shared/reducers/chat-messages/chat-reaction';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'app/config/store';
import { ConstantReaction } from 'app/entities/constants/constant-reaction';
import ExpressionIcon from '../expression-icon';
import EmojiPicker, { EmojiClickData, EmojiStyle } from 'emoji-picker-react';
import HappyemojiIcons from './happy-emoji';

const ChatReactionMessage = ({ message, onSelectEmojiMessage, onClose }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useAppDispatch();

  const emojis = ConstantReaction?.emojis || [];
  const emojisInRows = [];
  const emojisPerRow = 4;

  // Split the emojis into rows
  for (let i = 0; i < emojis.length; i += emojisPerRow) {
    emojisInRows.push(emojis.slice(i, i + emojisPerRow));
  }

  const handleReactionClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEmojiClick = emoji => {
    onSelectEmojiMessage(emoji);
    handleClose();
    onClose();
  };

  const getInput = (emojiData: EmojiClickData, event: MouseEvent) => {
    let emoji = '';
    emoji = emoji + (emojiData.isCustom ? emojiData.unified : emojiData.emoji);
    onSelectEmojiMessage(emoji);
    handleClose();
    onClose();
  };

  return (
    <div>
      <IconButton disableRipple size="small" aria-label="emoji-reaction" onClick={handleReactionClick}>
        <HappyemojiIcons className="m-2" sx={{ fontSize: 'medium', color: 'secondary.main', marginRight: '10px' }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <EmojiPicker onEmojiClick={getInput} autoFocusSearch={false} emojiStyle={EmojiStyle.NATIVE} />
      </Menu>
    </div>
  );
};

export default ChatReactionMessage;
