import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const ReplayIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24.559" height="23.314" viewBox="0 0 24.559 23.314">
    <path
      id="shortcut_24x24_1x"
      data-name="shortcut(24x24)@1x"
      d="M7.012,8.594,4.633,12.509,0,1.719,12.236,0,9.869,3.881l.046.034a13.316,13.316,0,0,1,3.927,4.177,7.329,7.329,0,0,1,.66,5.669,6.136,6.136,0,0,1-3.779,3.916,8.471,8.471,0,0,1-5.02.285,7.708,7.708,0,0,0,4.325-1.434,3.854,3.854,0,0,0,1.7-3.2,4.18,4.18,0,0,0-1.434-2.982A9.55,9.55,0,0,0,7.012,8.594Z"
      transform="translate(0.683 13.519) rotate(-60)"
      fill="none"
      stroke="#676e7b"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1"
    />
  </svg>,
  'ReplayIcon'
);
export default ReplayIcon;
