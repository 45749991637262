import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export interface createNgoPayload {
  name: string;
  address: string;
  contactPerson: string;
  emailId: string;
  phoneNumber: string;
  latitude: string;
  longitude: string;
}

const initialState = {
  loading: false,
  success: false,
  error: null,
  id: 0,
};

export const createNgo = createAsyncThunk<
  number,
  createNgoPayload,
  {
    rejectValue: string;
  }
>('Ngo/create', async (createNgoData: createNgoPayload, thunkAPI) => {
  try {
    const response = await axios.post<number>('/api/ngo', createNgoData);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to create NGO');
  }
});

const ngoSlice = createSlice({
  name: 'ngo',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(createNgo.pending, state => {
      state.loading = true;
      state.success = false;
      state.error = false;
      state.id = 0;
    });
    builder.addCase(createNgo.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.id = action.payload;
    });
    builder.addCase(createNgo.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload || 'Failed to create NGO';
      state.id = 0;
    });
  },
});

export default ngoSlice.reducer;
