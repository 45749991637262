import React from 'react';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Typography } from '@mui/material';
import './payment-modal.scss';
import CloseIcon from '@mui/icons-material/Close';

interface PaymentModalProps {
  open: boolean;
  onClose: () => void;
}

const PaymentModal: React.FC<PaymentModalProps> = ({ open, onClose }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isDarkMode = theme.palette.mode === 'dark';

  const handleClose = () => {
    onClose(); // Trigger the onClose function passed from parent to close the modal
  };

  return (
    <Dialog
      className="new  mx-2"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      sx={{
        '& .MuiDialog-paper': {
          // Target the Paper element inside Dialog
          borderRadius: '15px',
          overflow: 'hidden',
        },
      }}
    >
      <Box className="d-flex justify-content-end m-2">
        <CloseIcon onClick={handleClose} />
      </Box>
      <DialogContent sx={{ textAlign: '-webkit-center' }}>
        <Box className="circle">
          <CheckIcon sx={{ fontSize: '56px' }} />
        </Box>
        <Box>
          <Typography variant="h3" gutterBottom sx={{ color: '#008000', fontWeight: 'bolder' }}>
            Successful
          </Typography>
        </Box>
        <DialogContentText>This payment has been successful</DialogContentText>
      </DialogContent>
      <DialogActions className="justify-content-center">
        <Button
          className="m-1"
          type="button"
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: isDarkMode ? '#FFCE00' : '#674BFF',
            textTransform: 'none',
            borderRadius: '15px',
            width: '200px',
            color: isDarkMode ? 'black' : 'white',
            '&:hover': {
              backgroundColor: isDarkMode ? '#FFCE00' : '#674BFF',
            },
          }}
          onClick={handleClose}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentModal;
