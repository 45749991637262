import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import WelcomeUser from '../welcome-user/welcome-user';
import { useNavigate } from 'react-router-dom';
import { Storage } from 'react-jhipster';
import { useMediaQuery, useTheme } from '@mui/material';
import { Libraries, useLoadScript } from '@react-google-maps/api';
import { apiKeys } from 'app/utils/data/constants/api-keys';
import { appConstants } from 'app/utils/data/constants/constants';
import PlacesAutocomplete from './places-autocomplete.tsx/places-auto-complete';

const WelcomeScreen = () => {
  const [city, setCity] = useState<any>();
  const [fullLocationDetail, setFullLocationDetail] = useState<any>();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKeys.GMAP_API_Key,
    libraries: appConstants.mapLibraries as Libraries,
  });
  if (!isLoaded) return <div>Loading....</div>;

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      minHeight="100vh"
      py={5}
      sx={{ background: theme.palette.background.default }}
    >
      <Grid item alignItems="center" marginBottom={3}>
        <WelcomeUser />
      </Grid>
      <Grid item container direction="column" alignItems={'center'} justifyContent={'center'} width={'100%'} gap={2}>
        <Grid item>
          <Typography variant="h4" sx={{ color: 'secondary.light' }}>
            Where are you from?
          </Typography>
        </Grid>
        <Grid item sx={{ width: isMobile ? '65%' : '50%' }}>
          <div style={{ position: 'relative', width: '100%', boxShadow: 'none' }}>
            <PlacesAutocomplete setFullLocationDetail={setFullLocationDetail} setCity={setCity} city={city} />
          </div>
          <Grid item container alignItems="center" justifyContent={'space-between'} my={3} sx={{ marginTop: '68.5px' }}>
            <Button
              onClick={() => navigate('/')}
              variant="outlined"
              sx={{
                textTransform: 'none',
                width: '89px',
                height: '40px',
                borderRadius: '15px',
                border: '1px solid #FFCE00',
                boxShadow: 'none',
                '&:hover': { boxShadow: 'none' },
              }}
            >
              <Typography>Back</Typography>
            </Button>
            <Button
              onClick={() => {
                Storage.session.set('location', fullLocationDetail);
                Storage.session.set('city', city);
                navigate('/events');
              }}
              variant="contained"
              sx={{
                textTransform: 'none',
                width: '89px',
                height: '40px',
                borderRadius: '15px',
                border: '1px solid #FFCE00',
                boxShadow: 'none',
                '&:hover': { backgroundColor: 'primary.main', boxShadow: 'none' },
              }}
            >
              <Typography>Next</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WelcomeScreen;
