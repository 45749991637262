import React, { useState, useRef, useEffect, useCallback } from 'react';

import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import { useMediaQuery, useTheme, Box } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import JoditEditor, { Jodit } from 'jodit-react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { fetchCategories } from 'app/shared/reducers/categories';
import { singleImageType } from 'app/shared/reducers/image-source';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import axios from 'axios';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { postingRulesType } from 'app/utils/types/activity/activity-types';
import { useDropzone } from 'react-dropzone';
import './write-blog-left-sec.scss';
import MapBoxMapView from 'app/components/common/map-box/map-view-mapbox';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { apiEndPoints } from 'app/utils/data/constants/api-end-points';
import CreateIcon from './cancel-icon';
import { sideBaricons } from 'app/utils/data/side-bar-nav/side-nav-icon-list';

const WriteBlogLeftSection = ({ formik }: any) => {
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [categories, setCategories] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [imageResponse, setImageResponse] = useState<singleImageType>(null);
  const [error, setError] = useState(false);
  const [imageUploaded, setImageUploaded] = useState<boolean>(false);
  const [activityPostingRules, setActivityPostingRules] = useState<postingRulesType[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mapTouched, setMapTouched] = useState<boolean>(false);
  // state for selected location
  const [selectedLocation, setSelectedLocation] = useState<{ lat: number; lng: number }>(null);
  const [showNgoFieldsError, setShowNgoFieldsError] = useState(false);
  const [isNgoFieldsFilled, setIsNgoFieldsFilled] = useState(false);

  const isDarkMode = useAppSelector(state => state.darkMode);

  const editorRef = useRef(null);
  const [files, setFiles] = useState<File>();
  const characterCount = title.length;
  const [latitudeNear, setLatitude] = useState<any>('');
  const [longitudeNear, setLongitude] = useState<any>('');
  const [totalImages, setTotalImages] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [totalSize, setTotalSize] = useState(0);

  const handleSelectCategory = (event: SelectChangeEvent) => {
    setSelectedCategory(event.target.value);
    formik.setFieldValue('category', event.target.value);
  };
  const validateNgoFields = () => {
    const { name, address, contactPerson, emailId, phoneNumber, place } = formik.values;
    const isAtLeastOneFieldFilled = name || address || contactPerson || emailId || phoneNumber || place;
    setShowNgoFieldsError(!isAtLeastOneFieldFilled);
    setIsNgoFieldsFilled(isAtLeastOneFieldFilled);
    return isAtLeastOneFieldFilled;
  };

  const currentTheme = useTheme();
  function preparePaste(jodit) {
    jodit.events.on('afterPaste', async data => {
      console.log('first data', data);
      const pastedText = data.event.clipboardData.getData('text/plain');
      const modifiedText = pastedText.replace(/color:\s*#(?:2[0-9A-F]{5}|[3-9A-F][0-9A-F]{5,}|[0-9A-F]{6})\b/gi, 'color: grey;');
      console.log('modified data', modifiedText);
      jodit.selection.insertHTML(modifiedText);
    });
  }

  // Add the plugin to Jodit plugins
  Jodit.plugins.add('preparePaste', preparePaste);

  const editorConfig = {
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    showNodePathInStatusbar: false,
    showJoditPoweredBy: false,
    outline: 'none',
    height: '300px',
    addNewLine: false,
    wordWrap: 'break-word',
    whiteSpace: 'pre-line',
    placeholder: 'Tell your story*',
    spellcheck: true,
    askBeforePasteFromWord: false,
    askBeforePasteHTML: false,
    theme: currentTheme.palette.mode === 'dark' ? 'dark' : '',
    style: {
      background: currentTheme.palette.mode === 'dark' ? '#1F1F26' : '#fff',
      color: currentTheme.palette.mode === 'dark' ? 'secondary.main' : '',
    },
    plugins: ['preparePaste'],
  };

  const onDrop = useCallback((accFiles: File[], FileRejection: any) => {
    const acceptedFiles = accFiles.map(file => ({ file, errors: [] }));

    const currentFile = acceptedFiles[0];

    setFiles(currentFile.file);

    if (totalImages + acceptedFiles.length <= 10) {
      let valid = true;
      for (const file of acceptedFiles) {
        if (file.file.size > 5 * 1024 * 1024) {
          valid = false;
          setErrorMessage('File size must be less than 5MB.');
          break;
        }
      }
      if (valid) {
        const currentFile = acceptedFiles[0];
        setFiles(currentFile.file);
        setTotalImages(totalImages + acceptedFiles.length);
        setErrorMessage('');
        setTotalSize(existingSize => acceptedFiles.reduce((acc, file) => acc + file.file.size, existingSize));
      } else {
        setErrorMessage('File size must be less than 5MB.');
      }
    } else {
      setErrorMessage('You can upload a maximum of 10 files.');
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const dispatch = useAppDispatch();

  const theme = useTheme();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(fetchCategories());
        setCategories(response.payload);
      } catch (error) {
        setError(error);
      }
    };
    const fetchPostingRules = async () => {
      try {
        const response = await axios.get<postingRulesType[]>('api/rules-tables');

        setActivityPostingRules(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    fetchPostingRules();
  }, []);

  useEffect(() => {
    if (files !== undefined) {
      const sendFile = async () => {
        try {
          const formData = new FormData();
          formData.append('file', files as Blob);
          const response = await axios.post<any>(apiEndPoints.imageUpload, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
          if (response.status === 201) {
            setImageResponse(response.data);
            formik.setFieldValue('imageId', response.data.id);
            setImageUploaded(true);
          }
        } catch (error) {
          console.log(error);
        }
      };
      sendFile();
    }
  }, [files]);

  useEffect(() => {
    validateNgoFields();
  }, [formik.values]);

  const handleImageClear = () => {
    setImageUploaded(false);
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  //Function to handle closing the dialog
  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (formik.values.latitude === '' || (formik.values.longitude === '' && mapTouched)) {
      formik.setFieldValue('latitude', Number(latitudeNear));
      formik.setFieldValue('longitude', Number(longitudeNear));
    }
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
    setMapTouched(true);
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setLatitude(latitude);
          setLongitude(longitude);
        },
        function (error) {
          if (error.code === error.PERMISSION_DENIED) {
            alert('Location access denied. Please enable location services.');
          } else {
            alert('Error getting location: ' + error.message);
          }
        }
      );
    } else {
      alert('Geolocation is not supported in this browser.');
    }
  };

  //callback function to handle selected location
  const handleSelectLocation = location => {
    const { lat, lng } = location;

    formik.setFieldValue('latitude', lat);
    formik.setFieldValue('longitude', lng);
    setSelectedLocation(location);
  };

  const handleLocationFromSearchBox = (location: string) => {
    formik.setFieldValue('place', location);
  };

  return (
    <Stack gap={3}>
      <FormControl sx={{ width: '100%' }} variant="outlined">
        <OutlinedInput
          sx={{
            height: '45px',
            color: 'secondary.main',
            '&.MuiOutlinedInput-input::placeholder': {
              color: 'secondary.contrastText',
            },
            '&.MuiOutlinedInput-root': {},
            '&.MuiOutlinedInput-notchedOutline': {
              borderColor: '#ff5500',
            },
          }}
          endAdornment={
            <InputAdornment position="end">
              <Typography sx={{ mt: 3, mb: 1, color: 'textColor.paragraph' }}>{`${characterCount}/100`}</Typography>
            </InputAdornment>
          }
          size="small"
          id="blog-title"
          type={'text'}
          name="blogTitle"
          onBlur={formik.handleBlur}
          error={formik.touched.blogTitle && Boolean(formik.errors.blogTitle)}
          placeholder="Your title*"
          spellCheck
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setTitle(event.target.value);
            formik.setFieldValue('blogTitle', event.target.value);
          }}
        />

        <Typography variant="caption" sx={{ color: 'error.main' }}>
          {formik.touched.blogTitle && formik.errors.blogTitle ? formik.errors.blogTitle.toString() : ''}
        </Typography>
      </FormControl>
      <div className="editor-container example1">
        <JoditEditor
          ref={editorRef}
          value={content}
          onBlur={newContent => {
            setContent(newContent);
            if (!newContent.trim()) {
              formik.setFieldError('blogBody', 'Blog body cannot be empty');
            } else {
              formik.setFieldValue('blogBody', newContent);
            }
          }}
          config={editorConfig as any}
        />
      </div>
      {/* <Typography variant="caption" sx={{ color: 'error.main' }}>
        {formik.touched.blogBody && formik.errors.blogBody ? formik.errors.blogBody.toString() : ''}
      </Typography> */}
      <Typography variant="caption" sx={{ color: 'error.main', marginLeft: '3px' }}>
        {formik.values.blogBody.trim().length == 0 ? 'Blog body cannot be empty' : ''}
      </Typography>
      <Grid container direction={'row'} spacing={3} alignItems={'center'}>
        {/* Create Activity */}
        <Grid item md>
          <FormControl component="fieldset" sx={{ marginLeft: '10px' }}>
            <FormControlLabel
              sx={{ color: 'secondary.main', fontSize: '14px' }}
              control={
                <Checkbox
                  name="hasActivity"
                  onChange={event => formik.setFieldValue('hasActivity', event.target.checked)}
                  disableRipple
                  sx={{
                    color: 'transparent',
                    '&.Mui-checked': { color: 'primary.light' },
                    '& .MuiSvgIcon-root': { fontSize: 23, borderRadius: '25%', border: '1px solid #BFBFBF', padding: '3px' },
                  }}
                />
              }
              label="Create an Activity for this"
              labelPlacement="end"
            />
          </FormControl>
        </Grid>
        <Grid item md>
          {/* Post as Ngo */}
          <FormControl component="fieldset" sx={{ marginLeft: '10px' }}>
            <FormControlLabel
              sx={{ color: 'secondary.main', fontSize: '14px' }}
              control={
                <Checkbox
                  name="postAsNgo"
                  onChange={event => formik.setFieldValue('postAsNgo', event.target.checked)}
                  disableRipple
                  sx={{
                    color: 'transparent',
                    '&.Mui-checked': { color: 'primary.light' },
                    '& .MuiSvgIcon-root': { fontSize: 23, borderRadius: '25%', border: '1px solid #BFBFBF', padding: '3px' },
                  }}
                />
              }
              label="Post as NGO"
              labelPlacement="end"
            />
          </FormControl>
        </Grid>
      </Grid>
      {showNgoFieldsError && formik.values.postAsNgo && (
        <Typography variant="caption" sx={{ color: 'error.main', marginTop: '8px' }}>
          * At least one field is required in the NGO section.
        </Typography>
      )}
      {formik.values.postAsNgo && (
        <Grid container direction={'row'} spacing={3} alignItems={'center'}>
          <Grid item xs={12} lg={6}>
            <TextField
              size="small"
              sx={{ height: '45px' }}
              name="name"
              fullWidth
              placeholder="Ngo Name"
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name ? formik.errors.name.toString() : ''}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              size="small"
              name="address"
              fullWidth
              placeholder="Address"
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address ? formik.errors.address.toString() : ''}
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <TextField
              size="small"
              name="contactPerson"
              fullWidth
              placeholder="Contact Person"
              error={formik.touched.contactPerson && Boolean(formik.errors.contactPerson)}
              helperText={formik.touched.contactPerson && formik.errors.contactPerson ? formik.errors.contactPerson.toString() : ''}
              value={formik.values.contactPerson}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              size="small"
              name="emailId"
              fullWidth
              placeholder="Email Id"
              error={formik.touched.emailId && Boolean(formik.errors.emailId)}
              helperText={formik.touched.emailId && formik.errors.emailId ? formik.errors.emailId.toString() : ''}
              value={formik.values.emailId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              size="small"
              name="phoneNumber"
              fullWidth
              placeholder="Phone Number"
              error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
              helperText={formik.touched.phoneNumber && formik.errors.phoneNumber ? formik.errors.phoneNumber.toString() : ''}
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl
              fullWidth
              sx={{ backgroundColor: 'transperant', color: 'secondary.main', borderRadius: '15px' }}
              variant="outlined"
            >
              <OutlinedInput
                sx={{
                  height: '45px',
                  color: 'secondary.main',
                  '&.MuiOutlinedInput-input::placeholder': {
                    color: 'secondary.contrastText',
                  },
                  '&.MuiOutlinedInput-root': {
                    background: 'secondary.main',
                    borderRadius: '15px',
                    // border:
                    //   formik.touched.latitude && formik.values.latitude === '' && formik.values.longitude === '' && !mapTouched
                    //     ? '1px solid red'
                    //     : '2px solid #F6F7F8',
                  },
                  '& fieldset': {
                    borderColor: isDarkMode ? 'background.default' : 'secondary.dark',
                  },
                }}
                startAdornment={<PlaceOutlinedIcon sx={{ mr: 1, color: theme.textColor.paragraph }} />}
                size="small"
                type={'text'}
                placeholder={formik.values.place ? formik.values.place : 'Location'}
                value={
                  formik.values.place
                    ? formik.values.place
                    : formik.values.latitude && formik.values.longitude
                    ? `Lat: ${formik.values.latitude}, Long: ${formik.values.longitude}`
                    : 'Location'
                }
                readOnly
                onClick={handleOpenModal}
              />
              {/* {formik.touched.latitude && formik.touched.longitude && formik.errors.latitude && formik.errors.longitude ? (
                <Typography variant="caption" sx={{ color: 'error.main' }}>
                  Location is required
                </Typography>
              ) : null} */}
            </FormControl>
          </Grid>
        </Grid>
      )}
      <Stack
        direction="column"
        gap={1}
        sx={{ maxWidth: { xs: '100%' }, borderRadius: '15px', backgroundColor: 'secondary.dark', p: { xs: 2, lg: 3 } }}
      >
        {imageUploaded ? (
          <Box sx={{ position: 'relative', width: '100%', height: '300px' }}>
            <CreateIcon
              sx={{
                color: 'primary.light',
                background: '#fafafa',
                position: 'absolute',
                top: 0,
                right: 0,
                cursor: 'pointer',
                mr: '10px',
                mt: '10px',
                borderRadius: '20px',
              }}
              onClick={handleImageClear}
            />
            <img src={imageResponse?.url} alt="image" width="100%" height="100%" />
          </Box>
        ) : (
          <Box>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Grid
                container
                direction={'row'}
                alignItems={'center'}
                minHeight={300}
                justifyContent={'center'}
                sx={{ borderRadius: '15px', backgroundColor: theme.palette.mode === 'dark' ? '' : '#F6F7F8', cursor: 'pointer', px: 2 }}
              >
                <Stack direction={'column'} gap={0.5} alignItems="center">
                  Drop your image files here, or <span style={{ color: 'primary.main' }}>browse</span>
                  <br></br>(Accepted file formats: JPG, JPEG, PNG, MP4, WEBM, OGG)
                </Stack>
              </Grid>
            </div>
          </Box>
        )}
        {errorMessage && (
          <div style={{ margin: '10px 0px', fontFamily: 'Manrope', fontWeight: '400', fontSize: '12px', color: ' #E23A44' }}>
            {errorMessage}
          </div>
        )}
      </Stack>
      <Typography variant="caption" sx={{ color: 'error.main', marginTop: '-18px' }}>
        {formik.errors.imageId ? formik.errors.imageId : ''}
      </Typography>
      <Stack direction="column" sx={{ borderRadius: '15px', backgroundColor: 'transperant' }}>
        <FormControl fullWidth sx={{ backgroundColor: 'secondary.dark', borderRadius: '15px', height: '45px' }}>
          <Select
            size="small"
            name="category"
            fullWidth
            displayEmpty
            placeholder="Select Category"
            labelId="select-categories"
            id="select-categories"
            value={formik.values.category}
            onChange={handleSelectCategory}
            error={formik.touched.category && Boolean(formik.errors.category)}
            onBlur={formik.handleBlur}
            MenuProps={{
              anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
              transformOrigin: { vertical: 'bottom', horizontal: 'left' },
              PaperProps: {
                style: {
                  overflowY: 'scroll',
                },
              },
              MenuListProps: {
                style: {
                  maxHeight: '200px',
                },
              },
            }}
            sx={{
              border: 'none',
              '&.MuiMenu-paper': {
                borderRadius: '15px',
                border: 'none',
              },
              '&.MuiOutlinedInput-root': {
                background: 'secondary.main',
                borderRadius: '14px',
                height: '45px',
              },
              '& .MuiSelect-icon': {
                marginRight: 2,
              },
            }}
          >
            <MenuItem value="">
              <em>
                <Typography sx={{ fontStyle: 'normal', color: theme.textColor.paragraph }}>Select Category*</Typography>
              </em>
            </MenuItem>
            {categories?.map((category: any) => {
              const matchingIcon = sideBaricons.find(iconData => iconData.id === category.id);
              return (
                <MenuItem value={category.id} key={category.id}>
                  <Stack direction={'row'} pl={1} gap={1}>
                    {matchingIcon?.icon ?? null}
                    <Typography sx={{ fontSize: '16px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {category.category}
                    </Typography>
                  </Stack>
                </MenuItem>
              );
            })}
          </Select>
          <Typography variant="caption" sx={{ color: 'error.main' }}>
            {formik.touched.category && formik.errors.category ? formik.errors.category.toString() : ''}
          </Typography>
        </FormControl>
      </Stack>
      <Stack alignItems="end" gap={3}>
        <Button
          type="submit"
          variant="contained"
          disabled={!files || !formik.isValid || !formik.dirty || (showNgoFieldsError && formik.values.postAsNgo)} // Add !files condition
          sx={{
            backgroundColor: !files || !formik.dirty || !formik.isValid ? 'secondary.contrastText' : 'primary.main',
            color: !files || !formik.dirty || !formik.isValid ? 'secondary.dark' : 'common.black',
            textTransform: 'none',
            borderRadius: '15px',
            width: '40%',
            height: '40px',
            '&:hover': {
              backgroundColor: !files || !formik.dirty || !formik.isValid ? 'secondary.contrastText' : 'primary.main',
              color: !files || !formik.dirty || !formik.isValid ? 'secondary.dark' : 'common.black',
            },
          }}
        >
          Post now
        </Button>
      </Stack>
      <Dialog fullScreen={fullScreen} open={isModalOpen} onClose={handleCloseModal}>
        <MapBoxMapView
          handleCloseModal={handleCloseModal}
          formik={formik}
          handleSelectLocation={handleSelectLocation}
          handleLocationFromSearchBox={handleLocationFromSearchBox}
        />
      </Dialog>
    </Stack>
  );
};

export default WriteBlogLeftSection;
