import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Typography } from '@mui/material';
import { getRandomColor } from 'app/utils/common-utils/common-utils';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

type Props = {
  title: string;
  moreLink?: string;
  icon?: string;
};

export function getColor(type: string) {
  switch (type) {
    case 'Greener Cities':
      return '#F79D24';
    case 'Clean Energy':
      return '#F9C30C';
    case 'Clean Water':
      return '#41BDE2';
    case 'Well Being':
      return '#4C9F38';
    case 'Stop Hunger':
      return '#DDA63A';
    case 'Save Our Oceans':
      return '#3897D9';
    case 'Good Health':
      return '#79DB61';
    case 'Employment':
      return '#A21942';
    case 'End Poverty':
      return '#E5243B';
    case 'Education':
      return '#C5192D';
    case 'Responsible Consumption':
      return '#BF8B2E';
    case 'Peace & Justice':
      return '#25689D';
    case 'Save Plants & Animals':
      return '#56C02B';
    case 'Gender Equality':
      return '#F53A21';
    case 'Reduced Inequalities':
      return '#DD1367';
    case 'Innovation':
      return '#F66925';
    case 'Climate Action':
      return '#3F7E44';
    case 'Partnerships':
      return '#19486A';
    case 'Urgent':
      return '#DA9E2A';
    default:
      return '#8F8F8F';
  }
}

export default function SectionHead({ title, moreLink, icon }: Props) {
  const navigate = useNavigate();

  const handleNavigation = (e, link) => {
    e.preventDefault();
    navigate(link, { state: { title: title } });
  };

  function getPngIcon(type: string) {
    const BASE_PATH = 'content/images/categories/png';

    switch (type) {
      case 'Greener Cities':
        return `${BASE_PATH}/sustainable cities and communities.png`;
      case 'Clean Energy':
        return `${BASE_PATH}/affordable and clean energy.png`;
      case 'Clean Water':
        return `${BASE_PATH}/clean water and sanitation.png`;
      case 'Well Being':
        return `${BASE_PATH}/well-being.png`;
      case 'Stop Hunger':
        return `${BASE_PATH}/zero hunger.png`;
      case 'Save Our Oceans':
        return `${BASE_PATH}/life below water.png`;
      case 'Good Health':
        return `${BASE_PATH}/Health.png`;
      case 'Employment':
        return `${BASE_PATH}/decent work and enconomic grouth.png`;
      case 'End Poverty':
        return `${BASE_PATH}/no poverty.png`;
      case 'Education':
        return `${BASE_PATH}/quality education.png`;
      case 'Responsible Consumption':
        return `${BASE_PATH}/responsible consuption and production.png`;
      case 'Peace & Justice':
        return `${BASE_PATH}/peace justice and strong institution.png`;
      case 'Save Plants & Animals':
        return `${BASE_PATH}/life on land.png`;
      case 'Gender Equality':
        return `${BASE_PATH}/gender equality.png`;
      case 'Reduced Inequalities':
        return `${BASE_PATH}/reduced ineq.png`;
      case 'Innovation':
        return `${BASE_PATH}/industry innovation and infrastructure.png`;
      case 'Climate Action':
        return `${BASE_PATH}/climate action.png`;
      case 'Urgent':
        return `${BASE_PATH}/Urgent.png`;
      case 'Partnerships':
        return `${BASE_PATH}/partnership for goals.png`;
      default:
        return `${BASE_PATH}/a.svg`;
    }
  }

  return (
    <Box width="100%" mb={2.2} display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" gap={2} alignItems="center">
        {icon && (
          <Box display="flex" alignItems="center" justifyContent="center" height={40} width={40} py={1.2}>
            {title && <img style={{ display: 'block', width: '100%' }} src={getPngIcon(title)} alt="Icon" />}
          </Box>
        )}
        <Typography display="flex" alignItems="center" fontWeight="bold" fontSize="1.1rem">
          {title}
        </Typography>
      </Box>
      {moreLink && (
        <Link data-id={moreLink} to={`${moreLink}`} onClick={e => handleNavigation(e, `${moreLink}`)}>
          <Typography variant="body2" className="blue" style={{ display: 'flex', alignItems: 'center' }}>
            More
            <ArrowForwardIcon sx={{ color: '#674bff', fontSize: 20 }} />
          </Typography>
        </Link>
      )}
    </Box>
  );
}
