import { ReducersMapObject, combineReducers } from '@reduxjs/toolkit';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import authentication from './authentication';
import applicationProfile from './application-profile';
import darkMode from './dark-mode';
import userPreferencesSettings from './user-preferences';
import distanceLimit from './distance-limit';
import categoryMaster from './categories';
import homeActivity from './activity-home';
import homePetition from './petition-home';
import administration from 'app/modules/administration/administration.reducer';
import userManagement from 'app/modules/administration/user-management/user-management.reducer';
import register from 'app/modules/account/register/register.reducer';
import activate from 'app/modules/account/activate/activate.reducer';
import password from 'app/modules/account/password/password.reducer';
import settings from 'app/modules/account/settings/settings.reducer';
import passwordReset from 'app/modules/account/password-reset/password-reset.reducer';
import entitiesReducers from 'app/entities/reducers';
import imageUploadByType from './upload-image-by-type';
import ngo from './ngo';
import mapCoordinates from './map-coordinates';
import shareViaMail from './send-link-to-emails';
import bookmarkactivity from './bookmark-activity';
import likeactivity from './activity-like';
import volunteer from './volunteer';
import leaveActivityReducer from './activity/activity-actions';
import reportActivityReducer from './activity/activity-report';
import filters from './activity-filter';
import blogs from './blogs';
import notifications from './notifications';
import payment from './payment';
import leftPanelReducer from './chat-messages/left-panel';
import chatPanelReducer from './chat-messages/chat-Panel';
import chatPinReducer from './chat-messages/chat-pin';
import chatDeleteReducer from './chat-messages/chat-delete';
import rateActivity from './activity/activity-rating';
import cityReducer from './region-filter/city-filter';
import countryReducer from './region-filter/country-filter';
import stateReducer from './region-filter/country-filter';
import profileReducer from './profile';
import organizationReducer from './organization';
import dashboardReducer from './dashboard/dashboard';
import verificationSlice from './phone-verification';
import draftReducer from './activity/activity-draft';
import mileStones from './profile-section/milestones';
import peritionDraftReducer from './petition/petition-draft';
import chatList from './chat-messages/chat-store/chat-list';
import activitParticipants from './participants';
import chatSentReducer from './chat-messages/chat-post';
import settingsReducer from './settings/settings';
import settingsProfile from './settings/settings-profile';
import visibilityReducer from './settings/settings-privacy-visibility';
import settingsBlockAccounts from './settings/settings-block-accounts';
import settingsOrganization from './settings/settings-organization';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const rootReducer: ReducersMapObject = {
  authentication,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  loadingBar,
  darkMode,
  userPreferencesSettings,
  distanceLimit,
  categoryMaster,
  homeActivity,
  imageUploadByType,
  ngo,
  mapCoordinates,
  shareViaMail,
  bookmarkactivity,
  likeactivity,
  volunteer,
  homePetition,
  leaveActivityReducer,
  reportActivityReducer,
  filters,
  blogs,
  notifications,
  payment,
  leftPanelReducer,
  chatPanelReducer,
  chatPinReducer,
  chatDeleteReducer,
  rateActivity,
  cityReducer,
  countryReducer,
  stateReducer,
  profileReducer,
  organizationReducer,
  verificationSlice,
  draftReducer,
  mileStones,
  peritionDraftReducer,
  chatList,
  activitParticipants,
  chatSentReducer,
  settingsReducer,
  settingsProfile,
  visibilityReducer,
  settingsBlockAccounts,
  settingsOrganization,
  dashboardReducer,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  ...entitiesReducers,
};

export default rootReducer;
