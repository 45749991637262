import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

function ChatMediaOption({ anchorEl, onClose }) {
   const open = Boolean(anchorEl);
    const [actionValue, setActionValue] = useState('');

  const handleClose = (item) => {
    onClose(item);
  };

  const selectValue = (item) => {
    setActionValue(item)
    handleClose(item);
  }

  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <List>
        {/* <ListItem button onClick={() => selectValue('Photo')}>
          <ListItemText primary="Photo" />
        </ListItem> */}
        <ListItem  style={{cursor:"pointer"}}>
          <ListItemText primary="Media" onClick={() => selectValue('All')}/>
        </ListItem>
      </List>
    </Popover>
  );
}

export default ChatMediaOption;
