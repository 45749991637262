import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Typography, Grid, Button } from '@mui/material';
import { fetchBlogById, postCommentById } from 'app/shared/reducers/blogs';
import { useAppDispatch } from 'app/config/store';
import { useParams } from 'react-router-dom';

const BlogComment = ({ commentData, handleReply, open, handleClose, onClose }) => {
  const dispatch = useAppDispatch();
  const [comment, setComment] = useState('');
  const { id } = useParams();
  const blogId = Number(id.match(/\d+$/)[0]);
  console.log('handleReply', handleReply);
  const handleChange = event => {
    setComment(event.target.value);
  };

  const handleOKClick = payload => {
    onClose(payload);
  };

  const handleSubmit = () => {
    const newReply = {
      blogId: blogId,
      commentId: handleReply.id,
      comment: comment,
    };

    const fetchPost = async () => {
      let response, postResponse;
      try {
        console.log('New reply payload:', newReply);
        response = await dispatch(postCommentById(newReply));
        console.log('Response from postCommentById:', response);
        postResponse = await dispatch(fetchBlogById(blogId));
        console.log('Response from fetchBlogById:', postResponse);
        handleOKClick(postResponse.payload);
      } catch (error) {
        console.error('Error occurred:', error);
      }
    };
    fetchPost();
    setComment('');
    handleClose();
  };

  const isPostButtonDisabled = comment.trim() === '';

  return (
    <Grid container direction="column" gap={1} paddingTop="2%">
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Replying to {commentData?.name}</DialogTitle>
        <DialogContent>
          <TextField
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            value={comment}
            onChange={handleChange}
            placeholder="Your reply"
            inputProps={{ maxLength: 400 }}
          />
          <Typography variant="caption" color="textSecondary" sx={{ alignSelf: 'flex-end' }}>
            {comment.length}/400
          </Typography>
        </DialogContent>
        <Grid container justifyContent="flex-end" p={2}>
          <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isPostButtonDisabled}>
            Post
          </Button>
        </Grid>
      </Dialog>
    </Grid>
  );
};

export default BlogComment;
