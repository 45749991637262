import React from 'react';
import CreateActivitySelectCategory from './select-category/create-activity-select-category';
import CreateActivityBody from '../create-activity-main-sec/create-actvity-body/create-activity-body';
interface CreateActivityProps {
  setCreateActivityData: React.Dispatch<React.SetStateAction<{}>>;
  formik: any;
}
const CreateActivityMainSec = ({ setCreateActivityData, formik, setError }: any) => {
  return (
    <div className=" row d-flex  flex-column">
      <div>
        <CreateActivitySelectCategory formik={formik} setCreateActivityData={setCreateActivityData} />
      </div>
      <div>
        <CreateActivityBody setError={setError} formik={formik} setCreateActivityData={setCreateActivityData} />
      </div>
    </div>
  );
};
export default CreateActivityMainSec;
