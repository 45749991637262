// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.example-4::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example-4 {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.css-t7har8-MuiListItem-root,
.css-jyqr88-MuiListItem-root {
  width: 80% !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.css-ypiqx9-MuiDialogContent-root {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.css-ypiqx9-MuiDialogContent-root::-webkit-scrollbar {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/ui/activity-details-section/activity-resource/activity-contribute.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA,4CAAA;AACA;EACE,wBAAA,EAAA,gBAAA;EACA,qBAAA,EAAA,YAAA;AACF;;AACA;;EAEE,qBAAA;AAEF;;AACA,4CAAA;AACA;EACE,mCAAA,EAAA,gBAAA;EACA,gCAAA,EAAA,YAAA;AAEF;;AAAA;EACE,aAAA;AAGF","sourcesContent":[".example-4::-webkit-scrollbar {\n  display: none;\n}\n\n/* Hide scrollbar for IE, Edge and Firefox */\n.example-4 {\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n}\n.css-t7har8-MuiListItem-root,\n.css-jyqr88-MuiListItem-root {\n  width: 80% !important;\n}\n\n/* Hide scrollbar for IE, Edge and Firefox */\n.css-ypiqx9-MuiDialogContent-root {\n  -ms-overflow-style: none !important; /* IE and Edge */\n  scrollbar-width: none !important; /* Firefox */\n}\n.css-ypiqx9-MuiDialogContent-root::-webkit-scrollbar {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
