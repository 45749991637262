import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const CleanengergyIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="25.93" viewBox="0 0 21 25.93" style={{ fontSize: 'larger' }}>
    <path
      id="Clean_Energy"
      data-name="Clean Energy"
      d="M12.718.156A1.287,1.287,0,0,0,12.2.27c-2,.9-.5,6.663,1.431,11.4A3.861,3.861,0,0,1,19.758,8.9s.012,0,.016,0C17.764,4.838,14.738.156,12.718.156ZM19.774,8.9a3.876,3.876,0,0,1-.683,6.682c1.047.067,2.077.114,3.056.114,2.4,0,8-.238,8.21-2.357C30.577,11.164,24.834,9.6,19.774,8.9Zm-.683,6.682c-.01,0-.022,0-.033,0a3.823,3.823,0,0,1-1.561.341,3.893,3.893,0,0,1-3.885-3.885,3.748,3.748,0,0,1,.033-.374c-2.833,4.251-5.483,9.6-3.707,10.876a1.347,1.347,0,0,0,.8.244C12.783,22.786,16.492,18.927,19.091,15.584ZM17.5,9.179a2.861,2.861,0,1,0,2.861,2.861A2.865,2.865,0,0,0,17.5,9.179Zm0,1.561a1.3,1.3,0,1,1-1.3,1.3A1.3,1.3,0,0,1,17.5,10.74Zm1.821,6.308a39.375,39.375,0,0,1-3.9,4.243l-.358,4.227a.5.5,0,0,0,.13.39.545.545,0,0,0,.39.179h3.837a.518.518,0,0,0,.374-.179.528.528,0,0,0,.146-.39Z"
      transform="translate(-9.364 -0.156)"
      fill="#676e7b"
    />
  </svg>,
  'CleanengergyIcon'
);
export default CleanengergyIcon;
