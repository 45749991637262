import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const ModeratorStarIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
    <g id="Group_4951" data-name="Group 4951" transform="translate(2013 15383)">
      <circle id="Ellipse_238" data-name="Ellipse 238" cx="7.5" cy="7.5" r="7.5" transform="translate(-2013 -15383)" fill="#9ae3ff"/>
      <path id="Polygon_48" data-name="Polygon 48" d="M4.174.707a.4.4,0,0,1,.714,0l.978,1.937a.4.4,0,0,0,.294.215L8.294,3.2a.4.4,0,0,1,.221.676L6.983,5.423a.4.4,0,0,0-.111.343L7.21,7.923a.4.4,0,0,1-.578.418L4.714,7.354a.4.4,0,0,0-.366,0L2.431,8.34a.4.4,0,0,1-.578-.418L2.19,5.766a.4.4,0,0,0-.111-.343L.548,3.876A.4.4,0,0,1,.769,3.2L2.9,2.858A.4.4,0,0,0,3.2,2.644Z" transform="translate(-2010.281 -15380.1)" fill="#236d8a"/>
    </g>
  </svg>,
  'ModeratorStarIcon'
);

export default ModeratorStarIcon;
