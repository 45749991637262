import React, { Children, ReactNode, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import SettingsBody from './settings-body/settings-body';

import { getReportingReasons } from 'app/shared/reducers/activity/activity-report';
import PrimaryHeader from 'app/components/layout/header/primary-header';
import './secondary-header.scss';
import AmyIcon from 'app/components/common/cards/chat-card/amy-icons';

const drawerWidth = 315;
const drawerWidthSm = 248;

const SecondaryHeader = ({ children }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDarkMode = theme.palette.mode === 'dark';
  const [searchKeyword, setSearchKeyword] = React.useState<string>('');
  const [isSearching, setIsSearching] = React.useState(false);
  const [localSearchInput, setLocalSearchInput] = React.useState('');
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getReportingReasons());
    }
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        background: isDarkMode ? '#1F1F26' : 'transparent linear-gradient(118deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat',
        p: 0,
        border: 'none',
        backdropFilter: 'blur(10px)',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <CssBaseline />

      {/* header */}
      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidthSm}px)`, md: `calc(100% - ${drawerWidthSm}px)`, lg: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidthSm}px`, md: `${drawerWidthSm}px`, lg: `${drawerWidth}px` },
          background: isDarkMode ? '#1F1F26' : 'none',
        }}
      >
        <Toolbar
          className="amy-top-nav"
          sx={{
            backgroundColor: ' transparent linear-gradient(118deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat',
            border: 'none',
            backdropFilter: 'blur(10px)',
          }}
        >
          <PrimaryHeader
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            isSearching={isSearching}
            setIsSearching={setIsSearching}
            setLocalSearchInput={setLocalSearchInput}
            localSearchInput={localSearchInput}
          />
        </Toolbar>
      </AppBar>
      {/* main body */}
      <Box
        className="inside-bg"
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: '0px',
          background: isDarkMode
            ? '#1F1F26'
            : ' transparent linear-gradient(172deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat padding-box',
          border: 'none',
          backdropFilter: 'blur(10px)',
        }}
      >
        <Toolbar sx={{ alignItems: 'start' }}>
          <Box
            pt={1}
            sx={{ paddingLeft: '5px', paddingRight: '50px', paddingTop: '1px', marginTop: '-24px' }}
            onClick={() => navigate('/')}
            className="mob-logo"
          >
            <AmyIcon sx={{ width: '159px', cursor: 'pointer', height: '100px' }} />
          </Box>
        </Toolbar>
        <Box className="container-fluid body-padding" sx={{ background: isDarkMode ? '#1F1F26' : '', padding: '31px', paddingTop: '10px' }}>
          <div className="row screen-space">
            <div className=" col-sm-12 col-md-10 col-lg-12 col-scroll">
              <Box
                sx={{
                  border: 'none',
                  maxHeight: '100vh',
                  overflowY: 'scroll',
                  overflowX: 'hidden',
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                  '&::-webkit-scrollbar-thumb': { backgroundColor: 'secondary.contrastText', height: '10px' },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                {children}
              </Box>
            </div>
          </div>
        </Box>
      </Box>
      {/* End main body */}
    </Box>
  );
};
export default SecondaryHeader;
