import React from 'react';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

interface ButtonData {
  label: string;
  onClick: () => void;
}

interface GenericModalProps {
  handleClose: () => void;
  fullScreen: boolean;
  open: boolean;
  title: string;
  buttons: ButtonData[];
}

const GenericModal = ({ handleClose, fullScreen, open, title, buttons }: GenericModalProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal"
      PaperProps={{
        sx: {
          borderRadius: { xs: 0, sm: '15px' },
          boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
        },
      }}
    >
      <DialogContent>
        <Grid direction={'column'} spacing={3} container alignItems={'center'} justifyContent={'center'}>
          <Grid item>
            <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant="h5">{title}</Typography>
          </Grid>
          <Grid item>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} gap={2} p={2}>
              {buttons.map((button, index) => (
                <Button
                  key={index}
                  variant={index === 0 ? 'contained' : 'outlined'}
                  onClick={button.onClick}
                  sx={{
                    backgroundColor: 'primary.light',
                    color: 'common.white',
                    border: 'none',
                    '&:hover': {
                      backgroundColor: 'primary.light',
                      color: 'common.white',
                      border: 'none',
                    },
                  }}
                >
                  {button.label}
                </Button>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default GenericModal;
