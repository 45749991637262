import { useState, useEffect } from 'react';
import axios from 'axios';
import { apiEndPoints } from 'app/utils/data/constants/api-end-points';

const useFileUpload = selectedFile => {
  const [imageResponse, setImageResponse] = useState(null);
  const [imageUploaded, setImageUploaded] = useState(false);
  const [image, setImage] = useState(null);

  useEffect(() => {
    const sendFile = async () => {
      try {
        if (selectedFile !== null) {
          const formData = new FormData();
          formData.append('file', selectedFile as Blob);

          const response = await axios.post<any>(apiEndPoints.imageUpload, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          if (response.status === 201) {
            setImageResponse(response.data);
            setImageUploaded(true);
            setImage(response.data);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    sendFile();
  }, [selectedFile]);

  return { imageUploaded, image };
};

export default useFileUpload;
