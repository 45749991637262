// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 380px) {
  .mob-input {
    padding-top: 50px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/common/cards/chat-card/chat-feed.scss"],"names":[],"mappings":"AAAA;EACA;IACI,4BAAA;EACF;AACF","sourcesContent":["@media only screen and (max-width: 380px) {\n.mob-input {\n    padding-top: 50px !important;\n}\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
