import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const MyActivitiesIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15.158" viewBox="0 0 18 15.158">
    <g id="rewind" transform="translate(0)">
      <path
        id="Path_73"
        data-name="Path 73"
        d="M30.9,15l-.468.809H20.011V9.254l1.634-.815Z"
        transform="translate(-20.011 -8.231)"
        fill="#676e7b"
      />
      <ellipse
        id="Ellipse_111"
        data-name="Ellipse 111"
        cx="1.023"
        cy="1.023"
        rx="1.023"
        ry="1.023"
        transform="translate(0 0)"
        fill="#676e7b"
      />
      <path
        id="Path_74"
        data-name="Path 74"
        d="M30.9,24.809,30.432,24H20.011v6.556l1.634.815Z"
        transform="translate(-20.011 -16.421)"
        fill="#676e7b"
      />
      <ellipse
        id="Ellipse_112"
        data-name="Ellipse 112"
        cx="1.023"
        cy="1.023"
        rx="1.023"
        ry="1.023"
        transform="translate(0 13.113)"
        fill="#676e7b"
      />
      <path
        id="Path_75"
        data-name="Path 75"
        d="M16.9,15l-.468.809H6.011V9.254l1.634-.815Z"
        transform="translate(0.621 -8.231)"
        fill="#676e7b"
      />
      <path
        id="Path_76"
        data-name="Path 76"
        d="M5.947,22A.947.947,0,0,0,5,22.947H6.895A.947.947,0,0,0,5.947,22Z"
        transform="translate(11.105 -15.368)"
        fill="#676e7b"
      />
      <ellipse
        id="Ellipse_113"
        data-name="Ellipse 113"
        cx="1.023"
        cy="1.023"
        rx="1.023"
        ry="1.023"
        transform="translate(6.632 0)"
        fill="#676e7b"
      />
      <path
        id="Path_77"
        data-name="Path 77"
        d="M16.9,24.809,16.432,24H6.011v6.556l1.634.815Z"
        transform="translate(0.621 -16.421)"
        fill="#676e7b"
      />
      <path
        id="Path_78"
        data-name="Path 78"
        d="M5.947,24.947A.947.947,0,0,1,5,24H6.895A.947.947,0,0,1,5.947,24.947Z"
        transform="translate(11.105 -16.421)"
        fill="#676e7b"
      />
      <ellipse
        id="Ellipse_114"
        data-name="Ellipse 114"
        cx="1.023"
        cy="1.023"
        rx="1.023"
        ry="1.023"
        transform="translate(6.632 13.113)"
        fill="#676e7b"
      />
    </g>
  </svg>,

  'MyActivitiesIcon'
);
export default MyActivitiesIcon;
