import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import SideNavigation from 'app/components/layout/side-navigaion/side-navigation';
import PrimaryHeader from 'app/components/layout/header/primary-header';
import { fetchProfile, fetchUnAuthorizedProfile } from 'app/shared/reducers/profile';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import AmyIcon from 'app/components/common/cards/chat-card/amy-icons';
import toast from 'react-hot-toast';
import { Button, Grid, useMediaQuery } from '@mui/material';
import AuthorisationModal from 'app/components/common/authorization-modal/authorization-modal';
import { resetLogin, toggleModalLogin } from 'app/shared/reducers/authentication';
import axios from 'axios';
import NewPrimarySearchAppBar from 'app/components/layout/header/new-primary-header';
import { Container, Typography, Card, CardContent, CardMedia, List, ListItem, Link } from '@mui/material';
import './how-it-works.scss';

const drawerWidth = 315;
const drawerWidthSm = 248;

interface Props {
  window?: () => Window;
}
const HowItWorks = () => {
  // const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [currentComponent, setCurrentComponent] = React.useState(' ');
  const [authModalOpen, setAuthModalOpen] = React.useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDarkMode = theme.palette.mode === 'dark';
  const [toggleValue, setToggleValue] = useState(false);
  const [toggleMapValue, setToggleMapValue] = useState(false);
  const [searchKeyword, setSearchKeyword] = React.useState<string>('');
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [routeBack, setRouteBack] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const location = useLocation();
  const isBackHome = location.state?.isBackHome;
  const [htmlContent, setHtmlContent] = useState('');
  // const isBackHome = true;
  const fetchAboutUs = async () => {
    try {
      const response = await axios.get<any>(`api/staticContent/how-it-works`);
      console.log('community-guidelines', response);
      setHtmlContent(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAboutUs();
  }, []);
  useEffect(() => {
    if (isBackHome) {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener('popstate', e => {
        e.preventDefault();
        window.history.pushState(null, null, window.location.pathname);
      });
    }
  }, []);

  const handleSubCategoryClick = (category: string, subCategory: string) => {
    setCategory(category);
    setSubCategory(subCategory);
  };
  const ImageContainer = styled(Box)({
    position: 'relative',
    marginBottom: '20px',
  });

  const StyledCard = styled(Card)({
    padding: '15px',
    borderRadius: '8px',
    textAlign: 'left',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  });

  const StyledButton = styled(Button)({
    backgroundColor: '#FFCE00',
    color: '#333333',
    borderRadius: '8px',
    fontSize: '12px',
    fontWeight: '500',
    padding: '15px 30px',
    textTransform: 'none',
    marginTop: '20px',
  });
  const StyledList = styled(List)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column', // Change to column for better vertical alignment
    padding: 0,
    margin: 0,
  }));

  const StyledListItem = styled(ListItem)(({ theme }) => ({
    padding: 0,
  }));

  const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: '#4351f1',
    '&:hover': {
      textDecoration: 'underline',
    },
  }));

  return (
    <Box sx={{ mb: 4 }}>
      <NewPrimarySearchAppBar searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} />
      <Box
        sx={{
          maxWidth: '800px',
          width: '100%',
          margin: 'auto',
          overflowY: 'scroll',
          height: '100vh',
          paddingBottom: '120px',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
      >
        <Container maxWidth="md" sx={{ padding: '20px' }}>
          <Typography variant="h4" align="center" gutterBottom sx={{ p: 5 }}>
            Change the world around you
            <br />
            by volunteering
          </Typography>

          <ImageContainer>
            <img
              src="	https://images.qa.reachamy.com/20240726153730_Mask_Group_6.png"
              alt="Volunteering"
              style={{ width: '100%', height: 'auto' }}
            />
            <StyledCard style={{ background: isDarkMode ? '#2c2c38' : '' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Connect with others by Micro Volunteering
                </Typography>
                <Typography variant="body2" sx={{ color: isDarkMode ? '#ffffffb3' : '#000000d1', fontSize: 'smaller', fontWeight: '500' }}>
                  One of the more well-known benefits of volunteering is the impact on the community. Volunteering allows you to connect to
                  your community and make it a better place. Even helping out with the smallest tasks can make a real difference to the
                  lives of people, animals, and organizations in need. And volunteering is a two-way street: It can benefit you and your
                  family as much as the cause you choose to help. Dedicating your time as a volunteer helps you make new friends, expand
                  your network, and boost your social skills.
                </Typography>
              </CardContent>
            </StyledCard>
          </ImageContainer>

          <Grid container spacing={2} alignItems="center" sx={{ marginBottom: '20px', marginTop: '20px' }}>
            <Grid item xs={12} sm={6}>
              <ImageContainer>
                <img
                  src="	https://images.qa.reachamy.com/20240726153805_NoPath_-_Copy_23_ec.png
"
                  alt="Additional Image"
                  style={{ width: '100%', height: 'auto' }}
                />
              </ImageContainer>
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledCard style={{ background: isDarkMode ? '#2c2c38' : '' }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Create and engage in Micro Volunteering activities
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: isDarkMode ? '#ffffffb3' : '#000000d1', fontSize: 'smaller', fontWeight: '500' }}
                  >
                    Volunteering might take you to a new part of your community you have never been to before. Volunteer programs can give
                    you the opportunity to bring people into your social network you otherwise wouldn’t get to meet and learn from those who
                    come from different walks of life. This experience can expand your understanding of others who are different from you.
                    Learning about cultures and ways of life that are different from one of your upbringing will increase your social
                    flexibility and expand your world-view. It will also raise awareness of the issues that other people deal with and will
                    increase important social skills like empathy and teamwork.
                  </Typography>
                </CardContent>
              </StyledCard>
            </Grid>
          </Grid>

          <ImageContainer>
            <Box sx={{ position: 'absolute', top: '0', left: '0', padding: '20px' }}>
              <Typography className="font-small" variant="h6" gutterBottom style={{ color: isDarkMode ? 'black' : '' }}>
                Streamlined Steps to Effective Community Volunteering
              </Typography>
            </Box>
            <img
              src="https://images.qa.reachamy.com/20240805072021_image_from_ios.jpg"
              alt="Volunteering"
              style={{ width: '100%', height: 'auto' }}
            />
          </ImageContainer>

          {/* <ImageContainer>
            <img
              src="	https://images.qa.reachamy.com/20240726153616_Group_6412.png"
              alt="Volunteering"
              style={{ width: '100%', height: 'auto' }}
            />
          </ImageContainer> */}

          <Box textAlign="center">
            <Typography variant="h4" gutterBottom textAlign="center">
              Become part of the amazing community
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: isDarkMode ? '#ffffffb3' : '#000000d1', fontSize: 'smaller', fontWeight: '500', px: 5 }}
              gutterBottom
            >
              Volunteering might take you to a new part of your community you have never been to before. Volunteer programs can give you the
              opportunity to bring people together, foster connections, and create positive change. Through these programs, you can build
              meaningful relationships, learn new skills, and make a significant impact on the lives of others.
            </Typography>
            <Button
              className="filter-btn-mob"
              onClick={() => navigate('/')}
              sx={{
                marginTop: ' 6px',
                textTransform: 'capitalize',
                backgroundColor: isDarkMode ? '#FFCE00;' : '#674bff',
                marginRight: '10px',
                color: isDarkMode ? 'black' : 'white',
                '&:hover': {
                 color: isDarkMode ? 'black' : 'white',
                  backgroundColor: isDarkMode ? '#FFCE00;' : '#674bff',
                  textTransform: 'capitalize',
                  boxShadow: 'none',
                },
              }}

              variant="contained"
              size="small"
            >
              Start your journey
            </Button>
          </Box>
          <Box paddingTop={2}>
            <Typography variant="body1" paragraph>
              <strong>Other Relevant Links :</strong>
            </Typography>

            <StyledList sx={{ display: 'flex', flexDirection: 'row' }}>
              <StyledListItem>
                <StyledLink sx={{ color: isDarkMode ? '#FFCE00;' : '#674bff' }} onClick={() => navigate('/community-guidelines')}>
                  Community Guidelines
                </StyledLink>
              </StyledListItem>
              <StyledListItem>
                <StyledLink sx={{ color: isDarkMode ? '#FFCE00;' : '#674bff' }} onClick={() => navigate('/privacy-policy')}>
                  Privacy Policy
                </StyledLink>
              </StyledListItem>
              <StyledListItem>
                <StyledLink sx={{ color: isDarkMode ? '#FFCE00;' : '#674bff' }} onClick={() => navigate('/cookie-policy')}>
                  Cookie Policy
                </StyledLink>
              </StyledListItem>
              <StyledListItem>
                <StyledLink sx={{ color: isDarkMode ? '#FFCE00;' : '#674bff' }} onClick={() => navigate('/legal-notices')}>
                  Legal Notices
                </StyledLink>
              </StyledListItem>
              <StyledListItem>
                <StyledLink sx={{ color: isDarkMode ? '#FFCE00;' : '#674bff' }} onClick={() => navigate('/terms-and-condition')}>
                  Terms and Conditions
                </StyledLink>
              </StyledListItem>
            </StyledList>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default HowItWorks;
