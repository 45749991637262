import React, { useEffect } from 'react';

import { Dialog, DialogActions, Button, Typography, TextField, Box, MenuItem, Select, FormControl, useTheme } from '@mui/material';

import toast from 'react-hot-toast';
import { blockParticipant, reportParticipant } from 'app/shared/reducers/participants';
import { Close } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { appConstants } from 'app/utils/data/constants/constants';

interface ReportParticipantDialogProps {
  open: any;
  onClose: (value?: any) => void;
  handleData: any;
  title: any;
  message: any;
  action: any;
  userId: any;
  onParticipantBlocked: (val: string) => void;
}

const ReportParticipantDialog = ({
  open,
  onClose,
  handleData,
  title,
  message,
  action,
  userId,
  onParticipantBlocked,
}: ReportParticipantDialogProps) => {
  const [reportReason, setReportReason] = React.useState('');
  const dispatch = useAppDispatch();
  const [selectedOption, setSelectedOption] = React.useState('');
  const reportingReasons = useAppSelector(state => state.reportActivityReducer.reportingReasons);
  const theme = useTheme();
  const handleReport = async () => {
    try {
      let postResponse;
      if (action === 'Report') {
        postResponse = await dispatch(
          reportParticipant({
            userID: userId ? userId : '',
            activityID: handleData?.id,
            reason: selectedOption ? selectedOption : '',
          })
        );
        if (postResponse) {
          toast.success(postResponse.payload.message);
        }
      } else if (action === 'Block') {
        postResponse = await dispatch(
          blockParticipant({
            userId: userId ? userId : '',
            activityId: handleData?.id,
            message: reportReason ? reportReason : '',
          })
        );
        if (postResponse) {
          onParticipantBlocked('true');
          toast.success(postResponse.payload?.message);
        }
      }

      handleOKClick(postResponse.payload);
      setReportReason('');
      onClose();
    } catch (error) {
      toast.error('Something went wrong');
      console.error(error);
    }
  };

  const handleCancel = () => {
    setReportReason('');
    onClose();
  };

  const handleOKClick = payload => {
    onClose(payload);
  };
  const showTextField = selectedOption === appConstants?.reportingReasonEnd;
  useEffect(() => {
    return () => {
      setSelectedOption('');
    };
  }, []);
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '15px',
          padding: '0.5%',
        },
      }}
    >
      <div style={{ padding: '16px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            cursor: 'pointer',
            paddingTop: '1%',
            paddingRight: '2%',
            paddingBottom: '2%',
          }}
        >
          <Close onClick={handleCancel} />
        </Box>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1">{message}</Typography>
        <FormControl fullWidth variant="outlined" sx={{ marginTop: '16px' }}>
          <Select
            value={selectedOption}
            onChange={e => {
              setSelectedOption(e.target.value);
            }}
            size="small"
            displayEmpty
            MenuProps={{
              PaperProps: {
                style: {
                  overflowY: 'scroll',
                },
              },
              MenuListProps: {
                style: {
                  maxHeight: '200px',
                },
              },
            }}
          >
            <MenuItem value="">Select a reason</MenuItem>
            {reportingReasons?.map((data: any) => (
              <MenuItem value={data.label} key={data.id}>
                <Typography sx={{ fontSize: '16px' }}>{data.label}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {showTextField && (
          <TextField
            label="Reason for Reporting"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            value={reportReason}
            onChange={e => setReportReason(e.target.value)}
            sx={{ marginTop: 2 }}
          />
        )}
      </div>
      <DialogActions>
        <Button onClick={handleCancel} sx={{ color: theme.palette.mode === 'dark' ? '#FFCE00' : '#674BFF' }}>
          Cancel
        </Button>
        <Button onClick={handleReport} sx={{ color: theme.palette.mode === 'dark' ? '#FFCE00' : '#674BFF' }} disabled={!selectedOption}>
          {action}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportParticipantDialog;
