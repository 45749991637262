import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './blog-table.reducer';

export const BlogTableDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const blogTableEntity = useAppSelector(state => state.blogTable.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="blogTableDetailsHeading">
          <Translate contentKey="reachamyApp.blogTable.detail.title">BlogTable</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{blogTableEntity.id}</dd>
          <dt>
            <span id="title">
              <Translate contentKey="reachamyApp.blogTable.title">Title</Translate>
            </span>
          </dt>
          <dd>{blogTableEntity.title}</dd>
          <dt>
            <span id="blogContent">
              <Translate contentKey="reachamyApp.blogTable.blogContent">Blog Content</Translate>
            </span>
          </dt>
          <dd>{blogTableEntity.blogContent}</dd>
          <dt>
            <span id="categoryId">
              <Translate contentKey="reachamyApp.blogTable.categoryId">Category Id</Translate>
            </span>
          </dt>
          <dd>{blogTableEntity.categoryId}</dd>
          <dt>
            <span id="imageId">
              <Translate contentKey="reachamyApp.blogTable.imageId">Image Id</Translate>
            </span>
          </dt>
          <dd>{blogTableEntity.imageId}</dd>
          <dt>
            <span id="postAsNgo">
              <Translate contentKey="reachamyApp.blogTable.postAsNgo">Post As Ngo</Translate>
            </span>
          </dt>
          <dd>{blogTableEntity.postAsNgo ? 'true' : 'false'}</dd>
          <dt>
            <span id="ngoUserId">
              <Translate contentKey="reachamyApp.blogTable.ngoUserId">Ngo User Id</Translate>
            </span>
          </dt>
          <dd>{blogTableEntity.ngoUserId}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="reachamyApp.blogTable.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{blogTableEntity.createdBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="reachamyApp.blogTable.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {blogTableEntity.createdAt ? <TextFormat value={blogTableEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="reachamyApp.blogTable.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{blogTableEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="reachamyApp.blogTable.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>
            {blogTableEntity.modifiedAt ? <TextFormat value={blogTableEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
        </dl>
        <Button tag={Link} to="/blog-table" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/blog-table/${blogTableEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default BlogTableDetail;
