import { FormControl, Grid, OutlinedInput, Typography } from '@mui/material';
import { isEmail } from 'app/utils/common-utils/common-utils';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { linkRegex } from './settings-org-social';

type Props = {
  website: string;
  handleDetailsChange: (fieldName: any, newValue: any) => void;
  hasPermission: boolean;
  showWebError: boolean;
  setShowWebError: Dispatch<SetStateAction<boolean>>;
  isWebLinkValid: boolean;
  setIsWebLinkValid: Dispatch<SetStateAction<boolean>>;
};

const SettingsOrgwebsite = ({
  website,
  handleDetailsChange,
  hasPermission,
  showWebError,
  setShowWebError,
  isWebLinkValid,
  setIsWebLinkValid,
}: Props) => {
  const handleWebsiteChange = event => {
    const newValue = event.target.value;

    if (isEmail(newValue)) {
      setShowWebError(true);
    } else {
      setIsWebLinkValid(linkRegex.test(newValue));
      setShowWebError(false);
      handleDetailsChange('website', newValue);
    }
  };

  return (
    <Grid container spacing={2} sx={{ alignItems: 'center' }}>
      <Grid item xs={12} md={4}>
        <Typography variant="h5" sx={{ fontSize: '14px' }}>
          Website
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <FormControl sx={{ width: '100%' }} variant="outlined">
          <OutlinedInput

            placeholder="Website address"
            sx={{ height: '40px', fontSize: "0.75rem" }}
            id="outlined-adornment-weight"
            onChange={handleWebsiteChange}
            value={website}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
            }}
            disabled={!hasPermission}
          />
          {website &&
            (showWebError ||
              (!isWebLinkValid && (
                <Typography variant="body2" color="error" sx={{ marginTop: 0.6 }}>
                  This is not a valid link
                </Typography>
              )))}
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default SettingsOrgwebsite;
