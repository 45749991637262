// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-jo0io-MuiButtonBase-root-MuiButton-root:hover {
  background: #674bff !important;
}

.css-jo0io-MuiButtonBase-root-MuiButton-root:active {
  background: #674bff !important;
  box-shadow: none;
}

.css-1csz3ax-MuiButtonBase-root-MuiButton-root:hover {
  background: #674bff !important;
}

.css-19baddv-MuiButtonBase-root-MuiButton-root:hover {
  background: #674bff !important;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/ui/settings/settings-body/settings-panels/settings-organization/settings-org-doc.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;AACF;;AACA;EACE,8BAAA;EACA,gBAAA;AAEF;;AAAA;EACE,8BAAA;AAGF;;AADA;EACE,8BAAA;AAIF","sourcesContent":[".css-jo0io-MuiButtonBase-root-MuiButton-root:hover {\n  background: #674bff !important;\n}\n.css-jo0io-MuiButtonBase-root-MuiButton-root:active {\n  background: #674bff !important;\n  box-shadow: none;\n}\n.css-1csz3ax-MuiButtonBase-root-MuiButton-root:hover {\n  background: #674bff !important;\n}\n.css-19baddv-MuiButtonBase-root-MuiButton-root:hover {\n  background: #674bff !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
