// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.org-invitation-avatar {
  max-width: -moz-min-content !important;
  max-width: min-content !important;
}

.org-invitation-btn {
  cursor: unset !important;
}

@media only screen and (max-width: 992px) {
  .org-invitation-button-mob {
    max-width: 28% !important;
    padding-left: 0px !important;
  }
}
.org-invitation-pt-0 {
  padding: 16px !important;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/ui/settings/settings-body/settings-panels/settings-organization/settings-org-right/settings-org-invitations.scss"],"names":[],"mappings":"AAAA;EACE,sCAAA;EAAA,iCAAA;AACF;;AACA;EACE,wBAAA;AAEF;;AAAA;EACE;IACE,yBAAA;IACA,4BAAA;EAGF;AACF;AADA;EACE,wBAAA;AAGF","sourcesContent":[".org-invitation-avatar {\n  max-width: min-content !important;\n}\n.org-invitation-btn {\n  cursor: unset !important;\n}\n@media only screen and (max-width: 992px) {\n  .org-invitation-button-mob {\n    max-width: 28% !important;\n    padding-left: 0px !important;\n  }\n}\n.org-invitation-pt-0 {\n  padding: 16px !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
