import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const SelectedTerribleIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
  <g id="Group_6125" data-name="Group 6125" transform="translate(-310 -111)">
    <g id="Group_6119" data-name="Group 6119" transform="translate(-8690 16615)">
      <circle id="Ellipse_260" data-name="Ellipse 260" cx="22.5" cy="22.5" r="22.5" transform="translate(9000 -16504)" fill="#EEEBFF"/>
      <g id="Group_6063" data-name="Group 6063" transform="translate(9012 -16492)">
        <g id="Group_4937" data-name="Group 4937" transform="translate(0 0)">
          <g id="Group_4936" data-name="Group 4936" transform="translate(0 0)">
            <g id="Group_4933" data-name="Group 4933">
              <ellipse id="Ellipse_241" data-name="Ellipse 241" cx="10.249" cy="10.249" rx="10.249" ry="10.249" transform="translate(0 20.498) rotate(-90)" fill="#ffd05d"/>
            </g>
            <g id="Group_4934" data-name="Group 4934" transform="translate(1.613 1.501)">
              <path id="Path_3531" data-name="Path 3531" d="M576.961,524.432a10.251,10.251,0,0,1-14,14.256,10.25,10.25,0,1,0,14-14.256Z" transform="translate(-562.962 -524.432)" fill="#fdc453"/>
            </g>
            <g id="Group_4935" data-name="Group 4935" transform="translate(0.685 0.757)">
              <path id="Path_3532" data-name="Path 3532" d="M555.954,528.813a9.515,9.515,0,0,1,17.059-5.8,9.516,9.516,0,1,0-15.794,10.543A9.472,9.472,0,0,1,555.954,528.813Z" transform="translate(-555.246 -518.239)" fill="#ffd77f"/>
            </g>
          </g>
        </g>
        <g id="Group_4940" data-name="Group 4940" transform="translate(3.609 6.513)">
          <g id="Group_4938" data-name="Group 4938" transform="translate(0 0)">
            <rect id="Rectangle_3005" data-name="Rectangle 3005" width="0.938" height="4.225" transform="translate(0 0.663) rotate(-44.999)" fill="#303030"/>
            <rect id="Rectangle_3006" data-name="Rectangle 3006" width="0.938" height="4.225" transform="translate(2.988 0) rotate(44.999)" fill="#303030"/>
          </g>
          <g id="Group_4939" data-name="Group 4939" transform="translate(9.63 0)">
            <rect id="Rectangle_3007" data-name="Rectangle 3007" width="0.938" height="4.225" transform="translate(0 0.663) rotate(-44.999)" fill="#303030"/>
            <rect id="Rectangle_3008" data-name="Rectangle 3008" width="0.938" height="4.225" transform="translate(2.988 0) rotate(45)" fill="#303030"/>
          </g>
        </g>
        <g id="Group_4941" data-name="Group 4941" transform="translate(8.398 10.355)">
          <ellipse id="Ellipse_242" data-name="Ellipse 242" cx="1.851" cy="3.138" rx="1.851" ry="3.138" fill="#695342"/>
          <path id="Path_3533" data-name="Path 3533" d="M625.205,635.165a2.84,2.84,0,0,0-3.085.1c.334.819.891,1.356,1.523,1.356C624.3,636.621,624.877,636.04,625.205,635.165Z" transform="translate(-621.792 -630.346)" fill="#f06669"/>
        </g>
      </g>
    </g>
  </g>
</svg>
,
  'SelectedTerribleIcon'
);

export default SelectedTerribleIcon;
