import React, { useEffect, useState } from 'react';
import LoginPage from './login';
import RegistrationPage from './registration/registration-page';
import ForgotPasswordPage from './forgot-password/forgot-password';
import LogoutModal from './logout/logout';
import { isValidEmail } from 'app/utils/common-utils/common-utils';
import { useAppSelector } from 'app/config/store';

const AuthenticationModal = ({ currentComponent, setCurrentComponent, handleAuthModalClose, handleSearchClear }: any) => {
  const [enteredMail, setEnteredMail] = useState(null);

  const renderComponent = () => {
    switch (currentComponent) {
      case 'login':
        return (
          <LoginPage
            handleAuthModalClose={handleAuthModalClose}
            setCurrentComponent={setCurrentComponent}
            currentComponent={currentComponent}
            setEnteredMail={setEnteredMail}
          />
        );
      case 'signup':
        return (
          <RegistrationPage
            setCurrentComponent={setCurrentComponent}
            currentComponent={currentComponent}
            handleAuthModalClose={handleAuthModalClose}
          />
        );
      case 'forgotPassword':
        return (
          <ForgotPasswordPage
            setCurrentComponent={setCurrentComponent}
            currentComponent={currentComponent}
            handleAuthModalClose={handleAuthModalClose}
            enteredMail={isValidEmail(enteredMail) ? enteredMail : null}
          />
        );
      case 'logout':
        return <LogoutModal handleAuthModalClose={handleAuthModalClose} handleSearchClear={handleSearchClear} />;
      default:
        return null;
    }
  };
  return <div>{renderComponent()}</div>;
};

export default AuthenticationModal;
