import {
  Box,
  Card,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import SettingsPaymentistorytable from './settings-payment-history-table';
import SearchIcon from '@mui/icons-material/Search';

const SettingsPaymentistory = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  return (
    <Box>
      <Card
        sx={{
          maxWidth: '100%',
          marginBottom: '15px !important;',
          padding: ' 10px',
          boxShadow: 'none',
          background: theme.palette.mode === 'dark' ? '#2C2C38' : '',
        }}
      >
        <Box className="row align-items-center">
          <Box className="col-6">
            <Typography variant="h6" sx={{ fontSize: '14px', fontWeight: '600' }} gutterBottom>
              Payment details
            </Typography>
          </Box>
          {/* <Box className="col-6 text-end">
            <TextField
              // label="Search"
              placeholder="Search"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box> */}
        </Box>
      </Card>
      <SettingsPaymentistorytable />
    </Box>
  );
};

export default SettingsPaymentistory;
