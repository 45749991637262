import React, { CSSProperties } from 'react';
import { Button, Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const VerifyOtpPage = () => {
  const isMobile = useMediaQuery('(max-width: 425px)');
  const navigate = useNavigate();
  const theme = useTheme();
  const imageText: CSSProperties = {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: '10px 10px 30px 10px',
    textAlign: 'center',
    fontFamily: 'Manrope',
    fontWeight: 600,
    fontSize: '41px',
    letterSpacing: '0px',
    color: 'white',
    display: isMobile ? 'none' : 'block',
  };
  const imageContainer: CSSProperties = {
    position: 'relative',
    width: isMobile ? '100vw' : '100%',
    height: '100vh',
    overflow: 'hidden',
  };
  const coverImage: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  };
  const imageOverlay: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  };
  const loginContainer: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    paddingTop: '70px',
    paddingBottom: '70px',
  };
  const heading = {
    color: theme.textColor.heading,
    marginBottom: '20px',
  };

  if (isMobile) {
    loginContainer.paddingRight = '10px';
    loginContainer.paddingLeft = '10px';
    loginContainer.height = '100vh';
    loginContainer.position = 'absolute';
    loginContainer.top = 0;
    loginContainer.left = 0;
    loginContainer.width = '100%';
    heading.color = 'white';
  }
  return (
    <Grid container spacing={2} style={{ marginTop: 0 }}>
      <Grid item xs={12} md={5} style={imageContainer}>
        <img src="content/images/login-page/login-background.jpg" alt="Login Image" style={coverImage} />
        <div style={imageOverlay}></div>

        <div style={imageText}>Create and engage in a Micro Volunteering activities</div>
      </Grid>
      <Grid item xs={12} md={7} style={loginContainer}>
        <div style={{ position: 'absolute', top: '20px', left: '20px' }} onClick={() => navigate('/home')}>
          <img src="content/images/logos/reachamy-logo.png" alt="reach amy logo" />
        </div>

        <h2 style={heading}>Verify OTP</h2>

        <TextField
          name="username"
          size="small"
          id="otp"
          placeholder="OTP"
          InputProps={{
            style: { backgroundColor: theme.palette.secondary.dark },
            inputMode: 'numeric',
          }}
          variant="outlined"
          style={{ marginBottom: '10px', width: '356px' }}
        />
        <Button
          onClick={() => navigate('/logins')}
          variant="contained"
          style={{
            marginBottom: '10px',
            width: theme.createActivity.width,
            height: theme.createActivity.height,
            backgroundColor: theme.palette.primary.main,
            borderRadius: theme.createActivity.borderRadius,
            color: '#292D34',
            borderColor: theme.palette.primary.main,
          }}
        >
          <span>
            <Typography style={{ textTransform: 'capitalize' }}>Verify</Typography>
          </span>
        </Button>
      </Grid>
    </Grid>
  );
};

export default VerifyOtpPage;
