import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const EducationIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24.084" height="24.901" viewBox="0 0 24.084 24.901" style={{ fontSize: 'larger' }}>
    <g id="Education" transform="translate(9.924 -6.37) rotate(43)">
      <g id="Group_6593" data-name="Group 6593" transform="translate(6 4.927)">
        <path
          id="Path_4804"
          data-name="Path 4804"
          d="M6.835,16.84a.758.758,0,0,1-.585-.251.807.807,0,0,1,0-1.169,3.62,3.62,0,0,0,1-3.257l-.5-2.672a2.872,2.872,0,0,1,.835-2.756,3.035,3.035,0,0,1,2.756-.752A.82.82,0,1,1,9.925,7.57,1.4,1.4,0,0,0,8.672,7.9a1.459,1.459,0,0,0-.334,1.253l.5,2.672a5.255,5.255,0,0,1-1.42,4.76A.758.758,0,0,1,6.835,16.84Z"
          transform="translate(-6 -5.9)"
          fill="#676e7b"
        />
      </g>
      <g id="Group_6594" data-name="Group 6594" transform="translate(9.34)">
        <path
          id="Path_4805"
          data-name="Path 4805"
          d="M18.518,19.958a29.21,29.21,0,0,0,1.5-9.1C20.021,1.086,16.514,0,15.01,0S10,1.086,10,10.856a29.21,29.21,0,0,0,1.5,9.1A49.165,49.165,0,0,0,18.518,19.958Z"
          transform="translate(-10)"
          fill="#676e7b"
        />
        <path
          id="Path_4806"
          data-name="Path 4806"
          d="M15.423,26.084c-1,0-2,0-2.923-.084a41,41,0,0,0,2.171,4.676.852.852,0,0,0,.668.418.744.744,0,0,0,.668-.418A25.338,25.338,0,0,0,18.179,26C17.343,26,16.425,26.084,15.423,26.084Z"
          transform="translate(-10.412 -4.288)"
          fill="#676e7b"
        />
      </g>
    </g>
  </svg>,
  'EducationIcon'
);
export default EducationIcon;
