import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import RulesTable from './rules-table';
import RulesTableDetail from './rules-table-detail';
import RulesTableUpdate from './rules-table-update';
import RulesTableDeleteDialog from './rules-table-delete-dialog';

const RulesTableRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<RulesTable />} />
    <Route path="new" element={<RulesTableUpdate />} />
    <Route path=":id">
      <Route index element={<RulesTableDetail />} />
      <Route path="edit" element={<RulesTableUpdate />} />
      <Route path="delete" element={<RulesTableDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default RulesTableRoutes;
