import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IActivityMaster } from 'app/shared/model/activity-master.model';
import { ActivityType } from 'app/shared/model/enumerations/activity-type.model';
import { getEntity, updateEntity, createEntity, reset } from './activity-master.reducer';

export const ActivityMasterUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const activityMasterEntity = useAppSelector(state => state.activityMaster.entity);
  const loading = useAppSelector(state => state.activityMaster.loading);
  const updating = useAppSelector(state => state.activityMaster.updating);
  const updateSuccess = useAppSelector(state => state.activityMaster.updateSuccess);
  const activityTypeValues = Object.keys(ActivityType);

  const handleClose = () => {
    navigate('/activity-master');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...activityMasterEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          activityType: 'NEIGHBOURHOOD_CLEANING',
          ...activityMasterEntity,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="reachamyApp.activityMaster.home.createOrEditLabel" data-cy="ActivityMasterCreateUpdateHeading">
            <Translate contentKey="reachamyApp.activityMaster.home.createOrEditLabel">Create or edit a ActivityMaster</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="activity-master-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('reachamyApp.activityMaster.categoryID')}
                id="activity-master-categoryID"
                name="categoryID"
                data-cy="categoryID"
                type="text"
              />
              <ValidatedField
                label={translate('reachamyApp.activityMaster.activityType')}
                id="activity-master-activityType"
                name="activityType"
                data-cy="activityType"
                type="select"
              >
                {activityTypeValues.map(activityType => (
                  <option value={activityType} key={activityType}>
                    {translate('reachamyApp.ActivityType.' + activityType)}
                  </option>
                ))}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/activity-master" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ActivityMasterUpdate;
