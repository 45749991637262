import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  InputAdornment,
  Stack,
  Typography,
  TextField,
  Button,
  Grid,
  useTheme,
  IconButton,
  Tooltip,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import TwitterIcon from 'app/components/icons/login-page-icons/twitter-icon';
import FacebookIcon from 'app/components/icons/login-page-icons/facebook-icon';
import LinkedInIcon from 'app/components/icons/login-page-icons/linkedin-icon';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Chip from '@mui/material/Chip';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { shareActivity, shareOrganization, sharePetition } from 'app/shared/reducers/send-link-to-emails';
import { extractIdFromUrl, isValidEmail, shareOnSocialMedia } from 'app/utils/common-utils/common-utils';
import './share-modal.scss';
import { useLocation } from 'react-router-dom';
interface ShareModalProps {
  fullScreen: boolean;
  handleModalClose: () => void;
  id?: number;
  open: boolean;
  onClose: () => void;
  message?: string;
}

const ShareModal = ({ fullScreen, handleModalClose, id, open, onClose, message }: ShareModalProps) => {
  const [emailGroup, setEmailGroup] = useState('');
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [personName, setPersonName] = React.useState<string[]>([]);
  const [inputValue, setInputValue] = React.useState<string>('');
  const [invalidEmail, setInvalidEmail] = React.useState<boolean>(false);
  const isEmailsSend = useAppSelector(state => state.shareViaMail.success);
  const isEmailsSendSuccessMessage = useAppSelector(state => state.shareViaMail.successMessage);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const userState = useAppSelector(state => state.authentication.account);
  const [email, setEmail] = useState('');
  const isOwnMail = (userState?.email || userState?.login) === email;
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const handleSnackClick = () => {
    setOpenSnackBar(true);
  };

  const handleCopyButton = data => {
    navigator.clipboard.writeText(data);
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 1500);
  };
  const handleCloseSnackBar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };
  const handleEmailGroupChange = event => {
    setEmailGroup(event.target.value);
  };

  const getStyles = (name: string) => {
    return {
      fontWeight: personName.includes(name) ? theme.typography.fontWeightMedium : theme.typography.fontWeightRegular,
      backgroundColor: theme.palette.mode === 'dark' ? 'primary.main' : '#F6F7F8',
    };
  };
  const handleDelete = (nameToDelete: string) => {
    setEmail('');
    const updatedNames = personName.filter(name => name !== nameToDelete);
    setPersonName(updatedNames);
  };

  const selectedChips = personName.map(name => (
    <Grid item key={name} xs={12} lg={12}>
      <Chip label={name} onDelete={() => handleDelete(name)} style={getStyles(name)} />
    </Grid>
  ));

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setInvalidEmail(false);
    const cleanedValue = event.target.value.replace(/,/g, '');
    setInputValue(cleanedValue);
    const emails = inputValue.split(',');
    const isValid = emails.every(email => isValidEmail(email));
    if (cleanedValue.trim() !== '') {
      const emails = cleanedValue.split(',');
      const isValid = emails.every(email => isValidEmail(email));
      // if (isValid) {
      //   setInvalidEmail(false);
      // } else {
      //   setInvalidEmail(true);
      // }
    } else {
      setInvalidEmail(false);
    }
  };
  const { pathname } = useLocation();
  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if ((event.key === 'Enter' || event.key === ' ' || event.key === ',') && inputValue.trim() !== '') {
      // setEmail('')
      if (isValidEmail(inputValue.trim())) {
        if (message === 'Your organization has been created succesfully') {
          console.log('check enter value', inputValue.trim());
          setEmail(inputValue.trim());
          if (!isOwnMail) {
            const updatedNames = [...personName, inputValue.trim()];

            setPersonName(updatedNames);
            setInputValue('');
            setInvalidEmail(false);
          }
        } else {
          const updatedNames = [...personName, inputValue.trim()];

          setPersonName(updatedNames);
          setInputValue('');
          setInvalidEmail(false);
        }
      } else {
        setInvalidEmail(true);
      }
    }
  };
  const showSuccessMessageFor5Secs = () => {
    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 4000);
  };
  useEffect(() => {
    if (isEmailsSend) {
      showSuccessMessageFor5Secs();
    }
  }, [isEmailsSend]);
  const action = (
    <>
      <Button size="small" onClick={handleCloseSnackBar}>
        Undo
      </Button>
      <IconButton size="small" aria-label="close" onClick={handleCloseSnackBar}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );
  const messgeToShare = 'Reach out to Reach Amy-';

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleModalClose}
      scroll="body"
      PaperProps={{
        sx: {
          borderRadius: { xs: 0, sm: '14px' },
          boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
          width: { lg: pathname === '/create-a-blog' ? 600 : 600 },
        },
      }}
    >
      <DialogContent sx={{ px: 2 }}>
        <Stack direction="column" gap={1}>
          <Stack direction="row" alignItems={'center'} justifyContent={'flex-end'}>
            <CloseIcon onClick={handleModalClose} sx={{ cursor: 'pointer' }} />
          </Stack>
          <Stack direction="column" gap={1.7}>
            <Stack direction="column" gap={2.4} px={2}>
              <Stack direction="column" gap={2.4}>
                <Typography className="mx-1" sx={{ fontWeight: 600, fontSize: '12px' }}>
                  Invite your friends through email.
                </Typography>

                {personName.length === 5 && (
                  <Typography color="error" variant="caption">
                    You have reached max invites
                  </Typography>
                )}

                <div className=" mx-1 " style={{ border: '1px solid #bfbfbf', borderRadius: '15px' }}>
                  {selectedChips.map((chip, index) => (
                    <div style={{ display: 'inline-block' }}>
                      <Grid key={index} sx={{ p: 1 }}>
                        {chip}
                      </Grid>
                    </div>
                  ))}

                  <div style={{ display: 'inline-block' }}>
                    <div style={{ display: 'flex' }}>
                      {personName.length >= 0 && personName.length < 5 ? (
                        <TextField
                          id="Chip"
                          fullWidth
                          size="small"
                          disabled={personName.length === 5 || showButton || isOwnMail}
                          value={inputValue}
                          onChange={handleInputChange}
                          onKeyDown={handleInputKeyDown}
                          placeholder="Enter emails"
                          className="no-outline"
                          InputProps={{
                            style: {
                              backgroundColor: 'primary.main',
                              fontSize: '14px',
                              padding: 0.5,
                              width: `${Math.max(inputValue.length * 10, 200)}px`,
                            },
                          }}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>

                  {personName.length >= 1 && (
                    <div className="d-flex justify-content-end">
                      <Button
                        variant="text"
                        sx={{
                          color: 'primary.light',
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                          '&:active': {
                            backgroundColor: 'transparent',
                          },
                          borderRadius: '30px',
                        }}
                        disabled={showButton || invalidEmail || personName.length === 0}
                      >
                        Send
                      </Button>
                    </div>
                  )}
                </div>

                <Grid item xs={12}>
                  <Typography color="error" variant="caption">
                    {invalidEmail && 'Invalid email format'}
                  </Typography>
                  {isOwnMail && (
                    <Typography color="error" variant="caption">
                      Self-Invitations not allowed. Please use a different email address.
                    </Typography>
                  )}
                  {isEmailsSend && showSuccessMessage && (
                    <Typography color="success.main" variant="caption">
                      {'Emails sent successfully'}
                    </Typography>
                  )}
                </Grid>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ShareModal;
