import axios, { AxiosResponse } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { serializeAxiosError } from './reducer.utils';
import { Storage } from 'react-jhipster';

export const initialState = {
  darkMode: Storage?.local.get('dark-mode') === true ? true : false,
};
interface setThemeParams {
  darkMode: boolean;
}

export type darkModeState = Readonly<typeof initialState>;

export const setTheme = createAsyncThunk(
  'togglingtheme',
  async (mode: setThemeParams) => {
    axios.put<any>('api/user-preferences/dark-mode', mode);
  },
  {
    serializeError: serializeAxiosError,
  }
);

export const darkModeSlice = createSlice({
  name: 'darkMode',
  initialState: initialState as darkModeState,
  reducers: {
    darkModeToggle(state, action) {
      state.darkMode = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(setTheme.fulfilled, (state, action) => {});
  },
});

export const { darkModeToggle } = darkModeSlice.actions;

// Reducer
export default darkModeSlice.reducer;
