import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

interface LoaderIconProps {
  color?: string;
  size?: number | string;
}
const LoaderIcon = ({ color = 'common.white', size = 18 }: LoaderIconProps) => {
  return <CircularProgress sx={{ color: color }} size={size} />;
};

export default LoaderIcon;
