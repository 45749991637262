import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const GenderequalityIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19.072" viewBox="0 0 21 19.072" style={{ fontSize: 'larger' }}>
    <g id="Genderequality" transform="translate(0 -5.105)">
      <path
        id="Path_7"
        data-name="Path 7"
        d="M13.363,20.98a1.932,1.932,0,1,0-1.932-1.932A1.934,1.934,0,0,0,13.363,20.98Z"
        transform="translate(-9.273 -9.744)"
        fill="#676e7b"
      />
      <path
        id="Path_8"
        data-name="Path 8"
        d="M68.765,16.3l-1.5-4.644a.744.744,0,0,0-.673-.49h-.3l-.022,0-.023,0H64.171L60.655,9.011a.56.56,0,0,0-.163-.067l-.085-1.331a1.183,1.183,0,0,0,.919-1.23l-.042-.653a.316.316,0,0,0-.339-.29l-1.668.106a.342.342,0,0,0-.359.335l.042.654a1.187,1.187,0,0,0,1.069,1.1l.086,1.345a.564.564,0,0,0-.243.209l-.2.311a.562.562,0,0,0,.181.779l3.32,2.038v3.162a.841.841,0,0,0-.293.289l-1.113,1.966a.461.461,0,0,0,.3.7l1.208.3v4.957a.756.756,0,0,0,.755.755h.073a.756.756,0,0,0,.755-.755V19.108c.054,0,.11.007.168.007a2.105,2.105,0,0,0,.217-.011v4.589a.756.756,0,0,0,.755.755h.074a.756.756,0,0,0,.755-.755V18.715l1.089-.28a.463.463,0,0,0,.3-.7L67.6,16.643a.757.757,0,0,0,.55.267h.175a.447.447,0,0,0,.441-.606ZM67.1,15.771a.841.841,0,0,0-.293-.289v-.951l.645,1.862Z"
        transform="translate(-47.792 -0.271)"
        fill="#676e7b"
      />
      <path
        id="Path_9"
        data-name="Path 9"
        d="M83.721,18.5A1.717,1.717,0,1,0,82,16.781,1.719,1.719,0,0,0,83.721,18.5Z"
        transform="translate(-66.519 -8.078)"
        fill="#676e7b"
      />
      <path
        id="Path_10"
        data-name="Path 10"
        d="M10.6,5.381,8.975,5.111a.514.514,0,0,0-.082-.006.313.313,0,0,0-.343.252L8.443,6a1.183,1.183,0,0,0,.793,1.316L8.986,8.826c-.01.006-.02.01-.029.016L5.014,11.664H2.38a1.009,1.009,0,0,0-.751.386L.137,14.128a.839.839,0,0,0-.046.839L1.3,17.11a.909.909,0,0,0,.728.425h.03v5.628a.929.929,0,1,0,1.857,0V18.177h.354v4.986a.929.929,0,1,0,1.857,0V12.7l3.683-2.634a.575.575,0,0,0,.138-.791l-.2-.29a.558.558,0,0,0-.363-.227l.227-1.372h.006a1.181,1.181,0,0,0,1.168-.992l.107-.646A.32.32,0,0,0,10.6,5.381ZM2.056,15.845l-.569-.926a.441.441,0,0,1,.012-.425l.557-.8Z"
        transform="translate(0)"
        fill="#676e7b"
      />
    </g>
  </svg>,
  'GenderequalityIcon'
);
export default GenderequalityIcon;
