import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import SettingsPanel from './settings-panels/settings-panels';
import { Box, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

const SettingsBody = () => {
  const navigate = useNavigate();
  const handleCloseButtonClick = () => {
    navigate(-1);
  };
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box>
      <div className="d-flex mb-2 ">
        <div onClick={handleCloseButtonClick}>
          <ArrowCircleLeftIcon sx={{
            cursor: 'pointer', color: isDarkMode ? '#FFCE00' : '#775fff',
            borderRadius: '50%', fontSize: '25px'
          }} />
        </div>
      </div>
      <Box>
        <Typography variant="h6">Settings</Typography>
      </Box>
      <Box>
        <SettingsPanel />
      </Box>
    </Box>
  );
};

export default SettingsBody;
