import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export interface Blog {
  id: number;
  blogType: string;
  blogImage: string;
  blogTitle: string;
  authorName: string;
  postedOn: string;
  authorImage: string;
  postDescription: string;
}

export interface BlogsState {
  blogs: Blog[];
  loading: boolean;
  error: string | null;
}

export const initialState: BlogsState = {
  blogs: [],
  loading: false,
  error: null,
};

export interface SingleBlogState {
  singleBlog: SingleBlogType | null;
  loading: boolean;
  error: string | null;
}

export const firstState: SingleBlogState = {
  singleBlog: null,
  loading: false,
  error: null,
};

interface SingleBlogType {
  id: number;
  blogType: string;
  blogImage: string;
  blogTitle: string;
  activity: boolean;
  postedOn: string;
  blogDescription: string;
  publisherName: string;
  publisherImage: string;
  publisherBio: string;
  commentsCount: number;
  ngoUser: boolean;
  ngoUserDetails: NgoType[];
  comments: Comment[];
}

interface NgoType {
  ngoName: string;
  ngoAddress: string;
  ngoContactPerson: string;
  ngoEmailId: string;
  ngoContactPhoneNumber: string;
  ngoLocationLatitude: number;
  ngoLocationLongitude: number;
}

interface Comment {
  id: number;
  name: string;
  profileImage: string;
  commentPostedOn: string;
  comment: string;
  commentRply: CommentReply[];
}

interface CommentReply {
  id: number;
  name: string;
  profileImage: string;
  commentPostedOn: string;
  comment: string;
}

export const fetchBlogs = createAsyncThunk<Blog[], void, { rejectValue: string }>('blogList/fetchBlogs', async () => {
  try {
    const response = await axios.get<Blog[]>('api/blogs');
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'An error occurred';
  }
});

export const fetchBlogsUnauthorized = createAsyncThunk<Blog[], void, { rejectValue: string }>(
  'blogList/fetchBlogsUnauthorized',
  async () => {
    try {
      const response = await axios.get<Blog[]>('api/unauthorized/blogs');
      return response.data;
    } catch (error) {
      throw error.response?.data?.message || 'An error occurred';
    }
  }
);

// export const fetchBlogById = createAsyncThunk<SingleBlogType, number, { rejectValue: string }>(
//   'blogList/fetchBlogById',
//   async (blogId, { rejectWithValue }) => {
//     try {
//       const response = await axios.get<SingleBlogType>(`api/blog-tables/${blogId}`);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response?.data?.message || 'An error occurred');
//     }
//   }
// );

export const fetchBlogById = createAsyncThunk<SingleBlogType, number, { rejectValue: string }>(
  'blogList/fetchBlogById',
  async (blogId, thunkAPI) => {
    try {
      const response = await axios.get<SingleBlogType>(`api/blog-tables/${blogId}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch data');
    }
  }
);

export const fetchBlogByIdUnauthorized = createAsyncThunk<SingleBlogType, number, { rejectValue: string }>(
  'blogList/fetchBlogByIdUnauthorized',
  async (blogId, { rejectWithValue }) => {
    try {
      const response = await axios.get<SingleBlogType>(`api/unauthorized/blog-tables/${blogId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const postCommentById = createAsyncThunk<any, { blogId?: any; commentId?: any; comment?: string }, { rejectValue: string }>(
  'blogList/postCommentById',
  async ({ blogId, commentId, comment }, { rejectWithValue }) => {
    try {
      const response = await axios.post<any>('api/blog-comments', { blogId, commentId, comment });
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

//edit Option
export const editCommentById = createAsyncThunk<
  any,
  { commentId: number; blogId: number; commentMessage: string },
  { rejectValue: string }
>('blogComments/editComment', async ({ commentId, blogId, commentMessage }, { rejectWithValue }) => {
  try {
    const response = await axios.put<any>(`api/blog-comments/comment/${commentId}`, { blogId, commentMessage });
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.response?.data?.message || 'An error occurred');
  }
});

export const deleteCommentById = createAsyncThunk<any, { commentId: number }, { rejectValue: string }>(
  'blogComments/editComment',
  async ({ commentId }, { rejectWithValue }) => {
    try {
      const response = await axios.put<any>(`api/blog-comments/delete/${commentId}`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const reportCommentById = createAsyncThunk<
  any,
  {
    commentId: number;
    blogId: number;
    reportCategory: string;
    reportReason: string;
  },
  { rejectValue: string }
>('api/blog-comments/report', async ({ commentId, blogId, reportCategory, reportReason }, { rejectWithValue }) => {
  try {
    const response = await axios.put<any>(`api/blog-comments/report/${commentId}`, {
      commentId,
      blogId,
      reportCategory,
      reportReason,
    });

    return response?.data;
  } catch (error) {
    return rejectWithValue(error.response?.data?.message || 'An error occurred');
  }
});

export const fetchBlogsByCategory = createAsyncThunk<Blog[], { categoryId?: any; subCategoryId?: any }, { rejectValue: string }>(
  'blogs/fetchBlogsByCategory',
  async ({ categoryId, subCategoryId }, { rejectWithValue }) => {
    try {
      const response = await axios.post<Blog[]>('api/blogs/category-subcategory', { categoryId, subCategoryId });
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);
export const fetchBlogsByCategoryUnauthorized = createAsyncThunk<
  Blog[],
  { categoryId?: any; subCategoryId?: any },
  { rejectValue: string }
>('blogs/fetchBlogsByCategoryUnauthorized', async ({ categoryId, subCategoryId }, { rejectWithValue }) => {
  try {
    const response = await axios.post<Blog[]>('api/unauthorized/blogs/category-subcategory', { categoryId, subCategoryId });
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.response?.data?.message || 'An error occurred');
  }
});

export const blogsSlice = createSlice({
  name: 'blogs',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchBlogs.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBlogs.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.blogs = action.payload;
      })
      .addCase(fetchBlogs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'An error occurred';
      })
      .addCase(fetchBlogsUnauthorized.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBlogsUnauthorized.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.blogs = action.payload;
      })
      .addCase(fetchBlogsUnauthorized.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'An error occurred';
      })

      .addCase(fetchBlogsByCategory.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBlogsByCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.blogs = action.payload;
      })
      .addCase(fetchBlogsByCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'An error occurred';
      })
      .addCase(fetchBlogsByCategoryUnauthorized.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBlogsByCategoryUnauthorized.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.blogs = action.payload;
      })
      .addCase(fetchBlogsByCategoryUnauthorized.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'An error occurred';
      })
      .addCase(postCommentById.pending, state => {})
      .addCase(postCommentById.fulfilled, (state, action) => {})
      .addCase(postCommentById.rejected, (state, action) => {});
  },
});

export default blogsSlice.reducer;
