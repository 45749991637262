import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export interface reportType {
  activityId: Number;
  message: string;
}

export interface reportActivityType {
  data: string;
}

export interface leaveActivityState {
  activityReport: reportActivityType | null;
  loading: boolean;
  error: string | null;
  success: boolean | null;
  reportingReasons: Array<object | null>;
}

export const initialState: leaveActivityState = {
  activityReport: null,
  loading: false,
  error: null,
  success: false,
  reportingReasons: null,
};

export const ReportActivity = createAsyncThunk<any, { activityId: number; message: string }, { rejectValue: string }>(
  '/api/activity-details/report-activity',
  async ({ activityId, message }, thunkAPI) => {
    try {
      const axiosResponse = await axios.post<any[]>(`/api/activity-details/report-activity?activityId=${activityId}&message=${message}`);
      return axiosResponse.data;
    } catch (error) {
      throw error.response?.data || 'An error occurred';
    }
  }
);

export const getReportingReasons = createAsyncThunk<{ name: string; id: number }[], void, { rejectValue: string }>(
  'reportingReasons/getReasons',
  async (_, thunkAPI) => {
    try {
      const axiosResponse = await axios.get<{ name: string; id: number }[]>('/api/report-messages');
      return axiosResponse.data;
    } catch (error) {
      throw error.response?.data?.message || 'An error occurred';
    }
  }
);

export const reportActivitySlice = createSlice({
  name: 'reportActivityReducer',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(ReportActivity.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(ReportActivity.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.activityReport = action.payload;
        state.success = true;
      })
      .addCase(ReportActivity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'An error occurred';
        state.success = false;
      })
      .addCase(getReportingReasons.pending, (state, action) => {
        state.success = false;
        state.loading = true;
      })
      .addCase(getReportingReasons.fulfilled, (state, action) => {
        state.reportingReasons = action.payload;
        state.success = true;
      })
      .addCase(getReportingReasons.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to Fetch Data';
      });
  },
});

export default reportActivitySlice.reducer;
