import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import { Menu } from '@mui/material';
import { getReaction } from 'app/shared/reducers/chat-messages/chat-reaction';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'app/config/store';
import { ConstantReaction } from 'app/entities/constants/constant-reaction';
import ExpressionIcon from '../expression-icon';
import HappyemojiIcons from './happy-emoji';
import { setConversations } from 'app/shared/reducers/chat-messages/chat-Panel';
import { useAppSelector } from 'app/config/store';
const ChatReaction = ({ message, onSelectEmoji, onClose, chatData }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useAppDispatch();
  const getAllConversation = useAppSelector(state => state.chatPanelReducer.chatResponse);
  const emojis = ConstantReaction?.emojis || [];
  const emojisInRows = [];
  const emojisPerRow = 4;

  // Split the emojis into rows
  for (let i = 0; i < emojis.length; i += emojisPerRow) {
    emojisInRows.push(emojis.slice(i, i + emojisPerRow));
  }

  const handleReactionClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEmojiClick = emoji => {
    reactMessage(message, emoji);
  };

  const reactMessage = async (item, emoji) => {
    const userid2 = JSON.parse(localStorage?.getItem('MyDetailsAccount'));
    const payload = {
      messageId: item.id,
      payload: {
        userId: userid2.id,
        eventId: item.eventId,
        eventType: item.eventType,
        reactType: emoji ? emoji : '',
      },
    };
    if (payload) {
      onSelectEmoji(emoji);
      handleClose();
      onClose(payload);
    }

    // try {
    //   const response = await dispatch(getReaction(payload));
    //   if (response) {
    //     toast.success(response?.payload?.message);

    //     const updatedConversations = chatData?.map((item: any) => (item.id === response.payload.id ? response.payload : item));
    //     const filterConversations = chatData?.filter((item: any) => item.id === payload?.messageId);
    //     console.log('updatedConversations', updatedConversations);
    //     // Update the chatData state with the updated conversations
    //     if (updatedConversations) {
    //       dispatch(setConversations(updatedConversations));
    //       onSelectEmoji(emoji);
    //       handleClose();
    //       onClose(payload);
    //     }
    //   }
    // } catch (error) {
    //   console.error('An error occurred while fetching data:', error);
    //   handleClose();
    // }
  };

  return (
    <div>
      <IconButton size="small" aria-label="emoji-reaction" onClick={handleReactionClick}>
        <HappyemojiIcons className="m-2" sx={{ fontSize: 'medium' }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {emojisInRows.map((row, rowIndex) => (
          <ButtonGroup key={rowIndex}>
            {row.map((emoji, index) => (
              <Button key={index} onClick={() => handleEmojiClick(emoji.icon)} variant="text" style={{ fontSize: '20px' }}>
                {emoji.icon}
              </Button>
            ))}
          </ButtonGroup>
        ))}
      </Menu>
    </div>
  );
};

export default ChatReaction;
