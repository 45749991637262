export enum ActivityType {
  NEIGHBOURHOOD_CLEANING = 'NEIGHBOURHOOD_CLEANING',

  PARK_BEAUTIFICATION = 'PARK_BEAUTIFICATION',

  TREE_PLANTING = 'TREE_PLANTING',

  BEACH_CLEANUP = 'BEACH_CLEANUP',

  POND_CLEANUP = 'POND_CLEANUP',

  PLASTIC_COLLECTION = 'PLASTIC_COLLECTION',
}
