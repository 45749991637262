import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popover,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'app/config/store';
import { Link } from 'react-router-dom';
import {
  generateGMapsLinkCords,
  transformDate,
  calculateDistance,
  getAccessToken,
  convertDateToYear,
  convertDateToFormattedString,
  getFormattedTime,
} from 'app/utils/common-utils/common-utils';
import CloseIcon from '@mui/icons-material/Close';
import { participateInActivity, participateInActivityCard } from 'app/shared/reducers/volunteer';
import ActivityContribute from 'app/components/ui/activity-details-section/activity-resource/activity-contribute';
import { isBefore, isEqual, differenceInMinutes, parseISO } from 'date-fns';
import { announceArrival } from 'app/shared/reducers/announce-arrival';
import toast from 'react-hot-toast';
import AllContributionsListModal from 'app/components/common/modals/all-contributions-list-modal/all-contributions-list-modal';
import AuthorisationModal from 'app/components/common/authorization-modal/authorization-modal';
import './activity-chat-sidebar.scss';
import Cookies from 'js-cookie';
import JoiningSuccessModal from 'app/components/common/modals/joining-success-modal';
import { getPhoneNumber, toggleModalLogin } from 'app/shared/reducers/authentication';
import { getAllEvents, getAllEventsById } from 'app/shared/reducers/chat-messages/left-panel';
import { getAllConversationsById } from 'app/shared/reducers/chat-messages/chat-Panel';
import RadioDialog from 'app/components/common/alert-dialog/radio-dialog';
import {
  fetchActivityDetails,
  fetchActivityDetailsUnauthorized,
  fetchRecurringDetails,
  fetchRecurringDetailsFilter,
  fetchUnAuthRecurringDetails,
} from 'app/shared/reducers/activity-home';
import RecurringRangeModal from '../activity-recurring-section/recurring-date-range/recurring-range-modal';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import axios from 'axios';
import GenericModal from 'app/components/common/modals/generic-modal';
import { fetchBanned, fetchBlocked } from 'app/shared/reducers/profile';
import { postingRulesType } from 'app/utils/types/activity/activity-types';
import InstructionsComponent from 'app/components/common/cards/activity-specific-instruction/activity-specific-instructions';
import PhoneVerification from '../phone-verification/phone-verification';

interface Activity {
  id: number;
  startDate: string;
  startTime: string;
  endTime: string;
  activityStatus: string;
  parentActivityStatus: string;
  disable: boolean;
  participant: boolean;
  organizer: boolean;
  parent: boolean;
}

const ActivityReccuringChatSideBar = ({
  activityResponseParent,
  volunteerButtonClick,
  isSubmitClicked,
  onVolunteerButtonClick,
  onIsSubmitClicked,
}) => {
  const [activityResponse, setactivityResponse] = useState<any>();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDarkMode = theme.palette.mode === 'dark';
  const [manualClick, setManualClick] = useState<boolean>(false);
  const [isContributeOpen, setIsContributeOpen] = useState(false);
  const [viewContributions, setviewContributions] = useState(false);
  const success = useAppSelector(state => state.chatPanelReducer.success);
  const [calDialogOpen, setCalDialogOpen] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [childrens, setChildrens] = React.useState<any>([]);
  const [isVolunteerModalOpen, setIsVolunteerModalOpen] = useState(false);

  const [authModalOpen, setAuthModalOpen] = React.useState(false);
  const [currentComponent, setCurrentComponent] = React.useState(' ');
  const [openModal, setOpenModal] = useState(false);
  const myCookieValue = Cookies.get('jhi-authenticationToken');
  const authenticationToken = myCookieValue;
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const userOwnedOrganizations = useAppSelector(state => state.organizationReducer.userOwnedOrganization);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [selectedVolunteerOptions, setSelectedVolunteerOptions] = useState<any[]>([]);
  const [volunteerActivityAnchorEl, setVolunteerActivityAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const openVolunteer = Boolean(volunteerActivityAnchorEl);
  const volunteerActivityId = openVolunteer ? 'volunteer-options-popover' : undefined;
  const [newChildrens, setNewChildrens] = React.useState<any>([]);
  const [showOrgDialog, setShowOrgDialog] = useState(false);
  const [activityPostingRules, setActivityPostingRules] = useState<postingRulesType[]>([]);
  const [openRemoteModal, setOpenRemoteModal] = useState<boolean>(false);
  useEffect(() => {
    const fetchPostingRules = async () => {
      try {
        const response = await axios.get<postingRulesType[]>('api/volunteer_instructions');

        setActivityPostingRules(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchPostingRules();
  }, [activityResponse]);
  const handleOrgsDialog = value => {
    setShowOrgDialog(value);
  };

  const handleCalender = () => {
    setManualClick(false);
    setCalDialogOpen(true);
  };

  const handleCloseModal = filteredDataId => {
    setCalDialogOpen(false);
    if (filteredDataId !== 0) {
      fetchActivitiesRecurringInfo(filteredDataId);
    }
  };

  const fetchActivitiesRecurringInfo = async id => {
    let response;
    if (isAuthenticated) {
      response = await dispatch(fetchActivityDetails(id));
    } else {
      response = await dispatch(fetchActivityDetailsUnauthorized(id));
    }
    if (response.meta.requestStatus == 'fulfilled') {
      setactivityResponse(response?.payload);
      setSelectedVolunteerOptions([]);
    }
  };
  useEffect(() => {
    if (activityResponseParent?.recurringId) {
      fetchFirstData();
    }
  }, [activityResponseParent?.recurringId]);

  const fetchData = async () => {
    if (activityResponseParent?.recurringId) {
      try {
        const response = await dispatch(fetchRecurringDetails(Number(activityResponseParent?.recurringId)));
        setChildrens(response?.payload);
      } catch (error) {
        console.error('Recurring failed:', error);
      }
    }
  };

  const fetchFirstData = async () => {
    if (activityResponseParent?.recurringId) {
      try {
        const response = await dispatch(fetchRecurringDetails(Number(activityResponseParent?.recurringId)));
        setChildrens(response?.payload);
        const activities: Activity[] = response?.payload as Activity[];
        const nonParentActivities = activities.filter(activity => !activity.parent);
        const now = new Date();
        const closestFutureActivity = nonParentActivities.find(activity => new Date(activity.startTime) > now);
        const activityToFetch = closestFutureActivity || nonParentActivities[0];
        if (activityToFetch) {
          fetchActivitiesRecurringInfo(activityToFetch?.id);
        }
      } catch (error) {
        console.error('Recurring failed:', error);
      }
    }
  };

  useEffect(() => {
    const allResourcesEmpty = activityResponse?.resources?.filter(item => item.filled > 0);
    allResourcesEmpty?.length === 0 ? setIsEmpty(true) : setIsEmpty(false);
  }, [activityResponse]);

  const handleContributionsViewModal = () => {
    setviewContributions(true);
  };

  const handleContributionsViewModalClose = () => {
    setviewContributions(false);
    fetchActivitiesRecurringInfo(activityResponse?.id);
  };

  const handleResource = () => {
    setIsContributeOpen(true);
  };

  const handleResourceClose = () => {
    setIsContributeOpen(false);
    fetchActivitiesRecurringInfo(activityResponse?.id);
  };
  const handleVolunteerAs = (id: any, event: React.MouseEvent<HTMLButtonElement>) => {
    if (isAuthenticated) {
      setVolunteerActivityAnchorEl(event.currentTarget);
    } else {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
    }
  };
  const handleVolunteerOptionsClose = () => {
    setVolunteerActivityAnchorEl(null);
  };
  const fetchDataDetails = async () => {
    if (activityResponseParent !== undefined) {
      try {
        if (isAuthenticated) {
          const response = await dispatch(fetchRecurringDetailsFilter({ id: activityResponseParent?.id, filterType: 'VOLUNTEER' }));
          if (response) {
            const responsePayload = response?.payload as Array<{
              id: number;
              startDate: string;
              startTime: string;
              endTime: string;
              activityStatus: string;
              parentActivityStatus: string;
              disable: null | boolean;
              parent: boolean;
              participant: boolean;
              organizer: boolean;
            }>;
            const sortedResponse = responsePayload?.sort((a, b) => {
              if (a.parent && !b.parent) return -1;
              if (!a.parent && b.parent) return 1;
              return 0;
            });
            setNewChildrens(sortedResponse);
          }
        }
      } catch (error) {
        console.error('Recurring failed:', error);
      }
    }
  };

  useEffect(() => {
    fetchDataDetails();
  }, [activityResponseParent]);
  const handleOptionVolunteerClick = (option: any) => {
    if (option?.parent) {
      if (selectedVolunteerOptions.includes(option?.id)) {
        setSelectedVolunteerOptions([]);
      } else {
        const allIds = newChildrens?.map(child => child.id);
        setSelectedVolunteerOptions([...allIds, option?.id]);
      }
    } else {
      const selectedId = option?.id;
      const parent = newChildrens.find(item => item.parent);
      const childIds = newChildrens.filter(item => !item.parent).map(child => child.id);
      if (selectedVolunteerOptions.includes(parent?.id)) {
        setSelectedVolunteerOptions(selectedVolunteerOptions.filter(id => id !== parent?.id));
      }
      let updatedSelection = selectedVolunteerOptions.includes(selectedId)
        ? selectedVolunteerOptions.filter(id => id !== selectedId)
        : [...selectedVolunteerOptions, selectedId];
      const areAllChildrenSelected = childIds.every(id => updatedSelection.includes(id));
      if (areAllChildrenSelected) {
        updatedSelection = [...updatedSelection, parent?.id];
      } else {
        updatedSelection = updatedSelection.filter(id => id !== parent?.id);
      }

      setSelectedVolunteerOptions(updatedSelection);
    }
  };

  const handleOkVolunteerClick = () => {
    if (isAuthenticated) {
      const parent = newChildrens.find(item => item.parent);
      if (selectedVolunteerOptions?.length > 1 && parent && selectedVolunteerOptions.includes(parent.id)) {
        setSelectedVolunteerOptions([parent.id]);
      }
      handleVolunteerReccuringAs();
      handleVolunteerOptionsClose();
    }
  };
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down(400));
  const [phoneVerificationStatus, setPhoneVerificationStatus] = useState(true);
  const [phoneVerificationModal, setPhoneVerificationModal] = useState<boolean>(false);
  const [otpVerificationCompleted, setOtpVerificationCompleted] = useState(false);
  const [phoneInputCompleted, setPhoneInputCompleted] = useState(false);
  useEffect(() => {
    if (localStorage.getItem('getPhone') && localStorage.getItem('getPhone') !== undefined) {
      const phoneDataString = localStorage.getItem('getPhone');
      if (phoneDataString) {
        try {
          const phoneData = JSON.parse(phoneDataString);
          if (typeof phoneData.verified === 'boolean') {
            setPhoneVerificationStatus(phoneData.verified);
          } else {
            console.error('Verified status is missing or invalid in localStorage data');
          }
        } catch (error) {
          console.error('Failed to parse phone data from localStorage:', error);
        }
      } else {
        console.log('No phone data found in localStorage');
      }
    }
  }, [localStorage.getItem('getPhone')]);

  const handlePhoneVerificationClose = () => {
    setPhoneVerificationModal(false);
    if (otpVerificationCompleted) {
      fetchPhoneNumber();
    } else {
      setPhoneInputCompleted(false);
    }
  };

  const fetchPhoneNumber = async () => {
    try {
      const response = await dispatch(getPhoneNumber());
      if (response.meta.requestStatus == 'fulfilled') {
        localStorage.setItem('getPhone', JSON.stringify(response.payload));
        setPhoneVerificationStatus(response.payload?.verified);
      } else {
        // toast.error('Unable to fetch verification Status', { id: 'phone-verification-status-error' });
      }
    } catch (error) {}
  };
  const handleVolunteerReccuringAs = async () => {
    if (isAuthenticated) {
      const res = await dispatch(fetchBanned({ userId: JSON.parse(localStorage.getItem('MyDetailsAccount'))?.id }));
      if (res?.payload) {
        toast.error(<>Action Restricted: You cannot perform this action as you are banned by the admin.</>);
        return;
      }

      if (phoneVerificationStatus) {
        if (userOwnedOrganizations?.length > 0) {
          if (selectedOption) {
            handleOrgsDialog(true);
            openVolunteerModal();
          } else {
            handleOrgsDialog(true);
          }
        } else {
          handleOrgsDialog(true);
          changeOrgId(1);
          openVolunteerModal();
        }
      } else {
        setPhoneVerificationModal(true);
      }
    } else {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
    }
  };
  const openVolunteerModal = () => {
    if (isAuthenticated) {
      setIsVolunteerModalOpen(true);
    } else {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
    }
  };

  const changeOrgId = value => {
    setSelectedOrgId(value);
  };
  const handleCancel = () => {
    setShowOrgDialog(false);
    setSelectedOrgId(null);
    setSelectedOption('');
  };
  const VolunteerModal = (
    <Grid>
      {activityResponse && Object.keys(activityResponse).length > 0 && (
        <Grid>
          <Dialog
            open={isVolunteerModalOpen}
            onClose={() => {
              setIsVolunteerModalOpen(false);
              handleCancel();
            }}
            PaperProps={{
              sx: {
                borderRadius: '15px',
                padding: '2%',
              },
            }}
          >
            <Stack direction="row" alignItems={'center'} justifyContent={'flex-end'}>
              <CloseIcon
                onClick={() => {
                  setIsVolunteerModalOpen(false);
                  handleCancel();
                }}
                sx={{ cursor: 'pointer' }}
              />
            </Stack>

            <DialogContent sx={{ height: 'auto', overflowY: 'hidden', paddingBottom: 0 }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                {activityPostingRules && activityPostingRules?.length > 0 ? (
                  <Grid>
                    <Typography sx={{ fontSize: '14px/22px', color: isDarkMode ? '#FFCE00' : '#674BFF', marginBottom: '10px' }}>
                      Instructions for volunteers
                    </Typography>
                    <Paper
                      elevation={3}
                      sx={{
                        backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))',
                        p: 2,
                        pt: 0,
                        height: '200px',
                        width: '100%',
                        overflowX: 'hidden',
                        boxShadow: 'none',
                        overflowY: 'scroll',
                        scrollbarWidth: 'thin',
                        '&::-webkit-scrollbar': {
                          display: 'block',
                        },
                        '&::-webkit-scrollbar-track': {
                          backgroundColor: 'primary.light',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: '#AAA',
                          borderRadius: '16px',
                        },
                      }}
                    >
                      {activityPostingRules?.length
                        ? activityPostingRules?.map(item => (
                            <Stack key={item.id} direction="row" spacing={1} alignItems="flex-start" justifyContent={'flex-start'}>
                              <Stack p={0.7}>
                                <Box
                                  sx={{
                                    width: '9px',
                                    height: '9px',
                                    borderRadius: '50%',
                                    backgroundColor: '#775FFF',
                                  }}
                                />
                              </Stack>

                              <Stack direction="row" sx={{ color: theme.textColor.paragraph, gap: 0.2, px: { xs: 0 } }}>
                                <Typography sx={{ fontSize: theme.infoText.regular.fontSize }}>{item.description}</Typography>
                              </Stack>
                            </Stack>
                          ))
                        : null}
                    </Paper>
                  </Grid>
                ) : null}
              </div>
              {/* <div className="mt-2" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                {activityResponse?.instructions && activityResponse?.instructions?.length > 0 ? (
                  <Grid>
                    <Typography sx={{ fontSize: '14px/22px', color: isDarkMode ? '#FFCE00' : '#674BFF', marginBottom: '10px' }}>
                      Instructions by organizers
                    </Typography>
                    <Paper
                      elevation={3}
                      sx={{
                        backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))',
                        p: 2,
                        height: '141px',
                        width: '100%',
                        overflowX: 'hidden',
                        boxShadow: 'none',
                        overflowY: activityResponse?.instructions && activityResponse?.instructions?.length > 3 ? 'scroll' : 'hidden',
                        scrollbarWidth: 'none',
                        '&::-webkit-scrollbar': {
                          display: 'none',
                        },
                        '&::-webkit-scrollbar-track': {
                          backgroundColor: 'primary.light',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: '#AAA',
                          borderRadius: '16px',
                        },
                      }}
                    >
                      {activityResponse?.instructions?.length
                        ? activityResponse?.instructions?.map(item => (
                            <Stack key={item.id} direction="row" spacing={1} alignItems="flex-start" justifyContent={'flex-start'}>
                              <Stack p={0.7}>
                                <Box
                                  sx={{
                                    width: '9px',
                                    height: '9px',
                                    borderRadius: '50%',
                                    backgroundColor: '#775FFF',
                                  }}
                                />
                              </Stack>
                              <Stack direction="row" sx={{ color: theme.textColor.paragraph, gap: 0.2, px: { xs: 0, lg: 2 } }}>
                                <Typography sx={{ fontSize: theme.infoText.regular.fontSize }}>{item.instruction}</Typography>
                              </Stack>
                            </Stack>
                          ))
                        : null}
                    </Paper>
                  </Grid>
                ) : null}
              </div> */}
              <InstructionsComponent passedData={activityResponseParent} />
            </DialogContent>

            <DialogActions
              sx={{
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <Stack
                direction={'column'}
                gap={1}
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  onClick={() => joinAndProceed()}
                  sx={{
                    background: theme.palette.primary.main,
                    color: '#292D34',
                    borderRadius: '15px',
                    width: '159px',
                    height: '40px',
                    '&:hover': {
                      background: theme.palette.primary.main,
                      color: '#292D34',
                      borderRadius: '15px',
                      width: '159px',
                      height: '40px',
                    },
                  }}
                >
                  Join & proceed
                </Button>

                <Typography sx={{ fontSize: '14px', color: theme.textColor.heading }}>
                  By joining this event you accept the rules/instructions of this event.
                </Typography>
              </Stack>
            </DialogActions>
          </Dialog>
        </Grid>
      )}
    </Grid>
  );

  const joinAndProceed = async () => {
    setIsButtonClicked(true);
    try {
      let response;

      if (selectedOrgId === null || selectedOrgId === 1) {
        response = await dispatch(participateInActivityCard({ activityIds: selectedVolunteerOptions }));
      } else {
        response = await dispatch(participateInActivityCard({ activityIds: selectedVolunteerOptions, organizationId: selectedOrgId }));
      }
      if (response.meta.requestStatus == 'fulfilled') {
        console.log('fulfilled', selectedVolunteerOptions);
        setSelectedOrgId(null);
        setSelectedOption('');
        setShowOrgDialog(false);
        onVolunteerButtonClick(true);
        setIsVolunteerModalOpen(false);
        setIsButtonClicked(false);
        setOpenModal(true);
        fetchData();
        fetchActivitiesRecurringInfo(activityResponse?.id);
        fetchDataDetails();
      } else {
        setIsVolunteerModalOpen(false);
        setSelectedOrgId(null);
        setSelectedOption('');
        setShowOrgDialog(false);
        onVolunteerButtonClick(true);
        setIsVolunteerModalOpen(false);
        setIsButtonClicked(false);
        setOpenModal(true);
      }
    } catch (error) {
      console.error('Error joining the activity:', error);
    }
  };
  const handleOptionChange = event => {
    const selectedValue = event.target.value;

    if (parseInt(selectedValue, 10) === 1) {
      setSelectedOrgId(1);
      setSelectedOption('My Self');
    } else {
      const selectedOrganization = userOwnedOrganizations.find(organization => organization.id === parseInt(selectedValue, 10));

      if (selectedOrganization) {
        setSelectedOption(selectedOrganization.label);
        setSelectedOrgId(selectedOrganization.id);
      }
    }
  };
  const handleInstructionsModal = value => {
    setIsVolunteerModalOpen(value);
  };

  const handleAuthModalClose = () => {
    setAuthModalOpen(false);
  };
  const handleModalClose = () => {
    setOpenModal(false);
  };
  const handleRemoteActivityClose = () => {
    setOpenRemoteModal(false);
  };
  const handleRemote = async () => {
    const body = {
      activityId: activityResponse?.id,
    };
    const response = await axios.post<any>(`/api/activity/announce-remote-arrival`, body);
  };

  return (
    <div>
      <RadioDialog
        selectedOrgId={selectedOrgId}
        open={showOrgDialog}
        handleShow={handleOrgsDialog}
        handleOptionChange={handleOptionChange}
        handleCancel={handleCancel}
        handleInstructionsModal={handleInstructionsModal}
      />
      <Grid container padding={0} gap={0}>
        <Grid item container>
          <Grid padding={1} sx={{ width: '100%', marginRight: '6px' }}>
            {activityResponseParent?.recurringId != null && (
              <div>
                {' '}
                <CalendarMonthIcon
                  onClick={handleCalender}
                  sx={{ height: '22px', width: '25px', color: isDarkMode ? '#FFCE00' : '#674BFF', cursor: 'pointer' }}
                />
                <span style={{ fontSize: '14px', color: isDarkMode ? '#FFCE00' : '#674BFF' }}>
                  {convertDateToYear(activityResponse?.startDate)}
                </span>
              </div>
            )}

            <Typography
              sx={{
                color: theme.textColor.mainHeading,
                fontSize: theme.p1.semiBold.fontSize,
                fontWeight: theme.p1.semiBold.fontWeight,
              }}
            >
              Instructions
            </Typography>
            <Paper
              elevation={3}
              sx={{
                background: isDarkMode ? '#2C2C38' : '#FFFFFF',
                borderRadius: '16px',
                p: 2,
                paddingLeft: '0px',
                height: '105px',
                width: '100%',
                overflowX: 'hidden',
                boxShadow: 'none',
                scrollbarWidth: 'auto',
                '&::-webkit-scrollbar': {
                  display: 'block',
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: isDarkMode ? '#2C2C38' : '#f0f0f0',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#AAA',
                  borderRadius: '16px',
                },
              }}
            >
              {activityResponse?.instructions?.length ? (
                [
                  ...new Map(
                    (activityResponse.instructions as { id: number; instruction: string }[]).map(item => [item.instruction, item])
                  ).values(),
                ].map(item => (
                  <Stack key={item.id} direction="row" spacing={1} alignItems="flex-start" justifyContent={'flex-start'}>
                    <Stack p={0.7}>
                      <Box
                        sx={{
                          width: '9px',
                          height: '9px',
                          borderRadius: '50%',
                          backgroundColor: '#775FFF',
                        }}
                      />
                    </Stack>
                    <Stack direction="row" sx={{ color: theme.textColor.paragraph, gap: 0.2, px: { xs: 0 } }}>
                      <Typography sx={{ fontSize: theme.infoText.regular.fontSize }}>{item.instruction}</Typography>
                    </Stack>
                  </Stack>
                ))
              ) : (
                <Typography style={{ textAlign: 'left' }}>Organizer has not listed any instructions.</Typography>
              )}
            </Paper>
          </Grid>
          <hr style={{ width: '100%', textAlign: 'left', marginLeft: '0' }}></hr>
          <Grid sx={{ width: '100%', marginRight: '6px' }}>
            <Grid padding={1}>
              <Typography
                sx={{
                  color: theme.textColor.mainHeading,
                  fontSize: theme.p1.semiBold.fontSize,
                  fontWeight: theme.p1.semiBold.fontWeight,
                }}
              >
                Resources needed
              </Typography>

              {!isEmpty && (
                <Typography
                  sx={{
                    color: theme.palette.secondary.light,
                    fontSize: theme.infoText.regular.fontSize,
                    fontWeight: theme.infoText.regular.fontWeight,
                    paddingBottom: '12px',
                  }}
                >
                  Please add your contributions that you are willing to bring
                </Typography>
              )}
              {!isEmpty && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Link
                    style={{ paddingBottom: '12px', fontSize: '14px', fontWeight: '600', color: '#674BFF', textDecoration: 'none' }}
                    onClick={() => handleContributionsViewModal()}
                    to={''}
                  >
                    View contributors
                  </Link>
                </div>
              )}
              {activityResponse?.resources?.length === 0 && (
                <Typography sx={{ textAlign: 'left' }}>Organizer has not listed any resources.</Typography>
              )}
              <Paper
                elevation={3}
                sx={{
                  background: isDarkMode ? '#2C2C38' : '#FFFFFF',
                  borderRadius: '16px',
                  p: 1,
                  height: '100px',
                  width: '100%',
                  overflowX: 'hidden',
                  overflowY: activityResponse?.resources?.length > 1 ? 'scroll' : 'hidden',
                  boxShadow: 'none',
                  scrollbarWidth: 'auto',
                  '&::-webkit-scrollbar': {
                    display: 'block',
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: isDarkMode ? '#2C2C38' : '#f0f0f0',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#AAA',
                    borderRadius: '16px',
                  },
                }}
              >
                <ol style={{ paddingLeft: '8px' }}>
                  {activityResponse?.resources?.length
                    ? activityResponse?.resources.map((resource, index) => (
                        <li key={index} className="pb-2">
                          <Typography
                            sx={{
                              color: theme.textColor.mainHeading,
                              fontSize: theme.p1.regular.fontSize,
                              fontWeight: theme.p1.regular.fontWeight,
                            }}
                          >
                            {resource.resources}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: theme.infoText.bold.fontSize,
                              fontWeight: theme.infoText.bold.fontWeight,
                              color: resource.filled >= resource.totalCount ? ' #00B158' : '#FF4B8E',
                            }}
                          >
                            {resource.filled >= resource.totalCount
                              ? `${resource.filled}/${resource.totalCount} Filled `
                              : `${resource.filled}/${resource.totalCount} Filled `}
                          </Typography>
                        </li>
                      ))
                    : null}
                </ol>
              </Paper>
              {activityResponse?.resources?.length ? (
                <Grid className="mt-3" item container justifyContent={'center'} alignItems={'center'} gap={2} marginBottom={2}>
                  {activityResponse?.participant &&
                    (activityResponse?.activityStatus === 'OPEN' || activityResponse?.activityStatus === 'RUNNING') && (
                      <Button
                        variant="outlined"
                        sx={{
                          borderRadius: '15px',
                          fontSize: '14px',
                          fontWeight: '600',
                          color: theme.palette.mode === 'dark' ? '#FFCE00' : '#674BFF',
                          borderColor: isDarkMode ? '#ffce00' : '#674bff',
                        }}
                        onClick={() => handleResource()}
                        disabled={activityResponse?.resources?.length === 0}
                      >
                        Contribute
                      </Button>
                    )}
                </Grid>
              ) : null}

              {activityResponse?.hasPermission && activityResponse?.activityStatus === 'OPEN' && (
                <Grid item container justifyContent={'center'} alignItems={'center'} className="mb-2">
                  <Button
                    variant="text"
                    sx={{
                      fontSize: '14px',
                      borderRadius: '20px',
                      height: '28px',
                      lineHeight: '12px',
                      cursor: 'auto',
                      color: 'primary.light',
                      '&:hover': {
                        color: 'primary.light',
                      },
                    }}
                  >
                    Thanks for organizing
                  </Button>
                </Grid>
              )}

              {!activityResponse?.hasPermission && activityResponse?.activityStatus === 'OPEN' && activityResponse?.participant && (
                <Grid item container justifyContent={'center'} alignItems={'center'} className="mb-2">
                  <Button
                    variant="text"
                    sx={{
                      fontSize: '14px',
                      borderRadius: '20px',
                      height: '28px',
                      lineHeight: '12px',
                      cursor: 'auto',
                      color: isDarkMode ? '#FFCE00' : '#674BFF',
                      '&:hover': {
                        color: isDarkMode ? '#FFCE00' : '#674BFF',
                      },
                    }}
                  >
                    Thanks for volunteering
                  </Button>
                </Grid>
              )}

              {activityResponse?.activityStatus === 'RUNNING' && activityResponse?.category?.id !== 19 && (
                <Grid item container justifyContent={'center'} alignItems={'center'} className="mb-2">
                  <Button
                    variant="text"
                    sx={{
                      fontSize: '14px',
                      borderRadius: '20px',
                      height: '28px',
                      lineHeight: '12px',
                      cursor: 'auto',
                      color: 'primary.light',
                      '&:hover': {
                        color: 'primary.light',
                      },
                    }}
                  >
                    Started
                  </Button>
                </Grid>
              )}

              {!activityResponse?.participant && activityResponse?.activityStatus === 'OPEN' && (
                <Grid item container justifyContent={'center'} alignItems={'center'} className="mb-2">
                  <Button
                    variant="contained"
                    style={{ textTransform: 'none' }}
                    sx={{ borderRadius: '15px', fontSize: '14px', fontWeight: '600' }}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      handleVolunteerAs(activityResponse.id, event);
                    }}
                  >
                    I want to volunteer
                  </Button>
                </Grid>
              )}

              {activityResponse?.activityStatus === 'ENDED' && (
                <Grid item container justifyContent={'center'} alignItems={'center'} className="mb-2">
                  <Button
                    variant="text"
                    sx={{
                      fontSize: '14px',
                      borderRadius: '20px',
                      height: '28px',
                      lineHeight: '12px',
                      cursor: 'auto',
                      color: isDarkMode ? '#FFCE00' : '#674BFF',
                      '&:hover': {
                        color: isDarkMode ? '#FFCE00' : '#674BFF',
                      },
                    }}
                  >
                    Pending feedback
                  </Button>
                </Grid>
              )}
              {activityResponse?.activityStatus === 'COMPLETED' && (
                <Grid item container justifyContent={'center'} alignItems={'center'} className="mb-2">
                  <Button
                    variant="text"
                    style={{
                      cursor: 'default',
                    }}
                    sx={{
                      fontSize: '14px',
                      borderRadius: '20px',
                      height: '28px',
                      lineHeight: '12px',
                      color: 'primary.light',
                      '&:hover': {
                        color: 'primary.light',
                      },
                    }}
                  >
                    Activity completed
                  </Button>
                </Grid>
              )}
              <Popover
                id={volunteerActivityId}
                open={openVolunteer}
                anchorEl={volunteerActivityAnchorEl}
                onClose={handleVolunteerOptionsClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <Box
                  sx={{
                    maxHeight: '150px',
                    overflowY: 'auto',
                    scrollbarWidth: 'thin',
                    '-ms-overflow-style': 'none',
                    '&::-webkit-scrollbar': {
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: '#888',
                    },
                  }}
                >
                  <ListItem sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', alignItems: 'inherit' }}>
                    {/* Render options with checkboxes */}
                    {newChildrens?.map(option => {
                      const datePart = convertDateToFormattedString(option?.startDate);
                      const timePart1 = getFormattedTime(option?.startTime);
                      const timePart2 = getFormattedTime(option?.endTime);

                      return (
                        <ListItemButton key={option?.id} onClick={() => handleOptionVolunteerClick(option)}>
                          <ListItemIcon>
                            <Checkbox checked={selectedVolunteerOptions.includes(option?.id)} />
                          </ListItemIcon>

                          {!option?.parent ? (
                            <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '13px' }}>
                              <span style={{ fontWeight: 'bold' }}>{datePart}</span>
                              <br />
                              {timePart1}-{timePart2}
                            </Typography>
                          ) : (
                            <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '13px' }}>
                              <span style={{ fontWeight: 'bold' }}>{'Select all'}</span>
                            </Typography>
                          )}
                        </ListItemButton>
                      );
                    })}
                  </ListItem>
                </Box>
                <div style={{ display: 'flex', padding: '10px' }}>
                  <ListItemButton
                    onClick={handleOkVolunteerClick}
                    disabled={selectedVolunteerOptions?.length === 0}
                    sx={{
                      backgroundColor: '#FFCE00',
                      color: 'black',
                      borderRadius: '15px',
                      '&:hover': {
                        backgroundColor: '#FFCE00',
                      },
                    }}
                  >
                    <ListItemText sx={{ p: 0, cursor: 'pointer', fontSize: '14px', textAlign: 'center' }}>Submit</ListItemText>
                  </ListItemButton>
                </div>
              </Popover>
              {/* login modal */}
              <AuthorisationModal
                currentComponent={currentComponent}
                fullScreen={fullScreen}
                authModalOpen={authModalOpen}
                onClose={handleAuthModalClose}
                handleAuthModalClose={handleAuthModalClose}
                setCurrentComponent={setCurrentComponent}
              />
              <JoiningSuccessModal
                handleClose={handleModalClose}
                fullScreen={fullScreen}
                open={openModal}
                url={'chat url'}
                title="Thank you for volunteering."
              />
              <Dialog
                fullScreen={fullScreen}
                open={openRemoteModal}
                onClose={handleRemoteActivityClose}
                aria-labelledby="remote-activity-modal"
                PaperProps={{
                  sx: {
                    borderRadius: { xs: 0, sm: '14px' },
                    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
                    width: { lg: 400 },
                  },
                }}
              >
                <Stack direction="column" sx={{ p: 2 }} spacing={3} minWidth={400} minHeight={200}>
                  <Stack direction="row" alignItems={'center'} justifyContent={'flex-end'}>
                    <CloseIcon onClick={handleRemoteActivityClose} sx={{ cursor: 'pointer' }} />
                  </Stack>
                  <Typography variant="h4" textAlign={'center'}>
                    Meeting Link
                  </Typography>
                  <Button
                    variant="outlined"
                    sx={{ borderColor: 'primary.light', py: 1, borderRadius: '20px', '&:hover': { borderColor: 'primary.light' } }}
                  >
                    <Link to={activityResponse?.onlineUrl} target="_blank" rel="noopener noreferrer" onClick={handleRemote}>
                      <Stack direction="column" alignItems={'center'}>
                        <Typography
                          sx={{
                            wordBreak: 'break-word',
                            font: 'normal normal normal 14px/23px Manrope',
                            color: isDarkMode ? theme.palette.secondary.contrastText : '#676E7B',
                          }}
                        >
                          {activityResponse?.onlineUrl}
                        </Typography>
                      </Stack>
                    </Link>
                  </Button>
                </Stack>
              </Dialog>
              {selectedOrgId && isVolunteerModalOpen && VolunteerModal}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ActivityContribute
        open={isContributeOpen}
        handleClose={handleResourceClose}
        handleData={activityResponse}
        onPostClick={() => onIsSubmitClicked(true)}
      />
      <RecurringRangeModal open={calDialogOpen} onClose={handleCloseModal} handleData={childrens} />
      <PhoneVerification
        handleClose={handlePhoneVerificationClose}
        open={phoneVerificationModal}
        fullScreen={isSmallerScreen}
        phoneInputCompleted={phoneInputCompleted}
        setPhoneInputCompleted={setPhoneInputCompleted}
        otpVerificationCompleted={otpVerificationCompleted}
        setotpVerificationCompleted={setOtpVerificationCompleted}
      />

      <AllContributionsListModal open={viewContributions} handleClose={handleContributionsViewModalClose} activity={activityResponse} />
    </div>
  );
};
export default ActivityReccuringChatSideBar;
