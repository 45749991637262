import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme, useMediaQuery, useTheme } from '@mui/material';
import { a11yProps } from 'app/utils/common-utils/common-utils';
import CustomTabPanel from 'app/components/common/tabs/custom-tab-panel';
import SettingsAccount from './settings-account/settings-account';
import './settings-panels.scss';
import SettingsProfile from './settings-profile/settings-profile';
import SettingsOrganisation from './settings-organization/settings-organisation';
import { useLocation, useNavigate } from 'react-router-dom';
import SettingsPrivacy from './settings-privacy/settings-privacy';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { fetchUserGrantedOrganizations, fetchUserOrganizations } from 'app/shared/reducers/organization';
import SettingsPaymentistory from './settings-payments/settings-payment-history';
import SettingsNotifications from './settings-notifications/setting-notification';

const SettingsPanel = () => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get('type');
  const navigate = useNavigate();
  const myProfileId = useAppSelector(state => state.authentication.account.id);

  useEffect(() => {
    if (type === 'organization') {
      setValue(3);
    }
  }, [type]);

  const getUserGrantedOrganizations = async () => {
    try {
      await dispatch(fetchUserGrantedOrganizations());
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUserGrantedOrganizations();
  }, [dispatch]);

  useEffect(() => {
    const fetchOrganizations = async userId => {
      try {
        await dispatch(fetchUserOrganizations(userId));
      } catch (error) {
        console.log(error);
      }
    };
    if (myProfileId) {
      fetchOrganizations(myProfileId);
    }
  }, [myProfileId]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 0:
        break;
      case 1:
        break;
      case 2:
        break;
      case 3:
        break;
      case 4:
        break;
      case 5:
        break;
      default:
        break;
    }
    setValue(newValue);
  };

  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1030));
  const marginRightVW = isSmallScreen ? 4 : (16 / window.innerWidth) * 100;
  const customTheme = createTheme({
    ...theme,
    palette: {
      primary: {
        main: '#FFCE00',
      },
    },
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            minWidth: isSmallScreen ? 'auto' : '4rem',
            fontSize: isSmallScreen ? '0.675rem' : '0.975rem',
            marginRight: isSmallScreen ? `${marginRightVW}vw` : '',
            padding: isSmallScreen ? '0px' : '0px',
            '&.Mui-selected': {
              color: '#674BFF',
            },
          },
        },
      },
    },
  });

  const tabStyle = {
    textTransform: 'capitalize',
    color: theme.profile.color,
    fontWeight: 600,
    fontSize: '14px',
    minHeight: '50px',
    alignItems: 'flex-start',
  };
  return (
    <Box maxWidth={'100%'}>
      <ThemeProvider theme={customTheme}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="settings tabs"
          textColor="primary"
          indicatorColor="primary"
          variant="scrollable"
          // scrollButtons={true}
          // allowScrollButtonsMobile={true}
          sx={{
            '.MuiTabs-flexContainer': {
              gap: '16px',
            },
            '.MuiTabs-scrollButtons': {
              display: 'none',
            },
          }}
        >
          <Tab
            disableRipple
            label="Account"
            {...a11yProps(0, 'settings')}
            sx={{ ...tabStyle, color: isDarkMode ? '#757575' : '', alignItems: 'center' }}
          />
          <Tab
            disableRipple
            label="Profile"
            {...a11yProps(1, 'settings')}
            sx={{ ...tabStyle, color: isDarkMode ? '#757575' : '', alignItems: 'center' }}
          />
          <Tab
            disableRipple
            label="Privacy"
            {...a11yProps(2, 'settings')}
            sx={{ ...tabStyle, color: isDarkMode ? '#757575' : '', alignItems: 'center' }}
          />

          <Tab
            disableRipple
            label="Organization"
            {...a11yProps(3, 'settings')}
            sx={{ ...tabStyle, color: isDarkMode ? '#757575' : '', alignItems: 'center' }}
          />
          <Tab
            disableRipple
            label="Notification"
            {...a11yProps(4, 'settings')}
            sx={{ ...tabStyle, color: isDarkMode ? '#757575' : '', alignItems: 'center' }}
          />
          <Tab
            disableRipple
            label="Payments"
            {...a11yProps(5, 'settings')}
            sx={{ ...tabStyle, color: isDarkMode ? '#757575' : '', alignItems: 'center' }}
          />
        </Tabs>
      </ThemeProvider>
      <div
        className="example1"
        style={{
          overflowY: 'scroll',
          height: '63vh',
          overflow: 'auto',
        }}
      >
        <Box sx={{ width: { xs: '100%', md: '100%' } }}>
          <CustomTabPanel value={value} index={0}>
            <SettingsAccount />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <SettingsProfile />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <SettingsPrivacy />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <SettingsOrganisation />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            <SettingsNotifications />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={5}>
            <SettingsPaymentistory />
          </CustomTabPanel>
        </Box>
      </div>
    </Box>
  );
};

export default SettingsPanel;
