import { initializeApp } from 'firebase/app';
import { getToken, getMessaging, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyBKA3beA6m0EVHP9xskAcfCpCGWDGRZt6E',
  authDomain: 'reachamy-269ac.firebaseapp.com',
  projectId: 'reachamy-269ac',
  storageBucket: 'reachamy-269ac.appspot.com',
  messagingSenderId: '27596888111',
  appId: '1:27596888111:web:75c2f2e7fde07ed82d8eda',
  measurementId: 'G-0CLTTBY4XJ',
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const getOrRegisterServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    return navigator.serviceWorker.getRegistration('/content/firebase-push-notification-scope').then(serviceWorker => {
      if (serviceWorker) return serviceWorker;
      return navigator.serviceWorker.register('/content/firebase-messaging-sw.js', {
        scope: '/content/firebase-push-notification-scope',
      });
    });
  }
  throw new Error("The browser doesn't support service worker.");
};

const getFirebaseToken = () =>
  getOrRegisterServiceWorker().then(serviceWorkerRegistration =>
    getToken(messaging, {
      vapidKey: 'BJwwbJafS9ilwlJILin3v_ATFOqd4cIR-eoX5fmloZYjrksoLeZOJxEJsAQ1r00TKVxUxI452d3M7Ym931cyRrU',
      serviceWorkerRegistration,
    })
  );

const onForegroundMessage = () =>
  new Promise(resolve =>
    onMessage(messaging, payload => {
      console.log('to check', payload);
      localStorage.setItem('ForegroundChat', JSON.stringify(payload));
      resolve(payload);
    })
  );

export const onMessageListener = () =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      resolve(payload);
    });
  });

export { getFirebaseToken, onForegroundMessage };
