// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-156xqnd-MuiInputBase-input,
.css-yz9k0d-MuiInputBase-input {
  font-size: 0.75rem !important;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/ui/settings/settings-body/settings-panels/settings-account/settings-change-location.scss"],"names":[],"mappings":"AAAA;;EAEI,6BAAA;AACJ","sourcesContent":[".css-156xqnd-MuiInputBase-input,\n.css-yz9k0d-MuiInputBase-input {\n    font-size: .75rem !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
