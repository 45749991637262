import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  InputAdornment,
  Stack,
  Typography,
  TextField,
  Button,
  Grid,
  useTheme,
  IconButton,
  Tooltip,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import TwitterIcon from 'app/components/icons/login-page-icons/twitter-icon';
import FacebookIcon from 'app/components/icons/login-page-icons/facebook-icon';
import LinkedInIcon from 'app/components/icons/login-page-icons/linkedin-icon';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Chip from '@mui/material/Chip';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { shareActivity, shareOrganization, sharePetition } from 'app/shared/reducers/send-link-to-emails';
import { extractIdFromUrlName, isValidEmail, shareOnSocialMedia } from 'app/utils/common-utils/common-utils';
import './post-success-modal.scss';
import { useLocation } from 'react-router-dom';
interface PostSuccessModalProps {
  fullScreen: boolean;
  handleModalClose: () => void;
  responseUrl?: string;
  pdfUrl?: string;
  open: boolean;
  onClose: () => void;
  message?: string;
}

const PostSuccessModal = ({ fullScreen, handleModalClose, responseUrl, pdfUrl, open, onClose, message }: PostSuccessModalProps) => {
  const [emailGroup, setEmailGroup] = useState('');
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [personName, setPersonName] = React.useState<string[]>([]);
  const [inputValue, setInputValue] = React.useState<string>('');
  const [invalidEmail, setInvalidEmail] = React.useState<boolean>(false);
  const isEmailsSend = useAppSelector(state => state.shareViaMail.success);
  const isEmailsSendSuccessMessage = useAppSelector(state => state.shareViaMail.successMessage);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const userState = useAppSelector(state => state.authentication.account);
  const [email, setEmail] = useState('');
  const isOwnMail = (userState?.email || userState?.login) === email;
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const handleSnackClick = () => {
    setOpenSnackBar(true);
  };

  const handleCopyButton = data => {
    navigator.clipboard.writeText(data);
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 1500);
  };
  const handleCloseSnackBar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };
  const handleEmailGroupChange = event => {
    setEmailGroup(event.target.value);
  };
  const handleActionClick = () => {
    const activityId = extractIdFromUrlName(responseUrl).toString();
    const sharePayload = { activityId, url: responseUrl, emailAddresses: personName };
    if (activityId && responseUrl && personName.length > 0) {
      dispatch(shareActivity(sharePayload));
      setShowButton(true);
    }
  };
  const handleActionPetitionClick = () => {
    const petitionId = extractIdFromUrlName(responseUrl).toString();
    const sharePayload = { petitionId, url: responseUrl, emailAddresses: personName };
    if (petitionId && responseUrl && personName.length > 0) {
      dispatch(sharePetition(sharePayload));
      setShowButton(true);
    }
  };

  const handleActionOrganizationClick = () => {
    const check = extractIdFromUrlName(responseUrl).toString();
    const numericPart = check.match(/\d+/);
    const organizationId = numericPart ? numericPart[0] : null;

    const sharePayload = { organizationId, url: responseUrl, emailAddresses: personName, user: 'organization' };
    if (organizationId && responseUrl && personName.length > 0) {
      dispatch(shareOrganization(sharePayload));
      setShowButton(true);
    }
  };

  const getStyles = (name: string) => {
    return {
      fontWeight: personName.includes(name) ? theme.typography.fontWeightMedium : theme.typography.fontWeightRegular,
      backgroundColor: theme.palette.mode === 'dark' ? 'primary.main' : '#F6F7F8',
    };
  };
  const handleDelete = (nameToDelete: string) => {
    setEmail('');
    const updatedNames = personName.filter(name => name !== nameToDelete);
    setPersonName(updatedNames);
  };

  const selectedChips = personName.map(name => (
    <Grid item key={name} xs={12} lg={12}>
      <Chip label={name} onDelete={() => handleDelete(name)} style={getStyles(name)} />
    </Grid>
  ));

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setInvalidEmail(false);
    const cleanedValue = event.target.value.replace(/,/g, '');
    setInputValue(cleanedValue);
    const emails = inputValue.split(',');
    const isValid = emails.every(email => isValidEmail(email));
    if (cleanedValue.trim() !== '') {
      const emails = cleanedValue.split(',');
      const isValid = emails.every(email => isValidEmail(email));
      // if (isValid) {
      //   setInvalidEmail(false);
      // } else {
      //   setInvalidEmail(true);
      // }
    } else {
      setInvalidEmail(false);
    }
  };
  const { pathname } = useLocation();
  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if ((event.key === 'Enter' || event.key === ' ' || event.key === ',') && inputValue.trim() !== '') {
      // setEmail('')
      if (isValidEmail(inputValue.trim())) {
        if (message === 'Your organization has been created succesfully') {
          setEmail(inputValue.trim());
          if (!isOwnMail) {
            const updatedNames = [...personName, inputValue.trim()];

            setPersonName(updatedNames);
            setInputValue('');
            setInvalidEmail(false);
          }
        } else {
          const updatedNames = [...personName, inputValue.trim()];

          setPersonName(updatedNames);
          setInputValue('');
          setInvalidEmail(false);
        }
      } else {
        setInvalidEmail(true);
      }
    }
  };
  const showSuccessMessageFor5Secs = () => {
    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 4000);
  };
  useEffect(() => {
    if (isEmailsSend) {
      showSuccessMessageFor5Secs();
    }
  }, [isEmailsSend]);
  const action = (
    <>
      <Button size="small" onClick={handleCloseSnackBar}>
        Undo
      </Button>
      <IconButton size="small" aria-label="close" onClick={handleCloseSnackBar}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );
  const messgeToShare = 'Reach out to Reach Amy-';
  const handleDownloadPDF = () => {
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = 'downloaded_file.pdf';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleModalClose}
      scroll="body"
      PaperProps={{
        sx: {
          borderRadius: { xs: 0, sm: '18px' },
          boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
          width: { lg: pathname === '/create-a-blog' ? 600 : 600 },
        },
      }}
    >
      <DialogContent sx={{ px: 2 }}>
        <Stack direction="column" gap={1}>
          <Stack direction="row" alignItems={'center'} justifyContent={'flex-end'}>
            <CloseIcon onClick={handleModalClose} sx={{ cursor: 'pointer' }} />
          </Stack>
          <Stack direction="column" gap={1.7}>
            <Stack direction="column" gap={3} alignItems="center" justifyContent="center" sx={{ px: { lg: 10 } }}>
              {message && (
                <Typography
                  variant="h5"
                  sx={{ color: 'secondary.main', fontWeight: 600, textAlign: 'center', px: { lg: 3 }, fontSize: '15px' }}
                >
                  {message}
                </Typography>
              )}
              <CheckCircleOutlineRoundedIcon sx={{ fontSize: '45px', color: 'primary.light' }} />
              {pdfUrl && (
                <p onClick={handleDownloadPDF} style={{ textDecoration: 'none', cursor: 'pointer', color: '#674BFF' }}>
                  Download Poster
                </p>
              )}
            </Stack>
            <Stack direction="column" gap={2.4} px={2}>
              <Typography className="mx-1" sx={{ fontWeight: 600, fontSize: '14px' }}>
                Share this
              </Typography>
              <Stack className="mx-1" direction="row" alignItems={'center'} gap={1}>
                <TwitterIcon
                  sx={{ fontSize: '30px', cursor: 'pointer', marginRight: '10px' }}
                  onClick={() => shareOnSocialMedia('twitter', responseUrl, messgeToShare)}
                />
                <FacebookIcon
                  sx={{ fontSize: '30px', cursor: 'pointer', marginRight: '10px' }}
                  onClick={() => shareOnSocialMedia('facebook', responseUrl, messgeToShare)}
                />
                <LinkedInIcon
                  sx={{ fontSize: '30px', cursor: 'pointer' }}
                  onClick={() => shareOnSocialMedia('linkedIn', responseUrl, messgeToShare)}
                />
              </Stack>
              {responseUrl && (
                <>
                  <Typography className="mx-1" sx={{ fontWeight: 600, fontSize: '12px', height: 15 }}>
                    Or copy link
                  </Typography>
                  <Stack>
                    <TextField
                      size="small"
                      disabled
                      value={responseUrl}
                      InputProps={{
                        style: { backgroundColor: 'primary.main', fontSize: '14px', paddingLeft: '10px' },
                        endAdornment: (
                          <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                            <Tooltip open={showTooltip} title="Copied!" placement="top">
                              <span onClick={() => handleCopyButton(responseUrl)}>
                                <ContentCopyIcon />
                              </span>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                </>
              )}
              {pathname !== '/create-a-blog' && (
                <Stack direction="column" gap={1}>
                  <Typography className="mx-1" sx={{ fontWeight: 600, fontSize: '12px' }}>
                    Invite your friends through email.
                  </Typography>

                  {personName.length === 5 && (
                    <Typography color="error" variant="caption">
                      You have reached max invites
                    </Typography>
                  )}
                  <div style={{ position: 'relative' }}>
                    <div
                      className=" mx-1 "
                      style={{ border: '1px solid #bfbfbf', borderRadius: '15px', height: '80px', overflowY: 'scroll' }}
                    >
                      {selectedChips.map((chip, index) => (
                        <div style={{ display: 'inline-block' }}>
                          <Grid key={index} sx={{ p: 1 }}>
                            {chip}
                          </Grid>
                        </div>
                      ))}

                      <div style={{ display: 'inline-block' }}>
                        <div style={{ display: 'flex' }}>
                          {personName.length >= 0 && personName.length < 5 ? (
                            <TextField
                              id="Chip"
                              fullWidth
                              size="small"
                              disabled={personName.length === 5 || showButton || isOwnMail}
                              value={inputValue}
                              onChange={handleInputChange}
                              onKeyDown={handleInputKeyDown}
                              placeholder="Enter emails"
                              className="no-outline"
                              InputProps={{
                                style: {
                                  backgroundColor: 'primary.main',
                                  fontSize: '14px',
                                  padding: 0.5,
                                  width: `${Math.max(inputValue.length * 10, 200)}px`,
                                },
                              }}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>

                      {/* <div className=" w-75 p-0" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {selectedChips.map((chip, index) => (
                          <Grid key={index} sx={{ p: 1 }}>
                            {chip}
                          </Grid>
                        ))}
                      </div>
                      <div >
                        {personName.length >= 0 && personName.length < 5 ? (
                          <TextField
                            id="Chip"
                            fullWidth
                            size="small"
                            disabled={personName.length === 5}
                            value={inputValue}
                            onChange={handleInputChange}
                            onKeyDown={handleInputKeyDown}
                            placeholder="Enter emails"
                            className="no-outline"
                            InputProps={{
                              style: { backgroundColor: 'primary.main', fontSize: '14px', padding: 0.5 },
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>

                    <div className="w-25 d-flex flex-column p-0 justify-content-end align-items-center">
                      {message === 'Your petition was created successfully' ? (
                        <Button variant="text" sx={{ color: 'primary.light' }} onClick={handleActionPetitionClick}>
                          Send
                        </Button>
                      ) : message === 'Your petition was edited successfully' ? (
                        <Button variant="text" sx={{ color: 'primary.light' }} onClick={handleActionPetitionClick}>
                          Send
                        </Button>
                      ) : (
                        <Button variant="text" sx={{ color: 'primary.light' }} onClick={handleActionClick}>
                          Send
                        </Button>
                      )}
                    </div> */}
                    </div>
                  </div>
                  {personName.length >= 1 && (
                    <div style={{ position: 'inherit', right: '0px', bottom: '0px' }}>
                      <div className="d-flex justify-content-end">
                        {message === 'The petition has been posted successfully' ? (
                          <Button
                            variant="text"
                            sx={{
                              color: 'primary.light',
                              '&:hover': {
                                backgroundColor: 'transparent',
                              },
                              '&:active': {
                                backgroundColor: 'transparent',
                              },
                              borderRadius: '30px',
                            }}
                            onClick={handleActionPetitionClick}
                            disabled={showButton || invalidEmail || personName.length === 0}
                          >
                            Send
                          </Button>
                        ) : message === 'Your petition was edited successfully' ? (
                          <Button
                            variant="text"
                            sx={{
                              color: 'primary.light',
                              '&:hover': {
                                backgroundColor: 'transparent',
                              },
                              '&:active': {
                                backgroundColor: 'transparent',
                              },
                              borderRadius: '30px',
                            }}
                            onClick={handleActionPetitionClick}
                            disabled={showButton || invalidEmail || personName.length === 0}
                          >
                            Send
                          </Button>
                        ) : message === 'Your organization has been created succesfully' ? (
                          <Button
                            variant="text"
                            sx={{
                              color: 'primary.light',
                              '&:hover': {
                                backgroundColor: 'transparent',
                              },
                              '&:active': {
                                backgroundColor: 'transparent',
                              },
                              borderRadius: '30px',
                            }}
                            onClick={handleActionOrganizationClick}
                            disabled={showButton || invalidEmail || personName.length === 0 || isOwnMail}
                          >
                            Send
                          </Button>
                        ) : (
                          <Button
                            variant="text"
                            sx={{
                              color: 'primary.light',
                              '&:hover': {
                                backgroundColor: 'transparent',
                              },
                              '&:active': {
                                backgroundColor: 'transparent',
                              },
                              borderRadius: '30px',
                            }}
                            onClick={handleActionClick}
                            disabled={showButton || invalidEmail || personName.length === 0}
                          >
                            Send
                          </Button>
                        )}
                      </div>
                    </div>
                  )}

                  <Grid item xs={12}>
                    <Typography color="error" variant="caption">
                      {invalidEmail && 'Invalid email format'}
                    </Typography>
                    {isOwnMail && (
                      <Typography color="error" variant="caption">
                        Self-Invitations not allowed. Please use a different email address.
                      </Typography>
                    )}
                    {isEmailsSend && showSuccessMessage && (
                      <Typography color="success.main" variant="caption">
                        {'Emails sent successfully'}
                      </Typography>
                    )}
                  </Grid>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default PostSuccessModal;
