import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import DoughnutRound from '../general/doughnut-round';
import { convertDateToDesiredFormat } from 'app/utils/common-utils/common-utils';
import countryCodes from 'app/utils/data/json/country-currency-code.json';

type Props = {
  name: string;
  achieved: number;
  target: number;
  startDate?: string;
  endDate?: string;
  currencyCode?: string;
};

interface CountryCode {
  name: string;
  symbol: string;
  countryCode: string;
  code: string;
  symbol_native: string;
}

export default function DoughnutCard({ name, achieved, target, startDate, endDate, currencyCode }: Props) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const getCurrencySymbol = (currencyCode: string | undefined): string => {
    if (!currencyCode) return '';

    // Check if countryCodes is an array
    if (!Array.isArray(countryCodes)) {
      console.error('countryCodes is not an array:', countryCodes);
      return '';
    }

    const country = countryCodes.find((country: CountryCode) => country.code === currencyCode);
    return country ? country.symbol : '';
  };

  const currencySymbol = getCurrencySymbol(currencyCode);

  return (
    <Box
      p={1.7}
      flex="1"
      bgcolor={isDarkMode ? '#2c2c38' : '#fff'}
      borderRadius={2}
      overflow="hidden"
      borderBottom={2.5}
      borderColor="#8873fc"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box>
        <Typography fontSize="0.9rem" fontWeight="bold">
          {name}
        </Typography>
        {startDate && endDate ? (
          <Typography variant="body2" fontWeight="600" color="#798498" fontSize="0.7rem" pb={1}>
            {convertDateToDesiredFormat(startDate)} - {convertDateToDesiredFormat(endDate)}
          </Typography>
        ) : (
          <Typography variant="body2" color="#798498" fontSize="0.8rem">
            Goals
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection="column">
        <DoughnutRound name={name} achieved={achieved} target={target} />
        <Box display="flex" alignItems="flex-end" alignSelf="center">
          <Typography textAlign="center" variant="h5" component="h5" fontWeight="bold">
            {currencySymbol ? `${currencySymbol}` : ''}
            {`${achieved} `}
          </Typography>
          <Typography textAlign="center" fontSize="0.7rem" variant="body2" color="#798498" fontWeight="600" marginBottom="1px">
            /{target}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
