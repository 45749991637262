import React, { useCallback, useEffect, useRef, useState, useContext } from 'react';
import { Alert, Box, CircularProgress, Skeleton, useMediaQuery } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import { IconButton, Typography, useTheme, ListItemIcon, styled, ListItemButton, Stack, Tooltip } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import SendIcon from '@mui/icons-material/Send';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import './chat-card.scss';
import moment from 'moment';
import { addMessage, getPinnedMessage } from 'app/shared/reducers/chat-messages/chat-Panel';
import { deleteMessage } from 'app/shared/reducers/chat-messages/chat-delete';
import ChatOptions from './chat-options';
import toast from 'react-hot-toast';
import { getAllConversationsById } from 'app/shared/reducers/chat-messages/chat-Panel';
import { setConversations } from 'app/shared/reducers/chat-messages/chat-Panel';
import { sentMessage } from 'app/shared/reducers/chat-messages/chat-post';
import ChatPinIcon from 'app/components/icons/chat-action/pin-icon';
import ChatPinDarkIcon from 'app/components/icons/chat-action/dark-pin';
import copy from 'clipboard-copy';
import { fetchActivityDetails, fetchActivityDetailsUnauthorized, fetchPetitionDetails } from 'app/shared/reducers/activity-home';
import { getReportingReasons } from 'app/shared/reducers/activity/activity-report';
import { onForegroundMessage } from 'app/firebase';
import { deleteChatResponse, getAllEvents, getAllEventsById, updateChatResponse } from 'app/shared/reducers/chat-messages/left-panel';
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from './replay-icon';
import ChatReaction from './modals/chat-reaction-emoji';
import OrganiserCrownIcon from 'app/components/icons/activity-card/organiser-crown-icon';
import ModeratorStarIcon from 'app/components/icons/activity-card/moderator-star-icon';
import ChatMediaOption from './modals/chat-media-option';
import axios from 'axios';
import { apiEndPoints } from 'app/utils/data/constants/api-end-points';
import ReactPlayer from 'react-player';
import ChatReactionMessage from './modals/chat-message-emoji';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckIcon from '@mui/icons-material/Check';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import WebSocketContext, { WebSocketProvider } from './WebSocketContext';
import chatEdit, { getChatEdited } from 'app/shared/reducers/chat-messages/chat-edit';
import { getInitials } from 'app/utils/common-utils/common-utils';
import './chat-feed.scss';

interface ChatFeedProps {
  openPinnedData: (data: any) => void;
  reportBoxOpen: (data: any) => void;
  editBoxOpen: (data: any) => void;
  activityData: any;
  volunteerButtonClick?: boolean;
  editPass?: any;
  signPetiton?: any;
  changeChat?: any;
}

const ChatFeed: React.FC<ChatFeedProps> = ({
  openPinnedData,
  reportBoxOpen,
  editBoxOpen,
  activityData,
  volunteerButtonClick,
  editPass,
  signPetiton,
  changeChat,
}) => {
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(true);
  const [isPinnedCommentDialogOpen, setIsPinnedCommentDialogOpen] = useState(false);
  const [chatData, setChatData] = useState([]);
  const [pinnedData, setPinnedData] = useState(null);
  const [sendPinnedData, setSendPinnedData] = useState(null);
  const [isAnyPinned, setIsAnyPinned] = useState(false);
  const [activityDetails, setActivityDetails] = useState(activityData);
  const [olderItem, setOlderItem] = useState(null);
  const [yesterdayItem, setYesterdayItem] = useState(null);
  const [todayItem, setTodayItem] = useState(null);
  const [unReadItem, setUnReadItem] = useState(null);
  const [message, setMessage] = useState('');
  const [isSendIconEnabled, setIsSendIconEnabled] = useState(false);
  const [activity, setActivity] = useState({});
  const [chat, setChat] = useState(null);
  const [petition, setPetition] = useState({});
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [reply, setReply] = useState(false);
  const [replyData, setReplyData] = useState(null);
  const [reacted, setReacted] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState('');
  const [isImageUploaderOpen, setImageUploaderOpen] = useState(false);
  const [anchorEle, setAnchorEle] = useState(null);
  const [selectedAction, setSelectedAction] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [files, setFiles] = useState<File>();
  const [mediaSelected, setMediaSelected] = useState(null);
  const [mediaType, setMediaType] = useState(null);
  const [replybool, setReplybool] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(575));
  const yesterdayLabel = 'YESTERDAY';
  const todayLabel = 'TODAY';
  const unreadLabel = 'UNREAD MESSAGES';
  const messageContainerRef = useRef(null);

  const getAllConversation = useAppSelector(state => state.chatPanelReducer.chatResponse);
  const pinConversations = useAppSelector(state => state.chatPinReducer.chatResponse);
  const getAllChatResponse = useAppSelector(state => state.leftPanelReducer.chatResponse);
  const deleteConversation = useAppSelector(state => state.chatDeleteReducer.chatResponse);
  const userDetails = useAppSelector(state => state.authentication.account);
  const playerStyle = {
    borderRadius: '3%',
  };

  const isInfoPage = location.pathname === '/chat/info';
  const openImageUploader = event => {
    setImageUploaderOpen(true);
    setAnchorEle(event.currentTarget);
  };

  const cloeImageUploader = item => {
    setAnchorEle(null);
    setImageUploaderOpen(false);
    setSelectedAction(item);
    handleMedia(item);
  };

  const handleMedia = item => {
    setSelectedAction('');
    if (item === 'All') {
      const imageInput = document.getElementById('imageInput');
      if (imageInput) {
        imageInput.click();
      }
    }
  };

  const handleFileSelect = e => {
    const fileInput = e.target;
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    const MAX_VIDEO_SIZE_MB = 20;
    const MAX_VIDEO_SIZE_BYTES = MAX_VIDEO_SIZE_MB * 1024 * 1024;

    const acceptedImageTypes = ['image/svg+xml', 'image/png', 'image/jpeg', 'video/mp4', 'video/webm', 'video/ogg'];
    if (file.type.startsWith('video/') && file.size > MAX_VIDEO_SIZE_BYTES) {
      alert(`Video size greater than ${MAX_VIDEO_SIZE_MB}MB is not acceptable.`);
      fileInput.value = '';
      return;
    }

    if (acceptedImageTypes.includes(file.type)) {
      const imageURL = URL.createObjectURL(file);
      setSelectedImage(imageURL);
      setFiles(file);
      setMessage(file?.name);
      inputRef.current.value = file?.name;
      setIsSendIconEnabled(true);
      fileInput.value = '';
    } else {
      alert('Unsupported file format. Please select a valid image file (SVG, PNG, or JPG) or video file (MP4, WebM, Ogg, etc.).');
      fileInput.value = '';
    }
  };

  const thumbnailContainerStyle = {
    position: 'relative',
    width: '150px',
    height: '150px',
  };

  const playButtonStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '8px',
    color: 'white',
    cursor: 'pointer',
  };
  const ImageComponent = ({ additionalData }) => {
    return (
      <Box>
        <img style={{ width: '40%', height: '40%' }} src={additionalData?.link}></img>
      </Box>
    );
  };

  const VideoComponent = ({ additionalData }) => {
    const videoUrl = additionalData?.link;
    return (
      <Box
        sx={{
          position: 'relative',
          width: '150px',
          height: '150px',
          alignContent: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={additionalData?.thumbnail} alt="Video thumbnail"></img>
        <PlayCircleOutlineIcon sx={{ position: 'absolute', color: '#fff' }} onClick={() => window.open(videoUrl, '_blank')} />
      </Box>
    );
  };

  const fetchConversations = async item => {
    const myDetailsAccount = localStorage.getItem('MyDetailsAccount');
    const userId = myDetailsAccount ? JSON.parse(myDetailsAccount)?.id : null;
    const payload = {
      eventId: item?.id ? item?.id : 0,
      eventType: item?.eventType ? item?.eventType : '',
      userId: userId,
    };

    if (payload?.eventId != 0) {
      try {
        const response = await dispatch(getAllConversationsById(payload));
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
      }
    }
  };

  const autoScrollToBottom = () => {
    const messageContainer = document.getElementById('my_div');

    if (messageContainer) {
      const mediaElements = messageContainer.querySelectorAll('img, video');
      const mediaLoadPromises = Array.from(mediaElements).map((mediaElement: HTMLImageElement | HTMLVideoElement) => {
        return new Promise<void>(resolve => {
          if ('complete' in mediaElement && mediaElement.complete) {
            resolve();
          } else {
            mediaElement.addEventListener('load', () => resolve());
            mediaElement.addEventListener('error', () => resolve());
          }
        });
      });

      Promise.all(mediaLoadPromises).then(() => {
        messageContainer.scrollTop = messageContainer.scrollHeight;
      });
    }
  };

  const fetchActivity = async () => {
    let payload = {
      eventId: 0,
      eventType: '',
    };

    if (localStorage.getItem('dataKey')) {
      const keysJSON = await localStorage.getItem('dataKey');
      const keys = JSON.parse(keysJSON);
      payload = {
        eventId: keys.id,
        eventType: keys.eventType?.toUpperCase(),
      };
    }

    try {
      let response;
      if (payload.eventId == 0) {
        response = await dispatch(getAllEvents());
      } else {
        if (payload) response = await dispatch(getAllEventsById(payload));
      }
    } catch (error) {
      console.error('An error occurred while fetching data:', error);
    }
  };

  useEffect(() => {
    if (pinnedData != undefined && pinnedData != null) {
      setReplybool(true);
      autoScrollToBottom();
    } else {
      setReplybool(false);
    }
  }, [pinnedData]);

  useEffect(() => {
    if (localStorage.getItem('chatFeedDetails') != undefined) {
      setActivityDetails(JSON.parse(localStorage.getItem('chatFeedDetails')));
    }
  }, [localStorage.getItem('chatFeedDetails')]);

  useEffect(() => {
    if (reply != undefined && reply != null) {
      autoScrollToBottom();
    } else {
      setReplybool(false);
    }
  }, [reply]);

  useEffect(() => {
    if (signPetiton) {
      fetchConversations(activityData);
    }
  }, [signPetiton]);

  useEffect(() => {
    if (activityData) {
      fetchConversations(activityData);
    }
  }, [activityData, changeChat]);
  const [disableImage, setDisableImage] = useState<any>(false);
  useEffect(() => {
    if (files !== undefined) {
      setDisableImage(true);
      const sendFile = async () => {
        try {
          let endpoint = '';
          const formData = new FormData();

          if (files.type.includes('image')) {
            endpoint = apiEndPoints.imageUpload;
            formData.append('file', files as Blob);
          } else if (files.type.includes('video')) {
            endpoint = apiEndPoints.videoUpload;
            formData.append('file', files as Blob);
          }

          const response = await axios.post<any>(endpoint, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
          if (response.status === 201) {
            if (endpoint.includes('image')) {
              setMediaSelected([{ id: response.data.id }]);
              setMediaType('image');
              setDisableImage(false);
            } else if (endpoint.includes('video')) {
              setMediaSelected([{ id: response.data.id }]);
              setMediaType('video');
              setDisableImage(false);
            }
          }
        } catch (error) {
          console.error(error);
        }
      };
      sendFile();
    }
  }, [files]);

  useEffect(() => {
    const data = getAllChatResponse?.chatDetails?.filter(chatItem => chatItem?.id === activityData?.id);
    if (volunteerButtonClick) {
      fetchEventInfo();
    }
  }, [isSubmitClicked, volunteerButtonClick]);

  const fetchEventInfo = async () => {
    if (activityDetails?.eventType === 'Activity') {
      let response = await dispatch(fetchActivityDetails(activityDetails?.id));
      setActivityDetails(response?.payload);

      if (response.payload) localStorage.setItem('chatFeedDetails', JSON.stringify(response.payload));
    } else if (activityDetails?.eventType === 'Petition') {
      let response = await dispatch(fetchPetitionDetails(activityDetails?.id));
      if (response.payload) localStorage.setItem('chatFeedDetails', JSON.stringify(response.payload));
      setActivityDetails(response?.payload);
    }
  };

  useEffect(() => {
    if (chatData?.length > 0) {
      autoScrollToBottom();
    }
  }, [chatData]);

  useEffect(() => {
    if (Array.isArray(getAllConversation)) {
      const newEntries = getAllConversation?.filter(conv => !chatData?.some(existingConv => existingConv.id === conv.id));
      if (newEntries.length > 0) {
        setChatData([...chatData, ...newEntries]);
      }

      const pinnedMessages = getAllConversation.filter(item => item.pinned === true);
      setPinnedData(pinnedMessages ? pinnedMessages[0] : []);
      setSendPinnedData(pinnedMessages);

      const isAnyPinned = getAllConversation.some(item => item?.pinTop === true);
      setIsAnyPinned(isAnyPinned);

      dateChats(getAllConversation);
    }
    setMessage('');
  }, [getAllConversation]);

  useEffect(() => {
    if (pinConversations) {
      fetchConversations(activityData);
      const pinnedMessages = Array.isArray(pinConversations?.data) ? pinConversations?.data?.filter(item => item.pinned === true) : [];
      setPinnedData(pinnedMessages ? pinnedMessages[0] : []);
      setSendPinnedData(pinnedMessages ? pinnedMessages : []);
      dateChats(getAllConversation);
    }
  }, [pinConversations]);

  const pinMessage = async item => {
    const userid2 = JSON.parse(localStorage?.getItem('MyDetailsAccount'));
    const payload = {
      messageId: item.id,
      payload: {
        userId: userid2?.id,
        eventId: item.eventId,
        eventType: item.eventType,
      },
    };
    try {
      const response = await dispatch(getPinnedMessage(payload));
      if (response?.meta?.requestStatus === 'fulfilled') {
        toast.success(response?.payload?.message);
      } else {
        toast.error('Only Organizers can pin message');
      }
    } catch (error) {
      console.error('An error occurred while fetching data:', error);
    }
  };

  const deleteMessageById = async item => {
    const userid2 = JSON.parse(localStorage?.getItem('MyDetailsAccount'));
    const editPay = {
      userId: userid2?.id,
      eventId: item?.eventId,
      eventType: item?.eventType,
      message: item.message,
      replyToMessageId: item.replyToMessageId,
      multimedia: null,
      recurringActivity: activityData?.recurringEvent ? true : false,
      chatMessageType: 'DELETE',
      messageId: item?.id,
    };
    if (isConnected && webSocket.current) {
      sendMessage(JSON.stringify(editPay));
    }
  };

  const handleCopyMessage = async item => {
    const message = item.message;
    const eventGallery = item.eventGallery;

    try {
      if (eventGallery && eventGallery.length > 0) {
        const imageUrl = eventGallery[0].link;
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const dataUrl = URL.createObjectURL(blob);
        const textarea = document.createElement('textarea');
        textarea.value = dataUrl;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        toast.success('Image Copied to clipboard');
      } else {
        await copy(message);
        toast.success('Message Copied to clipboard');
      }
    } catch (error) {
      toast.error('Copy failed');
    }
  };

  const handleMenuItemClick = (item, message) => {
    if (item === 'pin') {
      setReplybool(!replybool);
      pinMessage(message);
    } else if (item === 'copy') {
      handleCopyMessage(message);
    } else if (item === 'delete') {
      deleteMessageById(message);
    } else if (item === 'report') {
      setChat(message);
      reportBoxOpen(message);
    } else if (item === 'edit') {
      handleEdit(message);
    }
  };

  const handleReply = message => {
    setReply(true);
    setReplybool(true);
    setReplyData(message);
  };

  const handleEmojiSelect = emoji => {
    setSelectedEmoji(emoji);
  };

  const handleEmojiMessageSelect = emoji => {
    setSelectedEmoji(emoji);
    const newText = emoji;
    inputRef.current.value = inputRef.current.value + newText;
    setMessage(inputRef.current.value + newText);
    setIsSendIconEnabled(newText.trim().length > 0);
  };

  const [chatMessages, setMock] = useState([]);
  const { webSocket, isConnected, sendMessage, messageTrigger } = useContext(WebSocketContext);
  const handleTextareaChange = useCallback(event => {
    setMessage(event.target.value);
    const newText = event.target.value;
    setIsSendIconEnabled(newText.trim().length > 0);
  }, []);
  const inputRef = useRef(null);

  const [wholeMessages, setWholeMessages] = useState<any>([]);
  useEffect(() => {
    if (isConnected) {
      console.log('WebSocket is connected and activityDetails are available.');
      const handleWebSocketMessage = (event: MessageEvent) => {
        try {
          const parsedBody = JSON.parse(event.data);
          if (parsedBody && parsedBody.message) {
            console.log('Received WebSocket message:', parsedBody);
            const lastMessage = parsedBody;

            // Get userId from local storage
            const myDetailsAccount = localStorage.getItem('MyDetailsAccount');
            const userId = myDetailsAccount ? JSON.parse(myDetailsAccount)?.id : null;

            // If the message is from another user and relates to the current activity
            if (lastMessage?.userId !== userId && lastMessage?.eventId === activityData?.id) {
              const readPayload = {
                eventType: lastMessage?.eventType,
                eventId: lastMessage?.eventId,
                userId,
                chatMessageType: 'READ',
                messageId: lastMessage?.id,
              };

              if (isConnected && webSocket.current) {
                sendMessage(JSON.stringify(readPayload));
              }
            }

            if (lastMessage?.edited && !lastMessage?.deleted && lastMessage?.chatReactions?.length === 0) {
              const updatedConversations = getAllConversation.map(item => (item.id === lastMessage?.id ? lastMessage : item));
              if (updatedConversations) {
                setChatData(updatedConversations);
                fetchConversations(updatedConversations);
                dateChats(updatedConversations);
                dispatch(setConversations(updatedConversations));
              }
            } else if (lastMessage?.chatReactions?.length > 0 && !lastMessage?.deleted) {
              const updatedConversations = getAllConversation.map(item => (item.id === lastMessage?.id ? lastMessage : item));
              if (updatedConversations) {
                setChatData(updatedConversations);
                fetchConversations(updatedConversations);
                dateChats(updatedConversations);
                dispatch(setConversations(updatedConversations));
              }
            } else if (lastMessage?.deleted) {
              dispatch(deleteChatResponse(lastMessage));
              const filteredConversations = getAllConversation.filter(conversation => conversation.id !== lastMessage?.id);
              if (filteredConversations) {
                setChatData(filteredConversations);
                fetchConversations(filteredConversations);
                dateChats(filteredConversations);
                dispatch(setConversations(filteredConversations));
              }
              console.log('delete', lastMessage);
            } else if (lastMessage.eventId === activityData?.id) {
              dispatch(addMessage(lastMessage));
            } else {
              dispatch(updateChatResponse(lastMessage));
            }
          } else {
            console.warn('Received message does not contain expected structure:', parsedBody);
          }
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
        }
      };

      if (webSocket.current) {
        console.log('check log');
        webSocket.current.onmessage = handleWebSocketMessage;
      }

      return () => {
        if (webSocket.current) {
          webSocket.current.onmessage = null;
        }
      };
    } else {
      fetchConversations(activityData);
      console.log('WebSocket is not connected or activityDetails are missing.');
    }
  }, [isConnected, webSocket, getAllConversation, activityData]);

  const handleReactClose = (data: any) => {
    const userid2 = JSON.parse(localStorage?.getItem('MyDetailsAccount'));
    const editPay = {
      userId: userid2?.id,
      eventId: data?.payload?.eventId,
      eventType: data?.payload?.eventType,
      message: null,
      replyToMessageId: null,
      multimedia: null,
      recurringActivity: activityData?.recurringEvent ? true : false,
      chatMessageType: 'REACT',
      messageId: data?.messageId,
      reactType: data?.payload?.reactType,
    };
    if (isConnected && webSocket.current) {
      sendMessage(JSON.stringify(editPay));
    }
    setReacted(false);
    setReplybool(false);
  };

  const handleSendIconClick = reply => {
    const userid2 = JSON.parse(localStorage?.getItem('MyDetailsAccount'));
    console.log('check activity data', activityData);
    const payload = {
      userId: userid2?.id,
      eventId: activityData?.id,
      eventType: activityData?.eventType,
      message: inputRef.current.value,
      replyToMessageId: reply ? replyData?.id : 0,
      multimedia: mediaSelected ? mediaSelected : [],
      recurringActivity: activityData?.recurringEvent ? true : false,
      chatMessageType: null,
    };
    if (isConnected && webSocket.current) {
      sendMessage(JSON.stringify(payload));
    }

    setMessage('');
    setMediaSelected(null);
    setIsSendIconEnabled(false);
    setReply(false);
    setReplybool(false);
    setReplyData('');

    inputRef.current.value = '';
  };

  useEffect(() => {
    if (editPass !== undefined && isConnected && webSocket.current) {
      const handleEditPass = async () => {
        if (editPass !== undefined && isConnected && webSocket.current) {
          const myDetailsAccount = localStorage.getItem('MyDetailsAccount');
          const userid2 = myDetailsAccount ? JSON.parse(myDetailsAccount) : null;
          const editPay = {
            userId: userid2?.id,
            eventId: editPass?.eventId,
            eventType: editPass?.eventType,
            message: editPass?.message,
            replyToMessageId: null,
            multimedia: null,
            recurringActivity: activityData?.recurringEvent ? true : false,
            chatMessageType: 'EDIT',
            messageId: editPass?.messageId,
          };
          if (isConnected && webSocket.current) {
            sendMessage(JSON.stringify(editPay));
          }
        }
      };
      handleEditPass();
    }
  }, [editPass, isConnected, webSocket]);

  const postMessage = async item => {
    try {
      const response = await dispatch(sentMessage(item));
      if (response.meta.requestStatus === 'fulfilled') {
      } else {
        toast.error('Error , Message not sent');
      }
    } catch (error) {
      console.error('An error occurred while fetching data:', error);
    }
  };

  const dateChats = DATA => {
    if (!Array.isArray(DATA)) {
      return;
    }

    const formatTimestamp = timestamp => moment(timestamp).utc().local().format('YYYY-MM-DD HH:mm:ss');

    const today = formatTimestamp(moment());
    const yesterday = formatTimestamp(moment().subtract(1, 'day'));

    const todayItems = DATA.filter(item => {
      const itemTimestamp = formatTimestamp(item.chatPostedOn);
      return moment(itemTimestamp).isSameOrAfter(today, 'day');
    });

    const yesterdayItems = DATA.filter(item => {
      const itemTimestamp = formatTimestamp(item.chatPostedOn);
      return moment(itemTimestamp).isSame(yesterday, 'day');
    });

    const olderItems = DATA.filter(item => {
      const itemTimestamp = formatTimestamp(item.chatPostedOn);
      return !moment(itemTimestamp).isSame(yesterday, 'day') && !moment(itemTimestamp).isSameOrAfter(today, 'day');
    });

    const unReadItems = [];
    for (let i = todayItems.length - 1; i >= 0; i--) {
      const item = todayItems[i];
      if (item.id >= activityData?.lastMessageId) {
        unReadItems.unshift(item);
        todayItems.splice(i, 1);
      }
    }

    setUnReadItem(unReadItems);
    setTodayItem(todayItems);
    setYesterdayItem(yesterdayItems);
    setOlderItem(olderItems);
  };

  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isDarkMode = theme.palette.mode === 'dark';
  const navigate = useNavigate();

  const handlePinnedCommentClick = () => {
    setIsPinnedCommentDialogOpen(true);
    openPinnedData(sendPinnedData);
  };

  const goToProfilePage = id => {
    navigate(`/profile/${id}`);
  };

  const closeReply = () => {
    setReply(false);
    setReplybool(false);
  };

  const handleEdit = comment => {
    editBoxOpen(comment);
  };

  const navigateToEvent = event => {
    if (event?.eventType === 'ACTIVITY') {
      if (event?.recurringEvent) {
        navigate(`/recurringactivity/${event?.id}`);
      } else {
        navigate(`/activity/${event?.id}`);
      }
    } else if (event?.eventType === 'PETITION') {
      navigate(`/petition/${event?.id}`);
    }
  };

  const navigateToInfo = event => {
    isMobile ? navigate('info') : navigateToEvent(event);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      const reply = inputRef.current.value;
      handleSendIconClick(reply);
    }
  };

  return (
    <List
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        width: '100%',
        maxWidth: '100%',
        backgroundColor: 'secondary.dark',
        paddingTop: '0',
        borderRadius: '15px 15px 15px 15px',
      }}
    >
      <div>
        <Box onClick={() => navigateToInfo(activityData)}>
          <ListItemButton
            alignItems="center"
            sx={{
              backgroundColor: isDarkMode ? 'secondary.dark' : '#FAF9FF',
              display: 'flex',
              borderRadius: '15px 15px 0px 0px',
              transition: 'background-color 0s ease',
            }}
          >
            <Box display="flex" alignItems="center">
              <ListItemAvatar>
                <Avatar
                  sx={{ borderRadius: '14px' }}
                  alt={pinnedData?.name}
                  src={
                    activityData?.eventProfileLink ? activityData?.eventProfileLink : getAllChatResponse?.chatDetails[0]?.eventProfileLink
                  }
                />
              </ListItemAvatar>
            </Box>
            <Box fontSize={'16px'} fontWeight={600} fontFamily={'Manrope'}>
              {activityData?.title ? activityData?.title : getAllChatResponse?.chatDetails[0]?.title}
            </Box>
          </ListItemButton>
        </Box>
        <div
          className={`toScroll ${replybool ? `chat-scroll-container-reply` : `chat-scroll-container`}`}
          id="my_div"
          ref={messageContainerRef}
          style={{ overflow: 'hidden scroll' }}
        >
          {pinnedData ? (
            <ListItemButton
              sx={{ backgroundColor: !isDarkMode ? '#F6F7F8' : '#6f6f6f', display: 'Block' }}
              onClick={handlePinnedCommentClick}
            >
              <Stack>
                <ListItemIcon sx={{ color: isDarkMode ? '#b58f28' : '', marginLeft: '55px' }}>
                  <Badge
                    color="primary"
                    variant="dot"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    sx={{
                      '& .MuiBadge-badge': {
                        bottom: '10px',
                        right: '8px',
                        backgroundColor: 'primary.light',
                      },
                    }}
                  >
                    {!isDarkMode ? <ChatPinIcon /> : <ChatPinDarkIcon />}
                  </Badge>
                </ListItemIcon>
              </Stack>
              <Box display="-webkit-inline-box;" flexDirection="column" alignItems="center">
                <ListItemAvatar>
                  <Avatar alt={`${pinnedData?.profileImage}` ? '' : getInitials(pinnedData?.name)} src={pinnedData?.profileImage}>
                    {' '}
                    {getInitials(pinnedData?.name)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <Typography
                        sx={{
                          display: 'inline',
                          fontWeight: '600',
                          color: pinnedData?.userId === userDetails?.id ? 'primary.light' : 'inherit',
                        }}
                        component="span"
                        variant="body2"
                        color={isDarkMode ? '#ffce00' : 'text.primary'}
                      >
                        {pinnedData?.userId == userDetails?.id ? 'You' : pinnedData?.name} &nbsp;
                      </Typography>
                      {moment(pinnedData?.chatPostedOn).fromNow()}
                    </React.Fragment>
                  }
                  secondary={pinnedData?.message.length > 50 ? pinnedData?.message?.slice(0, 50) + '...' : null}
                />
              </Box>
            </ListItemButton>
          ) : (
            ''
          )}
          <div>
            {Array.isArray(getAllConversation) && getAllConversation.length > 0 ? (
              <>
                {Array.isArray(olderItem) && olderItem?.length > 0 && (
                  <>
                    {olderItem?.map((message, index) => (
                      <ListItemButton className="d-flex  bd-highlight" alignItems="flex-start" key={index}>
                        {message?.messageType !== 'CHAT' ? (
                          <Alert severity="success" sx={{ minWidth: '100%' }}>
                            {message?.message}
                          </Alert>
                        ) : (
                          <>
                            <ListItemAvatar className="bd-highlight" onClick={() => goToProfilePage(message?.userId)}>
                              <Badge
                                overlap="circular"
                                onClick={() => navigate(`/profile/${message?.userId}`)}
                                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                badgeContent={
                                  message?.organizer ? (
                                    <OrganiserCrownIcon sx={{ height: 'unset', width: 'unset', rotate: '-51deg' }} />
                                  ) : message?.moderator ? (
                                    <ModeratorStarIcon sx={{ height: 'unset', width: 'unset', rotate: '-51deg' }} />
                                  ) : null
                                }
                              >
                                <Avatar
                                  alt={`${message?.name}` ? '' : getInitials(message?.name)}
                                  src={message?.profileImage}
                                  sx={{
                                    fontSize: message?.name ? 'default' : '12px',
                                  }}
                                >
                                  {' '}
                                  {getInitials(message?.name)}
                                </Avatar>
                              </Badge>
                            </ListItemAvatar>
                            <Box className="bd-highlight">
                              {message?.replyToMessageId !== 0 && message?.replyToMessageId !== null && (
                                <>
                                  <Box className={isDarkMode ? `chat-container-dark` : `chat-container`}>
                                    <div className={isDarkMode ? 'reply-section' : 'reply-section'}>
                                      <div className="reply-info">
                                        <p
                                          className="reply-user"
                                          style={{
                                            color:
                                              message?.replyToMessageUserId === userDetails?.id
                                                ? isDarkMode
                                                  ? '#ffce00'
                                                  : 'primary.light'
                                                : 'inherit',
                                          }}
                                        >
                                          {message?.replyToMessageUserId === userDetails?.id
                                            ? message?.replyToMessageUser
                                            : message?.replyToMessageUser}
                                        </p>
                                        <span className="reply-text">{message?.replyToMessage}</span>
                                      </div>
                                    </div>
                                  </Box>
                                </>
                              )}
                              <ListItemText
                                primary={
                                  <React.Fragment>
                                    <Typography
                                      sx={{
                                        display: 'inline',
                                        fontWeight: '600',
                                        color:
                                          message?.replyToMessageUserId === userDetails?.id
                                            ? isDarkMode
                                              ? '#ffce00'
                                              : 'primary.light'
                                            : 'inherit',
                                      }}
                                      component="span"
                                      variant="body2"
                                      color={isDarkMode ? '#ffce00' : 'text.primary'}
                                    >
                                      {message?.userId === userDetails?.id ? 'You' : message.name} &nbsp;
                                    </Typography>
                                    {moment(message?.chatPostedOn).fromNow()}
                                    <>
                                      {message?.eventGallery?.length > 0 && message?.eventGallery[0]?.type === 'images' && (
                                        <Box>
                                          <ImageComponent additionalData={message?.eventGallery[0]} />
                                        </Box>
                                      )}
                                      {message?.eventGallery?.length > 0 && message?.eventGallery[0]?.type === 'videos' && (
                                        <Box>
                                          <VideoComponent additionalData={message?.eventGallery[0]} />
                                        </Box>
                                      )}
                                    </>
                                  </React.Fragment>
                                }
                                sx={{ wordBreak: 'break-all' }}
                                secondary={message?.message}
                              />
                              <div style={{ display: 'flex', gap: '8px' }}>
                                {message?.chatReactions?.length > 0 &&
                                  message?.chatReactions?.map((reaction, index) => (
                                    <Box
                                      sx={{
                                        width: '40px',
                                        height: '25px',
                                        background: isDarkMode ? '#1f1f26' : '#e0e0e0',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: '#fff',
                                        borderRadius: '10px',
                                        fontSize: ' 16px',
                                        display: 'flex',
                                        cursor: 'pointer',
                                      }}
                                      key={index}
                                    >
                                      <Typography>{reaction?.reaction}</Typography>
                                      <Typography sx={{ color: !isDarkMode ? '#292D34' : '' }}>{reaction?.count}</Typography>
                                    </Box>
                                  ))}
                              </div>
                            </Box>
                            <Box className="ms-auto  bd-highlight">
                              {((activityDetails?.activityStatus === 'OPEN' && activityDetails?.participant === true) ||
                                (activityDetails?.petitionStatus === 'OPEN' && activityDetails?.organiser === true)) && (
                                <span className="d-flex align-items-center">
                                  {message?.pinned === true && (
                                    <Tooltip title="Pinned">
                                      <ChatPinIcon sx={{ fontSize: 'medium' }}></ChatPinIcon>
                                    </Tooltip>
                                  )}
                                  <Tooltip title="Reaction">
                                    <ChatReaction
                                      message={message}
                                      chatData={getAllConversation}
                                      onSelectEmoji={handleEmojiSelect}
                                      onClose={data => handleReactClose(data)}
                                    />
                                  </Tooltip>
                                  <Tooltip title="Reply">
                                    <ReplayIcon className="m-2" sx={{ fontSize: 'medium' }} onClick={() => handleReply(message)} />
                                  </Tooltip>
                                  <Tooltip title="More">
                                    <ChatOptions
                                      handleMenuItemClick={handleMenuItemClick}
                                      message={message}
                                      chatDetails={activityDetails}
                                    />
                                  </Tooltip>
                                </span>
                              )}
                            </Box>
                          </>
                        )}
                      </ListItemButton>
                    ))}
                  </>
                )}
                {Array.isArray(yesterdayItem) && yesterdayItem?.length > 0 && (
                  <>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontFamily: 'Manrope',
                        fontWeight: theme.infoText,
                        textAlign: 'center',
                        letterSpacing: '1px',
                      }}
                    >
                      {' '}
                      {yesterdayLabel}{' '}
                    </Typography>
                    {yesterdayItem?.map((message, index) => (
                      <ListItemButton className="d-flex bd-highlight" alignItems="flex-start" key={index}>
                        {message?.messageType !== 'CHAT' ? (
                          <Alert severity="success" sx={{ minWidth: '100%' }}>
                            {message?.message}
                          </Alert>
                        ) : (
                          <>
                            <ListItemAvatar className="bd-highlight" onClick={() => goToProfilePage(message?.userId)}>
                              <Badge
                                overlap="circular"
                                onClick={() => navigate(`/profile/${message?.userId}`)}
                                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                badgeContent={
                                  message?.organizer ? (
                                    <OrganiserCrownIcon sx={{ height: 'unset', width: 'unset', rotate: '-51deg' }} />
                                  ) : message?.moderator ? (
                                    <ModeratorStarIcon sx={{ height: 'unset', width: 'unset', rotate: '-51deg' }} />
                                  ) : null
                                }
                              >
                                <Avatar
                                  alt={`${message?.name}` ? '' : getInitials(message?.name)}
                                  src={message?.profileImage}
                                  sx={{
                                    fontSize: message?.name ? 'default' : '12px',
                                  }}
                                >
                                  {' '}
                                  {getInitials(message?.name)}
                                </Avatar>
                              </Badge>
                            </ListItemAvatar>
                            <Box className="bd-highlight">
                              {message?.replyToMessageId !== 0 && message?.replyToMessageId !== null && (
                                <>
                                  <Box className={isDarkMode ? `chat-container-dark` : `chat-container`}>
                                    <div className={isDarkMode ? 'reply-section' : 'reply-section'}>
                                      <div className="reply-info">
                                        <p
                                          className="reply-user"
                                          style={{
                                            color:
                                              message?.replyToMessageUserId === userDetails?.id
                                                ? isDarkMode
                                                  ? '#ffce00'
                                                  : 'primary.light'
                                                : 'inherit',
                                          }}
                                        >
                                          {message?.replyToMessageUserId === userDetails?.id
                                            ? message?.replyToMessageUser
                                            : message?.replyToMessageUser}
                                        </p>
                                        <span className="reply-text">{message?.replyToMessage}</span>
                                      </div>
                                    </div>
                                  </Box>
                                </>
                              )}
                              <ListItemText
                                primary={
                                  <React.Fragment>
                                    <Typography
                                      sx={{
                                        display: 'inline',
                                        fontWeight: '600',
                                        color:
                                          message?.replyToMessageUserId === userDetails?.id
                                            ? isDarkMode
                                              ? '#ffce00'
                                              : 'primary.light'
                                            : 'inherit',
                                      }}
                                      component="span"
                                      variant="body2"
                                      color={isDarkMode ? '#ffce00' : 'text.primary'}
                                    >
                                      {message?.userId === userDetails?.id ? 'You' : message.name} &nbsp;
                                    </Typography>
                                    {moment(message?.chatPostedOn).fromNow()}
                                    <>
                                      {message?.eventGallery?.length > 0 && message?.eventGallery[0]?.type === 'images' && (
                                        <Box>
                                          <ImageComponent additionalData={message?.eventGallery[0]} />
                                        </Box>
                                      )}
                                      {message?.eventGallery?.length > 0 && message?.eventGallery[0]?.type === 'videos' && (
                                        <Box>
                                          <VideoComponent additionalData={message?.eventGallery[0]} />
                                        </Box>
                                      )}
                                    </>
                                  </React.Fragment>
                                }
                                sx={{ wordBreak: 'break-all' }}
                                secondary={message?.message}
                              />
                              <div style={{ display: 'flex', gap: '8px' }}>
                                {message?.chatReactions?.length > 0 &&
                                  message?.chatReactions?.map((reaction, index) => (
                                    <Box
                                      sx={{
                                        width: '40px',
                                        height: '25px',
                                        background: isDarkMode ? '#1f1f26' : '#e0e0e0',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: '#fff',
                                        borderRadius: '10px',
                                        fontSize: ' 16px',
                                        display: 'flex',
                                        cursor: 'pointer',
                                      }}
                                      key={index}
                                    >
                                      <Typography>{reaction?.reaction}</Typography>
                                      <Typography sx={{ color: !isDarkMode ? '#292D34' : '' }}>{reaction?.count}</Typography>
                                    </Box>
                                  ))}
                              </div>
                            </Box>
                            <Box className="ms-auto  bd-highlight">
                              {(((activityDetails?.activityStatus === 'OPEN' ||
                                activityDetails?.activityStatus === 'ONGOING' ||
                                activityDetails?.activityStatus === 'RUNNING' ||
                                activityDetails?.activityStatus === 'ENDED' ||
                                activityDetails?.activityStatus === 'COMPLETED') &&
                                activityDetails?.participant === true) ||
                                ((activityDetails?.petitionStatus === 'OPEN' ||
                                  activityDetails?.petitionStatus === 'NOTIFIED' ||
                                  activityDetails?.petitionStatus === 'EXTENDED' ||
                                  activityDetails?.petitionStatus === 'COMPLETED') &&
                                  activityDetails?.participant === true)) && (
                                <span className="d-flex align-items-center">
                                  {message?.pinned === true && (
                                    <Tooltip title="Pinned">
                                      <ChatPinIcon sx={{ fontSize: 'medium' }}></ChatPinIcon>
                                    </Tooltip>
                                  )}
                                  <Tooltip title="Reaction">
                                    <ChatReaction
                                      message={message}
                                      chatData={getAllConversation}
                                      onSelectEmoji={handleEmojiSelect}
                                      onClose={data => handleReactClose(data)}
                                    />
                                  </Tooltip>
                                  <Tooltip title="Reply">
                                    <ReplayIcon className="m-2" sx={{ fontSize: 'medium' }} onClick={() => handleReply(message)} />
                                  </Tooltip>
                                  <Tooltip title="More">
                                    <ChatOptions handleMenuItemClick={handleMenuItemClick} message={message} chatDetails={activityData} />
                                  </Tooltip>
                                </span>
                              )}
                            </Box>
                          </>
                        )}
                      </ListItemButton>
                    ))}
                  </>
                )}
                {Array.isArray(todayItem) && todayItem?.length > 0 && (
                  <>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontFamily: 'Manrope',
                        fontWeight: theme.infoText,
                        textAlign: 'center',
                        letterSpacing: '1px',
                      }}
                    >
                      {' '}
                      {todayLabel}{' '}
                    </Typography>
                    {todayItem?.map((message, index) => (
                      <ListItemButton className="d-flex bd-highlight" alignItems="flex-start" key={index}>
                        {message?.messageType !== 'CHAT' ? (
                          <Alert severity="success" sx={{ minWidth: '100%' }}>
                            {message?.message}
                          </Alert>
                        ) : (
                          <>
                            <ListItemAvatar className="bd-highlight" onClick={() => goToProfilePage(message?.userId)}>
                              <Badge
                                overlap="circular"
                                onClick={() => navigate(`/profile/${message?.userId}`)}
                                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                badgeContent={
                                  message?.organizer ? (
                                    <OrganiserCrownIcon sx={{ height: 'unset', width: 'unset', rotate: '-51deg' }} />
                                  ) : message?.moderator ? (
                                    <ModeratorStarIcon sx={{ height: 'unset', width: 'unset', rotate: '-51deg' }} />
                                  ) : null
                                }
                              >
                                <Avatar
                                  alt={`${message?.name}` ? '' : getInitials(message?.name)}
                                  src={message?.profileImage}
                                  sx={{
                                    fontSize: message?.name ? 'default' : '12px',
                                  }}
                                >
                                  {' '}
                                  {getInitials(message?.name)}
                                </Avatar>
                              </Badge>
                            </ListItemAvatar>
                            <Box className="bd-highlight">
                              {message?.replyToMessageId !== 0 && message?.replyToMessageId !== null && (
                                <>
                                  <Box className={isDarkMode ? `chat-container-dark` : `chat-container`}>
                                    <div className={isDarkMode ? 'reply-section' : 'reply-section'}>
                                      <div className="reply-info">
                                        <p
                                          className="reply-user"
                                          style={{
                                            color:
                                              message?.replyToMessageUserId === userDetails?.id
                                                ? isDarkMode
                                                  ? '#ffce00'
                                                  : 'primary.light'
                                                : 'inherit',
                                          }}
                                        >
                                          {message?.replyToMessageUserId === userDetails?.id
                                            ? message?.replyToMessageUser
                                            : message?.replyToMessageUser}
                                        </p>
                                        <span className="reply-text">{message?.replyToMessage}</span>
                                      </div>
                                    </div>
                                  </Box>
                                </>
                              )}
                              <ListItemText
                                primary={
                                  <React.Fragment>
                                    <Typography
                                      sx={{
                                        display: 'inline',
                                        fontWeight: '600',
                                        color:
                                          message?.replyToMessageUserId === userDetails?.id
                                            ? isDarkMode
                                              ? '#ffce00'
                                              : 'primary.light'
                                            : 'inherit',
                                      }}
                                      component="span"
                                      variant="body2"
                                      color={isDarkMode ? '#ffce00' : 'text.primary'}
                                    >
                                      {message?.userId === userDetails?.id ? 'You' : message.name} &nbsp;
                                    </Typography>
                                    {moment(message?.chatPostedOn).fromNow()}
                                    <>
                                      {message?.eventGallery?.length > 0 && message?.eventGallery[0]?.type === 'images' && (
                                        <Box>
                                          <ImageComponent additionalData={message?.eventGallery[0]} />
                                        </Box>
                                      )}
                                      {message?.eventGallery?.length > 0 && message?.eventGallery[0]?.type === 'videos' && (
                                        <Box>
                                          <VideoComponent additionalData={message?.eventGallery[0]} />
                                        </Box>
                                      )}
                                    </>
                                  </React.Fragment>
                                }
                                sx={{ wordBreak: 'break-all' }}
                                secondary={message?.message}
                              />
                              <div style={{ display: 'flex', gap: '8px' }}>
                                {message?.chatReactions?.length > 0 &&
                                  message?.chatReactions?.map((reaction, index) => (
                                    <Box
                                      sx={{
                                        width: '40px',
                                        height: '25px',
                                        background: isDarkMode ? '#1f1f26' : '#e0e0e0',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: '#fff',
                                        borderRadius: '10px',
                                        fontSize: ' 16px',
                                        display: 'flex',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <Typography>{reaction?.reaction}</Typography>
                                      <Typography sx={{ color: !isDarkMode ? '#292D34' : '' }}>{reaction?.count}</Typography>
                                    </Box>
                                  ))}
                              </div>
                            </Box>
                            <Box className="ms-auto  bd-highlight">
                              {(((activityDetails?.activityStatus === 'OPEN' ||
                                activityDetails?.activityStatus === 'ONGOING' ||
                                activityDetails?.activityStatus === 'RUNNING' ||
                                activityDetails?.activityStatus === 'ENDED' ||
                                activityDetails?.activityStatus === 'COMPLETED') &&
                                activityDetails?.participant === true) ||
                                activityDetails?.partialParticipant === true ||
                                ((activityDetails?.petitionStatus === 'OPEN' ||
                                  activityDetails?.petitionStatus === 'NOTIFIED' ||
                                  activityDetails?.petitionStatus === 'EXTENDED' ||
                                  activityDetails?.petitionStatus === 'COMPLETED') &&
                                  activityDetails?.participant === true)) && (
                                <span className="d-flex align-items-center">
                                  {message?.pinned === true && (
                                    <Tooltip title="Pinned">
                                      <ChatPinIcon sx={{ fontSize: 'medium' }}></ChatPinIcon>
                                    </Tooltip>
                                  )}
                                  <Tooltip title="Reaction">
                                    <ChatReaction
                                      message={message}
                                      chatData={getAllConversation}
                                      onSelectEmoji={handleEmojiSelect}
                                      onClose={data => handleReactClose(data)}
                                    />
                                  </Tooltip>
                                  <Tooltip title="Reply">
                                    <ReplayIcon className="m-2" sx={{ fontSize: 'medium' }} onClick={() => handleReply(message)} />
                                  </Tooltip>
                                  <Tooltip title="More">
                                    <ChatOptions handleMenuItemClick={handleMenuItemClick} message={message} chatDetails={activityData} />
                                  </Tooltip>
                                </span>
                              )}
                            </Box>
                          </>
                        )}
                      </ListItemButton>
                    ))}
                  </>
                )}
                {Array.isArray(unReadItem) && unReadItem?.length > 0 && (
                  <>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontFamily: 'Manrope',
                        fontWeight: theme.infoText,
                        textAlign: 'center',
                        letterSpacing: '1px',
                      }}
                    >
                      {' '}
                      {unreadLabel}{' '}
                    </Typography>
                    {todayItem?.map((message, index) => (
                      <ListItemButton className="d-flex bd-highlight" alignItems="flex-start" key={index}>
                        {message?.messageType !== 'CHAT' ? (
                          <Alert severity="success" sx={{ minWidth: '100%' }}>
                            {message?.message}
                          </Alert>
                        ) : (
                          <>
                            <ListItemAvatar className="bd-highlight" onClick={() => goToProfilePage(message?.userId)}>
                              <Badge
                                overlap="circular"
                                onClick={() => navigate(`/profile/${message?.userId}`)}
                                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                                badgeContent={
                                  message?.organizer ? (
                                    <OrganiserCrownIcon sx={{ height: 'unset', width: 'unset', rotate: '-51deg' }} />
                                  ) : message?.moderator ? (
                                    <ModeratorStarIcon sx={{ height: 'unset', width: 'unset', rotate: '-51deg' }} />
                                  ) : null
                                }
                              >
                                <Avatar
                                  alt={`${message?.name}` ? '' : getInitials(message?.name)}
                                  src={message?.profileImage}
                                  sx={{
                                    fontSize: message?.name ? 'default' : '12px',
                                  }}
                                >
                                  {' '}
                                  {getInitials(message?.name)}
                                </Avatar>
                              </Badge>
                            </ListItemAvatar>
                            <Box className="bd-highlight">
                              {message?.replyToMessageId !== 0 && message?.replyToMessageId !== null && (
                                <>
                                  <Box className={isDarkMode ? `chat-container-dark` : `chat-container`}>
                                    <div className={isDarkMode ? 'reply-section' : 'reply-section'}>
                                      <div className="reply-info">
                                        <p
                                          className="reply-user"
                                          style={{
                                            color:
                                              message?.replyToMessageUserId === userDetails?.id
                                                ? isDarkMode
                                                  ? '#ffce00'
                                                  : 'primary.light'
                                                : 'inherit',
                                          }}
                                        >
                                          {message?.replyToMessageUserId === userDetails?.id
                                            ? message?.replyToMessageUser
                                            : message?.replyToMessageUser}
                                        </p>
                                        <span className="reply-text">{message?.replyToMessage}</span>
                                      </div>
                                    </div>
                                  </Box>
                                </>
                              )}
                              <ListItemText
                                primary={
                                  <React.Fragment>
                                    <Typography
                                      sx={{
                                        display: 'inline',
                                        fontWeight: '600',
                                        color: pinnedData?.userId === userDetails?.id ? 'primary.light' : 'inherit',
                                      }}
                                      component="span"
                                      variant="body2"
                                      color={isDarkMode ? '#ffce00' : 'text.primary'}
                                    >
                                      {message?.userId === userDetails?.id ? 'You' : message.name} &nbsp;
                                    </Typography>
                                    {moment(message?.chatPostedOn).fromNow()}
                                    <>
                                      {message?.eventGallery?.length > 0 && message?.eventGallery[0]?.type === 'images' && (
                                        <Box>
                                          <ImageComponent additionalData={message?.eventGallery[0]} />
                                        </Box>
                                      )}
                                      {message?.eventGallery?.length > 0 && message?.eventGallery[0]?.type === 'videos' && (
                                        <Box>
                                          <VideoComponent additionalData={message?.eventGallery[0]} />
                                        </Box>
                                      )}
                                    </>
                                  </React.Fragment>
                                }
                                sx={{ wordBreak: 'break-all' }}
                                secondary={message?.message}
                              />
                              <div style={{ display: 'flex', gap: '8px' }}>
                                {message?.chatReactions?.length > 0 &&
                                  message?.chatReactions?.map((reaction, index) => (
                                    <Box
                                      sx={{
                                        width: '40px',
                                        height: '25px',
                                        background: isDarkMode ? '#1f1f26' : '#e0e0e0',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        color: '#fff',
                                        borderRadius: '10px',
                                        fontSize: ' 16px',
                                        display: 'flex',
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <Typography>{reaction?.reaction}</Typography>
                                      <Typography sx={{ color: !isDarkMode ? '#292D34' : '' }}>{reaction?.count}</Typography>
                                    </Box>
                                  ))}
                              </div>
                            </Box>
                            <Box className="ms-auto  bd-highlight">
                              {(((activityDetails?.activityStatus === 'OPEN' ||
                                activityDetails?.activityStatus === 'ONGOING' ||
                                activityDetails?.activityStatus === 'RUNNING' ||
                                activityDetails?.activityStatus === 'ENDED' ||
                                activityDetails?.activityStatus === 'COMPLETED') &&
                                activityDetails?.participant === true) ||
                                ((activityDetails?.petitionStatus === 'OPEN' ||
                                  activityDetails?.petitionStatus === 'NOTIFIED' ||
                                  activityDetails?.petitionStatus === 'EXTENDED' ||
                                  activityDetails?.petitionStatus === 'COMPLETED') &&
                                  activityDetails?.participant === true)) && (
                                <span className="d-flex align-items-center">
                                  {message?.pinned === true && (
                                    <Tooltip title="Pinned">
                                      <ChatPinIcon sx={{ fontSize: 'medium' }}></ChatPinIcon>
                                    </Tooltip>
                                  )}
                                  <Tooltip title="Reaction">
                                    <ChatReaction
                                      message={message}
                                      chatData={getAllConversation}
                                      onSelectEmoji={handleEmojiSelect}
                                      onClose={data => handleReactClose(data)}
                                    />
                                  </Tooltip>
                                  <Tooltip title="Reply">
                                    <ReplayIcon className="m-2" sx={{ fontSize: 'medium' }} onClick={() => handleReply(message)} />
                                  </Tooltip>
                                  <Tooltip title="More">
                                    <ChatOptions handleMenuItemClick={handleMenuItemClick} message={message} chatDetails={activityData} />
                                  </Tooltip>
                                </span>
                              )}
                            </Box>
                          </>
                        )}
                      </ListItemButton>
                    ))}
                  </>
                )}
              </>
            ) : (
              !getAllConversation && (
                <>
                  <Box sx={{ pt: 0.5 }}>
                    <Skeleton variant="rectangular" width={210} height={118} />
                    <Skeleton width="60%" />
                    <Skeleton width="60%" />
                  </Box>
                  <Box sx={{ pt: 0.5 }}>
                    <Skeleton variant="rectangular" width={210} height={118} />
                    <Skeleton width="60%" />
                    <Skeleton width="60%" />
                  </Box>
                  <Box sx={{ pt: 0.5 }}>
                    <Skeleton variant="rectangular" width={210} height={118} />
                    <Skeleton width="60%" />
                    <Skeleton width="60%" />
                  </Box>
                </>
              )
            )}
          </div>
        </div>
      </div>
      <Box className="card-footer mob-input">
        {activityData?.eventType.toUpperCase() === 'ACTIVITY' && (
          <>
            {activityDetails?.activityStatus === 'OPEN' && activityDetails?.chatable === true && (
              <>
                <Box sx={{ background: isDarkMode ? '' : '#fff', marginTop: reply ? '-50px' : 'unset' }}>
                  {reply && (
                    <Box
                      sx={{
                        px: 7,
                        py: 0,
                        background: isDarkMode ? '#6f6f6f' : '#faf9ff',
                        borderRadius: '0px',
                        padding: '1.2% 2%',
                        position: 'relative',
                        width: '100%',
                        margin: '0 auto',
                        zIndex: 100,
                      }}
                    >
                      <IconButton
                        sx={{
                          position: 'absolute',
                          top: '4px',
                          right: '4px',
                          background: isDarkMode ? 'transparant' : 'white',
                          zIndex: 1,
                        }}
                        onClick={() => closeReply()}
                      >
                        <CloseIcon />
                      </IconButton>
                      <Typography
                        sx={{
                          fontSize: 14,
                          marginBottom: '0.11em',
                          color: replyData?.userId === userDetails?.id ? (isDarkMode ? '#ffce00' : 'primary.light') : 'text.secondary',
                        }}
                        gutterBottom
                      >
                        Replying to {replyData?.userId == userDetails?.id ? 'yourself' : replyData.name}
                      </Typography>
                      <Typography variant="body2">
                        {' '}
                        {replyData?.message?.length > 20 ? replyData?.message?.slice(0, 20) + '...' : replyData?.message}
                      </Typography>
                    </Box>
                  )}
                  <Box className="input-group input-width my-3">
                    <Box className="input-group-append">
                      <span
                        className="input-group-text attach_btn"
                        style={{
                          background: theme.palette.mode === 'dark' ? '#1f1f26' : '#f6f7f8',
                        }}
                      >
                        <ControlPointIcon onClick={openImageUploader} sx={{ color: isDarkMode ? '#ffce00' : '#674bff' }} />
                        {isImageUploaderOpen && <ChatMediaOption anchorEl={anchorEle} onClose={cloeImageUploader} />}
                      </span>
                    </Box>
                    <input type="file" id="imageInput" style={{ display: 'none' }} onChange={handleFileSelect} />
                    <br />
                    <input
                      placeholder="Type your message..."
                      style={{
                        background: theme.palette.mode === 'dark' ? '#1f1f26' : '#f6f7f8',
                        color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.7)' : '#333',
                        fontSize: '14px',
                        paddingTop: '2px',
                        border: 'none',
                        outline: 'none',
                        width: '100%',
                        height: '60px',
                        flex: 1,
                      }}
                      ref={inputRef}
                      onKeyDown={handleKeyDown}
                    />
                    <Box className="input-group-append">
                      <span
                        className="input-group-text send_btn align-items-baseline "
                        style={{
                          background: theme.palette.mode === 'dark' ? '#1f1f26' : '#f6f7f8',
                          paddingBottom: '0px',
                        }}
                      >
                        {' '}
                        <Box sx={{ position: 'relative', bottom: ' 9px' }}>
                          <ChatReactionMessage
                            message={message}
                            onSelectEmojiMessage={handleEmojiMessageSelect}
                            onClose={() => setReacted(false)}
                          />
                        </Box>
                        {disableImage ? (
                          <CircularProgress size={24} sx={{ color: 'secondary.main' }} />
                        ) : (
                          <SendIcon
                            onClick={() => {
                              handleSendIconClick(reply);
                            }}
                            sx={{ color: 'secondary.main' }}
                          />
                        )}
                      </span>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
            {activityDetails?.activityStatus === 'ONGOING' && activityDetails?.chatable === true && (
              <>
                <Box sx={{ background: isDarkMode ? '' : '#fff', marginTop: reply ? '-50px' : 'unset' }}>
                  {reply && (
                    <Box
                      sx={{
                        px: 7,
                        py: 0,
                        background: isDarkMode ? '#6f6f6f' : '#faf9ff',
                        borderRadius: '0px',
                        padding: '1.2% 2%',
                        position: 'relative',
                        zIndex: 100,
                      }}
                    >
                      <IconButton
                        sx={{
                          position: 'absolute',
                          top: '4px',
                          right: '4px',
                          background: isDarkMode ? 'transparant' : 'white',
                          zIndex: 1,
                        }}
                        onClick={() => closeReply()}
                      >
                        <CloseIcon />
                      </IconButton>
                      <Typography
                        sx={{
                          fontSize: 14,
                          marginBottom: '0.11em',
                          color: replyData?.userId === userDetails?.id ? (isDarkMode ? '#ffce00' : 'primary.light') : 'text.secondary',
                        }}
                        gutterBottom
                      >
                        Replying to {replyData?.userId == userDetails?.id ? 'yourself' : replyData.name}
                      </Typography>
                      <Typography variant="body2">
                        {' '}
                        {replyData?.message?.length > 20 ? replyData?.message?.slice(0, 20) + '...' : replyData?.message}
                      </Typography>
                    </Box>
                  )}
                  <Box className="input-group input-width my-3">
                    <Box className="input-group-append">
                      <span
                        className="input-group-text attach_btn"
                        style={{
                          background: theme.palette.mode === 'dark' ? '#1f1f26' : '#f6f7f8',
                        }}
                      >
                        <ControlPointIcon onClick={openImageUploader} sx={{ color: '#674bff' }} />
                        {isImageUploaderOpen && <ChatMediaOption anchorEl={anchorEle} onClose={cloeImageUploader} />}
                      </span>
                    </Box>
                    <input type="file" id="imageInput" style={{ display: 'none' }} onChange={handleFileSelect} />
                    <br />
                    <input
                      placeholder="Type your message..."
                      style={{
                        background: theme.palette.mode === 'dark' ? '#1f1f26' : '#f6f7f8',
                        color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.7)' : '#333',
                        fontSize: '14px',
                        paddingTop: '2px',
                        border: 'none',
                        outline: 'none',
                        width: '70%',
                      }}
                      ref={inputRef}
                      onKeyDown={handleKeyDown}
                    />
                    <Box className="input-group-append">
                      <span
                        className="input-group-text send_btn align-items-baseline "
                        style={{
                          background: theme.palette.mode === 'dark' ? '#1f1f26' : '#f6f7f8',
                          paddingBottom: '0px',
                        }}
                      >
                        {' '}
                        <Box sx={{ position: 'relative', bottom: ' 9px' }}>
                          <ChatReactionMessage
                            message={message}
                            onSelectEmojiMessage={handleEmojiMessageSelect}
                            onClose={() => setReacted(false)}
                          />
                        </Box>
                        {disableImage ? (
                          <CircularProgress size={24} sx={{ color: 'secondary.main' }} />
                        ) : (
                          <SendIcon
                            onClick={() => {
                              handleSendIconClick(reply);
                            }}
                            sx={{ color: 'secondary.main' }}
                          />
                        )}
                      </span>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
            {activityDetails?.activityStatus === 'RUNNING' && activityDetails?.chatable === true && (
              <>
                <Box sx={{ background: isDarkMode ? '' : '#fff', marginTop: reply ? '-50px' : 'unset' }}>
                  {reply && (
                    <Box
                      sx={{
                        px: 7,
                        py: 0,
                        background: isDarkMode ? '#6f6f6f' : '#faf9ff',
                        borderRadius: '0px',
                        padding: '1.2% 2%',
                        position: 'relative',
                        zIndex: 100,
                      }}
                    >
                      <IconButton
                        sx={{
                          position: 'absolute',
                          top: '4px',
                          right: '4px',
                          background: isDarkMode ? 'transparant' : 'white',
                          zIndex: 1,
                        }}
                        onClick={() => closeReply()}
                      >
                        <CloseIcon />
                      </IconButton>
                      <Typography
                        sx={{
                          fontSize: 14,
                          marginBottom: '0.11em',
                          color: replyData?.userId === userDetails?.id ? (isDarkMode ? '#ffce00' : 'primary.light') : 'text.secondary',
                        }}
                        gutterBottom
                      >
                        Replying to {replyData?.userId == userDetails?.id ? 'yourself' : replyData.name}
                      </Typography>
                      <Typography variant="body2">
                        {' '}
                        {replyData?.message?.length > 20 ? replyData?.message?.slice(0, 20) + '...' : replyData?.message}
                      </Typography>
                    </Box>
                  )}
                  <Box className="input-group input-width my-3">
                    <Box className="input-group-append">
                      <span
                        className="input-group-text attach_btn"
                        style={{
                          background: theme.palette.mode === 'dark' ? '#1f1f26' : '#f6f7f8',
                        }}
                      >
                        <ControlPointIcon onClick={openImageUploader} sx={{ color: '#674bff' }} />
                        {isImageUploaderOpen && <ChatMediaOption anchorEl={anchorEle} onClose={cloeImageUploader} />}
                      </span>
                    </Box>
                    <input type="file" id="imageInput" style={{ display: 'none' }} onChange={handleFileSelect} />
                    <br />
                    <input
                      placeholder="Type your message..."
                      style={{
                        background: theme.palette.mode === 'dark' ? '#1f1f26' : '#f6f7f8',
                        color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.7)' : '#333',
                        fontSize: '14px',
                        paddingTop: '2px',
                        border: 'none',
                        outline: 'none',
                        width: '100%',
                        flex: 1,
                      }}
                      ref={inputRef}
                      onKeyDown={handleKeyDown}
                    />
                    <Box className="input-group-append">
                      <span
                        className="input-group-text send_btn align-items-baseline "
                        style={{
                          background: theme.palette.mode === 'dark' ? '#1f1f26' : '#f6f7f8',
                          paddingBottom: '0px',
                        }}
                      >
                        {' '}
                        <Box sx={{ position: 'relative', bottom: ' 9px' }}>
                          <ChatReactionMessage
                            message={message}
                            onSelectEmojiMessage={handleEmojiMessageSelect}
                            onClose={() => setReacted(false)}
                          />
                        </Box>
                        {disableImage ? (
                          <CircularProgress size={24} sx={{ color: 'secondary.main' }} />
                        ) : (
                          <SendIcon
                            onClick={() => {
                              handleSendIconClick(reply);
                            }}
                            sx={{ color: 'secondary.main' }}
                          />
                        )}
                      </span>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
            {activityDetails?.activityStatus === 'OPEN' && activityDetails?.chatable === false && (
              <>
                <Typography
                  sx={{
                    color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.7)' : '#676E7B',
                    fontSize: '14px',
                    fontFamily: 'Manrope',
                    textAlign: 'center',
                    paddingBottom: '7%',
                    paddingTop: '6%',
                  }}
                >
                  Be part of this Activity to engage in this conversation
                </Typography>
              </>
            )}
            {activityDetails?.activityStatus === 'ONGOING' && activityDetails?.chatable === false && (
              <>
                <Typography
                  sx={{
                    color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.7)' : '#676E7B',
                    fontSize: '14px',
                    fontFamily: 'Manrope',
                    textAlign: 'center',
                    paddingBottom: '7%',
                    paddingTop: '6%',
                  }}
                >
                  You won't be able to engage in the conversations as you are not part of the activity
                </Typography>
              </>
            )}
            {activityDetails?.activityStatus === 'RUNNING' && activityDetails?.chatable === false && (
              <>
                <Typography
                  sx={{
                    color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.7)' : '#676E7B',
                    fontSize: '14px',
                    fontFamily: 'Manrope',
                    textAlign: 'center',
                    paddingBottom: '7%',
                    paddingTop: '6%',
                  }}
                >
                  You won't be able to engage in the conversations as you are not part of the activity
                </Typography>
              </>
            )}
            {activityDetails?.activityStatus === 'ENDED' && activityDetails?.chatable === false && (
              <>
                <Typography
                  sx={{
                    color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.7)' : '#676E7B',
                    fontSize: '14px',
                    fontFamily: 'Manrope',
                    textAlign: 'center',
                    paddingBottom: '7%',
                    paddingTop: '6%',
                  }}
                >
                  You won't be able to engage in the conversations as you are not part of the activity
                </Typography>
              </>
            )}
            {activityDetails?.activityStatus === 'COMPLETED' && (
              <>
                <Typography
                  sx={{
                    color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.7)' : '#676E7B',
                    fontSize: '14px',
                    fontFamily: 'Manrope',
                    textAlign: 'center',
                    paddingBottom: '7%',
                    paddingTop: '6%',
                  }}
                >
                  Please note that this activity has been completed and is no longer available for further discussion.
                  {!activityData?.organiser && '.'}
                  {activityData?.organiser && (
                    <Link to={`/activity/${activityData?.id}`} style={{ textDecoration: 'none', color: '#674BFF' }}>
                      , read review here{' '}
                    </Link>
                  )}
                </Typography>
              </>
            )}
            {activityDetails?.activityStatus === 'ENDED' && activityDetails?.chatable === true && (
              <>
                <Box sx={{ background: isDarkMode ? '' : '#fff', marginTop: reply ? '-50px' : 'unset' }}>
                  {reply && (
                    <Box
                      sx={{
                        px: 7,
                        py: 0,
                        background: isDarkMode ? '#6f6f6f' : '#faf9ff',
                        borderRadius: '0px',
                        padding: '1.2% 2%',
                        position: 'relative',
                        zIndex: 100,
                      }}
                    >
                      <IconButton
                        sx={{
                          position: 'absolute',
                          top: '4px',
                          right: '4px',
                          background: isDarkMode ? 'transparant' : 'white',
                          zIndex: 1,
                        }}
                        onClick={() => closeReply()}
                      >
                        <CloseIcon />
                      </IconButton>
                      <Typography
                        sx={{
                          fontSize: 14,
                          marginBottom: '0.11em',
                          color: replyData?.userId === userDetails?.id ? (isDarkMode ? '#ffce00' : 'primary.light') : 'text.secondary',
                        }}
                        gutterBottom
                      >
                        Replying to {replyData?.userId == userDetails?.id ? 'yourself' : replyData.name}
                      </Typography>
                      <Typography variant="body2">
                        {' '}
                        {replyData?.message?.length > 20 ? replyData?.message?.slice(0, 20) + '...' : replyData?.message}
                      </Typography>
                    </Box>
                  )}
                  <Box
                    className="input-group input-width my-3"
                    sx={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                    }}
                  >
                    <Box className="input-group-append">
                      <span
                        className="input-group-text attach_btn"
                        style={{
                          background: theme.palette.mode === 'dark' ? '#1f1f26' : '#f6f7f8',
                        }}
                      >
                        <ControlPointIcon onClick={openImageUploader} sx={{ color: '#674bff' }} />
                        {isImageUploaderOpen && <ChatMediaOption anchorEl={anchorEle} onClose={cloeImageUploader} />}
                      </span>
                    </Box>
                    <input type="file" id="imageInput" style={{ display: 'none' }} onChange={handleFileSelect} />
                    <br />
                    <input
                      placeholder="You can only upload images and videos as the activity has ended."
                      style={{
                        background: theme.palette.mode === 'dark' ? '#1f1f26' : '#f6f7f8',
                        color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.7)' : '#333',
                        fontSize: '14px',
                        paddingTop: '2px',
                        border: 'none',
                        outline: 'none',
                        width: '78%',
                      }}
                      ref={inputRef}
                      onKeyDown={handleKeyDown}
                      readOnly={true}
                    />
                    <Box className="input-group-append">
                      <span
                        className="input-group-text send_btn align-items-baseline"
                        style={{
                          background: theme.palette.mode === 'dark' ? '#1f1f26' : '#f6f7f8',
                          paddingBottom: message ? '25%' : '40%',
                        }}
                      >
                        {message?.length > 0 && (
                          <>
                            <CloseOutlinedIcon sx={{ color: isDarkMode ? 'white' : 'black' }} onClick={() => setMessage('')} />
                          </>
                        )}
                        <Box sx={{ position: 'relative', bottom: ' 9px' }}></Box>
                        {disableImage ? (
                          <CircularProgress size={24} sx={{ color: 'secondary.main' }} />
                        ) : (
                          <SendIcon
                            onClick={() => {
                              handleSendIconClick(reply);
                            }}
                            sx={{ color: 'secondary.main' }}
                          />
                        )}
                      </span>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
        {activityData?.eventType.toUpperCase() === 'PETITION' && (
          <>
            {(activityDetails?.petitionStatus === 'OPEN' || activityDetails?.petitionStatus === 'NOTIFIED') &&
              activityDetails?.hasPermission === true && (
                <>
                  <Box sx={{ background: isDarkMode ? '' : '#fff', marginTop: reply ? '-50px' : 'unset' }}>
                    {reply && (
                      <Box
                        sx={{
                          px: 7,
                          py: 0,
                          background: isDarkMode ? '#6f6f6f' : '#faf9ff',
                          borderRadius: '0px',
                          padding: '1.2% 2%',
                          position: 'relative',
                          zIndex: 100,
                        }}
                      >
                        <IconButton
                          sx={{
                            position: 'absolute',
                            top: '4px',
                            right: '4px',
                            background: isDarkMode ? 'transparant' : 'white',
                            zIndex: 1,
                          }}
                          onClick={() => closeReply()}
                        >
                          <CloseIcon />
                        </IconButton>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          Replying to {replyData?.userId == userDetails?.id ? 'yourself' : replyData.name}
                        </Typography>
                        <Typography variant="body2">
                          {' '}
                          {replyData?.message?.length > 20 ? replyData?.message?.slice(0, 20) + '...' : replyData?.message}
                        </Typography>
                      </Box>
                    )}
                    <Box className="input-group input-width my-3">
                      <Box className="input-group-append">
                        <span
                          className="input-group-text attach_btn"
                          style={{ background: theme.palette.mode === 'dark' ? '#1f1f26' : '#f6f7f8' }}
                        >
                          <ControlPointIcon onClick={openImageUploader} sx={{ color: '#674bff' }} />
                          {isImageUploaderOpen && <ChatMediaOption anchorEl={anchorEle} onClose={cloeImageUploader} />}
                        </span>
                      </Box>
                      <input type="file" id="imageInput" style={{ display: 'none' }} onChange={handleFileSelect} />
                      <br />
                      <input
                        placeholder="Type your message..."
                        style={{
                          background: theme.palette.mode === 'dark' ? '#1f1f26' : '#f6f7f8',
                          color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.7)' : '#333',
                          fontSize: '14px',
                          paddingTop: '2px',
                          border: 'none',
                          outline: 'none',
                          width: '70%',
                        }}
                        ref={inputRef}
                        onKeyDown={handleKeyDown}
                      />
                      <Box className="input-group-append">
                        <span
                          className="input-group-text send_btn align-items-baseline"
                          style={{
                            background: theme.palette.mode === 'dark' ? '#1f1f26' : '#f6f7f8',
                            paddingBottom: '0px',
                          }}
                        >
                          <Box sx={{ position: 'relative', bottom: ' 9px' }}>
                            <ChatReactionMessage
                              message={message}
                              onSelectEmojiMessage={handleEmojiMessageSelect}
                              onClose={() => setReacted(false)}
                            />
                          </Box>
                          {disableImage ? (
                            <CircularProgress size={24} sx={{ color: 'secondary.main' }} />
                          ) : (
                            <SendIcon
                              onClick={() => {
                                handleSendIconClick(reply);
                              }}
                              sx={{ color: 'secondary.main' }}
                            />
                          )}
                        </span>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            {(activityDetails?.petitionStatus === 'OPEN' || activityDetails?.petitionStatus === 'NOTIFIED') &&
              activityDetails?.hasPermission === false && (
                <>
                  <>
                    <Typography
                      sx={{
                        color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.7)' : '#676E7B',
                        fontSize: '14px',
                        fontFamily: 'Manrope',
                        textAlign: 'center',
                        paddingBottom: '7%',
                        paddingTop: '6%',
                      }}
                    >
                      Only organizer can message here{' '}
                    </Typography>
                  </>
                </>
              )}
            {(activityDetails?.petitionStatus === 'COMPLETED' || activityDetails?.petitionStatus === 'ENDED') && (
              <>
                <Typography
                  sx={{
                    color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.7)' : '#676E7B',
                    fontSize: '14px',
                    fontFamily: 'Manrope',
                    textAlign: 'center',
                    paddingBottom: '7%',
                    paddingTop: '6%',
                  }}
                >
                  This Petition has been closed{' '}
                </Typography>
              </>
            )}
          </>
        )}
      </Box>
    </List>
  );
};

export default ChatFeed;
