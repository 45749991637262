import React, { CSSProperties } from 'react';
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AccountCircle, Visibility, VisibilityOff } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { handlePasswordResetFinish } from 'app/modules/account/password-reset/password-reset.reducer';

const validationSchema = Yup.object({
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?!\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/,
      'Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character.No white spaces are allowed'
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
});

const ResetPasswordPage = () => {
  const isMobile = useMediaQuery('(max-width: 425px)');
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const handleClickShowPassword = () => setShowPassword(show => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(show => !show);
  const dispatch = useAppDispatch();

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const loginContainer: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    paddingTop: '70px',
    paddingBottom: '70px',
  };
  const heading = {
    color: theme.textColor.heading,
    marginBottom: '20px',
    marginTop: '20px',
    fontSize: '18px',
  };

  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      dispatch(handlePasswordResetFinish({ key, newPassword: values.password }));
    },
  });
  const { handleSubmit, handleChange, handleBlur, values, touched, errors } = formik;
  return (
    <Grid container spacing={2} style={{ marginTop: 0, alignItems: 'center', justifyContent: 'center' }}>
      <Grid item xs={12} md={7} style={loginContainer}>
        <div onClick={() => navigate('/')}>
          <img src="content/images/logos/reachamy-logo.png" alt="reach amy logo" style={{ padding: '10px' }} />
        </div>
        <form onSubmit={handleSubmit}>
          <Stack alignItems={'center'} gap={1}>
            <h2 style={heading}>Reset Password</h2>

            <FormControl
              sx={{ m: 1, width: '25ch' }}
              variant="outlined"
              style={{ marginBottom: '10px', width: '356px', marginTop: '0px', backgroundColor: 'white' }}
            >
              <OutlinedInput
                size="small"
                name="password"
                id="password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="Password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                error={touched.password && Boolean(errors.password)}
              />
            </FormControl>
            {touched.password && errors.password && (
              <Typography variant="body2" color="error">
                {errors.password}
              </Typography>
            )}

            <FormControl
              sx={{ m: 1, width: '25ch' }}
              variant="outlined"
              style={{ marginBottom: '10px', width: '356px', marginTop: '0px', backgroundColor: 'white' }}
            >
              <OutlinedInput
                size="small"
                name="confirmPassword"
                id="confirmPassword"
                type={showConfirmPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle confirm password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="confirm Password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirmPassword}
                error={touched.confirmPassword && Boolean(errors.confirmPassword)}
              />
            </FormControl>
            {touched.confirmPassword && errors.confirmPassword && (
              <Typography variant="body2" color="error">
                {errors.confirmPassword}
              </Typography>
            )}
            <Button
              type="submit"
              variant="contained"
              style={{
                marginBottom: '10px',
                width: theme.createActivity.width,
                height: theme.createActivity.height,
                backgroundColor: theme.palette.primary.light,
                borderRadius: theme.createActivity.borderRadius,
                color: '#292D34',
                borderColor: theme.palette.primary.light,
              }}
            >
              <span>
                <Typography style={{ textTransform: 'capitalize' }}>Save</Typography>
              </span>
            </Button>
          </Stack>
        </form>
      </Grid>
    </Grid>
  );
};

export default ResetPasswordPage;
