import { Dialog, DialogContent, Grid, Stack } from '@mui/material';
import React, { useState } from 'react';
import PhoneInputSection from './phone-number-input-section';
import VerifyOtpSection from './verify-otp-section';
import CloseIcon from '@mui/icons-material/Close';

interface PhoneVerificationProps {
  handleClose: () => void;
  fullScreen: boolean;
  open: boolean;
  phoneInputCompleted: boolean;
  setPhoneInputCompleted: (val: any) => void;
  setotpVerificationCompleted: (val: any) => void;
  otpVerificationCompleted: boolean;
}
const PhoneVerification = ({
  handleClose,
  setPhoneInputCompleted,
  setotpVerificationCompleted,
  fullScreen,
  open,
  phoneInputCompleted,
  otpVerificationCompleted,
}: PhoneVerificationProps) => {
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="phone number verification modal">
      <style>
        {`.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-przs3r-MuiPaper-root-MuiDialog-paper {
                border-radius: 18px !important; 
                padding: 2% !important }`}
      </style>
      <Stack direction="row" alignItems={'center'} justifyContent={'flex-end'} sx={{ paddingTop: '2px', paddingRight: '5px' }}>
        <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
      </Stack>
      <DialogContent>
        <Grid padding={1}>
          {!phoneInputCompleted && <PhoneInputSection onCompleted={setPhoneInputCompleted} />}
          {phoneInputCompleted && (
            <VerifyOtpSection
              handleClose={handleClose}
              setotpVerificationCompleted={setotpVerificationCompleted}
              setPhoneInputCompleted={setPhoneInputCompleted}
              otpVerificationCompleted={otpVerificationCompleted}
            />
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PhoneVerification;
