import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export interface distanceType {
  distance: number;
  loading: boolean;
  error: string | null;
}

export const initialState: distanceType = {
  distance: 0,
  loading: false,
  error: null,
};

export const fetchDistanceLimit = createAsyncThunk<number, void, { rejectValue: string }>('userPreferences/distanceLimit', async () => {
  try {
    const response = await axios.get<any>('api/user-preferences/distance-limit');
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'An error occurred';
  }
});

export const distanceLimitSlice = createSlice({
  name: 'distanceLimit',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchDistanceLimit.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDistanceLimit.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.distance = Number(action.payload);
      })
      .addCase(fetchDistanceLimit.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'An error occurred';
      });
  },
});

export default distanceLimitSlice.reducer;
