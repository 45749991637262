export const images: any[] = [
  {
    src: 'https://images.qa.reachamy.com/20240521063354_video-thumb-1716273234849.png',
    original: 'https://images.qa.reachamy.com/20240521063353_file_example_MP4_480_1_5MG.mp4',

    caption: 'Boats (Jeshu John - designerspics.com)',
  },
  {
    src: 'https://images.qa.reachamy.com/20240521063355_plastic-waste-hands-with-protective-glove.jpeg',
    original: 'https://file-examples.com/storage/fe83e1f11c664c2259506f1/2020/03/file_example_WEBM_480_900KB.webm',

    caption: 'Red Apples with other Red Fruit (foodiesfeed.com)',
  },
  {
    src: 'https://images.qa.reachamy.com/20240521063459_relative-poverty-cover.jpg',
    original: 'https://images.qa.reachamy.com/20240521063459_relative-poverty-cover.jpg',

    caption: '37H (gratispgraphy.com)',
  },
];
