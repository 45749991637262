import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const OrganisedIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="25.625" height="23" viewBox="0 0 25.625 23">
    <path
      id="diagram-project-1-svgrepo-com"
      d="M9.563,7.281h10.5m-10.887,2.9,6.008,8.655M5.1,10.562H7.462a3.261,3.261,0,0,0,1.383-.143,1.313,1.313,0,0,0,.574-.574,3.261,3.261,0,0,0,.143-1.383V6.1a3.261,3.261,0,0,0-.143-1.383,1.313,1.313,0,0,0-.574-.574A3.261,3.261,0,0,0,7.462,4H5.1a3.261,3.261,0,0,0-1.383.143,1.313,1.313,0,0,0-.574.574A3.261,3.261,0,0,0,3,6.1V8.462a3.261,3.261,0,0,0,.143,1.383,1.313,1.313,0,0,0,.574.574A3.261,3.261,0,0,0,5.1,10.562ZM16.912,25h2.362a3.26,3.26,0,0,0,1.383-.143,1.312,1.312,0,0,0,.574-.574,3.26,3.26,0,0,0,.143-1.383V20.538a3.26,3.26,0,0,0-.143-1.383,1.312,1.312,0,0,0-.574-.574,3.26,3.26,0,0,0-1.383-.143H16.912a3.26,3.26,0,0,0-1.383.143,1.312,1.312,0,0,0-.574.574,3.26,3.26,0,0,0-.143,1.383V22.9a3.26,3.26,0,0,0,.143,1.383,1.312,1.312,0,0,0,.574.574A3.26,3.26,0,0,0,16.912,25Zm5.25-14.438h2.363a3.261,3.261,0,0,0,1.383-.143,1.312,1.312,0,0,0,.574-.574,3.261,3.261,0,0,0,.143-1.383V6.1a3.261,3.261,0,0,0-.143-1.383,1.312,1.312,0,0,0-.574-.574A3.261,3.261,0,0,0,24.525,4H22.162a3.261,3.261,0,0,0-1.383.143,1.312,1.312,0,0,0-.574.574A3.261,3.261,0,0,0,20.062,6.1V8.462a3.261,3.261,0,0,0,.143,1.383,1.312,1.312,0,0,0,.574.574A3.261,3.261,0,0,0,22.162,10.562Z"
      transform="translate(-2 -3)"
      fill="none"
      stroke="#707070"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>,
  'OrganisedIcon'
);
export default OrganisedIcon;
