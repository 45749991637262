// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#demo-multiple-chip {
  font-size: 0.75rem;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/ui/settings/settings-body/settings-panels/settings-profile/settings-profile-intrests/settings-profile-intrests-list.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ","sourcesContent":["#demo-multiple-chip {\n    font-size: 0.75rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
