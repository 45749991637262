import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const SelectedGoodIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
    <g id="Group_290" data-name="Group 290" transform="translate(10660 -1606)">
      <g id="Group_6123" data-name="Group 6123" transform="translate(-10685 1495)">
        <circle id="Ellipse_257" data-name="Ellipse 257" cx="22.5" cy="22.5" r="22.5" transform="translate(25 111)" fill="#EEEBFF" />
      </g>
      <g id="Group_6122" data-name="Group 6122" transform="translate(-19442.75 18110.25)">
        <g id="Group_6061" data-name="Group 6061" transform="translate(8795 -16492)">
          <g id="Group_4947" data-name="Group 4947">
            <g id="Group_4946" data-name="Group 4946">
              <g id="Group_4943" data-name="Group 4943">
                <circle
                  id="Ellipse_243"
                  data-name="Ellipse 243"
                  cx="10.249"
                  cy="10.249"
                  r="10.249"
                  transform="translate(0 20.498) rotate(-90)"
                  fill="#ffd05d"
                />
              </g>
              <g id="Group_4944" data-name="Group 4944" transform="translate(1.613 1.501)">
                <path
                  id="Path_3534"
                  data-name="Path 3534"
                  d="M120.112,742.414a10.25,10.25,0,0,1-14,14.256,10.25,10.25,0,1,0,14-14.256Z"
                  transform="translate(-106.113 -742.414)"
                  fill="#fdc453"
                />
              </g>
              <g id="Group_4945" data-name="Group 4945" transform="translate(0.685 0.757)">
                <path
                  id="Path_3535"
                  data-name="Path 3535"
                  d="M99.105,746.795a9.516,9.516,0,0,1,17.059-5.8,9.516,9.516,0,1,0-15.794,10.544,9.472,9.472,0,0,1-1.265-4.744Z"
                  transform="translate(-98.397 -736.221)"
                  fill="#ffd77f"
                />
              </g>
            </g>
            <path
              id="Path_3536"
              data-name="Path 3536"
              d="M138.781,827.334a.338.338,0,0,0-.55.369,5.137,5.137,0,0,0,9.5.094.338.338,0,0,0-.546-.373C145.686,828.879,142.5,830.948,138.781,827.334Z"
              transform="translate(-132.733 -815.535)"
              fill="#5e4939"
            />
          </g>
          <g id="Group_4951" data-name="Group 4951" transform="translate(5.718 7.142)">
            <g id="Group_4950" data-name="Group 4950">
              <g id="Group_4948" data-name="Group 4948">
                <path
                  id="Path_3537"
                  data-name="Path 3537"
                  d="M141.766,790.6c0,.7-.338,1.273-.755,1.273s-.755-.57-.755-1.273.338-1.273.755-1.273S141.766,789.894,141.766,790.6Z"
                  transform="translate(-140.256 -789.324)"
                  fill="#303030"
                />
              </g>
              <g id="Group_4949" data-name="Group 4949" transform="translate(7.552)">
                <path
                  id="Path_3538"
                  data-name="Path 3538"
                  d="M204.57,790.6c0,.7-.338,1.273-.755,1.273s-.755-.57-.755-1.273.338-1.273.755-1.273S204.57,789.894,204.57,790.6Z"
                  transform="translate(-203.06 -789.324)"
                  fill="#303030"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  'SelectedGoodIcon'
);

export default SelectedGoodIcon;
