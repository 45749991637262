import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const SomethingWentWrong = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="154.574" height="218.983" viewBox="0 0 154.574 218.983">
        <g id="Group_5798" data-name="Group 5798" transform="translate(-45.182 -44.021)">
            <g id="Group_5797" data-name="Group 5797" transform="translate(49.048 44.657)">
                <g id="Group_5796" data-name="Group 5796" transform="translate(0 0)">
                    <g id="Group_5784" data-name="Group 5784" transform="translate(39.505 95.899)">
                        <path id="Path_4418" data-name="Path 4418" d="M83.974,124.917c.388,2.1-2.107,34.385-3.821,36.913-2.52,19.776,1.768,75.094,1.768,75.094s21.9,5.752,44.624,5.752,46.287-5.752,46.287-5.752l4.461-87.672s-6.427-23.778-5.651-26.87c-3.365-1.754-10.637-2.153-10.637-2.153s-.907,10.131-34.3,10.131-28.148-6.038-28.923-7.977C94.862,122.643,83.974,124.917,83.974,124.917Z" transform="translate(-79.36 -120.228)" fill="#674bff" />
                    </g>
                    <g id="Group_5787" data-name="Group 5787" transform="translate(50.774)">
                        <path id="Path_4419" data-name="Path 4419" d="M113.2,46.975S94.73,48.114,93.373,69.428c-.964,15.128-11.052,67.418,0,73.078,40.988,4.994,6.207-85.343,21.182-91.71C120.372,46.833,113.2,46.975,113.2,46.975Z" transform="translate(-88.24 -44.034)" fill="#292d34" />
                        <path id="Path_4420" data-name="Path 4420" d="M104.409,103.15s3.83,11.779,1.7,16.626-2.991,8.976-11.523,9.17,9.915,18.441,28.529,19.8c19.583-6.2,35.222-5.975,37.222-19.8-16.614-1.608-24.176-5.1-24.176-5.1l3.247-28.927Z" transform="translate(-87.11 -31.137)" fill="#ffb27d" />
                        <path id="Path_4421" data-name="Path 4421" d="M113.214,119.437c8.879,0,17.081-5.709,22.457-13.854l1.2-10.662-35,8.229s2.464,7.587,2.336,13.088A13.979,13.979,0,0,0,113.214,119.437Z" transform="translate(-84.573 -31.137)" fill="#fca86e" />
                        <path id="Path_4422" data-name="Path 4422" d="M113.735,48.908c-5.379,0-19.63,10.179-19.195,33.446s6.981,41.008,20.649,41.008,25.739-13.524,28.357-28.648S134.53,41.2,113.735,48.908Z" transform="translate(-86.548 -43.786)" fill="#ffb27d" />
                        <path id="Path_4423" data-name="Path 4423" d="M135.725,64.876s-26.365-2.544-30.339-.218a4,4,0,0,0-1.227,1.2,3.529,3.529,0,0,0-.8-.662c-3.974-2.327-10.748-.287-10.748-.287s.121,21.042,2.173,21.914,4.5-1.173,8.576-4a4.733,4.733,0,0,0,1.212-1.3,4.2,4.2,0,0,0,.815.76c4.071,2.825,28.53,2.557,30.582,1.685S135.725,64.876,135.725,64.876Z" transform="translate(-87.064 -39.541)" fill="#f79a79" opacity="0.53" />
                        <path id="Union_18" data-name="Union 18" d="M72.37,123.927c-.338-1.915-.792-4.261-1.314-6.887a77.584,77.584,0,0,1,1.864,9.6A21.549,21.549,0,0,1,72.37,123.927Zm-1.832-8.9c-1.631-6.04-3.968-12.081-6.6-11.578,1.441-3-.161-1.027-3.064,2.131a5.906,5.906,0,0,1-.755,1.764,16.052,16.052,0,0,0,3.937-1.208,29.142,29.142,0,0,1-7.55,3.915c-4.028,3.782-8.7,7.028-11.764,4.861-12-8.49-6.107-20.5-3.634-26.757.53-1.34,1.071-2.643,1.594-4.222-9.241-6.1-3.913-14.084-1.594-18.37,2.222-4.11,4.64-7.731,5.105-27.818A32.638,32.638,0,0,0,26.981,9.888c-1.369-.483-2.633-.869-3.714-1.168-1.777-.329-3.108-.168-3.684.473C15.221,9.484,4.007,19.032,3.1,31.442S1.978,51.51,1.978,51.51L0,25.335,5.236,9.921,15.512,4.686l.775.731c-.284-1.259.7-2.806,5.042-4.367C26.492-.807,46.34-2.73,58.7,19.228s5.962,46.1,5.236,54.095c-.615,6.759,4.274,29.4,7.12,43.719C70.893,116.376,70.719,115.7,70.537,115.028Z" transform="translate(6.005)" fill="#292d34" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" />
                    </g>
                    <g id="Group_5795" data-name="Group 5795" transform="translate(0 11.945)">
                        <g id="Group_5788" data-name="Group 5788" transform="translate(73.036 5.931)">
                            <path id="Path_4429" data-name="Path 4429" d="M168.624,127.983c-1.846-3.57-10.79-5.561-16.544-6.058.108,5.77,5.492,29.707,5.492,29.707l-1.692,27.429c.992,1.855,2.112,2.744,3.377,2.284,8.859-3.223,8.4-23.922,9.366-30.708S170.916,132.412,168.624,127.983Z" transform="translate(-94.598 -41.749)" fill="#ffb699" />
                            <path id="Path_4430" data-name="Path 4430" d="M120.822,59.638s.658-2.011,4.506,0,10.887,8.044,10.887,8.044,3.878,6.5,4.944,8.726,1.745,7.658,1.648,10.179,1.745,16.093,3.2,17.548,2.714,7.368,2.714,7.368,25.593,57.1,27.047,63.6,1.26,16.869-.97,20.068-11.827,9.5-17.353,7.853-11.536-28.986-11.439-39.457-9.21-49.636-11.246-51.865S124,102.584,123.224,99.287a18.188,18.188,0,0,0-5.719-9.113c-2.424-1.841-9.328-5.913-10.384-6.108s.495-3.684,2.822-3.1,11.923,4.169,13.766,4.556,4.848-.679,4.75-1.551,1.164-7.466,1.648-10.276a35.065,35.065,0,0,0,.388-6.011s-2.606-2.1-2.606-2.968C126.727,64.618,120.822,59.638,120.822,59.638Z" transform="translate(-106.783 -58.745)" fill="#fca86e" />
                        </g>
                        <g id="Group_5789" data-name="Group 5789" transform="translate(0 19.426)">
                            <path id="Path_4431" data-name="Path 4431" d="M84.75,161.577c1.627-7.748,3.675-35.8,3.334-37.654,0,0-9.413,3.442-12.316,4.915-4.072,2.067-16.48,61.592-9.7,56.744s18.032-21.909,18.032-21.909Z" transform="translate(-43.964 -54.707)" fill="#ffb699" />
                            <path id="Path_4432" data-name="Path 4432" d="M81,69.379c-1.26,4.265-.97,10.665-.873,11.925s-3.78,23.073-5.235,24.527-5.719,17.837-5.719,17.837S42.9,179.411,49.2,194.341s20.357,7.368,21.133,5.913,4.848-7.853,5.915-25.787,7.27-53.319,9.015-57.294a49.952,49.952,0,0,1,4.266-7.562s10.373-9.21,11.439-14.639c2.909-3.393,3.782-4.653,3.782-4.653s8.919-7.853,12.8-8.045c2.23-.97.581-3.587-1.358-3.782s-10.082,1.454-13.184,3.975a43.31,43.31,0,0,0-4.653,4.266s-2.909-1.939-5.042-1.843a5.276,5.276,0,0,1,1.551-5.138Z" transform="translate(-48.229 -69.379)" fill="#fca86e" />
                        </g>
                        <g id="Group_5794" data-name="Group 5794" transform="translate(28.925 0)">
                            <path id="Path_4433" data-name="Path 4433" d="M90.814,61.958s6.774-2.042,10.748.286,4.072,14.807,0,17.631a24.7,24.7,0,0,1-8.468,4.23C90.964,84.761,90.814,61.958,90.814,61.958Z" transform="translate(-65.699 -52.172)" fill="#1d1d1d" />
                            <path id="Path_4434" data-name="Path 4434" d="M115.771,63.787S96.595,59.443,92.62,61.77s6.647,14.757,10.718,17.582,15.578,2.629,17.63,1.758S115.771,63.787,115.771,63.787Z" transform="translate(-65.448 -52.184)" fill="#1d1d1d" />
                            <g id="Group_5791" data-name="Group 5791" transform="translate(0 2.437)">
                                <path id="Path_4437" data-name="Path 4437" d="M88.832,59.132l11.75,9.007s6.651,11.488-5.084,16.5a60.841,60.841,0,0,1-13.619-2.1Z" transform="translate(-68.102 -55.146)" fill="#1d1d1d" />
                                <g id="Group_5790" data-name="Group 5790" transform="translate(0 0)">
                                    <circle id="Ellipse_257" data-name="Ellipse 257" cx="12.41" cy="12.41" r="12.41" transform="translate(2.576 2.576)" fill="#292d34" />
                                    <circle id="Ellipse_258" data-name="Ellipse 258" cx="10.596" cy="10.596" r="10.596" transform="translate(0 14.986) rotate(-45)" fill="#575a60" />
                                    <path id="Path_4438" data-name="Path 4438" d="M75.53,71.881C75.226,70.9,74.754,70.408,75.53,71.881Z" transform="translate(-69.924 -51.967)" opacity="0.22" />
                                </g>
                            </g>
                            <g id="Group_5793" data-name="Group 5793" transform="translate(34.839)">
                                <path id="Path_4439" data-name="Path 4439" d="M117.579,58.329l10.759,8.634s8.238,12.207-3.88,17.385A62.879,62.879,0,0,1,110.4,82.18Z" transform="translate(-95.27 -52.925)" fill="#1d1d1d" />
                                <g id="Group_5792" data-name="Group 5792" transform="translate(0)">
                                    <path id="Path_4443" data-name="Path 4443" d="M12.814,0A12.814,12.814,0,1,1,0,12.814,12.814,12.814,0,0,1,12.814,0Z" transform="translate(0 9.868) rotate(-22.647)" fill="#292d34" />
                                    <circle id="Ellipse_260" data-name="Ellipse 260" cx="10.942" cy="10.942" r="10.942" transform="translate(2.451 10.87) rotate(-22.625)" fill="#575a60" />
                                    <path id="Path_4440" data-name="Path 4440" d="M104.143,71.493C103.831,70.475,103.343,69.972,104.143,71.493Z" transform="translate(-97.069 -49.643)" fill="#292d34" opacity="0.22" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
            <path id="Path_4441" data-name="Path 4441" d="M75.714,98.1l14.639,3.78-9.5,33.9,12.316-4.915,4.406,35.555S72.751,207.249,60.4,201.284s-14.053-5.851-15.191-14.725S75.714,98.1,75.714,98.1Z" transform="translate(0 14.377)" fill="#674bff" />
            <path id="Path_4442" data-name="Path 4442" d="M128.507,105.693l11.656-11.18,18.7,39.678-4.6-5.855s25.045-3.732,22.957,18.341-.006,43.506-11.713,46.989-16.7,7.82-21.638-4.827S128.507,105.693,128.507,105.693Z" transform="translate(22.414 13.411)" fill="#674bff" />
        </g>
    </svg>,

    'SomethingWentWrong'
);

export default SomethingWentWrong;
