import React, { useState, useMemo, useEffect } from 'react';
import { GoogleMap, useLoadScript, Marker, Libraries } from '@react-google-maps/api';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { apiKeys } from 'app/utils/data/constants/api-keys';
import CommonSearchBar from 'app/components/common/search-bar/common-search-bar';
import { useAppDispatch } from 'app/config/store';
import { useMediaQuery, useTheme } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { appConstants } from 'app/utils/data/constants/constants';
import { Storage } from 'react-jhipster';
interface LocationPickerGmapsProps {
  onSelectLocation: (location: any) => void;
  formik: any;
  handleLocationFromSearchBox?: (location: string) => void;
  defaultLatitude?: number;
  defaultLongitude?: number;
  mapHeight?: string;
  mapWidth?: string;
  mapId: string;
}
const LocationPickerGmaps = ({
  onSelectLocation,
  formik,
  handleLocationFromSearchBox,
  defaultLatitude = Number(JSON.parse(localStorage.getItem('currentLatitude'))),
  defaultLongitude = Number(JSON.parse(localStorage.getItem('currentLongitude'))),
  mapHeight = '400px',
  mapWidth = '500px',
}: any) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKeys.GMAP_API_Key,
    libraries: appConstants.mapLibraries as Libraries,
  });
  if (!isLoaded) return <div>Loading....</div>;

  return (
    <div>
      <GMaps
        mapWidth={mapWidth}
        mapHeight={mapHeight}
        defaultLatitude={defaultLatitude}
        defaultLongitude={defaultLongitude}
        formik={formik}
        handleLocationFromSearchBox={handleLocationFromSearchBox}
        onSelectLocation={onSelectLocation}
      />
    </div>
  );
};

const GMaps = ({ mapWidth, mapHeight, defaultLongitude, defaultLatitude, formik, handleLocationFromSearchBox, onSelectLocation }: any) => {
  const center = useMemo(() => ({ lat: defaultLatitude, lng: defaultLongitude }), [defaultLatitude, defaultLongitude]);
  const [selected, setSelected] = useState({ lat: defaultLatitude, lng: defaultLongitude });
  const [markers, setMarkers] = useState([{ lat: defaultLatitude, lng: defaultLongitude }]);
  const matchesXs = useMediaQuery('(max-width:450px)');

  const mapContainerStyle = {
    width: matchesXs ? '367px' : mapWidth,
    height: mapHeight,
    borderRadius: '16px',
  };

  const handleMapClick = (e: google.maps.MapMouseEvent) => {
    const lat = e.latLng?.lat();
    const lng = e.latLng?.lng();
    if (lat && lng) {
      setMarkers(prev => [...prev, { lat, lng }]);
    }
  };

  const handleMarkerDragEnd = async (e: google.maps.MapMouseEvent, index: number) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    if (lat && lng) {
      const updatedMarkers = [...markers];
      updatedMarkers[index] = { lat, lng };
      setMarkers(updatedMarkers);

      try {
        const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKeys.GMAP_API_Key}`);

        if (response.ok) {
          const data = await response.json();

          if (data.results && data.results.length > 0) {
            const compoundCode = data?.plus_code?.compound_code || '';
            const locationParts = compoundCode
              .split(' ')
              .slice(1)
              .join(' ')
              .split(',')
              .map(part => part.trim());

            const country = locationParts.pop() || '';
            const state = locationParts.pop() || '';
            const city = locationParts.pop() || '';
            // Update Formik field values
            formik.setFieldValue('latitude', lat);
            formik.setFieldValue('longitude', lng);
            formik.setFieldValue('place', compoundCode);
            formik.setFieldValue('city', city);
            formik.setFieldValue('state', state);
            formik.setFieldValue('country', country);
          } else {
            console.error('No address found for the provided coordinates.');
          }
        } else {
          console.error('Geocoding API error:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching geocoding data:', error);
      }
    }
  };

  return (
    <>
      <PlacesAutocomplete setSelected={setSelected} formik={formik} handleLocationFromSearchBox={handleLocationFromSearchBox} />
      <div>
        <GoogleMap zoom={14} center={selected} mapContainerStyle={mapContainerStyle} onClick={handleMapClick}>
          {markers.map((marker, index) => (
            <Marker key={index} position={marker} draggable onDragEnd={e => handleMarkerDragEnd(e, index)} />
          ))}
        </GoogleMap>
      </div>
    </>
  );
};
const PlacesAutocomplete = ({ setSelected, formik, handleLocationFromSearchBox }) => {
  const [sessionToken, setSessionToken] = useState(null);

  const x = new google.maps.places.AutocompleteSessionToken();
  const checkSessionTokenValidity = async () => {
    const cachedSessionToken = localStorage.getItem('sessionToken');

    // If the cached sessionToken is not valid, generate a new one.
    if (!cachedSessionToken) {
      const newSessionToken = new google.maps.places.AutocompleteSessionToken();
      setSessionToken(newSessionToken);
      Storage.session.set('sessionToken', newSessionToken);
    } else {
      // If the cached sessionToken is valid, use it.
      setSessionToken(cachedSessionToken);
    }
  };
  useEffect(() => {
    checkSessionTokenValidity();
  }, []);
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 1000,
    initOnMount: true,
    cache: 86400,
    cacheKey: 'reachamy',
    requestOptions: {
      sessionToken,
    },
  });
  const startNewSearchSession = () => {
    const newSessionToken = new google.maps.places.AutocompleteSessionToken();
    setSessionToken(newSessionToken);
    clearSuggestions();
  };

  const handleSelect = async address => {
    setValue(address, false);
    startNewSearchSession();
    handleLocationFromSearchBox(address);

    const results = await getGeocode({ address });
    console.log('city', results);
    const { lat, lng } = getLatLng(results[0]);
    setSelected({ lat, lng });
    formik.setFieldValue('latitude', lat);
    formik.setFieldValue('longitude', lng);
    const addressComponents = results[0].address_components;

    let city = '';
    let state = '';
    let country = '';

    addressComponents.forEach(component => {
      const types = component.types;

      if (types.includes('locality')) {
        city = component.long_name;
      }

      if (types.includes('administrative_area_level_1')) {
        state = component.long_name;
      }

      if (types.includes('country')) {
        country = component.long_name;
      }
    });
    console.log('countries', country, state, city);
    formik.setFieldValue('city', city);
    formik.setFieldValue('state', state);
    formik.setFieldValue('country', country);
  };
  const handleChange = async input => {
    setValue(input);
  };
  const theme = useTheme();
  return (
    <>
      <div style={{ position: 'absolute', top: '28%', left: '6%', zIndex: 1, marginTop: '10px' }}>
        <CommonSearchBar
          value={value}
          onChange={handleChange}
          clear={() => {
            clearSuggestions();
            setValue('');
          }}
        />
        {ready && (
          <ul
            onSelect={handleSelect}
            style={{
              width: '300px',
              listStyle: 'none',
              // border: '1px solid #ccc',
              borderRadius: '4px',
              backgroundColor: theme.palette.mode === 'dark' ? '#1F1F26' : '#fff',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              overflowY: 'scroll',
            }}
          >
            {status === 'OK' &&
              data?.map(({ places_id, description }: any) => (
                <li key={places_id} onClick={() => handleSelect(description)} role="button" color="red">
                  {description}
                </li>
              ))}
          </ul>
        )}
      </div>
    </>
  );
};
export default LocationPickerGmaps;
