import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import BlogComments from './blog-comments';
import BlogCommentsDetail from './blog-comments-detail';
import BlogCommentsUpdate from './blog-comments-update';
import BlogCommentsDeleteDialog from './blog-comments-delete-dialog';

const BlogCommentsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<BlogComments />} />
    <Route path="new" element={<BlogCommentsUpdate />} />
    <Route path=":id">
      <Route index element={<BlogCommentsDetail />} />
      <Route path="edit" element={<BlogCommentsUpdate />} />
      <Route path="delete" element={<BlogCommentsDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default BlogCommentsRoutes;
