import React from 'react';
import { Dialog, useTheme } from '@mui/material';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import dayjs from 'dayjs';

const CustomRangeModal = ({ open, onClose, handleData, handleDateChange }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const handleCancel = () => {
    onClose();
  };

  // Extract dates from handleData
  const activeDates = Object.keys(handleData).map(dateString => new Date(dateString));

  // Check if a given date is in activeDates
  const isDateActive = date => {
    return activeDates.some(
      activeDate =>
        date.getFullYear() === activeDate.getFullYear() &&
        date.getMonth() === activeDate.getMonth() &&
        date.getDate() === activeDate.getDate()
    );
  };

  const hasData = date => {
    const dateString = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    return handleData[dateString]?.length > 0;
  };

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="xs">
      <style>
        {`.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthXs.css-1qxmuh4-MuiPaper-root-MuiDialog-paper {
              border-radius:27px !important;
            }`}

        {`
            button.react-calendar__navigation__label {
                       pointer-events: none;
          }`}
        {`.activeDate {
            color: white !important;
            border-radius:48% !important;
            padding :10px;
            background:#674BFF !important;
        }`}

        {`.dateWithDot {
            color: white !important;
            border-radius:48% !important;
            padding :10px;
            background:#674BFF !important;
             position: relative;
        }`}

        {`.dateWithDot::before {
          content: '';
          width: 6px;
          height: 6px;
          background-color: white;
          border-radius: 50%;
          position: absolute;
          bottom: 4px;
          left: 50%;
          transform: translateX(-50%);
        }
        `}

        {`.dimmedActiveDate {
            background-color: #674bff;          /* Red circle */
            border-radius: 50%;             /* Make it circular */
            position: relative; 
            border: 2px solid red !important;
            color:white;
             pointer-events: none; /* Disables hover and click events */
        }`}
        {`.dimmedActiveDate::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 120%;                    /* Width to cover the circle diagonally */
            height: 1px;                   /* Thickness of the line */
            background-color: red;        /* White color for the line */
            transform: translate(-50%, -50%) rotate(45deg); /* Center and rotate the line */
            }`}

        {`.dimmedActiveDateParticipant  {
             background: #56C02B !important;
              border-radius: 50%;             /* Make it circular */
            position: relative; 
            border: 2px solid red !important;
            color:white;
             pointer-events: none; /* Disables hover and click events */
       }`}

        {`.dimmedActiveDateParticipant abbr::before {
         content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 120%;                    /* Width to cover the circle diagonally */
            height: 1px;                   /* Thickness of the line */
            background-color: red;        /* White color for the line */
            transform: translate(-50%, -50%) rotate(45deg); /* Center and rotate the line */
             
       }`}

        {`.activeDateParticipant abbr {
            color: #ffffff !important;
            background: #56C02B;
            padding: 20px;
         }`}

        {`.react-calendar__tile {
             border-radius: 12px;
        }`}
        {`.react-calendar__tile:disabled {
        background-color: unset !important
        }`}

        {`.react-calendar {
      width: 100%;
      max-width: 100%;
      background: ${isDarkMode ? '#2f2f2f' : 'white'};
      border:unset;
      font-family: Arial, Helvetica, sans-serif;
      line-height: 2.125em;
      padding: 10px;
    }`}
        {`.react-calendar button {
      height: 42px;
      max-width: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 6px 17px;
      border-radius:48% !important;
    }`}
        {`.css-1ec1dly-MuiPaper-root-MuiDialog-paper { 
      border-radius: 15px;
    }`}
        {`.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
    background-color: unset;
    }`}
        {`.react-calendar__navigation button{
    background-color: unset;
     color: ${isDarkMode ? 'white' : 'black'};`}

        {`react-calendar__navigation__label__labelText {
    pointer-events: none;
    }`}
        {`.react-calendar__navigation__label {
  pointer-events: none !important;
}`}
        {`button.react-calendar__navigation__label {
    pointer-events: none;
}`}
      </style>
      <Calendar
        tileClassName={({ date, view }) => {
          if (view === 'month') {
            const isPastDate = date.getTime() < new Date().setHours(0, 0, 0, 0);

            if (isDateActive(date)) {
              if (hasData(date)) {
                return isPastDate ? 'dimmedActiveDate' : 'dateWithDot';
              }
              return isPastDate ? 'dimmedActiveDate' : 'activeDate';
            } else if (hasData(date)) {
              return 'dateWithDot';
            }
          }

          return undefined;
        }}
        tileDisabled={({ date, view }) => {
          if (view === 'month') {
            return !isDateActive(date);
          }
          return false;
        }}
        onClickDay={date => {
          const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(
            2,
            '0'
          )}`;
          handleDateChange(dayjs(formattedDate));
          onClose();
        }}
      />
    </Dialog>
  );
};

export default CustomRangeModal;
