import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const AmyIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="600" height="600" viewBox="0 0 600 315">
    <defs>
      <clipPath id="clip-iPhone_14_Pro_1">
        <rect width="600" height="315" />
      </clipPath>
    </defs>
    <g id="iPhone_14_Pro_1" data-name="iPhone 14 Pro – 1" clipPath="url(#clip-iPhone_14_Pro_1)">
      <text
        id="ReachAmy"
        transform="translate(40 197)"
        fill="#674bff"
        fontSize="103"
        fontFamily="Manrope-SemiBold, Manrope"
        fontWeight="600"
      >
        <tspan x="0" y="0">
          Reach
        </tspan>
        <tspan y="0" fill="#ffce00">
          AMY
        </tspan>
      </text>
    </g>
  </svg>,
  'AmyIcon'
);

export default AmyIcon;
