// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.example {
  background-color: #eee;
  width: 200px;
  height: 100px;
  border: 1px dotted black;
  overflow-y: scroll;
}

.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none;
  scrollbar-width: none;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/layout/event-calender-page/event-calender-page.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,YAAA;EACA,aAAA;EACA,wBAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA,4CAAA;AACA;EACE,wBAAA;EACA,qBAAA;AACF","sourcesContent":[".example {\n  background-color: #eee;\n  width: 200px;\n  height: 100px;\n  border: 1px dotted black;\n  overflow-y: scroll;\n}\n\n.example::-webkit-scrollbar {\n  display: none;\n}\n\n/* Hide scrollbar for IE, Edge and Firefox */\n.example {\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
