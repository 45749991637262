import dayjs from 'dayjs';

export interface IActivity {
  id?: number;
  activityNumber?: number | null;
  categoryID?: number | null;
  header?: string | null;
  details?: string | null;
  ageLimit?: string | null;
  planningForMicrofunding?: boolean | null;
  amountToRaise?: number | null;
  postAsOrganisation?: boolean | null;
  makeAsUrgentNeed?: boolean | null;
  makeThisPrivateActivity?: boolean | null;
  startDate?: string | null;
  createdBy?: number | null;
  createdAt?: string | null;
  modifiedBy?: number | null;
  modifiedAt?: string | null;
}

export const defaultValue: Readonly<IActivity> = {
  planningForMicrofunding: false,
  postAsOrganisation: false,
  makeAsUrgentNeed: false,
  makeThisPrivateActivity: false,
};
