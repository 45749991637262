// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 600px) {
  .mob-100 {
    width: 100% !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/ui/petition/petition-details-sidebar.scss"],"names":[],"mappings":"AAAA;EACI;IACI,sBAAA;EACN;AACF","sourcesContent":["@media only screen and (max-width: 600px) {\n    .mob-100 {\n        width: 100% !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
