/* eslint-disable no-console */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiKeys } from 'app/utils/data/constants/api-keys';

export interface StateType {
    id: number
    iso2: string
    name: string
}

export interface stateState {
    cityState: StateType[] | null;
    loading: boolean;
    error: string | null;
}

export const initialState: stateState = {
    cityState: null,
    loading: false,
    error: null,
};




export const fetchStatesByCountry = createAsyncThunk<any, any, { rejectValue: string }>(
    'state/fetchStatesByCountry',
    async (id, thunkAPI) => {
      try {
            const API_KEY = apiKeys.LOCATION_FILTER_KEY;
            const headers = {
                'X-CSCAPI-KEY': API_KEY,
            };
            const response = await axios.get(
                `https://api.countrystatecity.in/v1/countries/${id.iso2}/states`, { headers,}
            );
            return response.data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response?.data?.message || 'An error occurred');
      }
    }
  );

const stateSlice = createSlice({
    name: 'stateReducer',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchStatesByCountry.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchStatesByCountry.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.cityState = [action.payload];
            })
            .addCase(fetchStatesByCountry.rejected, (state, action) => {
                state.loading = false;
                state.error ='An error occurred';
                state.cityState = null;

            });
    },
});

export default stateSlice.reducer;