import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const FlorafaunaIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="17.374" height="23.645" viewBox="0 0 17.374 23.645" style={{ fontSize: 'larger' }}>
    <g id="Flora_Fauna" data-name="Flora &amp; Fauna" transform="translate(-61.772 0)">
      <g id="Group_6596" data-name="Group 6596" transform="translate(61.772 0)">
        <path
          id="Path_4818"
          data-name="Path 4818"
          d="M77.565,22.83H71.248V20.9A14.306,14.306,0,0,1,73.4,17.936c2.267-.02,3.628-.435,4.525-1.376.867-.91,1.256-2.288,1.224-4.336a.4.4,0,0,0-.395-.4c-3.612,0-5.863.938-5.917,5.542a17.635,17.635,0,0,0-1.584,1.986v-5.49a5,5,0,0,1,1.364-2.447c3.633-.019,5.348-.645,5.348-5.707a.4.4,0,0,0-.395-.408c-3.718,0-5.489.554-5.526,5.548a10.735,10.735,0,0,0-.791,1.035V7.341a8.213,8.213,0,0,1,.118-1.425.852.852,0,0,1-.753-.241,6.236,6.236,0,0,1,.917-2.231.387.387,0,0,1,.547-.116.417.417,0,0,1,.113.565,5.376,5.376,0,0,0-.845,2.163,4.808,4.808,0,0,0,3.089-2.083A4.356,4.356,0,0,0,74.782.282.393.393,0,0,0,74.353,0C72.276.3,70.815,1.188,70.239,2.517a3.552,3.552,0,0,0,.349,3.27c0-.006,0-.011,0-.016a9.091,9.091,0,0,0-.133,1.565v0h0v2.87a8.975,8.975,0,0,0-.8-1l0,0c-.019-1.845-.343-2.965-1.079-3.713a4.3,4.3,0,0,0-3.267-1.01.4.4,0,0,0-.388.408c0,2.887.72,4.822,4.169,4.886l-.054-.058a5.043,5.043,0,0,1,1.412,2.511v5.179a9.553,9.553,0,0,0-.974-1.062c-.078-.076-.148-.144-.213-.211-.052-5.645-2.648-6.76-7.1-6.76a.4.4,0,0,0-.395.408c0,4.542,2.337,6.868,6.937,6.925.072.073.149.15.235.232a7.639,7.639,0,0,1,1.514,1.923V22.83H64.141a.408.408,0,0,0,0,.815H77.565a.408.408,0,0,0,0-.815Zm-2.132-8.12a.39.39,0,0,1,.52.211.413.413,0,0,1-.2.536A5.178,5.178,0,0,0,73.56,17.74a.382.382,0,0,1-.041.053.415.415,0,0,0-.027-.56.386.386,0,0,0-.558.018l-.027.031A5.841,5.841,0,0,1,75.433,14.71Zm-3.116-4.18a20.155,20.155,0,0,1,2.24-2.289.387.387,0,0,1,.554.069.417.417,0,0,1-.067.572,19.486,19.486,0,0,0-2.139,2.192c-.077.088-.139.16-.183.21l.092-.105a.9.9,0,0,1-.552-.585l-.12.138ZM69.1,9.387a.656.656,0,0,1-.268.1l.16.179.049.052-.049-.052L67.4,7.886a.417.417,0,0,1,.022-.576.387.387,0,0,1,.558.023l1.45,1.622A.8.8,0,0,1,69.1,9.387Zm.065,6.641a.387.387,0,0,0-.558-.018.415.415,0,0,0-.027.56.369.369,0,0,1-.035-.043,7.789,7.789,0,0,0-2.99-3.11.414.414,0,0,1-.195-.541.39.39,0,0,1,.524-.2,8.476,8.476,0,0,1,3.3,3.373Z"
          transform="translate(-61.772 0)"
          fill="#676e7b"
        />
      </g>
    </g>
  </svg>,
  'FlorafaunaIcon'
);
export default FlorafaunaIcon;
