import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import toast from 'react-hot-toast';

export interface initialPetitionState {
  petitions: any[];
  loading: boolean;
  error: string | null;
}

const initialPetitionState: initialPetitionState = {
  petitions: [],
  loading: false,
  error: null,
};

export const fetchPetitions = createAsyncThunk<any[], void, { rejectValue: string }>(
  'homePetition/fetchPetitions',
  async (_, { rejectWithValue }) => {
    try {
      const axiosResponse = await axios.get<any[]>('/api/petitions');
      return axiosResponse.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const deletePetitionById = createAsyncThunk<any, { petitionId: string }, { rejectValue: string }>(
  'homePetition/deletePetitionById',
  async ({ petitionId }, { rejectWithValue }) => {
    try {
      const axiosResponse = await axios.delete(`/api/petition/delete/${petitionId}`);
      toast.success(axiosResponse?.data?.message);
      return axiosResponse.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const signPetition = createAsyncThunk<void, { petitionId: string; reason: string }, { rejectValue: string }>(
  'homePetition/signPetition',
  async ({ petitionId, reason }, { rejectWithValue }) => {
    try {
      await axios.post(`/api/petition/signing/${petitionId}`, { reason });
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

// Create the homePetition slice
export const homePetitionSlice = createSlice({
  name: 'homePetition',
  initialState: initialPetitionState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchPetitions.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPetitions.fulfilled, (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.petitions = action.payload;
      })
      .addCase(fetchPetitions.rejected, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deletePetitionById.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deletePetitionById.fulfilled, state => {
        state.loading = false;
      })
      .addCase(deletePetitionById.rejected, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(signPetition.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signPetition.fulfilled, state => {
        state.loading = false;
      })
      .addCase(signPetition.rejected, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default homePetitionSlice.reducer;
