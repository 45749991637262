import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const ProfileLocationIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="15.062" height="21.756" viewBox="0 0 15.062 21.756">
    <path
      id="Icon_ionic-ios-pin"
      data-name="Icon ionic-ios-pin"
      d="M15.406,3.375a7.279,7.279,0,0,0-7.531,6.992c0,5.439,7.531,14.764,7.531,14.764s7.531-9.325,7.531-14.764A7.279,7.279,0,0,0,15.406,3.375Zm0,9.984a2.453,2.453,0,1,1,2.453-2.453A2.453,2.453,0,0,1,15.406,13.359Z"
      transform="translate(-7.875 -3.375)"
      fill="#bac6ce"
    />
  </svg>,
  'ProfileLocationIcon'
);
export default ProfileLocationIcon;
