// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 450px) {
  .faq-mob {
    display: flex !important;
    flex-direction: column !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/ui/faq/faq.scss"],"names":[],"mappings":"AAAA;EACC;IACG,wBAAA;IACI,iCAAA;EACN;AACF","sourcesContent":["@media (max-width: 450px) {\n .faq-mob {\n    display: flex !important;\n        flex-direction: column !important;\n }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
