import React, { useState } from 'react';
import { Button, Card, CardActions, CardContent, CardMedia, ListItem, ListItemText, Popover, Typography, useTheme } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import HTMLReactParser from 'html-react-parser';
import { convertDate, encodedTitle } from 'app/utils/common-utils/common-utils';
import axios from 'axios';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import FeatureDialog from '../../alert-dialog/feature-dialog';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { updatePrevItemId } from 'app/shared/reducers/activity-filter';
import Lightbox from 'yet-another-react-lightbox';
import { GallerySlide } from 'app/components/ui/activity-details-section/activity-details-card/activity-details-card';
import Video from 'yet-another-react-lightbox/plugins/video';
import ImageGrid from 'app/components/ui/grid-layout/ImageGrid';

interface BlogCardProps {
  blog: any;
}
const BlogCard = ({ blog }: BlogCardProps) => {
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const handleImageError = event => {
    event.target.src = 'content/images/no-img.jpg';
  };
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [confirmFeature, setConfirmFeature] = React.useState<boolean>(false);
  const [payload, setPayload] = React.useState<any>();
  const [passId, setPassId] = React.useState<any>();
  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const dispatch = useAppDispatch();

  const openLightbox = image => {
    setSelectedImage(image);
    setLightBoxOpen(true);
  };

  const handleFeatureClick = (event: React.MouseEvent<HTMLButtonElement>, data: any) => {
    setPassId(data?.id);
    handleGetCatalogue(4);
  };

  const [paymentLoading, setPaymentLoading] = useState(false);
  const handlePayment = async () => {
    setPaymentLoading(true);

    let passData = {
      eventId: passId,
      eventType: 4,
      quantity: 1,
      productId: payload?.productId,
    };

    localStorage.setItem('passEventId', passData?.eventId);
    localStorage.setItem('passEventType', JSON.stringify(passData?.eventType));

    try {
      const response = await axios.post<any>('api/payment-service/checkout', passData);
      if (response.status === 201 || response.status === 200) {
        setPaymentLoading(false);
        const checkoutUrl = response?.data?.checkoutUrl;
        if (checkoutUrl) {
          window.location.href = checkoutUrl; // Open checkout URL in current tab
        } else {
          console.error('Checkout URL not found.');
        }
      }
    } catch (error) {
      setPaymentLoading(false);
      console.log(error);
    }
  };

  const handleGetCatalogue = async id => {
    try {
      const response = await axios.get<any>(`api/payment-service/product-catalogue?eventType=${id}`);
      if (response.status === 201 || response.status === 200) {
        setPayload(response.data);
        setConfirmFeature(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const open = Boolean(anchorEl);
  const menuItemId = open ? 'simple-popover' : undefined;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [index, setIndex] = useState(-1);
  const handleLightBoxClick = (index: number) => setIndex(index);
  const handleLightBoxClose = () => setIndex(-1);
  const slides: GallerySlide[] = blog?.eventGallery?.map((img: any) => {
    if (img.type === 'videos') {
      return {
        type: 'video',
        src: img?.link,
        thumbnail: img?.thumbnail,
        title: '',
        sources: [{ src: img?.link, type: 'video/mp4' }],
      };
    } else {
      return {
        type: 'image',
        src: img?.link,
        thumbnail: img?.link,
        title: '',
      };
    }
  });
  return (
    <Card
      sx={{
        width: { xs: '100%', sm: '100%', md: '100%', lg: '100%' },
        boxShadow: 'none',
        borderRadius: '15px',
        cursor: 'pointer',
      }}
    >
      <div style={{ height: '430px', position: 'relative', borderRadius: '15px', overflow: 'hidden' }}>
        {/* <CardMedia
          component="img"
          alt="Blog Image"
          height="430px"
          image={blog?.eventGallery[0].link || 'content/images/no-img.jpg'}
          style={{ objectPosition: '1px 1%', objectFit: 'cover' }}
          onError={handleImageError}
          onClick={() => openLightbox(blog?.eventGallery[0].link ? blog?.eventGallery[0].link : 'content/images/no-img.jpg')}
        /> */}
        <ImageGrid onImageClick={handleLightBoxClick}>
          {blog?.eventGallery?.map(item => (
            <div className={`img_container ${item.type === 'videos' ? 'video-grid' : ''}`}>
              <img
                alt="ig"
                src={item.type === 'videos' ? item?.thumbnail : item?.link}
                style={{ objectPosition: '1px 1%', objectFit: 'cover' }}
              />
            </div>
          ))}
        </ImageGrid>
        {blog?.featured && (
          <div
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              background: '#674BFF',
              color: '#FFFF',
              padding: '5px 10px',
              borderRadius: '0 0 15px 0',
              zIndex: '999',
              fontSize: '12px',
            }}
          >
            <span style={{ fontWeight: 'bold' }}>Featured</span>
          </div>
        )}
        {blog?.category?.category && (
          <Button
            variant="contained"
            size="small"
            style={{
              position: 'absolute',
              bottom: 10,
              left: 10,
              background: '#F6F7F8',
              color: '#900095',
              textTransform: 'capitalize',
              borderRadius: '16px',
            }}
            onClick={() => {
              navigate(`/blog-single/${encodedTitle(blog?.title)}-${blog.id}`);
              dispatch(updatePrevItemId(blog?.id));
            }}
          >
            {blog?.category?.category}
          </Button>
        )}
        <Lightbox
          carousel={{ finite: true }}
          open={index >= 0}
          close={handleLightBoxClose}
          slides={slides}
          plugins={[Video]}
          index={index}
        />
      </div>

      <CardContent sx={{ backgroundColor: 'secondary.dark' }}>
        <div className="d-flex justify-content-between">
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ color: theme.palette.secondary.main }}
            onClick={() => {
              navigate(`/blog-single/${encodedTitle(blog?.title)}-${blog.id}`);
              dispatch(updatePrevItemId(blog?.id));
            }}
          >
            {blog?.title}
          </Typography>

          <span onClick={handleClick}>
            <MoreHorizOutlinedIcon aria-describedby={'menuItemId'} sx={{ cursor: 'pointer', fontSize: '19px', color: '#676E7B' }} />
          </span>
        </div>

        <Typography
          component={'div'}
          variant="body2"
          color="text.secondary"
          sx={{
            color: theme.palette.secondary.light,
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxHeight: '6em',
            lineClamp: 3,
            WebkitLineClamp: 3,
          }}
          onClick={() => {
            navigate(`/blog-single/${encodedTitle(blog?.title)}-${blog.id}`);
            dispatch(updatePrevItemId(blog?.id));
          }}
        >
          {blog?.caption ? blog.caption?.length > 220 ? <>{HTMLReactParser(blog?.caption)}</> : HTMLReactParser(blog?.caption) : null}
        </Typography>
      </CardContent>

      <CardActions sx={{ justifyContent: 'space-between', backgroundColor: 'secondary.dark' }}>
        <div>
          <Button variant="text" sx={{ color: theme.palette.secondary.light, textTransform: 'capitalize' }}>
            {convertDate(blog?.createdAt)}
          </Button>
          {isAuthenticated && (
            <>
              {/* <button
                style={{
                  padding: '3px 12px',
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  backgroundColor: '#674bff',
                  color: 'white',
                  fontSize: '12px',
                  borderRadius: '15px',
                }}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  handleFeatureClick(event, blog);
                }}
              >
                Feature now
              </button> */}

              <Popover
                id={menuItemId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <ListItem sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', alignItems: 'inherit', padding: '8px 16px' }}>
                  <button
                    style={{ padding: 0, background: 'none', border: 'none', cursor: 'pointer' }}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      handleFeatureClick(event, blog);
                    }}
                  >
                    <ListItemText className="text-start">
                      <Typography sx={{ p: 0, fontSize: '14px', cursor: 'pointer', color: isDarkMode ? '#fff' : '#000' }}>
                        Feature blog
                      </Typography>
                    </ListItemText>
                  </button>
                </ListItem>
              </Popover>
            </>
          )}
        </div>

        <Button variant="text" sx={{ color: theme.palette.secondary.light, textTransform: 'capitalize' }}>
          by {blog?.postedBy}
        </Button>
      </CardActions>
      <FeatureDialog
        paymentLoading={paymentLoading}
        agreeAction={handlePayment}
        values={payload}
        open={confirmFeature}
        setFeatureOpen={setConfirmFeature}
      />
    </Card>
  );
};

export default BlogCard;
