import React, { useEffect, useState } from 'react';
import WelcomeUser from '../welcome-user/welcome-user';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { Storage } from 'react-jhipster';
import { useAppDispatch } from 'app/config/store';
import { fetchDistanceLimit } from 'app/shared/reducers/distance-limit';
import InputAdornment from '@mui/material/InputAdornment';
import EventsSlider from './event-slider';

const EventsNearYou = () => {
  const [textFieldDistance, setTextFieldDistance] = useState<number>(0); //textfield
  const [sliderDistance, setSliderDistance] = useState<number | string>(100); //slider
  const [defaultMaxDistance, setDefaultMaxDistance] = useState<number>(100); //default value
  const [sliderDisabled, setSliderDisabled] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  let maxLimitFromStorage: number;
  let defaultDistance: number;

  useEffect(() => {
    if (Storage.session.get('Maxdistance')) {
      const maxDistanceFromStorage = Storage.session.get('Maxdistance');
      const defaultDistanceFromStorage = Storage.session.get('defaultDistance');
      if (maxDistanceFromStorage) {
        maxLimitFromStorage = JSON.parse(maxDistanceFromStorage);
      }
      if (defaultDistanceFromStorage) {
        defaultDistance = JSON.parse(defaultDistanceFromStorage);
      }
      setDefaultMaxDistance(defaultDistance);
      if (Storage.session.get('ActiveField') == 'TextField') {
        setTextFieldDistance(maxLimitFromStorage);
      } else {
        setSliderDistance(maxLimitFromStorage);
      }
    } else {
      const fetchData = async () => {
        try {
          const response = await dispatch(fetchDistanceLimit());
          setDefaultMaxDistance(Number(response.payload));
        } catch (error) {
          console.log(error);
        }
      };
      fetchData();
    }
  }, []);
  const input = document.querySelector('input[name="locationRange"]');

  if (input) {
    input.addEventListener('wheel', function (event) {
      event.preventDefault();
    });
  }

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    setTextFieldDistance(value);
  };
  useEffect(() => {
    if (textFieldDistance > 0) {
      setSliderDisabled(true);
      setSliderDistance(0);
    } else {
      setSliderDisabled(false);
    }
  }, [textFieldDistance]);
  const handleSliderChange = (newValue: number) => {
    console.log('New slider value:', newValue);
  };

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="center" minHeight="100vh" py={5}>
      <WelcomeUser />
      <Stack direction="column" spacing={5} alignItems="center" marginBottom={'16px'} sx={{ px: { xs: 2, md: 0 } }}>
        <Typography sx={{ fontSize: '24px', color: 'secondary.light', fontWeight: 600 }}>Want to know about events near you?</Typography>

        <div className="col-12">
          <div className="col-12 d-flex justify-content-between">
            {' '}
            <p style={{ fontSize: '12px', color: 'secondary.light', marginBottom: '0' }}>0 km/miles</p>
            <p style={{ fontSize: '12px', color: 'secondary.light', marginBottom: '0' }} className="ms-4">
              100 km/miles(approx.)
            </p>
          </div>
          <div className="col-12 w-100 ps-2 pe-2">
            <EventsSlider
              sliderDistance={sliderDistance}
              sliderDisabled={sliderDisabled}
              setSliderDistance={setSliderDistance}
              defaultMaxDistance={defaultMaxDistance}
              onSliderChange={handleSliderChange}
            />
          </div>
        </div>
        <p style={{ fontSize: '14px', color: 'secondary.light' }}> {sliderDistance != '0' ? sliderDistance + ' km/miles' : ''}</p>
        {/* <TextField
          type="number"
          InputProps={{ inputProps: { min: 0, max: 10000 }, endAdornment: <InputAdornment position="end">km</InputAdornment> }}
          name="locationRange"
          fullWidth
          size="small"
          value={textFieldDistance}
          onChange={handleTextFieldChange}
        /> */}
        <Grid item container alignItems="center" justifyContent={'space-between'} my={3}>
          <Button
            onClick={() => {
              if (sliderDisabled) {
                Storage.session.set('Maxdistance', textFieldDistance);
                Storage.session.set('ActiveField', 'TextField');
              } else {
                Storage.session.set('Maxdistance', sliderDistance);
                Storage.session.set('ActiveField', 'Slider');
              }
              navigate('/welcome');
            }}
            variant="outlined"
            sx={{
              textTransform: 'none',
              width: '89px',
              height: '40px',
              borderRadius: '15px',
              border: '1px solid #FFCE00',
              boxShadow: 'none',
              '&:hover': { boxShadow: 'none' },
            }}
          >
            <Typography>Back</Typography>
          </Button>
          <Button
            onClick={() => {
              if (sliderDisabled) {
                Storage.session.set('Maxdistance', textFieldDistance);
                Storage.session.set('ActiveField', 'TextField');
                Storage.session.set('defaultDistance', defaultMaxDistance);
              } else {
                Storage.session.set('Maxdistance', sliderDistance);
                Storage.session.set('ActiveField', 'Slider');
                Storage.session.set('defaultDistance', defaultMaxDistance);
              }

              navigate('/availability');
            }}
            variant="contained"
            sx={{
              textTransform: 'none',
              width: '89px',
              height: '40px',
              borderRadius: '15px',
              border: '1px solid #FFCE00',
              boxShadow: 'none',
              '&:hover': { backgroundColor: 'primary.main', boxShadow: 'none' },
            }}
          >
            <Typography>Next</Typography>
          </Button>
        </Grid>
      </Stack>
    </Grid>
  );
};

export default EventsNearYou;
