import React, { useState, useEffect } from 'react';

import { useAppSelector } from 'app/config/store';

import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { singleImageType } from 'app/shared/reducers/image-source';
import axios from 'axios';
import { Storage } from 'react-jhipster';
import { apiEndPoints } from 'app/utils/data/constants/api-end-points';

interface WelcomeUserProps {
  userName?: string;
}

const WelcomeUser = ({ userName }: WelcomeUserProps) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [imageUploaded, setImageUploaded] = useState<boolean>(false);
  const [imageResponse, setImageResponse] = useState<singleImageType>(null);
  const [imageUrl, setImageUrl] = useState<string>(null);

  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const currentUser = useAppSelector(state => state.authentication.account);
  const { firstName, lastName } = currentUser;
  const userNameStyles = { ...theme.typography.h2, color: theme.palette.primary.light };

  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const selectedFile = files[0];

    if (selectedFile && selectedFile.size > 6 * 1024 * 1024) {
      // File size exceeds 6MB, show an error message
      setErrorMessage('File size must be less than 6MB');
      return;
    }

    setSelectedFile(selectedFile);
  };

  const setImage = profilePicObj => {
    setImageUploaded(true);
    setImageUrl(profilePicObj.url);
  };

  useEffect(() => {
    if (selectedFile !== null) {
      const sendFile = async () => {
        try {
          const formData = new FormData();
          formData.append('file', selectedFile as Blob);
          const response = await axios.post<any>(apiEndPoints.imageUpload, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
          if (response.status === 201 || response.status === 200) {
            setImageResponse(response.data);
            setImageUploaded(true);
            Storage.session.set('profilePic', response.data);
            setImage(response.data);
          }
          else {

            setErrorMessage('File size must be less than 6MB');
          }
        } catch (error) {
          console.log(error);
          setErrorMessage('File size must be less than 6MB');
        }
      };
      sendFile();
    }
  }, [selectedFile]);

  useEffect(() => {
    if (Storage.session.get('profilePic')) {
      const profilePicObj = Storage.session.get('profilePic');
      setImageUploaded(true);
      setImageUrl(profilePicObj.url);
    }
  }, []);

  return (
    <Stack direction="column" alignItems="center" justifyContent={'center'} spacing={2}>
      <Stack direction="column" spacing={1} alignItems="center" justifyContent={'center'} sx={{ textAlign: 'center' }}>
        <Typography variant="h2" sx={{ color: theme.textColor.mainHeading }}>
          Welcome aboard
        </Typography>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} alignItems={'center'}>
          <Typography variant={'h5'} sx={userNameStyles}>
            {firstName}
          </Typography>
          <Typography variant={'h5'} sx={userNameStyles}>
            {lastName}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="column" alignItems="center" style={{ marginTop: '42px' }}>
        <Avatar alt="profile pic" src={imageUploaded ? imageUrl : './content/images/login-page/profile_avatar.png'} />

        <IconButton color="primary" aria-label="upload picture" component="label" disableRipple sx={{ gap: 1 }}>
          <input hidden accept="image/*" type="file" name="id_card_id" onChange={handleFileInputChange} />

          <Typography
            sx={{
              color: theme.palette.primary.light,

              textTransform: 'none',

              borderStyle: 'none',

              fontSize: theme.infoText.regular.fontSize,

              fontWeight: theme.infoText.regular.fontWeight,

              fontStyle: theme.infoText.regular.fontStyle,
            }}
          >
            Change
          </Typography>
        </IconButton>
        {errorMessage && (
          <Typography variant="caption" sx={{ color: 'error.main' }}>
            {errorMessage}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default WelcomeUser;
