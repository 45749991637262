import React, { useEffect, useState } from 'react';

import { Carousel } from 'react-responsive-carousel';
import { Avatar, Button, ListItem, ListItemText, Popover, Typography, useMediaQuery, useTheme, Box } from '@mui/material';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { Link, useNavigate } from 'react-router-dom';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { fetchBookmarkedPetition } from 'app/shared/reducers/bookmark-activity';
import { fetchPetitionDetails, fetchPetitionShareToken, trackActivityShare, trackPetitionShare } from 'app/shared/reducers/activity-home';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import ShareIcon from 'app/components/icons/activity-card/share-icon';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import SignPetitionModal from 'app/components/common/modals/petition-sign-modal/sign-petition-modal';
import { convertDateToDay, convertDateToYear, getInitials, shareOnSocialMedia } from 'app/utils/common-utils/common-utils';
import GenericModal from 'app/components/common/modals/generic-modal';
import UpVoteOutlined from 'app/components/icons/activity-card/up-vote-outlined';
import UpVoteFilled from 'app/components/icons/activity-card/up-vote-filled';
import axios from 'axios';
import LinearProgressBar from 'app/components/common/progress-bar/linear-progress-bar';
import { getReportingReasons } from 'app/shared/reducers/activity/activity-report';
import SquareChatIcon from 'app/components/icons/header-icons/square-chat-icon';
import AuthorisationModal from 'app/components/common/authorization-modal/authorization-modal';
import BlueTwitterIcon from 'app/components/icons/login-page-icons/blue-twitter-icon';
import GrayTwitterIcon from 'app/components/icons/login-page-icons/gray-twitter-icon';
import { appConstants } from 'app/utils/data/constants/constants';
import './petition-detail-inside-section.scss';
import StaticGMapImage from 'app/components/common/google-maps/static-g-map-image';
import { toggleModalLogin } from 'app/shared/reducers/authentication';
import { filterActivities } from 'app/shared/reducers/activity-filter';
import FeatureDialog from 'app/components/common/alert-dialog/feature-dialog';
import toast from 'react-hot-toast';
import { GallerySlide } from '../activity-details-section/activity-details-card/activity-details-card';
import ImageGrid from '../grid-layout/ImageGrid';
import Lightbox from 'yet-another-react-lightbox';
import Video from 'yet-another-react-lightbox/plugins/video';
import 'yet-another-react-lightbox/styles.css';
import { useItemId } from 'app/utils/hooks/useItemId';

const PetitionDetailInsideSection = ({ petitionData }) => {
  const theme = useTheme();
  const [petitionResponse, setPetition] = useState(petitionData || {});
  useEffect(() => {
    setPetition(petitionData || {});
  }, [petitionData]);
  const isDarkMode = theme.palette.mode === 'dark';
  const [authModalOpen, setAuthModalOpen] = React.useState(false);
  const isMdScreen = useMediaQuery(theme.breakpoints.down(992));
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const currentAccount = useAppSelector(state => state.authentication.account.id);
  const { filter, eventType } = useAppSelector(state => state.filters.RequestBody);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentComponent, setCurrentComponent] = React.useState(' ');
  const [isBookmarked, setIsBookmarked] = useState<boolean>(petitionResponse?.bookMark || false);
  const [hasSigned, setHasSigned] = useState<boolean>(petitionResponse?.participant || false);
  const [message, setMessage] = React.useState(' ');
  const [socialAnchorEl, setSocialAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const socialShare = Boolean(socialAnchorEl);
  const socialShareId = socialShare ? 'simple-popover-social' : undefined;
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorReportEl, setanchorReportEl] = useState(null);
  const [respectiveSign, setrespectiveSign] = useState(null);
  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false);
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const handleModalClose = async data => {
    setOpen(false);
    if (data) {
      let response;
      response = await dispatch(fetchPetitionDetails(petitionResponse?.id));
      setPetition(response?.payload);
    }
  };
  const handleShareButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSocialAnchorEl(event.currentTarget);
  };
  const handleSocialShareClose = () => {
    setSocialAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [selectedComment, setSelectedReport] = useState(null);
  const handleClickReport = (event, id) => {
    setanchorReportEl(event.currentTarget);
    setSelectedReport(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseReport = () => {
    setanchorReportEl(null);
  };
  const openList = Boolean(anchorEl);
  const openReport = Boolean(anchorReportEl);
  const menuItemId = openList ? 'simple-popover' : undefined;
  const menuItemIdReport = openList ? 'simple-popover' : undefined;
  const handleYesClick = () => {
    handleDeletePetition(petitionResponse?.id);
    handleClose();
    setConfirmDelete(false);
  };
  const modalActionButtons = [
    { label: 'Yes', onClick: handleYesClick },
    { label: 'No', onClick: () => setConfirmDelete(false) },
  ];
  const handleDeletePetition = async (id: any) => {
    try {
      const response = await axios.delete<any>(`api/petition/delete/${id}`);
      if (response) {
        toast.success(response?.data?.message);
        navigate('/');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setHasSigned(petitionResponse?.participant);
    setIsBookmarked(petitionResponse?.bookMark);
  }, [petitionResponse]);
  const handleAuthModalClose = () => {
    setAuthModalOpen(false);
    window.location.reload();
  };
  const handleBookmarkClick = async () => {
    if (!isAuthenticated) {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
      return;
    }

    try {
      const actionResult = await dispatch(fetchBookmarkedPetition(Number(petitionResponse?.id)));

      if (fetchBookmarkedPetition.fulfilled.match(actionResult)) {
        setIsBookmarked(prevState => !prevState);
        // dispatch(filterActivities({ filter, eventType, page: 0 }));
      } else {
        console.error('Bookmarking was not successful:', actionResult.error?.message || 'An unknown error occurred');
      }
    } catch (error) {
      console.error('Bookmarking failed:', error);
    }
  };
  const currentDate = new Date(petitionResponse?.startDate);
  const petitionTo = petitionResponse?.petitionTo;
  const handleSignButtonClick = () => {
    if (!isAuthenticated) {
      setCurrentComponent('login');
      setAuthModalOpen(true);
    } else {
      if (JSON.parse(localStorage.getItem('Banned'))) {
        toast.error(<>Action Restricted: You cannot perform this action as you are banned by the admin.</>);
        return;
      }
      if (petitionResponse?.participant) {
        setHasSigned(true);
      } else {
        setOpen(true);
        setMessage('You have signed because ... ( Optional )');
      }
    }
  };
  const handleLeavePetitionModal = () => {
    if (isAuthenticated) {
      setOpen(true);
      setMessage('You have leaving petition because ... ( Optional )');
      handleClose();
    } else {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
    }
  };

  const sentParameters = async data => {
    if (!isAuthenticated) {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
    } else {
      const { id, eventType } = data;
      const storageData = { id, eventType };
      // if (localStorage.getItem('dataKey')) {
      //   localStorage.removeItem('dataKey');
      // }
      localStorage.setItem('dataKey', JSON.stringify(storageData));
      navigate('/chat');
    }
  };
  const handleReportPetitionModal = () => {
    if (isAuthenticated) {
      setOpen(true);
      setMessage('Please select a reason for reporting the petition.');
      handleClose();
    } else {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
    }
  };

  const handleEditPetition = () => {
    navigate(`/edit-petition/${petitionResponse?.id}`);
  };
  const postedByStyle = {
    font: 'normal normal 600 16px/22px Manrope',
    color: isDarkMode ? theme.palette.secondary.contrastText : 'secondary.main',
  };
  const petitionCountStyle = {
    font: 'normal normal 600 14px/23px Manrope',
    color: isDarkMode ? theme.palette.secondary.contrastText : '#292D34',
  };

  const handleLikeClick = async (signatureId, index) => {
    try {
      const response = await axios.post<any>(`/api/petition/like/reason/${petitionResponse?.id}/${signatureId}`);
      if (response) {
        const likedCountMatch = response.data.match(/(\d+)/);
        let responsePetition;
        responsePetition = await dispatch(fetchPetitionDetails(petitionResponse?.id));
        if (responsePetition) {
          setPetition(responsePetition.payload);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReportModal = (id: any) => {
    setrespectiveSign(id);
    if (isAuthenticated) {
      setOpen(true);
      setMessage('You are report this comment  because');
    } else {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
    }
  };
  useEffect(() => {
    if (isAuthenticated && open) {
      dispatch(getReportingReasons());
    }
  }, [open]);
  const [confirmFeature, setConfirmFeature] = React.useState<boolean>(false);
  const [payload, setPayload] = React.useState<any>();
  const [passId, setPassId] = React.useState<any>();

  const handleFeatureClick = (event: React.MouseEvent<HTMLButtonElement>, data: any) => {
    setPassId(data?.id);
    handleGetCatalogue(3);
  };

  const [paymentLoading, setPaymentLoading] = useState(false);
  const handlePayment = async () => {
    setPaymentLoading(true);
    let passData = {
      eventId: passId,
      eventType: 3,
      quantity: 1,
      productId: payload?.productId,
    };
    localStorage.setItem('passEventId', passData?.eventId);
    localStorage.setItem('passEventType', JSON.stringify(passData?.eventType));
    try {
      const response = await axios.post<any>('api/payment-service/checkout', passData);
      if (response.status === 201 || response.status === 200) {
        setPaymentLoading(false);
        const checkoutUrl = response?.data?.checkoutUrl;
        if (checkoutUrl) {
          window.location.href = checkoutUrl; // Open checkout URL in current tab
        } else {
          console.error('Checkout URL not found.');
        }
      }
    } catch (error) {
      setPaymentLoading(false);
      console.log(error);
    }
  };

  const handleGetCatalogue = async id => {
    try {
      const response = await axios.get<any>(`api/payment-service/product-catalogue?eventType=${id}`);
      if (response.status === 201 || response.status === 200) {
        console.log('check res', response);
        setPayload(response.data);
        setConfirmFeature(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [index, setIndex] = useState(-1);
  const handleLightBoxClick = (index: number) => setIndex(index);
  const handleLightBoxClose = () => setIndex(-1);

  const slides: GallerySlide[] = petitionResponse?.eventGallery?.map(img => {
    return {
      type: 'image',
      src: img?.link,
      thumbnail: img?.link,
      title: '',
    };
  });

  const getShareToken = (socialName, content, activityId, type, URL, shareMessage) => {
    console.log(socialName, content, activityId, type, URL, shareMessage, 'socialName, URL, shareMessage');

    dispatch(fetchPetitionShareToken({ activityId, type }))
      .then(result => {
        const token = result.payload.token;

        if (token) {
          const utmSource = socialName;
          const utmCampaign = 'Reachamy';
          const utmContent = content.replace(/\s/g, '');

          const newURL = `${URL}?utm_source=${utmSource}&utm_campaign=${utmCampaign}&utm_content=${utmContent}&t=${token}`;

          shareOnSocialMedia(socialName, newURL, shareMessage);
        }
      })
      .catch(err => {
        console.error('Error fetching data:', err);
        toast.error('Something went wrong');
      });
  };
  const petitionId = useItemId();
  console.log('petitionId', petitionId);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const socialToken = params.get('t');
    if (socialToken) {
      dispatch(trackPetitionShare({ petitionId, token: socialToken }))
        .then(() => {
          const newSearch = new URLSearchParams(window.location.search);
          newSearch.delete('t');
          navigate(`?${newSearch.toString()}`, { replace: true });
        })
        .catch(() => {});
    }
  }, []);
  return (
    <div
      className=" row screen-space"
      style={{
        marginTop: '2px',
        marginBottom: '5px',
        height: `calc(100% - 103px)`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        scrollbarWidth: 'none',
      }}
    >
      <style>
        {`
         ::-webkit-scrollbar {
        width: 0;
        background: transparent;
      }  
      ::-webkit-scrollbar-thumb {
        background: transparent;
      }
    `}
      </style>

      <div className="col-12 col-md-8 d-flex flex-column justify-content-start pt-2 ">
        <div
          style={{
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            borderRadius: '15px 15px 0 0',
            background: isDarkMode ? '#373742' : '#fff',
          }}
        >
          <>
            <div style={{ height: '430px', position: 'relative', borderRadius: '15px', overflow: 'hidden' }}>
              {petitionResponse?.featured && (
                <div
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    background: '#674BFF',
                    color: '#FFFF',
                    padding: '5px 10px',
                    borderRadius: '0 0 15px 0',
                    zIndex: '999',
                    fontSize: '12px',
                  }}
                >
                  <span style={{ fontWeight: 'bold' }}>Featured</span>
                </div>
              )}
              {petitionResponse?.eventGallery?.length > 0 && (
                <ImageGrid onImageClick={handleLightBoxClick}>
                  {petitionResponse?.eventGallery.map(item => (
                    <div className={`img_container ${item.type === 'videos' ? 'video-grid' : ''}`}>
                      <img
                        alt="ig"
                        src={item.type === 'videos' ? item?.thumbnail : item?.link}
                        style={{ objectPosition: '1px 1%', objectFit: 'cover' }}
                      />
                    </div>
                  ))}
                </ImageGrid>
              )}
              <div
                className="legend d-flex justify-content-end align-items-center"
                style={{ position: 'absolute', height: '40px', background: 'rgba(0, 0, 0, 0.3)', right: 0, left: 0, bottom: 0 }}
              >
                <div>
                  {isBookmarked ? (
                    <BookmarkIcon
                      sx={{
                        color: '#674BFF',
                        fontSize: '24px',
                        marginRight: '16px',
                        cursor: 'pointer',
                      }}
                      onClick={handleBookmarkClick}
                    />
                  ) : (
                    <BookmarkBorderIcon
                      sx={{
                        color: '#ffff',
                        fontSize: '24px',
                        marginRight: '16px',
                        cursor: 'pointer',
                      }}
                      onClick={handleBookmarkClick}
                    />
                  )}
                </div>
              </div>
            </div>
            <Lightbox
              carousel={{ finite: true }}
              open={index >= 0}
              close={handleLightBoxClose}
              slides={slides}
              plugins={[Video]}
              index={index}
            />
          </>

          <div className="" style={{ background: isDarkMode ? '#373742' : '#fff', padding: '16px', paddingBottom: '0px' }}>
            <div className=" mb-1 d-flex align-items-center ">
              <span>
                <Avatar
                  onClick={() =>
                    petitionResponse?.postAsOrganisation
                      ? navigate(`/organization/${petitionResponse.organizationId}`)
                      : navigate(`/profile/${petitionResponse?.createdBy}`)
                  }
                  alt={`${petitionResponse?.postedByProfileImage}` ? '' : getInitials(petitionResponse?.postedBy)}
                  src={petitionResponse?.postedByProfileImage ? petitionResponse?.postedByProfileImage : ''}
                  sx={{
                    width: '30px',
                    height: '30px',
                    cursor: 'pointer',
                    fontSize: petitionResponse?.postedByProfileImage ? 'default' : '12px',
                  }}
                >
                  {' '}
                  {getInitials(petitionResponse?.postedBy)}
                </Avatar>
              </span>

              <span className="ms-2 d-block d-xs-block d-sm-block d-md-none d-lg-none d-xl-none postby-font" style={postedByStyle}>
                {petitionResponse?.postedBy && petitionResponse?.postedBy?.length < 20 && petitionResponse?.postedBy}
                {petitionResponse?.postedBy &&
                  petitionResponse?.postedBy.length >= 20 &&
                  petitionResponse?.postedBy.split(' ').map((word, index) => (
                    <React.Fragment key={index}>
                      {index > 0 && <br />}
                      {word}
                    </React.Fragment>
                  ))}
              </span>

              {petitionResponse?.postAsOrganisation ? (
                <Link
                  to={`/organization/${petitionResponse?.organizationId}`}
                  className="ms-2 d-none d-xs-none d-sm-none d-md-block d-lg-block d-xl-block"
                  style={postedByStyle}
                >
                  {petitionResponse?.postedBy}
                </Link>
              ) : (
                <span className="ms-2 d-none d-xs-none d-sm-none d-md-block d-lg-block d-xl-block" style={postedByStyle}>
                  {petitionResponse?.postedBy}
                </span>
              )}
            </div>
            <div className="col-6 " style={{ marginTop: isMdScreen ? 'unset' : '14px' }}>
              <Button
                className="mob-mb"
                sx={{
                  fontSize: '13px',
                  marginRight: '6px',
                  borderRadius: '20px',
                  height: '28px',
                  whiteSpace: 'nowrap',
                  backgroundColor: theme.palette.mode === 'dark' ? '#3D3C52' : '#F6F7F8',
                  color: theme.palette.mode === 'dark' ? 'secondary.contrastText' : '#362691',
                }}
              >
                {petitionResponse?.category?.category}
              </Button>

              {petitionResponse?.makeAsUrgentNeed && petitionResponse?.category?.id !== 19 && (
                <Button
                  variant="contained"
                  sx={{
                    fontSize: '13px',
                    borderRadius: '20px',
                    height: '28px',
                    whiteSpace: 'nowrap',
                    backgroundColor: theme.palette.mode === 'dark' ? '#3D3C52' : '#FF9494',
                    color: theme.palette.mode === 'dark' ? '#FF93A9' : '#710017',
                    boxShadow: 'none',
                  }}
                >
                  Urgent
                </Button>
              )}
            </div>
            <div className="mt-1  d-flex flex-row justify-content-between">
              <div className="col-8 mt-1">
                <p
                  style={{
                    font: 'normal normal normal 14px/21px Manrope',
                    color: theme.palette.mode === 'dark' ? theme.palette.secondary.contrastText : '#9FA1A4',
                  }}
                >
                  <b> Petition to &nbsp;&nbsp;&nbsp;{petitionTo?.join(', ')}</b>
                </p>
              </div>
              <div className="col-4 text-end mt-1">
                <p
                  style={{
                    font: 'normal normal normal 14px/21px Manrope',
                    color: theme.palette.mode === 'dark' ? theme.palette.secondary.contrastText : '#9FA1A4',
                  }}
                >
                  <b> Start date &nbsp;&nbsp;&nbsp; {convertDateToYear(petitionResponse?.startDate)}</b>
                </p>
              </div>
            </div>
            <div className=" mb-1" style={{ marginTop: '-15px' }}>
              <h1
                style={{
                  font: 'normal normal 600 24px/37px Manrope',
                  color: theme.palette.mode === 'dark' ? theme.palette.secondary.contrastText : '#292D34',
                }}
              >
                {petitionResponse?.title}
              </h1>
            </div>

            <div className="" style={{ textAlign: 'justify' }}>
              {petitionResponse?.details ? (
                <>
                  <span style={{ color: theme.palette.mode === 'dark' ? theme.palette.secondary.contrastText : '#5F675A' }}>
                    {petitionResponse?.details}
                  </span>
                </>
              ) : null}
            </div>

            <div className="d-flex  flex-row justify-content-end ">
              <span className=" mt-2  ms-3  " onClick={handleShareButtonClick}>
                <ShareIcon
                  aria-describedby={socialShareId}
                  style={{ cursor: 'pointer', fontSize: '16px', color: theme.palette.mode === 'dark' ? '#1D9BF0' : '#676E7B' }}
                />
              </span>
              <Popover
                id={socialShareId}
                open={Boolean(socialAnchorEl)}
                anchorEl={socialAnchorEl}
                onClose={handleSocialShareClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
              >
                <ListItem sx={{ display: 'flex', flexDirection: 'column' }}>
                  {theme.palette.mode === 'dark' ? (
                    <BlueTwitterIcon
                      sx={{ color: theme.palette.mode === 'dark' ? '#1D9BF0' : '#676e7b', cursor: 'pointer' }}
                      // onClick={() => shareOnSocialMedia('twitter', petitionResponse?.eventURL, appConstants.SocialShareMessage)}
                      onClick={() =>
                        getShareToken(
                          'twitter',
                          petitionResponse?.title.slice(0, 10),
                          petitionResponse?.id,
                          1,
                          petitionResponse?.eventURL,
                          appConstants.SocialShareMessage
                        )
                      }
                    />
                  ) : (
                    <GrayTwitterIcon
                      sx={{ cursor: 'pointer' }}
                      // onClick={() => shareOnSocialMedia('twitter', petitionResponse?.eventURL, appConstants.SocialShareMessage)}
                      onClick={() =>
                        getShareToken(
                          'twitter',
                          petitionResponse?.title.slice(0, 10),
                          petitionResponse?.id,
                          1,
                          petitionResponse?.eventURL,
                          appConstants.SocialShareMessage
                        )
                      }
                    />
                  )}
                </ListItem>
                <ListItem sx={{ display: 'flex', flexDirection: 'column' }}>
                  <FacebookIcon
                    sx={{ color: theme.palette.mode !== 'dark' ? '#1D9BF0' : '#676e7b', cursor: 'pointer' }}
                    // onClick={() => shareOnSocialMedia('facebook', petitionResponse?.eventURL, appConstants.SocialShareMessage)}
                    onClick={() =>
                      getShareToken(
                        'facebook',
                        petitionResponse?.title.slice(0, 10),
                        petitionResponse?.id,
                        2,
                        petitionResponse?.eventURL,
                        appConstants.SocialShareMessage
                      )
                    }
                  />
                </ListItem>

                <ListItem sx={{ display: 'flex', flexDirection: 'column' }}>
                  <LinkedInIcon
                    sx={{ color: theme.palette.mode !== 'dark' ? '#1D9BF0' : '#676e7b', cursor: 'pointer' }}
                    // onClick={() => shareOnSocialMedia('linkedIn', petitionResponse?.eventURL, appConstants.SocialShareMessage)}
                    onClick={() =>
                      getShareToken(
                        'linkedIn',
                        petitionResponse?.title.slice(0, 10),
                        petitionResponse?.id,
                        3,
                        petitionResponse?.eventURL,
                        appConstants.SocialShareMessage
                      )
                    }
                  />
                </ListItem>
              </Popover>
              <span className=" mt-2  ms-3 " onClick={() => sentParameters(petitionResponse)}>
                <SquareChatIcon style={{ cursor: 'pointer', fontSize: '19px' }} />
                &nbsp;&nbsp;&nbsp;{petitionResponse?.msgCount}
              </span>
              {isAuthenticated && (
                <span className=" ms-3 mt-2 " onClick={handleClick}>
                  <MoreHorizOutlinedIcon
                    aria-describedby={menuItemId}
                    sx={{
                      cursor: 'pointer',
                      fontSize: '19px',
                      color: theme.palette.mode === 'dark' ? theme.palette.secondary.contrastText : '#676E7B',
                    }}
                  />
                </span>
              )}
              <Popover
                id={menuItemId}
                open={openList}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <ListItem sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', alignItems: 'inherit' }}>
                  {petitionResponse?.hasPermission === true && (
                    <>
                      {/* edit petition logic */}

                      {petitionResponse?.petitionStatus !== 'COMPLETED' && (
                        <ListItemText sx={{ padding: 0 }} onClick={handleEditPetition}>
                          <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '14px' }}>Edit petition</Typography>
                        </ListItemText>
                      )}

                      {/* delete petition logic */}
                      {petitionResponse?.petitionCount <= 1 && (
                        <ListItemText
                          sx={{ padding: 0 }}
                          onClick={() => {
                            handleClose();
                            setConfirmDelete(true);
                          }}
                        >
                          <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '14px' }}>Delete petition</Typography>
                        </ListItemText>
                      )}

                      {petitionResponse?.hasPermission === true && (
                        <button
                          style={{ padding: 0, background: 'none', border: 'none', cursor: 'pointer' }}
                          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            handleFeatureClick(event, petitionResponse);
                          }}
                        >
                          <ListItemText sx={{ padding: 0 }} className="text-start">
                            <Typography sx={{ p: 0, fontSize: '14px', cursor: 'pointer', color: isDarkMode ? '#fff' : '#000' }}>
                              Feature petition
                            </Typography>
                          </ListItemText>
                        </button>
                      )}
                    </>
                  )}
                  {/* Leave option logic */}
                  {petitionResponse?.participant &&
                    !petitionResponse?.hasPermission &&
                    Number(petitionResponse?.petitionCount) !== Number(petitionResponse?.targetSignature) && (
                      <ListItemText sx={{ padding: 0 }} onClick={handleLeavePetitionModal}>
                        <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '14px' }}>Leave petition</Typography>
                      </ListItemText>
                    )}
                  {/* Report option logic */}
                  {!petitionResponse?.hasPermission && !petitionResponse?.reported ? (
                    <ListItemText sx={{ padding: 0 }} onClick={handleReportPetitionModal}>
                      <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '14px' }}>Report petition</Typography>
                    </ListItemText>
                  ) : !petitionResponse?.hasPermission && petitionResponse?.reported ? (
                    <ListItemText sx={{ padding: 0 }}>
                      <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '14px' }}>Reported</Typography>
                    </ListItemText>
                  ) : null}
                </ListItem>
              </Popover>
            </div>

            <div className="col-12 mt-2">
              {petitionResponse?.reasons?.length > 0 ? (
                <h1
                  style={{
                    font: 'normal normal 600 18px/35px Manrope',
                    color: theme.palette.mode === 'dark' ? theme.palette.secondary.contrastText : '#292D34',
                  }}
                >
                  Reasons for signing
                </h1>
              ) : (
                ''
              )}
            </div>
            <div>
              {petitionResponse?.reasons?.map((data, index) => (
                <div key={index}>
                  <div className="col-12 d-flex">
                    {/* Avatar */}
                    <div className="me-2">
                      {/* {data.profileImage ? (
                        <Avatar
                          src={data.profileImage}
                          style={{ height: '20px', width: '20px' }}
                          onClick={() => navigate(`/profile/${data?.userId}`)}
                        />
                      ) : (
                        <Avatar
                          src="slide1.jpg"
                          style={{ height: '20px', width: '20px' }}
                          onClick={() => navigate(`/profile/${data?.userId}`)}
                        />
                      )} */}
                      <Avatar
                        onClick={() => navigate(`/profile/${data?.userId}`)}
                        alt={`${data?.profileImage}` ? '' : getInitials(data.signedBy)}
                        src={data.profileImage}
                        sx={{
                          width: '30px',
                          height: '29px',
                          cursor: 'pointer',
                          fontSize: data?.postedByProfileImage ? 'default' : '12px',
                        }}
                      >
                        {' '}
                        {getInitials(data.signedBy)}
                      </Avatar>
                    </div>
                    <div className="d-flex flex-column justify-content-start">
                      <p>
                        <span
                          style={{
                            color: theme.palette.mode === 'dark' ? theme.palette.secondary.contrastText : '#292D34',
                            font: 'normal normal 600 14px/22px Manrope',
                          }}
                        >
                          {data.signedBy}
                        </span>
                        &nbsp;&nbsp;&nbsp;
                        <span
                          style={{
                            color: theme.palette.mode === 'dark' ? theme.palette.secondary.contrastText : '#9FA1A4',
                            font: 'normal normal normal 10px/18px Manrope',
                          }}
                        >
                          {new Date(data.signedAt).toLocaleDateString()}
                        </span>
                      </p>
                      <p
                        style={{
                          font: 'normal normal 400 12px/21px Manrope',
                          color: theme.palette.mode === 'dark' ? theme.palette.secondary.contrastText : '#292D34',
                        }}
                      >
                        {data.reason}
                      </p>
                      <div className="d-flex ">
                        <span>
                          {data?.liked ? (
                            <div className="d-flex align-items-center cursor-pointer" onClick={() => handleLikeClick(data.id, index)}>
                              <UpVoteFilled sx={{ borderColor: '#9FA1A4', fontSize: '16px', cursor: 'pointer' }} />
                              <span style={{ fontSize: '14px' }}>&nbsp;&nbsp;{data?.likeCount}</span>
                            </div>
                          ) : (
                            <div className="d-flex align-items-center cursor-pointer" onClick={() => handleLikeClick(data.id, index)}>
                              <UpVoteOutlined sx={{ borderColor: '#9FA1A4', fontSize: '16px', cursor: 'pointer' }} />
                              {data?.likeCount > 0 && <span style={{ fontSize: '14px' }}>&nbsp;&nbsp;&nbsp;{data.likeCount}</span>}
                            </div>
                          )}
                        </span>

                        {data?.userId !== currentAccount && (
                          <>
                            <span className="ms-2">
                              <span onClick={e => handleClickReport(e, data?.userId)}>
                                <MoreHorizOutlinedIcon
                                  aria-describedby={menuItemIdReport}
                                  sx={{ cursor: 'pointer', fontSize: '19px', color: '#676E7B' }}
                                />
                              </span>
                              <Popover
                                id={menuItemIdReport}
                                open={openReport && selectedComment === data?.userId}
                                anchorEl={anchorReportEl}
                                onClose={handleCloseReport}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                              >
                                <ListItem sx={{ display: 'flex', flexDirection: 'column' }}>
                                  {data?.reported ? (
                                    <div
                                      style={{ fontSize: '14px', color: '#9FA1A4', cursor: 'pointer' }}
                                      className="d-flex align-items-center cursor-pointer"
                                    >
                                      Reported
                                    </div>
                                  ) : (
                                    <div
                                      style={{ fontSize: '14px', color: '#818384', cursor: 'pointer' }}
                                      className="d-flex align-items-center cursor-pointer"
                                      onClick={() => handleReportModal(data?.id)}
                                    >
                                      Report
                                    </div>
                                  )}
                                </ListItem>
                              </Popover>
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-4 p-2 pb-5">
        <div className=" p-2 text-center" style={{ background: isDarkMode ? '#373742' : '#fff', height: '100%', borderRadius: '15px' }}>
          {petitionResponse?.latitude !== undefined && petitionResponse?.latitude !== '' && petitionResponse?.latitude !== null && (
            <div className="mb-3">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <StaticGMapImage lat={petitionResponse?.latitude} lng={petitionResponse?.longitude} />
              </Box>
            </div>
          )}

          {Number(petitionResponse?.petitionCount) === Number(petitionResponse?.targetSignature) ? (
            <>
              <div className="row mt-3">
                <div className="col-2"></div>
                <div className="col-8">
                  <span
                    className="ps-2 text-center"
                    style={{ ...petitionCountStyle }}
                  >{`${petitionResponse?.petitionCount} signed of ${petitionResponse?.targetSignature}`}</span>
                  <LinearProgressBar
                    currentValue={parseFloat(petitionResponse?.petitionCount)}
                    maxValue={parseFloat(petitionResponse?.targetSignature)}
                  />
                </div>
              </div>

              <div className="mt-2 ps-2 ">
                <p style={{ color: '#674BFF', font: 'normal normal 600 13px/21px Manrope', cursor: 'default' }}>Successful!</p>
              </div>
            </>
          ) : (
            <>
              <span
                className="ps-2 text-center"
                style={{ ...petitionCountStyle }}
              >{`${petitionResponse?.petitionCount} signed of ${petitionResponse?.targetSignature}`}</span>
              <div className="row mt-1 ps-2">
                <div className="col-2"></div>
                <div className="col-8">
                  <LinearProgressBar
                    currentValue={parseFloat(petitionResponse?.petitionCount)}
                    maxValue={parseFloat(petitionResponse?.targetSignature)}
                  />
                </div>
              </div>
              <div className="mt-2 ps-2 ">
                <p
                  style={{
                    font: 'normal normal normal 12px/21px Manrope',
                    color: theme.palette.mode === 'dark' ? theme.palette.secondary.contrastText : '#9FA1A4',
                  }}
                >
                  {' '}
                  {petitionResponse?.petitionCountToday === 1
                    ? `${petitionResponse?.petitionCountToday} person signed today`
                    : `${petitionResponse?.petitionCountToday} people signed today`}
                </p>
                {/* {hasSigned ? (
                  <p
                    style={{
                      color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.primary.light,
                      font: 'normal normal 600 13px/21px Manrope',
                    }}
                  >
                    You have already signed
                  </p>
                ) : (
                  <div className="col-12 text-center ">
                    <Button
                      type="submit"
                      variant="contained"
                      className="col-12"
                      sx={{
                        backgroundColor: 'primary.light',
                        textTransform: 'none',
                        borderRadius: '15px',
                        color: 'white',
                        height: '40px',
                        width: isSmallDevice ? '88%' : '190px',
                        position: isSmallDevice ? 'fixed' : 'unset',
                        bottom: isSmallDevice ? '20px' : 'unset',
                        right: isSmallDevice ? '21px' : 'unset',
                        zIndex: isSmallDevice ? 1000 : '',
                      }}
                      onClick={handleSignButtonClick}
                    >
                      Sign the petition
                    </Button>
                  </div>
                )} */}

                {hasSigned && petitionResponse?.petitionStatus !== 'COMPLETED' ? (
                  <p
                    style={{
                      color: theme.palette.mode === 'dark' ? '#ffce00' : theme.palette.primary.light,
                      font: 'normal normal 600 13px/21px Manrope',
                    }}
                  >
                    You have already signed
                  </p>
                ) : petitionResponse?.petitionStatus !== 'COMPLETED' ? (
                  <div className="col-12 text-center">
                    <Button
                      type="submit"
                      variant="contained"
                      className="col-12"
                      sx={{
                        backgroundColor: isDarkMode ? '#ffce00' : '#674bff',
                        textTransform: 'none',
                        borderRadius: '15px',
                        color: isDarkMode ? 'black' : 'white',
                        height: '40px',
                        width: isSmallDevice ? '88%' : '190px',
                        position: isSmallDevice ? 'fixed' : 'unset',
                        bottom: isSmallDevice ? '20px' : 'unset',
                        right: isSmallDevice ? '21px' : 'unset',
                        zIndex: isSmallDevice ? 1000 : '',
                         '&:hover': {
                        backgroundColor: isDarkMode ? '#ffce00' : '#674bff',
                          color: isDarkMode ? 'black' : 'white',
                      },
                      }}
                      onClick={handleSignButtonClick}
                    >
                      Sign the petition
                    </Button>
                  </div>
                ) : (
                  <Button
                    variant="contained"
                    className="me-1"
                    sx={{
                      fontSize: '14px',
                      borderRadius: '20px',
                      height: '28px',
                      lineHeight: '12px',
                      backgroundColor: 'transparent',
                      color: isDarkMode ? '#FFCE00' : '#674BFF',
                      '&:hover': {
                        backgroundColor: 'transparent',
                        color: theme.palette.primary.main,
                      },
                    }}
                  >
                    Completed
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <SignPetitionModal
        fullScreen={fullScreen}
        id={petitionResponse?.id}
        respectiveId={respectiveSign}
        open={open}
        message={message}
        onClose={handleModalClose}
      />
      <GenericModal
        handleClose={() => setConfirmDelete(false)}
        fullScreen={fullScreen}
        open={confirmDelete}
        title="Are you sure want to delete?"
        buttons={modalActionButtons}
      />
      <FeatureDialog
        paymentLoading={paymentLoading}
        agreeAction={handlePayment}
        values={payload}
        open={confirmFeature}
        setFeatureOpen={setConfirmFeature}
      />
      <AuthorisationModal
        currentComponent={currentComponent}
        fullScreen={fullScreen}
        authModalOpen={authModalOpen}
        onClose={handleAuthModalClose}
        handleAuthModalClose={handleAuthModalClose}
        setCurrentComponent={setCurrentComponent}
      />
    </div>
  );
};

export default PetitionDetailInsideSection;
