import axios, { AxiosResponse } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface volunteerState {
  loading: null | boolean;
  success: null | boolean;
  error: null | string;
}

export const initialState: volunteerState = {
  loading: null,
  success: null,
  error: null,
};

export const participateInActivity = createAsyncThunk<any, { activityID?: any; organizationId?: any }, { rejectValue: string }>(
  'api/participate',
  async ({ activityID, organizationId }, { rejectWithValue }) => {
    try {
      const response = await axios.post<any>('api/activity/participate', { activityID, organizationId });
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const participateInActivityCard = createAsyncThunk<
  any,
  { activityIds?: any; organizationId?: any; childCard?: any },
  { rejectValue: string }
>('api/participate', async ({ activityIds, organizationId, childCard }, { rejectWithValue }) => {
  try {
    const myId = JSON.parse(localStorage.getItem('respectiveActivityId'));
    const response = await axios.post<any>('api/activity/participate', {
      activityIds: myId ? [myId] : activityIds,
      organizationId,
      childCard,
    });
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.response?.data?.message || 'An error occurred');
  }
});

export const fetchCustomInstructions = createAsyncThunk<any, { activityId: number }, { rejectValue: string }>(
  'api/fetch-custom-instructions',
  async ({ activityId }, { rejectWithValue }) => {
    try {
      const response = await axios.get<any>(`api/activities/custom-instructions/${activityId}`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch custom instructions');
    }
  }
);

export const volunteerSlice = createSlice({
  name: 'volunteerSettings',
  initialState: initialState as volunteerState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(participateInActivity.pending, state => {
      state.loading = true;
      state.success = null;
      state.error = null;
    });

    builder.addCase(participateInActivity.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = null;
    });

    builder.addCase(participateInActivity.rejected, (state, action) => {
      state.loading = null;
      state.error = action.error.message;
      state.success = null;
    });
    builder.addCase(fetchCustomInstructions.pending, state => {
      state.loading = true;
      state.success = null;
      state.error = null;
    });

    builder.addCase(fetchCustomInstructions.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = null;
    });

    builder.addCase(fetchCustomInstructions.rejected, (state, action) => {
      state.loading = null;
      state.error = action.payload || action.error.message;
      state.success = null;
    });
  },
});

export default volunteerSlice.reducer;
