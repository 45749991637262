import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const NoResultsCalender = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="139.881" height="124.832" viewBox="0 0 139.881 124.832">
    <g id="Group_6178" data-name="Group 6178" transform="translate(-97.202 -114.605)">
      <g id="Group_6225" data-name="Group 6225" transform="translate(97.202 114.605)">
        <g id="Group_6206" data-name="Group 6206" transform="translate(0 0)">
          <g id="Group_6180" data-name="Group 6180" transform="translate(0 8.047)">
            <path
              id="Path_4488"
              data-name="Path 4488"
              d="M208.583,116.2H94.367a6.52,6.52,0,0,0-6.52,6.52V226.21a6.52,6.52,0,0,0,6.52,6.52H208.583a6.52,6.52,0,0,0,6.52-6.52V122.722A6.52,6.52,0,0,0,208.583,116.2Zm-88.028,11.026a3.574,3.574,0,1,1,3.574-3.574A3.574,3.574,0,0,1,120.555,127.228Zm61.841,0a3.574,3.574,0,1,1,3.574-3.574A3.574,3.574,0,0,1,182.4,127.228Z"
              transform="translate(-87.847 -116.202)"
              fill="#eeebff"
            />
            <g id="Group_6179" data-name="Group 6179">
              <g id="Group_6178-2" data-name="Group 6178">
                <path
                  id="Path_4489"
                  data-name="Path 4489"
                  d="M208.583,116.2H94.367a6.52,6.52,0,0,0-6.52,6.52v9.789H215.1v-9.789A6.52,6.52,0,0,0,208.583,116.2Zm-88.028,11.026a3.574,3.574,0,1,1,3.574-3.574A3.574,3.574,0,0,1,120.555,127.228Zm61.841,0a3.574,3.574,0,1,1,3.574-3.574A3.574,3.574,0,0,1,182.4,127.228Z"
                  transform="translate(-87.847 -116.202)"
                  fill="#674bff"
                />
              </g>
            </g>
          </g>
          <g id="Group_6205" data-name="Group 6205" transform="translate(30.338)">
            <g id="Group_6204" data-name="Group 6204">
              <rect id="Rectangle_3157" data-name="Rectangle 3157" width="4.894" height="18.296" rx="2.447" fill="#ffce00" />
              <rect
                id="Rectangle_3158"
                data-name="Rectangle 3158"
                width="4.894"
                height="18.296"
                rx="2.447"
                transform="translate(61.841)"
                fill="#ffce00"
              />
            </g>
          </g>
        </g>
        <g id="Group_6224" data-name="Group 6224" transform="translate(63.628 48.579)">
          <g id="Group_6223" data-name="Group 6223">
            <g id="Group_6222" data-name="Group 6222">
              <g id="Group_6220" data-name="Group 6220">
                <g id="Group_6208" data-name="Group 6208">
                  <g id="Group_6207" data-name="Group 6207">
                    <circle id="Ellipse_264" data-name="Ellipse 264" cx="38.127" cy="38.127" r="38.127" fill="#674bff" />
                    <path
                      id="Path_4494"
                      data-name="Path 4494"
                      d="M33.024,0A33.024,33.024,0,1,1,0,33.024,33.024,33.024,0,0,1,33.024,0Z"
                      transform="translate(5.103 5.103)"
                      fill="#ffce00"
                    />
                  </g>
                </g>
              </g>
              <g id="Group_6221" data-name="Group 6221" transform="translate(36.108 22.838)">
                <g id="Group_6226" data-name="Group 6226" transform="translate(0 0)">
                  <circle
                    id="Ellipse_274"
                    data-name="Ellipse 274"
                    cx="2.019"
                    cy="2.019"
                    r="2.019"
                    transform="translate(0 13.27)"
                    fill="#674bff"
                  />
                  <path
                    id="Path_4495"
                    data-name="Path 4495"
                    d="M26.038,14.763V-.525"
                    transform="translate(-24.019 0.525)"
                    fill="none"
                    stroke="#674bff"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                  <path
                    id="Path_4496"
                    data-name="Path 4496"
                    d="M48.052,16.887H25.889"
                    transform="translate(-24.001 -1.599)"
                    fill="none"
                    stroke="#674bff"
                    strokeLinecap="round"
                    strokeWidth="2"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>,

  'NoResultsCalender'
);

export default NoResultsCalender;
