// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activation-btn {
  color: #f6f7f8 !important;
  border-radius: 4px !important;
  background-color: #674bff !important;
  border: 1px solid #674bff !important;
  margin-bottom: 10px !important;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/account/activate/activate.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,6BAAA;EACA,oCAAA;EACA,oCAAA;EACA,8BAAA;AACF","sourcesContent":[".activation-btn {\n  color: #f6f7f8 !important;\n  border-radius: 4px !important;\n  background-color: #674bff !important;\n  border: 1px solid #674bff !important;\n  margin-bottom: 10px !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
