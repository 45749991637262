export const ConstantReaction = {
  emojis: [
    {
      name: 'thumbs_up',
      icon: '👍',
    },
    {
      name: 'smile',
      icon: '😊',
    },
    {
      name: 'heart',
      icon: '❤️',
    },
    {
      name: 'laugh',
      icon: '😂',
    },
    {
      name: 'sad',
      icon: '😢',
    },
    {
      name: 'angry',
      icon: '😡',
    },
    {
      name: 'cool',
      icon: '😎',
    },
    {
      name: 'confused',
      icon: '😕',
    },
    {
      name: 'clap',
      icon: '👏',
    },
  ],
};
