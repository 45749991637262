import dayjs from 'dayjs';
import * as Yup from 'yup';

// validation schema
export const createActivitySchema = Yup.object().shape({
  header: Yup.string()
    .trim()
    .required('Activity title is required.')
    .min(1, 'Activity title is required to be at least 1 character.')
    .max(75, 'Activity title cannot be longer than 75 characters.')
    .matches(/^[^?/]*$/, 'Activity title cannot contain the "?" or "/" character.'),
  details: Yup.string()
    .trim()
    .required('Activity details is required')
    .max(3990, 'Activity details cannot exceed 4000 characters, including spaces'),
  categoryID: Yup.string().required('Category is required'),
  subCategoryID: Yup.string().required('Sub category is required'),
  goalId: Yup.mixed().when(['categoryID', 'subCategoryID'], {
    is: (category, subCategory) => {
      return String(subCategory) !== '72';
    },
    then: createActivitySchema => createActivitySchema.required('Goal is required'),
    otherwise: createActivitySchema => createActivitySchema.nullable(),
  }),
  postAsOrganisation: Yup.boolean().required('Post as field is required'),
  quantity: Yup.number()
    .min(1, 'Quantity cannot be zero')
    .when(['categoryID', 'subCategoryID'], {
      is: (category, subCategory) => {
        return String(subCategory) !== '72' && String(subCategory) !== '14';
      },
      then: createActivitySchema => createActivitySchema.required('Quantity is required'),
      otherwise: createActivitySchema => createActivitySchema.nullable(),
    }),
  activityTypeId: Yup.string().when(['categoryID', 'subCategoryID'], {
    is: (category, subCategory) => {
      return String(category) !== '19' && String(subCategory) !== '72';
    },
    then: createActivitySchema => createActivitySchema.required('Activity type is required'),
    otherwise: createActivitySchema => createActivitySchema.nullable(),
  }),
  units: Yup.number().when(['subCategoryID'], {
    is: subCategory => {
      return String(subCategory) === '14';
    },
    then: createActivitySchema => createActivitySchema.required('Blood units is required'),
    otherwise: createActivitySchema => createActivitySchema.nullable(),
  }),
  bloodTypeId: Yup.number().when(['subCategoryID'], {
    is: subCategory => {
      return String(subCategory) === '14';
    },
    then: createActivitySchema => createActivitySchema.required('Blood type is required'),
    otherwise: createActivitySchema => createActivitySchema.nullable(),
  }),
  online: Yup.boolean(),
  latitude: Yup.string().when(['online', 'subCategoryID'], {
    is: (online, subCategory) => !online || String(subCategory) === '72',
    then: createActivitySchema =>
      createActivitySchema.required('Location is required').test('is-zero', 'Latitude cannot be zero', value => value !== '0'),
    otherwise: createActivitySchema => createActivitySchema.test('is-zero', 'Latitude cannot be zero', value => value !== '0'),
  }),
  onlineUrl: Yup.string().when(['online', 'subCategoryID'], {
    is: (online, subCategory) => online && String(subCategory) !== '72',
    then: createActivitySchema => createActivitySchema.required('Event link is required').url('URL must start with http:// or https://'),
    otherwise: createActivitySchema => createActivitySchema,
  }),
  includeMicroFunding: Yup.boolean(),
  amountToRaise: Yup.number().when(['includeMicroFunding', 'subCategoryID'], {
    is: (includeMicroFunding, subCategory) => includeMicroFunding && String(subCategory) !== '72',
    then: createActivitySchema => createActivitySchema.required('Amount is required'),
    otherwise: createActivitySchema => createActivitySchema,
  }),
  currency: Yup.string(),
  startDate: Yup.date()
    .min(dayjs().startOf('day').toDate(), 'Start date must be on or after today')
    .when('categoryID', {
      is: subCategory => String(subCategory) !== '19',
      then: createActivitySchema => createActivitySchema.required('Start date is required'),
      otherwise: createActivitySchema => createActivitySchema.nullable(),
    }),

  activityStartTime: Yup.date().when('categoryID', {
    is: subCategory => String(subCategory) !== '19',
    then: createActivitySchema => createActivitySchema.required('Start time is required'),
    otherwise: createActivitySchema => createActivitySchema.nullable(),
  }),
  activityEndTime: Yup.date().when(['activityStartTime', 'categoryID'], {
    is: (activityStartTime, subCategoryId) => activityStartTime || String(subCategoryId) !== '19',
    then: createActivitySchema =>
      createActivitySchema
        .required('End time is required')
        .test('isValidEndTime', 'End time should be greater than start time (hours and minutes)', function (value) {
          const startTime = this.parent.activityStartTime;
          const endTime = value;

          if (!startTime || !endTime) return true;

          const start = dayjs(startTime);
          const end = dayjs(endTime);

          // Compare only hours and minutes
          const isEndTimeValid = end.isAfter(start, 'minute');

          return isEndTimeValid;
        }),
    otherwise: createActivitySchema => createActivitySchema.nullable(),
  }),

  multimedia: Yup.array().min(1, 'At least one multimedia item is required.'),
  customFrequency: Yup.array().of(
    Yup.object().shape({
      days: Yup.number().min(0, 'Days must be greater than or equal to 0'),
      weeks: Yup.number().min(0, 'Weeks must be greater than or equal to 0'),
      weekDays: Yup.array().of(Yup.string()),
      months: Yup.number().min(0, 'Months must be greater than or equal to 0'),
    })
  ),

  lostPet: Yup.object().shape({
    subCategoryID: Yup.string(),
    petCategoryID: Yup.number().when(['subCategoryID'], {
      is: subCategory => String(subCategory) === '72',
      then: createActivitySchema => createActivitySchema.required('Pet category is required').min(1, 'Pet category is required'),
      otherwise: createActivitySchema => createActivitySchema.nullable(),
    }),
    animal: Yup.string(),
    currencyCode: Yup.string().test({
      name: 'currencyCode',
      test: function (value) {
        const reward = this.parent.reward;
        if (reward != 0 && reward != undefined && (value === undefined || value === '')) {
          return false;
        }
        return true;
      },
      message: 'Currency code is required when reward is specified',
    }),

    reward: Yup.number().test({
      name: 'reward',
      test: function (value) {
        const currencyCode = this.parent.currencyCode;
        if (typeof currencyCode === 'string' && currencyCode.trim() !== '' && (value === 0 || value === undefined)) {
          return false;
        }

        return true;
      },
      message: 'Reward is required when currency code is specified',
    }),

    countryCode: Yup.string().when(['subCategoryID'], {
      is: subCategory => String(subCategory) === '72',
      then: createActivitySchema => createActivitySchema.required('Country code is required'),
      otherwise: createActivitySchema => createActivitySchema.nullable(),
    }),
    phoneNumber: Yup.string().when(['subCategoryID'], {
      is: subCategory => String(subCategory) === '72',
      then: createActivitySchema => createActivitySchema.required('Phone number is required'),
      otherwise: createActivitySchema => createActivitySchema.nullable(),
    }),
    nameOfThePet: Yup.string().when(['subCategoryID'], {
      is: subCategory => String(subCategory) === '72',
      then: createActivitySchema =>
        createActivitySchema
          .trim()
          .required('Name of pet is required')
          .min(1, 'Name of pet is required to be at least 1 character.')
          .max(25, 'Name of pet cannot be longer than 25 characters.'),
      otherwise: createActivitySchema => createActivitySchema.nullable(),
    }),
    breed: Yup.string().max(25, 'Breed cannot be longer than 25 characters.'),
    keyIdentifier: Yup.string(),
    color: Yup.string().when(['subCategoryID'], {
      is: subCategory => String(subCategory) === '72',
      then: createActivitySchema => createActivitySchema.required('Color is required'),
      otherwise: createActivitySchema => createActivitySchema.nullable(),
    }),
    dateLostOn: Yup.date().when(['subCategoryID'], {
      is: subCategory => String(subCategory) === '72',
      then: createActivitySchema => createActivitySchema.required('Last seen date is required'),
      otherwise: createActivitySchema => createActivitySchema.nullable(),
    }),
  }),

  activityFrequencyId: Yup.number().when('categoryID', {
    is: subCategory => String(subCategory) !== '19',
    then: createActivitySchema => createActivitySchema.required('Activity frequency is required'),
    otherwise: createActivitySchema => createActivitySchema.nullable(),
  }),
  endDate: Yup.date().when(['activityFrequencyId', 'startDate', 'categoryID'], {
    is: (activityFrequencyId, startDate, subCategoryID) => {
      return String(activityFrequencyId) !== '1' && startDate !== undefined && String(subCategoryID) !== '19';
    },
    then: createActivitySchema =>
      createActivitySchema
        .required('End date is required')
        .test('endDate', 'End date should be greater than  start date', function (value) {
          const startdate = new Date(this.parent.startDate);
          const enddate = new Date(value);
          const isEndTimeValid = startdate < enddate;

          return isEndTimeValid;
        }),
    otherwise: createActivitySchema => createActivitySchema.nullable(),
  }),
});
