export const linksData = {
  googleMapSearch: 'https://www.google.com/maps?q=',
};

export const pageConfig = {
  notificationPannel: {
    itemLimit: 25,
  },
};

export const appConstants = {
  AUTH_TOKEN_KEY: 'jhi-authenticationToken',
  USER_PREFERENCES: 'userPreferencesCompleted',
  SocialShareMessage: 'Reach out to Reach Amy-',
  reportingReasonEnd: 'Something else',
  mapLibraries: ['places'],
};

export const colorSchemes = {
  appGradientColor: 'transparent linear-gradient(121deg, #F7F5EF 0%, #F7F5EF 34%, #F2F2FA 100%, #EFEFF5 100%) 0% 0% no-repeat padding-box',
};
export const staticData = {
  emailRegEx: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
};
export const bloodGroups = [
  { label: 'A+', value: 'A+', id: 1 },
  { label: 'A-', value: 'A-', id: 2 },
  { label: 'B+', value: 'B+', id: 3 },
  { label: 'B-', value: 'B-', id: 4 },
  { label: 'AB+', value: 'AB+', id: 5 },
  { label: 'AB-', value: 'AB-', id: 6 },
  { label: 'O+', value: 'O+', id: 7 },
  { label: 'O-', value: 'O-', id: 8 },
  { label: 'A1+', value: 'A1+', id: 9 },
  { label: 'A1-', value: 'A1-', id: 10 },
  { label: 'A1B+', value: 'A1B+', id: 11 },
  { label: 'A1B-', value: 'A1B-', id: 12 },
  { label: 'A2+', value: 'A2+', id: 13 },
  { label: 'A2-', value: 'A2-', id: 14 },
  { label: 'A2B+', value: 'A2B+', id: 15 },
  { label: 'A2B-', value: 'A2B-', id: 16 },
  { label: 'Bombay Blood Group', value: 'Bombay Blood Group', id: 17 },
  { label: 'INRA', value: 'INRA', id: 16 },
];
