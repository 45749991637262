import axios, { AxiosResponse } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppThunk } from 'app/config/store';
import { serializeAxiosError } from './reducer.utils';

interface PreferencesState {
  loading: boolean;
  success: boolean;
  error: string;
  sendLoading: boolean;
  sendSuccess: boolean;
  sendError: string;
  userPreferencesCurrentUser: {};
}
export const initialState: PreferencesState = {
  loading: false,
  success: false,
  error: '',
  userPreferencesCurrentUser: {},
  sendLoading: false,
  sendError: '',
  sendSuccess: false,
};

export type usePreferencesState = Readonly<typeof initialState>;

export const sendPreferences = createAsyncThunk(
  'userpreferences/sendData',
  async (preferences: any) => axios.post<any>('api/user-preferences', preferences),
  {
    serializeError: serializeAxiosError,
  }
);
export const sendUserPreferencesData: (preferences?: {}) => AppThunk = preferences => async dispatch => {
  const result = await dispatch(sendPreferences(preferences));
  const response = result.payload as AxiosResponse;
};
export const getUserPreferencesData = createAsyncThunk<{}, void, { rejectValue: string }>('userPreferences/getData', async () => {
  try {
    const response = await axios.get<any>('api/user-preferences/current-user');
    return response.data as AxiosResponse;
  } catch (error) {
    throw error.response?.data?.message || 'Error Fetching Data';
  }
});

export const userPreferencesSlice = createSlice({
  name: 'userPreferencesSettings',
  initialState: initialState as usePreferencesState,
  reducers: {
    clearUserPreferences(state) {
      state.userPreferencesCurrentUser = {};
    },
  },
  extraReducers: builder => {
    builder.addCase(sendPreferences.pending, state => {
      state.sendLoading = true;
      state.sendSuccess = false;
      state.sendError = '';
    });

    builder.addCase(sendPreferences.fulfilled, (state, action) => {
      state.sendLoading = false;
      state.sendSuccess = true;
      state.error = '';
    });

    builder.addCase(sendPreferences.rejected, (state, action) => {
      state.sendLoading = false;
      state.sendError = action.error.message;
      state.sendSuccess = false;
    });
    builder.addCase(getUserPreferencesData.pending, (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getUserPreferencesData.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.userPreferencesCurrentUser = action.payload;
    });
    builder.addCase(getUserPreferencesData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.success = false;
    });
  },
});
export const { clearUserPreferences } = userPreferencesSlice.actions;

export default userPreferencesSlice.reducer;
