import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import SideNavigation from 'app/components/layout/side-navigaion/side-navigation';
import PrimaryHeader from 'app/components/layout/header/primary-header';
import ProfileDetailsSection from './profile-details/profile-details';
import ProfileActivityDetails from './profile-activity-details/profile-activity-details';
import ProfileGoals from './profile-goals/profile-goals';
import { fetchProfile, fetchUnAuthorizedProfile } from 'app/shared/reducers/profile';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import AmyIcon from 'app/components/common/cards/chat-card/amy-icons';
import toast from 'react-hot-toast';
import { Button, Grid, useMediaQuery } from '@mui/material';
import AuthorisationModal from 'app/components/common/authorization-modal/authorization-modal';
import { resetLogin, toggleModalLogin } from 'app/shared/reducers/authentication';

const drawerWidth = 315;
const drawerWidthSm = 248;

interface Props {
  window?: () => Window;
}
const Profile = (props: Props) => {
  // const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [currentComponent, setCurrentComponent] = React.useState(' ');
  const [authModalOpen, setAuthModalOpen] = React.useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDarkMode = theme.palette.mode === 'dark';
  const [toggleValue, setToggleValue] = useState(false);
  const [toggleMapValue, setToggleMapValue] = useState(false);
  const myProfileId = useAppSelector(state => state.authentication.account.id);
  const [searchKeyword, setSearchKeyword] = React.useState<string>('');
  const profileData = useAppSelector(state => state.profileReducer.profile);
  const profileLoading = useAppSelector(state => state.profileReducer.profileLoading);

  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [routeBack, setRouteBack] = useState(false);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const location = useLocation();

  const isBackHome = location.state?.isBackHome;
  // const isBackHome = true;

  useEffect(() => {
    if (isBackHome) {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener('popstate', e => {
        e.preventDefault();
        window.history.pushState(null, null, window.location.pathname);
      });
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!isAuthenticated) {
        // Call fetchUnAuthorizedProfile
        try {
          await dispatch(fetchUnAuthorizedProfile(id));
        } catch (error) {
          console.error('Error fetching unauthorized profile:', error);
        }
      } else if (id !== undefined) {
        try {
          await dispatch(fetchProfile(id))
            .then(result => {
              if (result?.payload === 'Profile is locked') {
                navigate('/lock');
              }
            })
            .catch(error => {
              console.error('Error fetching profile:', error);
            });
        } catch (error) {
          console.error('Error fetching profile:', error);
        }
      } else if (myProfileId) {
        try {
          await dispatch(fetchProfile(myProfileId))
            .then(result => {
              if (result?.payload === 'Profile is locked') {
                navigate('/lock');
              }
            })
            .catch(error => {
              console.error('Error fetching profile:', error);
            });
        } catch (error) {
          console.error('Error fetching profile:', error);
        }
      }
    };

    fetchData();
  }, [id, myProfileId, isAuthenticated]);

  const handleSubCategoryClick = (category: string, subCategory: string) => {
    setCategory(category);
    setSubCategory(subCategory);
  };
  const handleAccordionClick = (category: any, currentState: any) => {
    setCategory(category);
  };
  const handleNavigationArrowButton = () => {
    if (fieldsChanged) {
      setRouteBack(true);
    } else if (isBackHome) {
      navigate('/');
    } else {
      navigate(-1);
    }
  };
  const handleButtonClicked = () => {
    const newToggleValue = !toggleValue;
    if (toggleMapValue) {
      setToggleMapValue(false);
    }
    setToggleValue(newToggleValue);
  };

  const handleButtonMapClicked = () => {
    const newToggleMapValue = !toggleMapValue;
    if (toggleValue) {
      setToggleValue(false);
    }
    setToggleMapValue(newToggleMapValue);
  };
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  const handleLoginModal = () => {
    setCurrentComponent('login');
    dispatch(toggleModalLogin());

    setAuthModalOpen(true);
  };

  const handleAuthModalClose = () => {
    setAuthModalOpen(false);
    dispatch(resetLogin());
  };

  const handleClick = () => {
    navigate('/');
  };
  const handleButtonFeatureClicked = () => { };

  const drawer = (
    <Box
      sx={{
        border: 'none',
        maxHeight: '100vh',
        overflowY: 'scroll',
        overflowX: 'hidden',

        background:
          theme.palette.mode === 'dark'
            ? '#1F1F26'
            : 'transparent linear-gradient(182deg, #F7F5EF 0%, #F2F2FA 49%, #f1f1f8 100%) 0% 0% no-repeat padding-box;',
        maxWidth: { sm: drawerWidthSm, lg: drawerWidth, md: drawerWidthSm },
        backdropFilter: 'blur(10px)',
        minHeight: '100%',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '&::-webkit-scrollbar-thumb': { backgroundColor: 'secondary.contrastText', height: '10px' },
        '&::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <DrawerHeader sx={{ justifyContent: 'center', height: '79px', border: 'none' }}>
        <Box style={{ paddingLeft: '0px', paddingRight: '50px', cursor: 'pointer' }} onClick={() => navigate('/')}>
          <AmyIcon sx={{ width: '159px', height: '100px' }} />
        </Box>
      </DrawerHeader>

      <SideNavigation
        category={category}
        subCategory={subCategory}
        onSubCategoryClick={handleSubCategoryClick}
        onhandleAccordionClick={handleAccordionClick}
        onButtonClicked={handleButtonClicked}
        toggleMapValue={toggleMapValue}
        setToggleMapValue={setToggleMapValue}
        onMapClicked={handleButtonMapClicked}
        onFeatureClicked={handleButtonFeatureClicked}
      />
      <Divider />
    </Box>
  );

  // const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <Box
      sx={{
        display: 'flex',
        background: isDarkMode ? '#1F1F26' : 'transparent linear-gradient(118deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat',
        p: 0,
        border: 'none',
        backdropFilter: 'blur(10px)',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <CssBaseline />

      {/* header */}
      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidthSm}px)`, md: `calc(100% - ${drawerWidthSm}px)`, lg: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidthSm}px`, md: `${drawerWidthSm}px`, lg: `${drawerWidth}px` },
          background: isDarkMode ? '#1F1F26' : 'none',
        }}
      >
        <Toolbar
          className="amy-top-nav"
          sx={{
            backgroundColor: ' transparent linear-gradient(118deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat',
            border: 'none',
            background: isDarkMode ? '#1F1F26' : '',
            backdropFilter: 'blur(10px)',
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' }, top: '10px' }}
          >
            <MenuIcon />
          </IconButton>
          <PrimaryHeader searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} />
        </Toolbar>
      </AppBar>
      {/* End Header */}

      {/* Side Nav */}
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidthSm, md: drawerWidthSm, lg: drawerWidth },
          flexShrink: { sm: 0 },
          minHeight: '100vh',
          border: 'none',
          background: isDarkMode ? '#1F1F26' : 'transparent linear-gradient(118deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat',
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidthSm },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: { sm: drawerWidthSm, md: drawerWidthSm, lg: drawerWidth } },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      {/* End side Nav */}

      {/* main body */}
      <Box
        className="inside-bg"
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: '0px',
          background: isDarkMode
            ? '#1F1F26'
            : ' transparent linear-gradient(172deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat padding-box',
          border: 'none',
          backdropFilter: 'blur(10px)',
        }}
      >
        <Toolbar />
        <div className="container-fluid p-0" style={{ background: isDarkMode ? '#1F1F26' : '' }}>
          <div className="col-3">
            <ArrowCircleLeftIcon
              onClick={handleNavigationArrowButton}
              sx={{
                cursor: 'pointer', color: isDarkMode ? '#FFCE00' : '#775fff',
                borderRadius: '50%', fontSize: '29px'
              }}
            />
          </div>
          {!profileLoading &&
            (profileData?.ownProfile || (!profileData?.ownProfile && profileData?.profileVisible && !profileData?.deactivatedOrDeleted) ? (
              <div className="row screen-space">
                <div
                  className=" col-sm-12 col-md-10 col-lg-8 col-scroll"
                  style={{ height: '89vh', overflowY: 'auto', scrollbarWidth: 'thin' }}
                >
                  <ProfileDetailsSection profileData={profileData} id={id ?? myProfileId} />
                  <ProfileActivityDetails profileData={profileData} hasParamId={id} id={id ?? myProfileId} />
                  <style>
                    {`
                  .col-scroll::-webkit-scrollbar {
                    width: 0px; 
                  }
                  .col-scroll::-webkit-scrollbar-track {
                    background: transparent;
                  }
                
                `}
                  </style>
                </div>
                {profileData?.profileVisible && !profileData?.ownProfile && (
                  <div className="col-md-2 col-lg-4 filter-response fixed-column" style={{ paddingTop: '15px' }}>
                    <ProfileGoals id={id ?? myProfileId} />
                  </div>
                )}
                {profileData?.ownProfile && (
                  <div className="col-md-2 col-lg-4 filter-response fixed-column" style={{ paddingTop: '15px' }}>
                    <ProfileGoals id={id ?? myProfileId} />
                  </div>
                )}
              </div>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <ProfileDetailsSection profileData={profileData} id={id ?? myProfileId} />
                  <hr></hr>
                  <Box
                    display="flex"
                    flexDirection="column"
                    minHeight="250px"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ marginTop: '-50px' }}
                  >
                    <div className="mt-1">
                      <p style={{ color: '#787b7f', font: 'normal normal 600 24px/37px Manrope' }}>
                        Oops! This profile is {profileData?.deactivatedOrDeleted ? 'inactive' : 'locked'}.
                      </p>
                    </div>
                    <div className="mt-1">
                      <p style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>
                        {isAuthenticated ? "You don't have access to view this profile" : 'You need to login or signup.'}
                      </p>
                    </div>
                    <div>
                      {isAuthenticated ? (
                        <Button
                          variant="contained"
                          className="me-1"
                          sx={{
                            fontSize: '14px',
                            borderRadius: '20px',
                            height: '28px',
                            lineHeight: '12px',
                            '&:hover': {
                              backgroundColor: 'primary.main',
                              color: 'common.black',
                            },
                            width: 'fit-content',
                          }}
                          onClick={handleClick}
                        >
                          Go back home
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          className="me-1"
                          sx={{
                            fontSize: '14px',
                            borderRadius: '20px',
                            height: '28px',
                            lineHeight: '12px',
                            '&:hover': {
                              backgroundColor: 'primary.main',
                              color: 'common.black',
                            },
                            width: 'fit-content',
                          }}
                          onClick={handleLoginModal}
                        >
                          Login
                        </Button>
                      )}
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}></Grid>
              </Grid>
            ))}
        </div>
      </Box>
      <AuthorisationModal
        currentComponent={currentComponent}
        fullScreen={fullScreen}
        authModalOpen={authModalOpen}
        onClose={handleAuthModalClose}
        handleAuthModalClose={handleAuthModalClose}
        setCurrentComponent={setCurrentComponent}
      />
      {/* End main body */}
    </Box>
  );
};

export default Profile;
