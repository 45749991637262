import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const WaveFormIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="23.36" height="21.237" viewBox="0 0 23.36 21.237">
    <path
      id="waveform-ecg-svgrepo-com"
      d="M9.495,2a1.062,1.062,0,0,1,1.007.726l5.364,16.091,2.178-6.534a1.062,1.062,0,0,1,1.007-.726H23.3a1.062,1.062,0,0,1,0,2.124H19.817l-2.944,8.83a1.062,1.062,0,0,1-2.015,0L9.495,6.42,7.317,12.954a1.062,1.062,0,0,1-1.007.726H2.062a1.062,1.062,0,1,1,0-2.124H5.544L8.487,2.726A1.062,1.062,0,0,1,9.495,2Z"
      transform="translate(-1 -2)"
      fill="#707070"
      fillRule="evenodd"
    />
  </svg>,
  'WaveFormIcon'
);
export default WaveFormIcon;
