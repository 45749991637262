import React, { useEffect } from 'react';
import RegistrationPage from './registration-page';
import { Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

export default function AdminRegister() {
  const [searchParams, setSearchParams] = useSearchParams();

  const inviitationType = searchParams.get('invitation');
  const token = searchParams.get('token');

  useEffect(() => {
    if (inviitationType && token) {
      localStorage.setItem('invitation', inviitationType);
      localStorage.setItem('token', token);
    }
  }, [inviitationType, token]);

  return (
    <Box width="75%" margin="0 auto">
      <RegistrationPage isAdmin={true} />{' '}
    </Box>
  );
}
