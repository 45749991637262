import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const SelectedMehIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
    <g id="Group_6127" data-name="Group 6127" transform="translate(-165 -111)">
      <g id="Group_6121" data-name="Group 6121" transform="translate(-8690 16615)">
        <circle id="Ellipse_259" data-name="Ellipse 259" cx="22.5" cy="22.5" r="22.5" transform="translate(8855 -16504)" fill="#EEEBFF"/>
        <g id="Group_6062" data-name="Group 6062" transform="translate(8867 -16492)">
          <g id="Group_4907" data-name="Group 4907" transform="translate(0 0)">
            <g id="Group_4904" data-name="Group 4904" transform="translate(0 0)">
              <ellipse id="Ellipse_238" data-name="Ellipse 238" cx="10.249" cy="10.249" rx="10.249" ry="10.249" transform="translate(0 20.498) rotate(-90)" fill="#ffd05d"/>
            </g>
            <g id="Group_4905" data-name="Group 4905" transform="translate(1.612 1.502)">
              <path id="Path_3512" data-name="Path 3512" d="M348.536,306.451a10.251,10.251,0,0,1-14,14.256,10.25,10.25,0,1,0,14-14.256Z" transform="translate(-334.537 -306.451)" fill="#fdc453"/>
            </g>
            <g id="Group_4906" data-name="Group 4906" transform="translate(0.685 0.757)">
              <path id="Path_3513" data-name="Path 3513" d="M327.529,310.832a9.515,9.515,0,0,1,17.059-5.8,9.516,9.516,0,1,0-15.794,10.543A9.472,9.472,0,0,1,327.529,310.832Z" transform="translate(-326.821 -300.258)" fill="#ffd77f"/>
            </g>
          </g>
          <g id="Group_4908" data-name="Group 4908" transform="translate(5.893 11.275)">
            <path id="Path_3514" data-name="Path 3514" d="M378.847,391.617a4.384,4.384,0,0,0-8.712,0Z" transform="translate(-370.135 -387.729)" fill="#695342"/>
          </g>
          <path id="Path_3515" data-name="Path 3515" d="M370.135,411.252s3.676-2.71,8.712,0Z" transform="translate(-364.242 -396.089)" fill="#f06669"/>
          <g id="Group_4911" data-name="Group 4911" transform="translate(4.189 6.778)">
            <g id="Group_4909" data-name="Group 4909">
              <path id="Path_3516" data-name="Path 3516" d="M356.5,353.97a.435.435,0,0,1-.267-.777l1.478-1.152-1.528-.9a.434.434,0,0,1,.44-.749l2.079,1.223a.434.434,0,0,1,.047.717l-1.983,1.545A.432.432,0,0,1,356.5,353.97Z" transform="translate(-355.968 -350.333)" fill="#303030"/>
            </g>
            <g id="Group_4910" data-name="Group 4910" transform="translate(9.171 0)">
              <path id="Path_3517" data-name="Path 3517" d="M434.658,353.971a.433.433,0,0,1-.267-.092l-1.982-1.545a.434.434,0,0,1,.047-.717l2.079-1.223a.434.434,0,1,1,.44.749l-1.528.9,1.478,1.152a.434.434,0,0,1-.267.777Z" transform="translate(-432.241 -350.334)" fill="#303030"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  'SelectedMehIcon'
);

export default SelectedMehIcon;
