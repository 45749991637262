import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const InnovationIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="20.995" height="20.995" viewBox="0 0 20.995 20.995" style={{ fontSize: 'larger' }}>
    <path
      id="Innovation"
      d="M20.713.783a.708.708,0,0,0-.5-.5A14.873,14.873,0,0,0,16.829,0C12.6,0,10.058,2.264,8.162,5.249H3.889A2.156,2.156,0,0,0,2.13,6.335L.1,10.387a1.165,1.165,0,0,0-.1.439.985.985,0,0,0,.984.984H5.241l-.922.921a1.336,1.336,0,0,0,0,1.856l2.087,2.087a1.337,1.337,0,0,0,1.857,0l.922-.921v4.258A.985.985,0,0,0,10.17,21a1.171,1.171,0,0,0,.439-.1l4.049-2.025a2.15,2.15,0,0,0,1.087-1.758V12.827c2.977-1.9,5.25-4.445,5.25-8.656A14.556,14.556,0,0,0,20.713.783ZM15.748,6.889a1.64,1.64,0,1,1,1.641-1.64,1.64,1.64,0,0,1-1.641,1.64Z"
      fill="#676e7b"
    />
  </svg>,
  'InnovationIcon'
);
export default InnovationIcon;
