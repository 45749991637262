import React from 'react';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import DialogContent from '@mui/material/DialogContent';
import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from 'app/config/store';
import toast from 'react-hot-toast';

interface CreateActivityModalProps {
  handleClose: () => void;
  fullScreen: boolean;
  open: boolean;
}
const CreateActivityModal = ({ handleClose, fullScreen, open }: CreateActivityModalProps) => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const postingLimit = useAppSelector(state => state.profileReducer.postingLimit);
  const navigate = useNavigate();

  const handleNavigation = type => {
    if (type === 'ACTIVITY') {
      if (postingLimit?.activityPostLimitReached) {
        toast.dismiss();
        toast.error("You've hit the daily limit for activities today. Try again tomorrow!", {
          icon: 'ℹ️',
          style: {
            background: '#f8fafc',
            border: '1px solid #cbd5e0',
            color: '#2d3748',
          },
        });
      } else {
        navigate('/create-activity');
      }
    } else {
      if (postingLimit?.petitionPostLimitReached) {
        toast.dismiss();
        toast.error("You've hit the daily limit for petitions today. Try again tomorrow!", {
          icon: 'ℹ️',
          style: {
            background: '#f8fafc',
            border: '1px solid #cbd5e0',
            color: '#2d3748',
          },
        });
      } else {
        navigate('/create-petition');
      }
    }
  };

  return (
    <Dialog

      open={open}
      onClose={handleClose}
      aria-labelledby="modal"
      PaperProps={{
        sx: {
          borderRadius: { xs: "15px", sm: '15px' },
          boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
          m:1.5
        },
      }}
    >
      <style>
        {`    .css-mvtyb9-MuiPaper-root-MuiDialog-paper {
        margin:10px !important;
    }`}
    {`.css-19z0m3f-MuiPaper-root-MuiDialog-paper {
     margin:10px !important;}`}
      </style>
      <DialogContent sx={{ padding: isSmallDevice ? '35px' : '32px', maxWidth: isSmallDevice ? '100%' : 500 }}>
        <Grid direction={'column'} spacing={2} container alignItems={'center'} justifyContent={'center'}>
          <Grid item>
            <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item>
            {isSmallScreen ? (
              <Typography variant="h6">What would you like to create?</Typography>
            ) : (
              <Typography variant="h4">What would you like to post?</Typography>
            )}
          </Grid>
          <Grid item>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} gap={2} p={2}>
              <Button
                sx={{
                  backgroundColor: 'primary.light',
                  color: 'common.white',
                  border: 'none',
                  '&:hover': {
                    backgroundColor: 'primary.light',
                    color: 'common.white',
                    border: 'none',
                  },
                }}
                variant="outlined"
                onClick={() => handleNavigation('ACTIVITY')}
              >
                Activity
              </Button>
              <Button
                sx={{
                  backgroundColor: 'primary.light',
                  color: 'common.white',
                  border: 'none',
                  '&:hover': {
                    backgroundColor: 'primary.light',
                    color: 'common.white',
                    border: 'none',
                  },
                }}
                variant="outlined"
                onClick={() => handleNavigation('PETITION')}
                autoFocus
              >
                Petition
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CreateActivityModal;
