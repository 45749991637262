import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Box, Typography, useTheme } from '@mui/material';
import './payment-reponse.scss';
import { Check, Download, Error, Redo } from '@mui/icons-material';
import LoaderIcon from '../../loader-icon/loader-icon';
import axios from 'axios';
export function truncateString(str, length) {
  if (str.length > length) {
    const halfLength = Math.floor((length - 3) / 2); // Calculate half the length excluding "..."
    const firstHalf = str.slice(0, halfLength);
    const secondHalf = str.slice(-halfLength);
    return `${firstHalf}...${secondHalf}`;
  }
  return str;
}

type PayementResponseProps = {
  id?: string;
  open?: boolean;
  handleShow?: (value?: boolean) => void;
  keepMounted?: boolean;
  handleOptionChange?: (event: any) => void;
  handleCancel?: () => void;
  isSuccess?: boolean;
  handleClose: () => void;
};

const PaymentResponseModal = ({ open, handleShow, keepMounted, handleCancel, isSuccess, handleClose }: PayementResponseProps) => {
  const radioGroupRef = useRef<HTMLElement | null>(null);
  const theme = useTheme();
  const [downloadLoading, setDownloadLoading] = useState(false);

  const isDarkMode = theme.palette.mode === 'dark';

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleOk = () => {
    handleShow(false);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {};

  const textColor = isDarkMode ? '#fff' : '#636363';
  const downloadInvoice = () => {
    console.log('to check invoice');
    const eventId = JSON.parse(localStorage.getItem('passEventId'));
    const eventType = JSON.parse(localStorage.getItem('passEventType'));
    fetchDownload(eventId, eventType);
  };

  const fetchDownload = async (eventId: any, eventType: any) => {
    try {
      const response = await axios.get<any>(`/api/payment-service/event/${eventId}/invoice/${eventType}/download`);

      console.log('check mine', response);
    } catch (error) {
      console.log('Error fetching activities:', error);
    }
  };

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': { width: '80%', overflow: 'unset' },
        '& .MuiDialog-paper::-webkit-scrollbar': {},
      }}
      open={open}
      keepMounted={keepMounted}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
    >
      <Box>
        <div className="modal-dialog modal-confirm">
          <div className="modal-content">
            <div className="modal-header">
              <Box className="icon-box" borderColor={isSuccess ? '#82ce34' : '#e43d4d'}>
                {isSuccess ? <Check sx={{ fontSize: 50, color: '#82ce34' }} /> : <Error sx={{ fontSize: 50, color: '#e43d4d' }} />}
              </Box>
              <h4 className="modal-title" style={{ color: textColor }}>
                {isSuccess ? 'Awesome!' : 'Oops!'}
              </h4>
            </div>
            <div className="modal-body">
              <p className="text-center" style={{ color: textColor }}>
                {isSuccess
                  ? 'This activity has been featured. Check your email for detials.'
                  : 'Your paymet has been failed. You can try again.'}
              </p>
            </div>
            <div className="modal-footer">
              {/* <Button
                disableElevation
                disableRipple
                size="small"
                sx={{
                  marginTop: 0.7,
                  marginBottom: 0.7,
                  width: '100%',
                  padding: '6px 9px',
                  borderRadius: 1,
                  border: '1px solid #674BFF',
                  background: '#674BFF',
                  color: '#fff',
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: '#674BFF',
                    border: '1px solid #674BFF  ',
                  },
                  '&.MuiButton-root.Mui-disabled': {
                    color: 'primary.light',
                    border: '1px solid #674BFF',
                  },
                }}
                startIcon={isSuccess ? <Download /> : <Redo />}
                variant="outlined"
                onClick={downloadInvoice}
                endIcon={downloadLoading ? <LoaderIcon color="#fff" /> : null}
                disabled={downloadLoading}
              >
                {isSuccess ? 'Invoice' : 'Retry'}
              </Button> */}
              <Button
                disableElevation
                disableRipple
                size="small"
                sx={{
                  marginTop: 0.7,
                  marginBottom: 0.7,
                  width: '100%',
                  color: 'primary.light',
                  padding: '6px 9px',
                  borderRadius: 1,
                  border: '1px solid #674BFF',
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: 'transparent',
                    border: '1px solid #674BFF  ',
                  },
                  '&.MuiButton-root.Mui-disabled': {
                    color: 'primary.light',
                    border: '1px solid #674BFF',
                  },
                }}
                variant="outlined"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </Box>
    </Dialog>
  );
};

export default PaymentResponseModal;
