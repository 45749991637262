import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useAppDispatch } from 'app/config/store';
import {
  clearSearchKeyword,
  clearExistingActivities,
  updateFilterProperties,
  clearSearhedActivities,
} from 'app/shared/reducers/activity-filter';
import { logout, unregisterDevice } from 'app/shared/reducers/authentication';
import { clearPhoneVerification } from 'app/shared/reducers/phone-verification';
import { clearUserPreferences } from 'app/shared/reducers/user-preferences';
import axios from 'axios';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

interface JoiningSuccessModalProps {
  handleClose: () => void;
  fullScreen: boolean;
  open: boolean;
}

function DeactivateModal({ handleClose, fullScreen, open }: JoiningSuccessModalProps) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [selectedOption, setSelectedOption] = useState('deactivate');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption((event.target as HTMLInputElement).value);
  };

  const handleContinue = async () => {
    try {
      if (selectedOption === 'deactivate') {
        const resp = await axios.post<any>(`/api/profile/deactivate-user`);
        if (resp) {
          const fcmTokenId = localStorage.getItem('firebaseToken');
          handleClose();
          dispatch(logout());
          dispatch(clearSearchKeyword());
          dispatch(clearExistingActivities());
          dispatch(clearSearhedActivities());
          dispatch(clearUserPreferences());
          dispatch(clearPhoneVerification());
          dispatch(unregisterDevice({ deviceId: localStorage.getItem('randomDeviceId'), fcmTokenId }));
          Cookies.remove('jhi-authenticationToken');
          localStorage.clear();
          sessionStorage.clear();
          toast.success(resp?.data?.message);
          navigate('/');
        }
      } else if (selectedOption === 'delete') {
        const deleteResponse = await axios.post<any>(`/api/profile/delete-user`);
        if (deleteResponse) {
          handleClose();
          dispatch(logout());
          dispatch(clearSearchKeyword());
          dispatch(clearExistingActivities());
          dispatch(clearSearhedActivities());
          dispatch(clearUserPreferences());
          dispatch(clearPhoneVerification());
          Cookies.remove('jhi-authenticationToken');
          localStorage.clear();
          sessionStorage.clear();
          toast.success(deleteResponse?.data?.message);
          navigate('/');
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="modal"  sx={{
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        borderRadius: '15px', // Set the border radius here
      },
    },
  }}>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="h6" align="center">
            Please choose an option
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup aria-label="options" name="options" value={selectedOption} onChange={handleOptionChange}>
              <FormControlLabel
                value="deactivate"
                control={
                  <Radio
                    sx={{
                      color: isDarkMode ? '#FFCE00' : '#674BFF',
                      '&.Mui-checked': {
                        color: isDarkMode ? '#FFCE00' : '#674BFF',
                      },
                    }}
                  />
                }
                label={
                  <div>
                    <Typography>Deactivate</Typography>
                    <Typography variant="body2">
                      Your profile will be deactivated but not deleted. Please note that while your account is deactivated, you will not be
                      able to use any features of the system. If you choose to reactivate your account, your previous profile and data will
                      be restored.
                    </Typography>
                  </div>
                }
              />
              <FormControlLabel
                className="mt-2"
                value="delete"
                control={
                  <Radio
                    sx={{
                      color: isDarkMode ? '#FFCE00' : '#674BFF',
                      '&.Mui-checked': {
                        color: isDarkMode ? '#FFCE00' : '#674BFF',
                      },
                    }}
                  />
                }
                label={
                  <div>
                    <Typography>Delete</Typography>
                    <Typography variant="body2">
                      Your profile will be permanently removed. Please note that if you sign up again using the same email or phone number,
                      you will start with a blank profile.
                    </Typography>
                  </div>
                }
              />
            </RadioGroup>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions sx={{
        paddingRight: "24px";
    paddingBottom: "20px";
      }}>
        <Button
          onClick={handleClose}
          sx={{
            color: isDarkMode ? '#FFCE00' : '#674BFF',

            '&:hover': {
              color: isDarkMode ? '#FFCE00' : '#674BFF',
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleContinue}
          sx={{
            backgroundColor: isDarkMode ? '#FFCE00' : '#674BFF',
            color: '#ffff',
            '&:hover': {
              backgroundColor: isDarkMode ? '#FFCE00' : '#674BFF',
              color: '#ffff',
            },
          }}
          variant="contained"
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeactivateModal;
