import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { serializeAxiosError } from '../reducer.utils';
import toast from 'react-hot-toast';

export interface SettingsAccountDetails {
  loading: boolean;
  error: object | null | string;
  success: object | null | string;
  userSettings: object | null;
  sendSettingsSuccess: object | null | string;
  sendSettingsError: object | null | string;
}
interface settingsAccountParams {
  settings: object;
  id: string;
}
const initialState = {
  loading: false,
  success: null,
  error: null,
  userSettings: null,
  sendSettingsSuccess: false,
  sendSettingsError: false,
};
const settingsEndpoint = '/api/profile/settings/account-details/';

export const getSettingsAccount = createAsyncThunk('settings/account/get', async (id: number) => axios.get<any>(settingsEndpoint + id), {
  serializeError: serializeAxiosError,
});

export const sendSettingsAccount = createAsyncThunk(
  'settings/account/send',
  async ({ settings, id }: settingsAccountParams) => axios.put<any>(settingsEndpoint + id, settings),
  {
    serializeError: serializeAxiosError,
  }
);

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getSettingsAccount.pending, state => {
        state = initialState;
      })
      .addCase(getSettingsAccount.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.userSettings = action.payload.data;
      })
      .addCase(getSettingsAccount.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message;
        state.userSettings = null;
      })
      .addCase(sendSettingsAccount.pending, state => {
        state = initialState;
      })
      .addCase(sendSettingsAccount.fulfilled, (state, action) => {
        state.loading = false;
        state.sendSettingsSuccess = action.payload.data;
        state.sendSettingsError = null;
        toast.success(action.payload?.data?.message);
      })
      .addCase(sendSettingsAccount.rejected, (state, action) => {
        state.loading = false;
        state.sendSettingsSuccess = null;
        state.sendSettingsError = true;
        toast.error('Something went wrong');
      });
  },
});

export default settingsSlice.reducer;
