// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img {
  display: block;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.blue {
  color: #674bff;
}

.home-ba-graph canvas {
  width: 100% !important;
}

.home-bar-graph .MuiGrid-item.MuiGrid-grid-xs-12 {
  display: flex;
}

.dashboard-tabs .css-15v19k8-MuiTabs-indicator {
  height: 3px;
  border-radius: 1px;
  bottom: 5px;
}

.gradient-fade::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px; /* Adjust the height of the gradient fade */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(255, 255, 255));
  pointer-events: none;
  z-index: 0;
}

.dark-gradient-fade::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px; /* Adjust the height of the gradient fade */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(44, 44, 56));
  pointer-events: none;
  z-index: 0;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/ui/dashboard/dashboard.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;EACA,oBAAA;KAAA,iBAAA;AACF;;AACA;EACE,cAAA;AAEF;;AAAA;EACE,sBAAA;AAGF;;AAAA;EACE,aAAA;AAGF;;AAcE;EACE,WAAA;EACA,kBAAA;EACA,WAAA;AAXJ;;AAeA;EACE,WAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA,EAAA,2CAAA;EACA,kFAAA;EACA,oBAAA;EACA,UAAA;AAZF;;AAcA;EACE,WAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA,EAAA,2CAAA;EACA,+EAAA;EACA,oBAAA;EACA,UAAA;AAXF","sourcesContent":[".img {\n  display: block;\n  width: 100%;\n  object-fit: cover;\n}\n.blue {\n  color: #674bff;\n}\n.home-ba-graph canvas {\n  width: 100% !important;\n}\n\n.home-bar-graph .MuiGrid-item.MuiGrid-grid-xs-12 {\n  display: flex;\n}\n// #bar-graph-canvas {\n//   @media (max-width: 768px) {\n//     height: 213px !important;\n//   }\n//   @media (max-width: 600px) {\n//     height: 190px !important;\n//   }\n//   @media (max-width: 480px) {\n//     height: 117px !important;\n//   }\n//   @media (max-width: 360px) {\n//     height: 100px !important;\n//   }\n// }\n.dashboard-tabs {\n  .css-15v19k8-MuiTabs-indicator {\n    height: 3px;\n    border-radius: 1px;\n    bottom: 5px;\n  }\n}\n\n.gradient-fade::before {\n  content: '';\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  height: 30px; /* Adjust the height of the gradient fade */\n  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));\n  pointer-events: none;\n  z-index: 0;\n}\n.dark-gradient-fade::before {\n  content: '';\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  height: 30px; /* Adjust the height of the gradient fade */\n  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(44 44 56));\n  pointer-events: none;\n  z-index: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
