import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ActivityMaster from './activity-master';
import ActivityMasterDetail from './activity-master-detail';
import ActivityMasterUpdate from './activity-master-update';
import ActivityMasterDeleteDialog from './activity-master-delete-dialog';

const ActivityMasterRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ActivityMaster />} />
    <Route path="new" element={<ActivityMasterUpdate />} />
    <Route path=":id">
      <Route index element={<ActivityMasterDetail />} />
      <Route path="edit" element={<ActivityMasterUpdate />} />
      <Route path="delete" element={<ActivityMasterDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ActivityMasterRoutes;
