import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import SideNavigation from 'app/components/layout/side-navigaion/side-navigation';
import PrimaryHeader from 'app/components/layout/header/primary-header';
import { fetchProfile, fetchUnAuthorizedProfile } from 'app/shared/reducers/profile';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import AmyIcon from 'app/components/common/cards/chat-card/amy-icons';
import toast from 'react-hot-toast';
import { Button, Grid, List, useMediaQuery } from '@mui/material';
import AuthorisationModal from 'app/components/common/authorization-modal/authorization-modal';
import { resetLogin, toggleModalLogin } from 'app/shared/reducers/authentication';
import axios from 'axios';
import './about-us.css';
import NewPrimarySearchAppBar from 'app/components/layout/header/new-primary-header';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Container, CardContent, CardMedia, ListItem, Link } from '@mui/material';


const drawerWidth = 315;
const drawerWidthSm = 248;

interface Props {
  window?: () => Window;
}
const AboutUs = () => {
  // const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [currentComponent, setCurrentComponent] = React.useState(' ');
  const [authModalOpen, setAuthModalOpen] = React.useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDarkMode = theme.palette.mode === 'dark';
  const [toggleValue, setToggleValue] = useState(false);
  const [toggleMapValue, setToggleMapValue] = useState(false);
  const [searchKeyword, setSearchKeyword] = React.useState<string>('');
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [routeBack, setRouteBack] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const location = useLocation();

  const isBackHome = location.state?.isBackHome;
  const [htmlContent, setHtmlContent] = useState('');
  // const isBackHome = true;
  const fetchAboutUs = async () => {
    try {
      const response = await axios.get<any>(`api/staticContent/about-us`);
      console.log('about us', response);
      setHtmlContent(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAboutUs();
  }, []);
  useEffect(() => {
    if (isBackHome) {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener('popstate', e => {
        e.preventDefault();
        window.history.pushState(null, null, window.location.pathname);
      });
    }
  }, []);

  const handleSubCategoryClick = (category: string, subCategory: string) => {
    setCategory(category);
    setSubCategory(subCategory);
  };
  const handleAccordionClick = (category: any, currentState: any) => {
    setCategory(category);
  };
  const handleNavigationArrowButton = () => {
    if (fieldsChanged) {
      setRouteBack(true);
    } else if (isBackHome) {
      navigate('/');
    } else {
      navigate(-1);
    }
  };
  const handleButtonClicked = () => {
    const newToggleValue = !toggleValue;
    if (toggleMapValue) {
      setToggleMapValue(false);
    }
    setToggleValue(newToggleValue);
  };

  const handleButtonMapClicked = () => {
    const newToggleMapValue = !toggleMapValue;
    if (toggleValue) {
      setToggleValue(false);
    }
    setToggleMapValue(newToggleMapValue);
  };
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));
  const StyledCard = styled(Card)({
    padding: '15px',
    borderRadius: '8px',
    textAlign: 'left',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  });

  const StyledButton = styled(Button)({
    backgroundColor: '#FFCE00',
    color: '#333333',
    borderRadius: '8px',
    fontSize: '12px',
    fontWeight: '500',
    padding: '15px 30px',
    textTransform: 'none',
    marginTop: '20px',
  });
  const StyledList = styled(List)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column', // Change to column for better vertical alignment
    padding: 0,
    margin: 0,
  }));

  const StyledListItem = styled(ListItem)(({ theme }) => ({
    padding: 0,
  }));

  const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: '#674bff',
    '&:hover': {
      textDecoration: 'underline',
    },
  }));
  const handleButtonFeatureClicked = () => { };

  return (
    <Box>
      <NewPrimarySearchAppBar searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} />
      <div className="container-fluid p-0" style={{ background: isDarkMode ? '#1F1F26' : '' }}>
        <div className="row ">
          <div>
            <div className="custome__container" style={{ height: '88vh' }}>
              <h3 className="title" style={{ color: isDarkMode ? 'white' : '' }}>
                About Us
              </h3>
              <Card style={{ background: isDarkMode ? '#2c2c38' : '' ,}}>
                <div className="p-4">
                  <Typography style={{ color: isDarkMode ? 'white' : '' }}>
                    This site is dedicated to Zoe, Kishmish and Tashi, three beloved dogs who sparked our journey to leverage geolocation
                    technology to mobilize help quickly. They inspired features like our Urgent category, which includes tools for
                    generating lost pet posters, and facilitating rapid responses from caring individuals. Tashi serves as a constant
                    reminder of the incredible joy and companionship that rescued pets can bring into our lives.They were the starting point
                    from which other ideas naturally evolved, that make volunteering more accessible and structured for Blood Donation,
                    Disaster Relief, UN Sustainable Development Goals (SDG) goals, Animal Rescue, Tree Planting, Student Mentoring, and many
                    other impactful initiatives.
                  </Typography>
                  <Typography className="mt-2" style={{ color: isDarkMode ? 'white' : '' }}>
                    This site also serves as a tribute to the compassionate individuals who rise to the occasion and lend a hand to those in
                    need. It recognizes and celebrates their invaluable efforts.
                  </Typography>
                </div>
                <div className="costome__col">
                  <div className="custome__row justify-content-center">
                    <div style={{ textAlign: 'center' }}>
                      <div className="img__wrap">
                        <img src="https://images.qa.reachamy.com/20240726095757_Zoe.jpeg" title="" />
                      </div>
                      <p className="img__titile" style={{ color: isDarkMode ? 'white' : '' }}>
                        ZOE
                      </p>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <div className="img__wrap">
                        <img src="https://images.qa.reachamy.com/20240730092823_20240726095848_kishmish.jpg" title="" />
                      </div>
                      <p className="img__titile" style={{ color: isDarkMode ? 'white' : '' }}>
                        KISHMISH
                      </p>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <div className="img__wrap">
                        <img src="https://images.qa.reachamy.com/20240803045958_WhatsApp_Image_2024-08-02_at_6.14.35_PM.jpeg" title="" />
                      </div>
                      <p className="img__titile" style={{ color: isDarkMode ? 'white' : '' }}>
                        TASHI
                      </p>
                    </div>
                  </div>
                </div>
              </Card>
              <Box sx={{ my: 4 }}>
                <div className="custome__row sec-two-mob">
                  <div className="custome__col" style={{ padding: '20px', marginTop: '-10px' }}>
                    <div className="title mt-3 mx-0 ">FAQ</div>
                    <p>
                      Please refer to the following link:
                      <a className="ms-1" onClick={() => navigate('/faq')}>
                        https://reachamy.com/faq
                      </a>
                    </p>
                  </div>
                  <div className="custome__col">
                    <div className="bg-color" style={{ backgroundColor: isDarkMode ? '' : '#faffdb' }}>
                      <div className="title mx-0" style={{ color: isDarkMode ? '#fff' : '#000000' }}>
                        {' '}
                        Contact Us
                      </div>
                      <p style={{ color: isDarkMode ? '#fff' : '#000000' }}>
                        For general inquiries, customer support, or any other questions, please reach out to us using the contact details
                        below:
                      </p>
                      <p style={{ color: isDarkMode ? '#fff' : '#000000' }}>
                        <b>Email:</b>{' '}
                        <a href="mailto:contact@reachamy.com" style={{ color: isDarkMode ? '#FFCE00;' : '#674bff' }}>
                          contact@reachamy.com
                        </a>
                      </p>
                      <p style={{ color: isDarkMode ? '#fff' : '#000000' }}>
                        <b>Address:</b>{' '}
                        <span style={{ color: isDarkMode ? '#fff' : '#000000' }}>
                          254 Chapman Rd, Ste 208 #15139<br></br>Newark, DE 19702 US
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </Box>

              <div className="feedback23 pt-3" style={{ backgroundColor: isDarkMode ? '' : '#ebfaff',borderRadius:"4px" }}>
                {/* <h3 className="title">Feedback and Suggestions</h3> */}
                <div className="title" style={{ color: isDarkMode ? '#ffff' : '#000000', marginLeft: '20px' }}>
                  Feedback and Suggestions
                </div>
                <p style={{ paddingInline: '20px', color: isDarkMode ? '#ffff' : '#000000' }}>
                  Your feedback is important to us as we strive to improve our platform and services. If you have ideas for new features,
                  suggestions for enhancements, or simply want to share your thoughts on your experience with ReachAMY Inc., please let us
                  know. We appreciate your input and will carefully consider all feedback to continue providing a valuable and user-friendly
                  experience for our community.
                </p>
                {/* <p>
                  We appreciate your input and will carefully consider all feedback to continue providing a valuable and user-friendly
                  experience for our community.
                </p> */}
                <p style={{ paddingInline: '20px', color: isDarkMode ? '#ffff' : '#000000' }}>
                  <b>Email : </b>{' '}
                  <a href="mailto:feedback@reachamy.com" style={{ color: isDarkMode ? '#FFCE00;' : '#674bff' }}>
                    feedback@reachamy.com
                  </a>
                </p>
                <br />
              </div>
              <Box className="mt-3">
                <Typography variant="body1" paragraph>
                  <strong style={{ padding: '20px' }}>Other Relevant Links :</strong>
                </Typography>
                <StyledList sx={{ display: 'flex', flexDirection: 'row' }}>
                  <StyledListItem>
                    <StyledLink sx={{ color: isDarkMode ? '#FFCE00;' : '#674bff' }} onClick={() => navigate('/community-guidelines')}>
                      Community Guidelines
                    </StyledLink>
                  </StyledListItem>
                  <StyledListItem>
                    <StyledLink sx={{ color: isDarkMode ? '#FFCE00;' : '#674bff' }} onClick={() => navigate('/privacy-policy')}>
                      Privacy Policy
                    </StyledLink>
                  </StyledListItem>
                  <StyledListItem>
                    <StyledLink sx={{ color: isDarkMode ? '#FFCE00;' : '#674bff' }} onClick={() => navigate('/cookie-policy')}>
                      Cookie Policy
                    </StyledLink>
                  </StyledListItem>
                  <StyledListItem>
                    <StyledLink sx={{ color: isDarkMode ? '#FFCE00;' : '#674bff' }} onClick={() => navigate('/legal-notices')}>
                      Legal Notices
                    </StyledLink>
                  </StyledListItem>
                  <StyledListItem>
                    <StyledLink sx={{ color: isDarkMode ? '#FFCE00;' : '#674bff' }} onClick={() => navigate('/terms-and-condition')}>
                      Terms and Conditions
                    </StyledLink>
                  </StyledListItem>
                </StyledList>
              </Box>

            </div>

            {/* <div className="custome__container"></div> */}
          </div>
        </div>
        <style>
          {`
                  .custome__container::-webkit-scrollbar {
                    width: 0px; 
                  }
                  .custome__container::-webkit-scrollbar-track {
                    background: transparent;
                  }
ul.lst-kix_io13e9n894ak-8 {
  list-style-type: none;
}
ul.lst-kix_io13e9n894ak-7 {
  list-style-type: none;
}
ul.lst-kix_io13e9n894ak-6 {
  list-style-type: none;
}
ul.lst-kix_io13e9n894ak-1 {
  list-style-type: none;
}
ul.lst-kix_io13e9n894ak-0 {
  list-style-type: none;
}
ul.lst-kix_io13e9n894ak-5 {
  list-style-type: none;
}
ul.lst-kix_io13e9n894ak-4 {
  list-style-type: none;
}
ul.lst-kix_io13e9n894ak-3 {
  list-style-type: none;
}
ul.lst-kix_io13e9n894ak-2 {
  list-style-type: none;
}

ul.lst-kix_xv32l98g6fb6-8 {
  list-style-type: none;
}
ul.lst-kix_xv32l98g6fb6-7 {
  list-style-type: none;
}
ul.lst-kix_xv32l98g6fb6-6 {
  list-style-type: none;
}
ul.lst-kix_xv32l98g6fb6-5 {
  list-style-type: none;
}
ul.lst-kix_xv32l98g6fb6-4 {
  list-style-type: none;
}

ul.lst-kix_xv32l98g6fb6-3 {
  list-style-type: none;
}
ul.lst-kix_m9u0auna7jc-0 {
  list-style-type: none;
}
ul.lst-kix_xv32l98g6fb6-2 {
  list-style-type: none;
}
ul.lst-kix_m9u0auna7jc-1 {
  list-style-type: none;
}
ul.lst-kix_xv32l98g6fb6-1 {
  list-style-type: none;
}
ul.lst-kix_m9u0auna7jc-2 {
  list-style-type: none;
}

ul.lst-kix_xv32l98g6fb6-0 {
  list-style-type: none;
}
ul.lst-kix_m9u0auna7jc-3 {
  list-style-type: none;
}
ul.lst-kix_m9u0auna7jc-4 {
  list-style-type: none;
}
ul.lst-kix_m9u0auna7jc-5 {
  list-style-type: none;
}
ul.lst-kix_m9u0auna7jc-6 {
  list-style-type: none;
}

ul.lst-kix_m9u0auna7jc-7 {
  list-style-type: none;
}
ul.lst-kix_h3el5qv8tqxp-6 {
  list-style-type: none;
}
ul.lst-kix_m9u0auna7jc-8 {
  list-style-type: none;
}
ul.lst-kix_h3el5qv8tqxp-7 {
  list-style-type: none;
}
ul.lst-kix_h3el5qv8tqxp-4 {
  list-style-type: none;
}
ul.lst-kix_h3el5qv8tqxp-5 {
  list-style-type: none;
}
ul.lst-kix_h3el5qv8tqxp-8 {
  list-style-type: none;
}

ul.lst-kix_h3el5qv8tqxp-2 {
  list-style-type: none;
}
ul.lst-kix_h3el5qv8tqxp-3 {
  list-style-type: none;
}

ul.lst-kix_h3el5qv8tqxp-0 {
  list-style-type: none;
}
ul.lst-kix_h3el5qv8tqxp-1 {
  list-style-type: none;
}


li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}

ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.c2 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c1 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
}
.c3 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c4 {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c6 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
}
.c10 {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  color: #1155cc;
  text-decoration: underline;
}
.c9 {
  font-size: 10.5pt;
  font-family: 'Roboto';
  color: #30313d;
  font-weight: 400;
}
.c11 {
  background-color: #ffffff;
  max-width: 468pt;
  padding: 72pt 72pt 72pt 72pt;
}
.c7 {
  padding: 0;
  margin: 0;
}
.c8 {
  margin-left: 36pt;
  padding-left: 0pt;
}
.c12 {
  color: inherit;
  text-decoration: inherit;
}
.c5 {
  height: 11pt;
}
.c0 {
  font-weight: 700;
}
.title {
  font-size: 26px;

  margin-bottom: 30px;
  font-weight: bold;
}
.subtitle {
  font-size: 15pt;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 30px;
}
li {
  color: #000000;
  font-size: 11pt;
  font-family: 'Poppins', sans-serif;
}
p {
 
  font-size: 11pt;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 15px;
}
h1 {
  padding-top: 20pt;
  color: #000000;
  font-size: 20pt;
  padding-bottom: 6pt;
  font-family: 'Poppins', sans-serif;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;
  font-family: 'Poppins', sans-serif;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h3 {
  color: #434343;
  font-size: 26px;
  font-family: 'Poppins', sans-serif;
}
h4 {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;
  font-family: 'Poppins', sans-serif;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h5 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: 'Poppins', sans-serif;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
h6 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: 'Poppins', sans-serif;
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.custome__row {
  display: flex;
  width: 100%;
  gap: 20px;
}
.custome__container {
  max-width: 800px;
  width: 100%;
  margin: auto;
  padding-block: 40px;
  height: 600px;
  overflow-y: scroll;
}
.items-center {
  align-items: center;
}
.img__wrap {
  width: 150px;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
}
.img__wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card {
  padding: 20px 30px;
  box-shadow: 0 0 10px 10px #f4f8f9;
}
.img__titile {
  font-size: 16px;
  font-weight: 500;
}
.custome__container ul {
    display: flex;
    width: 100%;
    justify-content: space-between;
    list-style: none;
    margin-left: 20px;
}
.custome__container ul a,
.custome__container ul li {
  text-decoration: none;
  
  font:inherit;
}
.feedback {
  background-color: #ebfaff;
  margin-top: 60px;
  padding-inline: 20px;
  border-radius: 10px;
}
.feedback23{

  margin-top: 60px;
  width:100%
  border-radius: 10px;
}
.bg-color {
 
  padding: 20px 20px;
  border-radius: 10px;
}

.html-content-container {
  height: 300px !important;
}

                
                `}
        </style>
      </div>
    </Box>
  );
};

export default AboutUs;
