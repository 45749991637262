import React, { useEffect } from 'react';
import { Grid, Box, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import MultiBarChart from '../general/multi-bar-graph';
import BottomBar from '../general/bottom-bar';
import ActualGoalCard from '../general/actual-goal-card';
import LineGraph from '../general/line-graph';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  fetchGoalCountsForIndividual,
  fetchLatestAndPreviousActivityCounts,
  fetchSocialMediaCountsIndividual,
} from 'app/shared/reducers/dashboard/dashboard';
import { convertDateToDesiredFormat, formatDate } from 'app/utils/common-utils/common-utils';
import ColoredBox from '../general/graph/colored-box';
import TopLabel from '../general/graph/top-label';
import DoughnutCard from '../home/doughnut-card';

export default function Individuals() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const isSmallScreen = useMediaQuery('(max-width: 640px)');
  const latestActivityShareCounts = useAppSelector(state => state.dashboardReducer.data.latestActivityShareCounts.successResponse);
  const latestAndPreviousActivityCounts = useAppSelector(
    state => state.dashboardReducer.data.latestAndPreviousActivityCounts.successResponse
  );
  const individualSocialMediaCounts = useAppSelector(state => state.dashboardReducer.data.individualSocialMediaCounts.successResponse);
  const goalCountsForIndividual = useAppSelector(state => state.dashboardReducer.data.goalCountsForIndividual.successResponse);

  const myProfileId = useAppSelector(state => state.authentication.account.id);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (myProfileId) {
      dispatch(fetchSocialMediaCountsIndividual());
      dispatch(fetchLatestAndPreviousActivityCounts(myProfileId));
      dispatch(fetchGoalCountsForIndividual(myProfileId));
    }
  }, [myProfileId]);

  const latestStartDate = formatDate(latestAndPreviousActivityCounts?.latestStartDate, 'DD-MMM-YYYY');
  const previousStartDate = formatDate(latestAndPreviousActivityCounts?.previousStartDate, 'DD-MMM-YYYY');

  // const dummyData = [
  //   {
  //     media: 'WhatsApp',
  //     startDate: '07-Jan-2024',
  //     endDate: '07-Feb-2024',
  //     shareCount: [
  //       {
  //         date: '06-Feb-2024',
  //         count: 24,
  //       },
  //       {
  //         date: '07-Feb-2024',
  //         count: 10,
  //       },
  //     ],
  //   },
  //   {
  //     media: 'Facebook',
  //     startDate: '07-Jan-2024',
  //     endDate: '07-Feb-2024',
  //     shareCount: [
  //       {
  //         date: '10-Jan-2024',
  //         count: 2,
  //       },
  //     ],
  //   },
  //   {
  //     media: 'X',
  //     startDate: '07-Jan-2024',
  //     endDate: '07-Feb-2024',
  //     shareCount: [
  //       {
  //         date: '01-Feb-2024',
  //         count: 75,
  //       },
  //     ],
  //   },
  // ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} display="flex">
        <Box bgcolor={isDarkMode ? '#2c2c38' : '#fff'} position="relative" overflow="hidden" py={2.9} px={2} flex="1" borderRadius={1}>
          <MultiBarChart
            width="94%"
            graphData={latestAndPreviousActivityCounts}
            latestStartDate={latestAndPreviousActivityCounts?.latestStartDate}
            previousStartDate={latestAndPreviousActivityCounts?.previousStartDate}
            latestData={latestAndPreviousActivityCounts?.latestActivityCounts}
            prevData={latestAndPreviousActivityCounts?.previousActivityCounts}
          />
          <Box display="flex" alignItems="center" justifyContent="center" gap={2}>
            <ColoredBox
              color="#674BFF"
              label="30 days"
              startDate={latestStartDate}
              count={latestAndPreviousActivityCounts?.latestActivityTotalCount}
            />
            <ColoredBox
              color="#EC6666"
              label="30 days"
              startDate={previousStartDate}
              count={latestAndPreviousActivityCounts?.previousActivityTotalCount}
            />
          </Box>

          <BottomBar />
        </Box>
      </Grid>

      <Grid item xs={12} md={6} display="flex">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          bgcolor={isDarkMode ? '#2c2c38' : '#fff'}
          position="relative"
          overflow="hidden"
          py={2.9}
          px={2}
          flex="1"
          borderRadius={1}
        >
          <Box px={isSmallScreen ? 0 : 1.5} mb={3} display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Typography fontSize="0.9rem" fontWeight="bold" component="h5">
              Social Medias
            </Typography>
            <Box display="flex" alignItems="center" gap={1.6}>
              <TopLabel color="#0b62f7" label="Facebook" />
              <TopLabel color="#40be4f" label="WhatsApp" />
              <TopLabel color="#707070" label="X" />
              <TopLabel color="#ff8f00" label="LinkedIn" />
            </Box>
          </Box>

          <LineGraph shareData={individualSocialMediaCounts} />

          <Typography margin="0 auto" variant="body2" fontWeight="600" color="#798498" fontSize="0.7rem">
            {convertDateToDesiredFormat(individualSocialMediaCounts && individualSocialMediaCounts[0]?.startDate)} -{' '}
            {convertDateToDesiredFormat(individualSocialMediaCounts && individualSocialMediaCounts[0]?.endDate)}
          </Typography>
          <BottomBar />
        </Box>
      </Grid>
      <Grid container spacing={1.7} py={3} ml={0}>
        {goalCountsForIndividual?.length > 0 &&
          goalCountsForIndividual.map((goal, index) => (
            <Grid display="flex" item xs={12} md={6} lg={3}>
              <DoughnutCard
                key={index}
                name={goal?.goalName}
                achieved={goal?.achievedGoal}
                target={goal?.targetGoal}
                startDate={goal?.startDate}
                endDate={goal?.endDate}
                currencyCode={goal?.currencyCode}
              />
            </Grid>
          ))}
      </Grid>
      {/* 
      <Grid container spacing={1.7} py={3} ml={0}>
        {goalCountsForIndividual?.length > 0 &&
          goalCountsForIndividual.map((goal, index) => (
            <Grid key={index} item xs={12} sm={6} md={4}>
              <ActualGoalCard key={index} goal={goal} />
            </Grid>
          ))}
      </Grid> */}
    </Grid>
  );
}
