import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const SquareChatIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20">
    <g id="square-chat-4_24x24_1x" data-name="square-chat-4(24x24)@1x" transform="translate(-1.5 -1.5)">
      <path
        id="Path_2693"
        data-name="Path 2693"
        d="M7,7v9a1,1,0,0,0,1,1H18l4,4V7a1,1,0,0,0-1-1H8A1,1,0,0,0,7,7Z"
        fill="none"
        stroke="#676E7B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_2694"
        data-name="Path 2694"
        d="M7,7A1,1,0,0,1,8,6h9V3a1,1,0,0,0-1-1H3A1,1,0,0,0,2,3v9a1,1,0,0,0,1,1H7Z"
        fill="none"
        stroke="#676E7B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_2695"
        data-name="Path 2695"
        d="M13,10h5"
        fill="none"
        stroke="#676E7B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_2696"
        data-name="Path 2696"
        d="M11,13h7"
        fill="none"
        stroke="#676E7B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>,
  'SquareChatIcon'
);
export default SquareChatIcon;
