import React from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

import Grid from '@mui/material/Grid';
import { apiKeys } from 'app/utils/data/constants/api-keys';

interface StaticGMapWithMarkerProps {
  latitude: number;
  longitude: number;
  width?: number | string;
  height?: number | string;
  zoom?: number;
}

const StaticGMapWithMarker = ({
  latitude = 44,
  longitude = -80,
  width = '100%',
  height = '250px',
  zoom = 15,
}: StaticGMapWithMarkerProps) => {
  const { isLoaded, loadError } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: apiKeys.GMAP_API_Key,
  });

  //   Center of the map
  const center = { lat: Number(latitude), lng: Number(longitude) };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  // Map Style
  const mapContainerStyle = {
    width: width,
    height: height,
    borderRadius: '16px',
  };

  return (
    <Grid container justifyContent="center">
      <GoogleMap zoom={zoom} center={center} mapContainerStyle={mapContainerStyle}>
        <Marker position={center} />
      </GoogleMap>
    </Grid>
  );
};

export default StaticGMapWithMarker;
