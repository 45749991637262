import { AddCircleOutline } from '@mui/icons-material';
import { Chip, FormControl, Grid, IconButton, InputAdornment, OutlinedInput, TextField, Typography } from '@mui/material';
import { isEmail } from 'app/utils/common-utils/common-utils';
import React, { Dispatch, SetStateAction, useState } from 'react';
import './settings-org-social.scss'

export const linkRegex = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

type Props = {
  socialLinks: any[];
  handleDetailsChange: (fieldName: any, newValue: any) => void;
  hasPermission: boolean;
  showSocialError: boolean;
  setShowSocialError: Dispatch<SetStateAction<boolean>>;
};
const SettingsOrgsocial = ({ socialLinks, handleDetailsChange, hasPermission, setShowSocialError, showSocialError }: Props) => {
  const [newLink, setNewLink] = useState('');
  const [isLinkValid, setIsLinkValid] = useState(false);

  const handleAddLink = () => {
    if (newLink && isLinkValid) {
      handleDetailsChange('socialLinks', [...socialLinks, { socialMediaLink: newLink }]);
      setNewLink('');
      setIsLinkValid(false);
      setShowSocialError(false);
    } else if (newLink) {
      setShowSocialError(true);
    }
  };

  const handleInputChange = event => {
    const newValue = event.target.value;
    setNewLink(newValue);

    if (isEmail(newValue)) {
      setIsLinkValid(false);
      setShowSocialError(true);
    } else {
      setIsLinkValid(linkRegex.test(newValue));
      setShowSocialError(false);
    }
  };

  const handleRemoveLink = (linkToRemove: any) => {
    handleDetailsChange(
      'socialLinks',
      socialLinks.filter(link => link !== linkToRemove)
    );
  };



  return (
    <Grid container spacing={2} sx={{ alignItems: 'center' }}>
      <Grid item xs={12} md={4}>
        <Typography variant="h5" sx={{ fontSize: '14px' }}>
          Social links
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <FormControl sx={{ width: '100%' }} variant="outlined" disabled={!hasPermission}>
          <TextField
            disabled={!hasPermission}
            sx={{
              height: '40px',
            }}
            value={newLink}
            id="outlined-adornment-weight1"
            inputProps={{
              style: {
                fontSize: "0.75rem",

              }
            }}

            placeholder="Social links"
            onKeyDown={event => {
              if (event.key === 'Enter') {
                handleAddLink();
              }
            }}
            onChange={handleInputChange}
            aria-describedby="outlined-weight-helper-text"
            InputProps={{
              endAdornment: hasPermission && (
                <InputAdornment position="end">
                  <IconButton aria-label="add" onClick={handleAddLink}>
                    <AddCircleOutline sx={{ color: 'primary.main' }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {showSocialError && (
            <Typography variant="body2" color="error" sx={{ marginTop: 0.6 }}>
              This is not a valid link
            </Typography>
          )}
          <Grid container spacing={0.5} sx={{ marginTop: '2px' }}>
            {socialLinks?.length > 0 &&
              socialLinks.map((link, index) => (
                <Grid item key={index}>
                  <Chip key={index} label={link.socialMediaLink} onDelete={() => handleRemoveLink(link)} disabled={!hasPermission} />
                </Grid>
              ))}
          </Grid>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default SettingsOrgsocial;
