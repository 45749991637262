import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const defaultDataProps = {
  loading: false,
  isSuccess: false,
  errorResponse: null,
  successResponse: null,
};

const initialState = {
  data: {
    disputeReport: { ...defaultDataProps },
    updateResponseState: { ...defaultDataProps },
  },
};

const handleAsyncAction = (state: any, action: any) => {
  state.loading = true;
  if (action.error) {
    state.loading = false;
    state.isSuccess = false;
    state.errorResponse = action.error;
    state.successResponse = [];
  } else {
    state.loading = false;
    state.isSuccess = !!action.payload;
    state.errorResponse = null;
    state.successResponse = action.payload !== undefined ? action.payload : [];
  }
};

export const postDisputeReport = createAsyncThunk(
  'dashboard/postDisputeReport',
  async ({ notificationId, disputeMessage }: { notificationId: number; disputeMessage: string }) => {
    try {
      const response = await axios.post('/api/notification/disputeReport', {
        notificationId,
        disputeMessage,
      });
      return response.data;
    } catch (error) {
      throw error.response?.data?.message || 'An error occurred';
    }
  }
);

export const updateResponse = createAsyncThunk('dashboard/updateResponse', async ({ notificationId }: { notificationId: number }) => {
  try {
    const response = await axios.post('/api/notification/update-response', {
      notificationId,
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'An error occurred';
  }
});

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(postDisputeReport.pending, state => {
        handleAsyncAction(state.data.disputeReport, { loading: true });
      })
      .addCase(postDisputeReport.fulfilled, (state, action) => {
        handleAsyncAction(state.data.disputeReport, { payload: action.payload });
      })
      .addCase(postDisputeReport.rejected, (state, action) => {
        handleAsyncAction(state.data.disputeReport, { error: action.error });
      })
      .addCase(updateResponse.pending, state => {
        handleAsyncAction(state.data.updateResponseState, { loading: true });
      })
      .addCase(updateResponse.fulfilled, (state, action) => {
        handleAsyncAction(state.data.updateResponseState, { payload: action.payload });
      })
      .addCase(updateResponse.rejected, (state, action) => {
        handleAsyncAction(state.data.updateResponseState, { error: action.error });
      });
  },
});

export default notificationsSlice.reducer;
