import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ICustomOccurence } from 'app/shared/model/custom-occurence.model';
import { WMY } from 'app/shared/model/enumerations/wmy.model';
import { getEntity, updateEntity, createEntity, reset } from './custom-occurence.reducer';

export const CustomOccurenceUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const customOccurenceEntity = useAppSelector(state => state.customOccurence.entity);
  const loading = useAppSelector(state => state.customOccurence.loading);
  const updating = useAppSelector(state => state.customOccurence.updating);
  const updateSuccess = useAppSelector(state => state.customOccurence.updateSuccess);
  const wMYValues = Object.keys(WMY);

  const handleClose = () => {
    navigate('/custom-occurence');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...customOccurenceEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          weekORMonthORYear: 'WEEK',
          ...customOccurenceEntity,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="reachamyApp.customOccurence.home.createOrEditLabel" data-cy="CustomOccurenceCreateUpdateHeading">
            <Translate contentKey="reachamyApp.customOccurence.home.createOrEditLabel">Create or edit a CustomOccurence</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="custom-occurence-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('reachamyApp.customOccurence.activityNumber')}
                id="custom-occurence-activityNumber"
                name="activityNumber"
                data-cy="activityNumber"
                type="text"
              />
              <ValidatedField
                label={translate('reachamyApp.customOccurence.repeatEveryNth')}
                id="custom-occurence-repeatEveryNth"
                name="repeatEveryNth"
                data-cy="repeatEveryNth"
                type="text"
              />
              <ValidatedField
                label={translate('reachamyApp.customOccurence.weekORMonthORYear')}
                id="custom-occurence-weekORMonthORYear"
                name="weekORMonthORYear"
                data-cy="weekORMonthORYear"
                type="select"
              >
                {wMYValues.map(wMY => (
                  <option value={wMY} key={wMY}>
                    {translate('reachamyApp.WMY.' + wMY)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('reachamyApp.customOccurence.occurence')}
                id="custom-occurence-occurence"
                name="occurence"
                data-cy="occurence"
                type="text"
              />
              <ValidatedField
                label={translate('reachamyApp.customOccurence.endDate')}
                id="custom-occurence-endDate"
                name="endDate"
                data-cy="endDate"
                type="date"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/custom-occurence" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CustomOccurenceUpdate;
