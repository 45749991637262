import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './activity.reducer';

export const ActivityDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const activityEntity = useAppSelector(state => state.activity.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="activityDetailsHeading">
          <Translate contentKey="reachamyApp.activity.detail.title">Activity</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{activityEntity.id}</dd>
          <dt>
            <span id="activityNumber">
              <Translate contentKey="reachamyApp.activity.activityNumber">Activity Number</Translate>
            </span>
          </dt>
          <dd>{activityEntity.activityNumber}</dd>
          <dt>
            <span id="categoryID">
              <Translate contentKey="reachamyApp.activity.categoryID">Category ID</Translate>
            </span>
          </dt>
          <dd>{activityEntity.categoryID}</dd>
          <dt>
            <span id="header">
              <Translate contentKey="reachamyApp.activity.header">Header</Translate>
            </span>
          </dt>
          <dd>{activityEntity.header}</dd>
          <dt>
            <span id="details">
              <Translate contentKey="reachamyApp.activity.details">Details</Translate>
            </span>
          </dt>
          <dd>{activityEntity.details}</dd>
          <dt>
            <span id="ageLimit">
              <Translate contentKey="reachamyApp.activity.ageLimit">Age Limit</Translate>
            </span>
          </dt>
          <dd>{activityEntity.ageLimit}</dd>
          <dt>
            <span id="planningForMicrofunding">
              <Translate contentKey="reachamyApp.activity.planningForMicrofunding">Planning For Microfunding</Translate>
            </span>
          </dt>
          <dd>{activityEntity.planningForMicrofunding ? 'true' : 'false'}</dd>
          <dt>
            <span id="amountToRaise">
              <Translate contentKey="reachamyApp.activity.amountToRaise">Amount To Raise</Translate>
            </span>
          </dt>
          <dd>{activityEntity.amountToRaise}</dd>
          <dt>
            <span id="postAsOrganisation">
              <Translate contentKey="reachamyApp.activity.postAsOrganisation">Post As Organisation</Translate>
            </span>
          </dt>
          <dd>{activityEntity.postAsOrganisation ? 'true' : 'false'}</dd>
          <dt>
            <span id="makeAsUrgentNeed">
              <Translate contentKey="reachamyApp.activity.makeAsUrgentNeed">Make As Urgent Need</Translate>
            </span>
          </dt>
          <dd>{activityEntity.makeAsUrgentNeed ? 'true' : 'false'}</dd>
          <dt>
            <span id="makeThisPrivateActivity">
              <Translate contentKey="reachamyApp.activity.makeThisPrivateActivity">Make This Private Activity</Translate>
            </span>
          </dt>
          <dd>{activityEntity.makeThisPrivateActivity ? 'true' : 'false'}</dd>
          <dt>
            <span id="startDate">
              <Translate contentKey="reachamyApp.activity.startDate">Start Date</Translate>
            </span>
          </dt>
          <dd>{activityEntity.startDate ? <TextFormat value={activityEntity.startDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="createdBy">
              <Translate contentKey="reachamyApp.activity.createdBy">Created By</Translate>
            </span>
          </dt>
          <dd>{activityEntity.createdBy}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="reachamyApp.activity.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{activityEntity.createdAt ? <TextFormat value={activityEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="modifiedBy">
              <Translate contentKey="reachamyApp.activity.modifiedBy">Modified By</Translate>
            </span>
          </dt>
          <dd>{activityEntity.modifiedBy}</dd>
          <dt>
            <span id="modifiedAt">
              <Translate contentKey="reachamyApp.activity.modifiedAt">Modified At</Translate>
            </span>
          </dt>
          <dd>
            {activityEntity.modifiedAt ? <TextFormat value={activityEntity.modifiedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
        </dl>
        <Button tag={Link} to="/activity" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/activity/${activityEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ActivityDetail;
