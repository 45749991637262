// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Hide scrollbar for Chrome, Safari and Opera */
.example2::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example2 {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hr-margin {
  margin: 0px !important;
  position: relative;
  top: 20px !important;
  opacity: 0.1;
}

.css-wadpfk .QontoStepIcon-completedIcon {
  width: 29px !important;
  height: 29px !important;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/ui/profile/profile-goals/profile-goals.scss"],"names":[],"mappings":"AAAA,gDAAA;AACA;EACE,aAAA;AACF;;AAEA,4CAAA;AACA;EACE,wBAAA,EAAA,gBAAA;EACA,qBAAA,EAAA,YAAA;AACF;;AACA;EACE,sBAAA;EACA,kBAAA;EACA,oBAAA;EACA,YAAA;AAEF;;AAAA;EACE,sBAAA;EACA,uBAAA;AAGF","sourcesContent":["/* Hide scrollbar for Chrome, Safari and Opera */\n.example2::-webkit-scrollbar {\n  display: none;\n}\n\n/* Hide scrollbar for IE, Edge and Firefox */\n.example2 {\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n}\n.hr-margin {\n  margin: 0px !important;\n  position: relative;\n  top: 20px !important;\n  opacity: 0.1;\n}\n.css-wadpfk .QontoStepIcon-completedIcon {\n  width: 29px !important;\n  height: 29px !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
