import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'app/config/store';
import { Grid, Typography, useTheme } from '@mui/material';
import { appConstants } from 'app/utils/data/constants/constants';
import { apiKeys } from 'app/utils/data/constants/api-keys';
import { Libraries, useLoadScript } from '@react-google-maps/api';
import PlacesAutocomplete from 'app/components/ui/auth/welcome-screen/places-autocomplete.tsx/places-auto-complete';
import './settings-change-location.scss'

const SettingsChangeLocation = ({ setUpdatedSettings, setLocationManualyTyped }: any) => {
  const [currentCity, setCurrentCity] = useState<any>();
  const theme = useTheme();
  const [fullLocationDetail, setFullLocationDetail] = useState<any>();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKeys.GMAP_API_Key,
    libraries: appConstants.mapLibraries as Libraries,
  });

  useEffect(() => {
    if (fullLocationDetail) {
      setUpdatedSettings(state => ({
        ...state,
        city: fullLocationDetail?.city,
        country: fullLocationDetail?.country,
        state: fullLocationDetail?.state,
        latitude: fullLocationDetail?.latitude,
        longitude: fullLocationDetail?.longitude,
      }));
    }
  }, [fullLocationDetail]);
  console.log('mine', fullLocationDetail);

  return (
    <Grid container direction="row" alignItems="baselineenter" justifyContent={'flex-start'} gap={2}>
      <Grid container item md={4} direction="column" spacing={1}>
        <Grid item>
          <Typography variant="h5" sx={{ color: theme.textColor.mainHeading, fontSize: '14px' }}>
            Location
          </Typography>
        </Grid>
        <Grid item>{/* <Typography sx={{ color: theme.textColor.heading, fontSize: '14px' }}>Primary location.</Typography> */}</Grid>
      </Grid>
      <Grid container item md>
        <Grid item container direction="column">
          {isLoaded ? (
            <PlacesAutocomplete
              setLocationManualyTyped={setLocationManualyTyped}
              setFullLocationDetail={setFullLocationDetail}
              setCity={setCurrentCity}
              city={currentCity}
            />
          ) : (
            <p>Loading...</p>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SettingsChangeLocation;
