import { weekDaysType } from 'app/utils/types/choose-availability-types/choose-availability-types';

export const weekDays: weekDaysType[] = [
  { label: 'Su', value: 'Su' },
  { label: 'M', value: 'M' },
  { label: 'T', value: 'T' },
  { label: 'W', value: 'W' },
  { label: 'Th', value: 'Th' },
  { label: 'F', value: 'F' },
  { label: 'S', value: 'S' },
];
