import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const AdvocacyIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="14.613" viewBox="0 0 21 14.613" style={{ fontSize: 'larger' }}>
    <path
      id="Advocacy"
      d="M21.778,5.54V16.973a1.122,1.122,0,0,1-1.463,1.141l-7.58-1.9-.442,1.768a1.293,1.293,0,0,1-1.567.938L6.531,17.872a1.3,1.3,0,0,1-.94-1.567l.443-1.773-2-.5H1.426a.647.647,0,0,1-.646-.646V9.131a.647.647,0,0,1,.646-.646H4.032L20.314,4.4a1.122,1.122,0,0,1,1.463,1.142ZM11.405,16.2c.012-.048.1-.272.067-.306a.359.359,0,0,0-.1-.024l-.2-.05-1.428-.359-2.453-.615-.008.034a1.943,1.943,0,0,0-.06.237l-.1.381-.207.83c-.012.048-.1.261-.064.294.014.015.079.019.1.024l.2.049,1.446.362,1.656.413.575.144c.024.005.193.068.214.052Z"
      transform="translate(-0.78 -4.345)"
      fill="#676e7b"
    />
  </svg>,
  'AdvocacyIcon'
);
export default AdvocacyIcon;
