import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const NopovertyIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="21.013" height="14.709" viewBox="0 0 21.013 14.709" style={{ fontSize: 'larger' }}>
    <path
      id="No_Poverty"
      data-name="No Poverty"
      d="M20.394,32.737A9.723,9.723,0,0,0,16.572,32c-4.044,0-8.088,2.047-12.132,2.047A9.962,9.962,0,0,1,1.4,33.6a1.123,1.123,0,0,0-.34-.053A1.045,1.045,0,0,0,0,34.588V45a1.044,1.044,0,0,0,.619.967,9.717,9.717,0,0,0,3.822.738c4.044,0,8.088-2.047,12.132-2.047a9.962,9.962,0,0,1,3.042.45,1.123,1.123,0,0,0,.34.053,1.045,1.045,0,0,0,1.059-1.044V33.7a1.045,1.045,0,0,0-.619-.968Zm-9.888,9.769A2.925,2.925,0,0,1,7.88,39.354,2.925,2.925,0,0,1,10.506,36.2a2.925,2.925,0,0,1,2.627,3.152A2.926,2.926,0,0,1,10.506,42.506Z"
      transform="translate(0 -32)"
      fill="#676e7b"
    />
  </svg>,
  'NopovertyIcon'
);
export default NopovertyIcon;
