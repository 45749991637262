import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export interface announceType {
  data: string;
}

export interface announceState {
  arrival: announceType | null;
  loading: null | boolean;
  success: null | boolean;
  error: null | string;
}

export const initialState: announceState = {
  arrival:null,
  loading: null,
  success: null,
  error: null,
};

export const announceArrival = createAsyncThunk<any,{ activityId?: any; latitude?: any; longitude?: any },{ rejectValue: string }>('api/announce-arrival', async ({ activityId, latitude, longitude }, { rejectWithValue }) => {
  try {
    const response = await axios.post<any>('api/activity/announce-on-arrival', { activityId, latitude, longitude });
    return response?.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'An error occurred');
  }
});

  export const announceArrivalSlice = createSlice({
    name: 'announcearrival',
    initialState: initialState as announceState,
    reducers: {},
    extraReducers: builder => {
      builder.addCase(announceArrival.pending, state => {
        state.loading = true;
        state.success = null;
        state.error = null;
      });
  
      builder.addCase(announceArrival.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.arrival = action.payload;
        state.error = null;
      });
  
      builder.addCase(announceArrival.rejected, (state, action) => {
        state.loading = null;
        state.error = action.error.message;
        state.success = null;
      });
    },
  });
  
  export default announceArrivalSlice.reducer;
