import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const TrophyIconsblue = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
    <g id="Group_6697" data-name="Group 6697" transform="translate(-6064 -9563.027)">
      <circle id="Ellipse_299" data-name="Ellipse 299" cx="12.5" cy="12.5" r="12.5" transform="translate(6064 9563.027)" fill="#674bff" />
      <path
        id="Icon_awesome-trophy"
        data-name="Icon awesome-trophy"
        d="M14,1.623H11.361V.609A.607.607,0,0,0,10.753,0h-6.9a.607.607,0,0,0-.609.609V1.623H.609A.607.607,0,0,0,0,2.232v1.42A3.309,3.309,0,0,0,1.57,6.206a5.941,5.941,0,0,0,2.79,1.058A5.61,5.61,0,0,0,6.086,9.13v1.826H4.869a1.463,1.463,0,0,0-1.623,1.42v.3a.305.305,0,0,0,.3.3h7.507a.305.305,0,0,0,.3-.3v-.3a1.463,1.463,0,0,0-1.623-1.42H8.521V9.13a5.61,5.61,0,0,0,1.727-1.867,5.921,5.921,0,0,0,2.79-1.058,3.316,3.316,0,0,0,1.57-2.554V2.232A.607.607,0,0,0,14,1.623ZM2.518,4.889a1.865,1.865,0,0,1-.9-1.238V3.246H3.251a9.108,9.108,0,0,0,.325,2.186,4.087,4.087,0,0,1-1.058-.543ZM12.984,3.652a1.949,1.949,0,0,1-.9,1.238,4.1,4.1,0,0,1-1.06.543,9.109,9.109,0,0,0,.325-2.186h1.631Z"
        transform="translate(6069.196 9569.035)"
        fill="#fff"
      />
    </g>
  </svg>,
  'TrophyIconsblue'
);
export default TrophyIconsblue;
