import React, { useState } from 'react';
import Lightbox, { Slide } from 'yet-another-react-lightbox';
import Video from 'yet-another-react-lightbox/plugins/video';
import 'yet-another-react-lightbox/styles.css';
import { images } from './images';
import ImageGrid from './ImageGrid';

interface SlideImage {
  type: 'image';
  src: string;
  title: React.ReactNode;
  thumbnail: string;
}

interface SlideVideo {
  type: 'video';
  src: string;
  title: React.ReactNode;
  thumbnail: string;
  sources: { src: string; type: string }[];
}

type GallerySlide = SlideImage | SlideVideo;

export default function Gallery() {
  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index: number) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  const slides: GallerySlide[] = images.map(img => {
    if (img.original.endsWith('.mp4')) {
      return {
        type: 'video',
        src: img.original,
        title: img.caption,
        thumbnail: img.src,

        sources: [{ src: img.original, type: 'video/mp4' }],
      };
    } else {
      return {
        type: 'image',
        src: img.original,
        title: img.caption,
        thumbnail: img.src,
      };
    }
  });

  return (
    <>
      <div style={{ width: '700px' }}>
        <ImageGrid onImageClick={handleClick}>
          <img alt="ig" src="https://cdn.pixabay.com/photo/2018/08/14/13/23/ocean-3605547__340.jpg" />
          <img alt="ig" src="https://cdn.pixabay.com/photo/2018/08/14/13/23/ocean-3605547__340.jpg" />
          <img alt="ig" src="https://cdn.pixabay.com/photo/2018/08/14/13/23/ocean-3605547__340.jpg" />
        </ImageGrid>
      </div>
      <Lightbox open={index >= 0} close={handleClose} slides={slides} plugins={[Video]} index={index} />
    </>
  );
}
