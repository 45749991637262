import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Grid, TextField, Button, useTheme, Typography, Alert, Box, FormControl, FormHelperText, OutlinedInput, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { handleRegister, reset } from 'app/modules/account/register/register.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { signupSchema } from 'app/utils/validation-schema/signup-schema';
import LoaderIcon from 'app/components/common/loader-icon/loader-icon';
import axios from 'axios';
interface RegistrationPageProps {
  currentComponent?: any;
  setCurrentComponent?: any;
  handleAuthModalClose?: any;
  isAdmin?: boolean;
}
const RegistrationPage = ({ currentComponent, setCurrentComponent, handleAuthModalClose, isAdmin }: RegistrationPageProps) => {
  const dispatch = useAppDispatch();
  const [message, setMessage] = useState<string>('');
  const [inviteEmail, setInviteEmail] = useState<string>('');
  const [isdisabled, setIsDisabled] = useState(false);
  const initialValues = {
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
  };

  const handleSubmit = values => {
    setIsDisabled(true);
    const { firstName, lastName, email, password } = values;
    dispatch(handleRegister({ firstName, lastName, email, password, langKey: 'en' }));
  };

  const navigate = useNavigate();
  const theme = useTheme();
  const loading = useAppSelector(state => state.register.loading);
  const response = useAppSelector(state => state.register.successMessage);
  const errorMessage = useAppSelector(state => state.register.errorMessage);

  const heading = {
    color: theme.textColor.heading,
    marginBottom: '13px',
    marginTop: '2px',
    fontSize: '15px',
    width: '98%',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: signupSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    // Update the message state based on the response
    if (response !== null) {
      if (typeof response === 'string') {
        setMessage(response);
      } else if (typeof response === 'object') {
        if (response?.error?.response?.data?.message) {
          setMessage(response?.error?.response?.data?.message);
        }
      }
    }
  }, [response]);
  const handleGoBack = () => {
    setCurrentComponent('login');
  };

  useEffect(() => {
    const emailInput = document.getElementById('reg-email') as HTMLInputElement | null;
    const passwordInput = document.getElementById('reg-password') as HTMLInputElement | null;

    if (emailInput) {
      emailInput.value = '';
    }

    if (passwordInput) {
      passwordInput.value = '';
    }

    return () => {
      dispatch(reset());
    };
  }, []);

  const fetchEmail = async () => {
    try {
      const response = await axios.get<any>(`/api/invitee-email?token=${localStorage.getItem('token')}`);
      if (response) {
        console.log('check response', response);
        setInviteEmail(response?.data?.data);
      }
    } catch (error) {
      console.log('Error fetching goal:', error);
    }
  };
  useEffect(() => {
    if (inviteEmail != '') {
      formik.setFieldValue('email', inviteEmail);
    }
  }, [inviteEmail]);
  useEffect(() => {
    if (localStorage.getItem('token')) {
      fetchEmail();
    }
  }, [localStorage.getItem('token'), localStorage?.getItem('invitation')]);

  return (
    <Grid container spacing={2} direction={'column'} justifyContent={'center'} alignItems={'center'} px={8} py={3} sx={{ margin: 'auto' }}>
      <Box
        sx={{
          border: 'none',
          maxHeight: '80vh',
          maxWidth: '460px',
          textAlign: 'center',
          overflowY: 'scroll',
          overflowX: 'hidden',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '&::-webkit-scrollbar-thumb': { backgroundColor: 'secondary.contrastText', height: '10px' },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <div onClick={() => navigate('/')} className="d-flex justify-content-center">
          <img src="content/images/logos/reachamy-logo.png" alt="reach amy logo" style={{ height: '58px' }} />
        </div>
        <h2 className="d-flex justify-content-center " style={heading}>
          Sign up
        </h2>

        <form id="registration-form" onSubmit={formik.handleSubmit}>
          {/* <Grid container direction="column" alignItems={'center'} gap={2} justifyContent={'center'} minWidth={'500px'}> */}
          <Grid container spacing={1}>
            <Grid item xs={6} md={6} className="pt-1">
              <TextField
                size="small"
                fullWidth
                name="firstName"
                placeholder="First name"
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName.toString() : ''}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={6} md={6} className="pt-1">
              <TextField
                size="small"
                fullWidth
                name="lastName"
                placeholder="Last name"
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName.toString() : ''}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} md={12} className="pt-3">
              <TextField
                disabled={isAdmin}
                size="small"
                fullWidth
                name="email"
                id="reg-email"
                placeholder="Email"
                type="email"
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email ? formik.errors.email.toString() : ''}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} md={12} className="pt-3">
              <TextField
                size="small"
                fullWidth
                name="password"
                id="reg-password"
                placeholder="Password"
                type="password"
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password ? formik.errors.password.toString() : ''}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12} md={12} className="pt-3">
              <TextField
                size="small"
                fullWidth
                name="confirmPassword"
                placeholder="Confirm password"
                type="password"
                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword ? formik.errors.confirmPassword.toString() : ''}
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              {message?.length > 0 && (
                <Alert sx={{ maxWidth: '100%' }}>
                  <Typography variant="subtitle1" fontSize={'10px'}>
                    {message}
                  </Typography>
                </Alert>
              )}
              {errorMessage?.length > 0 && (
                <Alert severity="error" sx={{ maxWidth: '100%' }}>
                  <Typography variant="subtitle1" fontSize={'10px'} color={theme.palette.error.main}>
                    {errorMessage}
                  </Typography>
                </Alert>
              )}
            </Grid>
            <Grid item xs={12} md={12} pt={0} className=" pt-0" sx={{ textAlign: 'justify' }}>
              <span style={{ fontSize: '11px', textAlign: 'center', width: '70%', fontWeight: '600' }}>
                Your information is safe and secure. By signing up, you agree to our{' '}
                <span
                  style={{
                    marginLeft: '1px',
                    marginRight: '1px',
                    color: theme.palette.primary.light,
                    textTransform: 'capitalize',
                    fontSize: '11px',
                  }}
                >
                  <span style={{ cursor: 'pointer' }} onClick={() => navigate('/privacy-policy')}>
                    privacy policy
                  </span>
                  , &nbsp;
                  <span style={{ cursor: 'pointer' }} onClick={() => navigate('/cookie-policy')}>
                    cookie policy
                  </span>
                  , &nbsp;
                  <span style={{ cursor: 'pointer' }} onClick={() => navigate('/member-agreement')}>
                    Member agreement
                  </span>
                </span>
                &nbsp;and that we may share your personal information with our partners to verify your account.
              </span>
            </Grid>
            <Grid item xs={12} md={12} className=" text-center pt-2">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  marginBottom: '10px',
                  height: theme.createActivity.height,
                  backgroundColor: theme.palette.primary.light,
                  borderRadius: theme.createActivity.borderRadius,
                  color: theme.palette.secondary.dark,
                  borderColor: theme.palette.primary.main,
                  boxShadow: 'none',
                  '&:active, &:focus, &:hover': {
                    backgroundColor: theme.createActivity.backgroundColor.hover,
                    boxShadow: 'none',
                  },
                }}
                endIcon={loading ? <LoaderIcon /> : null}
                disabled={!formik.dirty || !formik.isValid || loading || isdisabled}
              >
                Create Account
              </Button>
            </Grid>
            {!isAdmin && (
              <Grid item xs={12} md={12} pt={0} className="pt-0">
                <span style={{ fontSize: '11px', textAlign: 'center', width: '70%', fontWeight: '600' }}>Back to </span>
                <Link
                  onClick={() => handleGoBack()}
                  sx={{ fontSize: '11px', color: theme.palette.primary.light, textAlign: 'center', width: '70%', fontWeight: '600' }}
                >
                  Log in
                </Link>
              </Grid>
            )}
          </Grid>
        </form>
      </Box>
    </Grid>
  );
};

export default RegistrationPage;
