// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 600px) {
  .mob-searchbar {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
}
@media only screen and (max-width: 575px) {
  .mob-searchbar {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
.chat-count {
  font-size: 11px;
  background: #674bff;
  height: 16px;
  width: 16px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  right: 12px;
  top: 12px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/common/cards/chat-card/chat-cardleftnav.scss"],"names":[],"mappings":"AAAA;EACE;IACE,2BAAA;IACA,4BAAA;EACF;AACF;AACA;EACE;IACE,yBAAA;IACA,0BAAA;EACF;AACF;AACA;EACE,eAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;AACF","sourcesContent":["@media only screen and (max-width: 600px) {\n  .mob-searchbar {\n    margin-left: 8px !important;\n    margin-right: 8px !important;\n  }\n}\n@media only screen and (max-width: 575px) {\n  .mob-searchbar {\n    margin-left: 0 !important;\n    margin-right: 0 !important;\n  }\n}\n.chat-count {\n  font-size: 11px;\n  background: #674bff;\n  height: 16px;\n  width: 16px;\n  color: #fff;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50%;\n  position: absolute;\n  right: 12px;\n  top: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
