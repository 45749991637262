import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

export interface RatingImageType {
  activityId: number; 
  imageId:any[];
}

export interface RateActivityImageType {
  data: string;
}

export interface RateActivityImageState {
  ratingImages: RatingImageType | null;
  loading: boolean;
  error: string | null;
  success: boolean | null;
}

export const initialState: RateActivityImageState = {
  ratingImages: null,
  loading: false,
  error: null,
  success: false,
};

export const submitActivityImages = createAsyncThunk<any, RatingImageType, { rejectValue: string }>(
  'rateActivityImages', 
  async ({ activityId, imageId  }, { rejectWithValue }) => {
    try {
      const response = await axios.post<any>('/api/activity-details/completed-activity-images', { 
        activityId,
        imageId,
      });
      return response.data; 
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);

const activityRatingImageUploadSlice = createSlice({
  name: 'ActivityRatingImageUpload',
  initialState,
  reducers: {
    resetState(state) {
      state.loading = false;
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitActivityImages.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(submitActivityImages.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.ratingImages = action.payload;
    });
    builder.addCase(submitActivityImages.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || 'An error occurred';
      state.success = false;
    });
  },
});

export const { resetState } = activityRatingImageUploadSlice.actions;
export default activityRatingImageUploadSlice.reducer;