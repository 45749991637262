// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-heg063-MuiTabs-flexContainer {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.css-heg063-MuiTabs-flexContainer::-webkit-scrollbar {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/ui/settings/settings-body/settings-panels/settings-panels.scss"],"names":[],"mappings":"AAAA;EACE,mCAAA,EAAA,gBAAA;EACA,gCAAA,EAAA,YAAA;AACF;;AACA;EACE,aAAA;AAEF","sourcesContent":[".css-heg063-MuiTabs-flexContainer {\n  -ms-overflow-style: none !important; /* IE and Edge */\n  scrollbar-width: none !important; /* Firefox */\n}\n.css-heg063-MuiTabs-flexContainer::-webkit-scrollbar {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
