import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { pageConfig } from '../../utils/data/constants/constants';

const pageItemLimit = pageConfig?.notificationPannel?.itemLimit;
export interface categoriesType {
  id: number;
  category: string;
}

export interface notificationsType {
  id: number;
  message: string;
  notificationTime: string;
  eventProfile: string;
  eventType: string;
  eventId: number;
  notificationType: string;
  read: boolean;
  fcmStatus: boolean;
  fcmMessageId: string;
  retryCount: number;
  userId: number;
  fcmToken: string;
  fcmDeviceId: string;
}
export interface subCategoryType {
  label: string;
  id: number;
}

export interface animalType {
  label: string;
  id: number;
}

export interface bloodType {
  bloodType: string;
  id: number;
}

export interface categoriesState {
  categories: categoriesType[];
  loading: boolean;
  error: string | null;
}
export interface singleCategoryType {
  id: number;
  category: string;
  fullForm: string;
}

export const initialState: categoriesState = {
  categories: [],
  loading: false,
  error: null,
};
export const fetchCategories = createAsyncThunk<categoriesType[], void, { rejectValue: string }>(
  'locationMaster/fetchCategories',
  async () => {
    try {
      const response = await axios.get<categoriesType[]>('api/category-masters');
      return response.data;
    } catch (error) {
      throw error.response?.data?.message || 'An error occurred';
    }
  }
);

export const fetchSubCategories = async (id: string) => {
  try {
    const response = await axios.get<subCategoryType[], any>(`api/category-masters/category/${id}`);
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'An error occurred';
  }
};

export const fetchNotifications = (page: any) => {
  return async dispatch => {
    try {
      const body = {
        currentLatitude: JSON.parse(localStorage.getItem('currentLatitude') || 'null'),
        currentLongitude: JSON.parse(localStorage.getItem('currentLongitude') || 'null'),
      };
      const response = await axios.post(`api/notification?pageNumber=${page}&pageSize=${pageItemLimit}`, { body });
      const data = response?.data;
      return data;
    } catch (error) {
      dispatch({ type: 'FETCH_NOTIFICATIONS_ERROR', payload: error.message });
    }
  };
};
export const submitNotifications = notificationId => {
  return async dispatch => {
    try {
      const response = await axios.post('api/notification/updateReadMessages', { notificationId });
      const data = response;
      return data;
    } catch (error) {
      dispatch({ type: 'FETCH_NOTIFICATIONS_ERROR', payload: error.message });
    }
  };
};
export const getCategoryById = async (id: string) => {
  try {
    const response = await axios.get<singleCategoryType, any>(`api/category-masters/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const categorySlice = createSlice({
  name: 'categoryMaster',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCategories.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'An error occurred';
      });
  },
});

export default categorySlice.reducer;
