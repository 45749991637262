import React, { Fragment, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { Box, Fab, Modal, Stack, useMediaQuery, useTheme } from '@mui/material';
import ProfileLocationIcon from 'app/components/icons/profile/location-icon';
import WaveFormIcon from 'app/components/icons/profile/wave-form-icon';
import OrganisedIcon from 'app/components/icons/profile/organised-icon';
import ModeratedIcon from 'app/components/icons/profile/moderated-icon';
import './organization-details.scss';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
// import ProfileGoals from '../profile-goals/profile-goals';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import WebIcon from 'app/components/icons/organization/web-icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PhoneIcon from 'app/components/icons/organization/phone-icon';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import AuthorisationModal from 'app/components/common/authorization-modal/authorization-modal';
import { toggleModalLogin } from 'app/shared/reducers/authentication';
import { fetchOrgImpactReport, fetchOrganization, fetchUserOrganizations, handleInvitation } from 'app/shared/reducers/organization';
import toast, { LoaderIcon } from 'react-hot-toast';
import TwitterIcon from 'app/components/icons/login-page-icons/twitter-icon';
import FacebookIcon from 'app/components/icons/login-page-icons/facebook-icon';
import LinkedInIcon from 'app/components/icons/login-page-icons/linkedin-icon';
import GoogleIcon from 'app/components/icons/login-page-icons/google-icon';
import { Download, Instagram, YouTube } from '@mui/icons-material';
import InstagramIcon from 'app/components/icons/login-page-icons/instagram-icon';
import ProfileGoals from '../../profile/profile-goals/profile-goals';
import axios from 'axios';
import PaymentModal from '../../payment-modal/payment-modal';
import PaymentmodalFailed from '../../payment-modal/payment-modal-failed';

type ProfileDataProps = {
  profileData: any;
  id: any;
};
const OrganizationDetailsSection = ({ profileData, id }: ProfileDataProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [showFullText, setShowFullText] = useState(false);
  const [open, setOpen] = React.useState(false);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [currentComponent, setCurrentComponent] = useState(' ');
  const [actionType, setActionType] = useState('');
  const handleInvitationLoading = useAppSelector(state => state.organizationReducer.handleInvitationLoading);
  const profileLogin = useAppSelector(state => state.authentication.account.login);
  const profileEmail = useAppSelector(state => state.authentication.account.email);
  const myProfileId = useAppSelector(state => state.authentication.account.id);
  const [searchParams1, setSearchParams] = useSearchParams();
  const status = searchParams1.get('status');
  const [downloadLoading, setDownloadLoading] = useState(false);

  const profileAchievements = [
    {
      name: 'Participated',
      value: profileData?.participatedCountbyOrganization ?? 0,
      icon: <WaveFormIcon sx={{ fontSize: { xs: 'larger', lg: '16px' } }} />,
    },
    {
      name: 'Organized',
      value: profileData?.activitiesCountbyOrganization ?? 0,
      icon: <OrganisedIcon sx={{ fontSize: { xs: 'larger', lg: '16px' } }} />,
    },
  ];

  const isMobile = useMediaQuery(theme.breakpoints.down(700));
  const isDarkMode = theme.palette.mode === 'dark';

  const handleAuthModalClose = () => {
    setAuthModalOpen(false);
  };

  const toggleShowText = () => {
    setShowFullText(!showFullText);
  };

  const stylemodal = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function getSocialMediaName(url) {
    try {
      const hostname = new URL(url).hostname;
      const parts = hostname.split('.');
      // Check if there is a subdomain (www) and extract the social media name
      const socialMediaName = parts.length > 2 ? parts[1] : parts[0];
      return socialMediaName.toLowerCase();
    } catch (error) {
      // Handle invalid URLs
      return 'Unknown Social Media';
    }
  }

  function socialMediaNameIcons(url) {
    const socialMediaName = getSocialMediaName(url);

    switch (socialMediaName) {
      case 'twitter':
        return <TwitterIcon sx={{ fontSize: '27px', cursor: 'pointer', marginRight: '11px' }} />;
      case 'facebook':
        return <FacebookIcon sx={{ fontSize: '30px', cursor: 'pointer', marginRight: '11px' }} />;
      case 'linkedin':
        return <LinkedInIcon sx={{ fontSize: '30px', cursor: 'pointer', marginRight: '11px' }} />;
      case 'google':
        return <GoogleIcon sx={{ fontSize: '30px', cursor: 'pointer', marginRight: '11px' }} />;
      case 'youtube':
        return <YouTube sx={{ color: 'red', fontSize: '32px', marginRight: '11px' }} />;
      case 'instagram':
        return <InstagramIcon sx={{ fontSize: '32px', marginRight: '11px' }} />;
      default:
        return <span style={{ marginRight: '11px' }}>{socialMediaName}</span>;
    }
  }

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [paymentFailureOpen, setPaymentFailureOpen] = useState(false);
  const hasSuccessStatus = new URLSearchParams(location.search).get('status') === 'success';
  const hasSuccessFailure = new URLSearchParams(location.search).get('status') === 'failure';
  useEffect(() => {
    if (hasSuccessStatus) {
      setPaymentModalOpen(true);
    }

    if (hasSuccessFailure) {
      setPaymentFailureOpen(true);
    }
  }, [hasSuccessStatus, hasSuccessFailure]);
  const handlePaymentModalClose = () => {
    setPaymentModalOpen(false);
    const currentPath = location.pathname;
    navigate(currentPath, { replace: true });
  };

  const handlePaymentFailureClose = () => {
    setPaymentFailureOpen(false);
    const currentPath = location.pathname;
    navigate(currentPath, { replace: true });
  };
  const invitedFromParam = searchParams.get('invited');

  const storedOrgId = parseInt(localStorage.getItem('organizationId'));

  const fetchOrganizationData = async id => {
    try {
      await dispatch(fetchOrganization(id));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchOrganizations = async userId => {
    try {
      await dispatch(fetchUserOrganizations(userId));
    } catch (error) {
      console.log(error);
    }
  };

  const handleInvitationHandler = async ACTION => {
    try {
      dispatch(handleInvitation({ email: profileEmail ? profileEmail : profileLogin, organizationId: id, status: ACTION })).then(res => {
        fetchOrganizationData(id);
        if (myProfileId) {
          fetchOrganizations(myProfileId);
        }
      });
    } catch (error) {
      console.error('Error handling invitation:', error);
    }
  };

  const invitationHandler = ACTION => {
    ACTION === 'ACCEPTED' ? setActionType('accepted') : setActionType('rejected');

    if (isAuthenticated) {
      handleInvitationHandler(ACTION);
    } else {
      if (!storedOrgId) localStorage.setItem('invitedOrgId', id);
      dispatch(toggleModalLogin());
      setCurrentComponent('login');

      setAuthModalOpen(true);
    }
  };

  const navigateToSettings = (e, orgId) => {
    e.preventDefault();
    if (isAuthenticated) {
      localStorage.setItem('organizationId', orgId);
      navigate('/settings?type=organization', { state: { organizationId: orgId } });
    } else {
      dispatch(toggleModalLogin());
      setCurrentComponent('login');
      setAuthModalOpen(true);
    }
  };

  const inviteBox = (
    <Box
      sx={{ boxShadow: '0 8px 60px 0 rgba(103, 151, 255, 0.11), 0 12px 90px 0 rgba(103, 151, 255, 0.11)' }}
      p={2}
      bgcolor={isDarkMode ? '#1f1f26' : '#FFFFFF'}
      borderRadius={3}
      width={'100%'}
      marginTop={1}
    >
      {!profileData?.deleted && (
        <>
          <Typography variant="body1" gutterBottom>
            You have an invitation to this organization. Would you like to accept or reject?
          </Typography>
          <Box display="flex" justifyContent="flex-end" gap={1}>
            <Button
              sx={{ border: '1px solid #674BFF', color: '#674BFF' }}
              variant="outlined"
              color="primary"
              onClick={() => invitationHandler('DECLINED')}
              disabled={handleInvitationLoading}
              endIcon={actionType === 'rejected' && handleInvitationLoading ? <LoaderIcon /> : null}
            >
              Reject
            </Button>

            <Button
              sx={{ background: '#674BFF', color: '#fff' }}
              variant="contained"
              color="primary"
              onClick={() => invitationHandler('ACCEPTED')}
              disabled={handleInvitationLoading}
              endIcon={actionType === 'accepted' && handleInvitationLoading ? <LoaderIcon /> : null}
            >
              Accept
            </Button>
          </Box>
        </>
      )}

      {profileData?.deleted && (
        <>
          <Typography variant="body1" gutterBottom>
            We're sorry, but the organization you are trying to access has been removed from our system.
          </Typography>
        </>
      )}
    </Box>
  );

  const renderInvitation = () => {
    if (!isAuthenticated) {
      if (invitedFromParam) return inviteBox;
    } else {
      if (profileData?.invited && profileData?.invitedAndNotAccepted) {
        return inviteBox;
      }
    }
  };

  const [payload, setPayload] = useState<any>();
  const handleGetCatalogue = async id => {
    setDownloadLoading(true);

    try {
      const response = await axios.get<any>(`api/payment-service/product-catalogue?eventType=${id}`);
      if (response.status === 201 || response.status === 200) {
        setDownloadLoading(false);
        setPayload(response.data);
        // if (response.data) {
        //   handlePaymentSite();
        // }
      } else {
        toast.dismiss();
        toast.error('An error ocuured!');
      }
    } catch (error) {
      toast.dismiss();
      toast.error('An error ocuured! Try again');
      setDownloadLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (status === 'success') {
      downloadImpactReport();
    }
  }, [status]);

  useEffect(() => {
    if (payload?.productId) handlePaymentSite();
  }, [payload?.productId]);

  const download = async () => {
    setDownloadLoading(true);
    if (profileData?.participatedCountbyOrganization > 0 || profileData?.activitiesCountbyOrganization > 0) {
      handleGetCatalogue(5);
    } else {
      toast.dismiss();
      setDownloadLoading(false);
      toast.error('Please organize or volunteer in at least one activity to generate a report');
    }
  };
  const handlePaymentSite = async () => {
    setDownloadLoading(true);

    let passData = {
      eventId: storedOrgId,
      eventType: 5,
      quantity: 1,
      productId: payload?.productId,
    };
    localStorage.setItem('passEventId', JSON.stringify(passData?.eventId));
    localStorage.setItem('passEventType', JSON.stringify(passData?.eventType));
    try {
      const response = await axios.post<any>('api/payment-service/checkout', passData);
      if (response.status === 201 || response.status === 200) {
        setDownloadLoading(false);

        const checkoutUrl = response?.data?.checkoutUrl;
        if (checkoutUrl) {
          window.location.href = checkoutUrl;

          // Open checkout URL in current tab
        } else {
          console.error('Checkout URL not found.');
        }
      }
    } catch (error) {
      setDownloadLoading(false);

      console.log(error);
      toast.dismiss();
      toast.error('Payment cancelled. Please try again');
    }
  };
  const downloadImpactReport = async () => {
    setDownloadLoading(true);
    try {
      if (profileData?.participatedCountbyOrganization > 0 || profileData?.activitiesCountbyOrganization > 0) {
        const actionResult = await dispatch(fetchOrgImpactReport(id));
        const fileUrl = actionResult.payload;

        if (fileUrl !== 'Failed to fetch report') {
          const response = await fetch(fileUrl);

          if (response.ok) {
            setDownloadLoading(false);
            const blob = await response.blob();
            const blobURL = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = blobURL;
            link.download = 'Impact Report.pdf';

            link.click();

            setTimeout(() => window.URL.revokeObjectURL(blobURL), 1000);
          } else {
            const errorMessage = await response.text();
            setDownloadLoading(false);
          }
        } else {
          setDownloadLoading(false);
          toast.error(fileUrl);
        }
      } else {
        setDownloadLoading(false);
        toast.error('Please organize or volunteer in at least one activity to generate a report');
      }
    } catch (error) {
      toast.error('An unexpected error occurred. Please try again later.');
    }
  };

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems={{ xs: 'center', lg: 'flex-start' }}
        justifyContent={'space-between'}
        sx={{ paddingTop: '15px' }}
      >
        <Grid item container alignItems={'center'} justifyContent={'center'} xs={12} lg={3}>
          <Avatar src={profileData?.logoUrl ?? null} sx={{ width: 130, height: 130, objectFit: 'contain' }}></Avatar>
        </Grid>
        <Grid item container direction="column" xs={12} lg={9} spacing={2.5} className="mt-0">
          <Grid item container direction={'row'} alignItems="center" justifyContent={{ xs: 'center', lg: 'space-between' }}>
            <Grid item xs={12} lg={8}>
              <Typography
                textAlign={{ xs: 'center', lg: 'left' }}
                fontSize={23}
                color={theme.profile.color}
                fontWeight={600}
                lineHeight={1.3}
              >
                {profileData?.organizationName}
                {profileData?.verified && (
                  <img src="content/images/logos/verified.png" style={{ height: '20px', width: '20px' }} alt="verified" />
                )}
              </Typography>{' '}
            </Grid>
            {isAuthenticated && profileData?.hasSettingsPermission && !profileData?.deleted && (
              <Grid className="btn-mrgtop" item xs={12} lg={4}>
                <Link to={`/settings?type=organization&id=${id}`} onClick={e => navigateToSettings(e, id)}>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{
                      backgroundColor: 'primary.light',
                      color: 'common.white',
                      fontSize: '13px',
                      px: 1,
                      borderRadius: 1,
                      '&:hover': {
                        color: 'common.white',
                        backgroundColor: 'primary.light',
                      },
                    }}
                  >
                    Settings
                  </Button>
                </Link>
                <Button
                  disableElevation
                  disableRipple
                  size="small"
                  sx={{
                    marginTop: 0.7,
                    marginBottom: 0.7,
                    width: '100%',
                    color: 'primary.light',
                    borderRadius: 1,
                    border: '1px solid #674BFF',
                    '&.MuiButtonBase-root:hover': {
                      bgcolor: 'transparent',
                      border: '1px solid #674BFF  ',
                    },
                    '&.MuiButton-root.Mui-disabled': {
                      color: 'primary.light',
                      border: '1px solid #674BFF',
                    },
                  }}
                  startIcon={!downloadLoading && <Download />}
                  variant="outlined"
                  // onClick={downloadImpactReport}
                  onClick={() => download()}
                  endIcon={downloadLoading ? <LoaderIcon color="primary.light" /> : null}
                  disabled={downloadLoading}
                >
                  Impact Report
                </Button>
              </Grid>
            )}
            <Grid className="btn-mrgtop" item xs={12} lg={4}>
              <Button
                className="show-mile"
                fullWidth
                variant="contained"
                sx={{
                  backgroundColor: 'primary.light',
                  color: 'common.white',
                  fontSize: '13px',
                  px: 1,
                  borderRadius: 1,
                  '&:hover': {
                    color: 'common.white',
                    backgroundColor: 'primary.light',
                  },
                }}
                onClick={handleOpen}
              >
                Milestones
              </Button>
            </Grid>
          </Grid>
          {profileData?.bio && (
            <Grid item className="pt-2 tototot" sx={{ marginTop: isMobile ? '30px' : '20px' }}>
              <Typography color={theme.profile.color} fontSize={14}>
                {showFullText ? (
                  <>
                    {profileData?.bio}
                    <span style={{ cursor: 'pointer', color: '#674BFF', fontSize: 13 }} onClick={toggleShowText}>
                      ...Less
                    </span>
                  </>
                ) : (
                  <>
                    {profileData?.bio?.slice(0, 140)}
                    {profileData?.bio?.length > 140 && (
                      <span style={{ cursor: 'pointer', color: '#674BFF', fontSize: 13 }} onClick={toggleShowText}>
                        ...More
                      </span>
                    )}
                  </>
                )}
              </Typography>
            </Grid>
          )}
          {profileData?.address && (
            <Grid display="flex" flexWrap="nowrap" gap={1} item container spacing={1} alignItems={'center'} m={0} className="mob-pt">
              <ProfileLocationIcon sx={{ fontSize: 18 }} />

              <Grid item className="ps-0">
                <Typography sx={{ fontSize: '14px' }} color={theme.profile.secondary} className="ps-0">
                  {profileData?.address}
                </Typography>
              </Grid>
            </Grid>
          )}

          <Grid
            item
            container
            gap={0.9}
            spacing={1}
            alignItems={'center'}
            m={0}
            className="mob-pt"
            sx={{
              paddingTop: '11px !important',
              paddingBottom: '11px !important',
            }}
          >
            <Grid
              item
              container
              gap={1.3}
              spacing={1}
              alignItems={'center'}
              m={0}
              className="mob-pt ps-0"
              sx={{ flexWrap: 'nowrap', display: 'grid' }}
            >
              {profileData?.website && (
                <Grid
                  container
                  gap={1}
                  component="a"
                  href={profileData?.website ? `https://${profileData?.website}` : undefined}
                  rel="noopener noreferrer"
                  target="_blank"
                  sx={{ flexWrap: 'nowrap', width: 'auto' }}
                >
                  <Grid item style={{ paddingLeft: '0px', paddingTop: '0' }}>
                    <WebIcon sx={{ fontSize: 18 }} />
                  </Grid>
                  <Grid>
                    <Typography color="#674bff" sx={{ textDecoration: 'underline' }} fontWeight={600}>
                      {profileData?.website}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {profileData?.phoneNumber && (
                <Grid
                  container
                  gap={1}
                  component="a"
                  href={`tel:${profileData?.phoneCode}${profileData?.phoneNumber}`}
                  rel="noopener noreferrer"
                  target="_blank"
                  sx={{ flexWrap: 'nowrap', width: 'auto' }}
                >
                  <Grid item style={{ paddingLeft: '0px', paddingTop: '0' }}>
                    <PhoneIcon sx={{ fontSize: 18 }} />
                  </Grid>
                  <Grid>
                    <Typography color="#674bff" sx={{ textDecoration: 'underline' }} fontWeight={600}>
                      {`${profileData?.phoneCode} ${profileData?.phoneNumber}`}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          {profileData?.socialLinks?.length > 0 && (
            <Grid
              item
              container
              spacing={1}
              alignItems={'center'}
              m={0}
              className="mob-pt"
              sx={{
                paddingTop: '11px !important',
                paddingBottom: '11px !important',
              }}
            >
              {profileData?.socialLinks?.map((item, index: any) => (
                <Fragment key={index}>
                  <a href={item.socialMediaLink} rel="noopener noreferrer" target="_blank">
                    <Typography color={theme.profile.color} fontSize={15}>
                      {socialMediaNameIcons(item?.socialMediaLink)}
                    </Typography>
                  </a>
                  {/* {index < profileData.socialLinks.length - 1 && ', '} */}
                </Fragment>
              ))}
            </Grid>
          )}
        </Grid>

        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={stylemodal}>
            <CloseOutlinedIcon onClick={handleClose} sx={{ float: 'right' }} />
            <ProfileGoals id={id} />
          </Box>
        </Modal>

        {renderInvitation()}
      </Grid>
      <PaymentModal open={paymentModalOpen} onClose={handlePaymentModalClose} />
      <PaymentmodalFailed open={paymentFailureOpen} onClose={handlePaymentFailureClose} />
      <AuthorisationModal
        currentComponent={currentComponent}
        fullScreen={fullScreen}
        authModalOpen={authModalOpen}
        onClose={handleAuthModalClose}
        handleAuthModalClose={handleAuthModalClose}
        setCurrentComponent={setCurrentComponent}
      />
    </>
  );
};

export default OrganizationDetailsSection;
