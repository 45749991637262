import {
  Autocomplete,
  Box,
  Dialog,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { animalType } from 'app/shared/reducers/categories';
import axios from 'axios';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import MapBoxMapView from 'app/components/common/map-box/map-view-mapbox';
import { StyledTextarea } from 'app/components/common/text-area/styled-textare';
import currencyData from 'app/entities/constants/currencies.json';
import countryCode from '../../../../../../utils/data/json/country-code.json';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import countryCurrencyCode from '../../../../../../utils/data/json/country-currency-code.json';

type LostPetProps = { formik: any };
interface CountryCode {
  name: string;
  web_code: string;
  country_code: string;
  flag: string;
  phone_code: string;
  phone_number_length: number;
}
const LostPet = ({ formik }: LostPetProps) => {
  const theme = useTheme();
  const countryCodes = countryCode as CountryCode[];
  const isDarkMode = theme.palette.mode === 'dark';
  const [selectedAnimal, setSelectedAnimal] = useState<string>('');
  const [animals, setAnimals] = useState<any>([]);
  const [mapTouched, setMapTouched] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedLocation, setSelectedLocation] = useState<{ lat: number; lng: number }>(null);
  const [locationFomSearch, setLocationFromSearch] = useState<string>('');
  const handleSelectAnimal = (event: SelectChangeEvent) => {
    const animalValue = event.target.value;
    if (animalValue) {
      setSelectedAnimal(animalValue);
    }
    const selectedAnimalFrom = animals.find(animal => animal?.id === animalValue);
    formik.setFieldValue('lostPet.animal', selectedAnimalFrom?.label);
    formik.setFieldValue('lostPet.petCategoryID', selectedAnimalFrom?.id);
    const selectedDateCurrent = dayjs(new Date());
    formik.setFieldValue('startDate', selectedDateCurrent);
    formik.setFieldValue('activityStartTime', selectedDateCurrent);
    const endOfDay = selectedDateCurrent.hour(23).minute(58).second(19);
    formik.setFieldValue('activityEndTime', endOfDay);
  };

  const [latitudeNear, setLatitude] = useState<any>('');
  const [longitudeNear, setLongitude] = useState<any>('');

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setMapTouched(true);
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setLatitude(latitude);
          setLongitude(longitude);
        },
        function (error) {
          if (error.code === error.PERMISSION_DENIED) {
            alert('Location access denied. Please enable location services.');
          } else {
            alert('Error getting location: ' + error.message);
          }
        }
      );
    } else {
      alert('Geolocation is not supported in this browser.');
    }
  };

  const fetchAnimalData = async () => {
    try {
      const response = await axios.get<animalType[]>(`/api/subcategory/pet`);
      if (response && response.data) {
        setAnimals(response.data);
        // formik.setFieldValue('lostPet.petCategoryID', 0);
      }
    } catch (error) {
      console.log('Error fetching activities:', error);
    }
  };

  useEffect(() => {
    fetchAnimalData();
    formik.setFieldValue('startDate', null);
    // formik.setFieldValue('lostPet.petCategoryID', " ");
  }, []);

  useEffect(() => {
    if (formik.values?.lostPet?.petCategoryID !== null) {
      formik.setFieldValue('lostPet.petCategoryID', formik.values?.lostPet?.petCategoryID);
      setSelectedAnimal(formik.values?.lostPet?.petCategoryID);
    }
  }, [formik.values?.lostPet?.petCategoryID, animals]);

  useEffect(() => {
    if (formik.values?.lostPet?.reward !== null) {
      formik.setFieldValue('lostPet.reward', formik.values?.lostPet?.reward);
    } else {
      formik.setFieldValue('lostPet.reward', 0);
    }

    if (formik.values?.lostPet?.currencyCode !== null) {
      formik.setFieldValue('lostPet.currencyCode', formik.values?.lostPet?.currencyCode);
    } else {
      formik.setFieldValue('lostPet.currencyCode', ' ');
    }

    if (formik.values?.lostPet?.breed !== null) {
      formik.setFieldValue('lostPet.breed', formik.values?.lostPet?.breed);
    } else {
      formik.setFieldValue('lostPet.breed', ' ');
    }
  }, [formik.values?.lostPet?.reward, formik.values?.lostPet?.currencyCode, formik.values?.lostPet?.breed]);

  useEffect(() => {
    const selectedDate = dayjs(new Date());
    const formattedDate = selectedDate.format('YYYY-MM-DD') + 'T00:00:00.000Z';
    formik.setFieldValue('lostPet.dateLostOn', selectedDate);
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (formik.values.latitude === '' || (formik.values.longitude === '' && mapTouched)) {
      formik.setFieldValue('latitude', Number(latitudeNear));
      formik.setFieldValue('longitude', Number(longitudeNear));
    }
  };
  const iconStyle = {
    color: theme.textColor.paragraph,
  };
  //callback function to handle selected location
  const handleSelectLocation = location => {
    setSelectedLocation(location);
    const { lat, lng } = location;
    formik.setFieldValue('latitude', lat);
    formik.setFieldValue('longitude', lng);
  };
  const handleLocationFromSearchBox = (location: string) => {
    setLocationFromSearch(location);
    formik.setFieldValue('place', location);
  };

  const currencyList = Object.keys(currencyData).map(currencyCode => ({
    code: currencyCode,
    label: ` ${currencyData[currencyCode].symbol}`,
    symbol: currencyData[currencyCode].symbol,
  }));
  return (
    <Grid container direction={'row'} gap={{ xs: 2, md: 0.5 }} alignItems={'center'} justifyContent={'space-between'}>
      <style>
        {`.css-13wovh5-MuiInputBase-root-MuiOutlinedInput-root {
          border-radius:15px 0px 0px 15px !important;
        }
        `}
        {` .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
          border-radius:15px 0px 0px 15px !important;
        }
        `}
        {/* {`.css-1vlv06b-MuiInputBase-root-MuiOutlinedInput-root {
          border-radius:0px 15px 15px 0px !important;
        }
        `} */}

        {/* {`button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator.css-1ow00yu-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
          display:none !important
        }
        `} */}
        {`.css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
                   
                    display:none !important
                }`}
        {`.css-1ow00yu-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
                         display:none !important
                    }`}
        {`.css-1h51icj-MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
                    min-width:55px !important;
                }`}
      </style>
      <Grid item xs={12} md={5} className="mb-2">
        <FormControl
          sx={{
            backgroundColor: 'secondary.dark',
            color: 'secondary.main',
            borderRadius: '15px',
            width: '100%',
          }}
        >
          <Select
            size="small"
            displayEmpty
            name="petCategoryID"
            fullWidth
            placeholder="Animal *"
            labelId="petCategoryID"
            id="petCategoryID"
            value={selectedAnimal}
            onChange={handleSelectAnimal}
            defaultValue={formik.values?.lostPet?.petCategoryID ? formik.values?.lostPet?.petCategoryID : ''}
            MenuProps={{
              PaperProps: {
                style: {
                  overflowY: 'scroll',
                },
              },
              MenuListProps: {
                style: {
                  maxHeight: '200px',
                },
              },
            }}
            sx={{
              border: 'none',
              '&.MuiMenu-paper': {
                backgroundColor: '#FF5500',
                borderRadius: '15px',
                border: 'none',
              },
              '&.MuiOutlinedInput-root': {
                background: 'secondary.main',
                borderRadius: '15px',
                height: 45,
              },
            }}
          >
            <MenuItem value="" selected>
              <em>
                <Typography sx={{ fontStyle: 'normal', color: '#88A8E' }}>Animal *</Typography>
              </em>
            </MenuItem>
            {animals?.map((item: any) => (
              <MenuItem value={item?.id} key={item?.id}>
                {item?.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {formik.touched.lostPet?.petCategoryID && formik.errors.lostPet?.petCategoryID && (
          <Typography variant="caption" sx={{ color: 'error.main' }}>
            {formik.touched.lostPet?.petCategoryID && formik.errors.lostPet?.petCategoryID}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} md={6} className="mb-2">
        <TextField
          sx={{ height: '45px' }}
          className="w-100 "
          size="small"
          name="nameOfThePet"
          value={formik.values.lostPet?.nameOfThePet}
          onChange={event => formik.setFieldValue('lostPet.nameOfThePet', event.target.value)}
          placeholder="Name of pet *"
        />
        {formik.touched.lostPet?.nameOfThePet && formik.errors.lostPet?.nameOfThePet && (
          <Typography variant="caption" className="ms-2" sx={{ color: 'error.main' }}>
            {String(formik.errors.lostPet?.nameOfThePet)}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12} md={5} className="mb-2">
        <TextField
          sx={{ height: '45px' }}
          className="w-100 "
          size="small"
          name="breed"
          value={formik.values.lostPet?.breed}
          onChange={event => formik.setFieldValue('lostPet.breed', event.target.value)}
          placeholder="Breed"
        />
        {formik.touched.lostPet?.breed && formik.errors.lostPet?.breed && (
          <Typography variant="caption" sx={{ color: 'error.main' }}>
            {formik.touched.lostPet?.breed && formik.errors.lostPet?.breed}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} md={6} className="mb-2">
        <TextField
          sx={{ height: '45px' }}
          className="w-100 "
          size="small"
          name="color"
          value={formik.values.lostPet?.color}
          onChange={event => formik.setFieldValue('lostPet.color', event.target.value)}
          placeholder="Color *"
        />
        {formik.touched.lostPet?.color && formik.errors.lostPet?.color && (
          <Typography variant="caption" className="ms-2" sx={{ color: 'error.main' }}>
            {String(formik.errors.lostPet?.color)}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} md={5} className="mb-2">
        <DatePicker
          value={dayjs(formik.values?.lostPet?.dateLostOn)}
          defaultValue={dayjs(new Date())}
          label="Date lost on"
          maxDate={dayjs(new Date())}
          sx={{
            borderRadius: '15px',
            color: theme.textColor.paragraph,
            width: '100%',
            '& .MuiInputBase-root': {
              borderRadius: '15px',
              height: '45px',
              pb: 0.4,
            },
            '& .MuiInputAdornment-root': {
              padding: 0,
            },
          }}
          onChange={(newValue, event) => {
            const selectedDate = dayjs(newValue);
            const formattedDate = selectedDate.format('YYYY-MM-DD') + 'T00:00:00.000Z';
            formik.setFieldValue('lostPet.dateLostOn', selectedDate);
          }}
        />
        {formik.touched.lostPet?.dateLostOn && formik.errors.lostPet?.dateLostOn && (
          <Typography variant="caption" className="ms-2" sx={{ color: 'error.main' }}>
            {String(formik.errors.lostPet?.dateLostOn)}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} md={6} className="mb-2">
        <FormControl fullWidth sx={{ backgroundColor: 'transperant', color: 'secondary.main', borderRadius: '15px' }} variant="outlined">
          <OutlinedInput
            autoComplete="off"
            sx={{
              height: '45px',
              color: 'secondary.main',
              border: '1px solid #BFBFBF',
              '&.MuiOutlinedInput-input::placeholder': {
                color: 'secondary.contrastText',
              },
              '&.MuiOutlinedInput-root': {
                background: 'secondary.main',
                borderRadius: '15px',
                border: 'none',
              },
              '& fieldset': {
                borderColor: isDarkMode ? 'null' : 'secondary.dark',
              },
            }}
            size="small"
            type={'text'}
            startAdornment={
              !formik.values.place ? (
                <div style={{ display: 'flex', alignItems: 'center', width: '30px' }}>
                  <LocationOnIcon style={iconStyle} />
                </div>
              ) : null
            }
            placeholder={
              formik.values.place
                ? formik.values.place
                : formik.values.latitude && formik.values.longitude
                ? `Lat: ${formik.values.latitude}, Long: ${formik.values.longitude}`
                : 'Last Seen at *'
            }
            value={
              formik.values.place
                ? formik.values.place
                : formik.values.latitude && formik.values.longitude
                ? `Lat: ${formik.values.latitude}, Long: ${formik.values.longitude}`
                : `Last Seen at *`
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="latitude"
            readOnly
            onClick={handleOpenModal}
          />
          {formik.touched.latitude &&
          formik.values.latitude === '' &&
          formik.values.longitude === '' &&
          formik.values.online === false &&
          !mapTouched ? (
            <Typography variant="caption" sx={{ color: 'error.main', mt: 0.5 }}>
              Location is required
            </Typography>
          ) : null}
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12} className="mb-2">
        <StyledTextarea
          sx={{ height: '100px' }}
          value={formik.values.lostPet?.keyIdentifier}
          onChange={e => {
            const typedText = e.target.value;
            formik.setFieldValue('lostPet.keyIdentifier', typedText);
          }}
          onPaste={e => {
            const pastedText = e.clipboardData.getData('text/plain');
            formik.setFieldValue('lostPet.keyIdentifier', pastedText);
          }}
          placeholder="Key identifier (optional)"
        />
      </Grid>
      <Grid item xs={12} md={5} className="mb-2">
        <FormControl
          sx={{ backgroundColor: 'transperant', color: 'secondary.main', borderRadius: '15px 0px 0px 15px !important', width: '30%' }}
        >
          <Autocomplete
            options={countryCurrencyCode}
            getOptionLabel={option => option.code}
            onChange={(e, value) => {
              formik.setFieldValue('lostPet.currencyCode', value?.code);
            }}
            value={countryCurrencyCode.find(option => option.code === formik.values.lostPet?.currencyCode) || null}
            renderInput={params => <TextField name="lostPet?.currencyCode" {...params} label="" size="small" placeholder="$" />}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 1, flexShrink: 0 } }} {...props}>
                <img
                  loading="lazy"
                  width="20"
                  srcSet={`https://flagcdn.com/w40/${option.countryCode.toLowerCase()}.png 2x`}
                  src={`https://flagcdn.com/w20/${option.countryCode.toLowerCase()}.png`}
                  alt=""
                />
                {option.code}
              </Box>
            )}
          />
        </FormControl>

        <FormControl
          sx={{ backgroundColor: 'transperant', color: 'secondary.main', borderRadius: '0px 15px 15px 0px !important', width: '70%' }}
        >
          <TextField
            value={formik.values.lostPet?.reward === 0 ? '' : formik.values.lostPet?.reward}
            name="reward"
            onChange={event => {
              let inputValue = event.target.value;
              inputValue = inputValue.replace(/\D/g, '');
              inputValue = inputValue.slice(0, 6);
              formik.setFieldValue('lostPet.reward', inputValue);
            }}
            type="text"
            InputProps={{ inputProps: { min: 1 } }}
            sx={{
              height: '40px',
              color: 'secondary.main',
              borderRadius: '0px 15px 15px 0px',
              '& .css-9m4evr-MuiInputBase-root-MuiOutlinedInput-root': {
                borderRadius: '0px 15px 15px 0px',
              },
              '& .css-1x5c1p5-MuiInputBase-root-MuiOutlinedInput-root': {
                borderRadius: '0px 15px 15px 0px',
              },
              // border: '1px solid #BFBFBF',
              '&.MuiOutlinedInput-input::placeholder': {
                color: 'secondary.contrastText',
              },
              '& .css-1vlv06b-MuiInputBase-root-MuiOutlinedInput-root': {
                borderRadius: '0px 15px 15px 0px',
              },
              '&.MuiOutlinedInput-input': {
                borderRadius: '0px 15px 15px 0px',
              },
              '&.MuiOutlinedInput-root': {
                background: 'secondary.main',
                borderRadius: '0px 15px 15px 0px',
              },
            }}
            size="small"
            onKeyDown={event => {
              if (event.key === 'Backspace' || event.key === 'Tab') {
                return true;
              }
              if (!/^[0-9]+$/.test(event.key)) {
                event.preventDefault();
              }
            }}
            placeholder="Reward"
          />
        </FormControl>

        {formik.touched.lostPet?.currencyCode && formik.errors.lostPet?.currencyCode && (
          <Typography variant="caption" className="ms-2" sx={{ color: 'error.main' }}>
            {String(formik.errors.lostPet?.currencyCode)}
          </Typography>
        )}

        {formik.touched.lostPet?.reward && formik.errors.lostPet?.reward && (
          <Typography variant="caption" className="ms-2" sx={{ color: 'error.main' }}>
            {String(formik.errors.lostPet?.reward)}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12} md={6} className="mb-2">
        <FormControl
          sx={{ backgroundColor: 'transperant', color: 'secondary.main', borderRadius: '15px 0px 0px 15px !important', width: '30%' }}
        >
          <Autocomplete
            options={countryCodes}
            sx={{
              width: '180px',
              '& .MuiAutocomplete-popupIndicator': {
                marginRight: '80px',
              },
              '& .MuiAutocomplete-listbox': {
                maxHeight: 200,
                overflow: 'auto',
              },
              '& .MuiAutocomplete-option': {
                padding: '10px',
              },
            }}
            getOptionLabel={option => `${option.phone_code}`}
            filterOptions={(options, { inputValue }) => {
              return options.filter(option => option.phone_code.toLowerCase().includes(inputValue.toLowerCase()));
            }}
            onChange={(e, value) => {
              formik.setFieldValue('lostPet.countryCode', value?.phone_code || '');
            }}
            value={countryCodes.find(option => option.phone_code === formik.values.lostPet?.countryCode) || null}
            renderInput={params => <TextField name={`lostpet?.countryCode`} {...params} label="" size="small" placeholder="+" />}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.phone_code} {option.name}
              </Box>
            )}
          />
        </FormControl>
        <FormControl
          sx={{ backgroundColor: 'transperant', color: 'secondary.main', borderRadius: '0px 15px 15px 0px !important', width: '70%' }}
        >
          <TextField
            sx={{
              height: '40px',
              '& .css-9m4evr-MuiInputBase-root-MuiOutlinedInput-root': {
                borderRadius: '0px 15px 15px 0px',
              },
              '& .css-1x5c1p5-MuiInputBase-root-MuiOutlinedInput-root': {
                borderRadius: '0px 15px 15px 0px',
              },
              '& .css-1vlv06b-MuiInputBase-root-MuiOutlinedInput-root': {
                borderRadius: '0px 15px 15px 0px',
              },
            }}
            className="w-100"
            name="lostPet.phoneNumber"
            value={formik.values.lostPet?.phoneNumber || ''}
            onChange={event => {
              const inputValue = event.target.value;
              // Limit to 15 digits
              if (/^\d{0,15}$/.test(inputValue)) {
                formik.setFieldValue('lostPet.phoneNumber', inputValue);
              }
            }}
            onBlur={formik.handleBlur}
            size="small"
            placeholder="Phone number*"
            onKeyDown={event => {
              if (event.key === 'Backspace' || event.key === 'Tab') {
                return true;
              }
              if (!/^[0-9]+$/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </FormControl>

        {formik.touched.lostPet?.countryCode && formik.errors.lostPet?.countryCode && (
          <Typography variant="caption" className="ms-2" sx={{ color: 'error.main' }}>
            {String(formik.errors.lostPet?.countryCode)}
          </Typography>
        )}
        {formik.touched.lostPet?.phoneNumber && formik.errors.lostPet?.phoneNumber && (
          <Typography variant="caption" className="ms-2" sx={{ color: 'error.main' }}>
            {String(formik.errors.lostPet?.phoneNumber)}
          </Typography>
        )}
      </Grid>

      <Dialog
        fullScreen={fullScreen}
        open={isModalOpen}
        onClose={handleCloseModal}
        PaperProps={{
          sx: {
            borderRadius: { xs: 0, sm: '15px' },
            boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
          },
        }}
      >
        <MapBoxMapView
          handleCloseModal={handleCloseModal}
          formik={formik}
          handleSelectLocation={handleSelectLocation}
          handleLocationFromSearchBox={handleLocationFromSearchBox}
        />
      </Dialog>
    </Grid>
  );
};
export default LostPet;
