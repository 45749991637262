import activityMaster from 'app/entities/activity-master/activity-master.reducer';
import categoryMaster from 'app/entities/category-master/category-master.reducer';
import customOccurence from 'app/entities/custom-occurence/custom-occurence.reducer';
import activity from 'app/entities/activity/activity.reducer';
import messageTable from 'app/entities/message-table/message-table.reducer';
import blogComments from 'app/entities/blog-comments/blog-comments.reducer';
import activityComments from 'app/entities/activity-comments/activity-comments.reducer';
import rulesTable from 'app/entities/rules-table/rules-table.reducer';
import blogTable from 'app/entities/blog-table/blog-table.reducer';
import userPreferences from 'app/entities/user-preferences/user-preferences.reducer';
import constants from 'app/entities/constants/constants.reducer';
import locationMaster from 'app/entities/location-master/location-master.reducer';
import interestMaster from 'app/entities/interest-master/interest-master.reducer';
import image from 'app/entities/image/image.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  activityMaster,
  categoryMaster,
  customOccurence,
  activity,
  messageTable,
  blogComments,
  activityComments,
  rulesTable,
  blogTable,
  userPreferences,
  constants,
  locationMaster,
  interestMaster,
  image,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
