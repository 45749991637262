// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 600px) {
  .body-padding {
    padding: 0px !important;
  }
  .mob-logo {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/ui/settings/secondary-header.scss"],"names":[],"mappings":"AAAA;EACE;IACE,uBAAA;EACF;EACA;IACE,aAAA;EACF;AACF","sourcesContent":["@media only screen and (max-width: 600px) {\n  .body-padding {\n    padding: 0px !important;\n  }\n  .mob-logo {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
