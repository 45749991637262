import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const StarIconOutlined = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 13.117 13.117">
    <g id="star_24x24_1x_1_" data-name="star(24x24)@1x (1)" transform="translate(0.5 0.5)">
      <path
        id="star_24x24_1x_1_2"
        data-name="star(24x24)@1x (1)"
        d="M8.059,2,9.876,6.241h4.241L11.088,9.876,12.3,14.117,8.059,11.694,3.818,14.117,5.029,9.876,2,6.241H6.241Z"
        transform="translate(-2 -2)"
        fill="none"
        stroke="#676e7b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>,

  'StarIconOutlined'
);
export default StarIconOutlined;
