import React, { useEffect } from 'react';
import { Box, Button, Grid, Stack, Typography, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { getOtp, getPhoneNumber } from 'app/shared/reducers/phone-verification';
import { useAppDispatch } from 'app/config/store';
import { Storage } from 'react-jhipster';
import toast from 'react-hot-toast';
import './phone-number-input-section.scss';

const PhoneInputSection = ({ onCompleted }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDarkMode = theme.palette.mode === 'dark';
  useEffect(() => {
    const fetchPhoneNumber = async () => {
      try {
        const response = await dispatch(getPhoneNumber());
        if (response.meta.requestStatus == 'fulfilled') {
          const { countryCode, phoneNumber } = response.payload;
          // if (countryCode && phoneNumber) {
          //   const formattedPhoneNumber = `${countryCode}${phoneNumber}`;
          //   formik.setFieldValue('phone', formattedPhoneNumber);
          // } else {
          //   formik.setFieldValue('phone', '');
          // }
        } else {
          toast.error('Unable to fetch phone number!!', { id: 'phnumber-fetch-error' });
        }
      } catch (error) {
        toast.error('Unable to fetch phone number!!', { id: 'phnumber-fetch-error' });
      }
    };

    fetchPhoneNumber();
  }, [dispatch]);

  const sendOtp = async (countryCode, medium, phoneNo) => {
    try {
      const response = await dispatch(getOtp({ phoneNumber: phoneNo, medium: medium, countryCode: countryCode }));
      return response;
    } catch (error) {
      throw error;
    }
  };

  const formik = useFormik({
    initialValues: {
      phone: '',
      submitType: '',
    },
    validationSchema: Yup.object().shape({
      phone: Yup.string()
        .required('Phone number is required')
        .test('isValidPhone', 'Invalid phone number', value => {
          return value ? isValidPhoneNumber(value) : true;
        }),
    }),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      try {
        if (!isValidPhoneNumber(values.phone)) {
          setFieldError('phone', 'Invalid phone number');
        } else {
          const phoneInfo = parsePhoneNumber(values.phone);
          const country = phoneInfo.country;
          const countryCode = `+${phoneInfo.countryCallingCode}`;
          const phoneNo = phoneInfo.nationalNumber;
          // Determine the medium based on which button was clicked
          const medium = formik.values.submitType === 'sms' ? 'sms' : 'whatsapp';
          const response = await sendOtp(countryCode, medium, phoneNo);
          Storage.session.set('country', country);
          Storage.session.set('country-code', countryCode);
          Storage.session.set('phone-number', phoneNo);
          Storage.session.set('medium', medium);
          if (response.meta.requestStatus == 'fulfilled') {
            onCompleted(true);
            toast.success('OTP Sent Successfully');
          } else {
            setFieldError('phone', 'Something went wrong!');
          }
        }
      } catch (error) {
        setFieldError('phone', error.response?.data?.message || 'An error occurred');
      }
      setSubmitting(false);
    },
  });
  const handleKeyDown = event => {
    // Check if the backspace key is pressed (keyCode 8)
    if (event.keyCode === 8) {
      // Clear the country selection when backspace is pressed
      formik.setFieldValue('phone', '');
    }
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={`phone-input ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
        <style>{`input.PhoneInputInput{
        border-radius:8px !important;
        border: 1px solid #bfbfbf !important;}`}</style>
        <Grid container gap={2} direction="column" alignItems={'center'} justifyContent={'center'}>
          {/* <Typography variant="h5" align="center">
            Please verify your phone number
          </Typography> */}
          <small>
            Please verify your phone number before organizing or participating in any activity or petition. This step is essential for
            maintaining the integrity and security of our community. Thank you for your cooperation!
          </small>
          <Box sx={{ marginBottom: '8%' }}>
            <PhoneInput
              placeholder="Enter phone number"
              value={formik.values.phone}
              defaultCountry="US"
              onChange={phone => formik.setFieldValue('phone', phone)}
              onBlur={formik.handleBlur('phone')}
              required
              limitMaxLength
              countryCallingCodeEditable={false}
              international
              onKeyDown={handleKeyDown}
              style={{ borderRadius: '18px' }}
            />
            {formik.touched.phone && formik.errors.phone && <div style={{ color: 'red' }}>{formik.errors.phone}</div>}
          </Box>
          <Stack direction={'row'} gap={1}>
            {/* <Button
              type="submit"
              variant="outlined"
              style={{ textTransform: 'none' }}
              sx={{
                backgroundColor: isDarkMode ? '#2C2C38' : 'common.white',
                borderColor: 'primary.light',
                color: 'primary.light',
                '&:hover': {
                  backgroundColor: isDarkMode ? '#2C2C38' : 'common.white',
                  borderColor: 'primary.light',
                  color: 'primary.light',
                  textTransform: 'capitalize',
                  boxShadow: 'none',
                },
              }}
              disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
              onClick={() => formik.setFieldValue('submitType', 'whatsapp')}
            >
              SEND OTP via WhatsApp
            </Button> */}
            <Button
              variant="contained"
              type="submit"
              style={{ textTransform: 'none' }}
              sx={{
                backgroundColor: 'primary.light',
                width: 'fit-content',
                color: 'common.white',
                '&:hover': {
                  color: 'common.white',
                  backgroundColor: 'primary.light',
                  textTransform: 'capitalize',
                  boxShadow: 'none',
                },
              }}
              disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
              onClick={() => formik.setFieldValue('submitType', 'sms')}
            >
              Send OTP via SMS
            </Button>
          </Stack>
        </Grid>
      </div>
    </form>
  );
};

export default PhoneInputSection;
