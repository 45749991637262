import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const AquaticlifeIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="21.858" height="21.69" viewBox="0 0 21.858 21.69" style={{ fontSize: 'larger' }}>
    <g id="Aquatic_life" data-name="Aquatic life" transform="translate(0 -1.964)">
      <path
        id="Path_4791"
        data-name="Path 4791"
        d="M179.47,183.754l-2.392-.68a.633.633,0,0,0-.623.157l-2.271,2.271a.633.633,0,0,0-.157.623l.68,2.392a.678.678,0,0,0,.466.466l2.392.679a.633.633,0,0,0,.623-.156l2.271-2.271a.633.633,0,0,0,.156-.623l-.68-2.392A.681.681,0,0,0,179.47,183.754Z"
        transform="translate(-166.573 -173.354)"
        fill="#676e7b"
      />
      <path
        id="Path_4792"
        data-name="Path 4792"
        d="M225.3,99.134a6.875,6.875,0,0,0-1.3.25.411.411,0,0,0-.276.192.423.423,0,0,0-.043.336l.565,1.987a.679.679,0,0,0,.465.466l2.393.68a.631.631,0,0,0,.622-.156l1.869-1.869a.419.419,0,0,0,.117-.357.449.449,0,0,0-.214-.321,4.943,4.943,0,0,0-1.124-.679A7,7,0,0,0,225.3,99.134Z"
        transform="translate(-214.117 -93.011)"
        fill="#676e7b"
      />
      <path
        id="Path_4793"
        data-name="Path 4793"
        d="M108.077,119.754l1.787.507a.631.631,0,0,0,.622-.156l2.272-2.271a.633.633,0,0,0,.156-.623l-.09-.315-.44-1.546a.454.454,0,0,0-.251-.289.428.428,0,0,0-.376.011,5.655,5.655,0,0,0-.935.495,9.319,9.319,0,0,0-1.393,1.153,8.456,8.456,0,0,0-1.121,1.378,5.905,5.905,0,0,0-.515,1.041.433.433,0,0,0,0,.371A.455.455,0,0,0,108.077,119.754Z"
        transform="translate(-103.15 -108.23)"
        fill="#676e7b"
      />
      <path
        id="Path_4794"
        data-name="Path 4794"
        d="M96.808,236.86l-.68-2.393a.678.678,0,0,0-.466-.466l-.133-.038-1.744-.5a.428.428,0,0,0-.34.045.413.413,0,0,0-.19.281,6.448,6.448,0,0,0-.192,1.088,8.9,8.9,0,0,0,.224,3.4,4.564,4.564,0,0,0,.645,1.131.451.451,0,0,0,.321.223.421.421,0,0,0,.362-.116l2.035-2.034A.632.632,0,0,0,96.808,236.86Z"
        transform="translate(-89.041 -221.604)"
        fill="#676e7b"
      />
      <path
        id="Path_4795"
        data-name="Path 4795"
        d="M324.083,147.723a.452.452,0,0,0-.321-.214.421.421,0,0,0-.357.117l-1.869,1.869a.634.634,0,0,0-.157.623l.68,2.392a.679.679,0,0,0,.466.465l1.987.565a.426.426,0,0,0,.337-.043.411.411,0,0,0,.191-.276,6.906,6.906,0,0,0,.25-1.3,7,7,0,0,0-.528-3.078A4.957,4.957,0,0,0,324.083,147.723Z"
        transform="translate(-307.635 -139.327)"
        fill="#676e7b"
      />
      <path
        id="Path_4796"
        data-name="Path 4796"
        d="M279.527,284.18l-1.547-.44-.315-.09a.633.633,0,0,0-.622.156l-2.272,2.272a.632.632,0,0,0-.156.622l.507,1.787a.454.454,0,0,0,.246.287.433.433,0,0,0,.371,0,5.945,5.945,0,0,0,1.041-.515,8.457,8.457,0,0,0,1.378-1.122,9.28,9.28,0,0,0,1.152-1.393,5.627,5.627,0,0,0,.5-.935.431.431,0,0,0,.01-.376A.451.451,0,0,0,279.527,284.18Z"
        transform="translate(-262.867 -269.637)"
        fill="#676e7b"
      />
      <path
        id="Path_4797"
        data-name="Path 4797"
        d="M140.247,331.712l-.037-.133a.679.679,0,0,0-.466-.466l-2.393-.679a.632.632,0,0,0-.622.157l-2.034,2.035a.419.419,0,0,0-.116.362.453.453,0,0,0,.223.321,4.551,4.551,0,0,0,1.131.645,8.89,8.89,0,0,0,3.4.224,6.47,6.47,0,0,0,1.088-.192.414.414,0,0,0,.281-.19.427.427,0,0,0,.045-.34Z"
        transform="translate(-128.828 -314.423)"
        fill="#676e7b"
      />
      <path
        id="Path_4798"
        data-name="Path 4798"
        d="M355.788,64.917a8.218,8.218,0,0,0,1.332-.559,3.765,3.765,0,0,0,.9-1.677,1.913,1.913,0,0,0-1.977-1.978,3.769,3.769,0,0,0-1.677.9,8.215,8.215,0,0,0-.559,1.333,8.776,8.776,0,0,1,1.161.817A8.762,8.762,0,0,1,355.788,64.917Z"
        transform="translate(-338.705 -56.214)"
        fill="#676e7b"
      />
      <path
        id="Path_4799"
        data-name="Path 4799"
        d="M148.5,3.664l2.876.82a7.307,7.307,0,0,1,.377,1.387,5.053,5.053,0,0,1,2.844-.135l-.391-1.8a2.5,2.5,0,0,0-2.966-1.916l-2.695.567a.561.561,0,0,0-.444.525A.55.55,0,0,0,148.5,3.664Z"
        transform="translate(-141.774 0)"
        fill="#676e7b"
      />
      <path
        id="Path_4800"
        data-name="Path 4800"
        d="M422.428,214.079l-1.8-.391a5.051,5.051,0,0,1-.135,2.844,7.3,7.3,0,0,1,1.387.377l.82,2.876a.551.551,0,0,0,.554.4.561.561,0,0,0,.524-.444l.567-2.695A2.5,2.5,0,0,0,422.428,214.079Z"
        transform="translate(-402.54 -202.685)"
        fill="#676e7b"
      />
      <path
        id="Path_4801"
        data-name="Path 4801"
        d="M1.908,254.747a1.4,1.4,0,0,0-1.364.649l-.5,1.357a.659.659,0,0,0,.777.868l2.732-.683a4.333,4.333,0,0,1,0-1.972Z"
        transform="translate(0 -241.976)"
        fill="#676e7b"
      />
      <path
        id="Path_4802"
        data-name="Path 4802"
        d="M189.832,426.853a4.332,4.332,0,0,1-1.972,0l-.684,2.733a.659.659,0,0,0,.868.777l1.357-.5a1.4,1.4,0,0,0,.649-1.365Z"
        transform="translate(-179.167 -406.749)"
        fill="#676e7b"
      />
    </g>
  </svg>,
  'AquaticlifeIcon'
);
export default AquaticlifeIcon;
