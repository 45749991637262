import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

type DataType = any;

const defaultDataProps = {
  loading: false,
  isSuccess: false,
  errorResponse: null,
  successResponse: null,
};

const initialState = {
  data: {
    activityCount: { ...defaultDataProps },
    achievedTargetGoals: { ...defaultDataProps },
    goalCountsForCategory: { ...defaultDataProps },
    categoryHighestDetails: { ...defaultDataProps },
    latestActivityShareCounts: { ...defaultDataProps },
    individualSocialMediaCounts: { ...defaultDataProps },
    latestAndPreviousActivityCounts: { ...defaultDataProps },
    goalCountsForIndividual: { ...defaultDataProps },
    orgLatestActivityShareCounts: { ...defaultDataProps },
    orgLatestAndPreviousActivityCounts: { ...defaultDataProps },
    goalCountsForOrg: { ...defaultDataProps },
    activityCountForCategoryByDates: { ...defaultDataProps },
  },
};

// dash-board-info-resource
export const fetchActivityCount = createAsyncThunk<DataType, void, { rejectValue: string }>(
  'dashboard/fetchActivityCount',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get('/api/dashboard/activity-counts-by-category');
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch data');
    }
  }
);

export const fetchAchievedAndTargetGoalsByCategories = createAsyncThunk<DataType, void, { rejectValue: string }>(
  'dashboard/fetchAchievedAndTargetGoalsByCategories',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get('/api/dashboard/getAchievedAndTargetGoalsByCategories');
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch data');
    }
  }
);

export const fetchHighestDetailsForCategory = createAsyncThunk<any, any, { rejectValue: string }>(
  'dashboard/fetchHighestDetailsForCategory',
  async (categoryId, thunkAPI) => {
    try {
      const response = await axios.get(`/api/dashboard/getHighestDetailsForCategory/${categoryId}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch data');
    }
  }
);

export const fetchGoalCountsForCategory = createAsyncThunk<any, any, { rejectValue: string }>(
  'dashboard/fetchGoalCountsForCategory',
  async (categoryId, thunkAPI) => {
    try {
      const response = await axios.get(`/api/dashboard/getGoalCountsForCategory/${categoryId}`);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch data');
    }
  }
);

export const fetchActivityCountForCategoryByDates = createAsyncThunk<any, any, { rejectValue: string }>(
  'dashboard/fetchActivityCountForCategoryByDates',
  async (categoryId, thunkAPI) => {
    try {
      const response = await axios.get(`/api/dashboard/getActivityCountForCategoryByDates/${categoryId}`);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch data');
    }
  }
);

// individual-dashboard-resouce
export const fetchLatestActivityShareCounts = createAsyncThunk<any, any, { rejectValue: string }>(
  'dashboard/fetchLatestActivityShareCounts',
  async (userId, thunkAPI) => {
    try {
      const response = await axios.get(`/api/individualDashboard/getLatestActivityShareCounts/${userId}`);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch data');
    }
  }
);

export const fetchSocialMediaCountsIndividual = createAsyncThunk<DataType, void, { rejectValue: string }>(
  'dashboard/fetchSocialMediaCountsIndividual',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get('/api/activity/socialmedia/user-chart');
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch data');
    }
  }
);

export const fetchLatestAndPreviousActivityCounts = createAsyncThunk<any, any, { rejectValue: string }>(
  'dashboard/fetchLatestAndPreviousActivityCounts',
  async (userId, thunkAPI) => {
    try {
      const response = await axios.get(`/api/individualDashboard/getLatestAndPreviousActivityCounts/${userId}`);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch data');
    }
  }
);

export const fetchGoalCountsForIndividual = createAsyncThunk<any, any, { rejectValue: string }>(
  'dashboard/fetchGoalCountsForIndividual',
  async (userId, thunkAPI) => {
    try {
      const response = await axios.get(`/api/individualDashboard/getGoalCountsForIndividual/${userId}`);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch data');
    }
  }
);

// organisation-dashboard-resouce
export const fetchOrgLatestActivityShareCounts = createAsyncThunk<any, any, { rejectValue: string }>(
  'dashboard/fetchOrgLatestActivityShareCounts',
  async (organizationId, thunkAPI) => {
    try {
      const response = await axios.get(`/api/activity/socialmedia/organization-chart/${organizationId}`);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch data');
    }
  }
);

// export const fetchOrgLatestActivityShareCounts = createAsyncThunk<any, any, { rejectValue: string }>(
//   'dashboard/fetchOrgLatestActivityShareCounts',
//   async (organizationId, thunkAPI) => {
//     try {
//       const response = await axios.get(`/api/organizationDashboard/getLatestActivityShareCounts/${organizationId}`);

//       return response.data;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch data');
//     }
//   }
// );

export const fetchOrgLatestAndPreviousActivityCounts = createAsyncThunk<any, any, { rejectValue: string }>(
  'dashboard/fetchOrgLatestAndPreviousActivityCounts',
  async (organizationId, thunkAPI) => {
    try {
      const response = await axios.get(`/api/organizationDashboard/getLatestAndPreviousActivityCounts/${organizationId}`);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch data');
    }
  }
);

export const fetchGoalCountsForOrg = createAsyncThunk<any, any, { rejectValue: string }>(
  'dashboard/fetchGoalCountsForOrg',
  async (organizationId, thunkAPI) => {
    try {
      const response = await axios.get(`/api/organizationDashboard/getGoalCountsForOrganization/${organizationId}`);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch data');
    }
  }
);

const handleAsyncAction = (state: any, action: any) => {
  state.loading = true;
  if (action.error) {
    state.loading = false;
    state.isSuccess = false;
    state.errorResponse = action.error;
    state.successResponse = [];
  } else {
    state.loading = false;
    state.isSuccess = !!action.payload;
    state.errorResponse = null;
    state.successResponse = action.payload !== undefined ? action.payload : [];
  }
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // Fetch Activity Count
      .addCase(fetchActivityCount.pending, (state: any) => {
        handleAsyncAction(state.data.activityCount, { payload: null });
      })
      .addCase(fetchActivityCount.fulfilled, (state: any, action: any) => {
        handleAsyncAction(state.data.activityCount, action);
      })
      .addCase(fetchActivityCount.rejected, (state: any, action: any) => {
        handleAsyncAction(state.data.activityCount, action);
      })

      // Fetch Achieved and Target Goals by Categories
      .addCase(fetchAchievedAndTargetGoalsByCategories.pending, (state: any) => {
        handleAsyncAction(state.data.achievedTargetGoals, { payload: null });
      })
      .addCase(fetchAchievedAndTargetGoalsByCategories.fulfilled, (state: any, action: any) => {
        handleAsyncAction(state.data.achievedTargetGoals, action);
      })
      .addCase(fetchAchievedAndTargetGoalsByCategories.rejected, (state: any, action: any) => {
        handleAsyncAction(state.data.achievedTargetGoals, action);
      })

      // Fetch Highest Details for Category
      .addCase(fetchHighestDetailsForCategory.pending, (state: any) => {
        handleAsyncAction(state.data.categoryHighestDetails, { payload: null });
      })
      .addCase(fetchHighestDetailsForCategory.fulfilled, (state: any, action: any) => {
        handleAsyncAction(state.data.categoryHighestDetails, action);
      })
      .addCase(fetchHighestDetailsForCategory.rejected, (state: any, action: any) => {
        handleAsyncAction(state.data.categoryHighestDetails, action);
      })

      // Fetch Goal Counts for Individual
      .addCase(fetchGoalCountsForIndividual.pending, (state: any) => {
        handleAsyncAction(state.data.goalCountsForIndividual, { payload: null });
      })
      .addCase(fetchGoalCountsForIndividual.fulfilled, (state: any, action: any) => {
        handleAsyncAction(state.data.goalCountsForIndividual, action);
      })
      .addCase(fetchGoalCountsForIndividual.rejected, (state: any, action: any) => {
        handleAsyncAction(state.data.goalCountsForIndividual, action);
      })

      // Fetch Goal Counts for Category
      .addCase(fetchGoalCountsForCategory.pending, (state: any) => {
        handleAsyncAction(state.data.goalCountsForCategory, { payload: null });
      })
      .addCase(fetchGoalCountsForCategory.fulfilled, (state: any, action: any) => {
        handleAsyncAction(state.data.goalCountsForCategory, action);
      })
      .addCase(fetchGoalCountsForCategory.rejected, (state: any, action: any) => {
        handleAsyncAction(state.data.goalCountsForCategory, action);
      })

      // Fetch Latest Activity Share Counts
      .addCase(fetchLatestActivityShareCounts.pending, (state: any) => {
        handleAsyncAction(state.data.latestActivityShareCounts, { payload: null });
      })
      .addCase(fetchLatestActivityShareCounts.fulfilled, (state: any, action: any) => {
        handleAsyncAction(state.data.latestActivityShareCounts, action);
      })
      .addCase(fetchLatestActivityShareCounts.rejected, (state: any, action: any) => {
        handleAsyncAction(state.data.latestActivityShareCounts, action);
      })

      .addCase(fetchSocialMediaCountsIndividual.pending, (state: any) => {
        handleAsyncAction(state.data.individualSocialMediaCounts, { payload: null });
      })
      .addCase(fetchSocialMediaCountsIndividual.fulfilled, (state: any, action: any) => {
        handleAsyncAction(state.data.individualSocialMediaCounts, action);
      })
      .addCase(fetchSocialMediaCountsIndividual.rejected, (state: any, action: any) => {
        handleAsyncAction(state.data.individualSocialMediaCounts, action);
      })

      // Fetch Latest and Previous Activity Counts
      .addCase(fetchLatestAndPreviousActivityCounts.pending, (state: any) => {
        handleAsyncAction(state.data.latestAndPreviousActivityCounts, { payload: null });
      })
      .addCase(fetchLatestAndPreviousActivityCounts.fulfilled, (state: any, action: any) => {
        handleAsyncAction(state.data.latestAndPreviousActivityCounts, action);
      })
      .addCase(fetchLatestAndPreviousActivityCounts.rejected, (state: any, action: any) => {
        handleAsyncAction(state.data.latestAndPreviousActivityCounts, action);
      })

      // Fetch Counts for Category by Date
      .addCase(fetchActivityCountForCategoryByDates.pending, (state: any) => {
        handleAsyncAction(state.data.activityCountForCategoryByDates, { payload: null });
      })
      .addCase(fetchActivityCountForCategoryByDates.fulfilled, (state: any, action: any) => {
        handleAsyncAction(state.data.activityCountForCategoryByDates, action);
      })
      .addCase(fetchActivityCountForCategoryByDates.rejected, (state: any, action: any) => {
        handleAsyncAction(state.data.activityCountForCategoryByDates, action);
      })

      // Fetch Org Latest Activity Share Counts
      .addCase(fetchOrgLatestActivityShareCounts.pending, (state: any) => {
        handleAsyncAction(state.data.orgLatestActivityShareCounts, { payload: null });
      })
      .addCase(fetchOrgLatestActivityShareCounts.fulfilled, (state: any, action: any) => {
        handleAsyncAction(state.data.orgLatestActivityShareCounts, action);
      })
      .addCase(fetchOrgLatestActivityShareCounts.rejected, (state: any, action: any) => {
        handleAsyncAction(state.data.orgLatestActivityShareCounts, action);
      })
      // Fetch Latest and Previous Activity Counts
      .addCase(fetchOrgLatestAndPreviousActivityCounts.pending, (state: any) => {
        handleAsyncAction(state.data.orgLatestAndPreviousActivityCounts, { payload: null });
      })
      .addCase(fetchOrgLatestAndPreviousActivityCounts.fulfilled, (state: any, action: any) => {
        handleAsyncAction(state.data.orgLatestAndPreviousActivityCounts, action);
      })
      .addCase(fetchOrgLatestAndPreviousActivityCounts.rejected, (state: any, action: any) => {
        handleAsyncAction(state.data.orgLatestAndPreviousActivityCounts, action);
      })
      // Fetch Goal Counts for Org
      .addCase(fetchGoalCountsForOrg.pending, (state: any) => {
        handleAsyncAction(state.data.goalCountsForOrg, { payload: null });
      })
      .addCase(fetchGoalCountsForOrg.fulfilled, (state: any, action: any) => {
        handleAsyncAction(state.data.goalCountsForOrg, action);
      })
      .addCase(fetchGoalCountsForOrg.rejected, (state: any, action: any) => {
        handleAsyncAction(state.data.goalCountsForOrg, action);
      });
  },
});

export default dashboardSlice.reducer;
