export enum Category {
  ANIMAL_RESCUE = 'ANIMAL_RESCUE',

  ENVIRONMENT = 'ENVIRONMENT',

  HEALTH = 'HEALTH',

  EVENTS = 'EVENTS',

  PETITIONS = 'PETITIONS',

  PRIVATE = 'PRIVATE',

  OTHERS = 'OTHERS',
}
