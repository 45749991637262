// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollable-div {
  scrollbar-width: thin;
}

.scrollable-div::-webkit-scrollbar {
  width: 0px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/ui/create-activity/create-activity-instruction-resources/create-activity-resources/create-activity-resources.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;;AACA;EACE,UAAA;AAEF;;AAAA;;EAEE,wBAAA;EACA,SAAA;AAGF","sourcesContent":[".scrollable-div {\n  scrollbar-width: thin;\n}\n.scrollable-div::-webkit-scrollbar {\n  width: 0px;\n}\ninput[type='number']::-webkit-inner-spin-button,\ninput[type='number']::-webkit-outer-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
