import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const CaretDown = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="13.281" height="6.641" viewBox="0 0 13.281 6.641">
    <path
      id="caret-down---filled_24x24_1x"
      data-name="caret-down---filled(24x24)@1x"
      d="M17.281,8H4l6.641,6.641Z"
      transform="translate(-4 -8)"
      fill="#bfbfbf"
    />
  </svg>,
  'CaretDown'
);
export default CaretDown;
