// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 15px;
}

#strength {
  display: inline-flex;
  float: right;
}

#strengthBar {
  display: flex;
  margin-top: 7px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/account/password-reset/finish/password-reset-finish.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,2BAAA;EACA,mCAAA;EACA,4BAAA;EACA,2DAAA;EACA,wBAAA;EACA,qBAAA;EACA,gBAAA;EACA,sCAAA;EACA,wEAAA;EACA,mBAAA;AACF;;AAEA;EACE,oBAAA;EACA,YAAA;AACF;;AACA;EACE,aAAA;EACA,eAAA;AAEF","sourcesContent":[".form-control {\n  display: block;\n  width: 100%;\n  padding: 0.375rem 0.75rem;\n  font-size: 1rem;\n  font-weight: 400;\n  line-height: 1.5;\n  color: var(--bs-body-color);\n  background-color: var(--bs-body-bg);\n  background-clip: padding-box;\n  border: var(--bs-border-width) solid var(--bs-border-color);\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  appearance: none;\n  border-radius: var(--bs-border-radius);\n  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;\n  border-radius: 15px;\n}\n\n#strength {\n  display: inline-flex;\n  float: right;\n}\n#strengthBar {\n  display: flex;\n  margin-top: 7px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
