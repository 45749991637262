import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Box, useTheme } from '@mui/material';
import FastfoodIcon from '@mui/icons-material/Fastfood';

ChartJS.register(ArcElement, Tooltip, Legend);

type Props = {
  name: string;
  cutout?: string;
  height?: string;
  width?: string;
  innerHeight?: number;
  innerWidth?: number;
  achieved: number;
  target: number;
};

const getIconPath = (goalName: any) => {
  let iconPath;
  switch (goalName) {
    case 'No. of people trained':
      iconPath = 'content/images/dashboard/goals/no_of_people_trained.png';
      return iconPath;
    case 'No. of elderly':
      iconPath = 'content/images/dashboard/goals/no_of_elderly.png';
      return iconPath;
    case 'Meals Served':
      iconPath = 'content/images/dashboard/goals/meals_served.png';
      return iconPath;
    case 'Total hours of work':
      iconPath = 'content/images/dashboard/goals/total_hours_of_work.png';
      return iconPath;
    case 'Area in Sq Ft':
      iconPath = 'content/images/dashboard/goals/area_in_sq_ft.png';
      return iconPath;
    case 'Amount of money raised':
      iconPath = 'content/images/dashboard/goals/amount_of_money_raised.png';
      return iconPath;
    case 'No. of people tested':
      iconPath = 'content/images/dashboard/goals/no_of_people_tested.png';
      return iconPath;
    case 'No. of people':
      iconPath = 'content/images/dashboard/goals/no_of_people.png';
      return iconPath;
    case 'No. of books':
      iconPath = 'content/images/dashboard/goals/no_of_books.png';
      return iconPath;
    case 'No. of students':
      iconPath = 'content/images/dashboard/goals/no_of_schools.png';
      return iconPath;
    case 'Kgs of waste':
      iconPath = 'content/images/dashboard/goals/kgs_of_waste.png';
      return iconPath;
    case 'KW installed':
      iconPath = 'content/images/dashboard/goals/kw_installed.png';
      return iconPath;
    case 'Kms travelled':
      iconPath = 'content/images/dashboard/goals/kms_travelled.png';
      return iconPath;
    case 'No. of thermostats installed':
      iconPath = 'content/images/dashboard/goals/no_of_thermostats_installed.png';
      return iconPath;
    case 'No. of bulbs':
      iconPath = 'content/images/dashboard/goals/no_of_bulbs.png';
      return iconPath;
    case 'No. of innovations':
      iconPath = 'content/images/dashboard/goals/no_of_innovations.png';
      return iconPath;
    case 'Area Mapped':
      iconPath = 'content/images/dashboard/goals/area_mapped.png';
      return iconPath;
    case 'No. of buildings':
      iconPath = 'content/images/dashboard/goals/no_of_buildings.png';
      return iconPath;
    case 'No. of trees':
      iconPath = 'content/images/dashboard/goals/no_of_trees.png';
      return iconPath;
    case 'No. of animals':
      iconPath = 'content/images/dashboard/goals/no_of_animals.png';
      return iconPath;
    case 'Area':
      iconPath = 'content/images/dashboard/goals/area.png';
      return iconPath;
    case 'No. of NGOs':
      iconPath = 'content/images/dashboard/goals/no_of_ngos.png';
      return iconPath;
    case 'No. of schools':
      iconPath = 'content/images/dashboard/goals/no_of_schools.png';
      return iconPath;
    case 'Kgs':
      iconPath = 'content/images/dashboard/goals/kgs.png';
      return iconPath;
    case 'No.of clothes':
      iconPath = 'content/images/dashboard/goals/no_of_clothes.png';
      return iconPath;
    default:
      iconPath = 'content/images/dashboard/goals/no_of_people.png';
      return iconPath;
  }
};

const DoughnutRound = ({ cutout, height, width, innerWidth, innerHeight, achieved, target, name }: Props) => {
  const safeAchieved = achieved > target ? target : achieved;
  const remaining = target - safeAchieved;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const restColor = isDarkMode ? '#454c60' : '#ced5e1';

  const data = {
    datasets: [
      {
        data: [safeAchieved, remaining],
        backgroundColor: ['#674bff', restColor],
        hoverOffset: 4,
        borderWidth: 0,
      },
    ],
  };

  const options = {
    cutout: cutout ? cutout : '78%', // Increase this value to make the doughnut hole larger
    plugins: {
      tooltip: {
        enabled: false, // Set to false if you want to disable tooltips
      },
      legend: {
        display: false, // Set to true if you want to display the legend
      },
    },
    maintainAspectRatio: false,
  };

  const chartContainerStyle = {
    width: height ? height : '76px',
    height: width ? width : '85px',
  };

  return (
    <Box position="relative" display="flex" alignItems="center" justifyContent="center">
      <div style={chartContainerStyle} className="home-bar-graph">
        <Doughnut data={data} options={options} />
      </div>
      <Box
        position="absolute"
        top="50%"
        left="50%"
        style={{
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
          color: '#555', // Adjust the color as needed
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor="#674bff"
          borderRadius="50%"
          height={innerHeight ? innerHeight : 52}
          width={innerWidth ? innerWidth : 52}
        >
          <img src={getIconPath(name)} alt="Goal Icon" style={{ display: 'block', width: '40%' }} />
        </Box>
      </Box>
    </Box>
  );
};

export default DoughnutRound;
