import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export interface leaveType {
  activityIds: any;
  message: string;
}

export interface leaveActivityType {
  data: string;
}

export interface leaveActivityState {
  activityLeave: leaveActivityType | null;
  loading: boolean;
  error: string | null;
  success: boolean | null;
}

export const initialState: leaveActivityState = {
  activityLeave: null,
  loading: false,
  error: null,
  success: false,
};

export const LeaveActivity = createAsyncThunk<any, leaveType, { rejectValue: string }>(
  '/api/activity-details/leave-activity',
  async leaveType => {
    try {
      const axiosResponse = await axios.post<any[]>('/api/activity-details/leave-activity', leaveType);
      return axiosResponse.data;
    } catch (error) {
      throw error.response?.data?.message || 'An error occurred';
    }
  }
);

export const leaveActivitySlice = createSlice({
  name: 'leaveActivityReducer',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(LeaveActivity.pending, state => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(LeaveActivity.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.activityLeave = action.payload;
        state.success = true;
      })
      .addCase(LeaveActivity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'An error occurred';
        state.success = false;
      });
  },
});

export default leaveActivitySlice.reducer;
