import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const NgoIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" style={{ fontSize: 'larger' }}>
    <g id="NGO" transform="translate(-774 -705)">
      <path
        id="Path_4819"
        data-name="Path 4819"
        d="M9.356,17.467H21.2v2.224h1.156v-3.38h-6.5V14H14.7v2.311H8.2v3.38H9.356Z"
        transform="translate(769.722 699.111)"
        fill="#676e7b"
      />
      <rect id="Rectangle_2" data-name="Rectangle 2" width="10" height="7" rx="2" transform="translate(774 720)" fill="#676e7b" />
      <rect id="Rectangle_3" data-name="Rectangle 3" width="10" height="7" rx="2" transform="translate(786 720)" fill="#676e7b" />
      <rect id="Rectangle_4" data-name="Rectangle 4" width="10" height="7" rx="2" transform="translate(780 705)" fill="#676e7b" />
    </g>
  </svg>,
  'NgoIcon'
);
export default NgoIcon;
