import React, { useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Fab, Typography, useTheme } from '@mui/material';
import './event-filter-tabs.scss';
import CreateActivityModal from './create-activity-modal.tsx/create-activity-modal';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import AuthorisationModal from 'app/components/common/authorization-modal/authorization-modal';
import SingleActivityCard from 'app/components/common/cards/activity-card/activity-card';
import { deleteActivityByIds, fetchNearMe } from 'app/shared/reducers/activity-home';
import PhoneVerification from 'app/components/ui/phone-verification/phone-verification';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import PetitionCard from 'app/components/common/cards/petition-card/petition-card';
import { deletePetitionById } from 'app/shared/reducers/petition-home';
import DateRangeIcon from '@mui/icons-material/DateRange';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {
  clearExistingActivities,
  clearSearchKeyword,
  clearSearhedActivities,
  fetchActivityDatas,
  fetchActivityUnauthorizedDatas,
  filterActivities,
  filterActivitiesUnauthorized,
  searchActivities,
  searchActivitiesCount,
  unAuthSearchActivities,
  updateEventType,
  updateFilterProperties,
  updateFilterProperty,
} from 'app/shared/reducers/activity-filter';
import NearmeGMapWithMarker from 'app/components/common/google-maps/static-g-map-nearMe';
import { getPhoneNumber } from 'app/shared/reducers/phone-verification';
import DatePicker from 'react-multi-date-picker';
import CalendarIcon from 'app/components/icons/calendar-icon';
import dayjs from 'dayjs';
import './date-picker.scss';
import Toolbar from 'react-multi-date-picker/plugins/toolbar';
import EventCalenderModal from 'app/components/common/modals/event-calender/event-calender';
import BlogCard from 'app/components/common/cards/blog-card/blog-card';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import Cookies from 'js-cookie';
import ActivitySensitiveCard from 'app/components/common/cards/offensive-card/activity-sensitive-card';
import { authenticate, toggleModalLogin } from 'app/shared/reducers/authentication';
import RadioDialog from 'app/components/common/alert-dialog/radio-dialog';
import NoResultsCalender from 'app/components/icons/no-results-calender';
import SingleActivityReccuringCard from 'app/components/common/cards/activity-recurring-card/activity-recurring-card';
import Lottie from 'lottie-react';
import elephantWalking from '../../../entities/constants/elephantWalking.json';
import { truncateString } from 'app/utils/common-utils/common-utils';
import Routes from 'app/entities/routes';
import AppRoutes from 'app/routes';
import { fetchBanned, getPostingLimit } from 'app/shared/reducers/profile';
import AmySaysCard from 'app/components/common/cards/amy-says-card/amy-says-card';
import { apiKeys } from 'app/utils/data/constants/api-keys';
import PaymentModal from 'app/components/ui/payment-modal/payment-modal';
import PaymentmodalFailed from 'app/components/ui/payment-modal/payment-modal-failed';

interface BasicTabProps {
  eventType?: string;
  toggleValue?: boolean;
  toggleMapValue?: boolean;
  toggleFeatureValue?: boolean;
  category?: any;
  clearSearchKeyWords?: React.Dispatch<React.SetStateAction<string>>;
  setIsSearching?: React.Dispatch<React.SetStateAction<boolean>>;
  setLocalSearchInput?: React.Dispatch<React.SetStateAction<string>>;
  localSearchInput?: string;
}
export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ pb: 3, pt: 2, px: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BasicTabs = ({
  toggleValue,
  toggleMapValue,
  toggleFeatureValue,
  category,
  clearSearchKeyWords,
  setIsSearching,
  setLocalSearchInput,
}: BasicTabProps) => {
  const [value, setValue] = useState(0);
  const [createActivityModal, setCreateActivityModal] = useState<boolean>(false);
  const [phoneVerificationModal, setPhoneVerificationModal] = useState<boolean>(false);
  const [phoneInputCompleted, setPhoneInputCompleted] = useState(false);
  const [otpVerificationCompleted, setOtpVerificationCompleted] = useState(false);
  const [allowedList, setAllowedList] = useState([]);
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [currentComponent, setCurrentComponent] = useState(' ');
  const [bookmarkSuccess, setBookmarkSuccess] = useState(false);
  const [likeSuccess, setLikemarkSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [latitudeNear, setLatitude] = useState<any>('');
  const [longitudeNear, setLongitude] = useState<any>('');
  const [phoneVerificationStatus, setPhoneVerificationStatus] = useState(true);
  const [startDate, setStartDate] = useState<dayjs.Dayjs | undefined>();
  const [endDate, setEndDate] = useState<dayjs.Dayjs | undefined>();
  const [categoryName, setCategoryName] = useState('');
  const [subcategoryName, setSubCategoryName] = useState('');
  const [selectedVolunteerOptions, setSelectedVolunteerOptions] = useState<any[]>([]);
  const [selectedDeleteOptions, setSelectedDeletedOptions] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState<number>(0);
  const [showOrgDialog, setShowOrgDialog] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedOrgId, setSelectedOrgId] = useState<number | null>(null);
  const [currentActivityId, setCurrentActivityId] = useState<number | null>(null);
  const [isVolunteerModalOpen, setIsVolunteerModalOpen] = useState(false);

  const verificationStatusLoading = useAppSelector(state => state.verificationSlice.loading);
  const activities = useAppSelector(state => state.filters.activities);
  const { filter, eventType } = useAppSelector(state => state.filters.RequestBody);
  const searchResults = useAppSelector(state => state.filters.searchResults);
  const errorResults = useAppSelector(state => state.filters.error);
  const filterLoading = useAppSelector(state => state.filters.loading);
  const searchCount = useAppSelector(state => state.filters.searchCount);
  const searchKeyword = useAppSelector(state => state.filters.searchKeyWord);
  const isSearching = useAppSelector(state => state.filters.loading);
  const phoneNumberDetails = useAppSelector(state => state.verificationSlice?.data);
  const userOwnedOrganizations = useAppSelector(state => state.organizationReducer.userOwnedOrganization);
  const mySelf = useAppSelector(state => state.filters.RequestBody.filter.mySelf);

  const localStorageAllCategories = JSON.parse(localStorage.getItem('fetchAllCategories') || '[]');
  const foundCategory = localStorageAllCategories?.find(category => category?.id === filter?.categoryId);

  const theme = useTheme();
  const fullScreenXXL = useMediaQuery(theme.breakpoints.down(2500));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1030));
  const marginRightVW = isSmallScreen ? 4 : (16 / window.innerWidth) * 100;
  const myCookieValue = Cookies.get('jhi-authenticationToken');
  const authenticationToken = myCookieValue;
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const dispatch = useAppDispatch();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const boxRef = useRef(null);
  const elementRef = useRef(null);
  const [rerenderAppRoutes, setRerenderAppRoutes] = useState(false);
  const myProfileId = useAppSelector(state => state.authentication.account.id);

  const searchParams1 = new URLSearchParams(location.search);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [paymentFailureOpen, setPaymentFailureOpen] = useState(false);
  const hasSuccessStatus = new URLSearchParams(location.search).get('status') === 'success';
  const hasSuccessFailure = new URLSearchParams(location.search).get('status') === 'failure';
  useEffect(() => {
    if (hasSuccessStatus) {
      setPaymentModalOpen(true);
    }

    if (hasSuccessFailure) {
      setPaymentFailureOpen(true);
    }
  }, [hasSuccessStatus, hasSuccessFailure]);
  const handlePaymentModalClose = () => {
    setPaymentModalOpen(false);
    const urlWithoutQuery = window.location.pathname;
    window.history.replaceState(null, '', urlWithoutQuery);
  };

  const handlePaymentFailureClose = () => {
    setPaymentFailureOpen(false);
    const urlWithoutQuery = window.location.pathname;
    window.history.replaceState(null, '', urlWithoutQuery);
  };

  useEffect(() => {
    if (myProfileId && isAuthenticated) dispatch(getPostingLimit(myProfileId));
  }, [dispatch, myProfileId, isAuthenticated]);

  useEffect(() => {
    if (foundCategory) {
      setCategoryName(foundCategory?.category);

      if (filter?.subCategoryId !== null) {
        const subCategory = foundCategory?.subcategories.find(item => item.id === filter?.subCategoryId);
        setSubCategoryName(subCategory?.label);
        if (filter?.petition) {
          setSubCategoryName('Petition');
        }
      }
    } else if (filter?.remoteEvents) {
      setCategoryName('Remote Events');
    } else if (filter?.featured) {
      setCategoryName('Featured');
    }
  }, [foundCategory, filter]);

  useEffect(() => {
    dispatch(clearExistingActivities());

    return () => {
      dispatch(clearSearhedActivities());
    };
  }, []);

  useEffect(() => {
    const element = elementRef.current;

    if (activities.length && element) {
      const timer = setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth' });
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [activities]);

  const fetchPhoneNumber = async () => {
    try {
      const response = await dispatch(getPhoneNumber());
      if (response.meta.requestStatus == 'fulfilled') {
        localStorage.setItem('getPhone', JSON.stringify(response.payload));
        setPhoneVerificationStatus(response.payload?.verified);
        // setRerenderAppRoutes(false);
      } else {
        toast.error('Unable to fetch verification Status', { id: 'phone-verification-status-error' });
      }
    } catch (error) {}
  };

  const handleSignButtonPageClick = () => {
    navigate('/calender');
  };
  const handleMapPageClick = () => {
    navigate('/map');
  };

  const handleModalClose = async () => {
    setOpen(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 0:
        dispatch(updateEventType('Latest'));
        break;
      case 1:
        dispatch(updateEventType('Upcoming'));

        break;
      case 2:
        dispatch(updateEventType('Ongoing'));
        break;
      case 3:
        dispatch(updateEventType('Completed'));
        break;
      default:
        dispatch(updateEventType('Latest'));
        break;
    }
    setValue(newValue);
  };

  useEffect(() => {
    if (eventType === 'Ongoing') {
      setValue(2);
    } else if (eventType === 'Completed') {
      setValue(3);
    } else if (eventType === 'Upcoming') {
      setValue(1);
    } else if (eventType === 'Latest') {
      setValue(0);
    } else {
      setValue(0);
    }
  }, [eventType]);

  const handleCreateActivity = async () => {
    setRerenderAppRoutes(true);
    if (isAuthenticated) {
      const myDetailsAccount = localStorage.getItem('MyDetailsAccount');
      const userId = myDetailsAccount ? JSON.parse(myDetailsAccount)?.id : null;
      const res = await dispatch(fetchBanned({ userId: userId }));
      if (res?.payload) {
        toast.error(<>Action Restricted: You cannot perform this action as you are banned by the admin.</>);
        return;
      }
      if (phoneVerificationStatus) {
        setCreateActivityModal(true);
        setRerenderAppRoutes(true);
      } else if (!phoneVerificationStatus && !verificationStatusLoading) {
        setPhoneVerificationModal(true);
        // const phoneDataString = localStorage.getItem('getPhone');
        // if (phoneDataString) {
        //   const phoneData = JSON.parse(phoneDataString);
        //   phoneData.verified = true;
        //   const updatedPhoneDataString = JSON.stringify(phoneData);
        //   localStorage.setItem('getPhone', updatedPhoneDataString);
        //   setPhoneVerificationStatus(true);
        // }
      }
    } else {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
    }
  };

  const handleClose = () => {
    setCreateActivityModal(false);
    setPhoneInputCompleted(false);
    setRerenderAppRoutes(false);
  };

  const handleAuthModalClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void = () => {
    setAuthModalOpen(false);
  };

  const handlePhoneVerificationClose = () => {
    setPhoneVerificationModal(false);
    setRerenderAppRoutes(false);
    if (otpVerificationCompleted) {
      setCreateActivityModal(true);
      setRerenderAppRoutes(true);
    } else {
      setPhoneInputCompleted(false);
    }
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get('status');
  const action = searchParams.get('action');

  useEffect(() => {
    if (!isAuthenticated)
      setTimeout(() => {
        if (action === 'login') {
          setCurrentComponent('login');
          dispatch(toggleModalLogin());
          setAuthModalOpen(true);
        }
      }, 200);
  }, [action]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;

      return;
    }

    const updatedProperties = {
      nearMe: null,
      country: null,
      state: null,
      city: null,
      organizationId: null,
      categoryId: null,
      subCategoryId: null,
      remoteEvents: false,
      petition: false,
      mySelf: false,
    };

    dispatch(clearExistingActivities());
    dispatch(clearSearhedActivities());

    return () => {
      dispatch(updateEventType('Latest'));
    };
  }, [status]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchPhoneNumber();
    }
  }, [otpVerificationCompleted, isAuthenticated]);

  useEffect(() => {
    if (localStorage.getItem('gmailLogin')) {
      callFilterActivitiesAPI();
      localStorage.removeItem('gmailLogin');
    }
    if (isFirstRender.current) {
      isFirstRender.current = false;

      return;
    }

    if (page > 0) setPage(0);
    if (!hasMore) setHasMore(true);

    if (page === 0) {
      console.log('calling romal');
      callFilterActivitiesAPI();
    }
  }, [isAuthenticated, filter, eventType, localStorage.getItem('gmailLogin')]);

  console.log(searchKeyword, 'searchKeyword');
  useEffect(() => {
    setAuthModalOpen(false);

    if (hasMore && !searchKeyword) {
      callFilterActivitiesAPI();
    } else if (hasMore && searchKeyword) {
      if (searchResults.length) {
        if (isAuthenticated) {
          dispatch(searchActivities({ keyword: searchKeyword, startDate: startDate, endDate: endDate, page: page })).then(res => {
            if (res.payload.length === 0) {
              setHasMore(false);
            }
          });
        } else {
          dispatch(unAuthSearchActivities({ keyword: searchKeyword, startDate: startDate, endDate: endDate, page: page })).then(
            response => {
              if (response.payload.length === 0) {
                setHasMore(false);
              }
            }
          );
        }
      }
    }
  }, [page, hasMore]);

  useEffect(() => {
    const handleLocationSuccess = position => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      localStorage.setItem('currentLatitude', JSON.stringify(position.coords.latitude));
      localStorage.setItem('currentLongitude', JSON.stringify(position.coords.longitude));
      setLatitude(latitude);
      setLongitude(longitude);
      localStorage.setItem('latitude', latitude.toString());
      localStorage.setItem('longitude', longitude.toString());

      const payload = { latitude, longitude };
      if (isAuthenticated) {
        dispatch(fetchActivityDatas(payload));
      } else {
        dispatch(fetchActivityUnauthorizedDatas(payload));
      }

      const body = { latitude, longitude };
      const updatedProperties = {
        nearMe: body,
        country: null,
        state: null,
        city: null,
        organizationId: null,
        remoteEvents: false,
        petition: false,
        mySelf: false,
        categoryId: null,
        subCategoryId: null,
      };

      dispatch(updateFilterProperties(updatedProperties));
    };

    const handleLocationError = error => {
      let errorMessage =
        'Location Service message: Geo-location based services will no longer be accessible to you. Please enable location services if you wish to use these features.';
      if (error.code === error.PERMISSION_DENIED) {
        errorMessage =
          'You have denied access to location services. Please enable location services in your browser settings to use geo-location based features.';
      } else if (error.code === error.POSITION_UNAVAILABLE) {
        errorMessage = 'Location information is unavailable. Please check your device settings and try again.';
      } else if (error.code === error.TIMEOUT) {
        errorMessage = 'The request to get your location timed out. Please try again.';
      } else if (error.code === error.UNKNOWN_ERROR) {
        errorMessage = 'An unknown error occurred while trying to access your location.';
      }
      alert(errorMessage);
    };

    if (toggleMapValue) {
      if ('geolocation' in navigator) {
        console.log('location get');
        navigator.geolocation.getCurrentPosition(handleLocationSuccess, handleLocationError);
      } else {
        console.log('location denied');
        alert(
          'Location Service message: Geo-location based services will no longer be accessible to you. Please enable location services if you wish to use these features.'
        );
      }
    } else {
      console.log('location denied');
      dispatch(updateFilterProperty({ property: 'nearMe', value: null }));
    }
  }, [toggleMapValue, isAuthenticated, dispatch]);

  // useEffect(() => {
  //   if (toggleMapValue) {
  //     if ('geolocation' in navigator) {
  //       navigator.geolocation.getCurrentPosition(
  //         function (position) {
  //           const latitude = position.coords.latitude;
  //           const longitude = position.coords.longitude;

  //           setLatitude(latitude);
  //           setLongitude(longitude);
  //           localStorage.setItem('latitude', latitude.toString());
  //           localStorage.setItem('longitude', longitude.toString());
  //           // filter activities nearMe
  //           const payload = {
  //             latitude,
  //             longitude,
  //           };
  //           if (isAuthenticated) {
  //             dispatch(fetchActivityDatas(payload));
  //           } else {
  //             dispatch(fetchActivityUnauthorizedDatas(payload));
  //           }

  //           const body = {
  //             latitude: latitude,
  //             longitude: longitude,
  //           };
  //           const updatedProperties = {
  //             nearMe: body,
  //             country: null,
  //             state: null,
  //             city: null,
  //             organizationId: null,
  //             remoteEvents: false,
  //             petition: false,
  //             mySelf: false,
  //             categoryId: null,
  //             subCategoryId: null,
  //           };

  //           dispatch(updateFilterProperties(updatedProperties));
  //         },
  //         function (error) {
  //           if (error.code === error.PERMISSION_DENIED) {
  //             alert(
  //               'Location Service message:Geo-location based services will no longer be accessible to you. Please enable location services if you wish to use these features.'
  //             );
  //           } else {
  //             alert(
  //               'Location Service message:Geo-location based services will no longer be accessible to you. Please enable location services if you wish to use these features.'
  //             );
  //           }
  //         }
  //       );
  //     } else {
  //       alert(
  //         'Location Service message:Geo-location based services will no longer be accessible to you. Please enable location services if you wish to use these features.'
  //       );
  //     }
  //   } else {
  //     dispatch(updateFilterProperty({ property: 'nearMe', value: null }));
  //   }
  // }, [toggleMapValue]);

  useEffect(() => {
    if (toggleFeatureValue) {
      const updatedProperties = {
        nearMe: null,
        country: null,
        state: null,
        city: null,
        organizationId: null,
        remoteEvents: false,
        petition: false,
        mySelf: false,
        featured: toggleFeatureValue,
      };

      dispatch(updateFilterProperties(updatedProperties));
    } else {
      dispatch(updateFilterProperty({ property: 'featured', value: false }));
    }
  }, [toggleFeatureValue]);

  // useEffect(() => {
  //   if ('geolocation' in navigator) {
  //     navigator.geolocation.getCurrentPosition(
  //       function (position) {
  //         const latitude = position.coords.latitude;
  //         const longitude = position.coords.longitude;
  //         localStorage.setItem('currentLatitude', JSON.stringify(position.coords.latitude));
  //         localStorage.setItem('currentLongitude', JSON.stringify(position.coords.longitude));

  //       },
  //       function (error) {
  //         if (error.code === error.PERMISSION_DENIED) {
  //           alert('Location access denied. Please enable location services.');
  //         } else {
  //           alert('Error getting location: ' + error.message);
  //         }
  //       }
  //     );
  //   } else {
  //     alert('Geolocation is not supported in this browser.');
  //   }
  // }, []);

  const callFilterActivitiesAPI = async () => {
    if (!hasMore) return;

    try {
      const action = isAuthenticated ? filterActivities : filterActivitiesUnauthorized;
      console.log('check payload', filter);
      const response = await dispatch(action({ filter, eventType, page }));

      setHasMore(response?.payload?.length > 0);
    } catch (error) {
      console.log(error);
    }
  };

  const onButtonClick = data => {
    setAllowedList(state => [...state, data]);
  };

  function setStartOfDay(dateString) {
    const date = dayjs(dateString);
    if (!date.isValid()) {
      console.error('Invalid date provided:', dateString);
      return null; // Return null or a sensible default
    }
    const startOfDay = date.startOf('day');
    return startOfDay.format('YYYY-MM-DD[T]00:00:00[Z]');
  }

  function setEndOfDay(dateString) {
    const date = dayjs(dateString);
    if (!date.isValid()) {
      console.error('Invalid date provided:', dateString);
      return null; // Return null or a sensible default
    }
    const endOfDay = date.endOf('day');
    return endOfDay.format('YYYY-MM-DD[T]23:58:00[Z]');
  }

  const dispatchDateRange = () => {
    const dayjsStart = dayjs(startDate);
    const dayjsEnd = dayjs(endDate);
    if (dayjsStart.isValid() && dayjsEnd.isValid()) {
      console.log('i am timing12', dayjs(startDate), dayjs(endDate));
      if (isAuthenticated) {
        const adjustedStartDate = setStartOfDay(startDate);
        const adjustedEndDate = setEndOfDay(endDate);
        if (adjustedStartDate && adjustedEndDate) {
          // Make sure both dates are valid before dispatching
          dispatch(
            searchActivities({
              keyword: searchKeyword,
              startDate: adjustedStartDate,
              endDate: adjustedEndDate,
              page: page,
            })
          );
          dispatch(
            searchActivitiesCount({
              keyword: searchKeyword,
              startDate: adjustedStartDate,
              endDate: adjustedEndDate,
            })
          );
        }
      } else {
        const adjustedStartDate = setStartOfDay(startDate);
        const adjustedEndDate = setEndOfDay(endDate);
        if (adjustedStartDate && adjustedEndDate) {
          // Make sure both dates are valid before dispatching
          dispatch(
            unAuthSearchActivities({
              keyword: searchKeyword,
              startDate: adjustedStartDate,
              endDate: adjustedEndDate,
              page: page,
            })
          );
        }
      }
    } else if (dayjsStart.isValid()) {
      console.log('i am timing');
      if (isAuthenticated) {
        const adjustedStartDate = setStartOfDay(startDate);
        if (adjustedStartDate) {
          // Make sure start date is valid before dispatching
          dispatch(
            searchActivities({
              keyword: searchKeyword,
              startDate: adjustedStartDate,
              endDate: '',
              page: page,
            })
          );
          dispatch(
            searchActivitiesCount({
              keyword: searchKeyword,
              startDate: adjustedStartDate,
              endDate: '',
            })
          );
        }
      } else {
        const adjustedStartDate = setStartOfDay(startDate);
        if (adjustedStartDate) {
          // Make sure start date is valid before dispatching
          dispatch(
            unAuthSearchActivities({
              keyword: searchKeyword,
              startDate: adjustedStartDate,
              endDate: '',
              page: page,
            })
          );
        }
      }
    }
  };

  const handleBookmarkSuccess = () => {
    setBookmarkSuccess(s => !s);
  };

  const handleLikeSuccess = () => {
    setLikemarkSuccess(s => !s);
  };

  const customTheme = createTheme({
    ...theme,
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            minWidth: isSmallScreen ? 'auto' : '',
            fontSize: isSmallScreen ? '0.675rem' : '0.975rem',
            marginRight: isSmallScreen ? `${marginRightVW}vw` : '',
            padding: isSmallScreen ? '0px' : 'auto',
            '&.Mui-selected': {
              color: '#674BFF',
            },
          },
        },
      },
    },
  });

  const handleDeleteClick = async (id: string) => {
    try {
      const response = await dispatch(deleteActivityByIds({ activityIds: [id], organizationId: 0, message: '' }));
      if (response) {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteRecurringClick = async (id: string) => {
    try {
      const response = await dispatch(deleteActivityByIds({ activityIds: id, organizationId: 0, message: '' }));
      if (response) {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeletePetition = async (petitionId: string) => {
    try {
      const response = await dispatch(deletePetitionById({ petitionId }));
      if (response) {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const iconStyle = {
    color: '#674BFF',
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (phoneNumberDetails?.verified) {
      setPhoneVerificationStatus(true);
      // setRerenderAppRoutes(true);
    }
  }, [phoneNumberDetails?.verified, isAuthenticated]);

  const handleOrgsDialog = value => {
    setShowOrgDialog(value);
  };

  const getCurrentActivity = id => {
    setCurrentActivityId(id);
  };
  const clearCurrentActivity = () => {
    setCurrentActivityId(null);
  };

  const handleOptionChange = event => {
    const selectedValue = event.target.value;

    if (parseInt(selectedValue, 10) === 1) {
      setSelectedOrgId(1);
      setSelectedOption('My Self');
    } else {
      const selectedOrganization = userOwnedOrganizations.find(organization => organization.id === parseInt(selectedValue, 10));

      if (selectedOrganization) {
        setSelectedOption(selectedOrganization.label);
        setSelectedOrgId(selectedOrganization.id);
      }
    }
  };

  const handleInstructionsModal = value => {
    setIsVolunteerModalOpen(value);
  };

  const handleCancel = () => {
    setShowOrgDialog(false);
    setSelectedOrgId(null);
    setSelectedOption('');
  };

  const changeOrgId = value => {
    setSelectedOrgId(value);
  };

  useEffect(() => {
    if (category) {
      console.log('changes occurs', category);

      setPage(0);
    }
  }, [category]);

  useEffect(() => {
    if (localStorage.getItem('scrollPosition')) {
      window.scrollTo(0, JSON.parse(localStorage.getItem('scrollPosition')));
    }
  }, [localStorage.getItem('scrollPosition')]);

  function debounce(func, wait) {
    let timeout;

    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }
  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    const box = boxRef.current;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = box;
      const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;

      setScrollPosition(scrollTop);
      localStorage.setItem('scrollPosition', JSON.stringify(scrollTop));
      if (scrollPercentage >= 99 && hasMore && !filterLoading) {
        setPage(prev => prev + 1);
      }
    };

    const debouncedHandleScroll = debounce(handleScroll, 100); // Debounce time of 100ms

    box.addEventListener('scroll', debouncedHandleScroll);
    return () => box.removeEventListener('scroll', debouncedHandleScroll);
  }, [hasMore, searchKeyword, filterLoading]);

  //   useEffect(() => {
  //   const element = document.getElementById(prevItemId);
  //   console.log(element, 'element');
  //   if (element) {
  //     element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  //   }
  // }, [prevItemId, activities]);
  return (
    <Box sx={{ height: '100vh' }}>
      {/* {rerenderAppRoutes && <AppRoutes />} */}
      <RadioDialog
        selectedOrgId={selectedOrgId}
        open={showOrgDialog}
        handleShow={handleOrgsDialog}
        handleOptionChange={handleOptionChange}
        handleCancel={handleCancel}
        handleInstructionsModal={handleInstructionsModal}
      />
      {!searchResults?.length && !searchKeyword && (
        <div className="fixed-filter" style={{ zIndex: '1000' }}>
          <Box
            className="filter-btn"
            sx={{
              borderColor: 'divider',
              backgroundColor: 'secondary.dark',
              borderRadius: '15px',
              paddingLeft: '15px',

              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <ThemeProvider theme={customTheme}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab className="mob-px" label="Latest" {...a11yProps(0)} sx={{ textTransform: 'capitalize' }} />
                <Tab className="mob-px" label="Upcoming" {...a11yProps(1)} sx={{ textTransform: 'capitalize' }} />
                <Tab className="mob-px" label="Ongoing" {...a11yProps(2)} sx={{ textTransform: 'capitalize' }} />
                <Tab className="mob-px" label="Completed" {...a11yProps(3)} sx={{ textTransform: 'capitalize' }} />
              </Tabs>
              {mySelf && (
                <div
                  className={`mt-2 ${toggleValue ? 'margin-end' : ''}`}
                  style={{ cursor: 'pointer' }}
                  onClick={handleSignButtonPageClick}
                >
                  {' '}
                  <DateRangeIcon style={iconStyle} />
                </div>
              )}
              {toggleMapValue && (
                <div className={`mt-2 ${toggleMapValue ? 'margin-end' : ''}`} style={{ cursor: 'pointer' }} onClick={handleMapPageClick}>
                  {' '}
                  <LocationOnIcon style={iconStyle} />
                </div>
              )}
            </ThemeProvider>

            <Button
              className="filter-btn-mob"
              sx={{
                marginTop: ' 6px',
                textTransform: 'capitalize',
                backgroundColor: 'primary.light',
                marginRight: '10px',
                color: 'common.white',
                '&:hover': {
                  color: 'common.white',
                  backgroundColor: 'primary.light',
                  textTransform: 'capitalize',
                  boxShadow: 'none',
                },
              }}
              onClick={handleCreateActivity}
              variant="contained"
              size="small"
            >
              Create Activity
            </Button>
          </Box>
        </div>
      )}
      {searchResults?.length > 0 && searchKeyword && (
        <Stack direction={{ xs: 'column', md: 'row' }} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} alignItems={{ xs: 'center' }}>
            <Typography color="secondary.light">{`${searchCount} Search results found for  `}</Typography>
            <Typography color={'secondary.main'} fontWeight={600}>
              "{`${truncateString(searchKeyword, 35)}`}"
            </Typography>
          </Stack>
          <Stack alignItems={{ xs: 'flex-end' }} direction="row" spacing={1}>
            <Typography color={'secondary.light'} sx={{ fontSize: '14px' }}>
              Choose Date
            </Typography>
            <DatePicker
              className={theme.palette.mode === 'dark' ? 'bg-dark' : ''}
              plugins={[
                <Toolbar
                  position="bottom"
                  sort={['roday', 'deselect', 'close']}
                  names={{
                    today: 'select today',
                    deselect: 'Clear',
                    close: 'Select',
                  }}
                />,
              ]}
              showOtherDays
              onClose={() => {
                setPage(0);
                dispatchDateRange();
              }}
              calendarPosition="bottom-center"
              inputClass="custom-input"
              render={(value, openCalendar) => {
                return <CalendarIcon sx={{ fontSize: '14px', cursor: 'pointer' }} onClick={openCalendar} />;
              }}
              onChange={dateObject => {
                const startDate = dayjs(new Date(dateObject[0]));
                if (startDate) {
                  setStartDate(startDate);
                }
                const endDate = dayjs(new Date(dateObject[1]));
                if (endDate) {
                  setEndDate(endDate);
                }
              }}
              range
              rangeHover
            />
          </Stack>
        </Stack>
      )}
      <Box
        ref={boxRef}
        sx={{
          width: isSmallerScreen ? '100%' : '100%',
          marginTop: '19px',
          marginBottom: '19px',
          overflowY: 'scroll',
          height: '79vh',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <PaymentModal open={paymentModalOpen} onClose={handlePaymentModalClose} />
        <PaymentmodalFailed open={paymentFailureOpen} onClose={handlePaymentFailureClose} />
        {/* {!filterLoading ? ( */}
        {searchResults?.length && searchKeyword?.length > 0 ? (
          <Stack gap={4}>
            {searchResults?.map((activity: any) =>
              activity.reportedCount < 3 ? (
                activity?.eventType === 'Activity' && !activity?.blocked ? (
                  <>
                    {activity?.recurring && (
                      <SingleActivityReccuringCard
                        selectedVolunteerOptions={selectedVolunteerOptions}
                        setSelectedVolunteerOptions={setSelectedVolunteerOptions}
                        activity={activity}
                        onBookmarkSuccess={handleBookmarkSuccess}
                        onLikeSuccess={handleLikeSuccess}
                        handleOrgsDialog={handleOrgsDialog}
                        selectedOrgId={selectedOrgId}
                        selectedOption={selectedOption}
                        handleInstructionsModal={handleInstructionsModal}
                        isVolunteerModalOpen={isVolunteerModalOpen}
                        handleCancel={handleCancel}
                        changeOrgId={changeOrgId}
                        selectedDeleteOptions={selectedDeleteOptions}
                        setSelectedDeletedOptions={setSelectedDeletedOptions}
                        handleDeleteRecurringClick={handleDeleteRecurringClick}
                        eventGallery={[]}
                        handleDeleteClick={function (id: string): void {
                          throw new Error('Function not implemented.');
                        }}
                      />
                    )}
                    {!activity?.recurring && (
                      <SingleActivityCard
                        changeOrgId={changeOrgId}
                        handleCancel={handleCancel}
                        isVolunteerModalOpen={isVolunteerModalOpen}
                        handleInstructionsModal={handleInstructionsModal}
                        currentActivityId={currentActivityId}
                        getCurrentActivity={getCurrentActivity}
                        clearCurrentActivity={clearCurrentActivity}
                        showOrgDialog={showOrgDialog}
                        selectedOrgId={selectedOrgId}
                        selectedOption={selectedOption}
                        handleOrgsDialog={handleOrgsDialog}
                        onLikeSuccess={handleLikeSuccess}
                        onBookmarkSuccess={handleBookmarkSuccess}
                        activity={activity}
                        key={activity.id}
                        eventGallery={activity.eventGallery}
                        handleDeleteClick={handleDeleteClick}
                      />
                    )}
                  </>
                ) : activity.eventType === 'Blog' && !activity?.blocked ? (
                  <BlogCard blog={activity} key={activity.id} />
                ) : activity?.eventType === 'Petition' && !activity?.blocked ? (
                  <PetitionCard key={activity.id} petition={activity} handleDeletePetition={handleDeletePetition} />
                ) : null
              ) : (
                // the sensitive card  goes here
                <ActivitySensitiveCard key={activity?.id} handleButtonClick={() => onButtonClick(activity?.id)} />
              )
            )}
          </Stack>
        ) : searchResults.length === 0 && searchKeyword && !isSearching ? (
          <Stack direction="column" gap={2} height={'100%'}>
            <Stack alignItems={'center'} justifyContent={'flex-end'} direction="row" spacing={1}>
              <Typography color={'secondary.light'} sx={{ fontSize: '14px' }}>
                Choose Date
              </Typography>
              <DatePicker
                className={theme.palette.mode === 'dark' ? 'bg-dark' : ''}
                plugins={[
                  <Toolbar
                    position="bottom"
                    sort={['roday', 'deselect', 'close']}
                    names={{
                      today: 'select today',
                      deselect: 'Clear',
                      close: 'Select',
                    }}
                  />,
                ]}
                showOtherDays
                onClose={() => {
                  setPage(0);
                  dispatchDateRange();
                }}
                calendarPosition="bottom-center"
                inputClass="custom-input"
                render={(value, openCalendar) => {
                  return <CalendarIcon sx={{ fontSize: '14px', cursor: 'pointer' }} onClick={openCalendar} />;
                }}
                onChange={dateObject => {
                  if (dateObject) {
                    const startDate = dayjs(new Date(dateObject[0]));
                    if (startDate) {
                      setStartDate(startDate);
                    }
                    const endDate = dayjs(new Date(dateObject[1]));
                    if (endDate) {
                      setEndDate(endDate);
                    }
                  }
                }}
                range
                rangeHover
              />
            </Stack>
            <Stack
              sx={{ backgroundColor: 'secondary.dark', textAlign: 'center', height: '100%', borderRadius: '16px', pt: '5%' }}
              direction="column"
              gap={2}
              alignItems={'center'}
              justifyContent={'flex-start'}
            >
              <Stack width="100%" alignItems="center" direction="row" justifyContent={'flex-end'} mr={1}>
                <Button
                  sx={{
                    backgroundColor: 'primary.light',
                    marginRight: '10px',
                    color: 'common.white',
                    '&:hover': {
                      color: 'common.white',
                      backgroundColor: 'primary.light',
                      boxShadow: 'none',
                    },
                  }}
                  variant="contained"
                  onClick={() => {
                    dispatch(clearSearchKeyword());
                    dispatch(clearSearhedActivities());
                    clearSearchKeyWords('');
                    setIsSearching(false);
                    setLocalSearchInput('');
                  }}
                >
                  Clear search
                </Button>
              </Stack>
              <img src="content/images/no-results.png" alt="reach amy logo" />
              <Typography fontWeight={600}>Oops, sorry!</Typography>
              <Typography fontSize={14} color={'#7F8286'} px={{ xs: 2, lg: 15 }}>
                There doesn&rsquo;t seem to be any data matching your search request for &lsquo;
                <span style={{ fontWeight: 600 }}>{searchKeyword}</span>&rsquo; right now.
              </Typography>
            </Stack>
          </Stack>
        ) : (
          <>
            <CustomTabPanel value={value} index={0}>
              <Stack gap={4}>
                {eventType === 'Latest' &&
                  activities?.map((activity: any) =>
                    activity?.reportedCount < 3 || allowedList?.includes(activity.id) ? (
                      activity?.eventType === 'Activity' && !activity?.blocked ? (
                        <>
                          {activity?.recurring && (
                            <SingleActivityReccuringCard
                              selectedVolunteerOptions={selectedVolunteerOptions}
                              setSelectedVolunteerOptions={setSelectedVolunteerOptions}
                              activity={activity}
                              onBookmarkSuccess={handleBookmarkSuccess}
                              onLikeSuccess={handleLikeSuccess}
                              handleOrgsDialog={handleOrgsDialog}
                              selectedOrgId={selectedOrgId}
                              selectedOption={selectedOption}
                              handleInstructionsModal={handleInstructionsModal}
                              isVolunteerModalOpen={isVolunteerModalOpen}
                              handleCancel={handleCancel}
                              changeOrgId={changeOrgId}
                              selectedDeleteOptions={selectedDeleteOptions}
                              setSelectedDeletedOptions={setSelectedDeletedOptions}
                              handleDeleteRecurringClick={handleDeleteRecurringClick}
                              eventGallery={[]}
                              handleDeleteClick={function (id: string): void {
                                throw new Error('Function not implemented.');
                              }}
                            />
                          )}
                          {!activity?.recurring && (
                            <SingleActivityCard
                              changeOrgId={changeOrgId}
                              handleCancel={handleCancel}
                              isVolunteerModalOpen={isVolunteerModalOpen}
                              handleInstructionsModal={handleInstructionsModal}
                              currentActivityId={currentActivityId}
                              getCurrentActivity={getCurrentActivity}
                              clearCurrentActivity={clearCurrentActivity}
                              showOrgDialog={showOrgDialog}
                              selectedOrgId={selectedOrgId}
                              selectedOption={selectedOption}
                              handleOrgsDialog={handleOrgsDialog}
                              onLikeSuccess={handleLikeSuccess}
                              onBookmarkSuccess={handleBookmarkSuccess}
                              activity={activity}
                              key={activity.id}
                              eventGallery={activity.eventGallery}
                              handleDeleteClick={handleDeleteClick}
                            />
                          )}
                        </>
                      ) : activity?.eventType === 'Blog' && !activity?.blocked ? (
                        <BlogCard blog={activity} key={activity.id} />
                      ) : activity?.eventType === 'AMYS_MESSAGE_CARD' ? (
                        <AmySaysCard amySays={activity} key={activity.id} />
                      ) : activity?.eventType === 'Petition' && !activity?.blocked ? (
                        <PetitionCard key={activity.id} petition={activity} handleDeletePetition={handleDeletePetition} />
                      ) : null
                    ) : (
                      <ActivitySensitiveCard key={activity?.id} handleButtonClick={() => onButtonClick(activity?.id)} />
                    )
                  )}

                {eventType === 'Latest' && filterLoading && (
                  <>
                    <div
                      className="d-flex justify-content-center align-content-center align-items-center"
                      style={{ height: '400px', width: '100%' }}
                    >
                      <Lottie animationData={elephantWalking} loop={true} style={{ height: '200px', width: '200px' }} />
                    </div>
                  </>
                )}

                {eventType === 'Latest' && activities?.length === 0 && !filterLoading && (
                  <div className="col-12 d-flex justify-content-start flex-column align-items-center p-5 ">
                    {
                      <>
                        <NoResultsCalender sx={{ height: '86px', width: '100px' }} />
                        <div className="mt-3">
                          <p style={{ color: '#787b7f', font: 'normal normal 600 24px/37px Manrope' }}>Oh no!</p>
                        </div>
                        <div className="mt-1d-inline text-center">
                          {categoryName !== 'Remote Events' ? (
                            <>
                              <span style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>
                                There are no events in this {filter.subCategoryId ? 'sub category' : 'category'}
                                {` `}
                              </span>
                              <span style={{ color: '#787b7f', font: 'normal normal 600 14px/20px Manrope' }}>
                                {filter.subCategoryId ? subcategoryName : categoryName}
                              </span>{' '}
                              <span style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>
                                right now. But explore beyond for diverse and exciting content.
                              </span>
                            </>
                          ) : (
                            <>
                              <span style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>
                                There are no events in
                              </span>
                              <span style={{ color: '#787b7f', font: 'normal normal 600 14px/20px Manrope' }}>
                                &nbsp;{categoryName}&nbsp;
                              </span>
                              <span style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>
                                right now. But explore beyond for diverse and exciting content.
                              </span>
                            </>
                          )}
                        </div>
                      </>
                    }
                    {/* {localStoragePayload?.categoryId != null &&
                      (localStoragePayload?.subCategoryId !== null || localStoragePayload?.petition !== null) && (
                        <>
                          <NoResultsCalender sx={{ height: '86px', width: '100px' }} />
                          <div className="mt-3">
                            <p style={{ color: '#787b7f', font: 'normal normal 600 24px/37px Manrope' }}>Oh no!</p>
                          </div>
                          <div className="mt-1d-inline text-center">
                            <span style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>
                              There are no events in this subcategory
                            </span>
                            <span style={{ color: '#787b7f', font: 'normal normal 600 14px/20px Manrope' }}>
                              &nbsp;{subcategoryName}&nbsp;
                            </span>{' '}
                            <span style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>
                              {' '}
                              right now. But explore beyond for diverse and exciting content.
                            </span>
                          </div>
                        </>
                      )} */}
                  </div>
                )}
              </Stack>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Stack gap={4}>
                {eventType === 'Upcoming' &&
                  activities?.map((activity: any) =>
                    activity?.reportedCount < 3 || allowedList?.includes(activity.id) ? (
                      activity?.eventType === 'Activity' && !activity?.blocked ? (
                        <SingleActivityCard
                          changeOrgId={changeOrgId}
                          handleCancel={handleCancel}
                          isVolunteerModalOpen={isVolunteerModalOpen}
                          handleInstructionsModal={handleInstructionsModal}
                          currentActivityId={currentActivityId}
                          getCurrentActivity={getCurrentActivity}
                          clearCurrentActivity={clearCurrentActivity}
                          showOrgDialog={showOrgDialog}
                          selectedOrgId={selectedOrgId}
                          selectedOption={selectedOption}
                          handleOrgsDialog={handleOrgsDialog}
                          onLikeSuccess={handleLikeSuccess}
                          onBookmarkSuccess={handleBookmarkSuccess}
                          activity={activity}
                          key={activity.id}
                          eventGallery={activity.eventGallery}
                          handleDeleteClick={handleDeleteClick}
                        />
                      ) : activity?.eventType === 'Blog' && !activity?.blocked ? (
                        <BlogCard blog={activity} key={activity.id} />
                      ) : activity?.eventType === 'AMYS_MESSAGE_CARD' ? (
                        <AmySaysCard amySays={activity} key={activity.id} />
                      ) : activity?.eventType === 'Petition' && !activity?.blocked ? (
                        <PetitionCard key={activity.id} petition={activity} handleDeletePetition={handleDeletePetition} />
                      ) : null
                    ) : (
                      <ActivitySensitiveCard key={activity?.id} handleButtonClick={() => onButtonClick(activity?.id)} />
                    )
                  )}

                {eventType === 'Upcoming' && filterLoading && (
                  <>
                    <div
                      className="d-flex justify-content-center align-content-center align-items-center"
                      style={{ height: '400px', width: '100%' }}
                    >
                      <Lottie animationData={elephantWalking} loop={true} style={{ height: '200px', width: '200px' }} />
                    </div>
                  </>
                )}

                {eventType === 'Upcoming' && activities?.length === 0 && !filterLoading && (
                  <div className="col-12 d-flex justify-content-start flex-column align-items-center p-5 ">
                    {
                      <>
                        <NoResultsCalender sx={{ height: '86px', width: '100px' }} />
                        <div className="mt-3">
                          <p style={{ color: '#787b7f', font: 'normal normal 600 24px/37px Manrope' }}>Oh no!</p>
                        </div>
                        <div className="mt-1d-inline text-center">
                          {categoryName !== 'Remote Events' ? (
                            <>
                              <span style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>
                                There are no events in this {filter.subCategoryId ? 'sub category' : 'category'}
                                {` `}
                              </span>
                              <span style={{ color: '#787b7f', font: 'normal normal 600 14px/20px Manrope' }}>
                                {filter.subCategoryId ? subcategoryName : categoryName}
                              </span>{' '}
                              <span style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>
                                right now. But explore beyond for diverse and exciting content.
                              </span>
                            </>
                          ) : (
                            <>
                              <span style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>
                                There are no events in
                              </span>
                              <span style={{ color: '#787b7f', font: 'normal normal 600 14px/20px Manrope' }}>
                                &nbsp;{categoryName}&nbsp;
                              </span>
                              <span style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>
                                right now. But explore beyond for diverse and exciting content.
                              </span>
                            </>
                          )}
                        </div>
                      </>
                    }
                    {/* {localStoragePayload?.categoryId != null &&
                      (localStoragePayload?.subCategoryId !== null || localStoragePayload?.petition !== null) && (
                        <>
                          <NoResultsCalender sx={{ height: '86px', width: '100px' }} />
                          <div className="mt-3">
                            <p style={{ color: '#787b7f', font: 'normal normal 600 24px/37px Manrope' }}>Oh no!</p>
                          </div>
                          <div className="mt-1d-inline text-center">
                            <span style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>
                              There are no events in this subcategory
                            </span>
                            <span style={{ color: '#787b7f', font: 'normal normal 600 14px/20px Manrope' }}>
                              &nbsp;{subcategoryName}&nbsp;
                            </span>{' '}
                            <span style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>
                              {' '}
                              right now. But explore beyond for diverse and exciting content.
                            </span>
                          </div>
                        </>
                      )} */}
                  </div>
                )}
              </Stack>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Stack gap={4}>
                {eventType === 'Ongoing' &&
                  activities?.map((activity: any) =>
                    activity?.reportedCount < 3 || allowedList?.includes(activity.id) ? (
                      activity?.eventType === 'Activity' && !activity?.blocked ? (
                        <SingleActivityCard
                          changeOrgId={changeOrgId}
                          handleCancel={handleCancel}
                          isVolunteerModalOpen={isVolunteerModalOpen}
                          handleInstructionsModal={handleInstructionsModal}
                          currentActivityId={currentActivityId}
                          getCurrentActivity={getCurrentActivity}
                          clearCurrentActivity={clearCurrentActivity}
                          showOrgDialog={showOrgDialog}
                          selectedOrgId={selectedOrgId}
                          selectedOption={selectedOption}
                          handleOrgsDialog={handleOrgsDialog}
                          onLikeSuccess={handleLikeSuccess}
                          onBookmarkSuccess={handleBookmarkSuccess}
                          activity={activity}
                          key={activity.id}
                          eventGallery={activity.eventGallery}
                          handleDeleteClick={handleDeleteClick}
                        />
                      ) : activity?.eventType === 'Blog' && !activity?.blocked ? (
                        <BlogCard blog={activity} key={activity.id} />
                      ) : activity?.eventType === 'AMYS_MESSAGE_CARD' ? (
                        <AmySaysCard amySays={activity} key={activity.id} />
                      ) : activity?.eventType === 'Petition' && !activity?.blocked ? (
                        <PetitionCard key={activity.id} petition={activity} handleDeletePetition={handleDeletePetition} />
                      ) : null
                    ) : (
                      <ActivitySensitiveCard key={activity?.id} handleButtonClick={() => onButtonClick(activity?.id)} />
                    )
                  )}
                {eventType === 'Ongoing' && filterLoading && (
                  <>
                    <div
                      className="d-flex justify-content-center align-content-center align-items-center"
                      style={{ height: '400px', width: '100%' }}
                    >
                      <Lottie animationData={elephantWalking} loop={true} style={{ height: '200px', width: '200px' }} />
                    </div>
                  </>
                )}

                {eventType === 'Ongoing' && activities?.length === 0 && !filterLoading && (
                  <div className="col-12 d-flex justify-content-start flex-column align-items-center p-5 ">
                    {
                      <>
                        <NoResultsCalender sx={{ height: '86px', width: '100px' }} />
                        <div className="mt-3">
                          <p style={{ color: '#787b7f', font: 'normal normal 600 24px/37px Manrope' }}>Oh no!</p>
                        </div>
                        <div className="mt-1d-inline text-center">
                          {categoryName !== 'Remote Events' ? (
                            <>
                              <span style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>
                                There are no events in this {filter.subCategoryId ? 'sub category' : 'category'}
                                {` `}
                              </span>
                              <span style={{ color: '#787b7f', font: 'normal normal 600 14px/20px Manrope' }}>
                                {filter.subCategoryId ? subcategoryName : categoryName}
                              </span>{' '}
                              <span style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>
                                right now. But explore beyond for diverse and exciting content.
                              </span>
                            </>
                          ) : (
                            <>
                              <span style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>
                                There are no events in
                              </span>
                              <span style={{ color: '#787b7f', font: 'normal normal 600 14px/20px Manrope' }}>
                                &nbsp;{categoryName}&nbsp;
                              </span>
                              <span style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>
                                right now. But explore beyond for diverse and exciting content.
                              </span>
                            </>
                          )}
                        </div>
                      </>
                    }
                    {/* {localStoragePayload?.categoryId != null &&
                      (localStoragePayload?.subCategoryId !== null || localStoragePayload?.petition !== null) && (
                        <>
                          <NoResultsCalender sx={{ height: '86px', width: '100px' }} />
                          <div className="mt-3">
                            <p style={{ color: '#787b7f', font: 'normal normal 600 24px/37px Manrope' }}>Oh no!</p>
                          </div>
                          <div className="mt-1d-inline text-center">
                            <span style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>
                              There are no events in this subcategory
                            </span>
                            <span style={{ color: '#787b7f', font: 'normal normal 600 14px/20px Manrope' }}>
                              &nbsp;{subcategoryName}&nbsp;
                            </span>{' '}
                            <span style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>
                              {' '}
                              right now. But explore beyond for diverse and exciting content.
                            </span>
                          </div>
                        </>
                      )} */}
                  </div>
                )}
              </Stack>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <Stack gap={4}>
                {eventType === 'Completed' &&
                  activities?.map((activity: any) =>
                    activity?.reportedCount < 3 || allowedList?.includes(activity.id) ? (
                      activity?.eventType === 'Activity' && !activity?.blocked ? (
                        <SingleActivityCard
                          changeOrgId={changeOrgId}
                          handleCancel={handleCancel}
                          isVolunteerModalOpen={isVolunteerModalOpen}
                          handleInstructionsModal={handleInstructionsModal}
                          currentActivityId={currentActivityId}
                          getCurrentActivity={getCurrentActivity}
                          clearCurrentActivity={clearCurrentActivity}
                          showOrgDialog={showOrgDialog}
                          selectedOrgId={selectedOrgId}
                          selectedOption={selectedOption}
                          handleOrgsDialog={handleOrgsDialog}
                          onLikeSuccess={handleLikeSuccess}
                          onBookmarkSuccess={handleBookmarkSuccess}
                          activity={activity}
                          key={activity.id}
                          eventGallery={activity.eventGallery}
                          handleDeleteClick={handleDeleteClick}
                        />
                      ) : activity?.eventType === 'Blog' && !activity?.blocked ? (
                        <BlogCard blog={activity} key={activity.id} />
                      ) : activity?.eventType === 'AMYS_MESSAGE_CARD' ? (
                        <AmySaysCard amySays={activity} key={activity.id} />
                      ) : activity?.eventType === 'Petition' && !activity?.blocked ? (
                        <PetitionCard key={activity.id} petition={activity} handleDeletePetition={handleDeletePetition} />
                      ) : null
                    ) : (
                      <ActivitySensitiveCard key={activity?.id} handleButtonClick={() => onButtonClick(activity?.id)} />
                    )
                  )}

                {eventType === 'Completed' && filterLoading && (
                  <>
                    <div
                      className="d-flex justify-content-center align-content-center align-items-center"
                      style={{ height: '400px', width: '100%' }}
                    >
                      <Lottie animationData={elephantWalking} loop={true} style={{ height: '200px', width: '200px' }} />
                    </div>
                  </>
                )}
                {eventType === 'Completed' && activities?.length === 0 && !filterLoading && (
                  <div className="col-12 d-flex justify-content-start flex-column align-items-center p-5 ">
                    {
                      <>
                        <NoResultsCalender sx={{ height: '86px', width: '100px' }} />
                        <div className="mt-3">
                          <p style={{ color: '#787b7f', font: 'normal normal 600 24px/37px Manrope' }}>Oh no!</p>
                        </div>
                        <div className="mt-1d-inline text-center">
                          {categoryName !== 'Remote Events' ? (
                            <>
                              <span style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>
                                There are no events in this {filter.subCategoryId ? 'sub category' : 'category'}
                                {` `}
                              </span>
                              <span style={{ color: '#787b7f', font: 'normal normal 600 14px/20px Manrope' }}>
                                {filter.subCategoryId ? subcategoryName : categoryName}
                              </span>{' '}
                              <span style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>
                                right now. But explore beyond for diverse and exciting content.
                              </span>
                            </>
                          ) : (
                            <>
                              <span style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>
                                There are no events in
                              </span>
                              <span style={{ color: '#787b7f', font: 'normal normal 600 14px/20px Manrope' }}>
                                &nbsp;{categoryName}&nbsp;
                              </span>
                              <span style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>
                                right now. But explore beyond for diverse and exciting content.
                              </span>
                            </>
                          )}
                        </div>
                      </>
                    }
                    {/* {localStoragePayload?.categoryId != null &&
                      (localStoragePayload?.subCategoryId !== null || localStoragePayload?.petition !== null) && (
                        <>
                          <NoResultsCalender sx={{ height: '86px', width: '100px' }} />
                          <div className="mt-3">
                            <p style={{ color: '#787b7f', font: 'normal normal 600 24px/37px Manrope' }}>Oh no!</p>
                          </div>
                          <div className="mt-1d-inline text-center">
                            <span style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>
                              There are no events in this subcategory
                            </span>
                            <span style={{ color: '#787b7f', font: 'normal normal 600 14px/20px Manrope' }}>
                              &nbsp;{subcategoryName}&nbsp;
                            </span>{' '}
                            <span style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>
                              {' '}
                              right now. But explore beyond for diverse and exciting content.
                            </span>
                          </div>
                        </>
                      )} */}
                  </div>
                )}

                {/* {eventType === 'Completed' && errorResults !== null && !filterLoading && (
                  <div className="col-12 d-flex justify-content-start flex-column align-items-center p-5 ">
                    { (
                      <>
                        <NoResultsCalender sx={{ height: '86px', width: '100px' }} />
                        <div className="mt-3">
                          <p style={{ color: '#787b7f', font: 'normal normal 600 24px/37px Manrope' }}>Oh no!</p>
                        </div>
                        <div className="mt-1d-inline text-center">
                          <span style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>
                            There are no events in this category
                          </span>
                          <span style={{ color: '#787b7f', font: 'normal normal 600 14px/20px Manrope' }}>&nbsp;{categoryName}&nbsp;</span>{' '}
                          <span style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>
                            {' '}
                            right now. But explore beyond for diverse and exciting content.
                          </span>
                        </div>
                      </>
                    )}
                    {localStoragePayload?.categoryId != null &&
                      (localStoragePayload?.subCategoryId !== null || localStoragePayload?.petition !== null) && (
                        <>
                          <NoResultsCalender sx={{ height: '86px', width: '100px' }} />
                          <div className="mt-3">
                            <p style={{ color: '#787b7f', font: 'normal normal 600 24px/37px Manrope' }}>Oh no!</p>
                          </div>
                          <div className="mt-1d-inline text-center">
                            <span style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>
                              There are no events in this subcategory
                            </span>
                            <span style={{ color: '#787b7f', font: 'normal normal 600 14px/20px Manrope' }}>
                              &nbsp;{subcategoryName}&nbsp;
                            </span>{' '}
                            <span style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>
                              {' '}
                              right now. But explore beyond for diverse and exciting content.
                            </span>
                          </div>
                        </>
                      )}
                  </div>
                )} */}
              </Stack>
            </CustomTabPanel>
          </>
        )}
        {/* ) : (
          <Box sx={{ display: 'flex', minHeight: '200px', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )} */}
      </Box>

      <Box
        sx={{
          '& > :not(style)': { m: 1 },
          position: 'fixed',
          bottom: isSmallerScreen ? '8%' : '20px',
          right: isSmallerScreen ? '0px' : '40px',
        }}
        className="fixed-button"
      >
        <Fab
          size="large"
          color="secondary"
          aria-label="add"
          sx={{
            marginTop: ' 6px',
            textTransform: 'capitalize',
            backgroundColor: 'primary.light',
            color: 'common.white',
            '&:hover': {
              color: 'common.white',
              backgroundColor: 'primary.light',
              textTransform: 'capitalize',
              boxShadow: 'none',
            },
          }}
          onClick={handleCreateActivity}
        >
          <AddIcon />
        </Fab>
      </Box>
      <AuthorisationModal
        onClose={handleAuthModalClose}
        authModalOpen={authModalOpen}
        fullScreen={isSmallerScreen}
        handleAuthModalClose={handleAuthModalClose}
        currentComponent={currentComponent}
        setCurrentComponent={setCurrentComponent}
      />
      <CreateActivityModal handleClose={handleClose} open={createActivityModal} fullScreen={isSmallerScreen} />
      <PhoneVerification
        handleClose={handlePhoneVerificationClose}
        open={phoneVerificationModal}
        fullScreen={isSmallerScreen}
        phoneInputCompleted={phoneInputCompleted}
        setPhoneInputCompleted={setPhoneInputCompleted}
        otpVerificationCompleted={otpVerificationCompleted}
        setotpVerificationCompleted={setOtpVerificationCompleted}
      />
      <EventCalenderModal fullScreen={fullScreenXXL} open={open} data={activities} onClose={handleModalClose} />
      <NearmeGMapWithMarker
        open={isModalOpen}
        onClose={handleCloseModal}
        data={activities}
        latitude={latitudeNear}
        longitude={longitudeNear}
        fullScreen={fullScreenXXL}
      />
    </Box>
  );
};
export default BasicTabs;
