import React, { useState, useEffect } from 'react';
import { FormControl, MenuItem, Select, Grid, Typography, useTheme } from '@mui/material';
import { bloodGroups } from 'app/utils/data/constants/constants';
import { useAppSelector } from 'app/config/store';

interface SettingsProfileBloodGroupProps {
  setUpdatedProfile: React.Dispatch<React.SetStateAction<{}>>;
}
const SettingsProfileBloodGroup = ({ setUpdatedProfile }: SettingsProfileBloodGroupProps) => {
  const [selected, setSelected] = useState('');
  const profileSettings = useAppSelector(state => state.settingsProfile.settingsProfile);
  useEffect(() => {
    if (profileSettings?.bloodGroup) {
      setSelected(profileSettings?.bloodGroup);
    }
  }, [profileSettings?.bloodGroup]);
  const theme = useTheme();
  return (
    <Grid container direction="column" gap={2}>
      <Grid item>
        <Typography variant="h5" sx={{ color: theme.textColor.mainHeading, fontSize: '14px' }}>
          Choose your blood group
        </Typography>
      </Grid>
      <Grid>
        <FormControl
          sx={{
            backgroundColor: 'secondary.dark',
            color: 'secondary.main',
            borderRadius: '15px',
            width: '100%',
          }}
        >
          <Select
            size="small"
            displayEmpty
            name="bloodGroup"
            fullWidth
            placeholder="Blood group"
            labelId="blood group"
            id="postAsOrganisation"
            value={selected}
            onChange={e => {
              const group = e.target.value;
              setSelected(group);
              setUpdatedProfile(state => ({ ...state, bloodGroup: group }));
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  overflowY: 'scroll',
                },
              },
              MenuListProps: {
                style: {
                  maxHeight: '200px',
                },
              },
            }}
            sx={{
              border: 'none',
              fontSize: "0.75rem", padding: "3.5px 0px",
              '&.MuiMenu-paper': {
                backgroundColor: '#FF5500',
                borderRadius: '15px',
                border: 'none',
              },
              '&.MuiOutlinedInput-root': {
                background: 'secondary.main',
                borderRadius: '15px',
                height: 45,
              },
            }}
          >
            {bloodGroups?.map((item: any) => (
              <MenuItem value={item?.value} key={item?.id}>
                {item?.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default SettingsProfileBloodGroup;
