import dayjs from 'dayjs';

export interface IMessageTable {
  id?: number;
  message?: string | null;
  createdBy?: number | null;
  createdAt?: string | null;
  modifiedBy?: number | null;
  modifiedAt?: string | null;
}

export const defaultValue: Readonly<IMessageTable> = {};
