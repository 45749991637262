import { Dialog, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar'
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US'
import { useNavigate } from 'react-router-dom';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CloseIcon from '@mui/icons-material/Close';
import { Card } from 'reactstrap';
import { Scheduler } from "@aldabil/react-scheduler";



interface EventCalenderProps {
    fullScreen: boolean;
    data?: any;
    open: boolean;
    onClose: (data?: any) => void;

}

const EventCalenderModal = ({ fullScreen, onClose, data, open, }: EventCalenderProps) => {
    useEffect(() => {
    }, [data]);
    const navigate = useNavigate();
    const locales = {
        'en-US': enUS,
    }
    const localizer = dateFnsLocalizer({
        format,
        parse,
        startOfWeek,
        getDay,
        locales,
    });
    const mappedEvents = data.map((item, index) => {
        const start = item?.startTime;
        const end = item?.startTime;
        return {
            event_id: item?.id,
            title: item?.title,
            start: new Date(start),
            end: new Date(end),
            editable: false,
            deletable: false,
            type: item?.eventType,
            // eventBackgroundColor: '#674bff'
        };
    }).filter(item => item.type === 'Activity');

    const handleEventClick = (eventClickInfo) => {
        const eventId = eventClickInfo?.event_id;
        const typeID = eventClickInfo?.type;
        if (typeID === "Activity") {
            navigate(`/activity/${eventId}`);
        }
        else {
            navigate(`/petition/${eventId}`);
        }
    };
    function getDate(dayString) {
        const today = new Date();
        const year = today.getFullYear().toString();
        let month = (today.getMonth() + 1).toString();
        if (month.length === 1) {
            month = "0" + month;
        }
        return dayString;
    }
    const eventStyleGetter = (event, start, end, isSelected) => {
        const backgroundColor = '#674bff';
        const style = {
            backgroundColor,
            borderRadius: '5px',
            opacity: 0.8,
            color: 'white',
            border: 'none',
            display: 'block',
        };

        return {
            style,
        };
    };
    const EventWrapper = ({ event, children }) => {
        const { title } = event;
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ backgroundColor: event.eventBackgroundColor, borderRadius: '5px', color: 'white' }}>
                    {title}
                </div>
                {children}
            </div>
        );
    };
    const views = {
        week: true,
        day: true,
        month: true,
        agenda: false,
    };
    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={onClose}
            scroll="body"
            PaperProps={{
                sx: {

                    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
                    backgroundColor: '#ffff',
                    overflow: 'scroll',
                    scrollbarWidth: 'thin',
                    msOverflowStyle: 'none',
                    '&::-webkit-scrollbar': {
                        width: '0.1rem',
                    },
                },

            }}>
            <div className="d-flex justify-content-end">
                <IconButton
                    aria-label="Close"
                    onClick={onClose}
                >
                    <CloseIcon sx={{ color: '#362691' }} />
                </IconButton>
            </div>

            <div style={{ width: '100%' }}>

                {/* <Calendar
                    localizer={localizer}
                    events={mappedEvents}
                    views={views}
                    defaultView="week"
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 600, width: '100%' }}
                    onSelectEvent={handleEventClick}
                    eventPropGetter={eventStyleGetter}
                    components={{
                        week: {
                            event: EventWrapper,
                        },
                    }}
                /> */}

                <Scheduler events={mappedEvents} onEventClick={handleEventClick}
                    week={{
                        weekDays: [0, 1, 2, 3, 4, 5, 6],
                        weekStartOn: 1,
                        startHour: 0,
                        endHour: 24,
                        step: 60
                    }} />
            </div>

        </Dialog>
    );
}

export default EventCalenderModal;