import {
  Box,
  Card,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Switch,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import React, { useEffect } from 'react';

import { alpha, styled } from '@mui/material/styles';
import axios from 'axios';

const SettingsNotifications = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [payload, setPayload] = React.useState<any>();
  const BlueSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#674bff',
      '&:hover': {
        backgroundColor: alpha('#674bff', theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#674bff',
    },
  }));
  useEffect(() => {
    handleGetNotifications();
  }, []);

  const handleGetNotifications = async () => {
    try {
      const response = await axios.get<any>(`/api/notification/settings`);
      if (response.status === 201 || response.status === 200) {
        setPayload(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSwitchToggle = async (settingsId, enable) => {
    try {
      const response = await axios.put(`/api/notification/settings/${settingsId}`, {
        enable: enable,
      });
      if (response.status === 200) {
        // Update the payload state to reflect the changes
        setPayload(prevState => prevState.map(item => (item.settingsId === settingsId ? { ...item, enable: enable } : item)));
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box>
      <Card
        style={{ background: 'none' }}
        sx={{
          maxWidth: '100%',
          marginBottom: '15px !important;',
          padding: ' 10px',
          boxShadow: 'none',
          //   background: theme.palette.mode === 'dark' ? '#2C2C38' : 'transperant',
        }}
      >
        {payload?.map(item => (
          <Box key={item.settingsId} className="row align-items-center">
            <Box className="col-3">
              <Typography variant="h6" sx={{ fontSize: '14px', fontWeight: '600' }} gutterBottom>
                {item.description}
              </Typography>
            </Box>
            <Box className="col-3 text-end">
              <BlueSwitch defaultChecked={item.enable} onChange={e => handleSwitchToggle(item.settingsId, e.target.checked)} />
            </Box>
          </Box>
        ))}
      </Card>
    </Box>
  );
};

export default SettingsNotifications;
