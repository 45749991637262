import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import { rateActivity } from './activity/activity-rating';
import { Storage } from 'react-jhipster';
import { AUTH_TOKEN_KEY } from './authentication';

const initialState = {
  profile: null,
  profileLoading: true,
  profileSuccess: false,
  profileError: null,
  profileDetails: null,
  profileDetailsLoading: false,
  profileDetailsSuccess: false,
  profielDetailsError: null,
  profileBookmarkedItems: null,
  profileBookmarkedCount: null,
  postingLimit: null,
  postingLimitError: null,
  bookmarkCount: null,
  moderatorCount: null,
  draftCount: null,
  organizedCount: null,
  participationCount: null,
  bookmarkCountByCategory: null,
  participationCountByCategory: null,
  organizedCountByCategory: null,
  moderatedCountByCategory: null,
};
export interface profileFilterType {
  id: number;
  eventType: string;
}

interface RequestData {
  userId: number;
}
interface FetchCountRequest {
  userId: string;
  categoryId: string;
}
export const fetchProfile = createAsyncThunk<
  any,
  any,
  {
    rejectValue: string;
  }
>('profile/fetchProfile', async (id, thunkAPI) => {
  try {
    const response = await axios.post(`/api/profile/${id}`);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch profile');
  }
});

export const fetchUnAuthorizedProfile = createAsyncThunk<
  any,
  any,
  {
    rejectValue: string;
  }
>('profile/fetchProfile', async (id, thunkAPI) => {
  try {
    const response = await axios.post(`/api/unauthorized/profile/${id}`);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch profile');
  }
});

export const fetchImpactReport = createAsyncThunk<
  any,
  any,
  {
    rejectValue: string;
  }
>('profile/fetchImpactReport', async (id, thunkAPI) => {
  try {
    const offsetMinutes = new Date().getTimezoneOffset();
    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
    const offsetMins = Math.abs(offsetMinutes) % 60;
    const sign = offsetMinutes > 0 ? '-' : '+';
    const zoneId = `${sign}${String(offsetHours).padStart(2, '0')}:${String(offsetMins).padStart(2, '0')}`;

    const response = await axios.post(`/api/profile/${id}/impactReport`, {
      zoneId: zoneId,
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch report');
  }
});

export const getPostingLimit = createAsyncThunk<
  any,
  any,
  {
    rejectValue: string;
  }
>('profile/getPostingLimit', async (id, thunkAPI) => {
  try {
    const response = await axios.get(`/api/profile/${id}/postinglimit`);
    localStorage.setItem('activityPostLimitReached', response?.data?.activityPostLimitReached);
    localStorage.setItem('petitionPostLimitReached', response?.data?.petitionPostLimitReached);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch report');
  }
});

const localToken = Storage.local.get(AUTH_TOKEN_KEY);
const sessionToken = Storage.session.get(AUTH_TOKEN_KEY);
const myCookieValue = Cookies.get('jhi-authenticationToken');
const authenticationToken = myCookieValue;
const isCookie = !!authenticationToken;

const isAuthenticated = isCookie || !!localToken || !!sessionToken;

export const fetchProfileDetails = createAsyncThunk<any, profileFilterType, { rejectValue: string }>(
  'profile/fetchProfileDetails',
  async (requestData, thunkAPI) => {
    try {
      let response;
      if (localStorage.getItem('jhi-authenticationToken')) {
        response = await axios.post('/api/profile/filter', requestData);
      } else {
        response = await axios.post('/api/unauthorized/profile/filter', requestData);
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch profile details');
    }
  }
);

export const fetchParticipatedDetails = createAsyncThunk<any, profileFilterType, { rejectValue: string }>(
  'profile/fetchParticipatedDetails',
  async (requestData, thunkAPI) => {
    try {
      const response = await axios.post('/api/profile/filter', requestData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch profile details');
    }
  }
);

export const fetchCount = createAsyncThunk<any, FetchCountRequest, { rejectValue: string }>(
  'profile/fetchCount',
  async (requestData, thunkAPI) => {
    try {
      const response = await axios.get(
        `api/profile/counts?userId=${requestData?.userId}${requestData?.categoryId ? `&categoryId=${requestData?.categoryId}` : ''}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch profile details');
    }
  }
);

export const fetchBanned = createAsyncThunk<any, RequestData, { rejectValue: string }>(
  'profile/fetchBanned',
  async (requestData, thunkAPI) => {
    try {
      const response = await axios.get(`/api/users/${requestData.userId}/is-banned`);
      return response?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to get');
    }
  }
);

export const fetchBlocked = createAsyncThunk<any, RequestData, { rejectValue: string }>(
  'profile/fetchBlocked',
  async (requestData, thunkAPI) => {
    try {
      const response = await axios.get(`/api/users/is-blocked/${requestData.userId}`);
      return response?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to get');
    }
  }
);

export const fetchBookMarkedItems = createAsyncThunk<any, profileFilterType, { rejectValue: string }>(
  'profile/fetchBookMarkedItems',
  async (requestData, thunkAPI) => {
    try {
      const response = await axios.post('/api/profile/filter', requestData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch profile details');
    }
  }
);

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchProfile.pending, state => {
        state.profileLoading = true;
        state.profileSuccess = false;
        state.profileError = null;
        state.profile = null;
        state.profileDetails = null;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.profileLoading = false;
        state.profileSuccess = true;
        state.profileError = null;
        state.profile = action.payload;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.profileLoading = false;
        state.profileSuccess = false;
        state.profileError = action.payload;
        state.profile = null;
      })
      .addCase(fetchProfileDetails.pending, state => {
        state.profileDetailsLoading = true;
        state.profileDetailsSuccess = false;
        state.profielDetailsError = null;
        state.profileDetails = null;
      })
      .addCase(fetchProfileDetails.fulfilled, (state, action) => {
        state.profileDetailsLoading = false;
        state.profileDetailsSuccess = true;
        state.profielDetailsError = null;
        state.profileDetails = action.payload;
      })
      .addCase(fetchProfileDetails.rejected, (state, action) => {
        state.profileDetailsLoading = false;
        state.profileDetailsSuccess = false;
        state.profielDetailsError = action.payload;
        state.profileDetails = null;
      })

      .addCase(fetchBookMarkedItems.pending, state => {})
      .addCase(fetchBookMarkedItems.fulfilled, (state, action) => {
        state.profileBookmarkedCount = action.payload.size;
        state.profileBookmarkedItems = action.payload.homeDTOS;
      })
      .addCase(fetchBookMarkedItems.rejected, (state, action) => {
        state.profileBookmarkedCount = null;
        state.profileBookmarkedItems = null;
      })

      .addCase(getPostingLimit.pending, state => {})
      .addCase(getPostingLimit.fulfilled, (state, action) => {
        state.postingLimitError = false;
        state.postingLimit = action.payload;
      })
      .addCase(getPostingLimit.rejected, (state, action) => {
        state.postingLimitError = action.payload;
        state.profileDetails = null;
      })
      .addCase(rateActivity.fulfilled, (state, action) => {
        if (window.location.pathname === '/profile') {
          let avgRatingScore = 0;
          const activities = state.profileDetails?.homeDTOS;

          const updatedId = action.meta.arg.activityId;
          avgRatingScore = (action.meta.arg.experienceRating + action.meta.arg.punctualityRating + action.meta.arg.teamRating) / 3;
          avgRatingScore = Math.round(avgRatingScore);

          const activityIndex = activities?.findIndex(result => result.id === updatedId);

          if (activityIndex !== -1) {
            activities[activityIndex].rated = true;
            activities[activityIndex].starRating = avgRatingScore;
          }
        }
      })
      .addCase(fetchCount.pending, state => {
        // You can set loading states here if necessary
      })
      .addCase(fetchCount.fulfilled, (state, action) => {
        state.bookmarkCount = action.payload.bookmarkCount || null;
        state.moderatorCount = action.payload.moderatorCount || null;
        state.draftCount = action.payload.draftCount || null;
        state.organizedCount = action.payload.organizedCount || null;
        state.participationCount = action.payload.participationCount || null;
        state.bookmarkCountByCategory = action.payload.bookmarkCountByCategory || null;
        state.participationCountByCategory = action.payload.participationCountByCategory || null;
        state.organizedCountByCategory = action.payload.organizedCountByCategory || null;
        state.moderatedCountByCategory = action.payload.moderatedCountByCategory || null;
      })
      .addCase(fetchCount.rejected, (state, action) => {
        state.bookmarkCount = null;
        state.moderatorCount = null;
        state.draftCount = null;
        state.organizedCount = null;
        state.participationCount = null;
        state.bookmarkCountByCategory = null;
        state.participationCountByCategory = null;
        state.organizedCountByCategory = null;
        state.moderatedCountByCategory = null;
      });
  },
});

export default profileSlice.reducer;
