import React, { useState, useEffect } from 'react';
import { Avatar, Button, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { useAppSelector } from 'app/config/store';
import useFileUpload from 'app/components/common/hooks/use-file-upload';
import axios from 'axios';
import { apiEndPoints } from 'app/utils/data/constants/api-end-points';

const SettingsChangeProfilePic = ({ setUpdatedSettings }: any) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null); // State for the avatar source

  const { imageUploaded, image } = useFileUpload(selectedFile);

  useEffect(() => {
    if (imageUploaded && image?.id) {
      setUpdatedSettings(state => ({ ...state, profileId: image?.id }));
      setAvatarSrc(image?.url); // Update the avatar source when a new image is uploaded
    }
  }, [imageUploaded, image?.id]);

  const theme = useTheme();
  const userSettings = useAppSelector(state => state.settingsReducer.userSettings);

  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    setSelectedFile(files[0]);
  };

  return (
    <Grid container direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
      <Grid item xs={12}>
        <Grid container direction={'row'} alignItems="center" justifyContent={'flex-start'}>
          <Grid item xs={6} md>
            <Avatar
              sx={{
                width: 72,
                height: 72,
              }}
              src={
                avatarSrc
                  ? `${avatarSrc}`
                  : userSettings?.profilePic
                  ? `${userSettings?.profilePic}`
                  : './content/images/login-page/profile_avatar.png'
              }
              alt={userSettings?.emailId}
            />
          </Grid>
          <Grid item xs={6} md sx={{ textAlign: 'end' }}>
            <IconButton color="primary" aria-label="upload picture" component="label" disableRipple sx={{ gap: 1 }}>
              <input
                hidden
                accept=".jpeg, .jpg, .png, .svg"
                type="file"
                name="user_settings_profile_pic"
                onChange={handleFileInputChange}
              />
              <Typography
                sx={{
                  color: theme.textColor.mainHeading,
                  textTransform: 'none',
                  borderRadius: 1.875,
                  py: 0.5,
                  px: 3.25,
                  border: `1px solid ${theme.palette.secondary.contrastText}`,
                  fontSize: theme.infoText.regular.fontSize,
                  fontWeight: theme.infoText.regular.fontWeight,
                  fontStyle: theme.infoText.regular.fontStyle,
                }}
              >
                Change
              </Typography>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SettingsChangeProfilePic;
