import React from 'react';
import { PlayArrow } from '@mui/icons-material';
import ReactPlayer from 'react-player';

interface VideoComponentProps {
  url: string;
  height?: string;
  width?: string;
  controls?: boolean;
  playerStyle?: object;
  light?: boolean;
  playing?: boolean;
  muted?: boolean;
  className?: any;
  config?: any;
  loop?: boolean;
}
const VideoPlayer = ({
  url,
  height = '300px',
  width = '100%',
  controls = true,
  playerStyle,
  light = false,
  playing = false,
  muted = true,
  className,
  config,
  loop,
}: VideoComponentProps) => {
  return (
    <ReactPlayer
      className={className}
      url={url}
      height={height}
      width={width}
      style={playerStyle}
      controls={controls}
      light={light}
      playing={playing}
      muted={muted}
      config={config}
      loop={loop}
      playIcon={<PlayArrow />}
    />
  );
};

export default VideoPlayer;
