import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const AwesomeIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
  <g id="Group_6123" data-name="Group 6123" transform="translate(-25 -111)">
    <circle id="Ellipse_257" data-name="Ellipse 257" cx="22.5" cy="22.5" r="22.5" transform="translate(25 111)" fill="#eff0f0"/>
    <g id="Group_6051" data-name="Group 6051" transform="translate(9.932 95.932)">
      <g id="Group_4916" data-name="Group 4916" transform="translate(27.069 27.069)">
        <g id="Group_4913" data-name="Group 4913" transform="translate(0 0)">
          <circle id="Ellipse_239" data-name="Ellipse 239" cx="10.056" cy="10.056" r="10.056" transform="translate(0 20.112) rotate(-90)" fill="#ffd05d"/>
        </g>
        <g id="Group_4914" data-name="Group 4914" transform="translate(1.582 1.473)">
          <path id="Path_3518" data-name="Path 3518" d="M119.848,88.469a10.057,10.057,0,0,1-13.735,13.988,10.057,10.057,0,1,0,13.735-13.988Z" transform="translate(-106.113 -88.469)" fill="#fdc453"/>
        </g>
        <g id="Group_4915" data-name="Group 4915" transform="translate(0.672 0.743)">
          <path id="Path_3519" data-name="Path 3519" d="M99.092,92.65a9.336,9.336,0,0,1,16.737-5.691,9.337,9.337,0,1,0-15.5,10.345A9.293,9.293,0,0,1,99.092,92.65Z" transform="translate(-98.397 -82.276)" fill="#ffd77f"/>
        </g>
      </g>
      <g id="Group_4920" data-name="Group 4920" transform="translate(32.679 33.436)">
        <g id="Group_4919" data-name="Group 4919">
          <g id="Group_4917" data-name="Group 4917">
            <path id="Path_3520" data-name="Path 3520" d="M141.738,131.2c0,.69-.332,1.249-.741,1.249s-.741-.559-.741-1.249.332-1.249.741-1.249S141.738,130.512,141.738,131.2Z" transform="translate(-140.256 -129.953)" fill="#303030"/>
          </g>
          <g id="Group_4918" data-name="Group 4918" transform="translate(7.409)">
            <path id="Path_3521" data-name="Path 3521" d="M204.542,131.2c0,.69-.332,1.249-.741,1.249s-.741-.559-.741-1.249.332-1.249.741-1.249S204.542,130.512,204.542,131.2Z" transform="translate(-203.06 -129.953)" fill="#303030"/>
          </g>
        </g>
      </g>
      <path id="Path_3522" data-name="Path 3522" d="M120.664,169.28a6.8,6.8,0,0,0,13.514,0Z" transform="translate(-90.296 -131.204)" fill="#695342"/>
      <path id="Path_3523" data-name="Path 3523" d="M138.376,197.259a6.795,6.795,0,0,0,9.176.147l-.063-.146A11.217,11.217,0,0,0,138.376,197.259Z" transform="translate(-105.919 -155.009)" fill="#f06669"/>
    </g>
  </g>
</svg>
,
  'AwesomeIcon'
);

export default AwesomeIcon;
