import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Avatar, ListItemAvatar, ListItemButton, ListItemText, Typography, IconButton, Badge, Box } from '@mui/material';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close'; // Import the close icon
import PushPinIcon from '@mui/icons-material/PushPin'; // Import the unpin icon
import ChatPinIcon from 'app/components/icons/chat-action/pin-icon';
import { toast } from 'react-toastify';
import { getPinnedMessage } from 'app/shared/reducers/chat-messages/chat-Panel';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import OrganiserCrownIcon from 'app/components/icons/activity-card/organiser-crown-icon';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ReactPlayer from 'react-player';
import './pinned-chat.scss';
import { useNavigate } from 'react-router-dom';
import { getInitials } from 'app/utils/common-utils/common-utils';

const PinnedCommentDialog = ({ pinnedData, open, onClose, activity }) => {
  const dispatch = useAppDispatch();
  const userDetails = useAppSelector(state => state.authentication.account);
  const pinConversations = useAppSelector(state => state.chatPinReducer.chatResponse);
  const [localPinnedData, setLocalPinnedData] = useState(pinnedData);

  const pinMessage = async item => {
    const payload = {
      messageId: item.id, // Replace with the actual message ID
      payload: {
        eventId: item.eventId,
        eventType: item.eventType,
      },
    };
    try {
      const response = await dispatch(getPinnedMessage(payload));
      if (response) {
        const pinned = response?.payload?.data;
        toast.success(response?.payload?.message);
        const pinnedDatas = response?.payload ? pinned.filter(item => item.pinned === true) : [];
        setLocalPinnedData(pinnedDatas);
      } else {
        toast.error('Error , Message not unpinned');
      }
    } catch (error) {
      console.error('An error occurred while fetching data:', error);
    }
  };

  React.useEffect(() => {
    setLocalPinnedData(pinnedData);
    if (localPinnedData && localPinnedData.length === 0) {
      onClose();
    }
  }, [pinnedData, onClose]);

  const ImageComponent = ({ additionalData }) => {
    return (
      <Box>
        <img style={{ width: '10%', height: '10%' }} src={additionalData?.link}></img>
      </Box>
    );
  };

  // const VideoComponent = ({ additionalData }) => {
  //   const videoUrl = additionalData?.link;
  //   return (
  //     <Box>
  //       <ReactPlayer url={videoUrl} height="10%" width="20%" controls={true} />
  //     </Box>
  //   );
  // };
  const thumbnailContainerStyle = {
    position: 'relative',
    width: '40%',
    height: '40%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  // Styles for the play button to make it centered and styled
  const playButtonStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '8px',
    color: 'white',
    cursor: 'pointer',
  };
  const VideoComponent = ({ additionalData }) => {
    const videoUrl = additionalData?.link;
    return (
      <Box sx={{ thumbnailContainerStyle }}>
        <img style={{ width: '100%', height: '100%' }} src={additionalData?.thumbnail} alt="Video thumbnail"></img>
        <PlayCircleOutlineIcon sx={{ position: 'absolute', color: '#fff' }} onClick={() => window.open(videoUrl, '_blank')} />
      </Box>
    );
  };
  const navigate = useNavigate();
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Pinned Comments
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{ position: 'absolute', top: '8px', right: '16px' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {/* Render the content of the pinned comment */}
        <div style={{ height: '300px', overflowY: 'auto', overflowX: 'hidden' }}>
          {localPinnedData?.map((message, index) => (
            <ListItemButton alignItems="flex-start" key={index}>
              <ListItemAvatar>
                {message?.organizer === true ? (
                  <Badge
                    overlap="circular"
                    badgeContent={<OrganiserCrownIcon sx={{ height: 'unset', width: 'unset', rotate: '-51deg' }} />}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                  >
                    {/* <Avatar alt={message?.name} src={message?.profileImage} onClick={() => navigate(`/profile/${message?.userId}`)} /> */}
                    <Avatar
                      alt={`${message?.profileImage}` ? '' : getInitials(message?.name)}
                      src={message?.profileImage}
                      onClick={() => navigate(`/profile/${message?.userId}`)}
                    >
                      {' '}
                      {getInitials(message?.name)}
                    </Avatar>
                  </Badge>
                ) : (
                  // <Avatar alt={message?.name} src={message?.profileImage} onClick={() => navigate(`/profile/${message?.userId}`)} />
                  <Avatar
                    alt={`${message?.profileImage}` ? '' : getInitials(message?.name)}
                    src={message?.profileImage}
                    onClick={() => navigate(`/profile/${message?.userId}`)}
                  >
                    {' '}
                    {getInitials(message?.name)}
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText
                sx={{ textAlign: 'justify', paddingRight: '10%', wordWrap: 'break-word', wordBreak: 'break-all' }}
                primary={
                  <React.Fragment>
                    <Typography sx={{ display: 'inline', fontWeight: '600' }} component="span" variant="body2" color="text.primary">
                      {message?.userId === userDetails?.id ? 'You' : message?.name} &nbsp;
                    </Typography>
                    {moment(message?.chatPostedOn).fromNow()}
                    <>
                      {/* image display area */}
                      {message?.eventGallery?.length > 0 && message?.eventGallery[0]?.type === 'images' && (
                        <Box>
                          <ImageComponent additionalData={message?.eventGallery[0]} />
                        </Box>
                      )}

                      {/* video display area */}
                      {message?.eventGallery?.length > 0 && message?.eventGallery[0]?.type === 'videos' && (
                        <Box>
                          <VideoComponent additionalData={message?.eventGallery[0]} />
                        </Box>
                      )}
                    </>
                  </React.Fragment>
                }
                secondary={message?.message}
              />
              <IconButton
                edge="end"
                disableRipple
                color="inherit"
                aria-label="unpin"
                sx={{ position: 'absolute', top: '16px', right: '8px', transform: 'translateY(-50%)' }}
                // onClick={() => pinMessage(message)}
              >
                <ChatPinIcon />
              </IconButton>
            </ListItemButton>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PinnedCommentDialog;
