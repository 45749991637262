import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const ActivitySensitiveCard = ({handleButtonClick}) => {
  return (
    <Card
      sx={{
        minWidth: '100%',
        backgroundColor: 'secondary.dark',
        boxShadow: 'none',
        borderRadius: '20px',
      }}
    >
      <CardContent>
        <Typography sx={{ fontSize: 14, textAlign: 'center' }} color="text.secondary">
          This event contains sensitive content
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: 'center' }}>
        <Button sx={{ color: '#674BFF' }} size="small" onClick={handleButtonClick}>
          Click here to view <VisibilityOffIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

export default ActivitySensitiveCard