import React, { useEffect, useState } from 'react';

import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  Tooltip,
  Button,
  IconButton,
  Stack,
  useTheme,
  Typography,
  ListItem,
  ListItemText,
  useMediaQuery,
  Dialog,
  Box,
  Grid,
  DialogContent,
  DialogActions,
  Paper,
  AvatarGroup,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  CardHeader,
} from '@mui/material';
import Moment from 'react-moment';
interface amySaysProps {
  amySays;
}
const AmySaysCard = ({ amySays }: amySaysProps) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  return (
    <Card
      sx={{
        minWidth: '100%',
        // backgroundImage: isDarkMode ? "url('content/images/darkModeBackground.png')" : "url('content/images/lightModeBackground.png')",
        // background: 'linear-gradient(45deg, #674BFF 30%, #DDD7FF 90%)',
        background: isDarkMode ? 'linear-gradient(45deg, #674BFF 30%, #9b78b6 90%)' : 'linear-gradient(45deg, #674BFF 30%, #9b78b6 90%)',
        boxShadow: 'none',
        borderRadius: '20px',
        objectFit: 'contain',
        objectPosition: 'bottom',
      }}
    >
      <CardHeader
        avatar={
          <Avatar sx={{ borderRadius: '50%' }} src={'content/images/appIcon.png'}>
            AMY
          </Avatar>
        }
        title="AMY says"
        titleTypographyProps={{ sx: { color: '#ffff' } }}
        sx={{
          '.MuiCardHeader-avatar': {
            bgcolor: '#ffff',
          },
          '.MuiCardHeader-title': {
            color: '#ffff',
          },
        }}
      />
      <CardContent>
        {amySays?.title && (
          <Typography variant="body2" sx={{ color: theme.palette.mode === 'dark' ? '#FFF' : '#FFF', fontWeight: 600 }}>
            {amySays?.title}
          </Typography>
        )}
        <Typography variant="body2" sx={{ color: theme.palette.mode === 'dark' ? '#FFF' : '#FFF' }}>
          {amySays?.message}
        </Typography>
      </CardContent>
      <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <IconButton sx={{ color: theme.palette.mode === 'dark' ? '#FFF' : '#FFF', fontSize: '12px' }}>AM with You</IconButton>
        {!amySays?.title && (
          <IconButton sx={{ color: theme.palette.mode === 'dark' ? '#FFF' : '#FFF', fontSize: '12px' }}>
            <Moment fromNow>{amySays?.createdAt}</Moment>
          </IconButton>
        )}
      </CardActions>
    </Card>
  );
};

export default AmySaysCard;
