import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const ZerohungerIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21.046" viewBox="0 0 21 21.046" style={{ fontSize: 'larger' }}>
    <path
      id="zerohunger"
      d="M6.6,22.013a4.185,4.185,0,0,1-2.909-1.82A4.3,4.3,0,0,1,3.1,18.8a12.453,12.453,0,0,1-.078-2.384V14.567h-.19a2.222,2.222,0,0,1-.957-.218,1.622,1.622,0,0,1-.835-1.312,1.981,1.981,0,0,1,0-.719.556.556,0,0,1,1-.082.888.888,0,0,1,.033.3c0,.439.069.673.24.819a.713.713,0,0,0,.5.151h.193l.005-1.3.005-1.3H19.937v2.592l.078.018a1.084,1.084,0,0,0,.241,0c.507-.037.687-.293.688-.975,0-.235.028-.337.114-.43a.6.6,0,0,1,.8,0c.138.139.17.267.152.611a1.847,1.847,0,0,1-.7,1.531,1.934,1.934,0,0,1-1.069.307l-.305.018V16.4a11.933,11.933,0,0,1-.089,2.461,4.2,4.2,0,0,1-.741,1.625,5.244,5.244,0,0,1-.638.657,3.968,3.968,0,0,1-1.515.771c-.527.137-.232.13-5.433.128-3.907,0-4.768,0-4.927-.032ZM3.027,9.455V8.694h7.13l.036-.112a1.352,1.352,0,0,1,.263-.445l.074-.075V6.937h1.908V8.061l.074.075a1.363,1.363,0,0,1,.263.445l.036.112h7.13v1.522H3.027V9.455Zm3.23-1.693a.59.59,0,0,1-.337-.243.558.558,0,0,1,0-.65,1.32,1.32,0,0,0,.187-.607,2.856,2.856,0,0,0-.421-1.447A3.585,3.585,0,0,1,5.253,3.56a2.365,2.365,0,0,1,.672-1.825.574.574,0,0,1,.62-.192.666.666,0,0,1,.362.294.508.508,0,0,1,.012.369.835.835,0,0,1-.124.176,1.658,1.658,0,0,0-.306.439,1.1,1.1,0,0,0-.115.611,2.838,2.838,0,0,0,.337,1.057,5.792,5.792,0,0,1,.372.98,2.4,2.4,0,0,1-.169,1.8,1.37,1.37,0,0,1-.309.442.466.466,0,0,1-.349.051ZM8.668,7.6a.811.811,0,0,1-.331-.3.664.664,0,0,1,0-.5,1.766,1.766,0,0,0,.319-1.125A3.463,3.463,0,0,0,8.408,4.4,4.67,4.67,0,0,1,8.1,2.874,2.461,2.461,0,0,1,8.522,1.44C8.746,1.1,8.87,1,9.093,1a.565.565,0,0,1,.415.191.46.46,0,0,1,.143.344c0,.135-.032.213-.182.439a1.749,1.749,0,0,0-.205,1.471c.023.1.111.4.195.668a4.35,4.35,0,0,1,.293,1.666,3,3,0,0,1-.326,1.232c-.163.336-.279.5-.394.561a.475.475,0,0,1-.362.023Z"
      transform="translate(-1.013 -1)"
      fill="#676e7b"
    />
  </svg>,
  'ZerohungerIcon'
);
export default ZerohungerIcon;
