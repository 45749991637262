export interface IUserPreferences {
  id?: number;
  userID?: number | null;
  profilePic?: string | null;
  contactEmail?: string | null;
  country?: string | null;
  bio?: string | null;
  darkMode?: boolean | null;
  personalizeAdsBasedOnInfoFromOurPartners?: boolean | null;
  personalizeRecommendationsBasedOnYourGeneralLocation?: boolean | null;
  notificationsInboxMessages?: boolean | null;
  notificationsEmailMessages?: boolean | null;
  notificationsUpvotesOnYourPosts?: boolean | null;
  notificationsRepliesToYourComments?: boolean | null;
  notificationsActivityOnYourPosts?: boolean | null;
}

export const defaultValue: Readonly<IUserPreferences> = {
  darkMode: false,
  personalizeAdsBasedOnInfoFromOurPartners: false,
  personalizeRecommendationsBasedOnYourGeneralLocation: false,
  notificationsInboxMessages: false,
  notificationsEmailMessages: false,
  notificationsUpvotesOnYourPosts: false,
  notificationsRepliesToYourComments: false,
  notificationsActivityOnYourPosts: false,
};
