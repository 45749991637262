import { FormControl, Grid, OutlinedInput, Typography } from '@mui/material';
import React from 'react';

type Props = {
  address: string;
  handleDetailsChange: (fieldName: any, newValue: any) => void;
  hasPermission: boolean;
};

const SettingsOrgaddress = ({ address, handleDetailsChange, hasPermission }: Props) => {
  return (
    <Grid container spacing={2} sx={{ alignItems: 'center' }}>
      <Grid item xs={12} md={4}>
        <Typography variant="h5" sx={{ fontSize: '14px' }}>
          Address
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <FormControl sx={{ width: '100%' }} variant="outlined">
          <OutlinedInput
            sx={{ fontSize: "0.75rem" }}
            id="outlined-adornment-weight"
            placeholder="Address"
            value={address}
            onChange={e => handleDetailsChange('address', e.target.value)}
            multiline
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
            }}
            disabled={!hasPermission}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default SettingsOrgaddress;
