// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiAccordion-root:before {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/common/modals/all-contributions-list-modal/all-contribution-list-modal.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ","sourcesContent":[".MuiAccordion-root:before {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
