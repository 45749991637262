import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

interface EventRequest {
    eventId: number;
    eventType: string;
}



export interface chatMessageState {
    chatResponse: any | null;
    loading: boolean;
    error: string | null;
    success: boolean | null;
}



export const initialState: chatMessageState = {
    chatResponse: null,
    loading: false,
    error: null,
    success: false,
};



export const getPinnedMessage = createAsyncThunk<any, { messageId: string, payload: EventRequest }, { rejectValue: string }>(
    '/api/chats/conversation/pin/{messageId}',
    async ({ messageId, payload }, { rejectWithValue }) => {
        try {
            const axiosResponse = await axios.post<any>(`/api/chats/conversation/pin/${messageId}`, payload);
            return axiosResponse.data;
        } catch (error) {
            throw rejectWithValue(error.response?.data?.message || 'An error occurred');
        }
    }
);


export const chatPinSlice = createSlice({
    name: 'chatPinReducer',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getPinnedMessage.pending, state => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })

            .addCase(getPinnedMessage.fulfilled, (state, action) => {
                state.chatResponse = action.payload
                state.loading = false;
                state.error = null;
                state.success = true;
            })

            .addCase(getPinnedMessage.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'An error occurred';
                state.success = false;
            })
    },

});



export default chatPinSlice.reducer;