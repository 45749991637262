import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const NearmeIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="17.326"
    height="17.326"
    viewBox="0 0 17.326 17.326"
  >
    <defs>
      <linearGradient id="linear-gradient" x1="0.275" y1="6.088" x2="0.74" y2="5.184" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#33d7f0" />
        <stop offset="1" stopColor="#0083c4" />
      </linearGradient>
      <linearGradient id="linear-gradient-2" x1="0.326" y1="27.086" x2="0.835" y2="27.505" gradientUnits="objectBoundingBox">
        <stop offset="0" stopOpacity="0" />
        <stop offset="1" stopOpacity="0.302" />
      </linearGradient>
      <linearGradient id="linear-gradient-3" x1="-5.429" y1="0.262" x2="-4.919" y2="0.681" xlinkHref="#linear-gradient-2" />
      <linearGradient id="linear-gradient-4" x1="0.167" y1="6.555" x2="0.862" y2="5.861" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#4b4b4b" />
        <stop offset="0.531" stopColor="#393939" />
        <stop offset="1" stopColor="#252525" />
      </linearGradient>
      <radialGradient id="radial-gradient" cx="0.5" cy="0.5" r="0.406" gradientUnits="objectBoundingBox">
        <stop offset="0.177" />
        <stop offset="1" stopOpacity="0" />
      </radialGradient>
    </defs>
    <g id="location_update" transform="translate(-4 -4)">
      <path
        id="Path_66"
        data-name="Path 66"
        d="M21.326,12.663A8.663,8.663,0,1,1,12.663,4,8.663,8.663,0,0,1,21.326,12.663Z"
        fill="url(#linear-gradient)"
      />
      <path
        id="Path_67"
        data-name="Path 67"
        d="M21.326,12.663A8.663,8.663,0,1,1,12.663,4,8.663,8.663,0,0,1,21.326,12.663Z"
        fill="#656b78"
      />
      <path
        id="Path_68"
        data-name="Path 68"
        d="M39.1,30.114a8.594,8.594,0,0,0,.9-2.129L39.016,27,37.5,28.516Z"
        transform="translate(-18.989 -13.037)"
        fill="url(#linear-gradient-2)"
      />
      <path
        id="Path_69"
        data-name="Path 69"
        d="M5.63,13.812a8.594,8.594,0,0,0-.9,2.129l.985.985,1.516-1.516Z"
        transform="translate(-0.412 -5.562)"
        fill="url(#linear-gradient-3)"
      />
      <path
        id="Path_70"
        data-name="Path 70"
        d="M20.622,13.8h.972A7.8,7.8,0,0,0,6.159,12.223l1.035,1.035a.315.315,0,0,1-.223.538H6A7.8,7.8,0,0,0,21.435,15.37L20.4,14.335A.315.315,0,0,1,20.622,13.8Z"
        transform="translate(-1.134 -1.134)"
        fill="url(#linear-gradient-4)"
      />
      <path
        id="Path_71"
        data-name="Path 71"
        d="M21.18,19.06a4.765,4.765,0,1,0-6.831,0L17.39,22.1a.529.529,0,0,0,.748,0Z"
        transform="translate(-5.102 -3.955)"
        fill="#656b78"
      />
      <path
        id="Path_72"
        data-name="Path 72"
        d="M20.045,21.045a3.022,3.022,0,1,1,3.023-3.022A3.026,3.026,0,0,1,20.045,21.045Z"
        transform="translate(-7.381 -6.235)"
        fill="url(#radial-gradient)"
      />
      <circle id="Ellipse_110" data-name="Ellipse 110" cx="2.156" cy="2.156" r="2.156" transform="translate(10.507 9.631)" fill="#f9f9f9" />
    </g>
  </svg>,

  'NearmeIcon'
);
export default NearmeIcon;
