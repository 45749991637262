import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

interface JoiningSuccessModalProps {
  handleClose: () => void;
  fullScreen: boolean;
  open: boolean;
  title: string;
  url: string;
}

function JoiningSuccessModal({ handleClose, fullScreen, open, title, url }: JoiningSuccessModalProps) {
  const [showTooltip, setShowTooltip] = useState(false);
  const theme = useTheme();
  const handleCopyButton = data => {
    navigator.clipboard.writeText(data);
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 1500);
  };
  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="modal">
      <DialogContent>
        <Grid direction={'column'} gap={2} spacing={3} container alignItems={'center'} justifyContent={'center'}>
          <Grid item>
            <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item container direction={'column'} alignItems={'center'} justifyContent={'center'} gap={'3'}>
            <CheckCircleOutlineRoundedIcon sx={{ fontSize: '45px', color: 'primary.light' }} />
            {title && (
              <Typography
                variant="h5"
                sx={{ color: 'secondary.main', fontWeight: 600, textAlign: 'center', px: { lg: 3 }, fontSize: '15px' }}
              >
                {title}
              </Typography>
            )}
          </Grid>
          <Grid>
            {false && (
              <>
                <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                  <TextField
                    size="small"
                    disabled
                    value={url}
                    InputProps={{
                      style: { backgroundColor: 'primary.main', fontSize: '14px', paddingLeft: '10px' },
                      endAdornment: (
                        <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                          <Tooltip open={showTooltip} title="Copied!" placement="top">
                            <span onClick={() => handleCopyButton(url)}>
                              <ContentCopyIcon />
                            </span>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              </>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default JoiningSuccessModal;
