import React from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';

interface CommonSelectProps {
  options: Array<any>;
  selected: string | object;
  setSelected: React.Dispatch<React.SetStateAction<any>>;
}
const CommonSelect = ({ options, selected, setSelected }: CommonSelectProps) => {
  return (
    <Grid>
      <FormControl
        sx={{
          backgroundColor: 'secondary.dark',
          color: 'secondary.main',
          borderRadius: '15px',
          width: '100%',
        }}
      >
        <Select
          size="small"
          name="visibility"
          fullWidth
          placeholder="Visibility"
          labelId="Visibility"
          id="Visibility"
          style={{ fontSize: "0.75rem" }}
          value={selected}
          onChange={(e) => {
            console.log(typeof e.target.value, 'value');
            setSelected(e.target.value);
          }}
          MenuProps={{
            PaperProps: {
              style: {
                overflowY: 'scroll',
              },
            },
            MenuListProps: {
              style: {
                maxHeight: '200px',
              },
            },
          }}
          sx={{
            border: 'none',
            '&.MuiMenu-paper': {
              backgroundColor: '#FF5500',
              borderRadius: '15px',
              border: 'none',
            },
            '&.MuiOutlinedInput-root': {
              background: 'secondary.main',
              borderRadius: '15px',
              height: 45,
            },
          }}
        >
          {options?.length &&
            options.map((item, index) => (
              <MenuItem value={item} key={index}>
                {item}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default CommonSelect;
