import * as yup from 'yup';

// validation schema
export const createBlogValidationSchema = yup.object().shape({
  blogTitle: yup
    .string()
    .required('Blog title is required.')
    .min(1, 'Blog title is required to be at least 1 character.')
    .max(100, 'Blog title cannot be longer than 100 characters.'),
  blogBody: yup.string().required('Blog body is required'),
  postAsNgo: yup.boolean(),
  // name: yup.string().when('postAsNgo', {
  //   is: true,
  //   then: createBlogValidationSchema => createBlogValidationSchema.required('Name is required'),
  //   otherwise: createBlogValidationSchema => createBlogValidationSchema,
  // }),
  // address: yup.string().when('postAsNgo', {
  //   is: true,
  //   then: createBlogValidationSchema => createBlogValidationSchema.required('Address is required'),
  //   otherwise: createBlogValidationSchema => createBlogValidationSchema,
  // }),
  // contactPerson: yup.string().when('postAsNgo', {
  //   is: true,
  //   then: createBlogValidationSchema => createBlogValidationSchema.required('Contact person is required'),
  //   otherwise: createBlogValidationSchema => createBlogValidationSchema,
  // }),
  emailId: yup.string().when('postAsNgo', {
    is: true,
    then: createBlogValidationSchema =>
      createBlogValidationSchema
        // .required('Email id is required')
        .email('Your email is invalid')
        .matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, 'Please enter a valid email address.'),
    otherwise: createBlogValidationSchema => createBlogValidationSchema,
  }),
  phoneNumber: yup.string().matches(/^\d{10}$/, 'Please enter a valid phone number'),
  // phoneNumber: yup.string().when('postAsNgo', {
  //   is: true,
  //   then: createBlogValidationSchema => createBlogValidationSchema.required('Phone number is required'),
  //   otherwise: createBlogValidationSchema => createBlogValidationSchema,
  // }),
  // latitude: yup.string().when('postAsNgo', {
  //   is: true,
  //   then: createBlogValidationSchema => createBlogValidationSchema.required('Latitude is required'),
  //   otherwise: createBlogValidationSchema => createBlogValidationSchema,
  // }),
  // longitude: yup.string().when('postAsNgo', {
  //   is: true,
  //   then: createBlogValidationSchema => createBlogValidationSchema.required('Longitude is required'),
  //   otherwise: createBlogValidationSchema => createBlogValidationSchema,
  // }),

  category: yup.string().required('Category is required'),
  imageId: yup.number().required('Media is required'),
});
