import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const DarkModeToggleIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="19.007" height="19.017" viewBox="0 0 19.007 19.017">
    <path
      id="dark-mode_24x24_1x"
      data-name="dark-mode(24x24)@1x"
      d="M21.045,12.815A9.135,9.135,0,0,1,19.2,17.507,9.023,9.023,0,1,1,11.241,3,6.972,6.972,0,0,0,9.887,7.692a7.09,7.09,0,0,0,2.035,4.441,7.009,7.009,0,0,0,9.123.682Z"
      transform="translate(-2.538 -2.5)"
      fill="none"
      stroke="#292d34"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    />
  </svg>,
  'DarkModeToggleIcon'
);
export default DarkModeToggleIcon;
