import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

export interface RatingType {
  activityId: number;
  experienceRating: number;
  punctualityRating: number;
  teamRating: number;
}

export interface RateActivityType {
  data: string;
}

export interface RateActivityState {
  activityRating: RatingType | null;
  loading: boolean;
  error: string | null;
  success: boolean | null;
}

export const initialState: RateActivityState = {
  activityRating: {
    activityId: 0,
    experienceRating: 0,
    punctualityRating: 0,
    teamRating: 0,
  },
  loading: false,
  error: null,
  success: false,
};

export const rateActivity = createAsyncThunk<any, RatingType, { rejectValue: string }>(
  'rateActivity',
  async ({ activityId, experienceRating, punctualityRating, teamRating }, { rejectWithValue }) => {
    try {
      const response = await axios.post<any>('/api/activity-details/rate-activity', {
        activityId,
        experienceRating,
        punctualityRating,
        teamRating,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);

const rateActivitySlice = createSlice({
  name: 'rateActivity',
  initialState,
  reducers: {
    resetState(state) {
      state.loading = false;
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(rateActivity.pending, state => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(rateActivity.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.activityRating = action.payload;
    });
    builder.addCase(rateActivity.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || 'An error occurred';
      state.success = false;
    });
  },
});

export const { resetState } = rateActivitySlice.actions;
export default rateActivitySlice.reducer;
