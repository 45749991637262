import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const ShareIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21">
    <g id="Group_1674" data-name="Group 1674" transform="translate(0.5 0.5)">
      <g id="share_24x24_1x" data-name="share(24x24)@1x">
        <path
          id="Path_15"
          data-name="Path 15"
          d="M17.5,9A3.5,3.5,0,1,0,14,5.5,3.5,3.5,0,0,0,17.5,9Z"
          transform="translate(-3 -2)"
          fill="none"
          stroke="#676e7b"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_16"
          data-name="Path 16"
          d="M17.5,22A3.5,3.5,0,1,0,14,18.5,3.5,3.5,0,0,0,17.5,22Z"
          transform="translate(-3 -2)"
          fill="none"
          stroke="#676e7b"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_17"
          data-name="Path 17"
          d="M6.5,15.5A3.5,3.5,0,1,0,3,12,3.5,3.5,0,0,0,6.5,15.5Z"
          transform="translate(-3 -2)"
          fill="none"
          stroke="#676e7b"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_18"
          data-name="Path 18"
          d="M14.49,7.28,9.51,10.22"
          transform="translate(-3 -2)"
          fill="none"
          stroke="#676e7b"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_19"
          data-name="Path 19"
          d="M14.49,16.72,9.51,13.78"
          transform="translate(-3 -2)"
          fill="none"
          stroke="#676e7b"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </g>
  </svg>,
  'ShareIcon'
);

export default ShareIcon;
