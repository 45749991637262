import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const SearchIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="21.207" height="21.207" viewBox="0 0 21.207 21.207">
    <g id="search_24x24_1x" data-name="search(24x24)@1x" transform="translate(0.5 0.5)">
      <path
        id="Path_6"
        data-name="Path 6"
        d="M9.5,17A7.5,7.5,0,1,0,2,9.5,7.5,7.5,0,0,0,9.5,17Z"
        transform="translate(-2 -2)"
        fill="none"
        stroke="#bfbfbf"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_7"
        data-name="Path 7"
        d="M22,22l-7.2-7.2"
        transform="translate(-2 -2)"
        fill="none"
        stroke="#bfbfbf"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>,
  'SearchIcon'
);
export default SearchIcon;
