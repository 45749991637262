import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const EnvironmentIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="17.94" height="19.549" viewBox="0 0 17.94 19.549">
    <g id="Group_2506" data-name="Group 2506" transform="translate(18745.859 12359)">
      <rect
        id="Rectangle_836"
        data-name="Rectangle 836"
        width="5.601"
        height="2.23"
        transform="translate(-18745.859 -12340.54) rotate(-83.742)"
        fill="#676e7b"
      />
      <path
        id="Path_2653"
        data-name="Path 2653"
        d="M227.312,35.307l.344.014v1.8h.593v-1.8l.324-.014a4.189,4.189,0,0,0,4.015-4.02l.02-.537a.3.3,0,0,0-.31-.308l-.529.022a4.2,4.2,0,0,0-3.521,2.228V31.63l.271-.3a4.193,4.193,0,0,0,0-5.685l-.366-.4a.3.3,0,0,0-.438,0l-.365.4a4.227,4.227,0,0,0,.007,5.683l.3.319v1.049a4.282,4.282,0,0,0-3.536-2.229l-.544-.022a.3.3,0,0,0-.224.087.3.3,0,0,0-.088.222l.026.537A4.2,4.2,0,0,0,227.312,35.307Z"
        transform="translate(-18961.539 -12384.149)"
        fill="#676e7b"
      />
      <path
        id="Path_2654"
        data-name="Path 2654"
        d="M125.993,352.581l-.007,0-3,1.129a2.208,2.208,0,0,1,0,.5.3.3,0,0,1-.327.262l-4.206-.462a.3.3,0,1,1,.065-.589l3.884.426a1.62,1.62,0,0,0-1.437-1.486l-2.559-.281a3.376,3.376,0,0,1-1.361-.459l-.012-.007-.044-.026-.25-.157a3.97,3.97,0,0,0-4.064-.1l-.412,3.755.484-.21a1.908,1.908,0,0,1,1.431-.039l4.252,1.578a4.093,4.093,0,0,0,2.524-.075l6.366-3.415A1.146,1.146,0,0,0,125.993,352.581Z"
        transform="translate(-18855.238 -12696.03)"
        fill="#676e7b"
      />
      <path
        id="Path_2655"
        data-name="Path 2655"
        d="M242.936,315.446a5.462,5.462,0,0,1-1.13-.44,5.832,5.832,0,0,0-1.746-.6,5.244,5.244,0,0,0-2.146.329c-.318.1-.646.194-.97.26-.4.08-.612.828-.684,1.315a2.78,2.78,0,0,0,.957.288l2.559.281a2.212,2.212,0,0,1,1.806,1.365l2.919-1.1a1.385,1.385,0,0,0-.226-.573A2.663,2.663,0,0,0,242.936,315.446Z"
        transform="translate(-18973.986 -12661.134)"
        fill="#676e7b"
      />
    </g>
  </svg>,

  'EnvironmentIcon'
);
export default EnvironmentIcon;
