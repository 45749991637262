import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const RecyclingIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="19.247" viewBox="0 0 21 19.247" style={{ fontSize: 'larger' }}>
    <g id="Recycling" transform="translate(-54.143 -40.971)">
      <path
        id="Path_4815"
        data-name="Path 4815"
        d="M62.689,43.6a.428.428,0,0,0,.772.187l.9-1.233a.428.428,0,0,0-.1-.6l-1.231-.9a.428.428,0,0,0-.677.411l.1.663a10.452,10.452,0,0,0-7.593,14.06l.518,1.183h1.021l-.674-1.534A9.515,9.515,0,0,1,62.6,43.051Z"
        fill="#676e7b"
      />
      <path
        id="Path_4816"
        data-name="Path 4816"
        d="M298.691,75.136l-.592-.242a10.451,10.451,0,0,0-7.73-13.994l-.179.916a9.522,9.522,0,0,1,7.046,12.725l-.545-.223a.428.428,0,0,0-.557.562l.59,1.408a.428.428,0,0,0,.56.23l1.409-.585a.429.429,0,0,0,0-.794Z"
        transform="translate(-223.817 -18.897)"
        fill="#676e7b"
      />
      <path
        id="Path_4817"
        data-name="Path 4817"
        d="M154.549,182.984a5.6,5.6,0,0,0-4.127,1.326,4.155,4.155,0,0,0-.387.428,4.144,4.144,0,0,0-.386-.428,5.605,5.605,0,0,0-4.128-1.326,1.25,1.25,0,0,0-1.195,1.2,5.607,5.607,0,0,0,1.326,4.127,5.366,5.366,0,0,0,3.917,1.46v4.166h-3.7v.933h8.294v-.933H150.5v-4.166a5.368,5.368,0,0,0,3.915-1.46,5.607,5.607,0,0,0,1.326-4.127A1.251,1.251,0,0,0,154.549,182.984Zm-9.291,1.239a.322.322,0,0,1,.308-.308A4.693,4.693,0,0,1,149,184.982a4.419,4.419,0,0,1,1.173,3.161,4.057,4.057,0,0,0-2.247-2.006l-.267.839a3.167,3.167,0,0,1,1.846,1.858,4.433,4.433,0,0,1-3.182-1.174A4.7,4.7,0,0,1,145.258,184.224Z"
        transform="translate(-85.504 -134.647)"
        fill="#676e7b"
      />
    </g>
  </svg>,
  'RecyclingIcon'
);
export default RecyclingIcon;
