import React, { useEffect, useState, useCallback } from 'react';

import { Button, Stack, useTheme, Box, Typography, Grid } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import { singleImageType } from 'app/shared/reducers/image-source';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { apiEndPoints } from 'app/utils/data/constants/api-end-points';

interface CreatePetitionImageUploadProps {
  formik: any;
}
const CreatePetitionImageUpload = ({ formik }: CreatePetitionImageUploadProps) => {
  const [localMultimedia, setLocalMultimedia] = useState<singleImageType[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [multiMedia, setMultiMedia] = useState<{ id: number }[]>([]);
  const [totalImages, setTotalImages] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const theme = useTheme();
  const { id } = useParams();
  const acceptingFiles = {
    'image/jpeg': [],
    'image/png': [],
    'image/jpg': [],
  };

  const onDrop = useCallback(
    (accFiles: File[], FileRejection: any) => {
      const acceptedFiles = accFiles.map(file => ({ file, errors: [] }));
      if (totalImages + acceptedFiles.length <= 10) {
        let valid = true;
        const imageFiles = acceptedFiles.filter(entry => entry.file.type.includes('image'));

        imageFiles.forEach(entry => {
          if (entry.file.size > 5 * 1024 * 1024) {
            valid = false;
            entry.errors.push('File size must be less than 5MB.');
          }

          const reader = new FileReader();
          reader.onload = e => {
            const img = new Image();
            img.onload = () => {
              const ratio = (img.width / img.height) * 100;
              console.log(ratio, 'RATIO=========');
              if (ratio < 30) {
                valid = false;
                entry.errors.push(`Image '${entry.file.name}' has an invalid aspect ratio.`);
              }
              if (valid) {
                setErrorMessage('');
                setFiles(acceptedFiles.filter(f => !f.errors.length).map(entry => entry.file));
                setTotalImages(totalImages + acceptedFiles.filter(f => !f.errors.length).length);
                setTotalSize(existingSize => acceptedFiles.reduce((acc, file) => acc + file.file.size, existingSize));
              } else {
                setErrorMessage(entry.errors.join('\n'));
              }
            };
            if (typeof e.target.result === 'string') {
              img.src = e.target.result;
            }
          };
          reader.readAsDataURL(entry.file);
        });

        if (!imageFiles.length && valid) {
          setErrorMessage('');
          setFiles(acceptedFiles.map(entry => entry.file));
          setTotalImages(totalImages + acceptedFiles.length);
          setTotalSize(existingSize => acceptedFiles.reduce((acc, file) => acc + file.file.size, existingSize));
        }
      } else {
        setErrorMessage('You can upload a maximum of 10 images.');
      }
    },
    [totalImages, setFiles, setTotalImages, setTotalSize]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptingFiles,
    multiple: true,
  });

  useEffect(() => {
    if (files !== undefined) {
      const sendFiles = async () => {
        try {
          for (const file of files) {
            const formData = new FormData();
            formData.append('file', file);
            const response = await axios.post<any>(apiEndPoints.imageUpload, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });
            if (response.status === 201) {
              setMultiMedia(state => {
                const newState = [...state, { id: response.data?.id }];
                return newState;
              });
              setTimeout(() => {
                setLocalMultimedia(state => [...state, response.data]);
              }, 200);
            }
          }
        } catch (error) {
          console.log(error);
        }
      };
      sendFiles();
    }
  }, [files]);

  useEffect(() => {
    if (formik.values.multimedia?.length <= 0) {
      formik.setFieldError('multimedia', 'Multimedia is required');
    }
  }, [formik.values.multimedia]);

  useEffect(() => {
    if (id && formik.values?.multimedia) {
      setLocalMultimedia(formik.values?.multimedia);
    }
  }, [id, formik.values.multimedia]);

  const handleImageClear = id => {
    const newLocalMultiMedia = [...localMultimedia];
    const deletedImageIndex = newLocalMultiMedia.findIndex(item => item.id === id);
    if (deletedImageIndex !== -1) {
      newLocalMultiMedia.splice(deletedImageIndex, 1);
      setLocalMultimedia(newLocalMultiMedia);
      const newMultiMedia = multiMedia.filter(media => media.id !== id);
      setMultiMedia(newMultiMedia);
      setTotalImages(newMultiMedia.length);
      if (newMultiMedia.length > 0) {
        setSelectedImageIndex(0);
      }
    }
  };

  useEffect(() => {
    formik.setFieldValue('multimedia', multiMedia);
  }, [multiMedia]);

  return (
    <>
      <Stack
        direction="column"
        gap={0.3}
        sx={{
          maxWidth: { xs: '100%' },
          borderRadius: '15px',
          border: formik.touched.multimedia && formik.values.multimedia?.length < 1 ? '0.5px solid red' : '0.5px solid #F6F7F8',
          backgroundColor: 'secondary.dark',
          p: { xs: 2, lg: 3 },
        }}
      >
        {localMultimedia?.length > 0 ? (
          <Stack direction="column" sx={{ width: '100%' }} spacing={1}>
            <Carousel
              dynamicHeight={true}
              showThumbs={false}
              showStatus={true}
              showArrows={true}
              showIndicators={false}
              useKeyboardArrows={true}
              selectedItem={selectedImageIndex}
              autoPlay={true}
              interval={2000}
            >
              {localMultimedia?.length > 0 &&
                localMultimedia?.map((item, index) => (
                  <Box sx={{ position: 'relative', width: '100%' }} key={index}>
                    <DeleteIcon
                      sx={{
                        color: 'error.main',
                        background: '#fafafa',
                        position: 'absolute',
                        top: 5,
                        right: 40,
                        cursor: 'pointer',
                        borderRadius: '40%',
                      }}
                      onClick={() => handleImageClear(item.id)}
                    />
                    <div>
                      <img
                        src={item.link ? item.link : item.url || 'content/images/no-img.jpg'}
                        alt={`slide${index + 1}`}
                        style={{ height: '130px', objectFit: 'cover' }}
                      />
                    </div>
                  </Box>
                ))}
            </Carousel>
            <Stack direction="row" alignItems={'center'} justifyContent={'flex-end'}>
              <div {...getRootProps()}>
                <input {...getInputProps()} name={'multimedia'} />
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'primary.light',
                    color: 'common.white',
                    '&:hover': {
                      color: 'common.white',
                      backgroundColor: 'primary.light',
                      boxShadow: 'none',
                    },
                  }}
                >
                  + Add more
                </Button>
              </div>
            </Stack>
          </Stack>
        ) : (
          <Box>
            <div {...getRootProps()}>
              <input {...getInputProps()} name={'multimedia'} />
              <Grid
                container
                direction={'row'}
                alignItems={'center'}
                height={130}
                justifyContent={'center'}
                sx={{ borderRadius: '15px', backgroundColor: theme.palette.mode === 'dark' ? '' : '#FFFF', cursor: 'pointer', px: 2 }}
              >
                <Stack direction={'row'} gap={2}>
                  <FileUploadOutlinedIcon />
                  <Typography
                    sx={{
                      color: theme.textColor.mainHeading,
                      fontSize: theme.p1.semiBold.fontSize,
                      fontWeight: theme.p1.semiBold.fontWeight,
                    }}
                  >
                    Drop your image files here, or <span style={{ color: 'primary.main' }}>browse</span>
                    <br></br>(Accepted file formats: JPG, JPEG, PNG)
                  </Typography>
                </Stack>
              </Grid>
            </div>
          </Box>
        )}
        {errorMessage && (
          <div style={{ margin: '10px 0px', fontFamily: 'Manrope', fontWeight: '400', fontSize: '12px', color: ' #E23A44' }}>
            {errorMessage}
          </div>
        )}
      </Stack>
    </>
  );
};

export default CreatePetitionImageUpload;
