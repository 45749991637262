import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import LocationMaster from './location-master';
import LocationMasterDetail from './location-master-detail';
import LocationMasterUpdate from './location-master-update';
import LocationMasterDeleteDialog from './location-master-delete-dialog';

const LocationMasterRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<LocationMaster />} />
    <Route path="new" element={<LocationMasterUpdate />} />
    <Route path=":id">
      <Route index element={<LocationMasterDetail />} />
      <Route path="edit" element={<LocationMasterUpdate />} />
      <Route path="delete" element={<LocationMasterDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default LocationMasterRoutes;
