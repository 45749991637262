// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.css-112h4uz-MuiFormGroup-root .left {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.example1::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example1 {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/common/alert-dialog/radio-dialog.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,OAAA;AACF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAEF;;AAAA;EACE,aAAA;AAGF;;AAAA,4CAAA;AACA;EACE,wBAAA,EAAA,gBAAA;EACA,gCAAA,EAAA,YAAA;AAGF","sourcesContent":[".css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  flex: 1;\n}\n.css-112h4uz-MuiFormGroup-root .left {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.example1::-webkit-scrollbar {\n  display: none;\n}\n\n/* Hide scrollbar for IE, Edge and Firefox */\n.example1 {\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none !important; /* Firefox */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
