import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MapCoordinatesState {
  latitude: number | null;
  longitude: number | null;
  place: string | null;
}

const initialState: MapCoordinatesState = {
  latitude: null,
  longitude: null,
  place: null,
};

const mapCoordinatesSlice = createSlice({
  name: 'mapCoordinates',
  initialState,
  reducers: {
    setCoordinates: (state, action: PayloadAction<{ latitude: number; longitude: number }>) => {
      state.latitude = action.payload.latitude;
      state.longitude = action.payload.longitude;
    },
    setPlace: (state, action: PayloadAction<string>) => {
      state.place = action.payload;
    },
    clearCoordinates: state => {
      state.latitude = null;
      state.longitude = null;
      state.place = null;
    },
  },
});

export const { setCoordinates, setPlace, clearCoordinates } = mapCoordinatesSlice.actions;

export default mapCoordinatesSlice.reducer;
