import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import CustomOccurence from './custom-occurence';
import CustomOccurenceDetail from './custom-occurence-detail';
import CustomOccurenceUpdate from './custom-occurence-update';
import CustomOccurenceDeleteDialog from './custom-occurence-delete-dialog';

const CustomOccurenceRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<CustomOccurence />} />
    <Route path="new" element={<CustomOccurenceUpdate />} />
    <Route path=":id">
      <Route index element={<CustomOccurenceDetail />} />
      <Route path="edit" element={<CustomOccurenceUpdate />} />
      <Route path="delete" element={<CustomOccurenceDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default CustomOccurenceRoutes;
