import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { profileFilterType } from '../profile';
export interface ActivityDraftStat {
  loading: boolean;
  error: string | null;
  success: boolean | null;
  count: number;
}

const initialState = {
  draftedItems: null,
  draftLoading: false,
  draftSuccess: false,
  draftError: null,
  draftCount: null,
};

export const sendActivityDraft = createAsyncThunk<any, any, { rejectValue: string }>(
  'draft/sendActivityDraft',
  async (draftData, thunkAPI) => {
    try {
      const response = await axios.post('/api/activity/draft', draftData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch drafted items');
    }
  }
);

export const fetchDraftedItems = createAsyncThunk<any, profileFilterType, { rejectValue: string }>(
  'profile/fetchDraftedItems',
  async (requestData, thunkAPI) => {
    try {
      let response;
      if (localStorage.getItem('jhi-authenticationToken')) {
        response = await axios.post('/api/profile/filter', requestData);
      } else {
        response = await axios.post('/api/unauthorized/profile/filter', requestData);
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch profile details');
    }
  }
);

export const discardAllDrafts = createAsyncThunk<any, any, { rejectValue: string }>('draft/discardAllDrafts', async (drafts, thunkAPI) => {
  try {
    const response = await axios.delete('/api/profile', drafts);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to discard drafts');
  }
});

const draftSlice = createSlice({
  name: 'draftReducer',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchDraftedItems.pending, state => {
        state.draftLoading = true;
        state.draftSuccess = false;
        state.draftError = null;
      })
      .addCase(fetchDraftedItems.fulfilled, (state, action) => {
        state.draftLoading = false;
        state.draftSuccess = true;
        state.draftError = null;
        state.draftedItems = action.payload;
        state.draftCount = action.payload;
      })
      .addCase(fetchDraftedItems.rejected, (state, action) => {
        state.draftLoading = false;
        state.draftSuccess = false;
        state.draftError = action.payload;
      })
      .addCase(sendActivityDraft.pending, state => {
        state.draftLoading = true;
        state.draftSuccess = false;
        state.draftError = null;
      })
      .addCase(sendActivityDraft.fulfilled, (state, action) => {
        state.draftLoading = false;
        state.draftSuccess = true;
        state.draftError = null;
      })
      .addCase(sendActivityDraft.rejected, (state, action) => {
        state.draftLoading = false;
        state.draftSuccess = false;
        state.draftError = action.payload;
      });
  },
});

export default draftSlice.reducer;
