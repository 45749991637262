import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, useMediaQuery, createTheme, useTheme, ThemeProvider } from '@mui/material';
import CustomTabPanel from 'app/components/common/tabs/custom-tab-panel';
import Individuals from './individuals';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { fetchUserOrganizations } from 'app/shared/reducers/organization';
import {
  fetchGoalCountsForOrg,
  fetchOrgLatestActivityShareCounts,
  fetchOrgLatestAndPreviousActivityCounts,
} from 'app/shared/reducers/dashboard/dashboard';
import Organization from './organization';
import { truncateString } from 'app/utils/common-utils/common-utils';

const TabSwitcher = () => {
  const [value, setValue] = useState(0);
  const [selectedOrgId, setSelectedOrgId] = useState(null);

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const dispatch = useAppDispatch();
  const myProfileId = useAppSelector(state => state.authentication.account.id);
  const userOwnedOrganizations = useAppSelector(state => state.organizationReducer.userOwnedOrganization) || [];

  useEffect(() => {
    if (myProfileId) dispatch(fetchUserOrganizations(myProfileId));
  }, [myProfileId]);

  useEffect(() => {
    if (selectedOrgId) {
      dispatch(fetchOrgLatestActivityShareCounts(selectedOrgId));
      dispatch(fetchOrgLatestAndPreviousActivityCounts(selectedOrgId));
      dispatch(fetchGoalCountsForOrg(selectedOrgId));
    }
  }, [selectedOrgId]);

  const handleChange = (event: React.SyntheticEvent, newValue: number, orgId?: any) => {
    setValue(newValue);
    if (newValue > 0 && orgId) {
      setSelectedOrgId(Number(orgId));
    } else {
      setSelectedOrgId(null);
    }
  };

  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1030));
  const marginRightVW = isSmallScreen ? 4 : (16 / window.innerWidth) * 100;
  const customTheme = createTheme({
    ...theme,
    palette: {
      primary: {
        main: '#FFCE00',
      },
    },
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            minWidth: isSmallScreen ? 'auto' : '4rem',
            fontSize: isSmallScreen ? '0.675rem' : '0.9rem',
            marginRight: isSmallScreen ? `${marginRightVW}vw` : '',
            padding: isSmallScreen ? '0px' : '0px',
            '&.Mui-selected': {
              fontWeight: '600',
              color: '#000',
            },
          },
        },
      },
    },
  });

  const tabStyle = {
    textTransform: 'capitalize',
    color: theme.profile.color,
    fontWeight: 600,
    fontSize: '14px',
    minHeight: '50px',
    alignItems: 'flex-start',
  };

  return (
    <Box className="dashboard-tabs">
      <ThemeProvider theme={customTheme}>
        <Tabs
          value={value}
          onChange={(event, newValue) => handleChange(event, newValue, newValue > 0 ? userOwnedOrganizations[newValue - 1]?.id : null)}
          aria-label="tab switcher"
          textColor="primary"
          indicatorColor="primary"
          variant="scrollable"
          sx={{
            '.MuiTabs-flexContainer': {
              gap: '38px',
              borderBottom: '1px solid #bebebf',
              height: '42px',
            },
            '.MuiTabs-scrollButtons': {
              display: 'none',
            },
          }}
        >
          <Tab style={{ color: isDarkMode ? '#fff' : '#000' }} label="Individual" />
          {userOwnedOrganizations
            ?.filter(item => !item.deleted)
            .map(org => (
              <Tab style={{ color: isDarkMode ? '#fff' : '#000' }} title={org.label} key={org.id} label={truncateString(org.label, 30)} />
            ))}
        </Tabs>
      </ThemeProvider>
      <div
        style={{
          overflowY: 'scroll',
          overflow: 'auto',
        }}
      >
        <Box sx={{ width: { xs: '100%', md: '100%' } }}>
          <CustomTabPanel value={value} index={0}>
            <Individuals />
          </CustomTabPanel>
          {userOwnedOrganizations.map((org, index) => (
            <CustomTabPanel key={org.id} value={value} index={index + 1}>
              <Organization />
            </CustomTabPanel>
          ))}
        </Box>
      </div>
    </Box>
  );
};

export default TabSwitcher;
