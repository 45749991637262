import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export interface LocationType {
  id: number;
  name: string;
  value: string;
}

export interface LocationsState {
  locations: Location[];
  loading: boolean;
  error: string | null;
}

export const initialState: LocationsState = {
  locations: [],
  loading: false,
  error: null,
};

export const fetchLocations = createAsyncThunk<Location[], void, { rejectValue: string }>('locationMaster/fetchLocations', async () => {
  try {
    const response = await axios.get<Location[]>('api/location-masters');
    return response.data;
  } catch (error) {
    throw error.response?.data?.message || 'An error occurred';
  }
});

export const locationSlice = createSlice({
  name: 'locationMaster',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchLocations.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLocations.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.locations = action.payload;
      })
      .addCase(fetchLocations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'An error occurred';
      });
  },
});

export default locationSlice.reducer;
