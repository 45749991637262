import { Box, Link, List, ListItem, styled, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';

const RelevantLinks = () => {

     const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
    const StyledList = styled(List)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column', // Change to column for better vertical alignment
    padding: 0,
    margin: 0,
  }));

  const StyledListItem = styled(ListItem)(({ theme }) => ({
    padding: 0,
  }));

  const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: '#4351f1',
    '&:hover': {
      textDecoration: 'underline',
    },
  }));
  const navigate = useNavigate();
  return (
    <Box py={2}>
              <Typography variant="body1" paragraph>
                <strong>Other Relevant Links :</strong>
              </Typography>

              <StyledList sx={{ display: 'flex', flexDirection: 'row' }} className="mob-community">
                <StyledListItem>
                  <StyledLink sx={{ color: isDarkMode ? '#FFCE00;' : '#674bff' }} onClick={() => navigate('/community-guidelines')}>
                    Community Guidelines
                  </StyledLink>
                </StyledListItem>
                <StyledListItem>
                  <StyledLink sx={{ color: isDarkMode ? '#FFCE00;' : '#674bff' }} onClick={() => navigate('/privacy-policy')}>
                    Privacy Policy
                  </StyledLink>
                </StyledListItem>
                <StyledListItem>
                  <StyledLink sx={{ color: isDarkMode ? '#FFCE00;' : '#674bff' }} onClick={() => navigate('/cookie-policy')}>
                    Cookie Policy
                  </StyledLink>
                </StyledListItem>
                <StyledListItem>
                  <StyledLink sx={{ color: isDarkMode ? '#FFCE00;' : '#674bff' }} onClick={() => navigate('/legal-notices')}>
                    Legal Notices
                  </StyledLink>
                </StyledListItem>
                <StyledListItem>
                  <StyledLink sx={{ color: isDarkMode ? '#FFCE00;' : '#674bff' }} onClick={() => navigate('/terms-and-condition')}>
                    Terms and Conditions
                  </StyledLink>
                </StyledListItem>
              </StyledList>
            </Box>
  )
}

export default RelevantLinks