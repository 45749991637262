import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import MessageTable from './message-table';
import MessageTableDetail from './message-table-detail';
import MessageTableUpdate from './message-table-update';
import MessageTableDeleteDialog from './message-table-delete-dialog';

const MessageTableRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<MessageTable />} />
    <Route path="new" element={<MessageTableUpdate />} />
    <Route path=":id">
      <Route index element={<MessageTableDetail />} />
      <Route path="edit" element={<MessageTableUpdate />} />
      <Route path="delete" element={<MessageTableDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default MessageTableRoutes;
