import React from 'react';
import { Avatar, Box, Grid, Typography, Button, useTheme } from '@mui/material';
import './block-cards.scss';
interface BlockCardsProps {
  item: any;
  handleButtonClick: (id: any) => void;
}
const BlockCards = ({ item, handleButtonClick }: BlockCardsProps) => {
  const theme = useTheme();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className="org-invitation-pt-0 ">
        <Grid container spacing={2} p={1} m={0} alignItems={'center'}>
          <Grid item xs={2} md={2} className="org-invitation-avatar pl-0 ">
            <Avatar alt={item?.userName} src={item?.profilePic} />
          </Grid>
          <Grid item className="mr-block" xs={6} mr={3} md={6} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Box>
              <Typography variant="subtitle2">{item?.userName}</Typography>
              <Typography variant="caption">{item?.email}</Typography>
            </Box>
          </Grid>
          <Grid item xs={4} md={4} className="org-invitation-button-mob">
            <Box textAlign={'end'}>
              <Button
                onClick={() => handleButtonClick(item?.userId)}
                className="org-invitation-btn"
                disableElevation
                disableRipple
                variant="contained"
                size="small"
                sx={{
                  color: '#F6F7F8',
                  textTransform: 'none',
                  borderRadius: 1.875,
                  py: 0.5,
                  px: 3.25,
                  borderColor: 'primary.light',
                  bgcolor: 'primary.light',
                  fontSize: theme.infoText.regular.fontSize,
                  fontWeight: theme.infoText.regular.fontWeight,
                  fontStyle: theme.infoText.regular.fontStyle,
                  width: '100px',
                  cursor: 'pointer',
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: 'primary.light',
                  },
                }}
              >
                Unblock
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BlockCards;
