import axios from 'axios';
import { createAsyncThunk, createSlice, isPending, isRejected } from '@reduxjs/toolkit';

import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';

const initialState = {
  loading: false,
  resetPasswordSuccess: false,
  resetPasswordFailure: false,
  successMessage: null,
  errorMessage: null,
  error: null,
};

export type PasswordResetState = Readonly<typeof initialState>;

const apiUrl = 'api/account/reset-password';
// Actions

export const handlePasswordResetInit = createAsyncThunk(
  'passwordReset/reset_password_init',
  async (email: string) => axios.post(`${apiUrl}/init`, { email }, { headers: { ['Content-Type']: 'application/json' } }),
  { serializeError: serializeAxiosError }
);

export const handlePasswordResetFinish = createAsyncThunk(
  'passwordReset/reset_password_finish',
  async (data: { key: string; newPassword: string }) => axios.post(`${apiUrl}/finish`, data),
  { serializeError: serializeAxiosError }
);

export const PasswordResetSlice = createSlice({
  name: 'passwordReset',
  initialState: initialState as PasswordResetState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(handlePasswordResetInit.fulfilled, (state, action) => ({
        ...initialState,
        loading: false,
        resetPasswordSuccess: true,
        successMessage: action.payload || 'Check your emails for details on how to reset your password.',
        errorMessage: null,
      }))
      .addCase(handlePasswordResetInit.rejected, (state, action) => ({
        ...initialState,
        loading: false,
        errorMessage: action.payload,
      }))
      .addCase(handlePasswordResetFinish.fulfilled, (state, action) => ({
        ...initialState,
        loading: false,
        resetPasswordSuccess: true,
        successMessage: action.payload ?? 'Password has been reset Successfully.',
      }))
      .addMatcher(isPending(handlePasswordResetInit, handlePasswordResetFinish), state => {
        state.loading = true;
      })
      .addMatcher(isRejected(handlePasswordResetInit, handlePasswordResetFinish), (state, action) => ({
        ...initialState,
        loading: false,
        resetPasswordFailure: true,
        successMessage: null,
        errorMessage: action.error.message ?? 'The current e-mail address is not registered with us. Please try again',
        error: action?.error,
      }));
  },
});

export const { reset } = PasswordResetSlice.actions;

// Reducer
export default PasswordResetSlice.reducer;
