import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from '@mui/material';
import { useAppDispatch } from 'app/config/store';
import { deleteOrganization, setSelectedOrgId } from 'app/shared/reducers/settings/settings-organization';
import { fetchUserGrantedOrganizations } from 'app/shared/reducers/organization';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoaderIcon } from 'react-hot-toast';

type Props = {
  open: boolean;
  setAlertOpen: Dispatch<SetStateAction<boolean>>;
  title?: string;
  description?: string;
  agreeAction: () => void;
  okayButtonText?: string;
  closeButtonText?: string;
  paymentLoading?: boolean;
};

export default function AlertDialog({
  title,
  description,
  open,
  setAlertOpen,
  agreeAction,
  okayButtonText,
  closeButtonText,
  paymentLoading,
}: Props) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const handleClose = (event: React.MouseEvent, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') {
      return;
    }
    setAlertOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"  sx={{
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        borderRadius: '15px', // Set the border radius here
      },
    },
  }}>
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ fontWeight: '400', fontSize: '1 rem', color: isDarkMode ? '#FFFFFF' : '#000' }}
          >
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ paddingRight: '24px;',paddingBottom:"20px" }}>
          {closeButtonText && (
            <Button
              onClick={() => setAlertOpen(false)}
               sx={{
            color: isDarkMode ? '#FFCE00' : '#674BFF',
 
            '&:hover': {
              color: isDarkMode ? '#FFCE00' : '#674BFF',
            },
          }}
            >
              {closeButtonText}
            </Button>
          )}
          <Button
            onClick={agreeAction}
            autoFocus
            endIcon={paymentLoading ? <LoaderIcon color="primary.light" /> : null}
            disabled={paymentLoading}
           sx={{
            backgroundColor: isDarkMode ? '#FFCE00' : '#674BFF',
          color: isDarkMode ? "black":'#ffff',
            '&:hover': {
              backgroundColor: isDarkMode ? '#FFCE00' : '#674BFF',
             
            },
          }}
          variant="contained"
          >
            {okayButtonText ? okayButtonText : 'Proceed'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
