import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, TextFormat, getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, SORT } from 'app/shared/util/pagination.constants';
import { overrideSortStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities } from './activity.reducer';

export const Activity = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(overrideSortStateWithQueryParams(getSortState(location, 'id'), location.search));

  const activityList = useAppSelector(state => state.activity.entities);
  const loading = useAppSelector(state => state.activity.loading);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `${sortState.sort},${sortState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = p => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <h2 id="activity-heading" data-cy="ActivityHeading">
        <Translate contentKey="reachamyApp.activity.home.title">Activities</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="reachamyApp.activity.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/activity/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="reachamyApp.activity.home.createLabel">Create new Activity</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {activityList && activityList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="reachamyApp.activity.id">ID</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th className="hand" onClick={sort('activityNumber')}>
                  <Translate contentKey="reachamyApp.activity.activityNumber">Activity Number</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('activityNumber')} />
                </th>
                <th className="hand" onClick={sort('categoryID')}>
                  <Translate contentKey="reachamyApp.activity.categoryID">Category ID</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('categoryID')} />
                </th>
                <th className="hand" onClick={sort('header')}>
                  <Translate contentKey="reachamyApp.activity.header">Header</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('header')} />
                </th>
                <th className="hand" onClick={sort('details')}>
                  <Translate contentKey="reachamyApp.activity.details">Details</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('details')} />
                </th>
                <th className="hand" onClick={sort('ageLimit')}>
                  <Translate contentKey="reachamyApp.activity.ageLimit">Age Limit</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('ageLimit')} />
                </th>
                <th className="hand" onClick={sort('planningForMicrofunding')}>
                  <Translate contentKey="reachamyApp.activity.planningForMicrofunding">Planning For Microfunding</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('planningForMicrofunding')} />
                </th>
                <th className="hand" onClick={sort('amountToRaise')}>
                  <Translate contentKey="reachamyApp.activity.amountToRaise">Amount To Raise</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('amountToRaise')} />
                </th>
                <th className="hand" onClick={sort('postAsOrganisation')}>
                  <Translate contentKey="reachamyApp.activity.postAsOrganisation">Post As Organisation</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('postAsOrganisation')} />
                </th>
                <th className="hand" onClick={sort('makeAsUrgentNeed')}>
                  <Translate contentKey="reachamyApp.activity.makeAsUrgentNeed">Make As Urgent Need</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('makeAsUrgentNeed')} />
                </th>
                <th className="hand" onClick={sort('makeThisPrivateActivity')}>
                  <Translate contentKey="reachamyApp.activity.makeThisPrivateActivity">Make This Private Activity</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('makeThisPrivateActivity')} />
                </th>
                <th className="hand" onClick={sort('startDate')}>
                  <Translate contentKey="reachamyApp.activity.startDate">Start Date</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('startDate')} />
                </th>
                <th className="hand" onClick={sort('createdBy')}>
                  <Translate contentKey="reachamyApp.activity.createdBy">Created By</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('createdBy')} />
                </th>
                <th className="hand" onClick={sort('createdAt')}>
                  <Translate contentKey="reachamyApp.activity.createdAt">Created At</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('createdAt')} />
                </th>
                <th className="hand" onClick={sort('modifiedBy')}>
                  <Translate contentKey="reachamyApp.activity.modifiedBy">Modified By</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('modifiedBy')} />
                </th>
                <th className="hand" onClick={sort('modifiedAt')}>
                  <Translate contentKey="reachamyApp.activity.modifiedAt">Modified At</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('modifiedAt')} />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {activityList.map((activity, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/activity/${activity.id}`} color="link" size="sm">
                      {activity.id}
                    </Button>
                  </td>
                  <td>{activity.activityNumber}</td>
                  <td>{activity.categoryID}</td>
                  <td>{activity.header}</td>
                  <td>{activity.details}</td>
                  <td>{activity.ageLimit}</td>
                  <td>{activity.planningForMicrofunding ? 'true' : 'false'}</td>
                  <td>{activity.amountToRaise}</td>
                  <td>{activity.postAsOrganisation ? 'true' : 'false'}</td>
                  <td>{activity.makeAsUrgentNeed ? 'true' : 'false'}</td>
                  <td>{activity.makeThisPrivateActivity ? 'true' : 'false'}</td>
                  <td>{activity.startDate ? <TextFormat type="date" value={activity.startDate} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{activity.createdBy}</td>
                  <td>{activity.createdAt ? <TextFormat type="date" value={activity.createdAt} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{activity.modifiedBy}</td>
                  <td>{activity.modifiedAt ? <TextFormat type="date" value={activity.modifiedAt} format={APP_DATE_FORMAT} /> : null}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/activity/${activity.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button tag={Link} to={`/activity/${activity.id}/edit`} color="primary" size="sm" data-cy="entityEditButton">
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button tag={Link} to={`/activity/${activity.id}/delete`} color="danger" size="sm" data-cy="entityDeleteButton">
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="reachamyApp.activity.home.notFound">No Activities found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Activity;
