import React from 'react';
import { apiKeys } from 'app/utils/data/constants/api-keys';

interface StaticGmapProps {
  lat: number | string;
  lng: number | string;
  height?: number | string;
  width?: number | string;
}

const StaticGMapImage = ({ lat, lng, height = '164px', width = '100%' }: StaticGmapProps) => {
  return (
    <img
      src={`https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=13&size=300x164&maptype=roadmap
                &markers=color:red%7C${lat},${lng}&key=${apiKeys.GMAP_API_Key}`}
      alt={`location of ${lat},${lng}`}
      height={height}
      width={width}
    />
  );
};

export default StaticGMapImage;
