import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

type ShareCount = {
  date: string;
  count: number;
};

type ShareDataItem = {
  media: string;
  startDate: string;
  endDate: string;
  shareCount: ShareCount[];
};

type Props = {
  shareData: ShareDataItem[];
};

export default function LineGraph({ shareData }: Props) {
  const [labels, setLabels] = useState<string[]>([]);
  const [whatsAppData, setWhatsAppData] = useState<number[]>([]);
  const [facebookData, setFacebookData] = useState<number[]>([]);
  const [twitterData, setTwitterData] = useState<number[]>([]);
  const [linkedinData, setLinkedInData] = useState<number[]>([]);

  useEffect(() => {
    if (shareData && shareData.length > 0) {
      const dateFormat: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric' };

      const startDate = new Date(shareData[0].startDate);
      const endDate = new Date(shareData[0].endDate);

      let currentDate = startDate;
      const newLabels = [];
      while (currentDate <= endDate) {
        newLabels.push(currentDate.toLocaleDateString('en-GB', dateFormat).replace(/ /g, '-'));
        currentDate.setDate(currentDate.getDate() + 1);
        currentDate = new Date(currentDate);
      }

      setLabels(newLabels);

      const dataMapping = (mediaType: string) => {
        const mediaData = shareData.find(item => item.media === mediaType)?.shareCount || [];
        return newLabels.map(label => {
          const dataPoint = mediaData.find(data => {
            const dataDate = new Date(data.date.split('-').reverse().join('-'));

            const formattedDate = dataDate.toLocaleDateString('en-GB', dateFormat).replace(/ /g, '-');
            return label === formattedDate;
          });
          return dataPoint ? dataPoint.count : 0;
        });
      };

      setWhatsAppData(dataMapping('WhatsApp'));
      setFacebookData(dataMapping('Facebook'));
      setTwitterData(dataMapping('X'));
      setLinkedInData(dataMapping('LinkedIn'));
    }
  }, [shareData]);

  const data = {
    labels,
    datasets: [
      {
        label: 'Facebook',
        data: facebookData,
        borderColor: '#0b62f7',
        backgroundColor: '#0b62f7',
        tension: 0.4,
        borderWidth: 1,
        pointHoverRadius: 4,
        pointRadius: 0,
        hitRadius: 10,
      },
      {
        label: 'WhatsApp',
        data: whatsAppData,
        borderColor: '#40be4f',
        backgroundColor: '#40be4f',
        tension: 0.4,
        borderWidth: 1,
        pointHoverRadius: 4,
        pointRadius: 0,
        hitRadius: 10,
      },
      {
        label: 'Twitter (X)',
        data: twitterData,
        borderColor: 'rgb(112 112 112)',
        backgroundColor: 'rgb(112 112 112)',
        tension: 0.4,
        borderWidth: 1,
        pointHoverRadius: 4,
        pointRadius: 0,
        hitRadius: 10,
      },
      {
        label: 'LinkedIn',
        data: linkedinData,
        borderColor: '#ff8f00',
        backgroundColor: '#ff8f00',
        tension: 0.4,
        borderWidth: 1,
        pointHoverRadius: 4,
        pointRadius: 0,
        hitRadius: 10,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        mode: 'nearest' as const,
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat('en-US').format(context.parsed.y);
            }
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          drawBorder: 'transparent',
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: true,
          drawBorder: 'transparent',
        },
        ticks: {
          stepSize: 1,
          callback: function (value) {
            if (value % 1 === 0) {
              return value;
            }
          },
        },
      },
    },
  };

  return <Line options={options} data={data} />;
}
