import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import UserPreferences from './user-preferences';
import UserPreferencesDetail from './user-preferences-detail';
import UserPreferencesUpdate from './user-preferences-update';
import UserPreferencesDeleteDialog from './user-preferences-delete-dialog';

const UserPreferencesRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<UserPreferences />} />
    <Route path="new" element={<UserPreferencesUpdate />} />
    <Route path=":id">
      <Route index element={<UserPreferencesDetail />} />
      <Route path="edit" element={<UserPreferencesUpdate />} />
      <Route path="delete" element={<UserPreferencesDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default UserPreferencesRoutes;
