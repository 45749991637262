import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

interface EventRequest {
    eventId: number;
    eventType: string;
    message: string;
    replyToMessageId: number
}

export interface chatMessageState {
    chatResponse: any | null;
    loading: boolean;
    error: string | null;
    success: boolean | null;
}

export const initialState: chatMessageState = {
    chatResponse: null,
    loading: false,
    error: null,
    success: false,
};


export const sentMessage = createAsyncThunk<any, EventRequest, { rejectValue: string }>(
    '/api/chats',
    async (eventRequest, { rejectWithValue }) => {
        try {
            const axiosResponse = await axios.post<any>('/api/chats', eventRequest);
            return axiosResponse.data;
        } catch (error) {
            throw rejectWithValue(error.response?.data?.message || 'An error occurred');
        }
    }
);


export const chatSentSlice = createSlice({
    name: 'chatSentReducer',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(sentMessage.pending, state => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(sentMessage.fulfilled, (state, action) => {
                state.chatResponse = action.payload
                state.loading = false;
                state.error = null;
                state.success = true;
            })
            .addCase(sentMessage.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'An error occurred';
                state.success = false;
            })
    },
});

export default chatSentSlice.reducer;
