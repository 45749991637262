// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 600px) {
  .forgetpassword-mrg {
    width: 100% !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/ui/auth/forgot-password/forgot-password.scss"],"names":[],"mappings":"AAAA;EACE;IACE,sBAAA;EACF;AACF","sourcesContent":["@media only screen and (max-width: 600px) {\n  .forgetpassword-mrg {\n    width: 100% !important;\n   \n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
