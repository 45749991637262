// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrol::-webkit-scrollbar {
  display: block;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrol {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: thin; /* Firefox */
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/ui/activity-chat-sidebar/activity-chat-sidebar.scss"],"names":[],"mappings":"AAAA,gDAAA;AACA;EACE,cAAA;AACF;;AAEA,4CAAA;AACA;EACE,wBAAA,EAAA,gBAAA;EACA,qBAAA,EAAA,YAAA;AACF","sourcesContent":["/* Hide scrollbar for Chrome, Safari and Opera */\n.hide-scrol::-webkit-scrollbar {\n  display: block;\n}\n\n/* Hide scrollbar for IE, Edge and Firefox */\n.hide-scrol {\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: thin; /* Firefox */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
