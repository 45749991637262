import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const MySelfIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15.158" viewBox="0 0 360.023 837.323">
    <g id="SVGRepo_iconCarrier" data-name="SVGRepo iconCarrier" transform="translate(-359.999 -121.537)">
      <path
        id="Path_4824"
        data-name="Path 4824"
        d="M485.809,350.281a120,120,0,0,0,120.851-9.3h.06a120.034,120.034,0,1,0-120.911,9.3Zm-7.4-113.54a60,60,0,0,1,94.948-45.4v.06a59.976,59.976,0,1,1-94.948,45.341Z"
        fill="#676e7b"
      />
      <path
        id="Path_4825"
        data-name="Path 4825"
        d="M720,685.44V494.76a108,108,0,0,0-30.6-75.48v.06a105.9,105.9,0,0,0-75-31.38H465.6a105.9,105.9,0,0,0-74.4,31.38A108.518,108.518,0,0,0,360,494.82V685.5a63.6,63.6,0,0,0,17.4,44.04A60,60,0,0,0,420,749.46V888.84a68.774,68.774,0,0,0,60,70.02H600a69,69,0,0,0,60-70.02V749.4a63.842,63.842,0,0,0,60-63.96Zm-60,3H600v200.4a12.12,12.12,0,0,1-3.6,8.4,10.2,10.2,0,0,1-8.4,3.6H491.4a12.6,12.6,0,0,1-12-12V688.44H420V494.76a46.743,46.743,0,0,1,13.2-32.58,47.879,47.879,0,0,1,32.4-13.92H614.4a46.68,46.68,0,0,1,42.3,28.8,44.888,44.888,0,0,1,3.3,17.7Z"
        fill="#676e7b"
      />
    </g>
  </svg>,
  'MySelfIcon'
);
export default MySelfIcon;
