import React, { useEffect } from 'react';

import { Dialog, DialogActions, Button, Typography, TextField, FormControl, Select, MenuItem, Box, useTheme } from '@mui/material';
import activityReport, { ReportActivity } from 'app/shared/reducers/activity/activity-report';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import toast from 'react-hot-toast';
import { Close } from '@mui/icons-material';
import { appConstants } from 'app/utils/data/constants/constants';
import { fetchBookMarkedItems } from 'app/shared/reducers/profile';
import { filterActivities } from 'app/shared/reducers/activity-filter';

const ReportActivityDialog = ({ open, onClose, handleData }) => {
  const [reportReason, setReportReason] = React.useState('');
  const [selectedOption, setSelectedOption] = React.useState('');
  const reportingReasons = useAppSelector(state => state.reportActivityReducer.reportingReasons);
  const myProfileId = useAppSelector(state => state.authentication.account.id);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const handleReport = async () => {
    try {
      const postResponse = await dispatch(
        ReportActivity({
          activityId: handleData?.id,
          message: reportReason ? reportReason : '',
        })
      );
      handleOKClick(postResponse.payload);
      if (ReportActivity.fulfilled.match(postResponse)) {
        // Successfully completed action
        const payload = postResponse?.payload;
        handleOKClick(payload);
        toast.success(payload ?? 'Reported successfullly');
        setReportReason('');
        setSelectedOption('');
      } else if (ReportActivity.rejected.match(postResponse)) {
        // Action was rejected
        const error = postResponse.error;
        toast.error(error.message || 'Something went wrong');
        setReportReason('');
        setSelectedOption('');
      }

      onClose();
    } catch (error) {
      toast.error('Something went wrong');
      setSelectedOption('');
    }
  };

  const handleCancel = () => {
    setReportReason('');
    setSelectedOption('');
    onClose();
  };

  const handleOKClick = payload => {
    onClose(payload);
  };
  useEffect(() => {
    if (setSelectedOption?.length) {
      setReportReason(selectedOption);
    }
  }, [selectedOption]);

  useEffect(() => {
    return () => {
      setSelectedOption('');
    };
  }, []);

  const showTextField = selectedOption == appConstants?.reportingReasonEnd;
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '15px',
          padding: '0.5%',
        },
      }}
    >
      <Box sx={{ padding: 2 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            cursor: 'pointer',
            paddingTop: '1%',
            paddingRight: '2%',
            paddingBottom: '2%',
          }}
        >
          <Close onClick={handleCancel} />
        </Box>
        <Typography variant="body1">Please select a reason for reporting the activity.</Typography>
        <FormControl fullWidth variant="outlined" sx={{ marginTop: '16px' }}>
          <Select
            value={selectedOption}
            onChange={e => {
              setSelectedOption(e.target.value);
            }}
            size="small"
            displayEmpty
            MenuProps={{
              PaperProps: {
                style: {
                  overflowY: 'scroll',
                },
              },
              MenuListProps: {
                style: {
                  maxHeight: '200px',
                },
              },
            }}
          >
            <MenuItem value="">Select a reason</MenuItem>
            {reportingReasons?.map((data: any) => (
              <MenuItem value={data.label} key={data.id}>
                <Typography sx={{ fontSize: '16px' }}>{data.label}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {showTextField && (
          <TextField
            label="Details (Optional)"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            // value={reportReason}
            onChange={e => setReportReason(e.target.value)}
            sx={{ marginTop: 2 }}
          />
        )}
      </Box>
      <DialogActions>
        <Button onClick={handleCancel} sx={{
            color: isDarkMode ? '#FFCE00' : '#674BFF',
 
            '&:hover': {
              color: isDarkMode ? '#FFCE00' : '#674BFF',
            },
          }}>
          Cancel
        </Button>
        <Button onClick={handleReport} disabled={!selectedOption} sx={{
            backgroundColor: isDarkMode ? '#FFCE00' : '#674BFF',
          color: isDarkMode ? "black":'#ffff',
            '&:hover': {
              backgroundColor: isDarkMode ? '#FFCE00' : '#674BFF',
             
            },
          }}
          variant="contained">
          Report
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportActivityDialog;
