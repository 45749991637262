import { Box, Button, Chip, Grid, Typography } from '@mui/material';
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';

import './settings-org-doc.scss';
import { apiEndPoints } from 'app/utils/data/constants/api-end-points';
import axios from 'axios';

type Props = {
  documents?: any[];
  handleDetailsChange: (fieldName: any, newValue: any) => void;
  hasPermission: boolean;
  updateDocIds: (newDocIds: any) => void;
  setIsUpdateEnabled: Dispatch<SetStateAction<boolean>>;
};
const SettingsOrgdoc = ({ documents, hasPermission, updateDocIds, setIsUpdateEnabled }: Props) => {
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const [docs, setDocs] = useState([]);
  const [docIds, setDocIds] = useState([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    sendFile(event.target.files);
  };

  const handleFileDelete = (idToDelete: string) => {
    const filteredDocs = docs.filter(file => file.id !== idToDelete);
    setDocs(filteredDocs);

    const idArray = filteredDocs.filter(item => item.id !== idToDelete);
    updateDocIds(idArray);
    setIsUpdateEnabled(true);
  };

  function getNameAfterLastUnderscore(url) {
    const parts = url.split('_');
    const name = parts[parts.length - 1];

    return name;
  }

  useEffect(() => {
    if (documents?.length > 0) {
      const newArray = documents.map(document => {
        const match = document?.url.match(/_(.+)/);
        const name = match ? match[1] : document?.url;

        return {
          name: name,
          id: document.id,
        };
      });

      setDocs(newArray);

      const idArray = documents.map(item => ({ id: item.id }));
      setDocIds(idArray);
    }
  }, [documents]);

  const sendFile = async (files: FileList) => {
    try {
      let endpoint = '';
      const formData = new FormData();

      endpoint = apiEndPoints.imageUpload;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        formData.append('file', file);
      }

      const response = await axios.post<any>(endpoint, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (response.status === 201) {
        const newDocs = [...docs, { id: response.data.id, name: getNameAfterLastUnderscore(response.data.url) }];
        const idArray = newDocs.map(item => ({ id: item.id }));
        updateDocIds(idArray);
        setDocs(newDocs);
        setIsUpdateEnabled(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (docIds.length > 0) updateDocIds(docIds);
  }, [docIds]);

  return (
    <Grid container spacing={2} sx={{ alignItems: 'center' }}>
      <Grid item xs={12} md={4}>
        <Typography variant="h5" sx={{ fontSize: '14px' }}>
          Documents
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <Box>
          <Button
            disabled={!hasPermission}
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
            sx={{
              bgcolor: 'primary.light',
              color: '#F6F7F8',
              width: '100%',
              '&.MuiButtonBase-root:hover': {
                bgcolor: 'primary.light',
                boxShadow: 'none',
              },
            }}
          >
            {docs.length > 0 ? 'Add more files' : 'Upload file'}
            <input
              type="file"
              accept=".pdf"
              onChange={handleFileChange}
              ref={fileInputRef}
              style={{
                clip: 'rect(0 0 0 0)',
                clipPath: 'inset(50%)',
                height: 1,
                overflow: 'hidden',
                position: 'absolute',
                bottom: 0,
                left: 0,
                whiteSpace: 'nowrap',
                width: 1,
              }}
            />
          </Button>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: 1 }}>
            {docs.length > 0 &&
              docs.map((doc, index) => (
                <Chip
                  key={index}
                  label={doc.name}
                  deleteIcon={<DeleteIcon />}
                  onDelete={() => handleFileDelete(doc.id)}
                  disabled={!hasPermission}
                />
              ))}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SettingsOrgdoc;
