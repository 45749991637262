import { Button, Grid, useTheme } from '@mui/material';
import SomethingWentWrong from 'app/components/icons/something-went-wrong';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ProfileLockedPage = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    // useEffect(() => {
    //   navigate('/');
    // }, [navigate]);
    const handleClick = () => {
        navigate('/');
    };
    return (
        <Grid
            container
            direction="row"
            sx={{
                height: '100vh',
                overflow: 'hidden scroll',
                pb: 5,
                background: isDarkMode ? '#1F1F26' : 'linear-gradient(180deg, #F7F5EF 0%, #F2F2FA 0%, #EFEFF5 100%) 0% 0% no-repeat padding-box',
            }}
        >
            <div className="col-12 d-flex justify-content-start flex-column align-items-center " style={{ padding: '90px' }}>
                <SomethingWentWrong sx={{ height: '142px', width: '155px' }} />
                <div className="mt-3">
                    <p style={{ color: '#787b7f', font: 'normal normal 600 24px/37px Manrope' }}>Oops! This profile is locked .</p>
                </div>
                <div className="mt-1">
                    <p style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>You don't have access.</p>
                </div>
                <div>
                    {' '}
                    <Button
                        variant="contained"
                        className="me-1"
                        sx={{
                            fontSize: '14px',
                            borderRadius: '20px',
                            height: '28px',
                            lineHeight: '12px',
                            '&:hover': {
                                backgroundColor: 'primary.main',
                                color: 'common.black',
                            },
                            width: 'fit-content',
                        }}
                        onClick={handleClick}
                    >
                        Go back home
                    </Button>
                </div>
            </div>
        </Grid>
    );
};

export default ProfileLockedPage;
