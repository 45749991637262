import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

type Props = {
  title: string;
};

export default function SubHead({ title }: Props) {
  return (
    <Box width="100%" pt={0.9} pb={2.8}>
      <Typography component="h5" display="flex" alignItems="center" color="#646262" fontWeight="bold" fontSize="1.05rem">
        {title}
      </Typography>
    </Box>
  );
}
