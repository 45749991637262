import React from 'react';
import FeaturedIcon from 'app/components/icons/side-bar-icons/featured-icon';
import GlobeIcon from 'app/components/icons/side-bar-icons/globe-icon';
import OthersIcon from 'app/components/icons/side-bar-icons/others-icon';
import PrivateIcon from 'app/components/icons/side-bar-icons/private-icon';
import PetitionsIcon from 'app/components/icons/side-bar-icons/petitions-icon';
import EventsIcon from 'app/components/icons/side-bar-icons/events-icon';
import EnvironmentIcon from 'app/components/icons/side-bar-icons/environment-icon';
import AnimalRescueIcon from 'app/components/icons/side-bar-icons/animal-rescue-icon';
import HealthIcon from 'app/components/icons/side-bar-icons/category-icons/health-icon';
import EducationIcons from 'app/components/icons/side-bar-icons/category-icons/education-icons';
import WellbeingIcons from 'app/components/icons/side-bar-icons/category-icons/wellbeing-icons';
import ZerohungerIcons from 'app/components/icons/side-bar-icons/category-icons/zerohunger-icons';
import NopovertyIcons from 'app/components/icons/side-bar-icons/category-icons/nopoverty';
import GenderequalityIcons from 'app/components/icons/side-bar-icons/category-icons/genderequality-icons';
import WaterIcons from 'app/components/icons/side-bar-icons/category-icons/water-icons';
import CleanenergyIcons from 'app/components/icons/side-bar-icons/category-icons/clean-energy-icons';
import LivelihoodIcons from 'app/components/icons/side-bar-icons/category-icons/Livelihood-icons';
import InnovationIcons from 'app/components/icons/side-bar-icons/category-icons/innovation-icons';
import ReduceinequalityIcon from 'app/components/icons/side-bar-icons/category-icons/reduce-inequality-icons';
import SustainabilityIcon from 'app/components/icons/side-bar-icons/category-icons/sustainability-icons';
import RecyclingIcon from 'app/components/icons/side-bar-icons/category-icons/recycling-icons';
import ClimateactionIcon from 'app/components/icons/side-bar-icons/category-icons/climate-action-icons';
import AquaticlifeIcon from 'app/components/icons/side-bar-icons/category-icons/aquaticlife-icons';
import UrgentIcon from 'app/components/icons/side-bar-icons/category-icons/urgent-icons';
import NgoIcon from 'app/components/icons/side-bar-icons/category-icons/NGO-icons';
import AdvocacyIcon from 'app/components/icons/side-bar-icons/category-icons/advocacy-icons';
import FlorafaunaIcon from 'app/components/icons/side-bar-icons/category-icons/flora-fauna-icons';
import FavouritesIcon from 'app/components/icons/side-bar-icons/category-icons/favourites-icons';

export const sideBaricons = [
  {
    name: 'End Poverty',
    id: 1,
    icon: <NopovertyIcons />,
  },
  {
    name: 'Stop Hunger',
    id: 2,
    icon: <ZerohungerIcons />,
  },
  {
    id: 3,
    name: 'Good Health',
    icon: <HealthIcon />,
  },
  // {
  //   id: 4,
  //   name: 'Well Being ',
  //   icon: <WellbeingIcons />,
  // },
  {
    id: 5,
    name: 'Education',
    icon: <EducationIcons />,
  },
  {
    id: 6,
    name: 'Gender Equality',
    icon: <GenderequalityIcons />,
  },
  {
    id: 7,
    name: 'Clean Water',
    icon: <WaterIcons />,
  },
  {
    id: 8,
    name: 'Greener Cities',
    icon: <SustainabilityIcon />,
  },
  {
    id: 9,
    name: 'Clean Energy',

    icon: <CleanenergyIcons />,
  },
  {
    id: 10,
    name: 'Employment',
    icon: <LivelihoodIcons />,
  },
  {
    id: 11,
    name: 'Innovation',
    icon: <InnovationIcons />,
  },
  {
    id: 12,
    name: 'Reduced Inequalities',
    icon: <ReduceinequalityIcon />,
  },
  {
    id: 13,
    name: 'Climate Action',
    icon: <ClimateactionIcon />,
  },
  {
    id: 14,
    name: 'Save Our Oceans',
    icon: <AquaticlifeIcon />,
  },
  {
    id: 15,
    name: 'Save Plants & Animals',
    icon: <FlorafaunaIcon />,
  },
  {
    id: 16,
    name: 'Peace & Justice',
    icon: <AdvocacyIcon />,
  },
  {
    id: 17,
    name: 'Partnerships',
    icon: <NgoIcon />,
  },
  {
    id: 18,
    name: 'Responsible Consumption',
    icon: <RecyclingIcon />,
  },
  {
    id: 19,
    name: 'Urgent',
    icon: <UrgentIcon />,
  },
];
