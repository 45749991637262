import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  imageResponse: null,
  imageUploaded: false,
  status: 'idle',
  error: null,
};

interface UploadImagePayload {
  files: unknown;
  type: string;
}

export const uploadImageByType = createAsyncThunk<string, UploadImagePayload, { rejectValue: string }>(
  'images/upload',
  async ({ files, type }, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append('file', files as Blob);
      const response = await axios.post(`api/images/upload/${type}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const imageUploadSlice = createSlice({
  name: 'imageUploadByType',
  initialState,
  reducers: {
    setImageResponse(state, action) {
      state.imageResponse = action.payload;
    },
    setImageUploaded(state, action) {
      state.imageUploaded = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(uploadImageByType.pending, state => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(uploadImageByType.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.imageResponse = action.payload;
        state.imageUploaded = true;
        state.error = null;
      })
      .addCase(uploadImageByType.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload ?? action.error.message;
      });
  },
});

export const { setImageResponse, setImageUploaded } = imageUploadSlice.actions;
export default imageUploadSlice.reducer;
