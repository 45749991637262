// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hover-box {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  background: #fff;
  width: 140px;
  padding: 4px 4px;
  border-radius: 3px;
  z-index: 1000;
}

.link-container:hover .hover-box {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/layout/map-events-page/map-events-page.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,MAAA;EACA,SAAA;EACA,2BAAA;EACA,aAAA;EACA,gBAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,aAAA;AACF;;AAEA;EACE,cAAA;AACF","sourcesContent":[".hover-box {\n  position: absolute;\n  top: 0;\n  left: 50%;\n  transform: translateX(-50%);\n  display: none;\n  background: #fff;\n  width: 140px;\n  padding: 4px 4px;\n  border-radius: 3px;\n  z-index: 1000;\n}\n\n.link-container:hover .hover-box {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
