import React from 'react';

import { createSvgIcon } from '@mui/material/utils';

const ChatPinIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="17.428" height="22.187" viewBox="0 0 17.428 22.187">
    <g id="Group_1998" data-name="Group 1998" transform="translate(-3.647 0.683)">
      <g id="pin_24x24_1x" data-name="pin(24x24)@1x" transform="translate(10 0) rotate(30)">
        <path
          id="Path_2700"
          data-name="Path 2700"
          d="M0,0H2V8L1,11,0,8Z"
          transform="translate(5 9)"
          fill="none"
          stroke="#676e7b"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />

        <path
          id="Path_2701"
          data-name="Path 2701"
          d="M11,0H1L3.5,2.5v2l-.6.171A4,4,0,0,0,0,8.517V9H12V8.517A4,4,0,0,0,9.1,4.671L8.5,4.5v-2Z"
          transform="translate(0 0)"
          fill="none"
          stroke="#676e7b"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </g>
  </svg>,

  'ChatPinIcon'
);

export default ChatPinIcon;
