import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Box, Typography, useMediaQuery } from '@mui/material';
import TopLabel from './graph/top-label';

function formatDate(dateString) {
  const inputDate = new Date(dateString);

  if (isNaN(inputDate.getTime())) {
    return 'Invalid date';
  }

  const day = inputDate.getDate().toString().padStart(2, '0');
  const month = inputDate.toLocaleString('en-US', { month: 'short' });
  const year = inputDate.getFullYear();

  return `${day} ${month} ${year}`;
}

function addDays(dateString, daysToAdd) {
  const date = new Date(dateString);
  date.setDate(date.getDate() + daysToAdd);
  return date.toISOString();
}

type Props = {
  width?: string;
  graphData?: any;
  latestStartDate?: any;
  previousStartDate?: any;
  latestData?: [];
  prevData?: [];
};

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const MultiBarChart = ({ width, latestStartDate, previousStartDate, latestData, prevData }: Props) => {
  const isSmallScreen = useMediaQuery('(max-width: 640px)');

  const data = {
    labels: Array.from({ length: latestData?.length }, (_, i) => `SDG ${i + 1}`),
    datasets: [
      {
        data: latestData,
        backgroundColor: '#674BFF',
        barBorderRadius: 4,
        width: 50,
      },
      {
        data: prevData,
        backgroundColor: '#EC6666',
        barBorderRadius: 4,
        width: 50,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,

        grid: {
          display: false,
        },
        ticks: {
          // Custom callback to format ticks
          callback: function (value) {
            // Only return integer values
            if (value % 1 === 0) {
              return value;
            }
          },
        },
      },

      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false, // Remove this to hide the x-axis labels
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: 'SDG Activity Count',
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: function () {
            return '';
          },
          label: function (context) {
            const labelIndex = context.dataIndex;
            const datasetIndex = context.datasetIndex;
            const value = context.dataset.data[labelIndex];
            let formattedDate = '';

            if (datasetIndex === 0) {
              formattedDate = formatDate(addDays(latestStartDate, labelIndex));
            } else {
              formattedDate = formatDate(addDays(previousStartDate, labelIndex));
            }

            return `${formattedDate}: ${value}`;
          },
        },
      },
    },
    maintainAspectRatio: false,
  };

  const chartContainerStyle = {
    width: width ? width : '97%',
  };

  useEffect(() => {
    const elem = document.getElementById('cat-bar-graph-canvas');
    if (elem) elem.style.height = '255px';
  }, []);

  return (
    <div style={chartContainerStyle} className="home-bar-graph">
      <Box px={isSmallScreen ? 0 : 3} mb={3} display="flex" alignItems="center" justifyContent="space-between">
        <Typography fontSize="0.9rem" fontWeight="bold" component="h5">
          Activity Count
        </Typography>
        <Box display="flex" alignItems="center" gap={3}>
          <TopLabel color="#674BFF" label="Latest Activities" />
          <TopLabel color="#EC6666" label="Previous Activities" />
        </Box>
      </Box>
      <Box display="flex">
        <Typography
          display={isSmallScreen ? 'none' : 'block'}
          fontSize="0.6rem"
          variant="body2"
          fontWeight="600"
          color="#798498"
          sx={{ writingMode: 'vertical-lr', transform: 'rotate(-180deg)', paddingTop: ' 17px', paddingLeft: '13px' }}
        >
          Activity Count
        </Typography>
        <Bar id="cat-bar-graph-canvas" data={data} options={options} />
      </Box>
    </div>
  );
};

export default MultiBarChart;
