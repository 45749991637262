// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 600px) {
  .sec-two-mob {
    display: flex;
    flex-direction: column;
  }
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/ui/about-us/about-us.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAA;IACA,sBAAA;EACF;AACF","sourcesContent":["@media (max-width: 600px) {\n  .sec-two-mob {\n    display: flex;\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
