import React, { useEffect, useState } from 'react';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { apiKeys } from 'app/utils/data/constants/api-keys';
import { Box, Dialog, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import $ from 'jquery';

interface NearMeEventsMap {
  latitude: number;
  longitude: number;
  width?: number | string;
  height?: number | string;
  zoom?: number;
  data?: any;
  open?: any;
  onClose: (data?: any) => void;
  fullScreen: boolean;
}

const InfoWindowComponent = ({ markerData, backgroundColor }) => {
  const infoWindowStyle = {
    backgroundColor: backgroundColor,
    borderRadius: '5px',
    width: '100px',
    height: '28px',
    border: '1px solid black'

  };

  return (
    <InfoWindow position={{ lat: Number(markerData?.latitude), lng: Number(markerData?.longitude) }}>
      <div className=' pt-1 ps-2' style={infoWindowStyle}>
        <Typography sx={{ fontSize: '10px' }}>
          {markerData.eventType === 'Activity' ? (
            <Link to={`/activity/${markerData.id}`} style={{
              textDecoration: 'none', fontSize: '14px', whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'inline-block',
              maxWidth: '100%',
            }}>
              {markerData.title?.split(' ').length > 5
                ? `${markerData.title?.split(' ')?.slice(0, 5)?.join(' ')}...`
                : markerData.title}
            </Link>
          ) : (
            <Link to={`/petition/${markerData.id}`} style={{
              textDecoration: 'none', fontSize: '14px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'inline-block',
              maxWidth: '100%'
            }}>
              {markerData.title?.split(' ').length > 5
                ? `${markerData.title?.split(' ')?.slice(0, 5)?.join(' ')}...`
                : markerData.title}
            </Link>
          )}
        </Typography>
      </div>
    </InfoWindow>
  );
};

const NearmeGMapWithMarker = ({
  latitude,
  longitude,
  width = '100%',
  height = '100vh',
  zoom = 12,
  data = [],
  open,
  onClose,
  fullScreen
}: NearMeEventsMap) => {
  const { isLoaded, loadError } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: apiKeys.GMAP_API_Key,
  });

  const [selectedMarker, setSelectedMarker] = useState(null);
  const center = { lat: Number(latitude), lng: Number(longitude) };

  useEffect(() => {
    if (isLoaded) {
      $('.gm-style-iw').prev('div').remove();
    }
  }, [isLoaded]);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  const mapContainerStyle = {
    width: width,
    height: height,
    borderRadius: '16px',
  };

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={onClose}>
      <div className="d-flex justify-content-end">
        <IconButton aria-label="Close" onClick={onClose}>
          <CloseIcon sx={{ color: '#362691' }} />
        </IconButton>
      </div>
      <GoogleMap zoom={zoom} center={center} mapContainerStyle={mapContainerStyle}>
        <Box position="absolute" bottom={16} left={16} bgcolor="transperant" p={2} borderRadius={4}>
          <div style={{ height: '30px', width: '80px', borderRadius: '5px', backgroundColor: '#FFDE5D', textAlign: 'center', paddingTop: '3px', font: 'normal normal normal 14px/22px Manrope', color: '#000', marginBottom: '5px', border: '1px solid #000' }} >Latest</div>
          <div style={{ height: '30px', width: '80px', borderRadius: '5px', backgroundColor: '#F38993', textAlign: 'center', paddingTop: '3px', font: 'normal normal normal 14px/22px Manrope', color: '#000', marginBottom: '5px', border: '1px solid #000' }}>Upcoming</div>
          <div style={{ height: '30px', width: '80px', borderRadius: '5px', backgroundColor: '#98E4BD', textAlign: 'center', paddingTop: '3px', font: 'normal normal normal 14px/22px Manrope', color: '#000', marginBottom: '5px', border: '1px solid #000' }}>Ongoing</div>
          <div style={{ height: '30px', width: '80px', borderRadius: '5px', backgroundColor: '#9CCBF1', textAlign: 'center', paddingTop: '3px', font: 'normal normal normal 14px/22px Manrope', color: '#000', marginBottom: '5px', border: '1px solid #000' }}>Completed</div>
        </Box>
        <Marker position={center} />
        {data.map((markerData, index) => {
          let backgroundColor = '#FFDE5D';
          if (markerData?.timelineCategory === 'UPCOMING') {
            backgroundColor = '#F38993';
          } else if (markerData?.timelineCategory === 'ONGOING') {
            backgroundColor = '#98E4BD';
          } else if (markerData?.timelineCategory === 'COMPLETED') {
            backgroundColor = '#9CCBF1';
          }

          return (
            <InfoWindowComponent key={index} markerData={markerData} backgroundColor={backgroundColor} />
          );
        })}
      </GoogleMap>
    </Dialog >
  );
};

export default NearmeGMapWithMarker;

