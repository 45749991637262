import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { Storage } from 'react-jhipster';

export interface VerificationState {
  data: any;
  loading: boolean;
  error: string | null;
}

export const initialState: VerificationState = {
  data: null,
  loading: false,
  error: null,
};

export const verifyOtp = createAsyncThunk<any, { phoneNumber?: any; countryCode?: any; code?: any; isoCode: any }, { rejectValue: string }>(
  'phone/verifyOtp',
  async ({ phoneNumber, countryCode, code, isoCode }, { rejectWithValue }) => {
    try {
      const response = await axios.post<any>('api/phone/verifyOtp', { phoneNumber, countryCode, code, isoCode });
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const getOtp = createAsyncThunk<any, { phoneNumber?: any; medium?: any; countryCode?: any }, { rejectValue: string }>(
  'phone/sendOtp',
  async ({ phoneNumber, medium, countryCode }, { rejectWithValue }) => {
    try {
      const response = await axios.post<any>('api/phone/sendOtp', { phoneNumber, medium, countryCode });
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const getPhoneNumber = createAsyncThunk<any, void, { rejectValue: string }>(
  'phone/getPhoneNumber',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<any>('api/userPhone');
      if (response?.data) {
        Storage.local.set('getPhone', response.data);
      }
      return response?.data;
    } catch (error) {
      // return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const verificationSlice = createSlice({
  name: 'phoneverification',
  initialState,
  reducers: {
    clearPhoneVerification() {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getOtp.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(getOtp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'An error occurred';
      })
      .addCase(verifyOtp.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'An error occurred';
      })
      .addCase(getPhoneNumber.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPhoneNumber.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload;
      })
      .addCase(getPhoneNumber.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'An error occurred';
      });
  },
});

export const { clearPhoneVerification } = verificationSlice.actions;
export default verificationSlice.reducer;
