import React, { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import WelcomeUser from '../welcome-user/welcome-user';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { Storage } from 'react-jhipster';
import { weekDays } from 'app/utils/data/choose-availability/week-days';
import { weekDaysType } from 'app/utils/types/choose-availability-types/choose-availability-types';
import './choose-availability.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTheme } from '@mui/material';
import WeekDayButtons from './weekday-buttons';
const ChooseAvailability = () => {
  const [selected, setSelected] = useState<weekDaysType[]>([]);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const navigate = useNavigate();
  const handleItemClick = (item: weekDaysType) => {
    const isItemInSelected = selected.some(element => element.label === item.label && element.value === item.value);

    if (isItemInSelected) {
      setSelected(prevSelected => prevSelected.filter(element => element.value !== item.value));
    } else {
      setTimeout(() => {
        setSelected(prevSelected => [...prevSelected, item]);
      }, 0);
    }
  };
  useEffect(() => {
    if (Storage.session.get('weekDays')) {
      setSelected(JSON.parse(Storage.session.get('weekDays')));
    }
    if (Storage.session.get('preferredStartTime')) {
      setStartTime(new Date(Storage.session.get('preferredStartTime')));
    }
    if (Storage.session.get('preferredEndTime')) {
      setEndTime(new Date(Storage.session.get('preferredEndTime')));
    }
  }, []);

  const handleNext = () => {
    if (!startTime) {
      navigate('/interests');
      return;
    }
    if (startTime && endTime && new Date(startTime) < new Date(endTime)) {
      Storage.session.set('weekDays', JSON.stringify(selected));
      Storage.session.set('preferredStartTime', startTime);
      Storage.session.set('preferredEndTime', endTime);
      navigate('/interests');
    } else {
      alert('End time should not be less than start time');
    }
  };

  const handleBack = () => {
    Storage.session.set('weekDays', JSON.stringify(selected));
    Storage.session.set('preferredStartTime', startTime);
    Storage.session.set('preferredEndTime', endTime);
    navigate('/events');
  };
  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="center" minHeight="100vh" py={5} className="mob-av desk-av">
      <WelcomeUser />
      <Stack direction="column" spacing={5} alignItems="center" marginBottom={'16px'} sx={{ px: { xs: 2, md: 0 } }}>
        <Typography sx={{ fontSize: '24px', color: 'secondary.light', fontWeight: 600 }}>Choose your availability</Typography>

        <Grid
          className="text-center"
          item
          container
          direction="row"
          gap={3}
          alignItems={'center'}
          justifyContent={{ xs: 'center', md: 'space-between' }}
        >
          {weekDays?.map(item => (
            <Grid item xs={4} sm={2} md key={item.value} width="47px" height="47px">
              <WeekDayButtons selected={selected} item={item} handleItemClick={handleItemClick} />
            </Grid>
          ))}
        </Grid>
        <Grid item container direction="column" gap={3} alignItems={'center'} justifyContent={'center'}>
          <Typography variant="h4" color="secondary.light">
            Choose your preferred time
          </Typography>
          <Grid
            item
            container
            direction={{ xs: 'column', md: 'row' }}
            alignItems={'center'}
            justifyContent={'sapce-between'}
            maxWidth={'300px'}
            className="welcome-picker"
          >
            <Grid item xs={12} md>
              <DatePicker
                className={isDarkMode ? 'rom-timepickerdark ' : 'rom-timepicker '}
                selected={startTime}
                onChange={(date: Date | null) => {
                  setStartTime(date);
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption=""
                dateFormat="h:mm aa"
                isClearable={false}
                placeholderText="Start time"
                onKeyDown={e => {
                  e.preventDefault();
                }}
              />
            </Grid>
            <Grid item container alignItems={'center'} justifyContent={'center'} xs={12} md sx={{ maxWidth: '20px', maxHeight: '55px' }}>
              -
            </Grid>
            <Grid item xs={12} md>
              <DatePicker
                className={isDarkMode ? 'rom-timepickerdark ' : 'rom-timepicker '}
                selected={endTime}
                onChange={(date: Date | null) => {
                  setEndTime(date);
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption=""
                dateFormat="h:mm aa"
                isClearable={false}
                placeholderText="End time"
                onKeyDown={e => {
                  e.preventDefault();
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item container alignItems="center" justifyContent={'space-between'} mt={3}>
          <Button
            onClick={handleBack}
            variant="outlined"
            sx={{
              textTransform: 'none',
              width: '89px',
              height: '40px',
              borderRadius: '15px',
              border: '1px solid #FFCE00',
              boxShadow: 'none',
              '&:hover': { boxShadow: 'none' },
            }}
          >
            <Typography>Back</Typography>
          </Button>
          <Button
            onClick={handleNext}
            variant="contained"
            sx={{
              textTransform: 'none',
              width: '89px',
              height: '40px',
              borderRadius: '15px',
              border: '1px solid #FFCE00',
              boxShadow: 'none',
              '&:hover': { backgroundColor: 'primary.main', boxShadow: 'none' },
            }}
          >
            <Typography>Next</Typography>
          </Button>
        </Grid>
      </Stack>
    </Grid>
  );
};

export default ChooseAvailability;
