// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel {
  border-radius: 15px !important;
}

@media only screen and (max-width: 450px) {
  .w-dth {
    width: calc(100% + 16px) !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/common/modals/activity-rating-modal/activity-rating-modal.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;AACF;;AAEA;EACE;IACE,mCAAA;EACF;AACF","sourcesContent":[".carousel {\n  border-radius: 15px !important;\n}\n\n@media only screen and (max-width: 450px) {\n  .w-dth {\n    width: calc(100% + 16px) !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
