import React from 'react';
import Slider from '@mui/material/Slider';

interface EventsSliderProps {
  sliderDistance: string | number;
  sliderDisabled: boolean;
  setSliderDistance: React.Dispatch<React.SetStateAction<string | number>>;
  defaultMaxDistance: number;
  onSliderChange: (newValue: number) => void;
}
const EventsSlider = ({ sliderDistance, sliderDisabled, setSliderDistance, defaultMaxDistance, onSliderChange }: EventsSliderProps) => {
  const formatValueLabel = value => {
    console.log('slider', value);
    setSliderDistance(value);
    if (isNaN(value) || value < 0) {
      return '0';
    } else {
      return `${sliderDistance}`;
    }
  };

  const handleChange = (event: Event, newValue: number | number[]) => {
    console.log('herer', event);
    if (typeof newValue === 'number') {
      setSliderDistance(newValue);
      onSliderChange(newValue);
    }
  };

  return (
    <Slider
      value={+sliderDistance}
      aria-label="area-slider"
      disabled={sliderDisabled}
      valueLabelDisplay="auto"
      valueLabelFormat={formatValueLabel}
      onChange={handleChange}
      max={defaultMaxDistance}
      min={0}
      sx={{
        color: '#fff',
        '& .MuiSlider-track': {
          border: 'none',
          color: sliderDisabled ? 'grey' : 'primary.light',
        },
        '& .MuiSlider-thumb': {
          backgroundColor: sliderDisabled ? 'grey' : 'primary.light',
        },
        '& .MuiSlider-valueLabel': {
          backgroundColor: 'transparent',
          color: '#7F8286',
        },
      }}
    />
  );
};

export default EventsSlider;
