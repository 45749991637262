import dayjs from 'dayjs';

export interface IActivityComments {
  id?: number;
  userID?: number | null;
  replyToMessage?: string | null;
  imageUrl?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
}

export const defaultValue: Readonly<IActivityComments> = {};
