import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  loading: false,
  success: false,
  error: null,
  mileStones: null,
};

export const fetchMileStones = createAsyncThunk<any, string, { rejectValue: string }>('profile/fetchmilestones', async (id, thunkAPI) => {
  try {
    let response;
    if (localStorage.getItem('firebaseToken')) {
      response = await axios.get(`/api/profile/${id}/milestones`);
    } else {
      response = await axios.get(`/api/unauthorized/profile/${id}/milestones`);
    }

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to fetch milestone details');
  }
});

const mileStoneSlice = createSlice({
  name: 'milestones',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchMileStones.pending, state => {
        state.loading = true;
        state.success = false;
        state.error = null;
        state.mileStones = null;
      })
      .addCase(fetchMileStones.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.mileStones = action.payload;
      })
      .addCase(fetchMileStones.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.payload;
        state.mileStones = null;
      });
  },
});
export default mileStoneSlice.reducer;
