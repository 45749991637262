import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const BadIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
    <g id="Group_6126" data-name="Group 6126" transform="translate(-238 -111)">
      <g id="Group_6120" data-name="Group 6120" transform="translate(-8690 16615)">
        <circle id="Ellipse_261" data-name="Ellipse 261" cx="22.5" cy="22.5" r="22.5" transform="translate(8928 -16504)" fill="#eff0f0"/>
        <g id="Group_6053" data-name="Group 6053" transform="translate(8940 -16492)">
          <g id="Group_4930" data-name="Group 4930" transform="translate(0 0)">
            <g id="Group_4925" data-name="Group 4925" transform="translate(0)">
              <g id="Group_4922" data-name="Group 4922">
                <ellipse id="Ellipse_240" data-name="Ellipse 240" cx="10.249" cy="10.249" rx="10.249" ry="10.249" transform="translate(0 20.498) rotate(-90)" fill="#ffd05d"/>
              </g>
              <g id="Group_4923" data-name="Group 4923" transform="translate(1.612 1.501)">
                <path id="Path_3524" data-name="Path 3524" d="M348.536,88.469a10.251,10.251,0,0,1-14,14.256,10.25,10.25,0,1,0,14-14.256Z" transform="translate(-334.537 -88.469)" fill="#fdc453"/>
              </g>
              <g id="Group_4924" data-name="Group 4924" transform="translate(0.685 0.757)">
                <path id="Path_3525" data-name="Path 3525" d="M327.529,92.85a9.515,9.515,0,0,1,17.059-5.8,9.516,9.516,0,1,0-15.794,10.543A9.472,9.472,0,0,1,327.529,92.85Z" transform="translate(-326.821 -82.276)" fill="#ffd77f"/>
              </g>
            </g>
            <g id="Group_4929" data-name="Group 4929" transform="translate(5.718 6.49)">
              <g id="Group_4928" data-name="Group 4928">
                <g id="Group_4926" data-name="Group 4926">
                  <path id="Path_3526" data-name="Path 3526" d="M370.191,131.226c0,.7-.338,1.273-.755,1.273s-.755-.57-.755-1.273.338-1.273.755-1.273S370.191,130.523,370.191,131.226Z" transform="translate(-368.681 -129.953)" fill="#303030"/>
                </g>
                <g id="Group_4927" data-name="Group 4927" transform="translate(7.552)">
                  <path id="Path_3527" data-name="Path 3527" d="M433,131.226c0,.7-.338,1.273-.755,1.273s-.755-.57-.755-1.273.338-1.273.755-1.273S433,130.523,433,131.226Z" transform="translate(-431.485 -129.953)" fill="#303030"/>
                </g>
              </g>
            </g>
          </g>
          <g id="Group_4931" data-name="Group 4931" transform="translate(4.163 3.757)">
            <path id="Path_3528" data-name="Path 3528" d="M431.517,109.081l-.322-.092c-.23-.064-.448-.125-.658-.2a5.829,5.829,0,0,1-.864-.367,5.125,5.125,0,0,1-.781-.506,7.71,7.71,0,0,1-.743-.681.045.045,0,0,0-.076.033,1.953,1.953,0,0,0,.4,1.046,2.556,2.556,0,0,0,.867.737,2.7,2.7,0,0,0,1.048.3l.042,0a2.257,2.257,0,0,0,1.093-.194.044.044,0,0,0-.007-.083Z" transform="translate(-419.377 -107.224)" fill="#414042"/>
            <path id="Path_3529" data-name="Path 3529" d="M355.781,109.081l.322-.092c.23-.064.448-.125.658-.2a5.828,5.828,0,0,0,.864-.367,5.133,5.133,0,0,0,.781-.506,7.739,7.739,0,0,0,.743-.681.044.044,0,0,1,.076.033,1.952,1.952,0,0,1-.4,1.046,2.556,2.556,0,0,1-.867.737,2.7,2.7,0,0,1-1.048.3l-.042,0a2.258,2.258,0,0,1-1.093-.194.044.044,0,0,1,.007-.083Z" transform="translate(-355.749 -107.224)" fill="#414042"/>
          </g>
          <path id="Path_3530" data-name="Path 3530" d="M372.907,170.5c-.107.012-.169-.12-.167-.219a3.125,3.125,0,0,1,.361-1.383,3.868,3.868,0,0,1,.923-1.142,4.391,4.391,0,0,1,2.759-1,4.174,4.174,0,0,1,2.777.995,3.5,3.5,0,0,1,1.266,2.527c0,.1-.069.24-.186.219-.1-.017-.175-.148-.246-.207q-.164-.136-.339-.259a6.864,6.864,0,0,0-.726-.444,5.445,5.445,0,0,0-3.018-.667,5.2,5.2,0,0,0-1.911.564,6.682,6.682,0,0,0-1.015.646c-.121.092-.319.341-.469.369Z" transform="translate(-366.534 -155.839)" fill="#5e4939"/>
        </g>
      </g>
    </g>
  </svg>,
  'BadIcon'
);

export default BadIcon;
