import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const ReduceinequalityIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="13.364" viewBox="0 0 21 13.364" style={{ fontSize: 'larger' }}>
    <path
      id="Reduce_Inequality_"
      data-name="Reduce Inequality "
      d="M15.318,10.727a2.864,2.864,0,1,0-2.864-2.864A2.852,2.852,0,0,0,15.318,10.727Zm-7.636,0A2.864,2.864,0,1,0,4.818,7.864,2.852,2.852,0,0,0,7.682,10.727Zm0,1.909C5.458,12.636,1,13.753,1,15.977v2.386H14.364V15.977C14.364,13.753,9.906,12.636,7.682,12.636Zm7.636,0c-.277,0-.592.019-.926.048a4.028,4.028,0,0,1,1.88,3.293v2.386H22V15.977C22,13.753,17.542,12.636,15.318,12.636Z"
      transform="translate(-1 -5)"
      fill="#676e7b"
    />
  </svg>,
  'ReduceinequalityIcon'
);
export default ReduceinequalityIcon;
