import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const UpVoteOutlined = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="19.933" height="18.526" viewBox="0 0 19.933 18.526">
    <defs>
      <style>{`.a { fill: none; stroke: #676e7b; stroke-linecap: round; stroke-linejoin: round; stroke-miterlimit: 10; }`}</style>
    </defs>
    <path className="a" d="M15.467,3l9.467,9.467H18.148v8.06H12.781v-8.06H6Z" transform="translate(-5.5 -2.5)" />
  </svg>,
  'UpVoteOutlined'
);

export default UpVoteOutlined;
