import React, { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Row, Col, Alert, Button } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { activateAction, reset } from './activate.reducer';
import { Box, Typography } from '@mui/material';
import AmyIcon from 'app/components/common/cards/chat-card/amy-icons';
import './activate.scss';

export const ActivatePage = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const invitedOrgId = parseInt(localStorage.getItem('invitedOrgId'));
  const invitation = localStorage.getItem('invitation');
  const token = localStorage.getItem('token');

  console.log(invitation, token, 'Invitation====');

  const handleClick = () => {
    const key = searchParams.get('key');
    dispatch(activateAction(key));
  };

  const getBaseDomain = (url: string) => {
    const urlParts = url.split('/');
    return urlParts[2];
  };

  const setBaseUrl = (domain: string): string => {
    switch (domain) {
      case 'qa.reachamy.com':
        return 'https://admin.qa.reachamy.com';
      case 'stage.reachamy.com':
        return 'https://admin.stage.reachamy.com';
      case 'reachamy.com':
        return 'https://admin.reachamy.com';
      default:
        return '';
    }
  };

  const domain = getBaseDomain(window.location.href);
  const baseUrl = setBaseUrl(domain);

  console.log(`Domain: ${domain}`);
  console.log(`Base URL: ${baseUrl}`);

  const goToAdminPortal = (e: any) => {
    e.preventDefault();
    localStorage.removeItem('invitation');
    localStorage.removeItem('token');
    localStorage.removeItem('invitedOrgId');
    window.location.href = ` ${baseUrl}/invite?invitation=accept&token=${token}`;
  };

  const homeUrl = invitedOrgId ? `/?invited=true&org=${invitedOrgId}` : '/?action=login';

  const successAlert = (
    <Alert color="success">
      <strong>Your user account has been activated.</strong> Please
      {invitation === 'accept' && token ? (
        <a
          href={`${baseUrl}/invite?invitation=accept&token=${token}`}
          onClick={e => {
            goToAdminPortal(e);
          }}
          className="alert-link mx-1"
        >
          Sign in
        </a>
      ) : (
        <Link to={homeUrl} className="alert-link mx-1">
          Sign in
        </Link>
      )}
    </Alert>
  );

  const failureAlert = (
    <Alert color="danger">
      <strong>Your user could not be activated.</strong> Please use the registration form to sign up.
    </Alert>
  );

  const { activationSuccess, activationFailure } = useAppSelector(state => state.activate);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    // <div className="container d-flex flex-column align-center justify-content-center p-2">
    //   <Row className="justify-content-center ">
    //     <Col md="8">
    //       <h1>Activation</h1>
    //       <Button color="success" onClick={handleClick} className="my-2">
    //         Click here to activate your account
    //       </Button>
    //       {activationSuccess ? successAlert : undefined}
    //       {activationFailure ? failureAlert : undefined}
    //     </Col>
    //   </Row>
    // </div>
    <Box className="d-flex align-items-center justify-content-center" sx={{ height: '75vh' }}>
      <Box className="text-center" sx={{ width: '50%' }}>
        <AmyIcon sx={{ width: '100%', mb: 2, height: '100px' }} />
        <Typography variant="h3" gutterBottom>
          Activate your account !
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          Welcome to ReachAmy! We're thrilled to have you as part of our community. To begin your journey, just click the button below to
          activate your account
        </Typography>
        <Box textAlign={'center'} pt={2}>
          <Button onClick={handleClick} className="activation-btn" disableElevation disableRipple size="medium" variant="outlined">
            Activate
          </Button>
          {activationSuccess ? successAlert : undefined}

          {activationFailure ? failureAlert : undefined}
        </Box>
      </Box>
    </Box>
  );
};

export default ActivatePage;
