// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-input {
  border-radius: 0px;
  background-color: red;
  height: 22px;
}

.custom-input:focus {
  outline: none;
  border: 1px solid #775fff;
  box-shadow: 0 0 10px 2px #674bff;
}

.rmdp-range {
  background-color: #ddd7ff;
}

.rmdp-day span {
  border-radius: 0px;
}

.rmdp-range.start {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  background-color: #775fff;
  box-shadow: none !important;
}

.rmdp-range.end {
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  background-color: #775fff;
  box-shadow: none !important;
}

.rmdp-week-day {
  color: #292d34;
}

.rmdp-header-values {
  font-weight: 600;
}

.rmdp-toolbar div {
  background-color: #775fff !important;
}

.bg-dark .rmdp-day,
.rmdp-week-day {
  color: #fff;
}

.bg-dark .rmdp-header-values {
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/layout/event-filter-tabs/date-picker.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EAGA,qBAAA;EACA,YAAA;AADF;;AAIA;EACE,aAAA;EACA,yBAAA;EACA,gCAAA;AADF;;AAGA;EACE,yBAAA;AAAF;;AAEA;EACE,kBAAA;AACF;;AACA;EACE,8BAAA;EACA,2BAAA;EACA,yBAAA;EACA,2BAAA;AAEF;;AAAA;EACE,+BAAA;EACA,4BAAA;EACA,yBAAA;EACA,2BAAA;AAGF;;AADA;EACE,cAAA;AAIF;;AAFA;EACE,gBAAA;AAKF;;AAHA;EACE,oCAAA;AAMF;;AAJA;;EAEE,WAAA;AAOF;;AALA;EACE,WAAA;AAQF","sourcesContent":[".custom-input {\n  border-radius: 0px;\n  //   border: 1px #0c8af8 solid;\n  //   padding: 4px 12px;\n  background-color: red;\n  height: 22px;\n  //   box-shadow: 0 0 2px #0074d9;\n}\n.custom-input:focus {\n  outline: none;\n  border: 1px solid #775fff;\n  box-shadow: 0 0 10px 2px #674bff;\n}\n.rmdp-range {\n  background-color: #ddd7ff;\n}\n.rmdp-day span {\n  border-radius: 0px;\n}\n.rmdp-range.start {\n  border-bottom-left-radius: 0px;\n  border-top-left-radius: 0px;\n  background-color: #775fff;\n  box-shadow: none !important;\n}\n.rmdp-range.end {\n  border-bottom-right-radius: 0px;\n  border-top-right-radius: 0px;\n  background-color: #775fff;\n  box-shadow: none !important;\n}\n.rmdp-week-day {\n  color: #292d34;\n}\n.rmdp-header-values {\n  font-weight: 600;\n}\n.rmdp-toolbar div {\n  background-color: #775fff !important;\n}\n.bg-dark .rmdp-day,\n.rmdp-week-day {\n  color: #fff;\n}\n.bg-dark .rmdp-header-values {\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
