import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export interface PetitionDraftStat {
  loading: boolean;
  error: string | null;
  success: boolean | null;
}

const initialState = {
  petitionDraftLoading: false,
  petitionDraftSuccess: false,
  petitionDraftError: null,
};

export const sendPetitionDraft = createAsyncThunk<any, any, { rejectValue: string }>(
  'draft/sendPetitionDraft',
  async (draftData, thunkAPI) => {
    try {
      const response = await axios.post('/api/petition/draft', draftData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'Failed to send petition draft');
    }
  }
);

const petitionDraftSlice = createSlice({
  name: 'petitionDraftReducer',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(sendPetitionDraft.pending, state => {
        state.petitionDraftLoading = true;
        state.petitionDraftSuccess = false;
        state.petitionDraftError = null;
      })
      .addCase(sendPetitionDraft.fulfilled, (state, action) => {
        state.petitionDraftLoading = false;
        state.petitionDraftSuccess = true;
        state.petitionDraftError = null;
      })
      .addCase(sendPetitionDraft.rejected, (state, action) => {
        state.petitionDraftLoading = false;
        state.petitionDraftSuccess = false;
        state.petitionDraftError = action.payload;
      });
  },
});

export default petitionDraftSlice.reducer;
