// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rom-timepicker {
  border-radius: 15px;
  height: 45px;
  border: 1px solid #bfbfbf;
  padding: 7.5px 14px !important;
}

.rom-timepickerdark {
  border-radius: 15px;
  background: #2c2c38;
  height: 45px;
  color: #f6f7f8;
  border: 1px solid #bfbfbf;
}

input.rom-timepicker {
  width: -webkit-fill-available !important;
}

.react-datepicker-wrapper {
  width: -webkit-fill-available !important;
}

.react-datepicker__time-container {
  width: 147px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  background-color: #676bff !important;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/ui/create-activity/create-activity-main-sec/create-actvity-body/activity-end-time/activity-end-time.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,YAAA;EACA,yBAAA;EACA,8BAAA;AACF;;AACA;EACE,mBAAA;EACA,mBAAA;EACA,YAAA;EACA,cAAA;EACA,yBAAA;AAEF;;AACA;EACE,wCAAA;AAEF;;AACA;EACE,wCAAA;AAEF;;AACA;EACE,YAAA;AAEF;;AACA;EAKE,oCAAA;AAFF","sourcesContent":[".rom-timepicker {\n  border-radius: 15px;\n  height: 45px;\n  border: 1px solid #bfbfbf;\n  padding: 7.5px 14px !important;\n}\n.rom-timepickerdark {\n  border-radius: 15px;\n  background: #2c2c38;\n  height: 45px;\n  color: #f6f7f8;\n  border: 1px solid #bfbfbf;\n}\n\ninput.rom-timepicker {\n  width: -webkit-fill-available !important;\n}\n\n.react-datepicker-wrapper {\n  width: -webkit-fill-available !important;\n}\n\n.react-datepicker__time-container {\n  width: 147px;\n}\n\n.react-datepicker__time-container\n  .react-datepicker__time\n  .react-datepicker__time-box\n  ul.react-datepicker__time-list\n  li.react-datepicker__time-list-item:hover {\n  background-color: #676bff !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
