import { useParams } from 'react-router-dom';

export function useItemId() {
  const { id } = useParams<{ id: string }>();
  const cleanId = id.replace('?', '');
  const lastHyphenIndex = cleanId.lastIndexOf('-');
  let activityId;
  if (lastHyphenIndex !== -1) {
    activityId = parseInt(id.substring(lastHyphenIndex + 1), 10);
  } else {
    activityId = parseInt(id, 10);
  }

  return isNaN(activityId) ? null : activityId;
}
