import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export interface likeactivityType {
  data: string;
}

export interface likeState {
  activityLikes: likeactivityType | null;
  likeFlag: boolean;
  loading: boolean;
  error: string | null;
  success: boolean | null;
}

export const initialState: likeState = {
  activityLikes: null,
  likeFlag: false,
  loading: false,
  error: null,
  success: false,
};

export const fetchLikes = createAsyncThunk<likeactivityType, number, { rejectValue: string }>(
  'activity/fetchLikes',
  async (id, thunkAPI) => {
    try {
      const response = await axios.get<likeactivityType>(`api/activities/likes/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const likeSlice = createSlice({
  name: 'likeactivity',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchLikes.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLikes.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.activityLikes = action.payload;
        state.likeFlag = true;
      })
      .addCase(fetchLikes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'An error occurred';
        state.likeFlag = false;
      });
  },
});

export default likeSlice.reducer;
