/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable no-console */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiKeys } from 'app/utils/data/constants/api-keys';

export interface CityType {
    id: number
    iso2: number
    name: string
}

export interface CityState {
    cities: CityType[] | null;
    loading: boolean;
    error: string | null;
}

export const initialState: CityState = {
    cities: null,
    loading: false,
    error: null,
};


export const fetchCities = createAsyncThunk<any, any, { rejectValue: string }>(
    'state/fetchStatesByCountry',
    async (payload,  thunkAPI) => {
      try {
            const API_KEY = apiKeys.LOCATION_FILTER_KEY;
            const headers = {
                'X-CSCAPI-KEY': API_KEY,
            };
            const countryCode = payload.countryCode.iso2;
            const stateCode = payload.stateCode.iso2;
            const response = await axios.get(
                `https://api.countrystatecity.in/v1/countries/${countryCode}/states/${stateCode}/cities`, { headers,}
            );
            return response.data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response?.data?.message || 'An error occurred');
      }
    }
  );

const citySlice = createSlice({
    name: 'cityReducer',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchCities.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCities.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.cities = [action.payload];
            })
            .addCase(fetchCities.rejected, (state, action) => {
                state.loading = false;
                state.error ='An error occurred';
            });
    },
});

export default citySlice.reducer;