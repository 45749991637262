import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useAppSelector } from 'app/config/store';
import { Avatar, useTheme } from '@mui/material';
import './radio-dialog.scss';
import { truncateString } from 'app/utils/common-utils/common-utils';

export function middleTruncateString(str, length) {
  if (str.length > length) {
    const halfLength = Math.floor((length - 3) / 2); // Calculate half the length excluding "..."
    const firstHalf = str.slice(0, halfLength);
    const secondHalf = str.slice(-halfLength);
    return `${firstHalf}...${secondHalf}`;
  }
  return str;
}
type RadioDialogProps = {
  id?: string;
  open?: boolean;
  selectedOrgId: number;
  handleShow?: (value?: boolean) => void;
  keepMounted?: boolean;
  value?: string;
  handleOptionChange?: (event: any) => void;
  handleCancel: () => void;
  handleInstructionsModal: (value: boolean) => void;
  hasParamId?: boolean;
  loggedInUserOrganizations?: any;
};

const RadioDialog = ({
  id,
  open,
  selectedOrgId,
  handleShow,
  keepMounted,
  value: valueProp,
  handleOptionChange,
  handleCancel,
  handleInstructionsModal,
  hasParamId,
  loggedInUserOrganizations,
}: RadioDialogProps) => {
  const [value, setValue] = useState(valueProp);
  const radioGroupRef = useRef<HTMLElement | null>(null);
  const userOwnedOrganizations = useAppSelector(state => state.organizationReducer.userOwnedOrganization);
  const userPreferences = useAppSelector(state => state.userPreferencesSettings.userPreferencesCurrentUser);
  const theme = useTheme();

  const isDarkMode = theme.palette.mode === 'dark';

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleOk = () => {
    handleShow(false);
    handleInstructionsModal(true);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <Dialog
      className="example1"
      sx={{
        '& .MuiDialog-paper': { width: '80%', maxHeight: '435px !important', overflowY: 'scroll', borderRadius: '15px', },
        '& .MuiDialog-paper::-webkit-scrollbar': {
          display: ' none',
        },
      }}
      open={open}
      keepMounted={keepMounted}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
    >
      <DialogTitle>Volunteering as</DialogTitle>
      <DialogContent dividers className="example1">
        <RadioGroup sx={{ gap: 1.3 }} ref={radioGroupRef} aria-label="ringtone" name="ringtone" value={value} onChange={handleOptionChange}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className="left">
              <Avatar src={userPreferences?.profilePic} sx={{ mr: 1.2 }} />
              <span>Myself</span>
            </div>
            <Radio value={1} />
          </div>
          {hasParamId
            ? loggedInUserOrganizations?.length > 0 &&
            loggedInUserOrganizations?.map(org => (
              <div key={org.id} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className="left">
                  <Avatar src={org.logoUrl} sx={{ mr: 1.2 }} />
                  <span title={org.label}>{truncateString(org.label, 40)}</span>
                </div>
                <Radio value={org.id} />
              </div>
            ))
            : userOwnedOrganizations?.length > 0 &&
            userOwnedOrganizations
              ?.filter(item => !item.deleted)
              .map(org => (
                <div key={org.id} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className="left">
                    <Avatar src={org.logoUrl} sx={{ mr: 1.2 }} />
                    <span title={org.label}>{truncateString(org.label, 40)}</span>
                  </div>
                  <Radio value={org.id} />
                </div>
              ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button sx={{
            color: isDarkMode ? '#FFCE00' : '#674BFF',
 
            '&:hover': {
              color: isDarkMode ? '#FFCE00' : '#674BFF',
            },
          }} autoFocus onClick={handleCancel}>
          Close
        </Button>
        <Button  sx={{
            backgroundColor: isDarkMode ? '#FFCE00' : '#674BFF',
          color: isDarkMode ? "black":'#ffff',
            '&:hover': {
              backgroundColor: isDarkMode ? '#FFCE00' : '#674BFF',
             
            },
          }} onClick={handleOk}
           variant="contained">
          Proceed
        </Button>
      </DialogActions>
    </Dialog>  
  );
};

export default RadioDialog;
