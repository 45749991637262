// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editor-container {
  width: 100%;
  border-radius: 25px;
  background-color: white;
  border: "1px solid  rgba(191, 191, 191, 1)";
  box-shadow: 0 0 0 1px rgb(191, 191, 191);
  scrollbar-width: thin;
  scrollbar-color: #775fff;
  height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.editor-container::-webkit-scrollbar {
  width: 0px;
}

.editor-container .jodit-enter-button {
  display: none !important;
}

.jodit_theme_dark .jodit-toolbar__box:not(:empty) {
  background-color: #1f1f26;
}

.jodit-color-picker__color-item {
  display: none;
}

.jodit-color-picker__color-item[data-color="#E69138"],
.jodit-color-picker__color-item[data-color="#900095"],
.jodit-color-picker__color-item[data-color="#CC0000"],
.jodit-color-picker__color-item[data-color="#3C78D8"],
.jodit-color-picker__color-item[data-color="#E23A44"],
.jodit-color-picker__color-item[data-color="#666666"],
.jodit-color-picker__color-item[data-color="#B7B7B7"],
.jodit-color-picker__color-item[data-color="#9900FF"] {
  display: block;
}

.jodit-color-picker__color-item {
  height: 38px;
  width: 38px;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/ui/blog/write-blog-left-sec/write-blog-left-sec.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,mBAAA;EACA,uBAAA;EACA,2CAAA;EACA,wCAAA;EACA,qBAAA;EACA,wBAAA;EACA,aAAA;EACA,kBAAA;EACA,kBAAA;AACF;AAAE;EACE,UAAA;AAEJ;;AAEA;EACE,wBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;;;;;;;;EAQE,cAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;AACF","sourcesContent":[".editor-container {\n  width: 100%;\n  border-radius: 25px;\n  background-color: #ffff;\n  border: '1px solid  rgba(191, 191, 191, 1)';\n  box-shadow: 0 0 0 1px rgba(191, 191, 191, 1);\n  scrollbar-width: thin;\n  scrollbar-color: #775fff;\n  height: 300px;\n  overflow-x: hidden;\n  overflow-y: scroll;\n  &::-webkit-scrollbar {\n    width: 0px;\n  }\n}\n\n.editor-container .jodit-enter-button {\n  display: none !important;\n}\n\n.jodit_theme_dark .jodit-toolbar__box:not(:empty) {\n  background-color: #1f1f26;\n}\n\n.jodit-color-picker__color-item {\n  display: none;\n}\n\n.jodit-color-picker__color-item[data-color='#E69138'],\n.jodit-color-picker__color-item[data-color='#900095'],\n.jodit-color-picker__color-item[data-color='#CC0000'],\n.jodit-color-picker__color-item[data-color='#3C78D8'],\n.jodit-color-picker__color-item[data-color='#E23A44'],\n.jodit-color-picker__color-item[data-color='#666666'],\n.jodit-color-picker__color-item[data-color='#B7B7B7'],\n.jodit-color-picker__color-item[data-color='#9900FF'] {\n  display: block;\n}\n\n.jodit-color-picker__color-item {\n  height: 38px;\n  width: 38px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
