import React from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Close } from '@mui/icons-material';
import { DialogTitle, DialogContent, useTheme, Button } from '@mui/material';
import LocationPickerGmaps from 'app/components/ui/location-picker/location-picker-gmaps';

export interface MapBoxMapViewProps {
  handleCloseModal: () => void;
  handleSelectLocation: (location: any) => void;
  formik: any;
  handleLocationFromSearchBox: (location: string) => void;
}
const MapBoxMapView = ({ handleCloseModal, handleSelectLocation, formik, handleLocationFromSearchBox }: MapBoxMapViewProps) => {
  const theme = useTheme();

  return (
    <Stack sx={{ backgroundColor: theme.palette.secondary.dark }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          cursor: 'pointer',
          paddingTop: '1%',
          paddingRight: '2%',
          paddingBottom: '2%',
        }}
      >
        <Close onClick={handleCloseModal} />
      </Box>
      <DialogTitle sx={{ margin: 'auto' }}>Choose Location</DialogTitle>
      <DialogContent sx={{ margin: 'auto', padding: '5px', paddingBottom: '20px' }}>
        <LocationPickerGmaps
          formik={formik}
          onSelectLocation={handleSelectLocation}
          handleLocationFromSearchBox={handleLocationFromSearchBox}
        />
      </DialogContent>
      {/* <Stack direction={'row'} justifyContent="center" sx={{ mb: 2 }}>
        <Button variant="contained" onClick={handleCloseModal} sx={{ width: 100, borderRadius: '15px' }}>
          Select
        </Button>
      </Stack> */}
    </Stack>
  );
};

export default MapBoxMapView;
