import React, { useEffect } from 'react';
import BlockCards from './block-cards/block-cards';
import { Grid, Typography, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getBlockedAccounts, unblockAccount } from 'app/shared/reducers/settings/settings-block-accounts';

const BlockedAccounts = () => {
  const dispatch = useAppDispatch();
  const blockedAccounts = useAppSelector(state => state.settingsBlockAccounts);

  useEffect(() => {
    dispatch(getBlockedAccounts());
  }, [blockedAccounts?.unblockSuccess]);

  const handleButtonClick = (userId: any) => {
    dispatch(unblockAccount({ userId }));
  };
  const theme = useTheme();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ paddingLeft: '15px' }}>
        <Typography variant="h5" sx={{ color: theme.textColor.mainHeading, fontSize: '14px' }}>
          Blocked accounts
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {blockedAccounts?.currentVisibility?.length ? (
          blockedAccounts?.currentVisibility?.map(item => <BlockCards handleButtonClick={handleButtonClick} item={item} key={item.id} />)
        ) : (
          <Typography fontSize={'12px'} fontWeight={600}>
            You haven't blocked anyone{' '}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default BlockedAccounts;
