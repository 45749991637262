import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import SideNavigation from 'app/components/layout/side-navigaion/side-navigation';
import PrimaryHeader from 'app/components/layout/header/primary-header';
import { fetchProfile, fetchUnAuthorizedProfile } from 'app/shared/reducers/profile';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import AmyIcon from 'app/components/common/cards/chat-card/amy-icons';
import toast from 'react-hot-toast';
import { Button, Card, Grid, Typography, useMediaQuery } from '@mui/material';
import AuthorisationModal from 'app/components/common/authorization-modal/authorization-modal';
import { resetLogin, toggleModalLogin } from 'app/shared/reducers/authentication';
import axios from 'axios';
import NewPrimarySearchAppBar from 'app/components/layout/header/new-primary-header';
import { Container, Link } from '@mui/material';
import  '../relevant-links/relevant-links';
import RelevantLinks from '../relevant-links/relevant-links';

const drawerWidth = 315;
const drawerWidthSm = 248;

interface Props {
  window?: () => Window;
}
const PrivacyPolicy = () => {
  // const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [currentComponent, setCurrentComponent] = React.useState(' ');
  const [authModalOpen, setAuthModalOpen] = React.useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDarkMode = theme.palette.mode === 'dark';
  const [toggleValue, setToggleValue] = useState(false);
  const [toggleMapValue, setToggleMapValue] = useState(false);
  const [searchKeyword, setSearchKeyword] = React.useState<string>('');
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [routeBack, setRouteBack] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const location = useLocation();

  const isBackHome = location.state?.isBackHome;
  const [htmlContent, setHtmlContent] = useState('');
  // const isBackHome = true;
  const fetchAboutUs = async () => {
    try {
      const response = await axios.get<any>(`api/staticContent/privacy-policy`);
      console.log('community-guidelines', response);
      setHtmlContent(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAboutUs();
  }, []);
  useEffect(() => {
    if (isBackHome) {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener('popstate', e => {
        e.preventDefault();
        window.history.pushState(null, null, window.location.pathname);
      });
    }
  }, []);

  const Section = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(4),
  }));

  const List = styled('ul')(({ theme }) => ({
    paddingLeft: theme.spacing(4),
    listStyleType: 'disc',
    margin: 0,
  }));

  const ListItem = styled('li')(({ theme }) => ({
    marginBottom: theme.spacing(1),
  }));

  // const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <Box>
      <NewPrimarySearchAppBar searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} />
      <Box
        sx={{
          maxWidth: '800px',
          width: '100%',
          margin: 'auto',
          overflowY: 'scroll',
          height: '100vh',
          paddingBottom: '120px',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
      >
        <Card style={{ background: isDarkMode ? '#2c2c38' : '' }}>
          <Container maxWidth="md">
            <Typography variant="h5" gutterBottom style={{ color: isDarkMode ? 'white' : '', marginTop: '25px' }}>
              Privacy Policy
            </Typography>
            <Typography variant="body1" paragraph style={{ color: isDarkMode ? 'white' : '' }}>
              Last updated: July 30, 2024
            </Typography>
            <Typography variant="body1" paragraph style={{ color: isDarkMode ? 'white' : '' }}>
              This Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your information when You
              use the Service and informs You about Your privacy rights and how the law protects You. We use Your Personal data to provide
              and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this
              Privacy Policy.
            </Typography>

            <Section>
              <Typography variant="h5" gutterBottom style={{ color: isDarkMode ? 'white' : '' }}>
                Interpretation and Definitions
              </Typography>
              <Typography variant="h6" gutterBottom style={{ color: isDarkMode ? 'white' : '' }}>
                Interpretation
              </Typography>
              <Typography variant="body1" paragraph style={{ color: isDarkMode ? 'white' : '' }}>
                The words of which the initial letter is capitalized have meanings defined under the following conditions. The following
                definitions shall have the same meaning regardless of whether they appear in the singular or in the plural.
              </Typography>
              <Typography variant="h6" gutterBottom style={{ color: isDarkMode ? 'white' : '' }}>
                Definitions
              </Typography>
              <List>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  <strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.
                </ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  <strong>Affiliate</strong> means an entity that controls, is controlled by, or is under common control with a party, where
                  "control" means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for election
                  of directors or other managing authority.
                </ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  <strong>Application</strong> refers to ReachAMY, the software program provided by the Company.
                </ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  <strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to ReachAMY.
                </ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  <strong>Country</strong> refers to: Delaware, USA
                </ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  <strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.
                </ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  <strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.
                </ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  <strong>Service</strong> refers to the Application.
                </ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  <strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It
                  refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service
                  on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service
                  is used.
                </ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  <strong>Third-party Social Media Service</strong> refers to any website or any social network website through which a User
                  can log in or create an account to use the Service.
                </ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  <strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the
                  Service infrastructure itself (for example, the duration of a page visit).
                </ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  <strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf
                  of which such individual is accessing or using the Service, as applicable.
                </ListItem>
              </List>
            </Section>

            <Section>
              <Typography variant="h5" gutterBottom style={{ color: isDarkMode ? 'white' : '' }}>
                Collecting and Using Your Personal Data
              </Typography>
              <Typography variant="h6" gutterBottom style={{ color: isDarkMode ? 'white' : '' }}>
                Types of Data Collected
              </Typography>
              <Typography variant="h6" gutterBottom style={{ color: isDarkMode ? 'white' : '' }}>
                Personal Data
              </Typography>
              <Typography variant="body1" paragraph style={{ color: isDarkMode ? 'white' : '' }}>
                While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to
                contact or identify You. Personally identifiable information may include, but is not limited to:
              </Typography>
              <List>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>Email address</ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>First name and last name</ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>Phone number</ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>Address, State, Province, ZIP/Postal code, City</ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>Geolocation data</ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  Pictures and other information from your Device's camera and photo library
                </ListItem>
              </List>

              <Typography variant="h6" gutterBottom style={{ color: isDarkMode ? 'white' : '' }}>
                Usage Data
              </Typography>
              <Typography variant="body1" paragraph style={{ color: isDarkMode ? 'white' : '' }}>
                Usage Data is collected automatically when using the Service. Usage Data may include information such as Your Device's
                Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the
                time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data. When You
                access the Service by or through a mobile device, We may collect certain information automatically, including, but not
                limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your
                mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.
                We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or
                through a mobile device.
              </Typography>

              <Typography variant="h6" gutterBottom style={{ color: isDarkMode ? 'white' : '' }}>
                Information from Third-Party Social Media Services
              </Typography>
              <Typography variant="body1" paragraph style={{ color: isDarkMode ? 'white' : '' }}>
                The Company allows You to create an account and log in to use the Service through the following Third-party Social Media
                Services: Google . If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We
                may collect Personal data that is already associated with Your Third-Party Social Media Service's account, such as Your
                name, Your email address, and Your activities or Your contact list associated with that account. You may also have the
                option of sharing additional information with the Company through Your Third- Party Social Media Service's account. If You
                choose to provide such information and Personal Data, during registration or otherwise, You are giving the Company
                permission to use, share, and store it in a manner consistent with this Privacy Policy.
              </Typography>

              <Typography variant="h6" gutterBottom style={{ color: isDarkMode ? 'white' : '' }}>
                Information Collected while Using the Application
              </Typography>
              <Typography variant="body1" paragraph style={{ color: isDarkMode ? 'white' : '' }}>
                While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:
              </Typography>
              <List>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>Geolocation data</ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  Pictures and other information from your Device's camera and photo library
                </ListItem>
              </List>
              <Typography variant="body1" paragraph style={{ color: isDarkMode ? 'white' : '' }}>
                We use this information to provide features of Our Service, to improve and customize Our Service. The information may be
                uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device. You can
                enable or disable access to this information at any time through Your Device settings.
              </Typography>
            </Section>

            <Section>
              <Typography variant="h5" gutterBottom style={{ color: isDarkMode ? 'white' : '' }}>
                Use of Your Personal Data
              </Typography>
              <Typography variant="body1" paragraph style={{ color: isDarkMode ? 'white' : '' }}>
                The Company may use Personal Data for the following purposes:
              </Typography>
              <List>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  To provide and maintain our Service, including to monitor the usage of our Service.
                </ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You
                  access to different functionalities of the Service that are available to You as a registered user.
                </ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products,
                  items or services You have purchased or of any other contract with Us through the Service.
                </ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such
                  as a mobile application's push notifications regarding updates or informative communications related to the
                  functionalities, products or contracted services, including the security updates, when necessary or reasonable for their
                  implementation.
                </ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  To provide You with news, special offers and general information about other goods, services and events which we offer
                  that are similar to those that you have already purchased or enquired about unless You have opted not to receive such
                  information.
                </ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  To manage Your requests: To attend and manage Your requests to Us.
                </ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  For business transfers: We may use Your information to evaluate or conduct a merger, divestiture, restructuring,
                  reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part
                  of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the
                  assets transferred.
                </ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  For other purposes: We may use Your information for other purposes, such as data analysis, identifying usage trends,
                  determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services,
                  marketing and your experience.
                </ListItem>
              </List>
            </Section>

            <Section>
              <Typography variant="h5" gutterBottom style={{ color: isDarkMode ? 'white' : '' }}>
                Sharing Your Personal Data
              </Typography>
              <Typography variant="body1" paragraph style={{ color: isDarkMode ? 'white' : '' }}>
                We may share Your personal information in the following situations:
              </Typography>
              <List>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  <strong>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and
                  analyze the use of our Service, to contact You.
                </ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  <strong>For business transfers:</strong> We may share or transfer Your personal information in connection with, or during
                  negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to
                  another company.
                </ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  <strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will require those
                  affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture
                  partners or other companies that We control or that are under common control with Us.
                </ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  <strong>With business partners:</strong> We may share Your information with Our business partners to offer You certain
                  products, services or promotions.
                </ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  <strong>With other users:</strong> when You share personal information or otherwise interact in the public areas with
                  other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with
                  other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service
                  may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view
                  descriptions of Your activity, communicate with You and view Your profile.
                </ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  <strong>With Your consent:</strong> We may disclose Your personal information for any other purpose with Your consent.
                </ListItem>
              </List>
            </Section>

            <Section>
              <Typography variant="h5" gutterBottom style={{ color: isDarkMode ? 'white' : '' }}>
                Retention of Your Personal Data
              </Typography>
              <Typography variant="body1" paragraph style={{ color: isDarkMode ? 'white' : '' }}>
                The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy.
                We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we
                are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and
                policies. The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a
                shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our
                Service, or We are legally obligated to retain this data for longer time periods.
              </Typography>
            </Section>

            <Section>
              <Typography variant="h5" gutterBottom style={{ color: isDarkMode ? 'white' : '' }}>
                Transfer of Your Personal Data
              </Typography>
              <Typography variant="body1" paragraph style={{ color: isDarkMode ? 'white' : '' }}>
                Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the
                parties involved in the processing are located. It means that this information may be transferred to — and maintained on —
                computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws
                may differ than those from Your jurisdiction. Your consent to this Privacy Policy followed by Your submission of such
                information represents Your agreement to that transfer. The Company will take all steps reasonably necessary to ensure that
                Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take
                place to an organization or a country unless there are adequate controls in place including the security of Your data and
                other personal information.
              </Typography>
            </Section>

            <Section>
              <Typography variant="h5" gutterBottom style={{ color: isDarkMode ? 'white' : '' }}>
                Delete Your Personal Data
              </Typography>
              <Typography variant="body1" paragraph style={{ color: isDarkMode ? 'white' : '' }}>
                You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You. Our
                Service may give You the ability to delete certain information about You from within the Service. You may update, amend, or
                delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings
                section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or
                delete any personal information that You have provided to Us. Please note, however, that We may need to retain certain
                information when we have a legal obligation or lawful basis to do so.
              </Typography>
            </Section>

            <Section>
              <Typography variant="h5" gutterBottom style={{ color: isDarkMode ? 'white' : '' }}>
                Disclosure of Your Personal Data
              </Typography>
              <Typography variant="body1" paragraph style={{ color: isDarkMode ? 'white' : '' }}>
                <strong>Business Transactions:</strong> If the Company is involved in a merger, acquisition or asset sale, Your Personal
                Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different
                Privacy Policy.
              </Typography>
              <Typography variant="body1" paragraph style={{ color: isDarkMode ? 'white' : '' }}>
                <strong>Law enforcement:</strong> Under certain circumstances, the Company may be required to disclose Your Personal Data if
                required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
              </Typography>
              <Typography variant="body1" paragraph style={{ color: isDarkMode ? 'white' : '' }}>
                <strong>Other legal requirements:</strong> The Company may disclose Your Personal Data in the good faith belief that such
                action is necessary to:
              </Typography>
              <List>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>Comply with a legal obligation</ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>Protect and defend the rights or property of the Company</ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  Prevent or investigate possible wrongdoing in connection with the Service
                </ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  Protect the personal safety of Users of the Service or the public
                </ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>Protect against legal liability</ListItem>
              </List>
            </Section>

            <Section>
              <Typography style={{ color: isDarkMode ? 'white' : '' }} variant="h5" gutterBottom>
                Security of Your Personal Data
              </Typography>
              <Typography style={{ color: isDarkMode ? 'white' : '' }} variant="body1" paragraph>
                The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or
                method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal
                Data, We cannot guarantee its absolute security.
              </Typography>
            </Section>

            <Section>
              <Typography style={{ color: isDarkMode ? 'white' : '' }} variant="h5" gutterBottom>
                Children's Privacy
              </Typography>
              <Typography style={{ color: isDarkMode ? 'white' : '' }} variant="body1" paragraph>
                Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information
                from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with
                Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13
                without verification of parental consent, We take steps to remove that information from Our servers. If We need to rely on
                consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require
                Your parent's consent before We collect and use that information.
              </Typography>
            </Section>

            <Section>
              <Typography style={{ color: isDarkMode ? 'white' : '' }} variant="h5" gutterBottom>
                Links to Other Websites
              </Typography>
              <Typography style={{ color: isDarkMode ? 'white' : '' }} variant="body1" paragraph>
                Our Service may contain links to other websites that are not operated by Us. If You click on a third-party link, You will be
                directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit. We have no
                control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or
                services.
              </Typography>
            </Section>

            <Section>
              <Typography style={{ color: isDarkMode ? 'white' : '' }} variant="h5" gutterBottom>
                Changes to this Privacy Policy
              </Typography>
              <Typography style={{ color: isDarkMode ? 'white' : '' }} variant="body1" paragraph>
                We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on
                this page. We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective
                and update the "Last updated" date at the top of this Privacy Policy. You are advised to review this Privacy Policy
                periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
              </Typography>
            </Section>

            <Section>
              <Typography style={{ color: isDarkMode ? 'white' : '' }} variant="h5" gutterBottom>
                Contact Us
              </Typography>
              <Typography style={{ color: isDarkMode ? 'white' : '' }} variant="body1" paragraph>
                If you have any questions about this Privacy Policy, You can contact us:
              </Typography>
              <List>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  By email:{' '}
                  <Link href="mailto:privacy@reachamy.com" style={{ color: isDarkMode ? '#FFCE00;' : '#674bff', textDecoration: 'none' }}>
                    privacy@reachamy.com
                  </Link>
                </ListItem>
                <ListItem style={{ color: isDarkMode ? 'white' : '' }}>
                  By visiting this page on our website:{' '}
                  <Link
                    href="https://www.reachamy.com/privacy-policy"
                    style={{ color: isDarkMode ? '#FFCE00;' : '#674bff', textDecoration: 'none' }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.reachamy.com/privacy-policy
                  </Link>
                </ListItem>
              </List>
            </Section>
          <RelevantLinks/>
          </Container>
        </Card>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
