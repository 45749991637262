// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.org-member-btn {
  cursor: unset !important;
}

.org-member-avatar {
  max-width: -moz-min-content !important;
  max-width: min-content !important;
}

@media only screen and (max-width: 992px) {
  .org-member-MoreVertIcon-mob {
    max-width: 28% !important;
  }
}
.org-member-pl-0 {
  padding-left: 0 !important;
  padding-top: 0 !important;
}

.org-member-pl-16 {
  padding-left: 16px !important;
  padding-top: 16px !important;
}

.example-scrol {
  overflow-y: scroll; /* Add the ability to scroll */
  overflow-x: hidden;
}

.example-scrol::-webkit-scrollbar {
  display: none;
}

.example-scrol {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/ui/settings/settings-body/settings-panels/settings-organization/settings-org-right/settings-org-members.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;AACF;;AACA;EACE,sCAAA;EAAA,iCAAA;AAEF;;AAAA;EACE;IACE,yBAAA;EAGF;AACF;AADA;EACE,0BAAA;EACA,yBAAA;AAGF;;AADA;EACE,6BAAA;EACA,4BAAA;AAIF;;AADA;EACE,kBAAA,EAAA,8BAAA;EACA,kBAAA;AAIF;;AAFA;EACE,aAAA;AAKF;;AAHA;EACE,wBAAA,EAAA,gBAAA;EACA,qBAAA,EAAA,YAAA;AAMF","sourcesContent":[".org-member-btn {\n  cursor: unset !important;\n}\n.org-member-avatar {\n  max-width: min-content !important;\n}\n@media only screen and (max-width: 992px) {\n  .org-member-MoreVertIcon-mob {\n    max-width: 28% !important;\n  }\n}\n.org-member-pl-0 {\n  padding-left: 0 !important;\n  padding-top: 0 !important;\n}\n.org-member-pl-16 {\n  padding-left: 16px !important;\n  padding-top: 16px !important;\n}\n\n.example-scrol {\n  overflow-y: scroll; /* Add the ability to scroll */\n  overflow-x: hidden;\n}\n.example-scrol::-webkit-scrollbar {\n  display: none;\n}\n.example-scrol {\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
