import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const LivelihoodIcon = createSvgIcon(
  <svg id="Livelihood" xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" style={{ fontSize: 'larger' }}>
    <g id="invisible_box" data-name="invisible box">
      <rect id="Rectangle_1" data-name="Rectangle 1" width="23" height="23" fill="none" />
    </g>
    <g id="Health_Icons" data-name="Health Icons" transform="translate(0.93 1.861)">
      <g id="Group_6595" data-name="Group 6595">
        <path
          id="Path_4807"
          data-name="Path 4807"
          d="M21.084,4H3.4A1.35,1.35,0,0,0,2,5.4v5.539H4.466L5.3,9.213A.884.884,0,0,1,6.142,8.7a.978.978,0,0,1,.838.512l1.35,2.84L10.564,7.3a.931.931,0,0,1,1.676,0l1.722,3.631h1.071a.931.931,0,1,1,0,1.862H13.357a1.024,1.024,0,0,1-.838-.559L11.4,9.911,9.168,14.659a.978.978,0,0,1-.838.512.884.884,0,0,1-.838-.512l-1.35-2.84-.233.419a.931.931,0,0,1-.838.559H2v5.632a1.4,1.4,0,0,0,1.4,1.4H21.084a1.4,1.4,0,0,0,1.4-1.4V5.4a1.35,1.35,0,0,0-1.4-1.4ZM14.754,16.754a.931.931,0,0,1-.652.279H12.24a.931.931,0,0,1-.931-.931.791.791,0,0,1,.279-.652.931.931,0,0,1,.652-.279H14.1a.931.931,0,0,1,.931.931.791.791,0,0,1-.279.652Zm4.655,0a.931.931,0,0,1-.652.279H16.895a.931.931,0,0,1-.931-.931.791.791,0,0,1,.279-.652.931.931,0,0,1,.652-.279h1.862a.931.931,0,0,1,.931.931.791.791,0,0,1-.279.652Z"
          transform="translate(-1.998 -3.998)"
          fill="#676e7b"
        />
        <path
          id="Path_4808"
          data-name="Path 4808"
          d="M22.034,40H9.931a.931.931,0,0,0,0,1.862h12.1a.931.931,0,0,0,0-1.862Z"
          transform="translate(-5.741 -23.241)"
          fill="#676e7b"
        />
      </g>
    </g>
  </svg>,
  'LivelihoodIcon'
);
export default LivelihoodIcon;
