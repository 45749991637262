import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const FeaturedIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="22.621" height="10.625" viewBox="0 0 22.621 10.625">
    <g id="features_list" transform="translate(-8.186 15.625) rotate(-90)">
      <path
        id="Path_61"
        data-name="Path 61"
        d="M10.547,8.339,11.955,11.5l3.44.364a.256.256,0,0,1,.145.446l-2.568,2.315.717,3.382a.257.257,0,0,1-.38.276l-3-1.729-3,1.728A.257.257,0,0,1,6.936,18l.717-3.382L5.085,12.307a.257.257,0,0,1,.145-.446l3.44-.364,1.408-3.159A.258.258,0,0,1,10.547,8.339Z"
        transform="translate(0 0)"
        fill="#676e7b"
      />
      <path
        id="Path_62"
        data-name="Path 62"
        d="M10.547,20.339,11.955,23.5l3.44.364a.256.256,0,0,1,.145.446l-2.568,2.315.717,3.384a.257.257,0,0,1-.38.276l-3-1.73-3,1.728A.257.257,0,0,1,6.936,30l.717-3.384L5.085,24.305a.257.257,0,0,1,.145-.446l3.44-.364,1.408-3.159A.258.258,0,0,1,10.547,20.339Z"
        transform="translate(0 0.489)"
        fill="#676e7b"
      />
    </g>
  </svg>,
  'FeaturedIcon'
);
export default FeaturedIcon;
