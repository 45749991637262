import { Badge, Box, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './settings-payment-history-table.scss';
import { fetchTransactionHistory } from 'app/shared/reducers/payment';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import moment from 'moment';
import { LoaderIcon } from 'react-hot-toast';

function createData(sno, transationid, name, date, status, actions) {
  return { sno, transationid, name, date, status, actions };
}

const data = [{ sno: 1, transationid: 232, name: 'Jestin', date: '11-12-2', status: true, actions: '' }];

const SettingsPaymentistorytable = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const dispatch = useAppDispatch();
  const { transactionHistory, transactionHistoryLoading } = useAppSelector(state => state.payment);

  const formattedDate = (timestampString: string) => {
    return moment(timestampString).format('MMMM DD, YYYY');
  };

  useEffect(() => {
    dispatch(fetchTransactionHistory());
  }, [dispatch]);

  const handleInvoiceDownload = link => {
    const anchor = document.createElement('a');
    anchor.style.display = 'none';
    anchor.href = link;

    anchor.download = 'invoice.pdf';

    document.body.appendChild(anchor);

    anchor.click();

    document.body.removeChild(anchor);
  };

  const handleImpactDownload = link => {
    console.log('handleInvoiceDownload');
    const anchor = document.createElement('a');
    anchor.style.display = 'none';
    anchor.href = link;

    // Set the download attribute to suggest a filename for the downloaded file
    anchor.download = 'invoice.pdf';

    // Append the anchor element to the document body
    document.body.appendChild(anchor);

    // Programmatically trigger a click event on the anchor element
    anchor.click();

    // Remove the anchor element from the document body
    document.body.removeChild(anchor);
  };

  const downloadFile = async (fileUrl: string, fileName: string) => {
    try {
      const response = await fetch(fileUrl);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  return (
    <Box>
      {!transactionHistoryLoading ? (
        transactionHistory?.length > 0 ? (
          <TableContainer component={Paper} sx={{ boxShadow: 'none', background: theme.palette.mode === 'dark' ? '#2C2C38' : '' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ background: theme.palette.mode === 'dark' ? '#2C2C38' : '', width: '15%' }}>
                    Transaction Id
                  </TableCell>
                  <TableCell align="center" sx={{ background: theme.palette.mode === 'dark' ? '#2C2C38' : '', width: '20%' }}>
                    Service Type
                  </TableCell>
                  <TableCell align="center" sx={{ background: theme.palette.mode === 'dark' ? '#2C2C38' : '', width: '20%' }}>
                    Payment Date
                  </TableCell>
                  <TableCell align="center" sx={{ background: theme.palette.mode === 'dark' ? '#2C2C38' : '', width: '15%' }}>
                    Service Total
                  </TableCell>
                  <TableCell align="center" sx={{ background: theme.palette.mode === 'dark' ? '#2C2C38' : '', width: '15%' }}>
                    Payment Status
                  </TableCell>
                  <TableCell align="center" sx={{ background: theme.palette.mode === 'dark' ? '#2C2C38' : '', width: '15%' }}>
                    Invoice
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionHistory?.length > 0 &&
                  transactionHistory?.map(row => (
                    <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="left">{row.transactionId}</TableCell>
                      <TableCell align="center">{row.productLabel}</TableCell>
                      <TableCell align="center">{formattedDate(row.paymentDate)}</TableCell>
                      <TableCell align="center">{row.totalAmount}</TableCell>
                      <TableCell align="center">
                        {row.paymentStatus ? (
                          <Badge badgeContent="Success" color="success" sx={{ marginRight: '17px' }}></Badge>
                        ) : (
                          <Badge badgeContent="Failure" color="error" sx={{ marginRight: '17px' }}></Badge>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          onClick={() => downloadFile(row?.docLink, row?.transactionId)}
                          color={isDarkMode ? '#FFCE00' : '#674BFF'}
                          fontSize="14px"
                          fontWeight="500"
                          sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                        >
                          Download invoice
                        </Typography>
                        {row?.impactReport !== null && (
                          <Typography
                            onClick={() => handleImpactDownload(row?.impactReport)}
                            color={isDarkMode ? '#FFCE00' : '#674BFF'}
                            fontSize="14px"
                            fontWeight="500"
                            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                          >
                            Impact report
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box minHeight={400} bgcolor={isDarkMode ? '#2c2c38' : '#fff'} display="flex" justifyContent="center" paddingTop={10}>
            <Typography>No transactions found</Typography>
          </Box>
        )
      ) : (
        <Box minHeight={400} bgcolor={isDarkMode ? '#2c2c38' : '#fff'} display="flex" justifyContent="center" paddingTop={10}>
          <Typography>
            <LoaderIcon color="primary.light" />
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default SettingsPaymentistorytable;
