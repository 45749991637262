import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import CategoryMaster from './category-master';
import CategoryMasterDetail from './category-master-detail';
import CategoryMasterUpdate from './category-master-update';
import CategoryMasterDeleteDialog from './category-master-delete-dialog';

const CategoryMasterRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<CategoryMaster />} />
    <Route path="new" element={<CategoryMasterUpdate />} />
    <Route path=":id">
      <Route index element={<CategoryMasterDetail />} />
      <Route path="edit" element={<CategoryMasterUpdate />} />
      <Route path="delete" element={<CategoryMasterDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default CategoryMasterRoutes;
