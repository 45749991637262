import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import { useTheme } from '@mui/material';

interface ChooseCitySearchBar {
  placeholder?: string;
  value?: any;
  onChange?: (event: any) => void;
  clear?: () => void;
}

const ChooseCitySearchBar = ({ placeholder = 'Choose city...', value, onChange, clear, city }: any) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onChange && onChange(e.currentTarget.value);
    }
  };
  const theme = useTheme();

  return (
    <Paper
      component="form"
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        boxShadow: 'none',
        border: '1px solid #52525b',
        borderRadius: '15px',
        height: '38px',
        backgroundColor: theme.palette.mode === 'dark' ? '#1f1f2b' : '#fff',
      }}
    >
      <InputBase
        value={value !== 'nullnull null' ? value : city !== 'nullnull null' ? city : ''}
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'search google maps' }}
        onChange={e => onChange(e.target.value)}
        onKeyDown={handleKeyDown}
      />
    </Paper>
  );
};
export default ChooseCitySearchBar;
