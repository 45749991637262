import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { serializeAxiosError } from '../reducer.utils';
import toast from 'react-hot-toast';

export interface visibilitiesDetails {
  fetchLoading: boolean;
  fetchError: object | null | string;
  fetchSuccess: object | null | string;
  visibilities: object | null;
  sendSettingsSuccess: object | null | boolean;
  sendSettingsError: object | null | string;
  currentVisibility: string | null;
  currentVisibilitySuccess: object | null;
  currentVisibilityError: object | null;
}

interface ProfileParams {
  visibility: string;
  id: number;
}
const initialState = {
  fetchLoading: false,
  fetchError: null,
  fetchSuccess: null,
  visibilities: null,
  currentVisibility: null,
  sendSettingsSuccess: null,
  sendSettingsError: null,
  currentVisibilitySuccess: null,
  currentVisibilityError: null,
};

// get settings profile visibilities
export const getVisibilities = createAsyncThunk(
  'settings/privacy/visibilities',
  async () => axios.get<any>('/api/profile/settings/visibilities'),
  {
    serializeError: serializeAxiosError,
  }
);
// curent user visibility
export const getUserVisibility = createAsyncThunk(
  'settings/privacy/user-visibility',
  async () => axios.get<any>('/api/profile/visibility'),
  {
    serializeError: serializeAxiosError,
  }
);

// send profile visibility
export const sendVisibility = createAsyncThunk(
  'settings/privacy-visibility/send',
  async ({ visibility, id }: ProfileParams) => axios.post<any>('/api/profile/settings/privacy/' + id, { visibility }),
  {
    serializeError: serializeAxiosError,
  }
);

const settingVisibilitySlice = createSlice({
  name: 'settingsVisibility',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getVisibilities.pending, state => {
        state = initialState;
      })
      .addCase(getVisibilities.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.fetchSuccess = true;
        state.fetchError = null;
        state.visibilities = action.payload.data;
      })
      .addCase(getVisibilities.rejected, (state, action) => {
        state.fetchLoading = false;
        state.fetchSuccess = false;
        state.fetchError = action.error.message;
        state.visibilities = null;
      })
      .addCase(sendVisibility.pending, state => {
        state.fetchLoading = true;
        state.sendSettingsSuccess = null;
        state.sendSettingsError = null;
      })
      .addCase(sendVisibility.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.sendSettingsSuccess = true;
        state.sendSettingsError = null;
        toast.success(action.payload?.data?.message);
      })
      .addCase(sendVisibility.rejected, (state, action) => {
        state.fetchLoading = false;
        state.sendSettingsSuccess = false;
        state.sendSettingsError = true;
        toast.error('Something went wrong');
      })
      .addCase(getUserVisibility.fulfilled, (state, action) => {
        state.currentVisibilitySuccess = true;
        state.currentVisibilityError = null;
        state.currentVisibility = action.payload.data;
      })
      .addCase(getUserVisibility.rejected, state => {
        state.currentVisibilitySuccess = false;
        state.currentVisibilityError = true;
      });
  },
});

export default settingVisibilitySlice.reducer;
