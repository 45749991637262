import React, { MouseEvent, useEffect, useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import ImageGrid from '../grid-layout/ImageGrid';
import { GallerySlide } from '../activity-details-section/activity-details-card/activity-details-card';
import Video from 'yet-another-react-lightbox/plugins/video';
import { Button } from '@mui/material';
import { encodedTitle } from 'app/utils/common-utils/common-utils';
import { useNavigate } from 'react-router-dom';

const BlogListSingle = (data: any) => {
  console.log('BlogListSingle', data);
  const [index, setIndex] = useState(-1);
  const handleLightBoxClick = (index: number) => setIndex(index);
  const handleLightBoxClose = () => setIndex(-1);
  const navigate = useNavigate();
  const blogSlides: GallerySlide[] = data?.data?.eventGallery?.map((img: any) => {
    if (img.type === 'videos') {
      return {
        type: 'video',
        src: img?.link,
        thumbnail: img?.thumbnail,
        title: '',
        sources: [{ src: img?.link, type: 'video/mp4' }],
      };
    } else {
      return {
        type: 'image',
        src: img?.link,
        thumbnail: img?.link,
        title: '',
      };
    }
  });
  return (
    <div style={{ height: '430px', position: 'relative', borderRadius: '15px', overflow: 'hidden' }}>
      {data?.data?.eventGallery && data?.data?.eventGallery.length > 0 && (
        <ImageGrid onImageClick={handleLightBoxClick}>
          {data?.data?.eventGallery?.map((item, Index) => (
            <div key={Index} className={`img_container ${item.type === 'videos' ? 'video-grid' : ''}`}>
              <img
                alt="ig"
                src={item.type === 'videos' ? item?.thumbnail : item?.link}
                style={{ objectPosition: '1px 1%', objectFit: 'cover' }}
              />
            </div>
          ))}
        </ImageGrid>
      )}

      {data?.data?.featured && (
        <div
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            background: '#674BFF',
            color: '#FFFF',
            padding: '5px 10px',
            borderRadius: '0 0 15px 0',
            zIndex: '999',
            fontSize: '12px',
          }}
        >
          <span style={{ fontWeight: 'bold' }}>Featured</span>
        </div>
      )}

      <Button
        variant="contained"
        size="small"
        style={{
          position: 'absolute',
          bottom: 10,
          left: 10,
          background: '#F6F7F8',
          color: '#900095',
          textTransform: 'capitalize',
          borderRadius: '16px',
        }}
        onClick={() => {
          navigate(`/blog-single/${encodedTitle(data?.data?.blogTitle)}-${data?.data.id}`);
        }}
      >
        {data?.data?.blogType?.category}
      </Button>
      <Lightbox
        carousel={{ finite: true }}
        open={index >= 0}
        close={handleLightBoxClose}
        slides={blogSlides}
        plugins={[Video]}
        index={index}
      />
    </div>
  );
};

export default BlogListSingle;
