// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 1200px) {
  .mob-pt {
    padding-top: 50px !important;
  }
}
@media only screen and (max-width: 600px) {
  .fontsmall {
    font-size: small !important;
  }
}
@media (min-width: 900px) {
  .css-zcy9rs-MuiGrid-root {
    max-width: 30% !important;
  }
}
@media only screen and (max-width: 767px) {
  .hide-org {
    display: none !important;
  }
  .fixed-button2 {
    display: block !important;
    position: fixed;
    bottom: 40px; /* Adjust this value to change the distance from the bottom */
    right: 20px; /* Adjust this value to change the distance from the right */
    z-index: 9999; /* Ensure the button appears above other elements */
    color: #fff;
    padding: 15px 20px;
    border-radius: 50%;
    cursor: pointer;
  }
  .btn-mrgtop {
    margin-top: 10px !important;
  }
}
@media only screen and (min-width: 767px) {
  .show-mile {
    display: none !important;
  }
  .fixed-button2 {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/ui/organaization/organization-details/organization-details.scss"],"names":[],"mappings":"AAAA;EACE;IACE,4BAAA;EACF;AACF;AACA;EACE;IACE,2BAAA;EACF;AACF;AACA;EACE;IACE,yBAAA;EACF;AACF;AACA;EACE;IACE,wBAAA;EACF;EACA;IACE,yBAAA;IACA,eAAA;IACA,YAAA,EAAA,6DAAA;IACA,WAAA,EAAA,4DAAA;IACA,aAAA,EAAA,mDAAA;IACA,WAAA;IACA,kBAAA;IACA,kBAAA;IACA,eAAA;EACF;EACA;IACE,2BAAA;EACF;AACF;AACA;EACE;IACE,wBAAA;EACF;EACA;IACE,aAAA;EACF;AACF","sourcesContent":["@media only screen and (max-width: 1200px) {\n  .mob-pt {\n    padding-top: 50px !important;\n  }\n}\n@media only screen and (max-width: 600px) {\n  .fontsmall {\n    font-size: small !important;\n  }\n}\n@media (min-width: 900px) {\n  .css-zcy9rs-MuiGrid-root {\n    max-width: 30% !important;\n  }\n}\n@media only screen and (max-width: 767px) {\n  .hide-org {\n    display: none !important;\n  }\n  .fixed-button2 {\n    display: block !important;\n    position: fixed;\n    bottom: 40px; /* Adjust this value to change the distance from the bottom */\n    right: 20px; /* Adjust this value to change the distance from the right */\n    z-index: 9999; /* Ensure the button appears above other elements */\n    color: #fff;\n    padding: 15px 20px;\n    border-radius: 50%;\n    cursor: pointer;\n  }\n  .btn-mrgtop {\n    margin-top: 10px !important;\n  }\n}\n@media only screen and (min-width: 767px) {\n  .show-mile {\n    display: none !important;\n  }\n  .fixed-button2 {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
