import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const CalendarIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
    <g id="calendar_24x24_1x" data-name="calendar(24x24)@1x" transform="translate(0.5 0.5)">
      <path
        id="Path_34"
        data-name="Path 34"
        d="M20,22H4a2.006,2.006,0,0,1-2-2V6A2.006,2.006,0,0,1,4,4H20a2.006,2.006,0,0,1,2,2V20A2.006,2.006,0,0,1,20,22Z"
        transform="translate(-2 -2)"
        fill="none"
        stroke="#888a8e"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_35"
        data-name="Path 35"
        d="M20,22H4a2.006,2.006,0,0,1-2-2V10H22V20A2.006,2.006,0,0,1,20,22Z"
        transform="translate(-2 -2)"
        fill="none"
        stroke="#888a8e"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_36"
        data-name="Path 36"
        d="M16,2V6"
        transform="translate(-2 -2)"
        fill="none"
        stroke="#888a8e"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_37"
        data-name="Path 37"
        d="M8,2V6"
        transform="translate(-2 -2)"
        fill="none"
        stroke="#888a8e"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_38"
        data-name="Path 38"
        d="M5,14H7"
        transform="translate(-2 -2)"
        fill="none"
        stroke="#888a8e"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_39"
        data-name="Path 39"
        d="M9,14h2"
        transform="translate(-2 -2)"
        fill="none"
        stroke="#888a8e"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_40"
        data-name="Path 40"
        d="M13,14h2"
        transform="translate(-2 -2)"
        fill="none"
        stroke="#888a8e"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_41"
        data-name="Path 41"
        d="M17,14h2"
        transform="translate(-2 -2)"
        fill="none"
        stroke="#888a8e"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_42"
        data-name="Path 42"
        d="M5,18H7"
        transform="translate(-2 -2)"
        fill="none"
        stroke="#888a8e"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_43"
        data-name="Path 43"
        d="M9,18h2"
        transform="translate(-2 -2)"
        fill="none"
        stroke="#888a8e"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_44"
        data-name="Path 44"
        d="M13,18h2"
        transform="translate(-2 -2)"
        fill="none"
        stroke="#888a8e"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_45"
        data-name="Path 45"
        d="M17,18h2"
        transform="translate(-2 -2)"
        fill="none"
        stroke="#888a8e"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>,
  'CalendarIcon'
);
export default CalendarIcon;
