import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const RemoteEventIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="21" viewBox="0 0 17 21">
    <g id="webcam_24x24_1x" data-name="webcam(24x24)@1x" transform="translate(-3.5 -1.5)">
      <path
        id="Path_2729"
        data-name="Path 2729"
        d="M7,22H17"
        fill="none"
        stroke="#676e7b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_2730"
        data-name="Path 2730"
        d="M12.5,17.98c-.16.01-.33.02-.5.02s-.34-.01-.5-.02V22h1Z"
        fill="none"
        stroke="#676e7b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_2731"
        data-name="Path 2731"
        d="M12,18a8,8,0,1,0-8-8A8,8,0,0,0,12,18Z"
        fill="none"
        stroke="#676e7b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_2732"
        data-name="Path 2732"
        d="M20,10H15.5"
        fill="none"
        stroke="#676e7b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_2733"
        data-name="Path 2733"
        d="M8.5,10H4"
        fill="none"
        stroke="#676e7b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_2734"
        data-name="Path 2734"
        d="M12,13.5A3.5,3.5,0,1,0,8.5,10,3.5,3.5,0,0,0,12,13.5Z"
        fill="none"
        stroke="#676e7b"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>,
  'RemoteEventIcon'
);

export default RemoteEventIcon;
