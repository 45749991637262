import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    textColor: {
      mainHeading: string;
      heading: string;
      paragraph: string;
      information: string;
    };
    h0: {
      fontStyle: string;
      fontWeight: string;
      fontSize: string;
      letterSpacing: string;
    };
    p2: {
      fontStyle: string;
      fontWeight: string;
      fontSize: string;
      letterSpacing: string;
    };
    p1: {
      semiBold: {
        fontStyle: string;
        fontWeight: string;
        fontSize: string;
        letterSpacing: string;
      };
      medium: {
        fontStyle: string;
        fontWeight: string;
        fontSize: string;
        letterSpacing: string;
      };
      regular: {
        fontStyle: string;
        fontWeight: string;
        fontSize: string;
        letterSpacing: string;
      };
    };
    infoText: {
      bold: {
        fontStyle: string;
        fontWeight: string;
        fontSize: string;
        letterSpacing: string;
      };
      regular: {
        fontStyle: string;
        fontWeight: string;
        fontSize: string;
        letterSpacing: string;
      };
      semiBold: {
        fontStyle: string;
        fontWeight: string;
        fontSize: string;
        letterSpacing: string;
      };
    };

    createActivity: {
      width: string;
      height: string;
      backgroundColor: {
        hover: string;
        inactive: string;
      };
      borderRadius: string;
    };
    primary: {
      width: string;
      height: string;
      backgroundColor: { normal: string; hover: string; inactive: string };
      borderRadius: string;
    };
    secondary: {
      width: string;
      height: string;
      backgroundColor: { normal: string; hover: string; inactive: string };
      borderRadius: string;
    };
    small: {
      width: string;
      height: string;
      backgroundColor: { normal: string; hover: string; inactive: string };
      borderRadius: string;
    };
    profile: {
      color: string;
      secondary: string;
    };
  }
  interface ThemeOptions {
    textColor: {
      mainHeading: string;
      heading: string;
      paragraph: string;
      information: string;
    };
    h0: {
      fontStyle: string;
      fontWeight: string;
      fontSize: string;
      letterSpacing: string;
    };
    p2: {
      fontStyle: string;
      fontWeight: string;
      fontSize: string;
      letterSpacing: string;
    };
    p1: {
      semiBold: {
        fontStyle: string;
        fontWeight: string;
        fontSize: string;
        letterSpacing: string;
      };
      medium: {
        fontStyle: string;
        fontWeight: string;
        fontSize: string;
        letterSpacing: string;
      };
      regular: {
        fontStyle: string;
        fontWeight: string;
        fontSize: string;
        letterSpacing: string;
      };
    };
    infoText: {
      bold: {
        fontStyle: string;
        fontWeight: string;
        fontSize: string;
        letterSpacing: string;
      };
      regular: {
        fontStyle: string;
        fontWeight: string;
        fontSize: string;
        letterSpacing: string;
      };
      semiBold: {
        fontStyle: string;
        fontWeight: string;
        fontSize: string;
        letterSpacing: string;
      };
    };

    createActivity: {
      width: string;
      height: string;
      backgroundColor: {
        hover: string;
        inactive: string;
      };
      borderRadius: string;
    };
    primary: {
      width: string;
      height: string;
      backgroundColor: { normal: string; hover: string; inactive: string };
      borderRadius: string;
    };
    secondary: {
      width: string;
      height: string;
      backgroundColor: { normal: string; hover: string; inactive: string };
      borderRadius: string;
    };
    small: {
      width: string;
      height: string;
      backgroundColor: { normal: string; hover: string; inactive: string };
      borderRadius: string;
    };
    profile: {
      color: string;
      secondary: string;
    };
  }
}

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          maxHeight: 'unset !important',
          margin: '0% 0% 0% 0% !important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '15px',
          background: '#fff',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',
        },
      },
    },
  },
  palette: {
    mode: 'light',
    background: {
      default: 'transparent linear-gradient(118deg, #F7F5EF 35%, #F2F2FA 58%, #EFEFF5 100%) 0% 0% no-repeat padding-box;',
    },
    primary: {
      main: '#FFCE00',
      light: '#674BFF',
    },
    secondary: {
      main: '#292D34',
      dark: '#FFFFFF',
      light: '#676E7B',
      contrastText: '#BFBFBF',
    },
    error: {
      main: '#E23A44',
    },
    warning: {
      main: '#FFAC30',
    },
  },
  typography: {
    fontFamily: "'Manrope', sans-serif",
    h1: {
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: ' 41px',
      letterSpacing: '0px',
    },
    h2: {
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: ' 34px',
      letterSpacing: '0px',
    },
    h3: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: ' 29px',
      letterSpacing: '0px',
    },
    h4: {
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: ' 24px',
      letterSpacing: '0px',
    },
    h5: {
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: ' 20px',
      letterSpacing: '0px',
    },
  },

  textColor: {
    mainHeading: '#292D34',
    heading: '#676E7B',
    paragraph: '#888A8E',
    information: '#BFBFBF',
  },
  h0: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: ' 49px',
    letterSpacing: '0px',
  },
  p2: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    letterSpacing: '0px',
  },
  p1: {
    semiBold: {
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '16px',
      letterSpacing: '0px',
    },
    medium: {
      fontStyle: 'normal',
      fontWeight: 'medium',
      fontSize: '16px',
      letterSpacing: '0px',
    },
    regular: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      letterSpacing: '0px',
    },
  },
  infoText: {
    bold: {
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '14px',
      letterSpacing: '0px',
    },
    regular: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      letterSpacing: '0px',
    },
    semiBold: {
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '13px',
      letterSpacing: '2.15px',
    },
  },

  createActivity: {
    width: '139px',
    height: '40px',
    backgroundColor: {
      hover: '#775FFF',
      inactive: '#BFBFBF',
    },
    borderRadius: '7px',
  },
  primary: {
    width: '225px',
    height: '40px',
    backgroundColor: {
      normal: '#674BFF',
      hover: '#F2C300',
      inactive: '#BFBFBF',
    },
    borderRadius: '15px',
  },
  secondary: {
    width: '229',
    height: '40',
    backgroundColor: {
      normal: '#FFFFFF',
      hover: '#FFCE00',
      inactive: '#BFBFBF',
    },
    borderRadius: '15px',
  },
  small: {
    width: '110px',
    height: '40px',
    backgroundColor: {
      normal: '#FFCE00',
      hover: '#F2C300',
      inactive: '#BFBFBF',
    },
    borderRadius: '15px',
  },
  profile: { color: '#707070', secondary: '#5C5E60' },
});

export default theme;
