import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IUserPreferences } from 'app/shared/model/user-preferences.model';
import { getEntity, updateEntity, createEntity, reset } from './user-preferences.reducer';

export const UserPreferencesUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const userPreferencesEntity = useAppSelector(state => state.userPreferences.entity);
  const loading = useAppSelector(state => state.userPreferences.loading);
  const updating = useAppSelector(state => state.userPreferences.updating);
  const updateSuccess = useAppSelector(state => state.userPreferences.updateSuccess);

  const handleClose = () => {
    navigate('/user-preferences');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...userPreferencesEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...userPreferencesEntity,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="reachamyApp.userPreferences.home.createOrEditLabel" data-cy="UserPreferencesCreateUpdateHeading">
            <Translate contentKey="reachamyApp.userPreferences.home.createOrEditLabel">Create or edit a UserPreferences</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="user-preferences-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('reachamyApp.userPreferences.userID')}
                id="user-preferences-userID"
                name="userID"
                data-cy="userID"
                type="text"
              />
              <ValidatedField
                label={translate('reachamyApp.userPreferences.profilePic')}
                id="user-preferences-profilePic"
                name="profilePic"
                data-cy="profilePic"
                type="text"
              />
              <ValidatedField
                label={translate('reachamyApp.userPreferences.contactEmail')}
                id="user-preferences-contactEmail"
                name="contactEmail"
                data-cy="contactEmail"
                type="text"
              />
              <ValidatedField
                label={translate('reachamyApp.userPreferences.country')}
                id="user-preferences-country"
                name="country"
                data-cy="country"
                type="text"
              />
              <ValidatedField
                label={translate('reachamyApp.userPreferences.bio')}
                id="user-preferences-bio"
                name="bio"
                data-cy="bio"
                type="text"
              />
              <ValidatedField
                label={translate('reachamyApp.userPreferences.darkMode')}
                id="user-preferences-darkMode"
                name="darkMode"
                data-cy="darkMode"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('reachamyApp.userPreferences.personalizeAdsBasedOnInfoFromOurPartners')}
                id="user-preferences-personalizeAdsBasedOnInfoFromOurPartners"
                name="personalizeAdsBasedOnInfoFromOurPartners"
                data-cy="personalizeAdsBasedOnInfoFromOurPartners"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('reachamyApp.userPreferences.personalizeRecommendationsBasedOnYourGeneralLocation')}
                id="user-preferences-personalizeRecommendationsBasedOnYourGeneralLocation"
                name="personalizeRecommendationsBasedOnYourGeneralLocation"
                data-cy="personalizeRecommendationsBasedOnYourGeneralLocation"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('reachamyApp.userPreferences.notificationsInboxMessages')}
                id="user-preferences-notificationsInboxMessages"
                name="notificationsInboxMessages"
                data-cy="notificationsInboxMessages"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('reachamyApp.userPreferences.notificationsEmailMessages')}
                id="user-preferences-notificationsEmailMessages"
                name="notificationsEmailMessages"
                data-cy="notificationsEmailMessages"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('reachamyApp.userPreferences.notificationsUpvotesOnYourPosts')}
                id="user-preferences-notificationsUpvotesOnYourPosts"
                name="notificationsUpvotesOnYourPosts"
                data-cy="notificationsUpvotesOnYourPosts"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('reachamyApp.userPreferences.notificationsRepliesToYourComments')}
                id="user-preferences-notificationsRepliesToYourComments"
                name="notificationsRepliesToYourComments"
                data-cy="notificationsRepliesToYourComments"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('reachamyApp.userPreferences.notificationsActivityOnYourPosts')}
                id="user-preferences-notificationsActivityOnYourPosts"
                name="notificationsActivityOnYourPosts"
                data-cy="notificationsActivityOnYourPosts"
                check
                type="checkbox"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/user-preferences" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default UserPreferencesUpdate;
