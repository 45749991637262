import React from 'react';
import SecondaryHeader from './secondary-header';
import SettingsBody from './settings-body/settings-body';

const Settings = () => {
  return (
    <SecondaryHeader>
      <SettingsBody />
    </SecondaryHeader>
  );
};

export default Settings;
