import dayjs from 'dayjs';

export interface IBlogTable {
  id?: number;
  title?: string | null;
  blogContent?: string | null;
  categoryId?: number | null;
  imageId?: number | null;
  postAsNgo?: boolean | null;
  ngoUserId?: number | null;
  createdBy?: number | null;
  createdAt?: string | null;
  modifiedBy?: number | null;
  modifiedAt?: string | null;
}

export const defaultValue: Readonly<IBlogTable> = {
  postAsNgo: false,
};
