// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 450px) {
  .font-small {
    font-size: smaller !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/ui/how-it-works/how-it-works.scss"],"names":[],"mappings":"AAAA;EACE;IACE,6BAAA;EACF;AACF","sourcesContent":["@media (max-width: 450px) {\n  .font-small {\n    font-size: smaller !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
