import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const PetitionsIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="20.589" height="14.972" viewBox="0 0 20.589 14.972">
    <g id="noun_announce_3776827" transform="translate(-2 -10.004)">
      <path
        id="Path_3225"
        data-name="Path 3225"
        d="M6.084,13.824v6.047a.34.34,0,0,1-.34.34H3.7A1.7,1.7,0,0,1,2,18.51V15.186a1.7,1.7,0,0,1,1.7-1.7H5.743A.34.34,0,0,1,6.084,13.824Zm-.34,7.068H3.7a.34.34,0,0,0-.34.34v2.722a1.021,1.021,0,0,0,1.021,1.021h.681a1.021,1.021,0,0,0,1.021-1.021V21.233A.34.34,0,0,0,5.743,20.892ZM22.078,16.3H21.057a.51.51,0,0,0,0,1.021h1.021a.51.51,0,0,0,0-1.021ZM20.06,14.913a.51.51,0,0,0,.361-.15l.722-.722a.51.51,0,1,0-.722-.722l-.722.722a.51.51,0,0,0,.361.872Zm.361,3.941a.51.51,0,1,0-.722.722l.722.722a.51.51,0,1,0,.722-.722Zm-2.427-3.747h-.34V11.736a1.726,1.726,0,0,0-.217-.866,1.705,1.705,0,0,0-2.082-.758L6.984,13.251a.34.34,0,0,0-.221.319v6.557a.34.34,0,0,0,.221.319l8.372,3.139a1.7,1.7,0,0,0,2.008-.637,1.753,1.753,0,0,0,.291-1V18.51h.34a1.361,1.361,0,0,0,1.361-1.361v-.681A1.361,1.361,0,0,0,17.994,15.107Z"
        fill="#676e7b"
      />
    </g>
  </svg>,
  'PetitionsIcon'
);
export default PetitionsIcon;
