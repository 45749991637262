import React, { useEffect, useState } from 'react';
import '../dashboard.scss';
import AppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import PrimaryHeader from 'app/components/layout/header/primary-header';
import SideNavigation from 'app/components/layout/side-navigaion/side-navigation';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { fetchOrganization, fetchUnauthorizedOrganization } from 'app/shared/reducers/organization';
import Cookies from 'js-cookie';
import { Grid } from '@mui/material';
import SectionHead from '../general/section-head';
import TabSwitcher from './tab-switcher';
import AmyIcon from 'app/components/common/cards/chat-card/amy-icons';

const drawerWidth = 315;
const drawerWidthSm = 248;

interface Props {
  window?: () => Window;
}

const MyActivityDashboard = (props: Props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [category, setCategory] = useState('');
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [subCategory, setSubCategory] = useState('');
  const [routeBack, setRouteBack] = useState(false);
  const [toggleValue, setToggleValue] = useState(false);
  const [toggleMapValue, setToggleMapValue] = useState(false);
  const profileData = useAppSelector(state => state.organizationReducer.organization);
  const myCookieValue = Cookies.get('jhi-authenticationToken');
  const authenticationToken = myCookieValue;
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const container = window !== undefined ? () => window().document.body : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    const fetchOrganizationData = async () => {
      try {
        if (id)
          if (isAuthenticated) {
            await dispatch(fetchOrganization(id));
          } else {
            await dispatch(fetchUnauthorizedOrganization(id));
          }
      } catch (error) {
        console.log(error);
      }
    };

    fetchOrganizationData();
  }, [id, isAuthenticated]);

  const handleSubCategoryClick = (category: string, subCategory: string) => {
    setCategory(category);
    setSubCategory(subCategory);
  };
  const handleAccordionClick = (category: any, currentState: any) => {
    setCategory(category);
  };
  const handleNavigationArrowButton = () => {
    if (fieldsChanged) {
      setRouteBack(true);
    } else {
      navigate(-1);
    }
  };
  const handleButtonClicked = () => {
    const newToggleValue = !toggleValue;
    if (toggleMapValue) {
      setToggleMapValue(false);
    }
    setToggleValue(newToggleValue);
  };
  const handleButtonFeatureClicked = () => {};
  const handleButtonMapClicked = () => {
    const newToggleMapValue = !toggleMapValue;
    if (toggleValue) {
      setToggleValue(false);
    }
    setToggleMapValue(newToggleMapValue);
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  const drawer = (
    <Box
      sx={{
        border: 'none',
        maxHeight: '100vh',
        overflowY: 'scroll',
        overflowX: 'hidden',

        background:
          theme.palette.mode === 'dark'
            ? '#1F1F26'
            : 'transparent linear-gradient(182deg, #F7F5EF 0%, #F2F2FA 49%, #f1f1f8 100%) 0% 0% no-repeat padding-box;',
        maxWidth: { sm: drawerWidthSm, lg: drawerWidth, md: drawerWidthSm },
        backdropFilter: 'blur(10px)',
        minHeight: '100%',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '&::-webkit-scrollbar-thumb': { backgroundColor: 'secondary.contrastText', height: '10px' },
        '&::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        },
      }}
    >
      {/* <DrawerHeader sx={{ justifyContent: 'center', height: '79px', border: 'none' }}>
        <Box style={{ paddingLeft: '0px', paddingRight: '50px', cursor: 'pointer' }} onClick={() => navigate('/')}>
          <img src="content/images/logos/reachamy-logo.png" alt="reach amy logo" />
        </Box>
      </DrawerHeader> */}
      <DrawerHeader sx={{ justifyContent: 'center', height: '79px', border: 'none' }}>
        <Link to={'/'} style={{ paddingLeft: '0px', paddingRight: '50px' }}>
          <AmyIcon sx={{ width: '159px', height: '100px' }} />
        </Link>
      </DrawerHeader>

      <SideNavigation
        category={category}
        subCategory={subCategory}
        onSubCategoryClick={handleSubCategoryClick}
        onhandleAccordionClick={handleAccordionClick}
        onButtonClicked={handleButtonClicked}
        toggleMapValue={toggleMapValue}
        setToggleMapValue={setToggleMapValue}
        onMapClicked={handleButtonMapClicked}
        onFeatureClicked={handleButtonFeatureClicked}
      />
      <Divider />
    </Box>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        background: isDarkMode ? '#1F1F26' : 'transparent linear-gradient(118deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat',
        p: 0,
        border: 'none',
        backdropFilter: 'blur(10px)',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <CssBaseline />

      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidthSm}px)`, md: `calc(100% - ${drawerWidthSm}px)`, lg: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidthSm}px`, md: `${drawerWidthSm}px`, lg: `${drawerWidth}px` },
          background: isDarkMode ? '#1F1F26' : '#f6f4f1',
        }}
      >
        <Toolbar
          className="amy-top-nav"
          sx={{
            backgroundColor: ' transparent linear-gradient(118deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat',
            border: 'none',
            bakground: isDarkMode ? '#1F1F26' : '',
            backdropFilter: 'blur(10px)',
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' }, top: '10px' }}
          >
            <MenuIcon />
          </IconButton>
          <PrimaryHeader searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} />
        </Toolbar>
      </AppBar>
      {/* Side Nav */}
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidthSm, md: drawerWidthSm, lg: drawerWidth },
          flexShrink: { sm: 0 },
          minHeight: '100vh',
          border: 'none',
          background: isDarkMode ? '#1F1F26' : 'transparent linear-gradient(118deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat',
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidthSm },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: { sm: drawerWidthSm, md: drawerWidthSm, lg: drawerWidth } },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      {/* End side Nav */}

      {/* main body */}
      <Box
        className="inside-bg"
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: '0px',
          background: isDarkMode ? '#1F1F26' : ' #f6f4f1',
          border: 'none',

          overflowY: 'scroll',
        }}
      >
        <Toolbar />
        <div className="container-fluid p-0" style={{ background: isDarkMode ? '#1F1F26' : '' }}>
          <Grid py={2}>
            <SectionHead title="My Activity Dashboard" />
            <TabSwitcher />
          </Grid>
        </div>
      </Box>
    </Box>
  );
};
export default MyActivityDashboard;
