import React, { useState, useEffect } from 'react';
import { useAppSelector } from 'app/config/store';
import { Chip, Grid, IconButton, InputAdornment, TextField, Typography, useTheme } from '@mui/material';
import { isValidEmail, isValidSocial } from 'app/utils/common-utils/common-utils';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

const SettingsSocialAccounts = ({ setUpdatedSettings }: any) => {
  const [mail, setMail] = useState('');
  const [socialMediaMails, setSocialMediaMails] = useState<string[]>([]);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const theme = useTheme();
  const userSettings = useAppSelector(state => state.settingsReducer.userSettings);
  const isDarkMode = theme.palette.mode === 'dark';
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const cleanedValue = event.target.value.replace(/,/g, '');
    setMail(cleanedValue);
    if (cleanedValue.trim() === '') {
      setInvalidEmail(false);
      const emails = cleanedValue.split(',');
      // const isValid = emails.every(email => isValidSocial(email));
      // setInvalidEmail(!isValid);
    }
    // else {
    //   setInvalidEmail(false);
    // }
  };

  const handleInputKeyDown = (event: any) => {
    if ((event.key === 'Enter' || event.key === ' ' || event.key === ',' || event.type === 'click') && mail.trim() !== '') {
      if (isValidSocial(mail.trim())) {
        setSocialMediaMails(prev => [...prev, mail.trim()]);
        setMail('');
        setInvalidEmail(false);
      } else {
        setInvalidEmail(true);
      }
    }
  };
  useEffect(() => {
    const updatedSocialMediaArray =
      socialMediaMails?.map(url => ({
        socialMediaUrl: url,
      })) || [];
    setUpdatedSettings(state => ({ ...state, socialAccounts: updatedSocialMediaArray }));
  }, [socialMediaMails]);

  const handleDelete = (nameToDelete: string) => {
    setSocialMediaMails(prev => prev.filter(name => name !== nameToDelete));
  };

  const getStyles = (name: string) => ({
    fontWeight: mail.includes(name) ? theme.typography.fontWeightMedium : theme.typography.fontWeightRegular,
    backgroundColor: theme.palette.mode === 'dark' ? 'primary.main' : '#F6F7F8',
  });

  // chips
  const selectedChips = (socialMediaMails || [])?.map(name => (
    <Grid item key={name} xs={12} lg gap={1}>
      <Chip label={name} onDelete={() => handleDelete(name)} style={getStyles(name)} />
    </Grid>
  ));

  useEffect(() => {
    if (userSettings?.socialAccounts !== null && userSettings?.socialAccounts !== undefined) {
      const socialMails = userSettings?.socialAccounts?.map(item => item.socialMediaUrl);
      setSocialMediaMails(socialMails);
    }
  }, [userSettings]);

  return (
    <Grid container direction="row" alignItems="flex-start" justifyContent={'flex-start'} gap={2}>
      <Grid container item md={4} direction="column" spacing={1}>
        <Grid item>
          <Typography variant="h5" sx={{ color: theme.textColor.mainHeading, fontSize: '14px' }}>
            Social accounts
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="column" item md gap={1}>
        <Grid item container direction="row" alignItems={'center'} justifyContent={'space-between'}>
          <Grid item xs>
            <TextField
              name="add-social-media"
              fullWidth
              variant="outlined"
              size="small"
              value={mail}
              onChange={e => handleInputChange(e)}
              onKeyDown={handleInputKeyDown}
              placeholder="Enter social links"
              className="no-outline"
              InputProps={{
                style: {
                  backgroundColor: 'primary.main',
                  fontSize: '.75rem',
                  padding: 0.5,
                  height: '38px',
                  borderRadius: '15px',
                  border: '1px solid #52525b',
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="add" onClick={e => handleInputKeyDown(e)}>
                      <AddCircleOutlineOutlinedIcon sx={{ color:  isDarkMode ? '#FFCE00' : '#674BFF' }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Typography color="error" variant="caption">
            {invalidEmail && 'Invalid social link format'}
          </Typography>
        </Grid>
        <Grid item container gap={0.8} alignItems={'center'} justifyContent={'space-between'}>
          {selectedChips}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SettingsSocialAccounts;
