import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ActivityMaster from './activity-master';
import CategoryMaster from './category-master';
import CustomOccurence from './custom-occurence';
import Activity from './activity';
import MessageTable from './message-table';
import BlogComments from './blog-comments';
import ActivityComments from './activity-comments';
import RulesTable from './rules-table';
import BlogTable from './blog-table';
import UserPreferences from './user-preferences';
import Constants from './constants';
import LocationMaster from './location-master';
import InterestMaster from './interest-master';
import Image from './image';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="activity-master/*" element={<ActivityMaster />} />
        <Route path="category-master/*" element={<CategoryMaster />} />
        <Route path="custom-occurence/*" element={<CustomOccurence />} />
        <Route path="activity/*" element={<Activity />} />
        <Route path="message-table/*" element={<MessageTable />} />
        <Route path="blog-comments/*" element={<BlogComments />} />
        <Route path="activity-comments/*" element={<ActivityComments />} />
        <Route path="rules-table/*" element={<RulesTable />} />
        <Route path="blog-table/*" element={<BlogTable />} />
        <Route path="user-preferences/*" element={<UserPreferences />} />
        <Route path="constants/*" element={<Constants />} />
        <Route path="location-master/*" element={<LocationMaster />} />
        <Route path="interest-master/*" element={<InterestMaster />} />
        <Route path="image/*" element={<Image />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
