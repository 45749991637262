import React, { useState } from 'react';
import { Button, Checkbox, FormControl, FormControlLabel, Typography, useTheme } from '@mui/material';
import CreateActivityInstructions from './create-activity-instructions/create-activity-instructions';
import CreateActivityResources from './create-activity-resources/create-activity-resources';
import './create-activity-instruction-resources.scss';
import { useAppSelector } from 'app/config/store';
import LoaderIcon from 'app/components/common/loader-icon/loader-icon';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const CreateActivityInstructionResources = ({ isError, formik, isButtonDisabled, onCancel }: any) => {
  const isLoading = useAppSelector(state => state.homeActivity.loading);
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const handleCancel = () => {
    formik.setFieldValue('instructions', []);
    // Reset form values
    formik.resetForm();
  };
  const navigate = useNavigate();

  return (
    <div className="d-flex flex-column align-items-center justify-content-center " style={{ rowGap: '10px' }}>
      <div className="col-12  mt-sm-2 mt-2 mt-md-0">
        <CreateActivityInstructions formik={formik} />
      </div>
      {formik.values.subCategoryID !== 72 && (
        <div className="col-12">
          <CreateActivityResources formik={formik} />
        </div>
      )}
      <div className=" fixed-button1 text-center">
        <div>
          {formik.values.categoryID !== 19 && formik.values.subCategoryID !== 72 && formik.values.activityFrequencyId === 1 && (
            <FormControl component="fieldset">
              <FormControlLabel
                sx={{ color: 'secondary.main', fontSize: '14px' }}
                value="end"
                control={
                  <Checkbox
                    name="urgent"
                    checked={formik.values.urgent === true}
                    onChange={event => {
                      formik.setFieldValue('urgent', event.target.checked);
                    }}
                    disableRipple
                    sx={{
                      color: 'transparent',
                      '&.Mui-checked': { color: 'primary.light' },
                      '&.MuiCheckbox-root': { paddingBottom: '0px', paddingTop: '0px' },
                      '& .MuiSvgIcon-root': { fontSize: 23, borderRadius: '25%', border: '1px solid #BFBFBF' },
                    }}
                  />
                }
                label={<Typography sx={{ color: 'secondary.main', fontSize: '12px' }}>Mark as urgent</Typography>} // Set font size here
                labelPlacement="end"
              />
            </FormControl>
          )}
        </div>

        <div>
          <Button
            className="m-1"
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              backgroundColor:
                !formik.dirty || !formik.isValid || formik.values.activityStartTime === formik.values.activityEndTime
                  ? 'secondary.contrastText'
                  : 'primary.main',
              textTransform: 'none',
              borderRadius: '15px',
              width: '200px',
              color:
                !formik.dirty ||
                !formik.isValid ||
                dayjs(formik.values.activityStartTime).format('YYYY-MM-DD HH:mm') ===
                  dayjs(formik.values.activityEndTime).format('YYYY-MM-DD HH:mm')
                  ? 'secondary.dark'
                  : 'common.black',
              height: '40px',
              '&:hover': {
                backgroundColor:
                  !formik.dirty ||
                  !formik.isValid ||
                  dayjs(formik.values.activityStartTime).format('YYYY-MM-DD HH:mm') ===
                    dayjs(formik.values.activityEndTime).format('YYYY-MM-DD HH:mm')
                    ? 'secondary.contrastText'
                    : 'primary.main',
                color:
                  !formik.dirty ||
                  !formik.isValid ||
                  dayjs(formik.values.activityStartTime).format('YYYY-MM-DD HH:mm') ===
                    dayjs(formik.values.activityEndTime).format('YYYY-MM-DD HH:mm')
                    ? 'secondary.dark'
                    : 'common.black',
              },
            }}
            endIcon={isLoading && formik.isValid ? <LoaderIcon /> : null}
            disabled={
              formik.isSubmitting ||
              isButtonDisabled ||
              isError ||
              dayjs(formik.values.activityStartTime).format('YYYY-MM-DD HH:mm') ===
                dayjs(formik.values.activityEndTime).format('YYYY-MM-DD HH:mm')
            }
          >
            Post now
          </Button>

          <Button
            className="m-1"
            type="button"
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: isDarkMode ? '#FFCE00' : '#674BFF',
              textTransform: 'none',
              borderRadius: '15px',
              width: '200px',
              color: isDarkMode ? 'black' : 'white',
              '&:hover': {
                backgroundColor: isDarkMode ? '#FFCE00' : '#674BFF',
              },
            }}
            endIcon={isLoading && formik.isValid ? <LoaderIcon /> : null}
            disabled={
              formik.isSubmitting || isButtonDisabled || isError || formik.values.activityStartTime === formik.values.activityEndTime
            }
            onClick={onCancel}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};
export default CreateActivityInstructionResources;
